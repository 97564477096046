import { Box } from '@material-ui/core';
import { ProductTab } from 'apps/Product';
import { VerificationResponse } from 'models/VerificationOld.model';
import { ProductTypes } from 'models/Product.model';
import React, { useEffect, useMemo } from 'react';
import { selectCanUseADFraudScore } from 'state/merchant';
import { useSelector } from 'react-redux';
import { NavigationTypes, trackingManager } from 'apps/Tracking';
import { useLocation } from 'react-router-dom';
import { Routes } from 'models/Router.model';

export function VerificationProductList({ productList, onSelect, selectedId, verification, crashedProducts }: {
  productList: ProductTypes[];
  onSelect: (product: ProductTypes) => void;
  selectedId?: ProductTypes;
  verification: VerificationResponse;
  crashedProducts?: Map<ProductTypes, Error>;
}) {
  const canUseADFraudScore = useSelector<any, boolean>(selectCanUseADFraudScore);
  const primaryProductList = useMemo(() => productList.filter((id) => ![ProductTypes.Metadata, ProductTypes.ADFraudScore].includes(id)), [productList]);

  const location = useLocation();
  const from: string | undefined = location.state?.from;
  useEffect(() => {
    if (verification?.id && verification?.verificationStatus) {
      trackingManager.trackNavigation(NavigationTypes.Verification, {
        isCustomerProfile: from?.startsWith(Routes.identity.verification.customer),
        verificationId: verification?.id,
        verificationStatus: verification?.verificationStatus,
      });
    }
  }, [from, verification?.id, verification?.verificationStatus]);


  return (
    <Box p={2} position="sticky" top={0}>
      <Box mb={{ xs: 1, lg: 5 }}>
        {primaryProductList.map((item) => (
          <ProductTab
            key={item}
            id={item}
            isSelected={selectedId === item}
            onSelect={onSelect}
            verification={verification}
            isDisabled={crashedProducts?.has(item)}
          />
        ))}
        {canUseADFraudScore && (
          <ProductTab
            id={ProductTypes.ADFraudScore}
            isSelected={selectedId === ProductTypes.ADFraudScore}
            onSelect={onSelect}
            verification={verification}
            isDisabled={crashedProducts?.has(ProductTypes.ADFraudScore)}
          />
        )}
        <Box mt={3}>
          <ProductTab
            id={ProductTypes.Metadata}
            isSelected={selectedId === ProductTypes.Metadata}
            onSelect={onSelect}
            verification={verification}
            isDisabled={crashedProducts?.has(ProductTypes.Metadata)}
          />
        </Box>
      </Box>
    </Box>
  );
}
