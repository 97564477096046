import classnames from 'classnames';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AppTheme } from 'apps/theme';
import { useFormatMessage } from 'apps/intl';
import { DateFormatTypes } from 'lib/date';
import { formatCurrency } from 'lib/currency';
import { BankAccountTransactionTypes, IBankAccountDataVerificationTransaction, IMonthwiseIncomeExpenseChartData } from '../../models/BankAccountData.model';
import { useStyles } from '../BankAccountDataVerification/BankAccountDataVerification.styles';

function CustomTooltip({ active, payload, currency, chartData, noDataMessage, hoveredBar }: {
  active?: boolean;
  payload?: any[];
  currency: string;
  chartData: IMonthwiseIncomeExpenseChartData[];
  noDataMessage: string;
  hoveredBar?: string;
}) {
  const classes = useStyles();

  if (chartData.every((monthwiseData) => monthwiseData.totalDeposit === 0 && monthwiseData.totalWithdrawal === 0)) {
    return (
      <div className={classes.chartTooltip}>
        <div className={classes.noDataAvailable}>{noDataMessage}</div>
      </div>
    );
  }

  if (active && hoveredBar && payload?.length > 0) {
    const hoveredItem = payload.find((bar) => bar.dataKey === hoveredBar);
    return (
      <div className={classes.chartTooltip}>
        <Grid container alignItems="baseline" justifyContent="space-between">
          <div className={classes.chartLabel}>{hoveredItem?.payload?.timePeriod}</div>
          <div
            className={classnames(classes.chartValue, {
              [classes.greenChartValue]: hoveredBar === 'totalDeposit',
              [classes.redChartValue]: hoveredBar === 'totalWithdrawal',
            })}
          >
            {formatCurrency(hoveredItem?.value as string, currency)}
          </div>
        </Grid>
      </div>
    );
  }

  return null;
}

export function BankAccountMonthwiseChart({ transactions, currency }:
  {
    transactions: IBankAccountDataVerificationTransaction[];
    currency: string;
  }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [hoveredBar, setHoveredBar] = useState<Nullable<string>>(null);

  const monthwiseIncomeExpenseChartData = useMemo<IMonthwiseIncomeExpenseChartData[]>(() => {
    if (!transactions || transactions?.length === 0) return [{ timePeriod: '', totalDeposit: 0, totalWithdrawal: 0 }];

    // get a unique set of all months from the transactions, then use Array methods to reverse and slice out the recent 12 months
    const lastTwelveMonths = Array.from(new Set(transactions?.map((tx) => dayjs(tx.date).format(DateFormatTypes.MonthYearVeryShort)))).slice(0, 12);

    const monthwiseData = [];
    lastTwelveMonths.reverse().forEach((month) => {
      // get all transactions corresponding to the month
      const transactionsOfMonth = transactions
        .map((tx) => ({ ...tx, date: dayjs(tx.date).format(DateFormatTypes.MonthYearVeryShort) }))
        .filter((tx) => tx.date === month);

      const debits = transactionsOfMonth.filter((txm) => txm.type === BankAccountTransactionTypes.DEBIT).map((tx) => tx.amount);
      const credits = transactionsOfMonth.filter((txm) => txm.type === BankAccountTransactionTypes.CREDIT).map((tx) => tx.amount);

      // to format the data in { timePeriod: 'Mar 22', totalDeposit: 500, totalWithdrawal: 330 } format, so that chart can easily consume data
      monthwiseData.push({
        timePeriod: month,
        totalDeposit: credits.reduce((acc, val) => Number(acc) + Number(val), 0),
        totalWithdrawal: debits.reduce((acc, val) => Number(acc) + Number(val), 0),
      });
    });

    return monthwiseData;
  }, [transactions]);

  return (
    <ResponsiveContainer width="100%" height={300} className={classnames(classes.responsiveContainer, 'fs-exclude')}>
      <BarChart data={monthwiseIncomeExpenseChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} stroke={AppTheme.palette.common.black50} />
        <XAxis
          dataKey="timePeriod"
          tickLine={false}
          tickMargin={5}
          tick={{ fill: AppTheme.palette.common.black60, fontSize: 12 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          hide={monthwiseIncomeExpenseChartData.every((monthwiseData) => monthwiseData.totalDeposit === 0 && monthwiseData.totalWithdrawal === 0)}
          tick={{ fill: AppTheme.palette.common.black60, fontSize: 12 }}
          padding={{ bottom: 0.5 }}
        />
        <Bar
          dataKey="totalDeposit"
          fill={AppTheme.palette.common.greentransparent}
          name={formatMessage('BankAccountData.chart.totalDeposit')}
          onMouseEnter={() => setHoveredBar('totalDeposit')}
          onMouseLeave={() => setHoveredBar(null)}
          minPointSize={3}
        />
        <Bar
          dataKey="totalWithdrawal"
          fill={AppTheme.palette.common.lightRed}
          name={formatMessage('BankAccountData.chart.totalWithdrawal')}
          onMouseEnter={() => setHoveredBar('totalWithdrawal')}
          onMouseLeave={() => setHoveredBar(null)}
          minPointSize={3}
        />
        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip hoveredBar={hoveredBar} currency={currency} chartData={monthwiseIncomeExpenseChartData} noDataMessage={formatMessage('BankAccountData.table.noDataAvailable')} />}
        />
        <Legend formatter={(value) => <span style={{ color: AppTheme.palette.common.black90 }}>{value}</span>} />
      </BarChart>
    </ResponsiveContainer>
  );
}
