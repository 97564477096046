import { CustomWatchlistSeverityOnMatchTypes } from 'models/CustomWatchlist.model';
import { IStepExtra } from 'models/Step.model';
import { IWatchlist, IWatchlistMapping, WatchlistTypes } from 'models/Watchlist.model';
import { VerificationDocumentTypes } from 'models/Document.model';

export const customWatchlistsPollingDelay = 5000;
export const MAX_CUSTOMWATCHLISTS_QTY = 10;

export interface IFlowWatchlistUi extends IWatchlist {
  severityOnMatch: CustomWatchlistSeverityOnMatchTypes;
}

export enum CustomWatchlistSettingsTypes {
  CustomWatchlists = 'custom',
  DataSource = 'dataSource',
  facialWatchList = 'customWatchlistBiometrics',
  faceMatchThreshold = 'faceMatchThreshold',
  facialWatchListSource = 'facialWatchListSource'
}

export enum CustomWatchlistCheckTypes {
  CustomDatabases = 'customDatabases',
}

export enum CustomWatchlistModalValidationInputs {
  Name = 'name',
  FileKey = 'inputSourceFileKey',
  Mapping = 'mapping',
  CsvSeparator = 'csvSeparator',
  FileName = 'inputSourceFileName'
}

export interface CustomWatchlistStepDataWatchlist {
  id: string;
  name: string;
  watchlistType: CustomWatchlistSettingsTypes;
}

export interface CustomWatchlistStepDataSearchParams {
  fullName?: string;
  dateOfBirth?: string;
  documentType?: string;
  documentNumber?: string;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
}

export type CustomWatchlistStepDataSearchResult = Record<string, string>;

export interface ICustomWatchlistStepData {
  documentType?: VerificationDocumentTypes;
  dataSource: VerificationDataSourceTypes;
  country?: string;
  watchlist: CustomWatchlistStepDataWatchlist;
  searchedAt: Date;
  searchParams: Nullable<CustomWatchlistStepDataSearchParams>;
  searchResult: Nullable<CustomWatchlistStepDataSearchResult>;
}

export type CustomWatchlistStepType = IStepExtra<ICustomWatchlistStepData[]>;

export interface CustomWatchlistModalValidationInputTypes {
  [CustomWatchlistModalValidationInputs.Name]: string;
  [CustomWatchlistModalValidationInputs.FileKey]: Nullable<string>;
  [CustomWatchlistModalValidationInputs.Mapping]: IWatchlistMapping[];
  [CustomWatchlistModalValidationInputs.CsvSeparator]: Nullable<string>;
  [CustomWatchlistModalValidationInputs.FileName]: string;
}

export enum VerificationDataSourceTypes {
  DocumentVerification = 'document-verification',
  CustomFields = 'custom-fields',
}

export const defaultFaceMatchThreshold = 70;

export enum FileErrorTypes {
  UnSupportedType = 'UnSupportedType',
  FileSizeError = 'FileSizeError',
}

export enum WatchListErrorTypes {
  Empty= 'empty',
  MaxLength = 'maxLength',
  PatternMatchFailed = 'patternMatchFailed'
}

export enum FacialWatchlistDataSourceTypes {
  SELFIE_VIDEO = 'selfie-video',
  SELFIE_PHOTO = 'selfie-photo',
  LIVENESS_NIST = 'liveness-nist',
}
