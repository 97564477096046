import { FlowError } from 'apps/ui';
import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { useOverlay } from 'apps/overlay';
import { CurrenciesTypes } from 'models/Country.model';
import { IFlowFinancialInformationChecks } from 'models/Flow.model';
import { SUPPORTED_BALANCE_CHECKS, SUPPORTED_TRANSACTION_CHECKS } from '../../models/BankAccountData.model';
import { BankAccountCheckCard } from '../BankAccountCheckCard/BankAccountCheckCard';
import { FinancialChecksModal } from '../FinancialChecksModal/FinancialChecksModal';
import { useStyles, StyledButtonBase } from './BankAccountDataFinancialChecks.styles';

function BankAccountDataFinancialChecksComponent({ checks, supportedCurrencies, onUpdate, disableLibrary, errorMessage }: {
  checks: IFlowFinancialInformationChecks;
  supportedCurrencies: CurrenciesTypes[];
  onUpdate: (data: IFlowFinancialInformationChecks) => void;
  disableLibrary: boolean;
  errorMessage: Nullable<string>;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();

  const openFinancialChecksModal = useCallback(() => {
    createOverlay(<FinancialChecksModal closeOverlay={closeOverlay} onUpdate={onUpdate} selectedChecks={checks} supportedCurrencies={supportedCurrencies} />);
  }, [checks, closeOverlay, supportedCurrencies, createOverlay, onUpdate]);

  return (
    <Box>
      <Box my={2} className={classes.headerWrapper}>
        {formatMessage('BankAccountData.financialChecks.library')}
        <StyledButtonBase onClick={openFinancialChecksModal} disabled={disableLibrary}>
          {formatMessage('Button.open')}
        </StyledButtonBase>
      </Box>
      {errorMessage && (
        <FlowError text={formatMessage(errorMessage)} />
      )}
      <Box my={2} fontSize={14} fontWeight={700}>
        {formatMessage('BankAccountData.financialChecks.balanceChecks')}
      </Box>
      <Box className={classes.cardWrapper}>
        {SUPPORTED_BALANCE_CHECKS.map((balanceCheck) => (
          <BankAccountCheckCard
            key={balanceCheck}
            name={balanceCheck}
            isWarning={checks?.[balanceCheck]?.thresholds?.length !== supportedCurrencies.length}
            isChecked={Object.prototype.hasOwnProperty.call(checks, balanceCheck)}
          />
        ))}
      </Box>
      <Box my={2} fontSize={14} fontWeight={700}>
        {formatMessage('BankAccountData.financialChecks.transactionChecks')}
      </Box>
      <Box className={classes.cardWrapper}>
        {SUPPORTED_TRANSACTION_CHECKS.map((transactionCheck) => (
          <BankAccountCheckCard
            key={transactionCheck}
            name={transactionCheck}
            isWarning={checks?.[transactionCheck]?.thresholds?.length !== supportedCurrencies.length}
            isChecked={Object.prototype.hasOwnProperty.call(checks, transactionCheck)}
          />
        ))}
      </Box>
    </Box>
  );
}

export const BankAccountDataFinancialChecks = React.memo(BankAccountDataFinancialChecksComponent);
