import Box from '@material-ui/core/Box';
import { Alert, Warning, WarningTypes } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { getStepExtra, StepStatus, VerificationStepTypes } from 'models/Step.model';
import React, { useMemo } from 'react';
import { getStepsBySource } from 'models/Watchlist.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import type { CustomWatchlistStepType } from '../../models/CustomWatchlist.model';
import { WatchListsBySource } from '../WatchListsBySource/WatchListsBySource';

export function CustomWatchlistVerification({ data }: {
  data: VerificationResponse;
}) {
  const formatMessage = useFormatMessage();
  const step = useMemo<CustomWatchlistStepType>(() => getStepExtra(data.steps.find((dataStep) => dataStep.id === VerificationStepTypes.CustomWatchlistsValidation)), [data]);

  if (!step) {
    return null;
  }

  if (step?.checkStatus === StepStatus.Checking) {
    return (
      <Box mt={2}>
        <Warning
          type={WarningTypes.Notify}
          label={formatMessage(`SecurityCheckStep.customWatchlist.${step.checkStatus}`)}
          filled
          isLabelColored
        />
      </Box>
    );
  }

  if (!step?.data && step?.checkStatus === StepStatus.Failure) {
    return (
      <Box mt={2}>
        <Alert
          title={formatMessage(`SecurityCheckStep.${step.error.code}`, { defaultMessage: formatMessage('SecurityCheckStep.system.internalError.message') })}
        />
      </Box>
    );
  }

  if (!step.data) {
    return null;
  }

  const stepsBySource = getStepsBySource(step.data);
  const photosBySource = data?.documents?.reduce((acc, { type, photos }) => ({ ...acc, [type]: photos }), {});

  return (
    <WatchListsBySource data={stepsBySource} status={step.checkStatus} customFieldsDataCopy={data?.customFieldsDataCopy} photos={photosBySource} />
  );
}
