import { ProductTypes } from 'models/Product.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { productManagerService } from 'apps/Product';
import { selectMerchantTags } from 'state/merchant';
import { VideoAgreement } from '../services/VideoAgreement.service';

export const VideoAgreementInit = () => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const videoAgreement = new VideoAgreement({
    disabled: !merchantTags.includes(MerchantTagsTypes.CanUseVideoAgreement),
  });
  productManagerService.register(videoAgreement);
  return videoAgreement.id;
};
