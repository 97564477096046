import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { ReactComponent as MatiLogo } from 'assets/metamap-logo.svg';
import classnames from 'classnames';
import { useStyles } from './PromoBanner.styles';
import { ReactComponent as FreemiumLogo } from './assets/freemium-logo.svg';
import { ReactComponent as InfoLogo } from './assets/info-icon.svg';
import { SIGN_UP_FREE_ACCESS_MONTHS_COUNT, SIGN_UP_FREE_VERIFICATIONS_COUNT } from '../../../auth';

export function PromoBanner({ children, classNames = {} }: {
  children: ReactNode;
  classNames?: {rightSide?: string};
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.leftSide}>
        <div className={classes.leftContainer}>
          <Box mb="43px">
            <MatiLogo width={148} height={32} />
          </Box>
          <Box mb="36px">
            <FreemiumLogo />
          </Box>
          <Box mb="24px" className={classes.textContainer}>
            <InfoLogo />
            {' '}
            {formatMessage('Freemium.startVerifying')}
          </Box>
          <Box className={classes.overlayContainer}>
            {formatMessage('Freemium.createMetamap')}
            <b>
              {' '}
              {formatMessage('Freemium.enjoyLifetime', {
                messageValues: {
                  verificationsCnt: SIGN_UP_FREE_VERIFICATIONS_COUNT,
                  monthsCnt: SIGN_UP_FREE_ACCESS_MONTHS_COUNT,
                },
              })}
            </b>
          </Box>
        </div>
      </Box>
      <Box className={classnames(classes.rightSide, classNames?.rightSide)}>
        {children}
      </Box>
    </Box>
  );
}
