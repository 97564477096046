import { Loadable } from 'models/Loadable.model';
import { useDispatch, useSelector } from 'react-redux';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { useEffect } from 'react';
import { selectMetadataListModel } from '../store/Templates.selectors';
import { getMetadata } from '../store/Templates.actions';
import { ITemplateMetadata } from '../model/Templates.model';

export function useLoadMetadataList(): Loadable<ITemplateMetadata[]> {
  const dispatch = useDispatch();
  const metadataModel = useSelector(selectMetadataListModel);

  useEffect(() => {
    const loadData = async () => {
      if (LoadableAdapter.isPristine(metadataModel)) {
        try {
          await dispatch(getMetadata());
        } catch (error) {
          console.error(error);
        }
      }
    };
    loadData();
  }, [dispatch, metadataModel]);

  return metadataModel;
}
