import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectPatternsConfigValue, selectMerchantTags } from 'state/merchant';
import { Facematch } from '../services/Facematch.service';

export const facematchInit = () => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const patternsConfig = selectPatternsConfigValue(getState());
  const facematchCheck = new Facematch({
    patternsConfig,
    disabled: merchantTags.includes(MerchantTagsTypes.CanNotUseFaceMatch),
  });
  productManagerService.register(facematchCheck);
  return facematchCheck.id;
};
