import { ProductTypes } from 'models/Product.model';
import React, { useCallback } from 'react';
import { useOverlay } from 'apps/overlay';
import { ProductDependencyMultipleModal } from 'apps/Product/components/ProductDependencyMultipleModal/ProductDependecyMultipleModal';

export function useProductAddingMultiple() {
  const [createOverlay, closeOverlay] = useOverlay();

  return useCallback((requiredProductTypes: ProductTypes | Array<ProductTypes>, onAdd: (productType: ProductTypes) => void) => {
    const handleConfirm = () => {
      if (Array.isArray(requiredProductTypes)) {
        requiredProductTypes.forEach((productType) => {
          onAdd(productType);
        });
      }
      if (!Array.isArray(requiredProductTypes)) {
        onAdd(requiredProductTypes);
      }
      closeOverlay();
    };

    createOverlay(
      <ProductDependencyMultipleModal
        requiredProductTypes={requiredProductTypes}
        onClose={closeOverlay}
        onConfirm={handleConfirm}
      />,
    );
  }, [createOverlay, closeOverlay]);
}
