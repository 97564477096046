import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    boxShadow: 'none',
  },
  cardContent: {
    padding: '10px 0 0 !important',
  },
  bordered: {
    borderColor: theme.palette.common.black7opacity,
  },
  labelError: {
    color: theme.palette.common.red,
  },
  title: {
    color: theme.palette.common.black,
    marginBottom: 20,
  },
}));
