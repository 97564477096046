import { useFormatMessage } from 'apps/intl';
import { InfoTooltip } from 'apps/ui';
import React, { ReactNode } from 'react';
import { IFlattenVerificationPatternConfig, PatternErrorType, VerificationConfigurationErrorTypes } from 'models/VerificationPatternsConfigs.model';
import { DataSourceError } from './components/DataSourceError/DataSourceError';
import { ExclusivePatternsError } from './components/ExclusivePatternsError/ExclusivePatternsError';
import { RequiredPatternsError } from './components/RequiredPatternsError/RequiredPatternsError';
import { DataSourceSubTypeError } from './components/DataSourceSubTypeError/DataSourceSubTypeError';

function IssueTextMap({ errorType, govCheck }: {
  errorType: PatternErrorType;
  govCheck?: IFlattenVerificationPatternConfig;
}) {
  const formatMessage = useFormatMessage();
  switch (errorType.type) {
    case VerificationConfigurationErrorTypes.DataSourceNotInFlow:
    case VerificationConfigurationErrorTypes.DataSourceBadConfig:
      return <DataSourceError errorType={errorType} govCheck={govCheck} />;
    case VerificationConfigurationErrorTypes.DataSourceSubTypeBadConfig:
      return <DataSourceSubTypeError errorType={errorType} govCheck={govCheck} />;
    case VerificationConfigurationErrorTypes.RequiredPatternsNotInFlow:
      return <RequiredPatternsError errorType={errorType} />;
    case VerificationConfigurationErrorTypes.ExclusivePatternsError:
      return <ExclusivePatternsError errorType={errorType} />;
    case VerificationConfigurationErrorTypes.AgeCheckError:
      return <>{formatMessage('tooltip.issue.ageCheck.conflict')}</>;
    default:
      return <>{formatMessage('tooltip.issue.conflict')}</>;
  }
}

export function PatternIssueTooltip({ children, errorType, govCheck }: {
  children: ReactNode;
  errorType: PatternErrorType;
  govCheck: IFlattenVerificationPatternConfig;
}) {
  if (!errorType) return <>{children}</>;

  return (
    <InfoTooltip
      placement="right"
      title={<IssueTextMap errorType={errorType} govCheck={govCheck} />}
    >
      {children}
    </InfoTooltip>
  );
}
