import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { goToPage } from 'lib/url';
import { ApiResponse, CLIENT_CSRF_HEADER_NAME, CLIENT_CSRF_URL, ClientCSRFResponse, ClientErrorTypes, ClientMethodTypes, ClientPrivateMethodList } from 'models/Client.model';
import { devWarn } from 'lib/console';
import { ErrorStatusTypes } from 'models/Error.model';
import { Routes } from 'models/Router.model';

export class HttpClient {
  private csrf: string = null;
  private client: AxiosInstance = null;
  token: string;
  isNewDashboardApi: boolean = false;

  constructor(options: AxiosRequestConfig, isNewDashboardApi = false) {
    this.isNewDashboardApi = isNewDashboardApi;
    this.client = axios.create(options);
  }

  setToken(token: string) {
    this.token = token;
  }

  async getHeaders(method: ClientMethodTypes) {
    const headers: any = {
      ...this.getNonDashboardHeaders(),
      ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
    };

    if (ClientPrivateMethodList.includes(method)) {
      headers[CLIENT_CSRF_HEADER_NAME] = await this.getCSRFToken();
    }
    return headers;
  }

  getNonDashboardHeaders() {
    return {
      'x-mati-app': `platform=dashboard; version=${process.env.REACT_APP_VERSION}`,
    };
  }

  async getCSRFToken() {
    try {
      this.csrf = this.csrf || await this.resolveCSRFToken();
    } catch (e) {
      // eslint-disable-next-line
      console.error('CSRF token error', (e as any).message)
      this.csrf = null;
    }
    return this.csrf;
  }

  async resolveCSRFToken(): Promise<string> {
    const { data } = await this.get<ClientCSRFResponse>(CLIENT_CSRF_URL, null);
    devWarn('New CSRF token:', data?.token);
    return data?.token;
  }

  async createRequest<T>(config: AxiosRequestConfig): Promise<ApiResponse<T>> {
    try {
      return await this.client.request(config);
    } catch (error: any) {
      const type = error?.response?.data?.details?.type;
      if (error?.response?.data?.status === ErrorStatusTypes.BlockedByMerchant && !window.location.pathname.startsWith(Routes.auth.signIn)) {
        this.csrf = null;
        goToPage(Routes.auth.signIn);
      }
      if (type === ClientErrorTypes.CSRFTokenNotFound || type === ClientErrorTypes.CSRFTokenNotValid) {
        // eslint-disable-next-line
        console.error('CSRF error:', error?.message);
        this.csrf = null;
      }
      throw error;
    }
  }

  // TODO @dkchv: !!! remove any
  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.createRequest<T>({
      url: this.getCorsURL(url),
      ...config,
      method: ClientMethodTypes.GET,
      headers: await this.getHeaders(ClientMethodTypes.GET),
      withCredentials: true,
    });
  }

  async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.createRequest<T>({
      ...config,
      url: this.getCorsURL(url),
      data,
      method: ClientMethodTypes.POST,
      headers: {
        ...(await this.getHeaders(ClientMethodTypes.POST)),
        ...(config?.headers || {}),
      },
      withCredentials: true,
    });
  }

  async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.createRequest<T>({
      ...config,
      url: this.getCorsURL(url),
      data,
      method: ClientMethodTypes.PATCH,
      headers: await this.getHeaders(ClientMethodTypes.PATCH),
      withCredentials: true,
    });
  }

  async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.createRequest<T>({
      ...config,
      url: this.getCorsURL(url),
      data,
      method: ClientMethodTypes.PUT,
      headers: await this.getHeaders(ClientMethodTypes.PUT),
      withCredentials: true,
    });
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.createRequest<T>({
      ...config,
      url: this.getCorsURL(url),
      method: ClientMethodTypes.DELETE,
      headers: await this.getHeaders(ClientMethodTypes.DELETE),
      withCredentials: true,
    });
  }

  private getCorsURL(path: string): string {
    if (this.isNewDashboardApi) {
      return process.env.REACT_APP_CORS_URL
        ? `${process.env.REACT_APP_CORS_URL}${process.env.REACT_APP_CORS_PATH}/api/v2${path}`
        : path;
    }
    return process.env.REACT_APP_CORS_URL
      ? `${process.env.REACT_APP_CORS_URL}${process.env.REACT_APP_CORS_PATH}${path}`
      : path;
  }
}
