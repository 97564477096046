import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: theme.palette.text.main,
    marginBottom: 21,
  },
  iconBackground: {
    width: '280px',
    backgroundColor: `${theme.palette.common.lightgray}30`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  descriptionCell: {
    height: '100%',
    display: 'flex',
    color: theme.palette.common.black75,
  },
  description: {},
}));
