import { IFlow } from 'models/Flow.model';
import { Product, ProductSettings, IProductTranslations, IRegistryProduct, getProductBuildUrl, IProductSettingsPermissions, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { ProductExternalSettingsContainer } from '../components/ProductExternalSettingsContainer/ProductExternalSettingsContainer';
import { ProductExternalVerificationResultsContainer } from '../components/ProductExternalVerificationResultsContainer/ProductExternalVerificationResultsContainer';
import { getProductTranslations, getProductPDFTemplate } from '../api/Product.client';
import { onAdd, onRemove, parse, serialize, isInFlow, isCompatibilityMode, getVerification, hasFailedCheck } from '../models/LegacyProductAdapter.model';
import { ProductManagerService } from './ProductManager.service';

const SettingsTranslations: Record<string, IProductTranslations> = {};
const ResultsTranslations: Record<string, IProductTranslations> = {};
const PDFTemplates: Record<string, string> = {};

export class ExternalProduct extends ProductBaseFlowBuilder implements Product {
  private productManager: Nullable<ProductManagerService> = null;

  id = null;
  isExternal = true;
  order = 0;
  tags: string[] = [];
  disabled: boolean = false;
  icon = null;
  inputs = [];
  isIssuesIgnored = false;
  component = null;
  componentVerification = null;
  iconUri: Nullable<string> = null;
  settingUrl: Nullable<string> = null;
  resultUrl: Nullable<string> = null;
  settingsTranslationsUri: Nullable<string> = null;
  resultsTranslationsUri: Nullable<string> = null;
  PDFTemplateUri: Nullable<string> = null;
  initialSetting: Nullable<unknown> = null;
  permissions: IProductSettingsPermissions = {
    read: [],
    modify: [],
  };

  static checkIsPartial(product: IRegistryProduct): boolean {
    return Boolean(product.webContainerConfig.buildConfig?.settings?.disabled) || Boolean(product.webContainerConfig.buildConfig?.results?.disabled);
  }

  constructor(product: IRegistryProduct, merchantTags: string[], productManager: ProductManagerService) {
    super();

    this.productManager = productManager;
    this.id = product.name;
    this.permissions = {
      ...this.permissions,
      ...(product.webContainerConfig.buildConfig?.settings?.permissions ?? {}),
    };
    this.tags = product.webContainerConfig.buildConfig?.settings?.merchantTags ?? this.tags;
    this.order = product.webContainerConfig.buildConfig?.settings?.order ?? this.order;
    this.inputs = product.webContainerConfig.buildConfig?.settings?.inputs ?? this.inputs;
    this.component = ProductExternalSettingsContainer;
    this.componentVerification = ProductExternalVerificationResultsContainer;
    this.iconUri = getProductBuildUrl(`${product.webContainerConfig.settingsBuildUri}${product.webContainerConfig.buildConfig?.settings?.iconUri}`);
    this.settingUrl = getProductBuildUrl(product.webContainerConfig.settingsBuildUri);
    this.resultUrl = getProductBuildUrl(product.webContainerConfig.resultsBuildUri);
    this.initialSetting = product.webContainerConfig.buildConfig?.settings?.initialSetting ?? null;
    this.settingsTranslationsUri = product.webContainerConfig.buildConfig?.settings?.translationsUri ? getProductBuildUrl(`${product.webContainerConfig.settingsBuildUri}${product.webContainerConfig.buildConfig?.settings?.translationsUri}`) : null;
    this.isShowDescription = product.webContainerConfig.buildConfig?.settings?.showDescription || false;
    this.PDFTemplateUri = product.webContainerConfig.buildConfig?.results?.PDFTemplateUri ? getProductBuildUrl(`${product.webContainerConfig.resultsBuildUri}${product.webContainerConfig.buildConfig?.results?.PDFTemplateUri}`) : null;
    this.resultsTranslationsUri = product.webContainerConfig.buildConfig?.results?.translationsUri ? getProductBuildUrl(`${product.webContainerConfig.resultsBuildUri}${product.webContainerConfig.buildConfig?.results?.translationsUri}`) : null;

    if (this.tags.length > 0) {
      this.disabled = !this.tags.every((tag) => merchantTags.includes(tag));
    }

    this.getSettingsTranslations = this.getSettingsTranslations.bind(this);
    this.getResultsTranslations = this.getResultsTranslations.bind(this);
  }

  getCard() {
    return {
      id: this.id,
      disabled: this.disabled,
      icon: null,
      iconUrl: this.iconUri,
      order: this.order,
      title: null,
      description: null,
      isShowDescription: this.isShowDescription,
      inputs: this.inputs,
      checks: [],
      integrationTypes: [],
      requiredProductType: this.requiredProductType,
      dependentProductTypes: this.dependentProductTypes,
      requiredProductTypeFromSubStep: this.requiredProductTypeFromSubStep,
      isExternal: this.isExternal,
      getTranslations: this.getSettingsTranslations,
    };
  }

  async getSettingsTranslations(onLoaded?: (translations: Record<string, any>) => void): Promise<IProductTranslations> {
    if (!this.settingsTranslationsUri) {
      return {};
    }

    try {
      if (!SettingsTranslations[this.id]) {
        const { data } = await getProductTranslations(this.settingsTranslationsUri);
        SettingsTranslations[this.id] = data;
      }

      if (onLoaded) {
        onLoaded(SettingsTranslations[this.id]);
      }

      return SettingsTranslations[this.id];
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  async getResultsTranslations(onLoaded?: (translations: Record<string, any>) => void): Promise<IProductTranslations> {
    if (!this.resultsTranslationsUri) {
      return {};
    }

    try {
      if (!ResultsTranslations[this.id]) {
        const { data } = await getProductTranslations(this.resultsTranslationsUri);
        ResultsTranslations[this.id] = data;
      }

      if (onLoaded) {
        onLoaded(ResultsTranslations[this.id]);
      }

      return ResultsTranslations[this.id];
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  parser(flow: IFlow): ProductSettings {
    const readableProducts = this.productManager.products
      .filter((item) => item.permissions?.read?.includes(this.id))
      .map((item) => item.id);

    return {
      self: {
        value: isCompatibilityMode(this.id) ? parse(this.id, flow) : flow[this.id],
      },
      external: {
        value: readableProducts.reduce((result, id) => ({
          ...result,
          [id]: isCompatibilityMode(this.id) ? parse(this.id, flow) : flow[this.id],
        }), {}),
      },
      url: {
        value: this.settingUrl,
      },
    };
  }

  serialize(settings: any): Partial<IFlow> {
    const { data, target = this.id }: { data: any, target: string } = settings;

    // if self targeted perform usual update
    if (target === this.id) {
      if (isCompatibilityMode(this.id)) {
        return serialize(this.id, data);
      }
      return {
        [this.id]: data,
      };
    }

    // if not selftargeted chech permissions beforehand
    const writableProducts = this.productManager.products
      .filter((item) => item.permissions?.modify?.includes(this.id))
      .map((item) => item.id);

    if (writableProducts.includes(target as ProductTypes)) {
      if (isCompatibilityMode(target)) {
        return serialize(target, data);
      }
      return {
        [target]: data,
      };
    }

    return {};
  }

  onAdd(flow: IFlow): Partial<IFlow> {
    if (isCompatibilityMode(this.id)) {
      return onAdd(this.id, flow);
    }

    return {
      [this.id]: this.initialSetting,
      verificationPatterns: this.inputs.reduce((patterns, input: string) => ({
        ...patterns,
        [input]: true,
      }), {}),
    };
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    if (isCompatibilityMode(this.id)) {
      return onRemove(this.id, flow);
    }

    return {
      [this.id]: null,
      verificationPatterns: this.inputs.reduce((patterns, input: string) => ({
        ...patterns,
        [input]: false,
      }), {}),
    };
  }

  isInFlow(flow: IFlow): boolean {
    if (isCompatibilityMode(this.id)) {
      return isInFlow(this.id, flow);
    }

    return this.inputs.some((input: string) => flow.verificationPatterns[input]);
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    if (isCompatibilityMode(this.id)) {
      return hasFailedCheck(this.id, verification);
    }

    return verification[this.id]?.failed || false;
  }

  getVerification(verification: VerificationResponse) {
    return {
      results: isCompatibilityMode(this.id) ? getVerification(this.id, verification) : verification[this.id],
      url: this.resultUrl,
    };
  }

  async getPdfTemplate(onLoaded?: (template: string) => void): Promise<Nullable<string>> {
    if (!this.PDFTemplateUri) {
      return null;
    }

    try {
      if (!PDFTemplates[this.id]) {
        const { data } = await getProductPDFTemplate(this.PDFTemplateUri);
        PDFTemplates[this.id] = data;
      }

      if (onLoaded) {
        onLoaded(PDFTemplates[this.id]);
      }

      return PDFTemplates[this.id];
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
