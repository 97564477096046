import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core';

export const ButtonBaseText = withStyles((theme) => ({
  root: {
    minHeight: '0 !important',
    color: theme.palette.common.lightblue,
    textDecoration: 'underline',

    '&:hover': {
      color: theme.palette.common.lightbluehover,
    },
  },
}))(ButtonBase);
