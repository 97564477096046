import { Loader } from 'apps/ui';
import { Routes } from 'models/Router.model';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMerchantAgentNotesConfig } from 'state/merchant/merchant.selectors';
import { RoleGuardRoute } from 'apps/routing';
import { appcues } from 'apps/Appcues';
import { PermissionAccessTypes, PermissionsTypes } from 'lib/Auth0';

const DashboardLazy = lazy(async () => {
  const { Dashboard } = await import('apps/dashboard');
  return { default: Dashboard };
});

const SignUpLazy = lazy(async () => {
  const { NewSignUp } = await import('pages/NewSignUp');
  return { default: NewSignUp };
});

const FreeTrialLazy = lazy(async () => {
  const { FreeTrial } = await import('pages/FreeTrial');
  return { default: FreeTrial };
});

const AuthLazy = lazy(async () => {
  const { Auth } = await import('apps/auth');
  return { default: Auth };
});

const ReviewModeLazy = lazy(async () => {
  const { ReviewMode } = await import('apps/reviewMode');
  return { default: ReviewMode };
});

const VerificationDetailsPdfContainerLazy = lazy(async () => {
  const { VerificationDetailsPdfContainer } = await import('pages/VerificationDetailsPdf');
  return { default: VerificationDetailsPdfContainer };
});

export function AppRouter() {
  const agentNotesConfig = useSelector(selectMerchantAgentNotesConfig);
  const [previousLocation, setPreviousLocation] = useState();
  const location = useLocation();

  useEffect(() => {
    if (previousLocation !== location.pathname) {
      setPreviousLocation(location.pathname);
      appcues.page();
    }
  }, [location, previousLocation]);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={Routes.auth.root} component={AuthLazy} />
        <Route path={Routes.signUp.root} component={SignUpLazy} />
        <Route path={Routes.verificationDetailsPdf.root} component={VerificationDetailsPdfContainerLazy} />
        <Route path={Routes.freeTrial.root} component={FreeTrialLazy} />
        {!agentNotesConfig?.requiredOnChangeVerificationStatus && (
          <RoleGuardRoute permission={PermissionsTypes.verifications} access={PermissionAccessTypes.Update} path={Routes.review.root} component={ReviewModeLazy} />
        )}
        <Route path={Routes.root} component={DashboardLazy} />
      </Switch>
    </Suspense>
  );
}
