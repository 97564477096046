import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { QATags } from 'models/QA.model';
import { FiX } from 'react-icons/fi';
import styles from './Modal.module.scss';
import { overlayClose } from '../../state/overlay.actions';

export function Modal({ children, onClose, className, imgSrc = '', title = '', subtitle = '', titleClassName = '', subtitleClassName = '', closeIconClassName = '', description, small = false, wideHeader = false, customCloseIcon = null, isSticky = false, ...props }: {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  imgSrc?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  small?: boolean;
  wideHeader?: boolean;
  customCloseIcon?: React.ReactElement;
  [key: string]: any;
}) {
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    if (onClose) {
      onClose(); // this onClose will be called when you click on the closeButton (x)
    } else {
      dispatch(overlayClose());
    }
  }, [dispatch, onClose]);

  return (
    <div
      className={classNames(styles.window, className, { small })}
      data-role="modal"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {!customCloseIcon && (
        <IconButton data-qa={QATags.Modal.CloseButton} className={classNames(styles.btnClose, { [closeIconClassName]: !!closeIconClassName }, { [styles.sticky]: isSticky })} onClick={closeModal}>
          <FiX size={14} />
        </IconButton>
      )}
      {customCloseIcon && React.cloneElement(customCloseIcon, { 'data-qa': QATags.Modal.CloseButton, onClick: closeModal })}
      <Box className={classNames({ [styles.wideHeader]: wideHeader })}>
        {imgSrc && (
          <Box mb={2}>
            <Grid container alignItems="center" justifyContent="center" className={styles.img}>
              <img src={imgSrc} alt="" />
            </Grid>
          </Box>
        )}
        <Box className={classNames({ [titleClassName]: !!titleClassName }, styles.titleWrapper)}>
          {title && (
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Box className={classNames(styles.subtitle, { [subtitleClassName]: !!subtitleClassName })}>
              {subtitle}
            </Box>
          )}
          {description}
        </Box>
      </Box>
      {children}
    </div>
  );
}
