export default {
  'code[class*="language-"]': {
    color: 'var(--mgi-theme-palette-darkgrey)',
    fontFamily: 'Menlo, monospace',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'break-spaces',
    wordSpacing: 'normal',
    wordBreak: 'break-all',
    fontSize: '12px',
    lineHeight: '1.33em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: 'var(--mgi-theme-palette-darkgrey)',
    fontFamily: 'Menlo, monospace',
    direction: 'ltr',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    fontSize: '12px',
    lineHeight: '1.33em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1rem',
    margin: '0',
    overflow: 'auto',
  },

};
