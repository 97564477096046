import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { useMerchantPlan } from 'apps/merchant';
import { Warning } from 'apps/ui';
import { GithubDocumentationBanner } from '../GithubDocumentationBanner/GithubDocumentationBanner';
import { InformationImage } from '../InformationImage/InformationImage';
import { VideoExplainer } from '../VideoExplainer/VideoExplainer';
import { TabIDTypes, InformationImageTypes } from '../../models/ForDevelopers.model';
import { useStyles } from './ApiIntegrationInformation.styles';

export function ApiIntegrationInformation() {
  const formatMessage = useFormatMessage();
  const { isFreemium } = useMerchantPlan();
  const classes = useStyles();

  return (
    <Box>
      {isFreemium && (
        <Box mb={4}>
          <Warning
            label={formatMessage('Freemium.productNotAvailable')}
            isLabelColored={false}
            meritName={formatMessage('amlCheck.name')}
            bordered
          />
        </Box>
      )}
      <Box className={classNames({ [classes.gayedOut]: isFreemium })}>
        <Box mb={4}>
          <GithubDocumentationBanner
            tabId={TabIDTypes.Api}
            documentationURL="https://docs.metamap.com/docs/api-guide"
            platform={formatMessage(`forDevs.sideMenu.${TabIDTypes.Api}`)}
          />
        </Box>
        <Typography
          variant="subtitle2"
          gutterBottom
        >
          {formatMessage(`forDevs.informationPage.${TabIDTypes.Api}.header`)}
        </Typography>
        <Box
          mb={2}
          color="common.black75"
        >
          {formatMessage(`forDevs.informationPage.${TabIDTypes.Api}.subheader`)}
        </Box>
        <Box mb={4}>
          <InformationImage type={InformationImageTypes.Api} />
        </Box>
        <Box
          pt={4}
          borderTop={1}
          borderColor="common.black7"
        >
          <VideoExplainer
            videoCover="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati%2BAPI.png"
            videoURL="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati+API.mp4"
            tabId={TabIDTypes.Api}
          />
        </Box>
      </Box>
    </Box>
  );
}
