import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  icon: {
    height: 17,
    '& svg': {
      width: 17,
      height: 17,
    },
  },
}));
