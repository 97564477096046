import { useFormatMessage } from 'apps/intl';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantTrialQuota } from 'state/merchant/merchant.selectors';
import { ISubscriptionStatusQuota } from 'apps/Permissions';
import { BannerDaysLeftSubscriptionStatusUI } from 'apps/SubscriptionStatus/components/BannerDaysLeftSubscriptionStatus/BannerDaysLeftSubscriptionStatusUI';
import { useRole } from 'lib/Auth0';
import { FreshChatWidget } from 'apps/freshdesk';

export function BannerDaysLeftSubscriptionStatus() {
  const { isOwner } = useRole();
  const formatMessage = useFormatMessage();
  const quota = useSelector<any, ISubscriptionStatusQuota>(selectMerchantTrialQuota);

  const onClickActivateAccount = () => {
    FreshChatWidget.createNewMessage(formatMessage('SubscriptionStatus.message.startActivationChat'));
  };

  return (
    <BannerDaysLeftSubscriptionStatusUI
      onClickActivateAccount={isOwner && onClickActivateAccount}
      startDate={quota?.duration?.start}
      endDate={quota?.duration?.end}
    />
  );
}
