import FlagAD from './flag_AD.svg';
import FlagAE from './flag_AE.svg';
import FlagAF from './flag_AF.svg';
import FlagAG from './flag_AG.svg';
import FlagAI from './flag_AI.svg';
import FlagAL from './flag_AL.svg';
import FlagAM from './flag_AM.svg';
import FlagAO from './flag_AO.svg';
import FlagAR from './flag_AR.svg';
import FlagAS from './flag_AS.svg';
import FlagAT from './flag_AT.svg';
import FlagAU from './flag_AU.svg';
import FlagAW from './flag_AW.svg';
import FlagAX from './flag_AX.svg';
import FlagAZ from './flag_AZ.svg';
import FlagBA from './flag_BA.svg';
import FlagBB from './flag_BB.svg';
import FlagBD from './flag_BD.svg';
import FlagBE from './flag_BE.svg';
import FlagBF from './flag_BF.svg';
import FlagBG from './flag_BG.svg';
import FlagBH from './flag_BH.svg';
import FlagBI from './flag_BI.svg';
import FlagBJ from './flag_BJ.svg';
import FlagBM from './flag_BM.svg';
import FlagBN from './flag_BN.svg';
import FlagBO from './flag_BO.svg';
import FlagBR from './flag_BR.svg';
import FlagBS from './flag_BS.svg';
import FlagBT from './flag_BT.svg';
import FlagBW from './flag_BW.svg';
import FlagBY from './flag_BY.svg';
import FlagBZ from './flag_BZ.svg';
import FlagCA from './flag_CA.svg';
import FlagCD from './flag_CD.svg';
import FlagCF from './flag_CF.svg';
import FlagCG from './flag_CG.svg';
import FlagCH from './flag_CH.svg';
import FlagCI from './flag_CI.svg';
import FlagCL from './flag_CL.svg';
import FlagCM from './flag_CM.svg';
import FlagCN from './flag_CN.svg';
import FlagCO from './flag_CO.svg';
import FlagCR from './flag_CR.svg';
import FlagCU from './flag_CU.svg';
import FlagCV from './flag_CV.svg';
import FlagCW from './flag_CW.svg';
import FlagCY from './flag_CY.svg';
import FlagCZ from './flag_CZ.svg';
import FlagDE from './flag_DE.svg';
import FlagDJ from './flag_DJ.svg';
import FlagDK from './flag_DK.svg';
import FlagDM from './flag_DM.svg';
import FlagDO from './flag_DO.svg';
import FlagDZ from './flag_DZ.svg';
import FlagEC from './flag_EC.svg';
import FlagEE from './flag_EE.svg';
import FlagEG from './flag_EG.svg';
import FlagER from './flag_ER.svg';
import FlagES from './flag_ES.svg';
import FlagET from './flag_ET.svg';
import FlagFI from './flag_FI.svg';
import FlagFJ from './flag_FJ.svg';
import FlagFR from './flag_FR.svg';
import FlagGA from './flag_GA.svg';
import FlagGB from './flag_GB.svg';
import FlagGD from './flag_GD.svg';
import FlagGE from './flag_GE.svg';
import FlagGH from './flag_GH.svg';
import FlagGM from './flag_GM.svg';
import FlagGN from './flag_GN.svg';
import FlagGQ from './flag_GQ.svg';
import FlagGR from './flag_GR.svg';
import FlagGT from './flag_GT.svg';
import FlagGU from './flag_GU.svg';
import FlagGW from './flag_GW.svg';
import FlagGY from './flag_GY.svg';
import FlagHK from './flag_HK.svg';
import FlagHN from './flag_HN.svg';
import FlagHR from './flag_HR.svg';
import FlagHT from './flag_HT.svg';
import FlagHU from './flag_HU.svg';
import FlagID from './flag_ID.svg';
import FlagIE from './flag_IE.svg';
import FlagIL from './flag_IL.svg';
import FlagIN from './flag_IN.svg';
import FlagIQ from './flag_IQ.svg';
import FlagIR from './flag_IR.svg';
import FlagIS from './flag_IS.svg';
import FlagIT from './flag_IT.svg';
import FlagJM from './flag_JM.svg';
import FlagJO from './flag_JO.svg';
import FlagJP from './flag_JP.svg';
import FlagKE from './flag_KE.svg';
import FlagKG from './flag_KG.svg';
import FlagKH from './flag_KH.svg';
import FlagKI from './flag_KI.svg';
import FlagKM from './flag_KM.svg';
import FlagKN from './flag_KN.svg';
import FlagKP from './flag_KP.svg';
import FlagKR from './flag_KR.svg';
import FlagKW from './flag_KW.svg';
import FlagKY from './flag_KY.svg';
import FlagKZ from './flag_KZ.svg';
import FlagLA from './flag_LA.svg';
import FlagLB from './flag_LB.svg';
import FlagLC from './flag_LC.svg';
import FlagLI from './flag_LI.svg';
import FlagLK from './flag_LK.svg';
import FlagLR from './flag_LR.svg';
import FlagLS from './flag_LS.svg';
import FlagLT from './flag_LT.svg';
import FlagLU from './flag_LU.svg';
import FlagLV from './flag_LV.svg';
import FlagLY from './flag_LY.svg';
import FlagMA from './flag_MA.svg';
import FlagMC from './flag_MC.svg';
import FlagMD from './flag_MD.svg';
import FlagME from './flag_ME.svg';
import FlagMF from './flag_MF.svg';
import FlagMG from './flag_MG.svg';
import FlagMK from './flag_MK.svg';
import FlagML from './flag_ML.svg';
import FlagMM from './flag_MM.svg';
import FlagMN from './flag_MN.svg';
import FlagMO from './flag_MO.svg';
import FlagMP from './flag_MP.svg';
import FlagMR from './flag_MR.svg';
import FlagMT from './flag_MT.svg';
import FlagMU from './flag_MU.svg';
import FlagMV from './flag_MV.svg';
import FlagMW from './flag_MW.svg';
import FlagMX from './flag_MX.svg';
import FlagMY from './flag_MY.svg';
import FlagMZ from './flag_MZ.svg';
import FlagNA from './flag_NA.svg';
import FlagNE from './flag_NE.svg';
import FlagNG from './flag_NG.svg';
import FlagNI from './flag_NI.svg';
import FlagNL from './flag_NL.svg';
import FlagNO from './flag_NO.svg';
import FlagNP from './flag_NP.svg';
import FlagNR from './flag_NR.svg';
import FlagNU from './flag_NU.svg';
import FlagNZ from './flag_NZ.svg';
import FlagOM from './flag_OM.svg';
import FlagPA from './flag_PA.svg';
import FlagPE from './flag_PE.svg';
import FlagPG from './flag_PG.svg';
import FlagPH from './flag_PH.svg';
import FlagPK from './flag_PK.svg';
import FlagPL from './flag_PL.svg';
import FlagPR from './flag_PR.svg';
import FlagPS from './flag_PS.svg';
import FlagPT from './flag_PT.svg';
import FlagPY from './flag_PY.svg';
import FlagQA from './flag_QA.svg';
import FlagRO from './flag_RO.svg';
import FlagRS from './flag_RS.svg';
import FlagRU from './flag_RU.svg';
import FlagRW from './flag_RW.svg';
import FlagSA from './flag_SA.svg';
import FlagSB from './flag_SB.svg';
import FlagSC from './flag_SC.svg';
import FlagSD from './flag_SD.svg';
import FlagSE from './flag_SE.svg';
import FlagSG from './flag_SG.svg';
import FlagSI from './flag_SI.svg';
import FlagSK from './flag_SK.svg';
import FlagSL from './flag_SL.svg';
import FlagSM from './flag_SM.svg';
import FlagSN from './flag_SN.svg';
import FlagSO from './flag_SO.svg';
import FlagSR from './flag_SR.svg';
import FlagSS from './flag_SS.svg';
import FlagST from './flag_ST.svg';
import FlagSV from './flag_SV.svg';
import FlagSY from './flag_SY.svg';
import FlagSZ from './flag_SZ.svg';
import FlagTC from './flag_TC.svg';
import FlagTD from './flag_TD.svg';
import FlagTG from './flag_TG.svg';
import FlagTH from './flag_TH.svg';
import FlagTJ from './flag_TJ.svg';
import FlagTL from './flag_TL.svg';
import FlagTM from './flag_TM.svg';
import FlagTN from './flag_TN.svg';
import FlagTO from './flag_TO.svg';
import FlagTR from './flag_TR.svg';
import FlagTT from './flag_TT.svg';
import FlagTV from './flag_TV.svg';
import FlagTW from './flag_TW.svg';
import FlagTZ from './flag_TZ.svg';
import FlagUA from './flag_UA.svg';
import FlagUG from './flag_UG.svg';
import FlagUS from './flag_US.svg';
import FlagUY from './flag_UY.svg';
import FlagUZ from './flag_UZ.svg';
import FlagVA from './flag_VA.svg';
import FlagVC from './flag_VC.svg';
import FlagVE from './flag_VE.svg';
import FlagVI from './flag_VI.svg';
import FlagVN from './flag_VN.svg';
import FlagVU from './flag_VU.svg';
import FlagWS from './flag_WS.svg';
import FlagXK from './flag_XK.svg';
import FlagYE from './flag_YE.svg';
import FlagYT from './flag_YT.svg';
import FlagZA from './flag_ZA.svg';
import FlagZM from './flag_ZM.svg';
import FlagZW from './flag_ZW.svg';

export const Flags = {
  FlagAD,
  FlagAE,
  FlagAF,
  FlagAG,
  FlagAI,
  FlagAL,
  FlagAM,
  FlagAO,
  FlagAR,
  FlagAS,
  FlagAT,
  FlagAU,
  FlagAW,
  FlagAX,
  FlagAZ,
  FlagBA,
  FlagBB,
  FlagBD,
  FlagBE,
  FlagBF,
  FlagBG,
  FlagBH,
  FlagBI,
  FlagBJ,
  FlagBM,
  FlagBN,
  FlagBO,
  FlagBR,
  FlagBS,
  FlagBT,
  FlagBW,
  FlagBY,
  FlagBZ,
  FlagCA,
  FlagCD,
  FlagCF,
  FlagCG,
  FlagCH,
  FlagCI,
  FlagCL,
  FlagCM,
  FlagCN,
  FlagCO,
  FlagCR,
  FlagCU,
  FlagCV,
  FlagCW,
  FlagCY,
  FlagCZ,
  FlagDE,
  FlagDJ,
  FlagDK,
  FlagDM,
  FlagDO,
  FlagDZ,
  FlagEC,
  FlagEE,
  FlagEG,
  FlagER,
  FlagES,
  FlagET,
  FlagFI,
  FlagFJ,
  FlagFR,
  FlagGA,
  FlagGB,
  FlagGD,
  FlagGE,
  FlagGH,
  FlagGM,
  FlagGN,
  FlagGQ,
  FlagGR,
  FlagGT,
  FlagGU,
  FlagGW,
  FlagGY,
  FlagHK,
  FlagHN,
  FlagHR,
  FlagHT,
  FlagHU,
  FlagID,
  FlagIE,
  FlagIL,
  FlagIN,
  FlagIQ,
  FlagIR,
  FlagIS,
  FlagIT,
  FlagJM,
  FlagJO,
  FlagJP,
  FlagKE,
  FlagKG,
  FlagKH,
  FlagKI,
  FlagKM,
  FlagKN,
  FlagKP,
  FlagKR,
  FlagKW,
  FlagKY,
  FlagKZ,
  FlagLA,
  FlagLB,
  FlagLC,
  FlagLI,
  FlagLK,
  FlagLR,
  FlagLS,
  FlagLT,
  FlagLU,
  FlagLV,
  FlagLY,
  FlagMA,
  FlagMC,
  FlagMD,
  FlagME,
  FlagMF,
  FlagMG,
  FlagMK,
  FlagML,
  FlagMM,
  FlagMN,
  FlagMO,
  FlagMP,
  FlagMR,
  FlagMT,
  FlagMU,
  FlagMV,
  FlagMW,
  FlagMX,
  FlagMY,
  FlagMZ,
  FlagNA,
  FlagNE,
  FlagNG,
  FlagNI,
  FlagNL,
  FlagNO,
  FlagNP,
  FlagNR,
  FlagNU,
  FlagNZ,
  FlagOM,
  FlagPA,
  FlagPE,
  FlagPG,
  FlagPH,
  FlagPK,
  FlagPL,
  FlagPR,
  FlagPS,
  FlagPT,
  FlagPY,
  FlagQA,
  FlagRO,
  FlagRS,
  FlagRU,
  FlagRW,
  FlagSA,
  FlagSB,
  FlagSC,
  FlagSD,
  FlagSE,
  FlagSG,
  FlagSI,
  FlagSK,
  FlagSL,
  FlagSM,
  FlagSN,
  FlagSO,
  FlagSR,
  FlagSS,
  FlagST,
  FlagSV,
  FlagSY,
  FlagSZ,
  FlagTC,
  FlagTD,
  FlagTG,
  FlagTH,
  FlagTJ,
  FlagTL,
  FlagTM,
  FlagTN,
  FlagTO,
  FlagTR,
  FlagTT,
  FlagTV,
  FlagTW,
  FlagTZ,
  FlagUA,
  FlagUG,
  FlagUS,
  FlagUY,
  FlagUZ,
  FlagVA,
  FlagVC,
  FlagVE,
  FlagVI,
  FlagVN,
  FlagVU,
  FlagWS,
  FlagXK,
  FlagYE,
  FlagYT,
  FlagZA,
  FlagZM,
  FlagZW,
};
