import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SyntaxHighlighter, SyntaxHighlighterLanguagesTypes } from 'apps/ui';
import { selectFlowBuilderChangeableFlow, selectFlowBuilderProductsInGraphModel } from 'apps/flowBuilder/store/FlowBuilder.selectors';
import { useProductsIssues } from 'apps/Product/hooks/ProductIssues.hook';
import { productManagerService } from 'apps/Product/services/ProductManager.service';
import { createCurlExample } from 'models/Integration.model';
import { IFlow } from 'models/Flow.model';
import { Loadable } from 'models/Loadable.model';
import { ProductTypes } from 'models/Product.model';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './CurlSnippet.styles';

export function CurlSnippet() {
  const classes = useStyles();
  const productsInGraphModel = useSelector<any, Loadable<ProductTypes[]>>(selectFlowBuilderProductsInGraphModel);
  const haveIssues: boolean = useProductsIssues(productsInGraphModel.value);
  const changeableFlow = useSelector<any, IFlow>(selectFlowBuilderChangeableFlow);
  const inputParams = useMemo(() => {
    const curlExampleProductInputParams = productsInGraphModel.value.map((productType) => ({ ...productManagerService.getProduct(productType)?.getCURLExampleParams(changeableFlow), productType }));
    return curlExampleProductInputParams.filter((item) => item);
  }, [changeableFlow, productsInGraphModel.value]);

  if (haveIssues) {
    return null;
  }

  return (
    <Grid container direction="column">
      <Box maxWidth="100%">
        <SyntaxHighlighter
          code={createCurlExample(inputParams)}
          language={SyntaxHighlighterLanguagesTypes.JavaScript}
          withCopyText
          className={classes.root}
          isBorder
        />
      </Box>
    </Grid>
  );
}
