import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useGovCheckData } from 'hooks/useGovCheckData';
import { GovernmentChecksStep } from 'models/GovernmentChecksMerit.model';
import { CheckBarExpandable, CheckStepDetails, CheckStepDetailsEntry } from 'apps/checks';
import { getCheckTitle } from '../../../../models/GovCheckMerit.model';

export const GovCheckSuccess = ({ step }: {step: GovernmentChecksStep}) => {
  const stepDataEntries = useGovCheckData(step, false, CheckStepDetailsEntry);
  const title = getCheckTitle(step);
  return (
    <CheckBarExpandable title={title} step={step}>
      <CheckStepDetails step={step}>
        <Grid container>
          {stepDataEntries}
        </Grid>
      </CheckStepDetails>
    </CheckBarExpandable>
  );
};
