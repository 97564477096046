import React from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { Alert } from 'apps/ui';
import { useStyles } from './BackgroundChecksError.styles';

export function BackgroundChecksError({ errorCode }: { errorCode: string }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Box>
      <Alert
        className={classes.manualButtonWrap}
        title={formatMessage(`BackgroundCheck.check.${errorCode}.message`, {
          defaultMessage: formatMessage('BackgroundCheck.check.verificationError'),
        })}
      />
    </Box>
  );
}
