import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { LineChart, CartesianGrid, YAxis, Line, ResponsiveContainer, Tooltip } from 'recharts';
import { useFormatMessage } from 'apps/intl';
import React from 'react';
import { useStyles } from './GovCheckEmploymentHistory.styles';

function TooltipContent({ payload }: {
  payload?: {
    payload: {
      employerName,
      avgBaseSalary,
    };
  }[];
}) {
  const classes = useStyles();
  const data = payload[0]?.payload;
  return (
    <Box className={classNames(classes.tooltip, 'fs-exclude')}>
      {data?.employerName}
      <Box component="span" className={classes.value} pl={1}>
        {data?.avgBaseSalary}
      </Box>
    </Box>
  );
}

export function GovCheckEmploymentHistory({ data }: {
  data: {
    activeJob: boolean;
    avgBaseSalary: number;
    contributionBaseSalary: number;
    contributionBaseSalaryDuration: number;
    dischargeDate: string;
    employerId: string;
    employerName: string;
    employerState: string;
    jobDurationDays: number;
    maxBaseSalary: number;
    minBaseSalary: number;
    registrationDate: string;
    salaryDifference: number;
    salaryMovements: number;
  }[];
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  if (!Array.isArray(data)) {
    return null;
  }

  return (
    <Box mt={2}>

      <Box
        pb={2}
        className={classes.title}
      >
        {formatMessage('identity.field.salaryOverTime')}
      </Box>
      <Box mb={2}>
        <ResponsiveContainer width="100%" height={300} className="fs-exclude">
          <LineChart data={data}>
            <YAxis axisLine={false} orientation="right" />
            <CartesianGrid strokeDasharray="5 5" />
            <Tooltip content={<TooltipContent />} />
            <Line type="monotone" dataKey="avgBaseSalary" stroke="#fe7581" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box
        pb={2}
        className={classes.title}
      >
        {formatMessage('identity.field.emplymentHistory')}
      </Box>
      {data.map(({ employerName, ...fields }, index) => (
        <Box key={index}>
          <div className={classes.item}>
            <div className={classes.line}>
              <div className={classes.dot} />
            </div>
            <div className={classes.data}>
              <Box
                className={classNames(classes.companyName, 'fs-exclude')}
              >
                {employerName}
              </Box>
              <Box pl={2}>
                <Grid container>
                  {Object.entries(fields).map(([key, value]) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={key}
                    >
                      <Box className={classNames(classes.value, 'fs-exclude')}>
                        {(typeof value === 'boolean') ? value.toString() : value}
                      </Box>
                      <Box
                        className={classes.label}
                        pb={1}
                        pt={0.5}
                      >
                        {formatMessage(`identity.field.${key}`, { defaultMessage: key })}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </div>
          </div>
        </Box>
      ))}
    </Box>
  );
}
