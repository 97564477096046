import { isProduction } from 'models/Environment.model';
import { DEFAULT_LOCALE, SupportedLocales } from 'models/Intl.model';
import React, { useState, useEffect, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'state/merchant/merchant.selectors';
import { getTranslations } from '../../client/translations';
import baseLocale from '../../locale/en.json';
import baseRegions from '../../locale/en_regions.json';

const baseMessages = {
  ...baseLocale,
  ...baseRegions,
};

export function AppIntlProvider({ children }) {
  const [translations, setTranslations] = useState({});
  const currentLang = useSelector<any, SupportedLocales>(selectLanguage);
  const loadTranslation = useCallback(async (locale: SupportedLocales) => {
    try {
      const messages = await getTranslations(locale);
      let messagesMergedWithBase = {
        ...baseLocale,
        ...messages,
        ...baseRegions,
      };
      if (!isProduction && locale === DEFAULT_LOCALE) {
        // For local or stage environment keep local version of en.json file at higher priority than the version from Localize
        // To get changes from Localize run build:translations script
        messagesMergedWithBase = { ...messages, ...baseLocale, ...baseRegions };
      }
      setTranslations({
        ...translations,
        [locale]: messagesMergedWithBase,
      });
    } catch (error) {
      console.error(`couldn't load ${locale} translations`);
      console.error(error);
    }
  }, [translations]);

  useEffect(() => {
    if (!translations[currentLang]) {
      loadTranslation(currentLang);
    }
  }, [translations, currentLang, loadTranslation]);

  return (
    <IntlProvider
      locale={currentLang}
      defaultLocale={DEFAULT_LOCALE}
      messages={translations[currentLang] || baseMessages}
    >
      {children}
    </IntlProvider>
  );
}
