import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { ICustomField } from 'models/CustomField.model';
import { useStyles } from './CustomFieldActions.styles';
import { CustomFieldModalTypes, EMPTY_PAGE_SEPARATOR, HandleOpenModal, HandleUpdateFields } from '../../models/CustomField.model';
import { selectCustomFieldListFields } from '../../state/CustomField.selectors';

export function CustomFieldActions({ handleUpdateFields, handleOpenModal, disabled }: {
  handleOpenModal: HandleOpenModal;
  handleUpdateFields: HandleUpdateFields;
  disabled: boolean;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const listFields = useSelector<any, ICustomField[]>(selectCustomFieldListFields);

  const handleAddPageSeparator = () => {
    handleUpdateFields([...listFields, { ...EMPTY_PAGE_SEPARATOR, name: `SEPARATOR${Date.now()}`, label: `SEPARATOR${Date.now()}` }]);
  };

  return (
    <Box>
      <Button
        fullWidth
        className={classes.actionsButton}
        disabled={disabled}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleOpenModal(CustomFieldModalTypes.ConfigureField)}
      >
        {formatMessage('CustomField.settings.CustomFieldList.addField')}
      </Button>
      <Button
        fullWidth
        className={classes.actionsButton}
        disabled={disabled}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleOpenModal(CustomFieldModalTypes.ConfigureGroup)}
      >
        {formatMessage('CustomField.settings.CustomFieldList.addGroup')}
      </Button>
      <Button
        fullWidth
        className={classes.actionsButton}
        disabled={disabled}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleOpenModal(CustomFieldModalTypes.ConfigureSelection)}
      >
        {formatMessage('CustomField.settings.CustomFieldList.addSelection')}
      </Button>
      <Button
        fullWidth
        className={classes.actionsButton}
        disabled={disabled}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleAddPageSeparator}
      >
        {formatMessage('CustomField.settings.CustomFieldList.addPageSeparator')}
      </Button>
      {!!listFields.length && (
        <Button
          fullWidth
          className={classes.actionsButton}
          disabled={disabled}
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleOpenModal(CustomFieldModalTypes.PreviewCustomField)}
        >
          {formatMessage('CustomField.settings.CustomFieldList.preview')}
        </Button>
      )}
    </Box>
  );
}
