import { http } from 'lib/client/http';
import { IFlow } from 'models/Flow.model';
import { ITemplate, ITemplateMetadata, ITemplatesList } from '../model/Templates.model';

export function createTemplateRequest(name: string, description: string, metadata: ITemplateMetadata[], flow: IFlow, isAvailableToFreemium: boolean) {
  return http.post<ITemplate>('/api/v1/dashboard/template', { metadata, name, description, flow, isAvailableToFreemium });
}

export function getMetadataRequest() {
  return http.get<ITemplateMetadata[]>('/api/v1/dashboard/template/metadata/list');
}

export function getTemplateRequest(id: string) {
  return http.get<ITemplate>(`/api/v1/dashboard/template/${id}`);
}

export function updateTemplateRequest({ id, name, description, flow, metadata, isAvailableToFreemium }: { id: string; name?: string; description?: string; metadata?: ITemplateMetadata[]; flow?: IFlow; isAvailableToFreemium?: boolean }) {
  return http.patch<ITemplate>(`/api/v1/dashboard/template/${id}`, { metadata, name, description, flow, isAvailableToFreemium });
}

export function changeTemplatesOrder(ids: string[]) {
  return http.patch<undefined>('/api/v1/dashboard/template/order', { ids });
}

export function blockTemplateRequest(id: string) {
  return http.delete<ITemplate>(`/api/v1/dashboard/template/${id}`);
}

export function toggleTemplateRequest(id: string, toggle: string) {
  return http.patch<ITemplate>(`/api/v1/dashboard/template/${id}/${toggle}`);
}

export function getTemplatesListRequest() {
  return http.get<ITemplatesList>('/api/v1/dashboard/template/list', { params: { pageSize: 100 } });
}
