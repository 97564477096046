import React, { ComponentType } from 'react';
import has from 'lodash/has';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lib/isEmpty';
import { GovernmentChecksDisplayOptions, GovernmentChecksStep } from 'models/GovernmentChecksMerit.model';

export const useGovCheckData = (step: GovernmentChecksStep, isPDF: boolean, DetailsComponent: ComponentType<{
  label: string;
  value: any;
  isMarkedAsFailed?: boolean;
  isCentered?: boolean;
}>, customPdfComponents?: Record<string, Record<string, any>>) => {
  let stepDataEntries = [];
  let data = { ...step.data };
  if (step.data) {
    const id = step.data?.subStepId || step.id;
    const displayOption = GovernmentChecksDisplayOptions[id] || {};

    stepDataEntries = Object.keys(displayOption).map((entry) => {
      if (displayOption[entry]?.formatter) {
        data = displayOption[entry].formatter(data[entry], data);
      }

      const value = data[entry];
      const dependentFieldForFailedCheck = data[displayOption[entry]?.dependentFieldForFailedCheck];
      delete data[entry];
      if (displayOption[entry].hidden || (displayOption[entry].hiddenIfNotExists && isEmpty(value))) {
        return null;
      }

      if (has(displayOption[entry], 'hideIsField') && displayOption[entry].hideIsField === step.data[displayOption[entry].filedCondition]) {
        return null;
      }

      const props = {
        isCentered: displayOption[entry].isCentered,
        label: entry,
        value: !isEmpty(value) ? value : '—',
        key: entry,
        isMarkedAsFailed: !!displayOption[entry].dependentFieldForFailedCheck && dependentFieldForFailedCheck !== true,
      };

      const CustomPDFComponent = customPdfComponents?.[id]?.[entry];
      if (isPDF && CustomPDFComponent) {
        return <CustomPDFComponent data={value} />;
      }

      const CustomComponent = displayOption[entry].component;
      if (CustomComponent) {
        return isPDF ? null : <CustomComponent data={value} />;
      }

      if (Array.isArray(value)) {
        return (
          <>
            {value.map((val, index) => {
              if (isPDF) {
                return <DetailsComponent key={index} {...props} value={val} />;
              }
              return (
                <Grid key={index} xs={displayOption[entry].inline ? 6 : 12} item>
                  <DetailsComponent
                    {...props}
                    value={val}
                  />
                </Grid>
              );
            })}
          </>
        );
      }

      return (
        isPDF ? (
          <DetailsComponent {...props} />
        ) : (
          <Grid xs={displayOption[entry].inline ? 6 : 12} item>
            <DetailsComponent {...props} />
          </Grid>
        )
      );
    });
    stepDataEntries = stepDataEntries.concat(Object.entries(data).map(([key, value]) => {
      const props = {
        label: key,
        value: !isEmpty(value) ? value : '—',
        key,
      };
      return <DetailsComponent {...props} />;
    }));
  }

  return stepDataEntries;
};
