import { makeStyles } from '@material-ui/core/styles';

export const useModalStyles = makeStyles((theme) => ({
  modal: {
    width: 400,
  },
  modalTitle: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  modalSubTitle: {
    maxWidth: 'initial',
    textAlign: 'left',
    color: theme.palette.text.main,
  },
  content: {
    height: 340,
    width: '100%',
    overflow: 'auto',
    paddingLeft: 2,
    padding: 0,
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
      marginRight: -50,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.black7,
      borderRadius: 10,
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
    '& button': {
      '&:first-child': {
        textDecoration: 'underline',
        fontSize: 16,
      },
      '&:last-child': {
        width: 140,
        height: 52,
        fontSize: 16,
      },
    },
  },
}));

export const useStyles = makeStyles((theme) => ({
  marginTop14: {
    marginTop: 14,
  },
  subTitle: {
    maxWidth: 'initial',
    textAlign: 'left',
    color: theme.palette.text.main,
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    gap: 5,
  },
  blockItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& > .MuiButtonBase-root': {
      minHeight: 0,
    },
  },
  collapsibleItem: {
    marginTop: 14,
    display: 'flex',
    gap: 20,
    marginBottom: 28,
  },
  blockItemDivider: {
    width: 300,
    justifyContent: 'flex-end',
  },
  itemLabel: {
    color: theme.palette.common.black90,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
}));
