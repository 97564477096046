import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 50,
    height: 50,
    borderRadius: '50%',
    fontSize: 20,
    color: theme.palette.common.lightblue,
    backgroundColor: theme.palette.common.whiteblue,
    strokeWidth: 1.5,
  },
}));
