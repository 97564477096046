import { createSelector } from 'reselect';
import { Loadable } from 'models/Loadable.model';
import { IBankAccountDataStore, SliceNamesTypes } from './BankAccountData.store';
import { BANK_ACCOUNT_DATA_STORE_KEY } from './BankAccountData.reducer';

export const selectBankAccountDataState = (state: {BANK_ACCOUNT_DATA_STORE_KEY: IBankAccountDataStore}): IBankAccountDataStore => state[BANK_ACCOUNT_DATA_STORE_KEY];

export const selectGeneratedExcel = createSelector<[typeof selectBankAccountDataState], Loadable<string>>(
  selectBankAccountDataState,
  (store) => store[SliceNamesTypes.ExcelFile],
);
