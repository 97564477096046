import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export const TextFieldSearch = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: 14,
        '&::placeholder': {
          color: theme.palette.common.black75,
          fontStyle: 'italic',
        },
      },
      '&.Mui-focused fieldset': {
        borderWidth: 1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: `1px solid ${theme.palette.common.black7}`,
      },
    },
  },
}))(TextField);

export const InputAdornmentSearch = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}))(InputAdornment);

export const IconButtonSearch = withStyles((theme) => ({
  root: {
    fontSize: 17,
    color: theme.palette.common.black50,
  },
}))(IconButton);
