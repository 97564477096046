import { createSelector } from 'reselect';
import { filterSerializeGlobalSearch, IFilter } from 'models/Filter.model';
import { IVerificationListItem } from 'models/VerificationOld.model';
import { Loadable } from 'models/Loadable.model';
import { IGlobalFilter, IIdentityFilter } from 'models/Identity.model';
import { Serializable } from 'lib/object';
import { IDENTITIES_STORE_KEY, SliceNameTypes } from './identities.store';
import { IdentitiesState } from './identities.reducer';

export const selectIdentityStore = (state: {IDENTITIES_STORE_KEY: IdentitiesState}): IdentitiesState => state[IDENTITIES_STORE_KEY];

export const selectIdentityCollection = createSelector<[typeof selectIdentityStore], Loadable<IVerificationListItem[]>>(
  selectIdentityStore,
  (store) => store[SliceNameTypes.IdentityList],
);

export const selectFilteredCountModel = createSelector<[typeof selectIdentityStore], Loadable<number>>(
  selectIdentityStore,
  (store) => store[SliceNameTypes.FilteredCount],
);

export const selectIdentityFilter = createSelector<[typeof selectIdentityStore], IIdentityFilter>(
  selectIdentityStore,
  (store) => store.filter,
);

export const selectIdentityFilterForGlobalSearch = createSelector<[typeof selectIdentityFilter], IGlobalFilter>(
  selectIdentityFilter,
  ({ name, email, verificationId, identityId, metadata, documentNumber }) => ({ name, email, verificationId, identityId, metadata, documentNumber }),
);

export const selectIdentityFilterSerialized = createSelector<[typeof selectIdentityStore], Serializable<IFilter>>(
  selectIdentityStore,
  (store) => filterSerializeGlobalSearch(store.filter),
);

// identity
export const selectIdentityModel = createSelector(
  selectIdentityStore,
  (store) => store[SliceNameTypes.Identity],
);
