import React from 'react';
import { TooltipFollowingCursor } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { PermissionSubscriptionStatusTypes } from 'apps/Permissions/models/PermissionsSubscriptionStatus.model';
import { useHasPermissionSubscription } from '../../hooks/hasPermissionSubscription.hook';

export function LimiterSubscriptionStatus({ children, permission, classNameRoot }: {
  children: React.ReactElement;
  permission: PermissionSubscriptionStatusTypes;
  classNameRoot?: string;
}) {
  const permissionGranted = useHasPermissionSubscription(permission);
  const formatMessage = useFormatMessage();

  return (
    <TooltipFollowingCursor title={formatMessage('SubscriptionStatus.tooltip.activateAccount')} open={permissionGranted ? false : undefined}>
      {/* A disabled element does not fire events.
            Tooltip needs to listen to the child element's events to display the title. */}
      <span className={classNameRoot}>
        {React.cloneElement(children, { disabled: !permissionGranted })}
      </span>
    </TooltipFollowingCursor>
  );
}
