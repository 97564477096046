import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: theme.palette.background.default,
    padding: '4px 8px',
    boxShadow: theme.shadows[1],
  },
  title: {
    fontSize: '24px',
    color: theme.palette.text.main,
  },
  companyName: {
    padding: '8px 16px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    background: theme.palette.foreground.main,
    borderRadius: '4px',
    marginBottom: '16px',
  },
  value: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  label: {
    color: theme.palette.text.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
    position: 'relative',
    paddingLeft: '8px',
    marginRight: '16px',
  },
  dot: {
    height: '11px',
    width: '11px',
    borderRadius: '6px',
    background: theme.palette.background.default,
    border: `3px solid ${theme.palette.primary.main}`,
    position: 'absolute',
    left: '4px',
    top: '22px',
  },
  data: {
    flex: 1,
    border: `1px solid ${theme.palette.divider}`,
    padding: '8px',
    borderRadius: '4px',
    marginBottom: '8px',
  },
}));
