export const Routes = {
  root: '/',
  auth: {
    emailVerification: '/auth/email-verification',
    root: '/auth',
    signIn: '/auth/signin',
  },
  freemiumSignUp: {
    root: '/freemiumsignup',
  },
  freemiumSetUp: {
    root: '/freemiumsetup',
  },
  freeTrial: {
    root: '/freetrial',
  },
  signUp: {
    root: '/signup',
  },
  analytics: { root: '/analytics' },
  list: {
    root: '/identities',
    history: {
      root: '/history',
      details: '/identities/:id/history',
    },
  },
  verificationDetailsPdf: {
    root: '/pdf/identity/:identityId/verification/:verificationId',
  },
  identity: {
    profile: {
      root: '/identity',
      details: {
        root: '/identity/:identityId',
        verificationDetails: '/identity/:identityId/verification/:verificationId',
        verificationWebhooks: '/identity/:identityId/verification/:verificationId/webhooks',
      },
    },
    verification: {
      root: '/verification',
      customer: '/verification/profile',
    },
  },
  flow: {
    root: '/flow',
    details: '/flow/:id',
    history: '/flow/:id/history',
  },
  freemiumVsPreemium: {
    root: '/freemium-vs-premium',
    details: '/freemium-vs-premium/details',
  },
  templates: {
    root: '/templates',
    newTemplate: '/new_template',
    details: '/templates/:id',
    draftFlow: '/draft',
    draftFlowExisting: '/draft/:id',
  },
  dev: { root: '/dev' },
  settings: {
    root: '/settings',
  },
  collaborators: {
    agentProfile: {
      root: '/agentHistory',
      details: '/agentHistory/:id',
    },
  },
  info: { root: '/info' },
  review: { root: '/review' },
  automations: { root: '/automations' },
};
