import { HttpClient } from './HttpCilent';

export const http = new HttpClient({
  baseURL: process.env.REACT_APP_API_URL,
});

export const httpVPN = new HttpClient({
  baseURL: process.env.REACT_APP_API_VPN || process.env.REACT_APP_API_URL,
});

export const httpNew = new HttpClient({
  baseURL: process.env.REACT_APP_NEW_API_URL,
}, true);

export const httpNewVpn = new HttpClient({
  baseURL: process.env.REACT_APP_API_VPN || process.env.REACT_APP_NEW_API_URL,
}, true);
