import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  bordered: {
    border: `1px solid ${theme.palette.common.black50}`,
    borderRadius: 5,
  },
  content: {
    maxHeight: 227,
    overflow: 'scroll',
    padding: '0 1rem',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(237, 240, 245, .2)',
      borderRadius: 10,
    },
  },
  disabled: {
    color: theme.palette.common.black75,
  },
}));
