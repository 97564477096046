import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '4px',
    width: '100%',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
  },
  dataBaseName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    color: theme.palette.common.black90,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '130%',
  },
  tag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 6px',
    height: '20px',
    border: `1px solid ${theme.palette.common.lightblue}`,
    color: theme.palette.common.lightblue,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14px',
    borderRadius: '9999px',
    whiteSpace: 'nowrap',
  },
  btn: {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px 5px',
    minHeight: '24px !important',
    borderRadius: '9999px',
  },
  addBtn: {
    background: theme.palette.common.green,
    color: theme.palette.common.white,
    minWidth: '82px !important',
    '&:hover': {
      backgroundColor: theme.palette.common.green,
      borderColor: theme.palette.common.green,
      boxShadow: 'none',
    },
  },
  deleteBtn: {
    background: theme.palette.common.lightRed,
    color: theme.palette.common.red,
    minWidth: '82px !important',
    '&:hover': {
      backgroundColor: theme.palette.common.lightRed,
      borderColor: theme.palette.common.lightRed,
      boxShadow: 'none',
    },
  },
  requestBtn: {
    background: theme.palette.common.lightyellow,
    color: theme.palette.common.yellow2,
    minWidth: '82px !important',
    '&:hover': {
      backgroundColor: theme.palette.common.lightyellow,
      borderColor: theme.palette.common.lightyellow,
      boxShadow: 'none',
    },
  },
}));
