import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { MexicanBuholegalCheck } from 'models/BackgroundCheck.model';
import { RecordView } from 'apps/ui';
import { useStyles } from './MexicanRecordViewFailed.styles';
import { RecordFailedDetails } from '../RecordFailedDetails/RecordFailedDetails';

export const MexicanRecordViewFailed = ({
  state,
  records,
}: { state: string; records?: MexicanBuholegalCheck['records'] }) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.state'),
        value: state,
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.failed'),
        valueClass: classes.failedLabel,
        containerClass: classes.statusCell,
      }}
      details={records.map((record, idx) => <RecordFailedDetails key={idx} record={record} />)}
    />
  );
};
