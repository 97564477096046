import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { AppTheme } from 'apps/theme';
import { ButtonHeaderMenu } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { Modal, useOverlay } from 'apps/overlay';
import { selectVerification } from 'apps/Verification';
import { formatCurrency } from 'lib/currency';
import { DateFormatTypes, dateSortCompare } from 'lib/date';
import { useQuery } from 'lib/url';
import { QATags } from 'models/QA.model';
import { Loadable } from 'models/Loadable.model';
import { IdentityStatuses } from 'models/Status.model';
import { BankAccountSourceTypes, BankAccountStepErrorTypes, BankAccountTransactionCategoryTypes, BankAccountTransactionTypes, IBalancesChartData, IBankAccountDataVerification, BankAccountErrorBannerTypes } from '../../models/BankAccountData.model';
import { BankAccountVerificationChecks } from '../BankAccountVerificationChecks/BankAccountVerificationChecks';
import { BankAccountTransactionTable } from '../BankAccountTransactionTable/BankAccountTransactionTable';
import { BankAccountMonthwiseChart } from '../BankAccountMonthwiseChart/BankAccountMonthwiseChart';
import { BankAccountBalancesChart } from '../BankAccountBalancesChart/BankAccountBalancesChart';
import { BankAccountCashFlowChart } from '../BankAccountCashFlowChart/BankAccountCashFlowChart';
import { BankAccountStatements } from '../BankAccountStatements/BankAccountStatements';
import { bankAccountGenerateExcelReport } from '../../state/BankAccountData.actions';
import { selectGeneratedExcel } from '../../state/BankAccountData.selectors';
import { useStyles } from './BankAccountDataVerification.styles';
import { VerificationError } from '../../assets';

export function BankAccountDataVerification({ data }: {
  data: IBankAccountDataVerification;
}) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();
  const verification = useSelector(selectVerification);
  const excelResponse = useSelector<any, Loadable<string>>(selectGeneratedExcel);
  const [cashFlowActiveTab, switchCashFlowActiveTab] = useState<BankAccountTransactionTypes>(BankAccountTransactionTypes.CREDIT);
  const { asMerchantId } = useQuery();

  useEffect(() => {
    if (!!excelResponse && excelResponse.isFailed) {
      createOverlay(
        <Modal className={classes.excelErrorDialog}>
          <Box display="flex" flexDirection="column" width="500">
            <Typography variant="h3" className={classes.excelErrorTitle}>
              {formatMessage('BankAccountData.excelReport.errorDialog.title')}
            </Typography>
            <Typography variant="subtitle2" className={classes.excelErrorContent}>
              {formatMessage('BankAccountData.excelReport.errorDialog.content')}
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" className={classes.excelErrorOkay} onClick={closeOverlay}>{formatMessage('okay')}</Button>
            </Box>
          </Box>
        </Modal>,
      );
    }
  }, [excelResponse]);

  const consolidatedTransactions = data?.accounts?.filter((acc) => acc.transactions !== null)
    .map((acc) => acc.transactions)
    .flat()
    .sort((a, b) => dateSortCompare(a?.date, b?.date));

  const summedAmount = (type: BankAccountTransactionTypes, category?: BankAccountTransactionCategoryTypes): number => {
    if (!consolidatedTransactions) {
      return 0;
    }

    return consolidatedTransactions
      .filter((transaction) => !category || transaction?.category === category)
      .reduce((acc, transaction) => {
        if (transaction?.type !== type) {
          return acc;
        }
        return acc + parseFloat(transaction?.amount);
      }, 0);
  };

  const balancesChartData = useMemo<IBalancesChartData[]>(() => {
    if (!data?.accounts) {
      return [];
    }

    const liveAccounts = data?.accounts.filter((account) => account.transactions !== null);
    if (!liveAccounts || liveAccounts?.length === 0) {
      return [{ timePeriod: '', balance: 0 }];
    }

    // array for the line chart data
    const balancesData = [];

    // array for the last 12 months (if possible) from all accounts
    const lastTwelveMonthsOfAllAccounts = [];

    // get the recent 12 months from each account, and push it as a subarray
    liveAccounts.forEach((account) => {
      const lastTwelveMonthsOfAccount = Array.from(
        new Set(account.transactions
          .sort((a, b) => dateSortCompare(a?.date, b?.date))
          .map((tx) => dayjs(tx.date)?.endOf('month')
            .format(DateFormatTypes.DateShortStroke))),
      )
        .slice(0, 12);
      lastTwelveMonthsOfAllAccounts.push(lastTwelveMonthsOfAccount);
    });

    // from the multiple accounts, determine a unique set of the latest 12 months
    const lastTwelveMonths = Array.from(new Set(lastTwelveMonthsOfAllAccounts.flat()))
      .sort((a, b) => dateSortCompare(a, b))
      .slice(0, 12);

    // for each month, get the closing balance (the very last transaction of the month) from each account, and then add them up to get the cumulative closing balance
    lastTwelveMonths.reverse().forEach((monthYear) => {
      let closingBalanceOfMonth = 0;
      liveAccounts.forEach((account) => {
        // get all transactions corresponding to the month
        const transactionsOfMonth = account.transactions
          .map((transaction) => ({
            ...transaction,
            formattedDate: dayjs(transaction.date).endOf('month')
              .format(DateFormatTypes.DateShortStroke),
          }))
          .filter((transaction) => transaction.formattedDate === monthYear);

        closingBalanceOfMonth += parseFloat(transactionsOfMonth[0]?.balance || '0.00');
      });

      // to format the data in { timePeriod: 'Mar 22', balance: 70000 } format, so that chart can easily consume data
      balancesData.push({
        timePeriod: dayjs(monthYear).format(DateFormatTypes.MonthYearVeryShort),
        balance: closingBalanceOfMonth,
      });
    });

    const averageBalance = parseFloat(data?.consolidatedAccounts?.balance?.avgMonthly || '0.00');

    return balancesData.map((balanceData) => ({ ...balanceData, averageBalance }));
  }, [data?.accounts]);

  const renderErrorBannerText = (textType: string) => {
    let errorCategory;
    if (data?.error?.message === BankAccountStepErrorTypes.MetadataFailed) {
      errorCategory = BankAccountErrorBannerTypes.UnsupportedInvalid;
    } else if (data?.error?.message === BankAccountStepErrorTypes.MetadataReviewRequired) {
      errorCategory = BankAccountErrorBannerTypes.PotentialError;
    } else if (data?.statements?.length) {
      errorCategory = BankAccountErrorBannerTypes.Unsupported;
    } else {
      errorCategory = BankAccountErrorBannerTypes.UnsupportedNotUploaded;
    }

    if (!errorCategory || !textType) {
      return null;
    }
    return formatMessage(`BankAccountData.error.${errorCategory}.${textType}`, {
      messageValues: {
        bankName: data?.institution?.name,
        verificationStatus: formatMessage(`statuses.${data?.verificationStatus}`),
      },
    });
  };

  const getTotalMonthlyData = (key: Nullable<Record<string, string>>) => Math.abs(Object.values(key || {}).reduce((acc, val) => acc + parseFloat(val || '0'), 0));

  const downloadExcelReport = () => {
    dispatch<any>(bankAccountGenerateExcelReport(verification?._id, asMerchantId, formatMessage('BankAccountData.excelReport.filename', { messageValues: { verificationId: verification?._id } })));
  };

  return (
    <Grid container spacing={1}>
      {/* Excel Report Button */}
      {!!data?.accounts && data?.statements?.length > 0 && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <ButtonHeaderMenu
              variant="contained"
              onClick={downloadExcelReport}
              startIcon={<SiMicrosoftexcel />}
              className={classes.excelButton}
              data-qa={QATags.BankAccountVerification.Buttons.ExcelReport}
            >
              {formatMessage('BankAccountData.excelReport.button')}
            </ButtonHeaderMenu>
          </Box>
        </Grid>
      )}
      {[IdentityStatuses.reviewNeeded].includes(data?.verificationStatus) && data?.error?.message === BankAccountStepErrorTypes.MetadataReviewRequired && (
      <Grid xs={12} item>
        <Box bgcolor={AppTheme.palette.common.cornSilk} padding={1.2} borderRadius={6} display="flex">
          <Box><VerificationError /></Box>
          <Box ml={1.7}>
            <Typography variant="h4" className={classes.errorTitle} gutterBottom>
              {renderErrorBannerText('title')}
            </Typography>
            <Typography variant="subtitle2" className={classes.errorSubtitle}>
              {renderErrorBannerText('subtitle')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      )}
      {/* Basic Details */}
      {data?.identity && (
        <Grid xs={12} md={4} item>
          <Box className={classes.boxContainer}>
            <Typography variant="subtitle2" className={classes.boxTitle}>
              {formatMessage('BankAccountData.basicDetails.title')}
            </Typography>
            <Divider light className={classes.divider} />
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.basicDetails.userName')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {data?.identity?.name || '-'}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.basicDetails.phone')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {data?.identity?.phone || '-'}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.basicDetails.email')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {data?.identity?.email || '-'}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.basicDetails.address')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {data?.identity?.address?.retrievedAddress || '-'}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.basicDetails.taxpayerId')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {data?.identity?.taxpayerId || '-'}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      {/* Account Details */}
      <Grid xs={12} md={4} item>
        <Box className={classes.boxContainer}>
          <Typography variant="subtitle2" className={classes.boxTitle}>
            {formatMessage('BankAccountData.accountDetails.title')}
          </Typography>
          <Divider light className={classes.divider} />
          <Box className={classes.staticDataItem}>
            <Typography variant="subtitle2" className={classes.detailLabel}>
              {formatMessage('BankAccountData.accountDetails.bankName')}
            </Typography>
            <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
              {data?.institution?.name || '-'}
            </Typography>
          </Box>
          {data?.accounts && (
            <>
              <Box className={classes.staticDataItem}>
                <Typography variant="subtitle2" className={classes.detailLabel}>
                  {formatMessage(`BankAccountData.accountDetails.${data?.accounts[0]?.clabeNumber ? 'clabeNumber' : 'accountNumber'}`)}
                </Typography>
                <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                  {data?.accounts[0]?.clabeNumber || data?.accounts[0]?.number || '-'}
                </Typography>
              </Box>
              <Box className={classes.staticDataItem}>
                <Typography variant="subtitle2" className={classes.detailLabel}>
                  {formatMessage('BankAccountData.accountDetails.accountType')}
                </Typography>
                <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                  {data?.accounts[0]?.name || '-'}
                </Typography>
              </Box>
              <Box className={classes.staticDataItem}>
                <Typography variant="subtitle2" className={classes.detailLabel}>
                  {formatMessage('BankAccountData.accountDetails.availableBalance')}
                </Typography>
                <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                  {formatCurrency(data?.accounts[0]?.availableBalance, data?.accounts[0]?.currency)}
                </Typography>
              </Box>
              <Box className={classes.staticDataItem}>
                <Typography variant="subtitle2" className={classes.detailLabel}>
                  {formatMessage(`BankAccountData.accountDetails.${data?.source === BankAccountSourceTypes.Statements ? 'statementIssuanceDate' : 'verificationDate'}`)}
                </Typography>
                <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                  {data?.accounts[0]?.balance?.date || data?.statements?.sort((a, b) => dateSortCompare(a?.endDate, b?.endDate))[0]?.endDate || '-'}
                </Typography>
              </Box>
              <Box className={classes.staticDataItem}>
                <Typography variant="subtitle2" className={classes.detailLabel}>
                  {formatMessage('BankAccountData.accountDetails.source')}
                </Typography>
                <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                  {formatMessage(data?.source === BankAccountSourceTypes.Login ? 'BankAccountData.accountDetails.source.login' : 'BankAccountData.accountDetails.source.statement')}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Grid>
      {/* Error Banner */}
      {(!data?.accounts && [IdentityStatuses.reviewNeeded, IdentityStatuses.rejected].includes(data?.verificationStatus)) && (
        <Grid xs={12} md={8} item>
          <Box bgcolor={AppTheme.palette.common.cornSilk} padding={1.2} borderRadius={6} display="flex">
            <Box><VerificationError /></Box>
            <Box ml={1.7}>
              <Typography variant="h4" className={classes.errorTitle} gutterBottom>
                {renderErrorBannerText('title')}
              </Typography>
              <Typography variant="subtitle2" className={classes.errorSubtitle}>
                {renderErrorBannerText('subtitle')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      {/* Insights */}
      {data?.accounts && (
        <Grid xs={12} md={4} item>
          <Box className={classes.boxContainer}>
            <Typography variant="subtitle2" className={classes.boxTitle}>
              {formatMessage('BankAccountData.insights.title')}
            </Typography>
            <Divider light className={classes.divider} />
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.insights.income')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {formatCurrency(getTotalMonthlyData(data?.consolidatedAccounts?.income?.totalMonthly), data?.accounts[0]?.currency)}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.insights.expenses')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {formatCurrency(getTotalMonthlyData(data?.consolidatedAccounts?.expense?.totalMonthly), data?.accounts[0]?.currency)}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.insights.loanRepayment')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {formatCurrency(getTotalMonthlyData(data?.consolidatedAccounts?.loanRepayment?.totalMonthly), data?.accounts[0]?.currency)}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.insights.totalWithdrawal')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {formatCurrency(summedAmount(BankAccountTransactionTypes.DEBIT), data?.accounts[0]?.currency)}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.insights.totalDeposit')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {formatCurrency(summedAmount(BankAccountTransactionTypes.CREDIT), data?.accounts[0]?.currency)}
              </Typography>
            </Box>
            <Box className={classes.staticDataItem}>
              <Typography variant="subtitle2" className={classes.detailLabel}>
                {formatMessage('BankAccountData.insights.averageBalance')}
              </Typography>
              <Typography variant="subtitle2" className={classnames(classes.detailValue, 'fs-exclude')}>
                {formatCurrency(data?.consolidatedAccounts?.balance?.avgMonthly, data?.accounts[0]?.currency)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      {/* Checks */}
      {data?.checks && (
        <Grid xs={12} lg={6} item>
          <Box className={classes.boxContainer}>
            <Typography variant="subtitle2" className={classes.boxTitle}>
              {formatMessage('BankAccountData.checks.title')}
            </Typography>
            <Divider light className={classes.divider} />
            <BankAccountVerificationChecks checks={data?.checks} />
          </Box>
        </Grid>
      )}

      {/* Charts */}
      {!!data?.accounts && (
        <>
          <Grid xs={12} lg={data?.checks ? 6 : 12} item>
            <Box className={classes.boxContainer}>
              <Typography variant="subtitle2" className={classes.boxTitle}>
                {formatMessage('BankAccountData.chart.title.monthwiseIncomeExpense')}
              </Typography>
              <Divider light className={classes.divider} />
              <BankAccountMonthwiseChart transactions={consolidatedTransactions} currency={data?.accounts[0]?.currency} />
            </Box>
          </Grid>
          {data?.source === BankAccountSourceTypes.Statements && (
            <Grid xs={12} lg={6} item>
              <Box className={classes.boxContainer}>
                <Typography variant="subtitle2" className={classes.boxTitle}>
                  {formatMessage('BankAccountData.chart.title.balanceOverTime')}
                </Typography>
                <Divider light className={classes.divider} />
                <BankAccountBalancesChart balancesChartData={balancesChartData} currency={data?.accounts[0]?.currency} />
              </Box>
            </Grid>
          )}
          {data?.source === BankAccountSourceTypes.Statements && (
            <Grid xs={12} lg={6} item>
              <Box className={classes.boxContainer}>
                <Typography variant="subtitle2" className={classes.boxTitle}>
                  {formatMessage('BankAccountData.chart.title.cashFlow')}
                </Typography>
                <ToggleButtonGroup
                  color="standard"
                  value={cashFlowActiveTab}
                  exclusive
                  onChange={(_, value) => value && switchCashFlowActiveTab(value)}
                  aria-label="language-picker"
                  data-qa={QATags.BankAccountVerification.CashFlowChart.ChartTypeSelect}
                  className={classes.radio}
                >
                  <ToggleButton value={BankAccountTransactionTypes.CREDIT}>{formatMessage('BankAccountData.chart.totalDeposit')}</ToggleButton>
                  <ToggleButton value={BankAccountTransactionTypes.DEBIT}>{formatMessage('BankAccountData.chart.totalWithdrawal')}</ToggleButton>
                </ToggleButtonGroup>
                <Divider light className={classes.divider} />
                <BankAccountCashFlowChart transactions={consolidatedTransactions} currency={data?.accounts[0]?.currency} activeTab={cashFlowActiveTab} />
              </Box>
            </Grid>
          )}
        </>
      )}

      {/* Transaction Table */}
      {!!data?.accounts && <BankAccountTransactionTable data={consolidatedTransactions} currency={data?.accounts[0]?.currency} />}

      {/* Bank Statements  */}
      {data?.statements?.length > 0 && <BankAccountStatements statements={data.statements} />}
    </Grid>
  );
}
