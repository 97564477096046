import { productManagerService, selectProductRegistered } from 'apps/Product';
import { mergeDeep } from 'lib/object';
import cloneDeep from 'lodash/cloneDeep';
import { ApiResponse } from 'models/Client.model';
import { ErrorMessagesTokenTypes } from 'models/Error.model';
import { createEmptyFlow, IAppearance, IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';
import { merchantDeleteFlow, merchantUpdateFlow, merchantUpdateFlowList } from 'state/merchant/merchant.actions';
import { selectCanMerchantUseReusage, selectCurrentFlow, selectMerchantId } from 'state/merchant/merchant.selectors';
import { createTypesSequence, createTypesSequences } from 'state/store.utils';
import { subscribeToWebhook } from 'state/webhooks/webhooks.actions';
import { selectWebhook } from 'state/webhooks/webhooks.selectors';
import { getTemplate, toggleTemplateApplying } from 'apps/Templates';
import * as api from '../api/flowBuilder.client';
import { FlowResetMergeSettingsTypes, IPreviewFlow } from '../models/FlowBuilder.model';
import { selectFlowBuilderChangeableFlow, selectFlowBuilderProductsInGraphModel, selectFlowBuilderSelectedId, selectFlowHistoryList } from './FlowBuilder.selectors';
import { FlowBuilderActionGroups, SliceNameTypes } from './FlowBuilder.store';
import { notification } from 'apps/ui';

export const types: any = {
  ...createTypesSequences(SliceNameTypes),
  ...createTypesSequence(FlowBuilderActionGroups.ProductsInGraph),
  ...createTypesSequence(FlowBuilderActionGroups.ChangeableFlow),
  ...createTypesSequence(FlowBuilderActionGroups.PreviewFlow),
  HAVE_UNSAVED_CHANGES_UPDATE: 'HAVE_UNSAVED_CHANGES_UPDATE',
  PRODUCT_SELECT: 'PRODUCT_SELECT',
  SET_IS_SHOW_INTEGRATION: 'SET_IS_SHOW_INTEGRATION',
  SET_IS_SHOW_CUSTOMIZATION: 'SET_IS_SHOW_CUSTOMIZATION',
  HISTORY_ENTRIES_COUNT_LOAD: 'HISTORY_ENTRIES_COUNT_LOAD',
};

export const flowBuilderShowIntegration = () => ({ type: types.SET_IS_SHOW_INTEGRATION, payload: true });
export const flowBuilderShowCustomization = () => ({ type: types.SET_IS_SHOW_CUSTOMIZATION, payload: true });
export const flowBuilderDontShowIntegration = () => ({ type: types.SET_IS_SHOW_INTEGRATION, payload: false });
export const flowBuilderDontShowCustomization = () => ({ type: types.SET_IS_SHOW_CUSTOMIZATION, payload: false });

export const clearProductSelection = () => ({ type: types.PRODUCT_SELECT, payload: null });

export const flowBuilderProductSelect = (productId: ProductTypes) => (dispatch) => {
  dispatch({ type: types.PRODUCT_SELECT, payload: productId });
  dispatch(flowBuilderDontShowIntegration());
};

export const flowBuilderClearStore = () => (dispatch) => {
  dispatch({ type: types.PRODUCTS_IN_GRAPH_CLEAR, payload: [] });
  dispatch({ type: types.CHANGEABLE_FLOW_CLEAR, payload: {} });
  dispatch({ type: types.HAVE_UNSAVED_CHANGES_UPDATE, payload: false });
  dispatch({ type: types.PRODUCT_SELECT, payload: null });
  dispatch(flowBuilderDontShowIntegration());
};

export const flowBuilderCreateEmptyFlow = (data?: Partial<IFlow>) => (dispatch) => {
  dispatch({ type: types.CHANGEABLE_FLOW_CLEAR, payload: createEmptyFlow(data) });
  dispatch({ type: types.PRODUCTS_IN_GRAPH_SUCCESS, payload: [] });
};

export const flowBuilderProductListInit = (flow, isReset = false) => (dispatch, getState) => {
  const state = getState();
  const registered = selectProductRegistered(state);
  const isReusageEnabled = selectCanMerchantUseReusage(state);
  const activated = registered.filter((item) => {
    const product = productManagerService.getProduct(item);
    if (!product) {
      return false;
    }
    return product.isInFlow(flow);
  });
  const sorted = isReusageEnabled ? productManagerService.sortReusageProductTypes(activated, flow) : productManagerService.sortProductTypes(activated);
  dispatch({ type: types.PRODUCTS_IN_GRAPH_SUCCESS, payload: sorted, isReset });
  dispatch(flowBuilderProductSelect(sorted[0]));
};

export const flowBuilderChangeableFlowLoad = () => (dispatch, getState) => {
  const state = getState();
  const flow = selectCurrentFlow(state);
  if (!flow) {
    return;
  }
  dispatch({ type: types.CHANGEABLE_FLOW_UPDATING });
  try {
    dispatch(flowBuilderProductListInit(flow));
    dispatch({ type: types.CHANGEABLE_FLOW_SUCCESS, payload: cloneDeep(flow) });
  } catch (error) {
    dispatch({ type: types.CHANGEABLE_FLOW_FAILURE, error });
    throw error;
  }
};

export const flowBuilderChangeableFlowUpdate = (changes: Partial<IFlow>) => (dispatch, getState) => {
  const state = getState();
  const changeableFlow = selectFlowBuilderChangeableFlow(state);
  const { value } = selectFlowBuilderProductsInGraphModel(state);
  const isReusageEnabled = selectCanMerchantUseReusage(state);
  dispatch({ type: types.CHANGEABLE_FLOW_UPDATING });
  try {
    const updatedFlow = mergeDeep(changeableFlow, changes, Object.values(FlowResetMergeSettingsTypes));
    dispatch({ type: types.HAVE_UNSAVED_CHANGES_UPDATE, payload: true });
    dispatch({ type: types.CHANGEABLE_FLOW_SUCCESS, payload: updatedFlow, isReset: true });

    if (isReusageEnabled) {
      dispatch({ type: types.PRODUCTS_IN_GRAPH_SUCCESS, isReset: true, payload: productManagerService.sortReusageProductTypes(value, updatedFlow) });
    }
  } catch (error) {
    dispatch({ type: types.CHANGEABLE_FLOW_FAILURE, error });
    throw error;
  }
};

export const flowBuilderProductAdd = (productId: ProductTypes) => (dispatch, getState) => {
  const state = getState();
  const { value } = selectFlowBuilderProductsInGraphModel(state);
  const changeableFlow = selectFlowBuilderChangeableFlow(state);
  const isReusageEnabled = selectCanMerchantUseReusage(state);
  if (value.includes(productId)) {
    return;
  }
  const payload = [...value, productId];
  dispatch({ type: types.PRODUCTS_IN_GRAPH_SUCCESS, isReset: true, payload: isReusageEnabled ? productManagerService.sortReusageProductTypes(payload, changeableFlow) : productManagerService.sortProductTypes(payload) });
  dispatch(flowBuilderProductSelect(productId));
  const product = productManagerService.getProduct(productId);
  if (product) {
    dispatch(flowBuilderChangeableFlowUpdate(product.onAdd(changeableFlow)));
  }
};

export const flowBuilderProductAddFacematchEmpty = () => (dispatch, getState) => {
  const productId = ProductTypes.Facematch;
  const state = getState();
  const { value } = selectFlowBuilderProductsInGraphModel(state);
  const changeableFlow = selectFlowBuilderChangeableFlow(state);
  const isReusageEnabled = selectCanMerchantUseReusage(state);
  const payload = [...value, productId];
  dispatch({ type: types.PRODUCTS_IN_GRAPH_SUCCESS, isReset: true, payload: isReusageEnabled ? productManagerService.sortReusageProductTypes(payload, changeableFlow) : productManagerService.sortProductTypes(payload) });
  dispatch(flowBuilderProductSelect(productId));
  const product = productManagerService.getProduct(productId);
  if (product) {
    dispatch(flowBuilderChangeableFlowUpdate(product.onAddEmpty(changeableFlow)));
  }
};

export const flowBuilderProductRemove = (productId: ProductTypes) => (dispatch, getState) => {
  const state = getState();
  const { value } = selectFlowBuilderProductsInGraphModel(state);
  const selectedId = selectFlowBuilderSelectedId(state);
  const changeableFlow = selectFlowBuilderChangeableFlow(state);
  const isReusageEnabled = selectCanMerchantUseReusage(state);
  const payload = value.filter((item) => item !== productId);
  dispatch({ type: types.PRODUCTS_IN_GRAPH_SUCCESS, isReset: true, payload: isReusageEnabled ? productManagerService.sortReusageProductTypes(payload, changeableFlow) : payload });
  if (selectedId === productId) {
    dispatch({ type: types.PRODUCT_SELECT, payload: null });
  }

  const product = productManagerService.getProduct(productId);
  if (product) {
    dispatch(flowBuilderChangeableFlowUpdate(product.onRemove(changeableFlow)));
  }
};

export const flowBuilderGetTemporaryFlowId = () => async (dispatch, getState): Promise<string> => {
  const changeableFlow = selectFlowBuilderChangeableFlow(getState());
  const { data }: ApiResponse<{ _id: string }> = await api.changeableFlowPost({
    ...changeableFlow,
    name: `${changeableFlow.name} (preview)`,
  });
  return data?._id;
};

export const flowBuilderSubscribeToTemporaryWebhook = (temporaryFlowId: string) => async (dispatch, getState) => {
  const webhook = selectWebhook(getState());
  if (webhook.url) {
    await dispatch(subscribeToWebhook({ url: webhook.url, secret: webhook?.secret }, temporaryFlowId));
  }
};

export const flowBuilderSaveAndPublish = (name?: string) => async (dispatch, getState) => {
  const state = getState();
  const changeableFlow = await selectFlowBuilderChangeableFlow(state);
  dispatch({ type: types.CHANGEABLE_FLOW_UPDATING });
  try {
    const merchantId = selectMerchantId(state);
    const { data }: ApiResponse<IFlow> = await api.flowUpdate(merchantId, changeableFlow.id, {
      ...changeableFlow,
      name: name || changeableFlow.name,
      createdAt: undefined,
      id: undefined,
      updatedAt: undefined,
      pinnedCountries: undefined,
      inputTypes: undefined,
      isFlowAlreadyUsed: undefined,
    });

    dispatch(merchantUpdateFlowList(changeableFlow.id, data));
    dispatch({ type: types.CHANGEABLE_FLOW_SUCCESS, payload: data });
    dispatch({ type: types.HAVE_UNSAVED_CHANGES_UPDATE, payload: false });
  } catch (error) {
    dispatch({ type: types.CHANGEABLE_FLOW_FAILURE, error });
    throw error;
  }
};

export const flowBuilderPreview = (merits?: string[]) => async (dispatch) => {
  dispatch({ type: types.PREVIEW_FLOW_REQUEST });
  try {
    const { data }: ApiResponse<IPreviewFlow> = await api.previewFlow(merits);
    dispatch({ type: types.PREVIEW_FLOW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.PREVIEW_FLOW_FAILURE, error });
    throw error;
  }
};

export const flowBuilderDelete = () => async (dispatch, getState) => {
  const state = getState();
  const flow = selectCurrentFlow(state);
  await dispatch(merchantDeleteFlow(flow.id));
};

export const toggleUnsavedChanges = (value: boolean) => (dispatch) => {
  dispatch({ type: types.HAVE_UNSAVED_CHANGES_UPDATE, payload: value });
};

export const flowBuilderSaveAndPublishSettings = (payload: Partial<IFlow>) => async (dispatch, getState) => {
  try {
    await dispatch(merchantUpdateFlow(payload));
    dispatch({ type: types.CHANGEABLE_FLOW_UPDATING });
    const state = getState();
    const changeableFlow = await selectFlowBuilderChangeableFlow(state);
    dispatch({ type: types.CHANGEABLE_FLOW_SUCCESS, payload: { ...changeableFlow, ...payload } });
  } catch (error) {
    dispatch({ type: types.CHANGEABLE_FLOW_FAILURE, error });
    throw error;
  }
};

export const loadAndApplyTemplateToMetamap = (templateId: string) => async (dispatch) => {
  try {
    dispatch(toggleTemplateApplying(true));
    const { flow } = await dispatch(getTemplate(templateId));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, name, ...fixedTemplateFlow } = flow;
    dispatch(flowBuilderProductListInit(createEmptyFlow(), true));
    dispatch(flowBuilderProductListInit(fixedTemplateFlow, true));
    dispatch({ type: types.CHANGEABLE_FLOW_SUCCESS, payload: fixedTemplateFlow });
    dispatch({ type: types.HAVE_UNSAVED_CHANGES_UPDATE, payload: true });
  } finally {
    dispatch(toggleTemplateApplying(false));
  }
};

export const loadFlowHistory = (flowId: string, page: number, isReload: boolean = true) => async (dispatch, getState) => {
  dispatch({ type: isReload ? types.flowHistory_REQUEST : types.flowHistory_UPDATING });
  try {
    const { data } = await api.getFlowHistory(flowId, { page });
    const currentRows = selectFlowHistoryList(getState()) || [];
    const newData = currentRows.concat(data || []);

    dispatch({ type: types.flowHistory_SUCCESS, payload: newData, isReset: isReload });
  } catch (error) {
    dispatch({ type: types.flowHistory_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
    throw error;
  }
};

export const clearFlowHistory = () => async (dispatch) => {
  dispatch({ type: types.flowHistory_CLEAR });
};

export const loadFlowHistoryEventCount = (flowId) => async (dispatch) => {
  try {
    const { data } = await api.getFlowHistoryEventCount(flowId);
    dispatch({ type: types.HISTORY_ENTRIES_COUNT_LOAD, payload: data?.count });
  } catch (error) {
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
    throw error;
  }
};
