import Grid from '@material-ui/core/Grid';
import React from 'react';
import { CheckBarExpandableSummary } from 'apps/ui';
import { useCheckStepDetailsObject } from 'hooks/useCheckStepDetailsObject';
import { CheckStepDetails } from '../CheckStepDetails/CheckStepDetails';
import { CheckStepDetailsEntry } from '../CheckStepDetails/CheckStepDetailsEntry';

export const CheckStepDetailsObject = (label: string, value: {}) => {
  const { isShowError, isSuccess, copyValue, title } = useCheckStepDetailsObject(label, value);

  return (
    <CheckBarExpandableSummary
      title={title}
      isSuccess={isSuccess}
      isShowError={isShowError}
    >
      <CheckStepDetails>
        <Grid container>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {Array.isArray(value) && value.map((arrObjectValue, index) => Object.keys(arrObjectValue)
            .map((key) => (
              <Grid xs={6} item key={`${index}_${key}`}>
                <CheckStepDetailsEntry label={key} value={copyValue[index][key] || '—'} />
              </Grid>
            )))}
          {!Array.isArray(value) && Object.keys(copyValue)
            .map((key) => (
              <Grid xs={6} item key={key}>
                <CheckStepDetailsEntry label={key} value={copyValue[key] || '—'} />
              </Grid>
            ))}
        </Grid>
      </CheckStepDetails>
    </CheckBarExpandableSummary>
  );
};
