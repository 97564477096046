/* eslint-disable no-underscore-dangle */
import { ICustomerIOEvent, ICustomerIOEventHandlerOptions } from '../models/CustomerIO.model';

declare global {
  interface Window {
    _cio: any;
  }
}

export class CustomerIO {
  start(id: string, parameters: Record<string, string | number | boolean> = {}) {
    window._cio?.identify({
      id,
      ...parameters,
    });
  }

  on(eventId: string, handler: (event: ICustomerIOEvent) => void, options?: ICustomerIOEventHandlerOptions) {
    window._cio?.on(eventId, handler, options);
  }

  off(eventId: string, handler: (event: ICustomerIOEvent) => void) {
    window._cio?.off(eventId, handler);
  }
}

export const customerIO = new CustomerIO();
