export const TimeoutHours = Array.from({ length: 25 }, (_, h) => h);
export const TimeoutValidHours = Array.from({ length: 24 }, (_, h) => h + 1);
export const TimeoutMinutes = Array.from({ length: 16 }, (_, i) => {
  if (i <= 5) {
    return i;
  }
  return i * 5 - 20;
});

export function convertTimeToHoursAndMinutes(ptTime: string): {
  hours: number; minutes: number;
} {
  const timeHMFormat = ptTime.replace('PT', '')
    .replace('H', ':')
    .replace('M', '');
  const result = timeHMFormat.split(':');

  if (result.length === 1) {
    return {
      hours: 0,
      minutes: parseInt(result[0], 10) || 0,
    };
  }

  return {
    hours: parseInt(result[0], 10) || 0,
    minutes: parseInt(result[1], 10) || 0,
  };
}

export const defaultTimeoutSettings = {
  hh: 12,
  mm: 0,
};
