import { AmlValidationTypes } from 'apps/Aml';
import { LocationIntelligenceValidationTypes } from 'apps/LocationIntelligence';
import { PhoneOwnershipValidationTypes } from './PhoneCheck.model';
import { ProductTypes } from './Product.model';
import { filterSteps, IStep } from './Step.model';
import { VerificationResponse } from './VerificationOld.model';

export enum VerificationPatternTypes {
  AgeValidation = 'age-check',
  AgeConsistencyCheck = 'age-consistency-check',
  ArgentinianAnses = 'argentinian-anses-validation',
  ArgentinianDni = 'argentinian-dni-validation',
  ArgentinianRenaperPremium = 'argentinian-renaper-premium-validation',
  ArgentinianRenaperExtended = 'argentinian-renaper-extended-validation',
  ArgentinianRenaperFacematch = 'argentinian-renaper-facematch-validation',
  ArgentinianRenaper = 'argentinian-renaper-validation',
  Biometrics = 'biometrics',
  BolivianOep = 'bolivian-oep-validation',
  BrazilianCnpj = 'brazilian-cnpj-validation',
  BrazilianCpf = 'brazilian-cpf-validation',
  BrazilianCpfLight = 'brazilian-cpf-light-validation',
  BrazilianNoCriminalRecordsValidation = 'brazilian-no-criminal-records-validation',
  BackgroundMexicanBuholegal = 'background-mexican-buholegal-validation',
  BackgroundBrazilianChecks = 'brazilian-background-checks',
  BackgroundLatinAmericaChecks = 'background-drucker-validation',
  CreditArgentinianFidelitas = 'credit-argentinian-fidelitas-validation',
  CreditBrazilianSerasa = 'credit-brazilian-serasa-validation',
  CreditBrazilianSerasaChecks = 'credit-brazilian-serasa-checks-validation',
  CreditMexicoCdc = 'credit-mexico-cdc-validation',
  CreditColombiaTransunion = 'credit-colombian-transunion-validation',
  ChileanRut = 'chilean-rut-validation',
  ChileanRegistroCivil = 'chilean-registro-civil-validation',
  ColombianBdua = 'colombian-bdua-validation',
  ChileanDriverLicense = 'chilean-driver-license-validation',
  ColombianContraloria = 'colombian-contraloria-validation',
  ColombianMigraciones = 'colombian-migraciones-validation',
  ColombianNationalPolice = 'colombian-national-police-validation',
  ColombianNit = 'colombian-nit-validation',
  ColombianPpt = 'colombian-ppt-validation',
  ColombianProcuraduria = 'colombian-procuraduria-validation',
  ColombianRegistraduria = 'colombian-registraduria-validation',
  ColombianUnifiedLegalSearch = 'colombian-unified-legal-search-validation',
  ColombianRunt = 'colombian-runt-validation',
  ColombianSisben = 'colombian-sisben-validation',
  ComplyAdvantageValidation = 'comply-advantage-validation',
  CostaRicanAtv = 'costa-rican-atv-validation',
  CostaRicanTse = 'costa-rican-tse-validation',
  CostaRicanSocialSecurity = 'costa-rican-social-security-validation',
  CustomWatchlistsValidation = 'custom-watchlists-validation',
  CustomFieldsValidation = 'custom-fields-validation',
  DominicanJce = 'dominican-jce-validation',
  DominicanRnc = 'dominican-rnc-validation',
  DuplicateUserDetection = 'duplicate-user-detection',
  DuplicateUserValidation = 'duplicate-user-detection',
  EcuadorianRegistroCivil = 'ecuadorian-registro-civil-validation',
  EcuadorianSri = 'ecuadorian-sri-validation',
  EmailOwnershipValidation = 'email-ownership-validation',
  EmailRiskValidation = 'email-risk-validation',
  GhanaianGra = 'ghanaian-gra-validation',
  GuatemalanTse = 'guatemalan-tse-validation',
  IpValidation = 'ip-validation',
  Geolocation = 'geolocation',
  HonduranRnp = 'honduran-rnp-validation',
  IndonesianKPTValidation = 'indonesian-ktp-validation',
  KenyanEcitizen = 'kenyan-ecitizen-validation',
  KenyanBRC = 'kenyan-brs-validation',
  KenyanIprs = 'kenyan-iprs-validation',
  MexicanCurp = 'mexican-curp-validation',
  MexicanIne = 'mexican-ine-validation',
  MexicanPep = 'mexican-pep-validation',
  MexicanRfc = 'mexican-rfc-validation',
  MexicanRfcStatus = 'mexican-rfc-status-validation',
  NigerianCac = 'nigerian-cac-validation',
  NigerianAffiliatesCac = 'nigerian-cac-affiliates-validation',
  NigerianShareholdersCac = 'nigerian-cac-shareholders-validation',
  NigerianDl = 'nigerian-dl-validation',
  NigerianNin = 'nigerian-nin-validation',
  NigerianVirtualNin = 'nigerian-vnin-validation',
  NigerianVin = 'nigerian-vin-validation',
  NigerianBvn = 'nigerian-bvn-validation',
  NigerianLegal = 'nigerian-legal-validation',
  NigerianTin = 'nigerian-tin-validation',
  NigerianAv = 'nigerian-av-validation',
  PanamenianTribunalElectoral = 'panamenian-tribunal-electoral-validation',
  ParaguayanRcp = 'paraguayan-rcp-validation',
  PeruvianReniec = 'peruvian-reniec-validation',
  PeruvianSunat = 'peruvian-sunat-validation',
  PhilippineDl = 'philippine-dl-validation',
  PhilippineNbi = 'philippine-nbi-validation',
  PhilippineUMIDSSS = 'philippine-umid-ssn-validation',
  PhilippinePNP = 'philippine-pnp-validation',
  PeruvianHealthSocialSecurity = 'peruvian-health-social-security',
  PhoneOwnershipValidation = 'phone-ownership-validation',
  PhoneRiskValidation = 'phone-risk-analysis-validation',
  PremiumAmlWatchListsSearchValidation = 'premium-aml-watchlists-search-validation',
  PremiumAmlWatchListsValidation = 'premium-aml-watchlists-validation',
  ProofOfOwnership = 'proof-of-ownership',
  ReFacematch = 're-facematch',
  SalvadorianTse = 'salvadorian-tse-validation',
  VenezuelanCne = 'venezuelan-cne-validation',
  VenezuelanSeniat = 'venezuelan-seniat-validation',
  TemplateMatching = 'template-matching',
  DocumentReading = 'document-reading',
  VpnDetection = 'vpn-detection',
  HighAccuracy='high-accuracy',
  ESignatureDocuments = 'electronic-signature-document-validation',
  FinancialInformationBankAccountsRetrieving = 'financial-information-bank-accounts-retrieving',
  FinancialInformationBankStatementParsingRetrieving = 'financial-information-bank-statement-parsing-retrieving',
  Facematch = 'facematch-service-validation',
  UgandanElectoralCommission = 'ugandan-electoral-commission-validation',
  PeruvianMigrationInstitute = 'peruvian-national-migration-institute',
  DuplicateFaceDetection = 'duplicate-face-detection',
  Watchlists = 'watchlists',
  PhilippinesUMIDSSS = 'philippinian-umid-ssn-validation',
  VideoAgreementValidation = 'video-agreement-validation',
  ColombianRegistraduriaLight = 'colombian-registraduria-light-validation',
  EcuadorianSocialSecurity = 'ecuadorian-social-security-validation',
  PhilippinePrcValidation = 'philippine-prc-validation',
  UruguayRegistroCivil = 'uruguayan-registro-civil-validation',
  BiometricsVerification = 'biometrics-verification',
  BrazilianCNPJExtended = 'brazilian-cnpj-extended-validation',
  AgeConsistencyCheckEnabled = 'age-consistency-enabled',
}

type VerificationPatternBooleanType = {
  [T in VerificationPatternTypes]?: boolean;
}

type VerificationPatternOtherType = {
  [VerificationPatternTypes.ArgentinianRenaper]?: string;
  [VerificationPatternTypes.ArgentinianRenaperExtended]?: boolean;
  [VerificationPatternTypes.ArgentinianRenaperFacematch]?: boolean;
  [VerificationPatternTypes.CreditBrazilianSerasa]?: boolean;
  [VerificationPatternTypes.CreditBrazilianSerasaChecks]?: boolean;
  [VerificationPatternTypes.CreditArgentinianFidelitas]?: boolean;
  [VerificationPatternTypes.CreditMexicoCdc]?: boolean;
  [VerificationPatternTypes.CreditColombiaTransunion]?: boolean;
  // TODO: @ggrigorev move Biometric.model to ts
  [VerificationPatternTypes.Biometrics]?: string; // BiometricTypes
  // TODO: @ggrigorev move GovCheck.model to ts
  [VerificationPatternTypes.BrazilianCpf]?: string; // GovCheckStepTypes.BrazilianCpf
  [VerificationPatternTypes.BackgroundBrazilianChecks]?: string;
  [VerificationPatternTypes.BackgroundLatinAmericaChecks]?: string;
  [VerificationPatternTypes.IndonesianKPTValidation]?: string;
  [VerificationPatternTypes.IpValidation]?: LocationIntelligenceValidationTypes;
  [VerificationPatternTypes.PremiumAmlWatchListsSearchValidation]?: AmlValidationTypes;
  [VerificationPatternTypes.PremiumAmlWatchListsValidation]?: AmlValidationTypes;
  [VerificationPatternTypes.PhoneOwnershipValidation]?: PhoneOwnershipValidationTypes;
  [VerificationPatternTypes.EmailOwnershipValidation]?: string;
  [VerificationPatternTypes.AgeConsistencyCheckEnabled]?: boolean;
}

export const ProductByVerificationPattern: { [T in VerificationPatternTypes]?: ProductTypes } = {
  [VerificationPatternTypes.CustomFieldsValidation]: ProductTypes.CustomField,
  [VerificationPatternTypes.Biometrics]: ProductTypes.BiometricVerification,
  [VerificationPatternTypes.Facematch]: ProductTypes.Facematch,
};

export function getAllVerificationSteps<T>(verification: VerificationResponse, listStep: string[]): IStep<T>[] {
  const govCheckDocumentsSteps = verification?.documents?.map((document) => filterSteps<T>(document.steps, listStep))
    .flat() || [];
  const govCheckRootSteps = filterSteps<T>(verification.steps, listStep);
  return govCheckDocumentsSteps.concat(govCheckRootSteps);
}

type VerificationPatternType = Omit<VerificationPatternBooleanType, keyof VerificationPatternOtherType> & VerificationPatternOtherType;

export interface IVerificationPatterns extends VerificationPatternType {}
