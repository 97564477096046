import { makeStyles, ButtonBase, withStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    borderRadius: 10,
    overflow: 'initial',
    cursor: 'pointer',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.common.black7}`,
    '&.isOpen': {
      border: 'none',
      boxShadow: `0px 1px 3px ${theme.palette.common.black50}`,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&.isDisabled': {
      cursor: 'not-allowed',
    },
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: '50%',
    fontSize: 20,
    transform: 'translateY(-50%)',
    transition: '.2s all ease-in-out',
  },
  iconOpen: {
    transform: 'translateY(-50%) rotate(180deg)',
  },
  openItems: {
    boxShadow: `0px 2px 3px ${theme.palette.common.black50}`,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.common.white,
  },
  itemIcons: {
    color: theme.palette.common.black75,
  },
  text: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.common.black75,
  },
  placeholder: {
    color: theme.palette.common.black75,
    width: '95%',
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
    '& button': {
      fontSize: 16,
      '&:first-child': {
        textDecoration: 'underline',
      },
      '&:last-child': {
        width: 140,
        height: 52,
      },
    },
  },
  content: {
    maxHeight: 330,
    overflow: 'auto',
    '&.boxBordered': {
      border: `1px solid ${theme.palette.common.black7}`,
      padding: '10px 6px 0 16px',
      borderRadius: 8,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTopColor: 'transparent',
      minHeight: 330,
    },
  },
}));

export const StyledButtonBase = withStyles((theme) => ({
  root: {
    padding: '6px 12px',
    fontSize: 14,
    backgroundColor: theme.palette.common.black7opacity,
    color: theme.palette.common.black75,
    borderRadius: 10,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.black7,
    },
  },
}))(ButtonBase);
