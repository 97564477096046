import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    isolation: 'isolate',
    overflow: 'auto',
  },
  showSelectedBlock: {
    padding: 8,
    borderTop: `1px solid ${theme.palette.common.black7}`,
  },
}));
