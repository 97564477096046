import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useFormatMessage } from 'apps/intl';
import { IDefaultValues } from '../../../../models/CustomField.model';

export function CustomFieldCheckboxSetting({ checked, setDefaults }: {
  checked: boolean;
  setDefaults: Dispatch<SetStateAction<IDefaultValues>>;
}) {
  const formatMessage = useFormatMessage();

  const handleChangeChecked = useCallback(() => setDefaults((prev) => ({ ...prev, checked: !prev.checked })), [setDefaults]);

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item>
        <Typography variant="body1">
          {formatMessage('CustomField.settings.defaultChecked')}
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={checked}
          color="primary"
          size="small"
          onChange={handleChangeChecked}
        />
      </Grid>
    </Grid>
  );
}
