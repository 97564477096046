import { Loadable } from 'models/Loadable.model';
import { createTypesSequences, TypesSequences } from 'state/store.utils';
import { ITemplateMetadata, ITemplate, ITemplatesList } from '../model/Templates.model';

export const TEMPLATES_STORE_KEY = 'templates';

export enum SliceNameTypes {
  MetadataList = 'metadataList',
  CurrentTemplate = 'currentTemplate',
  Templates = 'templates',
  TemplatesList = 'templatesList',
}

export interface ITemplatesStore {
  [SliceNameTypes.MetadataList]: Loadable<ITemplateMetadata[]>;
  [SliceNameTypes.CurrentTemplate]: Loadable<ITemplate>;
  [SliceNameTypes.Templates]: Loadable<Record<string, ITemplate[]>>;
  [SliceNameTypes.TemplatesList]: Loadable<ITemplatesList>;
  isTemplateApplying: boolean;
}

export const types: TypesSequences<typeof SliceNameTypes> & { TOGGLE_TEMPLATE_APPLYING: string } = {
  ...createTypesSequences(SliceNameTypes),
  TOGGLE_TEMPLATE_APPLYING: 'TOGGLE_TEMPLATE_APPLYING',
};
