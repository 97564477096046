import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormatMessage } from 'apps/intl';
import { copyToClipboard } from 'lib/copyToClipboard';
import { generateWebhookSecret, HTTPS_REG_EXP, WEBHOOK_SECRET_REG_EXP } from 'lib/validations';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Modal } from 'apps/overlay';
import { notification, TextFieldSecret, VideoPlayer } from 'apps/ui';
import { QATags } from 'models/QA.model';
import { deleteWebhook, getWebhooks, subscribeToWebhook } from 'state/webhooks/webhooks.actions';
import { selectWebhook } from 'state/webhooks/webhooks.selectors';
import { ReactComponent as PlayIcon } from 'assets/video-player-play.svg';
import isValidDomain from 'is-valid-domain';
import { ForDevelopersInputTypes } from '../../models/ForDevelopers.model';
import { useStyles } from './ForDevsWebhookModal.styles';
import { Button } from 'lib/UI';

interface IForDevsWebhookModalInputs {
  [ForDevelopersInputTypes.Secret]: string;
  [ForDevelopersInputTypes.Url]: string;
}

export function ForDevsWebhookModal() {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const webhook = useSelector<any, { secret: string; url: string; id: string }>(selectWebhook);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { register, handleSubmit, watch, setValue, formState: { errors, submitCount } } = useForm<IForDevsWebhookModalInputs>({
    defaultValues: {
      [ForDevelopersInputTypes.Url]: webhook?.url || '',
      [ForDevelopersInputTypes.Secret]: webhook?.secret || '',
    },
  });

  const webHookUrlValidator = (url): boolean => {
    try {
      const parsedURL = new URL(url);
      const hostname = parsedURL.hostname;
      return isValidDomain(hostname, { subdomain: true });
    } catch (error) {
      return false;
    }
  };

  const urlRegister = register(ForDevelopersInputTypes.Url, {
    required: formatMessage('validations.required'),
    pattern: {
      value: HTTPS_REG_EXP,
      message: formatMessage('validations.httpsOnly'),
    },
    validate: (url: string) => webHookUrlValidator(url) || formatMessage('validations.invalidHostname'),
  });
  const secretRegister = register(ForDevelopersInputTypes.Secret, {
    required: formatMessage('validations.required'),
    pattern: {
      value: WEBHOOK_SECRET_REG_EXP,
      message: formatMessage('validations.webhookSecret'),
    },
  });
  const secretWatch = watch(ForDevelopersInputTypes.Secret);

  const handleGenerateSecret = () => {
    const secret = generateWebhookSecret();
    setValue(ForDevelopersInputTypes.Secret, secret, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    copyToClipboard(secret);
    notification.info(formatMessage('copied'));
  };

  const handleFormSubmit: SubmitHandler<IForDevsWebhookModalInputs> = useCallback(async (data) => {
    try {
      setIsSubmitting(true);
      if (webhook?.id) {
        await dispatch(deleteWebhook(webhook.id));
      }
      await dispatch(subscribeToWebhook(data));
      await dispatch(getWebhooks());
      setIsSubmitting(false);
      notification.info(formatMessage('forDevs.webhook.success'));
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      notification.error(formatMessage('Error.common'));
    }
  }, [dispatch, webhook, formatMessage]);

  const handleRedirect = useCallback(() => {
    window.open('https://docs.metamap.com/docs/webhook-specifications', '_blank', 'noopener');
  }, []);

  return (
    <Modal className={classes.modal}>
      <Box mb={1}>
        <Typography variant="h4">
          {formatMessage('forDevs.webhook.title')}
        </Typography>
      </Box>
      <Box mb={3.5}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12} lg={7}>
            <Box color="common.black75">
              {formatMessage('forDevs.webhook.description')}
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Button
              size="large"
              type="secondary"
              onClick={handleRedirect}
              data-qa={QATags.Webhook.Doc}
            >
              {formatMessage('forDevs.webhook.documentation')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Box mb={1}>
          <Typography variant="subtitle2">
            {formatMessage('forDevs.webhook.video')}
          </Typography>
        </Box>
        <Box className={classes.video}>
          <VideoPlayer
            controls
            light="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati%2BWebhooks.png"
            playing
            url="https://metamap.s3.amazonaws.com/static-assets/MetaMap+-+The+webhooks.mp4"
            playIcon={<IconButton><PlayIcon /></IconButton>}
          />
        </Box>
      </Box>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InputLabel className={classes.label}>
              {formatMessage('forDevs.webhook.url')}
            </InputLabel>
            <TextField
              {...urlRegister}
              helperText={errors?.[ForDevelopersInputTypes.Url]?.message}
              error={!!errors[ForDevelopersInputTypes.Url]}
              type="input"
              variant="outlined"
              fullWidth
              placeholder={formatMessage('onboarding.webhooks.placeholders.url')}
              className={classes.input}
              inputProps={{ 'data-qa': QATags.Webhook.Url }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel className={classes.label}>
              {formatMessage('forDevs.webhook.secret')}
            </InputLabel>
            <TextFieldSecret
              {...secretRegister}
              setValue={setValue}
              helperText={errors?.[ForDevelopersInputTypes.Secret]?.message}
              error={!!errors[ForDevelopersInputTypes.Secret]}
              value={secretWatch}
              submitCount={submitCount}
              type="input"
              variant="outlined"
              fullWidth
              placeholder={formatMessage('onboarding.webhooks.placeholders.secret')}
              className={classes.input}
            />
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                size="large"
                type="secondary"
                disabled={isSubmitting}
                data-qa={QATags.Webhook.GenerateSecret}
                onClick={handleGenerateSecret}
              >
                {formatMessage('forDevs.webhook.generateSecret')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                disabled={isSubmitting}
                data-qa={QATags.Webhook.Save}
                onClick={handleSubmit(handleFormSubmit)}
              >
                {formatMessage('forDevs.webhook.submit')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
}
