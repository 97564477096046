import React from 'react';
import { WarningBadgeAccessAllowed } from '../WarningBadgeAccessAllowed/WarningBadgeAccessAllowed';
import { ContainerAccessAllowed } from '../ContainerAccessAllowed/ContainerAccessAllowed';

export function HOCIsAccessAllowed({ children, isShowAlert = true, isAccessAllowed, meritName }: {
    children: React.ReactElement;
    isAccessAllowed: boolean;
    isShowAlert?: boolean;
    meritName?: string;
}) {
  return isAccessAllowed ? children : (
    <>
      {isShowAlert && <WarningBadgeAccessAllowed meritName={meritName} />}
      <ContainerAccessAllowed disabled>
        {children}
      </ContainerAccessAllowed>
    </>
  );
}
