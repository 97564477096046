import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as CrossIcon } from 'assets/icon-btn-close.svg';
import { MexicanBuholegalCheck } from 'models/BackgroundCheck.model';
import classnames from 'classnames';
import { Spoiler, WarningBadge } from 'apps/ui';
// TODO: @aleksei.rasskazov refactor SpoilerTypes to be able to import it from apps/ui
import { SpoilerTypes } from 'apps/ui/components/Spoiler/SpoilerTypes';
import { useStyles } from './RecordFailedDetails.styles';
import { RecordFailedContent } from './components/RecordFailedContent/RecordFailedContent';

export const RecordFailedDetails = ({
  type = SpoilerTypes.Danger,
  record,
}: { record: ArrayElement<MexicanBuholegalCheck['records']>; type?: SpoilerTypes }) => {
  const classes = useStyles();

  return (
    <Box pb="14px" px="12px">
      <Paper>
        <Box p="12px">
          <Spoiler
            type={type}
            title={record.court}
            extraIcon={<WarningBadge className={classes.warningIcon} isDefaultPosition={false} />}
            headerIcon={(
              <CrossIcon className={classnames(classes.crossIcon, {
                [classes.crossIconDanger]: type === SpoilerTypes.Danger,
                [classes.crossIconWarning]: type === SpoilerTypes.Warning,
              })}
              />
            )}
          >
            <RecordFailedContent className={classes.detailsContent} record={record} />
          </Spoiler>
        </Box>
      </Paper>
    </Box>
  );
};
