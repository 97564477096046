import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';
import { notification } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { downloadBlob } from 'lib/file';
import { QATags } from 'models/QA.model';
import { DownloadFileIcon } from '../../assets';
import { IBankAccountDataVerificationStatement } from '../../models/BankAccountData.model';
import { useStyles } from './PdfViewer.styles';

export function PdfViewer({ pdf, pdfList, closeOverlay }: {
  pdf: IBankAccountDataVerificationStatement;
  pdfList: IBankAccountDataVerificationStatement[];
  closeOverlay: () => void;
}) {
  const styles = useStyles();
  const formatMessage = useFormatMessage();
  const [selectedStatement, setSelectedStatement] = useState<IBankAccountDataVerificationStatement>(pdf);
  const [selectedPdf, setSelectedPdf] = useState<Nullable<string>>(null);
  const [numberOfPages, setNumberOfPages] = useState<Nullable<number>>(null);
  const [originalPdfWidth, setOriginalPdfWidth] = useState<number>(600);
  const [originalPdfHeight, setOriginalPdfHeight] = useState<number>(900);
  const file = useMemo(() => ({ url: selectedPdf }), [selectedPdf]);

  useEffect(() => {
    pdf?.url.then((resolvedPdf) => setSelectedPdf(resolvedPdf));
  }, [pdf]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const previousPage = () => {
    setNumberOfPages(null);
    const previousStatement = pdfList[pdfList.indexOf(selectedStatement) - 1];
    setSelectedStatement(previousStatement);
    previousStatement.url.then((resolvedPdf) => {
      setSelectedPdf(resolvedPdf);
    })
      .catch((error) => {
        notification.error(formatMessage('Error.common'));
        throw error;
      });
  };

  const nextPage = () => {
    setNumberOfPages(null);
    const nextStatement = pdfList[pdfList.indexOf(selectedStatement) + 1];
    setSelectedStatement(nextStatement);
    nextStatement.url.then((resolvedPdf) => {
      setSelectedPdf(resolvedPdf);
    })
      .catch((error) => {
        notification.error(formatMessage('Error.common'));
        throw error;
      });
  };

  const onPageLoad = ({ originalHeight, originalWidth }) => {
    setOriginalPdfHeight(originalHeight);
    setOriginalPdfWidth(originalWidth);
  };

  const downloadPdf = () => {
    downloadBlob(selectedPdf, selectedStatement?.originalFileName);
  };

  if (!selectedPdf) {
    return null;
  }

  return (
    <>
      <div className={styles.headerActions}>
        <IconButton onClick={downloadPdf} className={styles.pageButton} data-qa={QATags.BankAccountVerification.PdfViewer.Download}>
          <DownloadFileIcon />
        </IconButton>
        <IconButton onClick={closeOverlay} className={styles.pageButton} data-qa={QATags.BankAccountVerification.PdfViewer.Close}>
          <Close />
        </IconButton>
      </div>
      <Document
        file={file}
        loading={<Box height={originalPdfHeight} width={originalPdfWidth} />}
        onLoadSuccess={onDocumentLoadSuccess}
        className={classnames(styles.pdfContainer, { [styles.singlePdf]: pdfList.length === 1 })}
      >
        {Array.from(
          new Array(numberOfPages),
          (el, index) => (
            <Page
              onLoadSuccess={onPageLoad}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ),
        )}
      </Document>
      <div className={classnames(styles.footerActions, { [styles.previousDisabled]: pdfList.indexOf(selectedStatement) === 0 })}>
        {pdfList.indexOf(selectedStatement) !== 0 && (
          <IconButton disableRipple onClick={previousPage} className={styles.pageButton} data-qa={QATags.BankAccountVerification.PdfViewer.Previous}>
            <ArrowBack />
            <Typography variant="subtitle2" className={styles.footerButtonText}>{formatMessage('BankAccountData.bankStatements.previousStatement')}</Typography>
          </IconButton>
        )}
        {pdfList.indexOf(selectedStatement) !== pdfList.length - 1 && (
          <IconButton disableRipple onClick={nextPage} className={styles.pageButton} data-qa={QATags.BankAccountVerification.PdfViewer.Next}>
            <Typography variant="subtitle2" className={styles.footerButtonText}>{formatMessage('BankAccountData.bankStatements.nextStatement')}</Typography>
            <ArrowForward />
          </IconButton>
        )}
      </div>
    </>
  );
}
