import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
  },
  cardWrapper: {
    display: 'inline-flex',
    width: '100%',
    flexWrap: 'wrap',
    gap: 16,
  },
}));

export const StyledButtonBase = withStyles((theme) => ({
  root: {
    padding: '6px 12px',
    fontSize: 14,
    backgroundColor: theme.palette.common.black7opacity,
    color: theme.palette.common.black75,
    borderRadius: 10,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.black7,
    },
  },
}))(ButtonBase);
