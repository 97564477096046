import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import type { IStep } from 'models/Step.model';
import { getLatamChecks, IBackgroundCheckStepData, StepExtraLatam, StructureSection } from 'models/BackgroundCheck.model';
import ReactJsonViewer from 'react-json-view';
import { BackgroundCheckSummary } from '../BackgroundCheckSummary/BackgroundCheckSummary';
import { BackgroundChecksError } from '../BackgroundChecksError/BackgroundChecksError';
import { BackgroundCheckInProgress } from '../BackgroundCheckInProgress/BackgroundCheckInProgress';
import { BackgroundCheckControls } from '../BackgroundCheckControls/BackgroundCheckControls';
import { LatamRecordViewPassed } from './components/LatamRecordViewPassed/LatamRecordViewPassed';
import { LatamRecordViewFailed } from './components/LatamRecordViewFailed/LatamRecordViewFailed';
import { BackgroundCheckStructure } from '../BackgroundCheckStructure/BackgroundCheckStructure';
import { useBackgroundCheckParams } from '../../hooks/backgroundCheck.hook';

export function BackgroundChecksLatam({ step }: { step: IStep<IBackgroundCheckStepData<StepExtraLatam>> }) {
  const {
    isJsonVisible,
    setJsonVisible,
    isInProgress,
    couldBeRunManually,
  } = useBackgroundCheckParams(step);

  const records = getLatamChecks(step.data?.stepExtra || []);

  const hasValidRecords = Boolean(records.passed.length || records.failed.length);

  useEffect(() => {
    setJsonVisible(!hasValidRecords);
  }, [hasValidRecords, setJsonVisible]);

  if (couldBeRunManually) {
    return null;
  }

  if (isInProgress) {
    return <BackgroundCheckInProgress />;
  }

  if (step.error && !step.data) {
    return <BackgroundChecksError errorCode={step.error.code} />;
  }

  const sections: StructureSection[] = [
    {
      titleKey: 'BackgroundCheck.verification.jsonView.failedChecks',
      items: records.failed,
      component: LatamRecordViewFailed,
    },
    {
      titleKey: 'BackgroundCheck.verification.jsonView.passedChecks',
      items: records.passed,
      component: LatamRecordViewPassed,
    },
  ];

  return (
    <Grid container spacing={2}>
      <BackgroundCheckSummary step={step} />
      <Grid item container>
        <Grid item xs={12}>
          <BackgroundCheckControls
            step={step}
            isVisible={isJsonVisible}
            onSetVisibility={setJsonVisible}
            isToggleVisible={!step.data?.stepExtra?.length}
          />
          {isJsonVisible && (
            <ReactJsonViewer
              src={step}
              collapsed
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          )}
          {!isJsonVisible && Boolean(step.data?.stepExtra) && (<BackgroundCheckStructure sections={sections} />)}
        </Grid>
      </Grid>
    </Grid>
  );
}
