import { IVerificationPatterns, VerificationPatternTypes } from 'models/VerificationPatterns.model';
import dayjs from 'dayjs';
import type { ComponentType } from 'react';
import { DateFormatTypes } from 'lib/date';
import { StepIds, VerificationStepTypes } from './Step.model';
import { MerchantTagsTypes } from './Merchant.model';
import { getBackgroundCheckScore } from '../apps/BackgroundCheck/models/BackgroundCheck.model';

export enum BackgroundCheckDateFormatsTypes {
  DateOfBirth = 'dateOfBirth',
  BirthDay = 'birthday',
}

export enum BackgroundCheckSettingTypes {
  BackgroundChecksSetting = 'backgroundChecksSetting',
  PostResultPhaseTimeout = 'postResultPhaseTimeout'
}

export enum BackgroundChecksTypes {
  CountryDependantSources = 'countryDependantSources'
}

export enum BackgroundCheckErrorTypes {
  LatamNoRecords = 'NO REGISTRA ANTECEDENTES',
}

export enum BackgroundCheckCountryTypes {
  Argentina = 'AR',
  Brazil = 'BR',
  Chile = 'CL',
  Colombia = 'CO',
  Ecuador = 'EC',
  Mexico = 'MX',
  Paraguay = 'PY',
  Peru = 'PE',
  Venezuella = 'VE',
}

export const backgroundCheckCountriesOrder = [
  BackgroundCheckCountryTypes.Argentina,
  BackgroundCheckCountryTypes.Brazil,
  BackgroundCheckCountryTypes.Chile,
  BackgroundCheckCountryTypes.Colombia,
  BackgroundCheckCountryTypes.Ecuador,
  BackgroundCheckCountryTypes.Mexico,
  BackgroundCheckCountryTypes.Paraguay,
  BackgroundCheckCountryTypes.Peru,
  BackgroundCheckCountryTypes.Venezuella,
];

type BackgroundCheckBaseOptionsListItem = {
  id: VerificationStepTypes;
  title: string;
  value: string | boolean | null;
  merchantTags?: MerchantTagsTypes[];
}

export type BackgroundCheckBaseOptionsListItemExtended = BackgroundCheckBaseOptionsListItem & { isDisabled?: boolean }

export interface IBackgroundCheck<T extends BackgroundCheckBaseOptionsListItem> {
  id: string;
  default: boolean | string;
  value?: boolean | string;
  options?: {
    list: T[];
  };
}

export interface IBackgroundCheckConfiguration {
  country: BackgroundCheckCountryTypes;
  checks: IBackgroundCheck<BackgroundCheckBaseOptionsListItem>[];
}

export enum BackgroundCheckTypes {
  None = 'none',
  LIGHT = 'light',
  FULL = 'full',
}

export const BrazilGovCheckTypesForPattern = {
  [VerificationPatternTypes.BackgroundBrazilianChecks]: {
    NONE: BackgroundCheckTypes.None,
    LIGHT: BackgroundCheckTypes.LIGHT,
    FULL: BackgroundCheckTypes.FULL,
  },
};

export const backgroundCheckConfigurations: IBackgroundCheckConfiguration[] = [
  {
    country: BackgroundCheckCountryTypes.Mexico,
    checks: [
      {
        id: VerificationStepTypes.BackgroundMexicanBuholegal,
        default: false,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Argentina,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Ecuador,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Colombia,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Chile,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Peru,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Paraguay,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Venezuella,
    checks: [
      {
        id: VerificationStepTypes.BackgroundLatinAmericaChecks,
        default: BackgroundCheckTypes.None,
      },
    ],
  },
  {
    country: BackgroundCheckCountryTypes.Brazil,
    checks: [
      {
        id: VerificationStepTypes.BackgroundBrazilianChecks,
        default: BrazilGovCheckTypesForPattern[VerificationPatternTypes.BackgroundBrazilianChecks].NONE,
        options: {
          list: [
            {
              id: VerificationStepTypes.BackgroundBrazilianChecksFull,
              title: 'BackgroundCheck.check.brazilianBackgroundChecks.fullCheck',
              value: BrazilGovCheckTypesForPattern[VerificationPatternTypes.BackgroundBrazilianChecks].FULL,
              merchantTags: [MerchantTagsTypes.CanUseBrazilianBackgroundFullChecks],
            },
            {
              id: VerificationStepTypes.BackgroundBrazilianChecksLight,
              title: 'BackgroundCheck.check.brazilianBackgroundChecks.lightCheck',
              value: BrazilGovCheckTypesForPattern[VerificationPatternTypes.BackgroundBrazilianChecks].LIGHT,
            },
          ],
        },
      },
    ],
  },
];

export const backgroundCheckDisplayOptions = {
  [VerificationStepTypes.BackgroundMexicanBuholegal]: {
    resource: {
      hidden: true,
    },
    timestamp: {
      hidden: true,
    },
    stepExtra: {
      hidden: true,
    },
    lastSurname: {
      hidden: true,
    },
    status: {
      hidden: true,
    },
  },
  [VerificationStepTypes.BackgroundBrazilianChecks]: {
    results: {
      hidden: true,
    },
    status: {
      hidden: true,
    },
  },
  [VerificationStepTypes.BackgroundLatinAmericaChecks]: {
    resource: {
      hidden: true,
    },
    timestamp: {
      hidden: true,
    },
    stepExtra: {
      hidden: true,
    },
    lastSurname: {
      hidden: true,
    },
    country: {
      hidden: true,
    },
    status: {
      hidden: true,
    },
  },
};

export enum BackgroundCheckStatusesTypes {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Approved = 'approved',
  LowRisk = 'lowRisk',
  HighRisk = 'highRisk',
}

type BackgroundCheckFieldResult = BackgroundCheckErrorTypes.LatamNoRecords | string;

enum CrawlerStatusTypes {
  Approved = 'approved',
  LowRisk = 'lowRisk',
  HighRisk = 'highRisk',
  InvalidInput = 'invalidInput',
}

export type FailedTrialStatus = 'highRisk' | 'lowRisk';

export type TrialRecord = {
  subject: string;
  type: string;
  class: string;
  status: FailedTrialStatus;
  processNumber: string;
  lastMovementDate: string;
  area: string;
  link?: string;
}

export type StepExtraBrazil = {
  id: number;
  crawler: string;
  crawlerFullName: string;
  status: CrawlerStatusTypes;
  linkEvidence?: string;
  trials: TrialRecord[];
};

export type StepExtraLatam = {
  tipoDocumento: string;
  nroDocumento: string;
  estado: string;
  nombreApellidos: string;
  antecedentePolicial: BackgroundCheckFieldResult;
  antecedenteJudicial: BackgroundCheckFieldResult;
  antecedentePenal: BackgroundCheckFieldResult;
  antecedenteExtranjeria: BackgroundCheckFieldResult;
}

export type StepExtraMexicanBuholegal = {
  entidad: string;
  resultados: {
    entidad: string;
    expedientes: {
      expediente: string;
      actor: string;
      demandado: string;
      entidad: string;
      juzgado: string;
      tipo: string;
      fuero: string;
      fecha: string;
      acuerdos: {
        acuerdo: string;
        fecha: string;
      }[];
      isContainFullName?: boolean;
    }[];
  }[];
}

export type BackgroundCheckStepExtra = StepExtraLatam | StepExtraMexicanBuholegal | StepExtraBrazil;

export interface IBackgroundCheckStepData<T = BackgroundCheckStepExtra> {
  name: string | null;
  curp: string | null;
  dateOfBirth: string | null;
  fullName: T extends StepExtraBrazil ? string : never;
  cpf: T extends StepExtraBrazil ? string : never;
  rg: T extends StepExtraBrazil ? string : never;
  birthday: T extends StepExtraBrazil ? string : never;
  age: number | null;
  gender: string | null;
  status: BackgroundCheckStatusesTypes;
  resource: null;
  results?: StepExtraBrazil[];
  timestamp: null;
  stepExtra?: T[] | null;
  documentNumber: T extends StepExtraLatam ? string : never;
}

export const BackgroundChecksSteps = [
  VerificationStepTypes.BackgroundMexicanBuholegal,
  VerificationStepTypes.BackgroundBrazilianChecks,
  VerificationStepTypes.BackgroundLatinAmericaChecks,
];

function parseCountryCheckOptions(options: IBackgroundCheck<BackgroundCheckBaseOptionsListItem>['options'], tags: MerchantTagsTypes[]): IBackgroundCheck<BackgroundCheckBaseOptionsListItemExtended>['options'] {
  if (!options?.list || !tags) {
    return options;
  }

  return {
    ...options,
    list: options.list.map((item) => ({
      ...item,
      isDisabled: item.merchantTags ? !item.merchantTags.every((requiredTag) => tags.includes(requiredTag)) : false,
    })),
  };
}

export function backgroundCheckParse(list: IBackgroundCheck<BackgroundCheckBaseOptionsListItem>[], pattern: Partial<IVerificationPatterns>, tags: MerchantTagsTypes[]): IBackgroundCheck<BackgroundCheckBaseOptionsListItemExtended>[] {
  return list.map((item) => ({
    ...item,
    value: pattern[item.id] !== undefined ? pattern[item.id] : item.default,
    options: parseCountryCheckOptions(item.options, tags),
  }));
}

export const getBackgroundCheckScoreByCrawlerStatus = (data: StepExtraBrazil[] = []) => data.reduce((results, crawler: StepExtraBrazil) => ({
  ...results,
  [crawler.status]: (results[crawler.status] ?? 0) + 1,
}), {
  [CrawlerStatusTypes.Approved]: 0,
  [CrawlerStatusTypes.LowRisk]: 0,
  [CrawlerStatusTypes.HighRisk]: 0,
});

export const mexicanStates = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Coahuila',
  'Colima',
  'Chiapas',
  'Chihuahua',
  'Ciudad de México',
  'Durango',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Estado de México',
  'Michoacán',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
];

const latamChecksSourceMap = {
  antecedentePolicial: 'policeRecords',
  antecedenteJudicial: 'judicialRecords',
  antecedentePenal: 'penalRecords',
  antecedenteExtranjeria: 'foreignInstituteRecords',
};

export const getBackgroundCheckScoreByExtraRecords = (data: BackgroundCheckStepExtra[] | null) => (data ? data.reduce((acc, record) => {
  Object.keys(latamChecksSourceMap)
    .forEach((recordKey) => {
      if (record[recordKey] === BackgroundCheckErrorTypes.LatamNoRecords) {
        // eslint-disable-next-line no-param-reassign
        acc[BackgroundCheckStatusesTypes.Approved] += 1;
      }

      if (record[recordKey] && record[recordKey] !== BackgroundCheckErrorTypes.LatamNoRecords) {
        // eslint-disable-next-line no-param-reassign
        acc[BackgroundCheckStatusesTypes.HighRisk] += 1;
      }
    });

  return acc;
}, {
  [BackgroundCheckStatusesTypes.Approved]: 0,
  [BackgroundCheckStatusesTypes.LowRisk]: 0,
  [BackgroundCheckStatusesTypes.HighRisk]: 0,
}) : {
  [BackgroundCheckStatusesTypes.Approved]: 0,
  [BackgroundCheckStatusesTypes.LowRisk]: 0,
  [BackgroundCheckStatusesTypes.HighRisk]: 0,
});

export const getBackgroundCheckScoreByLength = (steps: StepExtraMexicanBuholegal[] | null) => {
  const passed = new Set(mexicanStates);
  let fullMatchCounter = 0;
  let partialMatchCounter = 0;

  steps?.forEach((step) => {
    step.resultados.forEach((result) => {
      passed.delete(result.entidad);

      result.expedientes.forEach(({ isContainFullName }) => {
        if (isContainFullName === false) {
          partialMatchCounter += 1;
        } else {
          fullMatchCounter += 1;
        }
      });
    });
  });

  return {
    [BackgroundCheckStatusesTypes.Approved]: passed.size,
    [BackgroundCheckStatusesTypes.LowRisk]: partialMatchCounter,
    [BackgroundCheckStatusesTypes.HighRisk]: fullMatchCounter,
  };
};

type BrazilCheckResult = {
  passed: {
    title: string;
    link: string;
  }[];
  failed: {
    title: string;
    link: string;
  }[];
  unreachable: {
    title: string;
    link: string;
    trials: TrialRecord[];
  }[];
}

export const getBrazilChecks = (data: StepExtraBrazil[] = []): BrazilCheckResult => data.reduce((acc, record) => {
  const recordItem = {
    title: record.crawlerFullName,
    link: record.linkEvidence,
  };

  if (record.status === CrawlerStatusTypes.Approved) {
    acc.passed.push(recordItem);
  } else if ([CrawlerStatusTypes.LowRisk, CrawlerStatusTypes.HighRisk].includes(record.status)) {
    acc.failed.push({
      ...recordItem,
      trials: record.trials.sort((a, b) => {
        if (a.status === CrawlerStatusTypes.HighRisk) {
          return -1;
        }

        if (b.status === CrawlerStatusTypes.HighRisk) {
          return 1;
        }

        if (a.status === CrawlerStatusTypes.LowRisk) {
          return -1;
        }

        if (b.status === CrawlerStatusTypes.LowRisk) {
          return 1;
        }

        return 0;
      }),
    });
  } else {
    acc.unreachable.push(recordItem);
  }

  return acc;
}, {
  passed: [],
  failed: [],
  unreachable: [],
});

type FailedLatamItem = { title: string; result?: string };
type PassedLatamItem = { title: string }

export const getLatamChecks = (data: StepExtraLatam[] = []): { passed: PassedLatamItem[]; failed: FailedLatamItem[] } => {
  const passed: string[] = [];
  const failed: FailedLatamItem[] = [];
  const failedKeys = new Set();

  data.forEach((el) => {
    Object.keys(latamChecksSourceMap)
      .forEach((checkKey) => {
        if (el[checkKey]) {
          if (el[checkKey] !== BackgroundCheckErrorTypes.LatamNoRecords) {
            failedKeys.add(latamChecksSourceMap[checkKey]);
            failed.push({
              title: latamChecksSourceMap[checkKey],
              result: el[checkKey],
            });
          } else {
            passed.push(latamChecksSourceMap[checkKey]);
          }
        }
      });
  });

  return {
    passed: Array.from(new Set(passed))
      .filter((passedKey) => !failedKeys.has(passedKey))
      .map((checkKey) => ({ title: checkKey })),
    failed,
  };
};

export type MexicanBuholegalCheck = {
  state: string;
  records: {
    record: string;
    actor: string;
    defendant: string;
    state: string;
    court: string;
    type: string;
    jurisdiction: string;
    date: string;
    agreements: {
      agreement: string;
      date: string;
    }[];
    isFullyMatched?: boolean;
  }[];
};

const mapMexicanBuholegalCheck = (data: StepExtraMexicanBuholegal['resultados']): MexicanBuholegalCheck[] => {
  const mapAgreements = (agreements: ArrayElement<ArrayElement<StepExtraMexicanBuholegal['resultados']>['expedientes']>['acuerdos']): ArrayElement<ArrayElement<MexicanBuholegalCheck['records']>['agreements']>[] => agreements.map((agreement) => ({
    agreement: agreement.acuerdo,
    date: agreement.fecha,
  }));

  const mapRecords = (records: ArrayElement<ArrayElement<StepExtraMexicanBuholegal['resultados']>['expedientes']>[]): ArrayElement<MexicanBuholegalCheck['records']>[] => records.map((record) => ({
    record: record.expediente,
    actor: record.actor,
    defendant: record.demandado,
    state: record.entidad,
    court: record.juzgado,
    type: record.tipo,
    jurisdiction: record.fuero,
    date: record.fecha,
    agreements: mapAgreements(record.acuerdos),
    isFullyMatched: record.isContainFullName,
  }));

  const mapResults = (results: ArrayElement<StepExtraMexicanBuholegal['resultados']>[]): MexicanBuholegalCheck[] => results.map((res) => ({
    state: res.entidad,
    records: mapRecords(res.expedientes),
  }));

  return mapResults(data);
};

export const getMexicanBuholegalChecks = (data?: StepExtraMexicanBuholegal[]) => {
  const fullyMatched = {};
  const partialMatched = {};

  const transformPassed = (items: Set<string>) => Array.from(items)
    .map((state) => ({ state }));

  const passedSet = new Set(mexicanStates);

  data?.forEach(({ resultados }) => {
    mapMexicanBuholegalCheck(resultados)
      .forEach(({
        state,
        records,
      }) => {
        passedSet.delete(state);

        records.forEach((record) => {
          if (record.isFullyMatched === false) {
            if (!partialMatched[record.state]) {
              partialMatched[record.state] = [];
            }
            partialMatched[record.state].push(record);
          } else {
            if (!fullyMatched[record.state]) {
              fullyMatched[record.state] = [];
            }
            fullyMatched[record.state].push(record);
          }
        });
      });
  });

  return {
    passed: transformPassed(passedSet),
    fullyMatched: Object.entries(fullyMatched)
      .map(([state, records]) => ({
        state,
        records,
      })),
    partialMatched: Object.entries(partialMatched)
      .map(([state, records]) => ({
        state,
        records,
      })),
  };
};

type BackgroundChecksStepId = VerificationPatternTypes.BackgroundBrazilianChecks | VerificationPatternTypes.BackgroundLatinAmericaChecks | VerificationPatternTypes.BackgroundMexicanBuholegal;

export const getBackgroundCheckData = (verification: {
  steps: {
    id: BackgroundChecksStepId;
    data: IBackgroundCheckStepData;
    error: unknown;
  }[];
}): ({
  stepId: StepIds;
  summary: Omit<IBackgroundCheckStepData, 'stepExtra' | 'results'>;
  records: ReturnType<typeof getBrazilChecks | typeof getMexicanBuholegalChecks | typeof getLatamChecks>;
  score: {
    [BackgroundCheckStatusesTypes.Approved]: number;
    [BackgroundCheckStatusesTypes.LowRisk]: number;
    [BackgroundCheckStatusesTypes.HighRisk]: number;
  }; } | null) => {
  const bgCheckSteps: BackgroundChecksStepId[] = [
    VerificationPatternTypes.BackgroundBrazilianChecks,
    VerificationPatternTypes.BackgroundMexicanBuholegal,
    VerificationPatternTypes.BackgroundLatinAmericaChecks,
  ];

  const mapExtra = {
    [VerificationPatternTypes.BackgroundBrazilianChecks]: getBrazilChecks,
    [VerificationPatternTypes.BackgroundMexicanBuholegal]: getMexicanBuholegalChecks,
    [VerificationPatternTypes.BackgroundLatinAmericaChecks]: getLatamChecks,
  };

  const stepData: { id: StepIds; data: IBackgroundCheckStepData; error: unknown } | undefined = verification.steps.find((step) => step.data && bgCheckSteps.includes(step.id));

  if (!stepData) {
    return null;
  }

  const { id: stepId, data } = stepData;

  const score = getBackgroundCheckScore(stepId, data);
  const { stepExtra, results, ...summary } = data;

  // TODO: @aleksei.rasskazov remove condition after merging of LCW-430
  if (stepId === VerificationPatternTypes.BackgroundBrazilianChecks) {
    return { stepId, records: mapExtra[stepId]?.(results), summary, score } || null;
  }

  return { stepId, records: mapExtra[stepId]?.(stepExtra), summary, score } || null;
};

type BrazilBackgroundChecksFieldsOrder = keyof Pick<IBackgroundCheckStepData<StepExtraBrazil>, 'fullName' | 'cpf' | 'rg' | 'birthday'>;

export const brazilSummaryFieldsOrder: BrazilBackgroundChecksFieldsOrder[] = ['fullName', 'cpf', 'rg', 'birthday'];

type MexicanBuholegalBackgroundChecksFieldsOrder = keyof Pick<IBackgroundCheckStepData<StepExtraMexicanBuholegal>, 'name' | 'curp' | 'dateOfBirth' | 'gender' | 'age'>;

export const mexicanBuholegalSummaryFieldsOrder: MexicanBuholegalBackgroundChecksFieldsOrder[] = ['name', 'curp', 'dateOfBirth', 'gender', 'age'];

type LatamBackgroundChecksFieldsOrder = keyof Pick<IBackgroundCheckStepData<StepExtraLatam>, 'fullName' |'documentNumber' >;

export const latamSummaryFieldsOrder: LatamBackgroundChecksFieldsOrder[] = ['fullName', 'documentNumber'];

export type BackgroundScoreTypes =
  BackgroundCheckStatusesTypes.Approved
  | BackgroundCheckStatusesTypes.LowRisk
  | BackgroundCheckStatusesTypes.HighRisk;

export const checkStatusesMap: Record<BackgroundScoreTypes, { title: string }> = {
  [BackgroundCheckStatusesTypes.Approved]: {
    title: 'BackgroundCheck.verification.summary.noMatch',
  },
  [BackgroundCheckStatusesTypes.LowRisk]: {
    title: 'BackgroundCheck.verification.summary.lowRisk',
  },
  [BackgroundCheckStatusesTypes.HighRisk]: {
    title: 'BackgroundCheck.verification.summary.highRisk',
  },
};

export const formatDateShort = (value: string) => dayjs(value, [DateFormatTypes.DateShort, DateFormatTypes.DateShortStroke])
  .format(DateFormatTypes.FullMonthDateAndFullYear);

const nameFields = [
  'fullName',
  'name',
];

export const isNameField = (field: string): boolean => nameFields.includes(field);

export const isHiddenField = (field: string, stepId: StepIds): boolean => backgroundCheckDisplayOptions[stepId][field]?.hidden;

export type StructureSection = {
  titleKey: string;
  items: unknown[];
  component: ComponentType<any>;
}
