import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  failedLabel: {
    color: theme.palette.common.red,
  },
  statusCell: {
    minWidth: '106px',
  },
}));
