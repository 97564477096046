import { useSelector } from 'react-redux';
import { selectDataForCreditCheck } from 'apps/Verification';
import { IdentityStatuses, VerificationStatusChangeReason } from 'models/Status.model';
import { useMemo } from 'react';
import { IStep, StepCodeStatus } from 'models/Step.model';
import { CreditCheckStep, IDataForCreditCheck } from 'models/CreditCheck.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export function useCreditCheck(): {
  creditStep: IStep<CreditCheckStep>;
  id: string;
  isShowManualCreditCheckButton: boolean;
  isCheckInProgress: boolean;
  isPostResultPhase: boolean;
  isCreditMexicoCdc: boolean;
  isCreditBrazilianSerasa: boolean;
  isCreditBrazilianSerasaChecks: boolean;
  isCreditColombiaTransunion: boolean;
  } {
  const { verification, creditStep, id } = useSelector<any, IDataForCreditCheck>(selectDataForCreditCheck);
  const { value: verificationStatus, reasonCode } = verification?.verificationStatusDetails;
  const isPostResultPhase = [IdentityStatuses.reviewNeeded, IdentityStatuses.rejected, IdentityStatuses.verified].includes(verificationStatus);
  const isVerifiedBySystem = verificationStatus === IdentityStatuses.verified && reasonCode !== VerificationStatusChangeReason.ManualChange;
  const verificationPatterns = verification.flow.verificationPatterns;
  const isCreditMexicoCdc = verificationPatterns?.[VerificationPatternTypes.CreditMexicoCdc];
  const isCreditBrazilianSerasa = verificationPatterns?.[VerificationPatternTypes.CreditBrazilianSerasa];
  const isCreditBrazilianSerasaChecks = verificationPatterns?.[VerificationPatternTypes.CreditBrazilianSerasaChecks];
  const isCreditColombiaTransunion = verificationPatterns?.[VerificationPatternTypes.CreditColombiaTransunion];
  const isShowManualCreditCheckButton = useMemo(() => isPostResultPhase && !isVerifiedBySystem && !creditStep?.startedManuallyAt && !isCreditMexicoCdc,
    [isPostResultPhase, isVerifiedBySystem, creditStep?.startedManuallyAt, isCreditMexicoCdc]);
  const isCheckInProgress = [StepCodeStatus.Pending, StepCodeStatus.Running].includes(creditStep?.status) && !isShowManualCreditCheckButton;

  return { creditStep, id, isShowManualCreditCheckButton, isCheckInProgress, isPostResultPhase, isCreditMexicoCdc, isCreditBrazilianSerasa, isCreditBrazilianSerasaChecks, isCreditColombiaTransunion };
}
