import { http } from 'lib/client/http';
import { ClientMethodTypes } from 'models/Client.model';
import { isString } from 'lodash';
import { MEDIA_URL_REGEX, CDN_URL_REGEX, BLOB_URL } from '../models/Media.model';

export async function getMedia(src: string) {
  try {
    if (isString(src) && BLOB_URL.test(src)) {
      const blob = await (await fetch(src)).blob();
      return { data: blob };
    }
    if (isString(src) && MEDIA_URL_REGEX.test(src)) {
      const headers = await http.getHeaders(ClientMethodTypes.GET);
      const response = await fetch(src, { headers, credentials: 'include' });
      const blob = await response.blob();
      return { data: blob };
    }
    if (isString(src) && CDN_URL_REGEX.test(src)) {
      const blob = await (await fetch(src)).blob();
      return { data: blob };
    }
  } catch (error) {
    console.error(`Error fetching blob from the media URL: ${error}`);
  }
  console.error('Error: String not matching Media URL or CDN URL  or BLOB URL format');
  return null;
}
