import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { ICountryAndWorldPartStore, SliceNameTypes } from './CountryAndWorldPart.store';

const initialState: ICountryAndWorldPartStore = {
  [SliceNameTypes.CountryAndWorldParts]: LoadableAdapter.createState([]),
};

export const countryAndWorldPartReducer = createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.CountryAndWorldParts),
});
