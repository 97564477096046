import { permalinkUrl } from 'lib/client/urls';
import React, { useEffect } from 'react';
import { SdkEventTypes } from '../../models/WebSdkPreview.model';

export function WebSdkPreview({ merchantToken, flowId, onClose }: {
  merchantToken: string;
  flowId: string;
  onClose: () => void;
}) {
  const src: string = permalinkUrl({ clientId: merchantToken, flowId, color: 'blue', nopersist: true });

  useEffect(() => {
    function handleIframeMessage(event: MessageEvent<unknown>) {
      if (typeof event.data === 'string') {
        const { action } = JSON.parse(event.data);
        const [, actionName] = (action || '').split('::');
        if ([SdkEventTypes.ExitedSdk, SdkEventTypes.UserFinishedSdk].includes(actionName)) {
          onClose();
        }
      }
    }
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [onClose]);

  return (
    <iframe
      title="preview"
      frameBorder="0"
      allow="geolocation; microphone; camera; midi; encrypted-media;"
      src={src}
    />
  );
}
