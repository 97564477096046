import { Box, Button, Checkbox, FormControl, FormControlLabel, Typography } from '@material-ui/core';
import { Modal } from 'apps/overlay';
import { DocumentListOrdered, DocumentTypes } from 'models/Document.model';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantCustomDocumentsModel, selectMerchantTags } from 'state/merchant/merchant.selectors';
import { useFormatMessage } from 'apps/intl';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { useStyles } from './DocumentSelect.styles';

export function DocumentSelect({ checked, onSubmit, unavailable, custom, variant = 'add', onSelectSubTypes, stepIndex }: {
  checked: (DocumentTypes | string)[];
  unavailable: (DocumentTypes | string)[];
  onSubmit: (checked: (DocumentTypes | string)[]) => void;
  variant: 'change' | 'add';
  custom?: boolean;
  stepIndex: number;
  onSelectSubTypes: (stepIndex: number, checked: (DocumentTypes | string)[]) => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const merchantCustomDocumentsModel = useSelector(selectMerchantCustomDocumentsModel);
  const getDocTypesPool = useCallback(() => (custom ? merchantCustomDocumentsModel.value.map((customDocument) => customDocument.type) : DocumentListOrdered), [custom, merchantCustomDocumentsModel]);
  const [availableDocumentTypes, setAvailableDocumentTypes] = useState<(DocumentTypes| string)[]>(getDocTypesPool());
  const [selectedDocuments, setSelectedDocuments] = useState<(DocumentTypes | string)[]>(checked || []);
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const isDocumentSubTypesHidden = merchantTags.includes(MerchantTagsTypes.CanHideDocumentSubTypes);

  const handleCheck = useCallback((documentType: DocumentTypes | string) => () => {
    setSelectedDocuments((selected) => {
      if (selected.includes(documentType)) {
        return [...selected].filter((item) => documentType !== item);
      }

      return [...selected, documentType];
    });
  }, [setSelectedDocuments]);

  const handleSubmit = useCallback(() => {
    if (selectedDocuments?.length > 0) {
      onSubmit(selectedDocuments);
    }
  }, [selectedDocuments, onSubmit]);

  const handleSelectSubTypes = useCallback(() => {
    onSelectSubTypes(stepIndex, selectedDocuments);
  }, [onSelectSubTypes, selectedDocuments, stepIndex]);

  useEffect(() => {
    setAvailableDocumentTypes(getDocTypesPool().filter((doc) => checked?.includes(doc) || !unavailable?.includes(doc)));
  }, [checked, unavailable, custom, getDocTypesPool]);

  return (
    <Modal className={classes.modal}>
      <FormControl className={classes.control}>
        <Box mb={1} color="common.black90" fontWeight="bold" fontSize={18}>
          {formatMessage(`DocumentVerification.settings.title.${variant === 'add' ? 'addStep' : 'changeStep'}`)}
        </Box>
        <Box mb={2} color="common.black75">
          {formatMessage('DocumentVerification.settings.description.addStep')}
        </Box>
        <Box className={classes.wrapper}>
          {availableDocumentTypes.map((documentType, documentIndex) => (
            <React.Fragment key={documentType}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={selectedDocuments.includes(documentType)}
                      onChange={handleCheck(documentType)}
                      color="primary"
                    />
              )}
                  label={custom ? merchantCustomDocumentsModel.value.find((el) => el.type === documentType)?.name : formatMessage(`flow.documentTypeStep.${documentType}`)}
                />
                {documentType === DocumentTypes.NationalId && !isDocumentSubTypesHidden && (
                <Button className={classes.button} variant="contained" color="primary" onClick={handleSelectSubTypes} disabled={!selectedDocuments.includes(documentType)}>
                  {formatMessage('DocumentVerification.settings.description.subTypesSelection.button.selectSubType')}
                </Button>
                )}
              </Box>
              {documentIndex < availableDocumentTypes?.length - 1 && (<Typography>{formatMessage('DocumentVerification.settings.documentStep.or')}</Typography>)}
            </React.Fragment>
          ))}
        </Box>
        <Box mt="auto" textAlign="right">
          <Button className={classes.button} variant="contained" color="primary" disabled={selectedDocuments?.length < 1} onClick={handleSubmit}>
            {formatMessage(`DocumentVerification.settings.button.${variant === 'add' ? 'add' : 'save'}`)}
          </Button>
        </Box>
      </FormControl>
    </Modal>
  );
}
