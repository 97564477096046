import { Box } from '@material-ui/core';
import { CustomDocumentSettingsTypes } from 'apps/customDocument/models/CustomDocument.model';
import { DocumentStepSettings } from 'apps/documents/components/DocumentStepSettings/DocumentStepSettings';
import { ExtendedDescription, Warning } from 'apps/ui';
import { useMerchantPlan } from 'apps/merchant';
import { cloneDeep } from 'lodash';
import { DocumentTypes } from 'models/Document.model';
import { ProductSettingsProps } from 'models/Product.model';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormatMessage } from 'apps/intl';
import { CustomDocumentList } from '../CustomDocumentList/CustomDocumentList';

export function CustomDocumentsSettings({ settings, onUpdate, disabled }: ProductSettingsProps<CustomDocumentSettingsTypes>) {
  const formatMessage = useFormatMessage();
  const { isFreemium } = useMerchantPlan();
  const [steps, setSteps] = useState<(DocumentTypes | string)[][]>([]);

  useEffect(() => {
    setSteps(settings.neededSteps.value);
  }, [settings, setSteps]);

  const handleUpdateSteps = useCallback((newSteps: (DocumentTypes | string)[][]) => {
    const newSettings = cloneDeep(settings);
    newSettings.neededSteps.value = newSteps;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  return (
    <Box>
      {disabled ? (
        <Box mb={2}>
          {isFreemium ? (
            <Warning
              label={formatMessage('Freemium.productNotAvailable')}
              isLabelColored={false}
              meritName={formatMessage('CustomDocument')}
              bordered
            />
          ) : (
            <Warning
              label={formatMessage('CustomDocuments.settings.customDocumentNotAvailable')}
              linkLabel={formatMessage('CustomDocuments.settings.helpEmail')}
              isLabelColored={false}
              meritName={formatMessage('CustomDocument')}
              bordered
            />
          )}
        </Box>
      ) : (
        <Box mb={2}>
          <DocumentStepSettings custom steps={steps} onUpdate={handleUpdateSteps} />
        </Box>
      )}
      <Box>
        <ExtendedDescription
          isDisabled={disabled}
          title={formatMessage('CustomDocuments.settings.upload.title')}
          text={formatMessage('CustomDocuments.settings.upload.description')}
        />
      </Box>
      <CustomDocumentList disabled={disabled} />
    </Box>
  );
}
