import React, { DOMAttributes, ReactNode } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['mtm-notification-bubble']: DOMAttributes<HTMLElement> & {
        active?: boolean;
        inside?: boolean;
        type?: string;
      };
    }
  }
}

export function NotificationBubble({ type, active, inside, children }: {
  active?: boolean;
  inside?: boolean;
  type?: 'positive' | 'negative' | 'warning',
  children?: ReactNode;
}) {
  return (
    <mtm-notification-bubble
      active={active || undefined}
      inside={inside || undefined}
      type={type}
    >
      {children}
    </mtm-notification-bubble>
  );
}
