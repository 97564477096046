import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { IMeritBlockAdditionalStore, SliceNameTypes } from './MeritBlockAdditional.store';

const initialState: IMeritBlockAdditionalStore = {
  [SliceNameTypes.MeritBlocksAdditional]: LoadableAdapter.createState({}),
};

export const meritBlockAdditionalReducer = createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.MeritBlocksAdditional),
});
