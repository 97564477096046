import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import cloneDeep from 'lodash/cloneDeep';
import { IFlow } from 'models/Flow.model';
import { GovernmentChecksStepDataType } from 'models/GovernmentChecksMerit.model';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductTypes } from 'models/Product.model';
import { filterSteps, getStepStatus, StepStatus } from 'models/Step.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { FiFlag } from 'react-icons/fi';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { getPatternsConfigByProducts, productIsInFlow, removeVerificationPatternsByConfig, setPatternSettings, VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { getAllVerificationSteps } from 'models/VerificationPatterns.model';
import { GovCheckSettings } from '../components/GovCheckSettings/GovCheckSettings';
import { GovCheckVerification } from '../components/GovCheckVerification/GovCheckVerification';
import { getDefaultSettings, GovernmentCheckSettingTypes, GovernmentChecksTypes, IconByProductId, IGovernmentChecksVerificationData, ProductSettingsGovCheck, getGovCheckError, fixOldVerificationStep, DEFAULT_GOVERNMENT_CHECK_PRODUCT_SETTINGS } from '../models/GovCheckMerit.model';

export class GovCheckMerit extends ProductBaseFlowBuilder implements Product {
  id: ProductTypes;
  verificationPatternsConfig: VerificationPatternsConfigType;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  order = 300;
  requiredProductType = [
    ProductTypes.DocumentVerification,
    ProductTypes.CustomField,
  ];
  checks = [
    {
      id: GovernmentChecksTypes.GovernmentDatabaseCheck,
      isActive: true,
    },
  ];
  inputs = [
    ProductInputTypes.Documents,
  ];

  icon = FiFlag;
  component = GovCheckSettings;
  componentVerification = GovCheckVerification;

  constructor({ id, patternsConfig, disabled }: {id: ProductTypes; patternsConfig: VerificationPatternsConfigType; disabled?: boolean }) {
    super({ disabled });
    if (IconByProductId?.[id]) {
      this.icon = IconByProductId[id];
    }
    this.id = id;
    // @GevB hot fix
    this.verificationPatternsConfig = getPatternsConfigByProducts([this.id], patternsConfig || {});
  }

  isInFlow(flow: IFlow): boolean {
    return productIsInFlow(flow, this.verificationPatternsConfig);
  }

  getVerificationPatternsByProduct(): string[] {
    const { id, verificationPatternsConfig = {} } = this;

    return Object.entries(verificationPatternsConfig)
      .filter(([, configs]) => configs.some(({ product }) => product === id))
      .map(([patternKey]) => patternKey);
  }

  parser(flow: IFlow, productsInGraph?: ProductTypes[]): ProductSettingsGovCheck {
    const error = getGovCheckError(this.id, flow, productsInGraph, this.verificationPatternsConfig);

    return {
      [GovernmentCheckSettingTypes.PatternsConfig]: {
        value: cloneDeep(this.verificationPatternsConfig),
        error,
      },
      [GovernmentCheckSettingTypes.VerificationPatterns]: {
        value: flow.verificationPatterns,
        error,
      },
      [GovernmentCheckSettingTypes.PatternSettings]: {
        value: flow.patternSettings || {},
        error,
      },
      [GovernmentCheckSettingTypes.ProductSetting]: {
        value: flow.productSettings?.[this.id] || DEFAULT_GOVERNMENT_CHECK_PRODUCT_SETTINGS,
        error,
      },
      [GovernmentCheckSettingTypes.AgeThreshold]: {
        value: flow?.ageThreshold,
        error,
      },
    };
  }

  serialize(settings: ProductSettingsGovCheck, flow: IFlow): Partial<IFlow> {
    const verificationPatterns = cloneDeep(settings[GovernmentCheckSettingTypes.VerificationPatterns]?.value);
    const newPatternSettings = { ...flow.patternSettings, ...settings[GovernmentCheckSettingTypes.PatternSettings]?.value };
    const newVerificationPatterns = { ...flow.verificationPatterns, ...verificationPatterns };
    return {
      ageThreshold: settings.ageThreshold.value,
      verificationPatterns,
      productSettings: {
        [this.id]: settings[GovernmentCheckSettingTypes.ProductSetting]?.value,
      },
      patternSettings: setPatternSettings(newPatternSettings, newVerificationPatterns, this.verificationPatternsConfig, getDefaultSettings),
    };
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    const verificationPatterns = removeVerificationPatternsByConfig(this.verificationPatternsConfig);

    const facematchSources = flow?.facematchServiceConfig?.sources.filter((source) => source.type !== FacematchSourceTypes.GovermentCheck);

    return {
      productSettings: {
        [this.id]: DEFAULT_GOVERNMENT_CHECK_PRODUCT_SETTINGS,
      },
      patternSettings: setPatternSettings(flow.patternSettings, verificationPatterns, this.verificationPatternsConfig, getDefaultSettings),
      facematchServiceConfig: {
        ...flow.facematchServiceConfig,
        sources: facematchSources,
      },
      verificationPatterns,
    };
  }

  isInVerification(verification: VerificationResponse): boolean {
    const verificationSteps = fixOldVerificationStep(Object.keys(this.verificationPatternsConfig), this.id);
    return Boolean(getAllVerificationSteps<GovernmentChecksStepDataType>(verification, verificationSteps).length);
  }

  getVerification(verification: VerificationResponse): IGovernmentChecksVerificationData {
    const verificationSteps = fixOldVerificationStep(Object.keys(this.verificationPatternsConfig), this.id);

    const rootSteps = filterSteps<GovernmentChecksStepDataType>(verification.steps, verificationSteps);

    const documentWithSteps = verification?.documents?.map((document) => {
      const indexStepFromRoot = rootSteps.findIndex((step) => step?.connectedDocumentType === document.type);
      if (indexStepFromRoot !== -1) {
        const stepFromRoot = rootSteps.splice(indexStepFromRoot, 1);
        return {
          ...document,
          steps: filterSteps<GovernmentChecksStepDataType>([...document.steps, ...stepFromRoot], verificationSteps),
        };
      }
      return {
        ...document,
        steps: filterSteps<GovernmentChecksStepDataType>(document.steps, verificationSteps),
      };
    })
      .filter((document) => document.steps.length) || [];

    return {
      document: documentWithSteps,
      rootSteps,
    };
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const verificationSteps = fixOldVerificationStep(Object.keys(this.verificationPatternsConfig), this.id);
    return getAllVerificationSteps<GovernmentChecksStepDataType>(verification, verificationSteps).some((step) => getStepStatus(step) === StepStatus.Failure);
  }
}
