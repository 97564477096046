import classnames from 'classnames';
import { ProductMessageTypes } from 'models/Product.model';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './ProductExternalVerificationResultsContainer.styles';
import { selectMerchantSettings } from 'state/merchant';
import { selectVerificationId, selectVerificationIsEditable } from 'apps/Verification';
import { MessageClient } from '../../services/MessageClient';

const MIN_HEIGHT = 600;

export function ProductExternalVerificationResultsContainer({ data }) {
  const frame = useRef(null);
  const resultsMessageClient = useRef<Nullable<MessageClient>>(null);
  const styles = useStyles();
  const [height, setHeight] = useState(MIN_HEIGHT);
  const merchantSettings = useSelector(selectMerchantSettings);
  const verificationId = useSelector<any, string>(selectVerificationId);
  const isEditable = useSelector<any, boolean>(selectVerificationIsEditable);

  const meritResults = useMemo(() => ({
    ...(data?.results ?? {}),
    merchantSettings,
    verification: {
      id: verificationId,
      isEditable,
    },
  }), [data?.results]);

  useEffect(() => {
    if (frame.current) {
      resultsMessageClient.current = new MessageClient(frame.current);
      resultsMessageClient.current.on(ProductMessageTypes.Handshake, (payload) => resultsMessageClient.current.send(ProductMessageTypes.PullResults, meritResults));
      resultsMessageClient.current.on(ProductMessageTypes.Resize, (payload) => {
        if (payload?.height) {
          setHeight((payload.height > MIN_HEIGHT) ? payload.height : MIN_HEIGHT);
        }

        setHeight(MIN_HEIGHT);
      });
    }

    return () => {
      resultsMessageClient.current.close();
    };
  }, [data?.url, frame]);

  useEffect(() => {
    if (frame.current) {
      resultsMessageClient.current.send(ProductMessageTypes.PullResults, meritResults);
    }
  }, [meritResults, frame, resultsMessageClient]);

  return (
    <iframe
      className={classnames(styles.product, 'fs-exclude')}
      height={height}
      title="product"
      sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      src={`${data?.url}index.html`}
      ref={frame}
    />
  );
}
