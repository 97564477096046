import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { RecordView } from 'apps/ui';
import { LatamRecordFailedDetails } from './components/LatamRecordFailedDetails/LatamRecordFailedDetails';
import { useStyles } from './LatamRecordViewFailed.styles';

export type LatamRecordViewFailedItem = {
  title: string; result?: string;
}

export const LatamRecordViewFailed = ({
  title,
  result,
}: LatamRecordViewFailedItem) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.institution'),
        value: formatMessage(`BackgroundCheck.verification.jsonView.${title}`),
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.recordsFound'),
        valueClass: classes.failedLabel,
      }}
      details={<LatamRecordFailedDetails result={result} />}
    />
  );
};
