import React from 'react';
import { TabIDTypes } from '../../models/ForDevelopers.model';
import { ApiIntegrationInformation } from './ApiIntegrationInformation';
import { WebIntegrationInformation } from './WebIntegrationInformation';
import { DirectLinkIntegrationInformation } from './DirectLinkIntegrationInformation';

export function Information({ selectedPage }: {
  selectedPage: TabIDTypes;
}) {
  switch (selectedPage) {
    case TabIDTypes.Api:
      return (<ApiIntegrationInformation />);

    case TabIDTypes.Web:
      return (<WebIntegrationInformation />);

    case TabIDTypes.DirectLink:
      return (<DirectLinkIntegrationInformation />);

    default:
      return null;
  }
}
