import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { useFormatMessage } from 'apps/intl';
import type { IStep } from 'models/Step.model';
import type { IBackgroundCheckStepData } from 'models/BackgroundCheck.model';
import { FiDownload } from 'react-icons/fi';
import { useStyles, DownloadJsonButton } from './BackgroundCheckControls.styles';

export function BackgroundCheckControls({
  isVisible,
  onSetVisibility,
  step,
  isToggleVisible,
}: {
  isVisible?: boolean;
  onSetVisibility?: (isVisible: boolean) => void;
  step: IStep<IBackgroundCheckStepData>;
  isToggleVisible?: boolean;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
      <Grid item />
      <Grid item className={classes.controls}>
        <DownloadJsonButton
          variant="outlined"
          href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(step.data))}`}
          download="background_check.json"
          startIcon={<FiDownload />}
        >
          {formatMessage('BackgroundCheck.verification.downloadJson')}
        </DownloadJsonButton>
        {!isToggleVisible && (
          <Box className={classes.toggle}>
            <Typography className={classes.toggleLabel}>
              {formatMessage('BackgroundCheck.verification.downloadJson.checkbox')}
            </Typography>
            <Switch
              checked={isVisible}
              color="primary"
              onChange={() => onSetVisibility?.(!isVisible)}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
