export enum WatchlistFileExtTypes {
  Csv = 'csv',
  Xls = 'xls',
}

export function handleDownloadSampleClick() {
  const rows = [
    ['fullName', 'dateOfBirth', 'documentType', 'documentNumber', 'country', 'emailAddress', 'phoneNumber'],
    ['Entry Full Name', '23-11-1992', 'passport', 'ABC-123456789', 'MX', 'some@mail.com', '+5293656676768'],
  ];

  const csvContent = `data:text/csv;charset=utf-8,${rows.map((row) => row.join(',')).join('\n')}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'sample_data.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
}
