import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const StyledLinkButton = withStyles((theme) => ({
  root: {
    fontSize: 14,
    color: theme.palette.button.document.contrastText,
    backgroundColor: theme.palette.button.document.main,
    whiteSpace: 'nowrap',
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  endIcon: {
    width: 17,
  },
}))(Button);
