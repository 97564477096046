import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    width: 670,
    padding: '35px 20px 20px',
    '& .MuiIconButton-root': {
      minHeight: 'initial',
      padding: 0,
    },
  },
  video: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  label: {
    '&.MuiInputLabel-root': {
      marginBottom: 8,
      fontWeight: 'bold',
      color: theme.palette.text.main,
    },
  },
  input: {
    '& .MuiInputBase-root': {
      color: theme.palette.text.main,
    },
  },
  showPasswordButton: {
    marginRight: 5,
  },
}));
