import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& div': {
      alignItems: 'center',
    },
  },
  btn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '24px',
    padding: '3px 5px',
    gap: '4px',
    width: '82px',
  },
  warnTitle: {
    color: theme.palette.common.black75,
    opacity: 0.3,
  },
  blueBtn: {
    background: theme.palette.common.whiteblue,
    color: theme.palette.common.lightblue,
  },
  warnBtn: {
    background: theme.palette.common.lightyellow,
    color: theme.palette.common.tangerineapprox,
  },
  editedBtn: {
    background: theme.palette.common.black7,
    color: theme.palette.common.black75,
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 5,
  },
}));
