import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { ReactComponent as MeritCloseIcon } from 'assets/icon-merit-close.svg';
import { FLOW_BUILDER_DRAG_PRODUCT_DATA_TRANSFER_KEY } from 'models/DragAndDrop.model';
import { selectNeverUsedMerits, selectProductCards, UIProductCard } from 'apps/Product';
import { useFormatMessage, useFormatServerMessage } from 'apps/intl';
import { InfoTooltip, TabsComponent } from 'apps/ui';
import { IProductCard, ProductTypes } from 'models/Product.model';
import { TemplatesButton } from 'apps/SolutionCatalog';
import type { IMeritBlockCategory, IMeritBlockContent, IMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { useLoadMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { Loadable } from 'models/Loadable.model';
import { selectFlowBuilderProductsInGraphModel } from '../../store/FlowBuilder.selectors';
import { useStyles } from './ProductListSidebar.styles';

type CategoryName = string;

export function ProductListSidebar() {
  const classes = useStyles();
  const cards = useSelector(selectProductCards);
  const formatServerMessage = useFormatServerMessage();
  const productsInGraphModel = useSelector(selectFlowBuilderProductsInGraphModel);
  const neverUsedMerits = useSelector(selectNeverUsedMerits);
  const formatMessage = useFormatMessage();
  const [isHide, setIsHide] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryName>();
  const cardsNotInFlow = useMemo(() => cards?.filter((card: IProductCard) => productsInGraphModel.value.every((type) => type !== card.id)), [productsInGraphModel, cards]);

  const meritBlocksAdditionalModel: Loadable<IMeritBlocksAdditional> = useLoadMeritBlocksAdditional();
  const meritBlocksAdditional: IMeritBlocksAdditional = useMemo(() => meritBlocksAdditionalModel?.value || {}, [meritBlocksAdditionalModel]);
  const meritBlocksAdditionalObjValues: IMeritBlockContent[] = useMemo(() => Object.values(meritBlocksAdditional), [meritBlocksAdditional]);
  const uniqueCategories: IMeritBlockCategory[] = useMemo(() => Array.from(new Set(meritBlocksAdditionalObjValues.map((value) => value?.category?.name))).map((categoryName) => meritBlocksAdditionalObjValues.find((meritBlockObj: IMeritBlockContent) => meritBlockObj?.category?.name === categoryName)?.category), [meritBlocksAdditionalObjValues]);
  const categoriesDisplayNames: CategoryName[] = useMemo(() => uniqueCategories?.sort((A: IMeritBlockCategory, B: IMeritBlockCategory) => A.orderOfCategory - B.orderOfCategory)?.map((category) => formatMessage(`Tools.category.${category.name.replace(' ', '')}`)), [uniqueCategories, formatMessage]);
  const categoriesNameKeys: CategoryName[] = useMemo(() => uniqueCategories?.map((category) => category?.name), [uniqueCategories]);
  if (categoriesNameKeys?.length > 0 && !selectedCategory) {
    setSelectedCategory(categoriesNameKeys[0]);
  }
  // TODO @dkchv: !!! move to selectors
  const cardsNotInPlan: IProductCard[] = useMemo(() => cardsNotInFlow?.filter((card: IProductCard) => card.disabled)?.filter((card) => meritBlocksAdditional[card.id]?.category?.name === selectedCategory)
    ?.sort((cardA: IProductCard, cardB: IProductCard) => (cardA?.id > cardB?.id ? 1 : -1)), [cardsNotInFlow, selectedCategory, meritBlocksAdditional]);
  const cardsInPlan: IProductCard[] = useMemo(() => cardsNotInFlow?.filter((card: IProductCard) => !card.disabled)?.filter((card) => meritBlocksAdditional[card.id]?.category?.name === selectedCategory)
    ?.sort((cardA: IProductCard, cardB: IProductCard) => (cardA?.id > cardB?.id ? 1 : -1)), [cardsNotInFlow, selectedCategory, meritBlocksAdditional]);

  const handleDragStart = useCallback((productId: ProductTypes) => (event) => {
    event.dataTransfer.setData(FLOW_BUILDER_DRAG_PRODUCT_DATA_TRANSFER_KEY, productId);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  }, []);

  const handleShowAndHide = () => setIsHide((prev) => !prev);
  const toggleClose = () => setIsClose((prev) => !prev);

  const updateCurrSelectedCategory = (currSelectedCat: string) => {
    setSelectedCategory(currSelectedCat);
  };

  return (
    <Box>
      {isClose
        ? (
          <Box onClick={toggleClose} className={classes.meritCloseBtn}>
            <InfoTooltip
              placement="bottom-start"
              title={formatMessage('FlowBuilder.product.meritTooltip')}
            >
              <MeritCloseIcon />
            </InfoTooltip>
          </Box>
        )
        : (
          <Box className={classes.container} p={0.4}>
            <Grid item container alignItems="center" justifyContent="space-between">
              <Box p={1.6} className={classes.meritBlockHeaderText} fontWeight="bold">
                {formatMessage('FlowBuilder.products')}
              </Box>
              <Box onClick={toggleClose} className={classes.closeButton}>
                <Close />
              </Box>
            </Grid>
            <Grid item>
              <Box px={1.6}>
                <TemplatesButton />
              </Box>
            </Grid>
            {meritBlocksAdditionalModel?.isLoaded
              && (
                <Grid item container alignContent="center" justifyContent="space-between">
                  <TabsComponent updateCurrSelectedCategory={updateCurrSelectedCategory} categoryTabsDisplayNames={categoriesDisplayNames} tabs={categoriesNameKeys || []}>
                    <Box px={1.6} pb={1} className={classes.newList}>
                      {cardsInPlan.map((productCard) => (
                        <div key={productCard.id} onDragStart={handleDragStart(productCard.id)} draggable>
                          <Box mb={1}>
                            <UIProductCard isNeverUsed={neverUsedMerits.includes(productCard.id)} card={productCard} meritBlocksAdditional={meritBlocksAdditional} />
                          </Box>
                        </div>
                      ))}
                      {cardsNotInPlan?.length > 0 && (
                      <Grid item container justifyContent="space-between">
                        <Box pb={1} pt={1} display="flex">
                          <div>{formatMessage('FlowBuilder.products.notInYourPlan')}</div>
                          {isHide && <div className={classes.notification}>{cardsNotInPlan?.length}</div>}
                        </Box>
                        <Box pb={1} pt={1} className={classes.active} onClick={handleShowAndHide}>
                          {isHide ? formatMessage('FlowBuilder.products.show') : formatMessage('FlowBuilder.products.hide') }
                        </Box>
                      </Grid>
                      )}
                      {cardsNotInPlan.map((productCard) => (
                        <div key={productCard.id} onDragStart={handleDragStart(productCard.id)} draggable>
                          <Box mb={1} className={isHide ? classes.inactive : null}>
                            <UIProductCard isNeverUsed={neverUsedMerits.includes(productCard.id)} card={productCard} meritBlocksAdditional={meritBlocksAdditional} />
                          </Box>
                        </div>
                      ))}
                    </Box>
                  </TabsComponent>
                </Grid>
              )}
          </Box>
        )}
    </Box>
  );
}
