import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { BiometricConfiguration, IBiometricVerification } from 'apps/biometricVerification';
import { FaceMatchingThreshold } from 'apps/facematch';
import { appPalette } from 'apps/theme';
import { FACEMATCH_DEFAULT_REVERIFICATION_THRESHOLD, REVERIFICATION_FACEMATCH_THRESHOLDS } from 'apps/facematch/models/facematch.model';
import { ExtendedDescription } from 'apps/ui';
import cloneDeep from 'lodash/cloneDeep';
import { ProductSettingsProps } from 'models/Product.model';
import { useIntl } from 'react-intl';
import { ReVerificationChallengeTypes, ReVerificationSettingTypes, ReVerificationTypes } from '../../models/ReVerification.model';

export function ReVerificationSettings({ settings, onUpdate }: ProductSettingsProps<ReVerificationSettingTypes>) {
  const intl = useIntl();
  const handleReFacematchChanges = useCallback((reFacematchThreshold: number | null): void => {
    const newSettings = cloneDeep(settings);
    newSettings.reFacematchThreshold.value = reFacematchThreshold;
    onUpdate(newSettings);
  }, [settings, onUpdate]);
  const handleBiometricsChanges = useCallback((biometrics: Nullable<string>, biometricVerificationPattern?: boolean, biometricsVerification?: Nullable<IBiometricVerification>): void => {
    const newSettings = cloneDeep(settings);
    if (biometricsVerification) {
      newSettings[ReVerificationSettingTypes.BiometricsVerification] = {
        value: biometricsVerification,
      };
    } else {
      newSettings[ReVerificationSettingTypes.BiometricsVerification] = {
        value: { input: null, challenge: null, provider: null },
      };
    }
    newSettings[ReVerificationSettingTypes.Biometrics].value = biometrics;
    newSettings[ReVerificationSettingTypes.BiometricsVerificationPattern].value = !!biometricVerificationPattern;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const getBioMetricValue = useCallback(() => {
    if (settings[ReVerificationSettingTypes.BiometricsVerificationPattern].value && settings[ReVerificationSettingTypes.BiometricsVerification].value?.challenge) {
      if (settings[ReVerificationSettingTypes.BiometricsVerification].value?.challenge === ReVerificationChallengeTypes.ACTIVE) {
        return ReVerificationTypes.LivenessNistActive;
      }
      return ReVerificationTypes.LivenessNistPassive;
    }
    return settings[ReVerificationSettingTypes.Biometrics].value;
  }, [settings]);

  return (
    <Box>
      <Box mb={4}>
        <ExtendedDescription
          title={intl.formatMessage({ id: 'ReVerification.settings.biometrics.title' })}
          text={intl.formatMessage({ id: 'ReVerification.settings.biometrics.description' })}
        >
          <BiometricConfiguration
            isReVerification
            biometrics={getBioMetricValue()}
            proofOfOwnership={settings.proofOfOwnership.value}
            onUpdate={handleBiometricsChanges}
          />
        </ExtendedDescription>
      </Box>
      <Box mb={4}>
        <ExtendedDescription
          title={intl.formatMessage({ id: 'ReVerification.settings.facematch.title' })}
          text={intl.formatMessage({ id: 'ReVerification.settings.facematch.description' })}
        >
          <FaceMatchingThreshold
            defaultThreshold={FACEMATCH_DEFAULT_REVERIFICATION_THRESHOLD}
            thresholds={REVERIFICATION_FACEMATCH_THRESHOLDS}
            facematchThreshold={settings.reFacematchThreshold.value}
            onUpdate={handleReFacematchChanges}
          />
        </ExtendedDescription>
      </Box>
    </Box>
  );
}
