import React, { useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Modal } from 'apps/overlay';
import { useFormatMessage } from 'apps/intl';
import { useForm, FormProvider } from 'react-hook-form';
import { IFlowFinancialInformationChecks } from 'models/Flow.model';
import { CurrenciesTypes } from 'models/Country.model';
import { SUPPORTED_BALANCE_CHECKS, SUPPORTED_TRANSACTION_CHECKS } from '../../models/BankAccountData.model';
import { useModalStyles } from './FinancialChecksModal.styles';
import { FinancialChecksModalListBlock } from './FinancialChecksModalListBlock';

export function FinancialChecksModal({ selectedChecks, supportedCurrencies, closeOverlay, onUpdate }: {
  selectedChecks?: IFlowFinancialInformationChecks;
  supportedCurrencies: CurrenciesTypes[];
  closeOverlay: () => void;
  onUpdate: (data: IFlowFinancialInformationChecks) => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useModalStyles();

  const checkboxesValues = useMemo(() => Object.fromEntries(
    Object.entries(selectedChecks).map((entry) => [`${entry[0]}_check`, true]),
  ), [selectedChecks]);

  const formMethods = useForm({
    defaultValues: { ...selectedChecks, ...checkboxesValues },
    shouldUnregister: true,
  });
  const { handleSubmit } = formMethods;

  const handleFormSubmit = useCallback((data) => {
    const checks = Object.fromEntries(Object.entries(data).filter(([key]) => !key.includes('_check')));
    onUpdate(checks);
    closeOverlay();
  }, [closeOverlay, onUpdate]);

  return (
    <Modal
      onClose={closeOverlay}
      description={(
        <Box pb={3}>
          <Typography variant="h4" gutterBottom className={classes.modalTitle}>
            {formatMessage('BankAccountData.financialChecks.library')}
          </Typography>
          <Box className={classes.modalSubTitle}>
            {formatMessage('BankAccountData.financialChecks.library.description')}
          </Box>
        </Box>
      )}
      className={classes.modal}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box className={classes.content}>
            <Box mb={2} fontSize={14} fontWeight={700}>
              {formatMessage('BankAccountData.financialChecks.balanceChecks')}
            </Box>
            <FinancialChecksModalListBlock list={SUPPORTED_BALANCE_CHECKS} supportedCurrencies={supportedCurrencies} />
            <Box my={2} fontSize={14} fontWeight={700}>
              {formatMessage('BankAccountData.financialChecks.transactionChecks')}
            </Box>
            <FinancialChecksModalListBlock list={SUPPORTED_TRANSACTION_CHECKS} supportedCurrencies={supportedCurrencies} />
          </Box>
          <Box py={1.5} className={classes.btnGroup}>
            <Button color="primary" onClick={closeOverlay}>
              {formatMessage('Button.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              {formatMessage('Button.apply')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Modal>
  );
}
