import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { useFormatMessage } from 'apps/intl';
import { ReactComponent as DragButton } from 'assets/file-drag.svg';
import classnames from 'classnames';
import { replaceElementsInArray } from 'lib/array';
import React, { useState } from 'react';
import { Modal } from 'apps/overlay';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ITemplate } from 'apps/Templates/index';
import { useStyles } from './ChangeTemplatesOrderModal.styles';

export function ChangeTemplatesOrderModal({ templates, onSave }: {
  templates: ITemplate[];
  onSave: (reorderedTemplates: ITemplate[]) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [orderedTemplates, setOrderedTemplates] = useState<ITemplate[]>(templates);

  const handleSave = () => onSave(orderedTemplates);
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedTemplates: ITemplate[] = replaceElementsInArray(orderedTemplates, result.source.index, result.destination.index);
    setOrderedTemplates(reorderedTemplates);
  };

  return (
    <Modal
      className={classes.modal}
      wideHeader
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="droppable"
          renderClone={(provided, _, rubric) => (
            <Card
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              className={classes.dragItem}
            >
              <Box className={classes.dragButton}>
                <DragButton />
              </Box>
              <Box className={classnames(classes.fullWidth, classes.ellipsis)}>
                {orderedTemplates[rubric.source.index].name}
              </Box>
            </Card>
          )}
        >
          {(provided) => (
            <Card
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.dropComponent}
            >
              {orderedTemplates.map((template, index) => (
                <Draggable key={template._id} draggableId={template._id} index={index}>
                  {(dragProvided) => (
                    <Card
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      className={classes.dragItem}
                    >
                      <Box className={classes.dragButton} {...dragProvided.dragHandleProps}>
                        <DragButton />
                      </Box>
                      <Box className={classnames(classes.fullWidth, classes.ellipsis)}>
                        {template.name}
                      </Box>
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Card>
          )}
        </Droppable>
      </DragDropContext>
      <Grid container justifyContent="flex-end">
        <Box width={180} mt={2}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            fullWidth
            onClick={handleSave}
          >
            {formatMessage('Templates.changeOrderModal.button.save')}
          </Button>
        </Box>
      </Grid>
    </Modal>
  );
}
