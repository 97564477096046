import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOverlay } from 'apps/overlay';
import { ProductTypes } from 'models/Product.model';
import { flowBuilderProductAddFacematchEmpty, selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { productManagerService } from 'apps/Product';
import { FacematchAddMeritModal } from '../components/FacematchAddMeritModal/FacematchAddMeritModal';

export function useAddFacematch() {
  const [createOverlay, closeOverlay] = useOverlay();
  const dispatch = useDispatch();
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraph);

  return useCallback((productType: ProductTypes, onAdd: (productType: ProductTypes) => void) => {
    const { requiredProductType } = productManagerService.getProduct(productType).getCard();

    const handleConfirm = () => {
      onAdd(productType);
      if (!Array.isArray(requiredProductType)) {
        onAdd(requiredProductType);
      } else {
        requiredProductType.forEach((type) => onAdd(type));
      }
      closeOverlay();
    };

    const handleClose = () => {
      dispatch(flowBuilderProductAddFacematchEmpty());
      closeOverlay();
    };
    if (requiredProductType && productsInGraph.every((type) => type !== requiredProductType)) {
      createOverlay(<FacematchAddMeritModal onClose={handleClose} onConfirm={handleConfirm} />);
    } else {
      onAdd(productType);
    }
  }, [createOverlay, closeOverlay, dispatch, productsInGraph]);
}
