import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useFormatMessage } from 'apps/intl';
import React, { useCallback, useEffect, useRef } from 'react';
import { ExtendedDescription } from 'apps/ui';
import { useDispatch, useSelector } from 'react-redux';
import { ProductTypes } from 'models/Product.model';
import { flowBuilderProductAdd, selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { useOtherProductAdding } from 'apps/Product';
import { IVerificationPatternInputConfig, ProductByVerificationInput, VerificationInputTypes } from 'models/VerificationPatternsConfigs.model';

export function DataSourceSelect({ handleChangeDataSource, selectedDataSource, inputs, allowDefault = false, addProductOnDestroy = false, disabled = false }: {
  handleChangeDataSource: (value: VerificationInputTypes) => void;
  selectedDataSource: VerificationInputTypes;
  allowDefault?: boolean;
  inputs?: IVerificationPatternInputConfig[];
  addProductOnDestroy?: boolean;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const createAddOtherProductModalOverlay = useOtherProductAdding();

  const componentWillUnmount = useRef(false);

  const productsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);

  const handleAddProduct = useCallback((value: VerificationInputTypes) => {
    const product = ProductByVerificationInput[value];
    const handleChange = () => {
      if (!addProductOnDestroy) {
        handleChangeDataSource(value);
      }
    };
    if (product && !productsInGraph.includes(product)) {
      createAddOtherProductModalOverlay(
        product,
        null,
        () => {
          dispatch(flowBuilderProductAdd(product));
          handleChange();
        },
      );
      return;
    }
    handleChange();
  }, [addProductOnDestroy, createAddOtherProductModalOverlay, dispatch, handleChangeDataSource, productsInGraph]);

  const handleChange = ({ target: { value } }) => {
    if (!addProductOnDestroy) {
      handleAddProduct(value);
      return;
    }
    handleChangeDataSource(value);
  };

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current && addProductOnDestroy && !disabled) {
      handleAddProduct(selectedDataSource);
    }
  }, [addProductOnDestroy, disabled, handleAddProduct, selectedDataSource]);

  return (
    <ExtendedDescription
      title={formatMessage('dataSource.title')}
      text={formatMessage('dataSource.description')}
    >
      <Select
        fullWidth
        disabled={disabled}
        value={selectedDataSource}
        onChange={handleChange}
        variant="outlined"
      >
        {allowDefault && <MenuItem value={VerificationInputTypes.Default}>{formatMessage('dataSource.default')}</MenuItem>}
        {inputs?.map((input) => <MenuItem key={input.id} value={input.id}>{formatMessage(`dataSource.${input.id}`)}</MenuItem>) || (
          [
            <MenuItem key={VerificationInputTypes.DocumentVerification} value={VerificationInputTypes.DocumentVerification}>{formatMessage(`dataSource.${VerificationInputTypes.DocumentVerification}`)}</MenuItem>,
            <MenuItem key={VerificationInputTypes.CustomField} value={VerificationInputTypes.CustomField}>{formatMessage(`dataSource.${VerificationInputTypes.CustomField}`)}</MenuItem>,
          ]
        )}
      </Select>
    </ExtendedDescription>
  );
}
