import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&:hover, &:focus': {
      '& $controlTrash': {
        opacity: 1,
        visibility: 'visible',
      },
      border: 'none',
      '&:hover': {
        borderRadius: '0px !important',
      },
    },
  },
  iconWrapper: {
    flexShrink: 0,
    width: 48,
    height: 48,
    borderRadius: '50%',
    fontSize: 20,
    color: theme.palette.common.lightblue,
    backgroundColor: theme.palette.common.whiteblue,
  },
  icon: {
    strokeWidth: 1.5,
  },
  control: {
    position: 'absolute',
    width: 25,
    minWidth: 'auto',
    height: 25,
    padding: 0,
    borderRadius: '50%',
  },
  controlTrash: {
    bottom: 0,
    right: 10,
    color: theme.palette.common.red,
    backgroundColor: theme.palette.common.white,
    transform: 'translateY(-50%)',
    opacity: 0,
    visibility: 'hidden',
    transition: '.2s all ease-in-out',
  },
  controlIssues: {
    position: 'absolute',
    padding: 0,
    top: 0,
    right: 13,
    minWidth: 'auto',
    cursor: 'default',
    color: theme.palette.common.yellow,
    backgroundColor: theme.palette.common.white,
    transform: 'translateY(50%)',
  },
  tooltipArrow: {
    '&::before': {
      backgroundColor: theme.palette.common.black90,
    },
  },
  modal: {
    minHeight: 'auto',
    padding: 25,
    '& > div': {
      position: 'static',
      transform: 'none',
    },
  },
  processesTitle: {
    display: 'inline-block',
    lineHeight: '14.5px',
    fontSize: '12px',
    fontWeight: 600,
  },
  popperClassName: {
    marginTop: '-4px',
  },
  arrowClassName: {
    marginLeft: '-8px !important',
    color: theme.palette.common.black90,
  },
  processBadge: {
    borderRadius: '6px',
    border: `1px solid ${theme.palette.common.green}`,
    fontSize: '12px',
    fontWeight: 400,
    color: '#2D3648',
    marginRight: '4px',
    marginTop: '6px',
  },
  inputBadge: {
    borderRadius: '6px',
    border: `1px solid ${theme.palette.common.black7}`,
    fontSize: '12px',
    fontWeight: 400,
    color: '#2D3648',
    marginRight: '4px',
    marginTop: '6px',
  },
  badgesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));
