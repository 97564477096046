import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
  },
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  closeButton: {
    cursor: 'pointer',
    position: 'relative',
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.gray,
    borderRadius: 10,
    transition: '.25s all ease-in-out',
    '&:hover, &:focus': {
      filter: 'brightness(0.8)',
      backgroundColor: theme.palette.common.gray,
    },
  },
  padding: {
    padding: theme.spacing(3),
  },
  modal: {
    backgroundColor: theme.palette.common.white,
    padding: 20,
  },
}));
