import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { flowBuilderChangeableFlowUpdate, selectFlowBuilderChangeableFlow } from 'apps/flowBuilder';
import { useFormatMessage } from 'apps/intl';
import { QATags } from 'models/QA.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { useStyles } from './SDKCustomization.style';
import { ColorCustomization } from './ColorCustomization';
import { LogoCustomization } from './LogoCustomization';
import { TypographyCustomization } from './TypographyCustomization';
import { CustomCSS } from './CustomCSS';
import { Button, Icon, Quote } from 'lib/UI';
import { useOverlay } from 'apps/overlay';

const defaultAppearance = {
  properties: [],
  isDisplayedPoweredByMetamap: true,
};

export function SDKCustomization({ closePanel }: { closePanel: () => void }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tags = useSelector(selectMerchantTags);
  const changeableFlow = useSelector(selectFlowBuilderChangeableFlow);
  const canUseCustomCss = tags.includes(MerchantTagsTypes.CanUseCustomCss);
  const [tab, setTab] = useState(0);
  const [createOverlay, closeOverlay] = useOverlay();

  const handleSetToDefaultClick = () => {
    createOverlay(
      <Box
        display="flex"
        flexDirection="column"
        className={classes.modal}
      >
        <Box>
          <Typography variant="h3">
            {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.resetToDefaults.title')}
          </Typography>
        </Box>
        <Box
          pt={1}
          flex={1}
          pb={2}
          width={300}
        >
          <Typography variant="body1" color="textSecondary">
            {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.resetToDefaults.message')}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          gridGap="8px"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            size="large"
            type="secondary"
            onClick={closeOverlay}
          >
            {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.resetToDefaults.cancel')}
          </Button>
          <Button
            size="large"
            type="secondary"
            destructive
            onClick={() => {
              dispatch(flowBuilderChangeableFlowUpdate({ appearance: defaultAppearance }));
              closeOverlay();
            }}
          >
            {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.resetToDefaults.confirm')}
          </Button>
        </Box>
      </Box>,
    );
  };

  const handleUpdate = (appearance) => dispatch(flowBuilderChangeableFlowUpdate({ appearance }));

  return (
    <Box p={0.4} className={classes.root}>
      <Box p={1.6} pt={0.6} className={classes.container}>
        <Box mb={1}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            gridGap="9px"
          >
            <Box fontWeight="bold" color="common.black90">
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm')}
            </Box>
            <Box flex={1} />
            <Box
              onClick={closePanel}
              className={classes.closeButton}
            >
              <Close />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            gridGap="10px"
            py="8px"
          >
            <Button
              type="secondary"
              size="large"
              data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.tabs.Styling}
              onClick={() => setTab(0)}
            >
              <Icon name="customize" />
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Styling')}
            </Button>
            <Button
              type="secondary"
              size="large"
              disabled={!canUseCustomCss}
              data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.tabs.customCSS}
              onClick={() => setTab(1)}
            >
              <Icon name="code" />
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.CustomCSS')}
            </Button>
          </Box>
          <Box flexGrow={1}>
            {(tab === 0) && (
              <>
                <Box my={1}>
                  <Quote warning>
                    {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.missingFeaturesDisclaimer')}
                  </Quote>
                </Box>
                <LogoCustomization
                  appearance={changeableFlow.appearance ?? defaultAppearance}
                  onChange={handleUpdate}
                />
                <TypographyCustomization
                  appearance={changeableFlow.appearance ?? defaultAppearance}
                  onChange={handleUpdate}
                />
                <ColorCustomization
                  appearance={changeableFlow.appearance ?? defaultAppearance}
                  onChange={handleUpdate}
                />
              </>
            )}
            {(tab === 1) && (
              <CustomCSS
                appearance={changeableFlow.appearance ?? defaultAppearance}
                onChange={handleUpdate}
              />
            )}
          </Box>
          <Box mt={2}>
            <Button
              type="secondary"
              destructive
              onClick={handleSetToDefaultClick}
              data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.cta.setToDefault}
            >
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.cta.SetToDefault')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
