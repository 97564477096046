import IconButton from '@material-ui/core/IconButton';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription, PopperUI } from 'apps/ui';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { IFlattenVerificationPatternConfig, isPatternSettingsChanged } from 'models/VerificationPatternsConfigs.model';
import { ReactComponent as CircleWavyWarningIcon } from '../../assets/CircleWavyWarning.svg';
import { ReactComponent as GearIcon } from '../../assets/Gear.svg';
import { ReactComponent as InfoWithTooltipIcon } from '../../assets/InfoWithTooltip.svg';
import { ReactComponent as PencilSimpleIcon } from '../../assets/PencilSimple.svg';
import { getDefaultSettings, getError, GovernmentCheckSettingTypes, ProductSettingsGovCheck } from '../../models/GovCheckMerit.model';
import { PatternIssueTooltip } from '../PatternIssueTooltip/PatternIssueTooltip';
import { GovCheckPopper } from '../GovCheckPopper/GovCheckPopper';
import { useStyles } from './GovCheckMeritPanelGovCheck.styles';

export function GovCheckMeritPanelGovCheck({ settings, handleUpdate, govCheck }: {
  handleUpdate: (newData: {settingId: GovernmentCheckSettingTypes; value: unknown}[]) => void;
  govCheck: IFlattenVerificationPatternConfig;
  settings: ProductSettingsGovCheck;
}) {
  const titleRef = useRef();
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);

  const govCheckOption = govCheck.options?.find((option) => option.isActive);
  const isChanged = govCheckOption || isPatternSettingsChanged(govCheck.patternSetting, getDefaultSettings(govCheck));

  const error = getError(govCheck);
  const isHaveAnyError = error.length !== 0;

  const handleTogglePopper = () => {
    setIsPopperOpen((prevIsPopperOpen) => !prevIsPopperOpen);
  };

  const handleClosePopper = () => {
    setIsPopperOpen(false);
  };

  const id = `popper-${govCheck.patternName}`;

  return (
    <>
      <ExtendedDescription
        title={(
          <>
            {isHaveAnyError && (
              <PatternIssueTooltip
                govCheck={govCheck}
                errorType={error[0]}
              >
                <InfoWithTooltipIcon className={classes.icon} />
              </PatternIssueTooltip>
            )}
            <span ref={titleRef} className={classNames({ [classes.warnTitle]: isHaveAnyError })}>
              {formatMessage(`GovCheck.check.${govCheck.patternName}.${govCheck.patternValue}`, {
                defaultMessage: formatMessage(`GovCheck.check.${govCheck.patternName}`),
              })}
            </span>
          </>
        )}
        className={classes.root}
        titleColor="common.black75"
        textFontSize={10}
        postfix={(
          <PatternIssueTooltip
            govCheck={govCheck}
            errorType={error[0]}
          >
            <IconButton
              aria-describedby={id}
              onClick={handleTogglePopper}
              className={classNames(classes.btn, {
                [classes.blueBtn]: !isHaveAnyError && !isChanged,
                [classes.editedBtn]: !isHaveAnyError && isChanged,
                [classes.warnBtn]: isHaveAnyError,
              })}
              size="small"
            >
              {!isHaveAnyError && !isChanged && <GearIcon />}
              {!isHaveAnyError && isChanged && <PencilSimpleIcon />}
              {isHaveAnyError && <CircleWavyWarningIcon />}
              {formatMessage('GovCheckMerit.settings.button.settings')}
            </IconButton>
          </PatternIssueTooltip>
        )}
      />
      {isPopperOpen && (
        <PopperUI
          id={id}
          isPopperOpen={isPopperOpen}
          anchorEl={titleRef.current}
        >
          <GovCheckPopper
            onClose={handleClosePopper}
            settings={settings}
            govCheck={govCheck}
            handleUpdate={handleUpdate}
          />
        </PopperUI>
      )}
    </>
  );
}
