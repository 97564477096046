import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.common.darkgrey,
  },
  footerData: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.common.darkgrey,
  },
  recommendation: {
    color: theme.palette.common.black,
  },
  listItem: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  footer: {
    backgroundColor: theme.palette.common.lightyellow,
    borderRadius: 5,
    padding: 5,
  },
  footerIcon: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.yellow,
    borderRadius: '50%',
  },
}));
