import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import { FiPlus } from 'react-icons/fi';
import { ButtonStyled, ExtendedDescription, notification, HOCIsAccessAllowed } from 'apps/ui';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import { useOverlay } from 'apps/overlay';
import { BasicWatchlistIdType } from 'models/Aml.model';
import { selectMerchantId } from 'state/merchant/merchant.selectors';
import { IWatchlist, WatchlistIdType } from 'models/Watchlist.model';
import { useDebounce } from 'lib/debounce.hook';
import { selectCanManageBasicWatchlists } from 'apps/Aml/state/Aml.selectors';
import { getTogglePrimitiveInArray } from 'lib/array';
import { basicWatchlistsFlowSubmit, clearWatchlist, setCurrentWatchlist, basicWatchlistLoadById, basicWatchlistUpdateVisibility } from '../../state/Aml.actions';
import { AmlBasicWatchlistList } from '../BasicWatchlistList/BasicWatchlistList';
import { IAmlBasicWatchlistGroupUI, IBasicWatchlistModalValidationInputs, IBasicWatchlistItemUI } from '../../models/Aml.model';
import { useBasicWatchlistData } from '../../hooks/useBasicWatchlistData';
import { BasicWatchlistModalValidation } from '../BasicWatchlistModalValidation/BasicWatchlistModalValidation';
import { BasicWatchlistSkeleton } from '../BasicWatchlistSkeleton/BasicWatchlistSkeleton';

export function BasicWatchlist({ basicWatchlistsIds, isBasicWatchlistChecked, onBasicWatchlistsSelected, onBasicWatchlistValidationToggle }: {
  basicWatchlistsIds: BasicWatchlistIdType[];
  isBasicWatchlistChecked: boolean;
  onBasicWatchlistsSelected: (data: BasicWatchlistIdType[]) => void;
  onBasicWatchlistValidationToggle: (checked: boolean) => void;
}) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const debounce = useDebounce();
  const [createOverlay, closeOverlay] = useOverlay();
  const merchantId = useSelector<any, string>(selectMerchantId);
  const [watchlistChecked, setWatchlistChecked] = useState<BasicWatchlistIdType[]>(basicWatchlistsIds);
  const canManageBasicWatchlists = useSelector<any, boolean>(selectCanManageBasicWatchlists);

  const { basicWatchlistData, setBasicWatchlistItem, setBasicWatchlistBulkChecked, addGroup, removeGroup, handleEdit, isGroupsLoading, isWatchlistLoading, runningWatchlists } = useBasicWatchlistData(basicWatchlistsIds, canManageBasicWatchlists);

  const handleGroupItemSwitch = useCallback((group: IAmlBasicWatchlistGroupUI, watchlistId: BasicWatchlistIdType, value: boolean) => {
    setWatchlistChecked((prev) => getTogglePrimitiveInArray<BasicWatchlistIdType>(prev, watchlistId));
    setBasicWatchlistItem('checked', value, group, watchlistId);
  }, [setBasicWatchlistItem]);

  const handleSubmitWatchlist = useCallback(async (values: IBasicWatchlistModalValidationInputs, watchlist?: Partial<IWatchlist>) => {
    const handleWatchlistCallback = (watchlistData: Nullable<Partial<IWatchlist>>) => {
      if (!watchlistData) {
        notification.error(formatMessage('Watchlist.settings.watchlist.error'));
        return;
      }

      if (watchlist?.id) {
        notification.info(formatMessage('Watchlist.settings.watchlist.updated', { messageValues: { name: watchlist.name } }));
        return;
      }

      notification.info(formatMessage('Watchlist.settings.watchlist.created', { messageValues: { name: watchlistData.name } }));
    };

    dispatch(basicWatchlistsFlowSubmit(merchantId, values, handleWatchlistCallback, watchlist));
  }, [merchantId, formatMessage, dispatch]);

  const handleCloseOverlay = useCallback(() => {
    dispatch(clearWatchlist());
    closeOverlay();
  }, [dispatch, closeOverlay]);

  const handleRefreshClick = useCallback((watchlistId: WatchlistIdType) => () => {
    debounce(() => dispatch(basicWatchlistLoadById(merchantId, watchlistId)));
  }, [dispatch, debounce, merchantId]);

  const handleChangeVisibility = useCallback((_, isVisible: boolean) => {
    dispatch(basicWatchlistUpdateVisibility(merchantId, isVisible));
  }, [merchantId, dispatch]);

  const handleOpenWatchlist = useCallback((watchlist?: IBasicWatchlistItemUI) => () => {
    dispatch(clearWatchlist());
    if (watchlist?.id) {
      dispatch(setCurrentWatchlist(watchlist.id));
    }

    createOverlay(
      <BasicWatchlistModalValidation
        watchlist={watchlist}
        onChangeVisibility={handleChangeVisibility}
        onClose={handleCloseOverlay}
        onSubmit={handleSubmitWatchlist}
      />,
      { onClose: handleCloseOverlay },
    );
  }, [dispatch, createOverlay, handleCloseOverlay, handleChangeVisibility, handleSubmitWatchlist]);

  useEffect(() => {
    if (basicWatchlistsIds.length !== watchlistChecked.length) {
      onBasicWatchlistsSelected(watchlistChecked);
    }
  }, [basicWatchlistsIds, watchlistChecked, onBasicWatchlistsSelected]);

  const handleToggleChange = useCallback((_, checked: boolean) => {
    setWatchlistChecked(checked ? (
      basicWatchlistData.map((group) => group.watchlists.map((watchlist) => !watchlist.process?.error && watchlist.id))
        .flat()
        .filter(Boolean)
    ) : []);
    setBasicWatchlistBulkChecked(checked);
    onBasicWatchlistValidationToggle(checked);
  }, [onBasicWatchlistValidationToggle, setBasicWatchlistBulkChecked, basicWatchlistData]);

  return (
    <>
      <ExtendedDescription
        title={formatMessage('BasicWatchlist.settings.title')}
        info={formatMessage('BasicWatchlist.settings.description')}
        prefix={(
          <Switch
            checked={isBasicWatchlistChecked}
            onChange={handleToggleChange}
            color="primary"
          />
        )}
      />
      <HOCIsAccessAllowed isShowAlert={false} isAccessAllowed={isBasicWatchlistChecked}>
        <>
          <Box mt={2}>
            {(isWatchlistLoading || isGroupsLoading) ? (
              <BasicWatchlistSkeleton />
            ) : (
              <AmlBasicWatchlistList
                data={basicWatchlistData}
                handleGroupItemSwitch={handleGroupItemSwitch}
                isAdmin={canManageBasicWatchlists}
                handleEditClick={handleEdit}
                handleRemoveClick={removeGroup}
                handleOpenWatchlist={handleOpenWatchlist}
                disabled={!isBasicWatchlistChecked || isWatchlistLoading || isGroupsLoading}
                runningWatchlists={runningWatchlists}
                handleRefreshClick={handleRefreshClick}
              />
            )}
          </Box>
          {canManageBasicWatchlists && (
            <>
              <ButtonStyled color="primary" disabled={isWatchlistLoading || isGroupsLoading}>
                <Typography variant="body1" onClick={addGroup}>{formatMessage('BasicWatchlist.settings.addGroup')}</Typography>
              </ButtonStyled>
              <Box mt={1}>
                <ButtonStyled
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleOpenWatchlist()}
                >
                  <FiPlus />
                  {formatMessage('BasicWatchlist.settings.addWatchlist')}
                </ButtonStyled>
              </Box>
            </>
          )}
        </>
      </HOCIsAccessAllowed>
    </>
  );
}
