import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { GaugeChart, GaugeChartTypes } from 'apps/ui';
import { ReactComponent as FraudReasonIcon } from 'assets/fraud-score-reason.svg';
import { IFraudAssessmentScore } from 'models/FraudAssessmentScore.model';
import { useFormatMessage } from 'apps/intl';
import Divider from '@material-ui/core/Divider';
import { TrackTypes, VerificationTabTypes, useTrackClickEvent } from 'apps/Tracking';
import { useLocation } from 'react-router-dom';
import { Routes } from 'models/Router.model';
import RiskScoreInstructions from '../RiskScoreInstructions/RiskScoreInstructions';
import { useStyles } from './RiskScore.styles';

const RiskScore = ({ data }: { data: IFraudAssessmentScore }) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const shouldRender = data.data?.score !== undefined;
  const location = useLocation();
  const from: string | undefined = location.state?.from;
  const trackEvent = useTrackClickEvent();

  useEffect(() => {
    trackEvent(TrackTypes.VerificationTabViewed, {
      isCustomerProfile: from?.startsWith(Routes.identity.verification.customer),
      tabName: VerificationTabTypes.FraudAssessment,
      verificationId: data.verificationDetails.id,
      verificationStatus: data.verificationDetails.verificationStatus,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box p={1} className={classes.box}>
      <Typography className={classes.descriptionTitle}>{formatMessage('AlterationDetectionFraudScore.card.description.title')}</Typography>
      {shouldRender ? (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6} alignItems="center">
            <Grid container direction="column">
              <Grid item xs={12} alignItems="center">
                <Box mb={2} className={classes.scoreWrapper}>
                  <GaugeChart className="fs-exclude" value={data.data.score} type={GaugeChartTypes.RiskAssessment} />
                  <Typography className={classes.title}>{formatMessage('ProfileInsights.profileRiskAssessment.chart.title')}</Typography>
                  <Typography className={classes.subtitle}>{formatMessage('ProfileInsights.profileRiskAssessment.chart.subtitle')}</Typography>
                </Box>
              </Grid>
              <Box p={1}>
                <Divider />
              </Box>
              <Grid item xs={12}>
                <Box m={1} mb={1} mt={2}>
                  <Typography className={classes.heading}>{formatMessage('ProfileInsights.profileRiskAssessment.mainReasons')}</Typography>

                  {!data?.error?.reasonCode && (
                  <Box mt={2}>
                    <Typography className={classNames(classes.reason, classes.noRiskColor)}>
                      {formatMessage('ProfileInsights.profileRiskAssessment.noReason')}
                    </Typography>
                  </Box>
                  )}
                  {data.error?.reasonCode && (
                  <>
                    {
                      data.error.reasonCode.map((reason) => (
                        <React.Fragment key={reason}>
                          <Box mt={2}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item xs="auto">
                                <FraudReasonIcon />
                              </Grid>
                              <Grid item xs={10}>
                                <Typography className={classes.reason}>{formatMessage(`ProfileInsights.profileRiskAssessment.fraudReason.${reason}.short`)}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </React.Fragment>
                      ))
                    }
                  </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} alignItems="center">
            <RiskScoreInstructions />
          </Grid>
        </Grid>
      ) : (<Typography className={classes.subtitle}>{formatMessage('ProfileInsights.profileRiskAssessment.noScore')}</Typography>)}
    </Box>
  );
};

export default RiskScore;
