import { IStep, StepStatus } from 'models/Step.model';
import { FormatMessage } from 'apps/intl';
import { validateRangeLength } from 'lib/validations';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export enum VideoAgreementErrors {
  FaceIsNotRecognized = 'videoAgreement.faceIsNotRecognized',
  MoreThanOneFace = 'videoAgreement.moreThanOneFace',
  InsufficientSimilarity = 'videoAgreement.insufficientSimilarity',
}

export interface ILanguage {
  id: string;
  label: string;
}

export const LocaleKeysAdapter = {
  en: 'en-US',
  'pt-BR': 'pt-BR',
  es: 'es-ES',
};

export const MIN_SCRIPT_LENGTH = 30;
export const MAX_SCRIPT_LENGTH = 280;
export const SCRIPT_ROW_COUNT = 8;
export const FUZZY_SLIDER_STEP = 5;

export const CHANGE_SELECT_REASON = 'select-option';

export enum VideoAgreementCheckTypes {
  VerbalAgreement = 'verbalAgreement'
}

export enum VideoAgreementSettingTypes {
  Script = 'script',
  Language = 'language',
  TextMatching = 'textMatching'
}

export interface IVideoAgreementValidation {
  script: string;
  language?: string;
  textMatching: number;
}

export interface IVideoAgreementVerificationOutput {
  publicUrls: {
    selfieUrl: string,
    videoUrl: string,
  },
  agreement: {
    script: string;
    minimalSimilarity: number;
    language: string;
  },
  recognizedSpeech: {
    transcript: string;
    similarity: number;
  },
  recognizedVideo: {
    faceMask: boolean;
    selfieKey: string;
    numFaces: number;
  },
  errors?: [{type: string, code: string}]
}

export interface IVideoAgreementStep extends IStep<IVideoAgreementVerificationOutput & {
  isRunning: boolean;
}> {
}

export function getLanguageObject(languageId: string, languageHook: FormatMessage): ILanguage {
  return {
    id: languageId,
    label: languageHook(`Languages.${languageId}`),
  };
}

export const defaultLanguageId = 'en-US';

export const MockLanguagesId = [
  'af-ZA',
  'sq-AL',
  'am-ET',
  'ar-DZ',
  'ar-BH',
  'ar-EG',
  'ar-IQ',
  'ar-IL',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-PS',
  'ar-TN',
  'ar-AE',
  'ar-YE',
  'hy-AM',
  'az-AZ',
  'eu-ES',
  'bn-BD',
  'bn-IN',
  'bs-BA',
  'bg-BG',
  'my-MM',
  'ca-ES',
  'hr-HR',
  'cs-CZ',
  'da-DK',
  'nl-BE',
  'nl-NL',
  'en-AU',
  'en-CA',
  'en-GH',
  'en-HK',
  'en-IN',
  'en-IE',
  'en-KE',
  'en-NZ',
  'en-NG',
  'en-PK',
  'en-PH',
  'en-SG',
  'en-ZA',
  'en-TZ',
  'en-GB',
  'en-US',
  'et-EE',
  'fil-PH',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-FR',
  'fr-CH',
  'gl-ES',
  'ka-GE',
  'de-AT',
  'de-DE',
  'de-CH',
  'el-GR',
  'gu-IN',
  'iw-IL',
  'hi-IN',
  'hu-HU',
  'is-IS',
  'id-ID',
  'it-IT',
  'it-CH',
  'ja-JP',
  'jv-ID',
  'kn-IN',
  'kk-KZ',
  'km-KH',
  'ko-KR',
  'lo-LA',
  'lv-LV',
  'lt-LT',
  'mk-MK',
  'ms-MY',
  'ml-IN',
  'mr-IN',
  'mn-MN',
  'ne-NP',
  'no-NO',
  'fa-IR',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sr-RS',
  'si-LK',
  'sk-SK',
  'sl-SI',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-SV',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PY',
  'es-PE',
  'es-PR',
  'es-ES',
  'es-US',
  'es-UY',
  'es-VE',
  'su-ID',
  'sw-KE',
  'sw-TZ',
  'sv-SE',
  'ta-IN',
  'ta-MY',
  'ta-SG',
  'ta-LK',
  'te-IN',
  'th-TH',
  'tr-TR',
  'uk-UA',
  'ur-IN',
  'ur-PK',
  'uz-UZ',
  'vi-VN',
  'zu-ZA',
];

export const getVerificationStatus = (isRunning: boolean, error: boolean): StepStatus => {
  if (isRunning) {
    return StepStatus.Checking;
  }
  if (error) {
    return StepStatus.Failure;
  }
  return StepStatus.Success;
};

export const getVideoAgreementData = (verification: VerificationResponse): IVideoAgreementVerificationOutput => {
  const step = verification.steps.filter((item) => item.id === VerificationPatternTypes.VideoAgreementValidation)[0];
  return step?.data;
};

export const validateScript = (text: string): Nullable<string> => {
  if (!text) {
    return 'VideoAgreement.issues.emptyScript.description';
  }

  const rangeValidation = validateRangeLength(text, MIN_SCRIPT_LENGTH, MAX_SCRIPT_LENGTH);

  if (rangeValidation) {
    return `VideoAgreement.issues.${rangeValidation}.description`;
  }
  return null;
};
