export const appTypography = {
  h1: {
    fontSize: 34,
    fontWeight: 400,
  },
  h2: {
    fontSize: 28,
  },
  h3: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  h4: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  h5: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  h6: {
    fontSize: 16,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  body1: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  button: {
    // remove default capitalize from buttons
    textTransform: 'none',
  },
  fontFamily: ['Lato', 'Helvetica Neue', 'sans-serif'].join(','),
  title: {
    fontFamily: ['Lato', 'Helvetica Neue', 'sans-serif'].join(','),
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
};
