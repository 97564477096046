import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import SignInService from 'assets/signin-service.png';
import SignInSession from 'assets/signin-session.png';
import { useStyles } from './AuthDescription.styles';

export function AuthDescription() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Grid className={classes.description} direction="column" justifyContent="center" alignItems="flex-start" container>
      <Box className={classes.descriptionWrapper}>
        <Typography variant="h2" className={classes.descriptionTitle}>
          {formatMessage('SignIn.description.title')}
        </Typography>
        <Box className={classes.descriptionList}>
          <Typography variant="body1" className={classes.text}>
            {formatMessage('SignIn.description.text')}
          </Typography>
          <img src={SignInSession} alt="" className={classes.descriptionImage} />
        </Box>
        <Box className={classes.service}>
          <img src={SignInService} alt="" />
          <Typography variant="body1" className={classes.serviceTitle}>
            {formatMessage('SignIn.description.serviceTitle')}
          </Typography>
          <Typography variant="body1">
            {formatMessage('SignIn.description.service', { messageValues: { breakingLine: <br /> } })}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
