import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { IFlattenVerificationPatternConfig, PatternErrorType, ProductByVerificationInput, VerificationInputTypes } from 'models/VerificationPatternsConfigs.model';

export function DataSourceSubTypeError({ errorType, govCheck }: {
  errorType: PatternErrorType;
  govCheck: IFlattenVerificationPatternConfig;
}) {
  const formatMessage = useFormatMessage();

  const message = `${formatMessage('tooltip.issue.product.missing.option', {
    messageValues: {
      option: errorType?.input?.documents?.map(({ subTypes }) => subTypes.map((subType) => formatMessage(`flow.documentSubTypeStep.${govCheck.country}.${subType}`)).join(` ${formatMessage('or')} `)).join(' '),
    },
  })}`;

  return (
    <>
      {formatMessage('tooltip.issue.products.configuration', {
        messageValues: {
          messageContinued: `${formatMessage(`${ProductByVerificationInput[VerificationInputTypes.DocumentVerification]}.card.title`)}. ${message}.`,
        },
      })}
    </>
  );
}
