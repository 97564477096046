import { UserId, TeamInviteFormInputs } from 'models/Collaborator.model';
import { selectMerchantId } from 'state/merchant/merchant.selectors';
import { createTypesSequence } from 'state/store.utils';
import { FormatMessage } from 'apps/intl';
import { notification } from 'apps/ui';
import { CollaboratorActionGroups } from './collaborator.store';
import * as api from '../api/collaborators';
import { selectCollaboratorCollection } from './collaborator.selectors';

export const types = {
  ...createTypesSequence(CollaboratorActionGroups.CollaboratorList),
  ...createTypesSequence(CollaboratorActionGroups.Collaborator),
  ...createTypesSequence(CollaboratorActionGroups.CollaboratorRole),
};

export const collaboratorLoad = (id) => async (dispatch, getState) => {
  dispatch({ type: types.COLLABORATOR_REQUEST });
  try {
    const merchantId = selectMerchantId(getState());
    const { data } = await api.getCollaborator(merchantId, id);
    dispatch({ type: types.COLLABORATOR_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.COLLABORATOR_FAILURE, error });
    throw error;
  }
};

export const collaboratorClear = () => async (dispatch) => {
  dispatch({ type: types.COLLABORATOR_CLEAR });
};

export const collaboratorListLoad = () => async (dispatch, getState) => {
  dispatch({ type: types.COLLABORATOR_LIST_REQUEST });
  try {
    const merchantId = selectMerchantId(getState());
    const { data } = await api.getCollaborators(merchantId);
    dispatch({ type: types.COLLABORATOR_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.COLLABORATOR_LIST_FAILURE, error });
    throw error;
  }
};

export const collaboratorInvite = (formatMessage: FormatMessage, collaborators: TeamInviteFormInputs[]) => async (dispatch) => {
  dispatch({ type: types.COLLABORATOR_LIST_UPDATING });
  try {
    const { data } = await api.postCollaborators(collaborators);
    dispatch({ type: types.COLLABORATOR_LIST_SUCCESS, isReset: true, payload: data.collaborators });
    if (data.created === collaborators.length) {
      notification.info(formatMessage('teamTable.inviteSuccess.description'));
    } else {
      const count = collaborators.length - data.created;
      notification.info(formatMessage('teamTable.invitePartialSuccess.description', { messageValues: { count } }));
    }
  } catch (error: any) {
    dispatch({ type: types.COLLABORATOR_LIST_FAILURE, error });
    if (error?.response?.data?.type === 'ValidationError.emailInUse') {
      notification.error(formatMessage('Settings.teamSettings.submit.ValidationError', { defaultMessage: formatMessage('Error.common') }));
    } else {
      notification.error(formatMessage('Error.common'));
    }
    console.error(error);
  }
};

export const collaboratorResendInvite = (formatMessage: FormatMessage, id: UserId, email: string) => async (dispatch) => {
  try{
    await api.resendCollaboratorInvitation(id);
    notification.info(formatMessage('teamTable.resendInviteSuccess.description', { messageValues: { email } }));
  } catch (error: any) {
    dispatch({ type: types.COLLABORATOR_LIST_FAILURE, error });
    notification.error(formatMessage('Error.common'));
  }
};

export const collaboratorUpdate = (id, updateData) => async (dispatch, getState) => {
  dispatch({ type: types.COLLABORATOR_UPDATING });
  try {
    const merchantId = selectMerchantId(getState());
    const { data } = await api.patchCollaborators(merchantId, id, updateData);
    const currentUpdatedCollaborator = data.find((collaborator) => collaborator?.user?.id === id);
    dispatch({ type: types.COLLABORATOR_SUCCESS, payload: currentUpdatedCollaborator });
  } catch (error) {
    dispatch({ type: types.COLLABORATOR_FAILURE, error });
    throw error;
  }
};
export const collaboratorRoleUpdate = (id, updateData) => async (dispatch, getState) => {
  dispatch({ type: types.COLLABORATOR_ROLE_UPDATING });
  try {
    const merchantId = selectMerchantId(getState());
    await api.patchCollaboratorsRole(merchantId, id, updateData);
    dispatch({ type: types.COLLABORATOR_ROLE_SUCCESS, isReset: true, payload: { collaboratorRoleChanged: true, ...updateData, collaboratorId: id } });
  } catch (error: any) {
    dispatch({ type: types.COLLABORATOR_ROLE_FAILURE, error, message: error?.response?.data?.message });
    if(error?.response?.data?.message){
      notification.error(error?.response?.data?.message);
    }
    throw error;
  }
};

export const collaboratorRemove = (id) => async (dispatch) => {
  dispatch({ type: types.COLLABORATOR_LIST_UPDATING });
  try {
    const { data } = await api.deleteCollaborators(id);
    dispatch({ type: types.COLLABORATOR_LIST_SUCCESS, isReset: true, payload: data });
  } catch (error) {
    dispatch({ type: types.COLLABORATOR_LIST_FAILURE, error });
    throw error;
  }
};

export const userChangeBlockStatus = (userId: UserId, blockedStatus: boolean) => async (dispatch, getState) => {
  dispatch({ type: types.COLLABORATOR_LIST_UPDATING });
  try {
    const collaborators = selectCollaboratorCollection(getState());
    const { data } = await api.userChangeBlockStatus(userId, blockedStatus);
    const newCollaborators = collaborators.map((item) => ((item?.user?.id === data?._id) ? { ...item, user: { ...item?.user, blocked: data?.blocked } } : item));
    dispatch({ type: types.COLLABORATOR_LIST_SUCCESS, isReset: true, payload: newCollaborators });
  } catch (error) {
    dispatch({ type: types.COLLABORATOR_LIST_FAILURE, error });
    throw error;
  }
};
