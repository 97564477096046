import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantSubscriptionStatusValue, selectMerchantTags } from 'state/merchant';
import { DocumentVerification } from '../services/DocumentVerification.service';
import { POAVerification } from '../services/POAVerification.service';

export const documentVerificationInit = () => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const documentVerification = new DocumentVerification({
    isOnlyFreemiumCountries: merchantTags.includes(MerchantTagsTypes.CanUseOnlyFreemiumDocumentCountries),
  });
  productManagerService.register(documentVerification);
  return documentVerification.id;
};

export const PoaVerificationInit = () => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const poaVerification = new POAVerification({
    disabled: !merchantTags.includes(MerchantTagsTypes.CanUseProofOfAdress),
  });
  productManagerService.register(poaVerification);
  return poaVerification.id;
};
