import { downloadBlob } from 'lib/file';
import { MediaUploadFile } from 'models/Media.model';
import { getMedia } from 'apps/media';
import { IStep } from './Step.model';
import { ProductSettings } from './Product.model';

export const MAX_UPLOAD_DOCUMENTS = 20;

export type ESignatureDocumentId = string;
export const MAX_DOCUMENT_SIZE_MB = 2;

export enum ESignatureFieldTypes {
  ReadAt = 'readAt',
  SignedAt = 'signedAt',
  TransactionId = 'documentId',
  CustomVariables = 'customVariables',
}

export enum ESignatureTypes {
  NameTyping = 'name-typing',
  Document = 'document',
  FaceSignature = 'face-signature',
}

export enum ESignatureCheckSettingsTypes {
  SignatureMethod = 'signatureMethod',
  Terms = 'terms',
  TermsOrder = 'termsOrder',
  SignatureTouch = 'SignatureTouch'
}

export enum ESignatureCheckTypes {
  SignatureCheck = 'signatureCheck',
  GeoRestrictionCheck = 'geoRestrictionCheck',
  RiskyIpCheck = 'riskyIpCheck'
}

export enum ESignatureRadioOptionsTypes {
  NameTyping = 'nameTyping',
  UploadDocumentAndTypeName = 'uploadDocumentAndTypeName',
  FaceAndDocumentSignature = 'faceAndDocumentSignature'
}

export interface IESignaturePDFDocument {
  publicUrl: string;
  documentImages: string[];
}

export interface ICustomVariable {
  title: string;
  value: string;
}

export interface IESignatureReadDetails {
  readAt: string;
  signedAt: string;
  documentId: ESignatureDocumentId;
  pdfDocument: IESignaturePDFDocument;
  documentTemplate: {
    originalDocument: MediaUploadFile;
  };
  fullName: string;
  customVariables: {[key: string]: ICustomVariable}
}

export interface IESignatureStepData {
  readDetails: IESignatureReadDetails[];
}

export type ESignatureStep = IStep<IESignatureStepData>;

export interface IESignatureAcceptanceCriteria {
  isDocumentsRequired: boolean;
  isFaceMatchRequired: boolean;
  isFullNameRequired: boolean;
  isTouchSignRequired: boolean;
}

export interface IESignatureTemplates {
  order: ESignatureDocumentId[];
  list: IESignatureTemplate[];
}

export interface IESignatureValidation {
  acceptanceCriteria: IESignatureAcceptanceCriteria;
}

export interface IESignatureFlow extends IESignatureValidation {
  templates: IESignatureTemplates;
}

export type IESignatureDocumentMediaFile = Pick<MediaUploadFile,
  'folder' |
  'fileName' |
  'originalFileName' |
  'publicUrl' |
  'url'>

export interface IESignatureTemplate {
  id: ESignatureDocumentId;
  originalDocument: IESignatureDocumentMediaFile;
}

export const ESignatureFields = [
  ESignatureFieldTypes.ReadAt,
  ESignatureFieldTypes.SignedAt,
  ESignatureFieldTypes.TransactionId,
];

export interface IESignatureApiTemplate {
  fullName: string;
  readDetails: {documentId: ESignatureDocumentId; readAt: string}[];
  signedAt: string;
}

export function getESignatureType(criteria: IESignatureAcceptanceCriteria): ESignatureTypes {
  if (criteria?.isFaceMatchRequired) {
    return ESignatureTypes.FaceSignature;
  }
  if (criteria?.isDocumentsRequired) {
    return ESignatureTypes.Document;
  }
  return ESignatureTypes.NameTyping;
}

export async function downloadESignaturePDFDocument(eSignaturePDF: IESignatureReadDetails) {
  if (!eSignaturePDF) {
    return;
  }

  const publicUrl = await eSignaturePDF.pdfDocument.publicUrl;
  const response = await getMedia(publicUrl);
  downloadBlob(response.data, `${eSignaturePDF.documentTemplate.originalDocument.originalFileName}.pdf`);
}

export async function downloadESignatureOriginalDocument(eSignatureDocument: IESignatureTemplate) {
  if (!eSignatureDocument) {
    return;
  }
  const response = await getMedia(eSignatureDocument.originalDocument.publicUrl);
  downloadBlob(response.data, `${eSignatureDocument.originalDocument.originalFileName}.pdf`);
}

export function reorderESignatureIds(list: ESignatureDocumentId[], startIndex: number, endIndex: number): ESignatureDocumentId[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function getAcceptanceCriteria(value?: ESignatureRadioOptionsTypes, settings?: ProductSettings<ESignatureCheckSettingsTypes>): IESignatureAcceptanceCriteria {
  if (value === ESignatureRadioOptionsTypes.FaceAndDocumentSignature) {
    return {
      isDocumentsRequired: true,
      isFaceMatchRequired: true,
      isFullNameRequired: true,
      isTouchSignRequired: settings?.SignatureTouch?.value,
    };
  }

  if (value === ESignatureRadioOptionsTypes.UploadDocumentAndTypeName) {
    return {
      isDocumentsRequired: true,
      isFaceMatchRequired: false,
      isFullNameRequired: true,
      isTouchSignRequired: settings?.SignatureTouch?.value,
    };
  }

  return {
    isDocumentsRequired: false,
    isFaceMatchRequired: false,
    isFullNameRequired: true,
    isTouchSignRequired: settings?.SignatureTouch?.value,
  };
}

export function getSigMethod(values?: IESignatureAcceptanceCriteria, neededProductsInFlow?: boolean): ESignatureRadioOptionsTypes {
  if ((values?.isFaceMatchRequired && values?.isDocumentsRequired) && neededProductsInFlow) {
    return ESignatureRadioOptionsTypes.FaceAndDocumentSignature;
  }

  if (values?.isDocumentsRequired && values?.isFullNameRequired) {
    return ESignatureRadioOptionsTypes.UploadDocumentAndTypeName;
  }

  return ESignatureRadioOptionsTypes.NameTyping;
}

export const electronicSignatureFlowTemplatesDefault = {
  order: [],
  list: [],
};

export const electronicSignatureFlowInitialState = {
  templates: electronicSignatureFlowTemplatesDefault,
  acceptanceCriteria: {
    isDocumentsRequired: false,
    isFaceMatchRequired: false,
    isFullNameRequired: true,
    isTouchSignRequired: false,
  },
};
