import { ExtendedDescription, TimeoutSettings } from 'apps/ui';
import { GDPRRangeTypes } from 'models/GDPR.model';
import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { useFormatMessage } from 'apps/intl';
import { Routes } from 'models/Router.model';
import { IRetryBlockerConfig, VerificationRetryBlockerModeTypes } from 'apps/flowBuilder';
import { SDKRedirectionOptionsTypes } from 'models/Flow.model';
import { useStyles } from './FlowSettingsSwitches.styles';
import { UserRedirectionOptions } from '../UserRedirectionOptions/UserRedirectionOptions';
import { EOFRedirection } from '../EOFRedirection/EOFRedirection';
import VerificationRetryBlockOptions from '../VerificationRetryBlockOptions/VerificationRetryBlockOptions';

export function FlowSettingsSwitches({ policyInterval, policyIntervalError, gdprChecked, digitalSignatureChecked, sdkRedirectionOptionsState, onValidatePolicyInterval, onChangePolicyInterval, onGDPRSwitcher, onDigitalSignatureSwitcher, onSDKRedirectOptionsSwitcher, postponedTimeout, onPostponedTimeoutChange, disableDigitalSignature, placeholder, retryVerificationBlockerConfig, onBlockVerificationRetryChange, updateBlockRetriesPrimaryKey, allowedUrls, onSdkAllowedRedirectionUrlsUpdate }: {
  policyInterval?: string;
  policyIntervalError: string;
  gdprChecked: boolean;
  digitalSignatureChecked: boolean;
  sdkRedirectionOptionsState?: any;
  onValidatePolicyInterval: (e: any) => void;
  onChangePolicyInterval: (e: any) => void;
  onGDPRSwitcher: (e: any) => void;
  onDigitalSignatureSwitcher: (e: any) => void;
  postponedTimeout: string;
  onPostponedTimeoutChange: (value: string) => void;
  onSDKRedirectOptionsSwitcher?: (option: SDKRedirectionOptionsTypes, value: boolean) => void;
  allowedUrls?: string[];
  onSdkAllowedRedirectionUrlsUpdate?: (values: string[]) => void;
  disableDigitalSignature?: boolean;
  placeholder?: string;
  retryVerificationBlockerConfig?: IRetryBlockerConfig;
  onBlockVerificationRetryChange?: (mode: VerificationRetryBlockerModeTypes, timeOut?: string) => void;
  updateBlockRetriesPrimaryKey?: (value: string | undefined) => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const location = useLocation();
  const pathMatchObj = matchPath(location.pathname, { path: Routes.flow.details, exact: true });
  return (
    <Box>
      <Box>
        <ExtendedDescription
          className={classes.extendedDescription}
          title={formatMessage('FlowBuilder.settings.title.timestamp')}
          text={formatMessage('FlowBuilder.settings.description.timestamp')}
          postfix={<Switch color="primary" disabled={disableDigitalSignature} checked={digitalSignatureChecked} onChange={onDigitalSignatureSwitcher} />}
        />
      </Box>
      <Box mt={2}>
        <ExtendedDescription
          title={formatMessage('FlowBuilder.settings.title.gdpr')}
          text={formatMessage('Product.configuration.gdpr.subtitle')}
          className={classes.extendedDescription}
          postfix={<Switch color="primary" checked={gdprChecked} onChange={onGDPRSwitcher} />}
        />
      </Box>
      {gdprChecked && (
        <Box mt={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <InputLabel className={classes.inputLabel}>
              {formatMessage('FlowBuilder.settings.gdpr.input.title')}
            </InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              value={policyInterval || ''}
              onChange={onChangePolicyInterval}
              onBlur={onValidatePolicyInterval}
              placeholder={placeholder || `${GDPRRangeTypes.From}-${GDPRRangeTypes.To}`}
              error={!!policyIntervalError}
              type="number"
            />
          </Grid>
          {!!policyIntervalError && <span className={classes.error}>{policyIntervalError}</span>}
        </Box>
      )}
      {pathMatchObj?.isExact && (
        <>
          <Box mt={2}>
            <UserRedirectionOptions
              sdkRedirectionOptionsState={sdkRedirectionOptionsState}
              onSDKRedirectOptionsSwitcher={onSDKRedirectOptionsSwitcher}
            />
          </Box>
          <Box mt={2}>
            <EOFRedirection
              allowedUrls={allowedUrls}
              onUpdate={onSdkAllowedRedirectionUrlsUpdate}
            />
          </Box>
        </>
      )}
      <Box mt={2}>
        <ExtendedDescription
          className={classes.extendedDescription}
          title={formatMessage('FlowBuilder.settings.title.postponedTimeout')}
          text={formatMessage('FlowBuilder.settings.description.postponedTimeout')}
        >
          <Grid item xs={6} container>
            <TimeoutSettings
              onChange={onPostponedTimeoutChange}
              postponedTimeout={postponedTimeout}
            />
          </Grid>
        </ExtendedDescription>
      </Box>
      {pathMatchObj?.isExact && (
        <VerificationRetryBlockOptions retryVerificationBlockerConfig={retryVerificationBlockerConfig} onBlockVerificationRetryChange={onBlockVerificationRetryChange} updateBlockRetriesPrimaryKey={updateBlockRetriesPrimaryKey} />
      )}
    </Box>
  );
}
