import { Grid, Button, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useOverlay } from 'apps/overlay';
import { notification, Warning, WarningTypes } from 'apps/ui';
import classnames from 'classnames';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { isNil } from 'lib/isNil';
import { CustomDocumentResponse, MAX_CUSTOMDOC_QTY } from 'models/CustomDocument.model';
import { ErrorMessagesTokenTypes } from 'models/Error.model';
import React, { useCallback, useEffect } from 'react';
import { useFormatMessage } from 'apps/intl';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { merchantCustomDocumentsLoad, merchantCreateCustomDocumentClearStats } from 'state/merchant/merchant.actions';
import { selectMerchantCustomDocumentsModel, selectMerchantModel } from 'state/merchant/merchant.selectors';
import { NOT_UNIQUE_TYPE_ERROR, CustomDocumentWizardStepTypes } from '../../models/CustomDocument.model';
import { useStyles } from './CustomDocumentList.styles';
import { updateEditedCustomDocument, deleteCustomDocument, updateCustomDocumentModal, resetCustomDocumentModal, saveCustomDocument, updateCustomDocumentWizardStep } from '../../state/customDocument.actions';
import { CustomDocumentWizard } from '../CustomDocumentWizard/CustomDocumentWizard';

export function CustomDocumentList({ disabled = false }: {
  disabled: boolean;
}) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();
  const merchantModel = useSelector(selectMerchantModel);
  const customDocuments = useSelector(selectMerchantCustomDocumentsModel);
  const isMaxCustomDocumentQty = customDocuments.value.length >= MAX_CUSTOMDOC_QTY;

  const handleCloseOverlay = useCallback(() => {
    dispatch(merchantCreateCustomDocumentClearStats());
    closeOverlay();
  }, [closeOverlay, dispatch]);

  const handleSaveDocument = useCallback(async (customDocumetUpdate: Partial<CustomDocumentResponse>) => {
    const newCustomDocumetUpdate = { ...customDocumetUpdate };
    newCustomDocumetUpdate.type = newCustomDocumetUpdate.type?.trim();

    await dispatch(saveCustomDocument(newCustomDocumetUpdate));
    handleCloseOverlay();
  }, [dispatch, handleCloseOverlay]);

  const handleEdit = useCallback((index: Nullable<number> = null, customDocument: Nullable<CustomDocumentResponse> = null) => () => {
    dispatch(updateEditedCustomDocument(index));
    if (isNil(index)) {
      dispatch(resetCustomDocumentModal());
    } else {
      dispatch(updateCustomDocumentModal(customDocument));
    }
    createOverlay(<CustomDocumentWizard onDone={handleSaveDocument} onClose={handleCloseOverlay} />, { sticky: true });
  }, [dispatch, createOverlay, handleSaveDocument, handleCloseOverlay]);

  const handleDelete = useCallback((index: number) => async () => {
    const customDocumentType = customDocuments.value[index].type;
    try {
      await dispatch(deleteCustomDocument(customDocumentType));
    } catch (error: any) {
      notification.error(formatMessage(error?.data?.code ? `CustomDocuments.settings.error.${error.data.code}` : ErrorMessagesTokenTypes.ERROR_COMMON, { messageValues: { flowName: error?.data?.flowName } }));
    }
  }, [dispatch, formatMessage, customDocuments]);

  useEffect(() => {
    if (merchantModel.isLoaded && LoadableAdapter.isPristine(customDocuments) && !customDocuments.value?.length) {
      dispatch(merchantCustomDocumentsLoad());
    }
  }, [dispatch, customDocuments, merchantModel]);

  useEffect(() => {
    if (customDocuments.error === NOT_UNIQUE_TYPE_ERROR) {
      dispatch(updateCustomDocumentWizardStep(CustomDocumentWizardStepTypes.BasicInfo));
    }
  }, [customDocuments.error, dispatch]);

  return (
    <Box>
      <Box mt={2} className={classes.buttonWrap}>
        {isMaxCustomDocumentQty && (
          <Box mb={0.5} className={classes.warningWrap}>
            <Warning
              type={WarningTypes.Notify}
              label={formatMessage('CustomDocuments.settings.customDocumentList.help', { messageValues: { count: MAX_CUSTOMDOC_QTY } })}
              isLabelColored
              isIconExist={false}
            />
          </Box>
        )}
        <Button
          className={classnames(classes.addButton, classes.addButtonFullWidth)}
          disabled={disabled || isMaxCustomDocumentQty || !customDocuments.isLoaded}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleEdit()}
        >
          {formatMessage('CustomDocuments.settings.customDocumentList.add')}
        </Button>
      </Box>

      {!disabled && (
        <Grid item xs={12}>
          {customDocuments.isLoading ? (
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
            </Grid>
          ) : (
            customDocuments.value.map((customDocument: CustomDocumentResponse, index: number) => (
              <Box className={classes.tag} key={customDocument.type}>
                {customDocument.name}
                <Box className={classes.buttonsHolder}>
                  <Button className={classes.editButton} onClick={handleEdit(index, customDocument)}>
                    <FiEdit2 />
                  </Button>
                  <Button className={classes.deleteButton} onClick={handleDelete(index)}>
                    <FiTrash2 />
                  </Button>
                </Box>
              </Box>
            ))
          )}
        </Grid>
      )}
    </Box>
  );
}
