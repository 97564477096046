import { DocumentVerificationProduct } from 'apps/documents';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { DocumentSideTypes, DocumentTypes, DocumentInputTypes, getDocumentCURLExampleFiles, DocumentErrorCodeTypes } from 'models/Document.model';
import { IFlow } from 'models/Flow.model';
import { IProductCURLExampleParams, Product, ProductInputTypes, ProductIntegrationTypes, ProductTypes } from 'models/Product.model';
import { DocumentFrontendSteps, DocumentSecuritySteps, DocumentStepTypes, getComputedSteps, getDocumentStep, getReaderFrontendSteps, getStepStatus, StepStatus, VerificationDocStepTypes } from 'models/Step.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { FiFileText } from 'react-icons/fi';
import { CountryCodeTypes } from 'models/Country.model';
import { DocumentVerificationCheckTypes, POAVerificationTypes, ProductSettingsPOAVerification } from '../models/DocumentVerification.model';
import { PoaVerificationSettings } from '../components/PoaVerificationSettings/PoaVerificationSettings';

export class POAVerification extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.POAVerification;
  order = 110;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  icon = FiFileText;
  inputs = [
    ProductInputTypes.Documents,
  ];
  dependentProductTypes = [
    ProductTypes.AmlCheck,
    ProductTypes.CustomWatchlist,
  ];
  checks = [
    {
      id: DocumentVerificationCheckTypes.DocumentReading,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.ExpirationDetection,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.TemplateMatching,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.AlterationDetection,
      isActive: true,
    },
  ];

  component = PoaVerificationSettings;
  componentVerification = DocumentVerificationProduct;
  isRestrictedToFreemiumCountries = false;

  onAdd(flow: IFlow): Partial<IFlow> {
    return {
      verificationSteps: [...flow?.verificationSteps, [DocumentTypes.ProofOfResidency]],
    };
  }

  parser(flow: IFlow): ProductSettingsPOAVerification {
    const steps = flow?.verificationSteps ?? [];
    const isWarning = steps.some((group) => (group.length > 1) && group.includes(DocumentTypes.ProofOfResidency));

    return {
      [POAVerificationTypes.steps]: {
        value: steps,
        error: isWarning ? 'POAVerification.card.warning.legacy' : undefined,
      },
    };
  }

  serialize(setting: ProductSettingsPOAVerification): Partial<IFlow> {
    return {
      verificationSteps: setting.steps.value,
    };
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    const otherSteps = flow?.verificationSteps
      .map((group) => group.filter((step) => step !== DocumentTypes.ProofOfResidency))
      .filter((group) => group.length > 0);

    return {
      verificationSteps: [...otherSteps],
    };
  }

  isInFlow(flow: IFlow): boolean {
    const allSteps = flow?.verificationSteps?.flatMap((step) => step) || [];
    return allSteps.some((step) => step === DocumentTypes.ProofOfResidency);
  }

  getVerification(verification: VerificationResponse): VerificationResponse {
    const documentTypes: string[] = Object.values(DocumentTypes).filter((item) => item === DocumentTypes.ProofOfResidency).map((item: DocumentTypes) => item as string);
    const documents = verification?.documents?.filter((el) => documentTypes.includes(el.type) || el?.documentReadingStep?.error?.code === DocumentErrorCodeTypes.DocumentSkipped).map((doc) => {
      const duplicateUserDetectionStep = doc?.steps?.find((item) => item?.id === VerificationDocStepTypes.DuplicateUserValidation);
      const ageCheck = doc?.steps?.find((item) => item?.id === VerificationDocStepTypes.AgeValidation);
      const ageConsistencyCheck = doc?.steps?.find((step) => step?.id === VerificationDocStepTypes.AgeConsistencyCheck);
      return { ...doc, duplicateUserDetectionStep, ageCheck, ageConsistencyCheck };
    });

    return { ...verification, documents };
  }

  getCURLExampleParams(flow: IFlow): Nullable<IProductCURLExampleParams> {
    const verificationSteps = flow?.verificationSteps || [];
    const documentTypes: string[] = Object.values(DocumentTypes);
    const documentInputTypes = verificationSteps.filter((step) => documentTypes.includes(step[0])).map((step) => step[0]);

    if (!documentInputTypes.length) {
      return null;
    }

    return documentInputTypes.reduce((acc, type, idx) => {
      const inputId = type.toUpperCase();
      const inputTemplate = {
        inputType: DocumentInputTypes.DocumentPhoto,
        group: idx,
        data: {
          type,
          country: CountryCodeTypes.MX,
          page: DocumentSideTypes.Front,
          filename: `MX_${inputId}_FRONT.jpg`,
        },
      };

      if (type === DocumentTypes.NationalId) {
        return {
          inputs: [
            ...acc.inputs,
            inputTemplate,
            {
              ...inputTemplate,
              data: {
                ...inputTemplate.data,
                page: DocumentSideTypes.Back,
                filename: `MX_${inputId}_BACK.jpg`,
              },
            },
          ],
          files: [...acc.files, getDocumentCURLExampleFiles(type)],
        };
      }

      return {
        inputs: [...acc.inputs, inputTemplate],
        files: [...acc.files, getDocumentCURLExampleFiles(type)],
      };
    }, {
      inputs: [],
      files: [],
    });
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    return verification?.documents?.some((document) => {
      const steps = document?.steps || [];
      const documentStep = getDocumentStep(DocumentStepTypes.DocumentReading, steps);
      const readerStep = getReaderFrontendSteps(documentStep);
      const computedStep = getComputedSteps(documentStep, verification, document);
      const filteredSteps = steps.filter((step) => [
        ...DocumentSecuritySteps,
        ...DocumentFrontendSteps,
        VerificationDocStepTypes.DuplicateUserValidation].includes(step.id));
      const allSteps = [
        ...filteredSteps,
        ...readerStep,
        ...computedStep,
      ];

      if (allSteps.length === 0) {
        return false;
      }

      return allSteps.some((step) => getStepStatus(step) === StepStatus.Failure);
    });
  }
}
