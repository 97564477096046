import React, { DOMAttributes, ReactNode } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['mtm-button']: DOMAttributes<HTMLElement> & {
        id?: string;
        label?: string;
        size?: string;
        type?: string;
        disabled?: boolean;
        highlighted?: boolean;
        destructive?: boolean;
      };
    }
  }
}

export function Button({ id, qa, label, size, type, disabled, highlighted, destructive, onClick, children }: {
  id?: string;
  qa?: string;
  label?: string;
  size?: 'mini' | 'large' | 'huge' | 'standard';
  type?: 'primary' | 'secondary' | 'tertiary' | 'icon';
  disabled?: boolean;
  destructive?: boolean;
  highlighted?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}) {
  return (
    <mtm-button
      id={id}
      data-qa={qa}
      size={size}
      type={type}
      disabled={disabled || undefined}
      highlighted={highlighted || undefined}
      destructive={destructive || undefined}
      label={label}
      onClick={onClick}
    >
      {children}
    </mtm-button>
  );
}
