import { selectFlowBuilderChangeableFlow, selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { Modal, overlayClose } from 'apps/overlay';
import { CountryCodeTypes } from 'models/Country.model';
import { IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';
import { IVerificationPatterns } from 'models/VerificationPatterns.model';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupPatternsConfig, IFlattenVerificationPatternConfig, toggleSubVerificationPattern, toggleVerificationPattern } from 'models/VerificationPatternsConfigs.model';
import { GovernmentCheckSettingTypes, parseGovCheckPatternConfig, ProductSettingsGovCheck } from '../../models/GovCheckMerit.model';
import { GovCheckModalBody } from '../GovCheckModalBody/GovCheckModalBody';
import { GovCheckModalFooter } from '../GovCheckModalFooter/GovCheckModalFooter';
import { GovCheckModalHeader } from '../GovCheckModalHeader/GovCheckModalHeader';
import { useStyles } from './GovCheckModal.styles';

export function GovCheckModal({ initialCountry, settings, handleUpdate }: {
  initialCountry?: CountryCodeTypes;
  settings: ProductSettingsGovCheck;
  handleUpdate: (newData: {settingId: GovernmentCheckSettingTypes; value: unknown}[]) => void;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const flow = useSelector<any, IFlow>(selectFlowBuilderChangeableFlow);
  const productsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);

  const [selectedCountry, setSelectedCountry] = useState<CountryCodeTypes>(initialCountry);
  const [newVerificationPatterns, setNewVerificationPatterns] = useState<Partial<IVerificationPatterns>>(flow.verificationPatterns);

  const patternsConfig = settings[GovernmentCheckSettingTypes.PatternsConfig].value;
  const productSettings = settings[GovernmentCheckSettingTypes.ProductSetting].value;

  const patternsConfigGroupedByCountry = useMemo(() => groupPatternsConfig('country', patternsConfig, parseGovCheckPatternConfig.bind({}, newVerificationPatterns, flow, productsInGraph, productSettings)), [flow, newVerificationPatterns, patternsConfig, productSettings, productsInGraph]);

  const handleToggleGovCheck = (checked: boolean, govCheck: IFlattenVerificationPatternConfig) => {
    setNewVerificationPatterns((verificationPatterns) => ({ ...verificationPatterns, ...toggleVerificationPattern(checked, govCheck) }));
  };

  const handleToggleOption = (govCheck: IFlattenVerificationPatternConfig, checked: boolean, option: IFlattenVerificationPatternConfig) => {
    setNewVerificationPatterns((verificationPatterns) => ({ ...verificationPatterns, ...toggleSubVerificationPattern(checked, govCheck, option) }));
  };

  const handleClose = () => dispatch(overlayClose());

  const handleApply = () => {
    handleUpdate([{
      settingId: GovernmentCheckSettingTypes.VerificationPatterns,
      value: newVerificationPatterns,
    }]);
    handleClose();
  };

  return (
    <Modal
      className={classes.root}
    >
      <GovCheckModalHeader />
      <GovCheckModalBody
        handleToggleGovCheck={handleToggleGovCheck}
        handleToggleOption={handleToggleOption}
        setSelectedCountry={setSelectedCountry}
        patternsConfigGroupedByCountry={patternsConfigGroupedByCountry}
        selectedCountry={selectedCountry}
      />
      <GovCheckModalFooter handleApply={handleApply} handleClose={handleClose} />
    </Modal>
  );
}
