export function isArrayIncludesArrayableValue<T>(arr: T[], arrayable: T[] | T) {
  if (Array.isArray(arrayable)) {
    return arrayable.every((item) => arr?.includes(item));
  }
  return arr?.includes(arrayable as T);
}

// Use this function for toggle primitive value in array
//
// Example:
// const [watchlistChecked, setWatchlistChecked] = useState([1, 2, 3]);
// setWatchlistChecked((prev) => getTogglePrimitiveInArray(prev, watchlistId));
export const getTogglePrimitiveInArray = <T>(prev: T[], value: T): T[] => {
  if (prev.includes(value as T)) {
    return prev.filter((currentWatchlistId) => currentWatchlistId !== value);
  }

  return [...prev, value];
};

export function replaceElementsInArray<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}
