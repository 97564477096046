import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  groupImage: {
    width: 'auto',
    height: 'auto',
    maxHeight: 350,
    maxWidth: 250,
    backgroundSize: 'cover',
  },
}));
