import { ProductTypes } from 'models/Product.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { productManagerService } from 'apps/Product';
import { selectMerchantTags } from 'state/merchant';
import { BiometricVerification } from '../services/BiometricVerification.service';

export const biometricVerificationInit = () => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const biometricVerification = new BiometricVerification({
    isVideoDisabled: merchantTags.includes(MerchantTagsTypes.CanNotUseSelfieVideo),
  });

  productManagerService.register(biometricVerification);
  return biometricVerification.id;
};
