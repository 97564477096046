import React, { DOMAttributes, ReactNode } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['mtm-quote']: DOMAttributes<HTMLElement> & {
        warning?: boolean;
      };
    }
  }
}

export function Quote({ warning, children }: {
  warning?: boolean;
  children?: ReactNode;
}) {
  return (
    <mtm-quote
      warning={warning || undefined}
    >
      {children}
    </mtm-quote>
  );
}
