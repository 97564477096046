import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    backgroundColor: theme.palette.common.lightRed,
    color: theme.palette.common.red,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 10px 6px',
  },
}));
