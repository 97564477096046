import React, { useCallback, useState } from 'react';
import { IProductCard } from 'models/Product.model';
import { IMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { useTheme } from '@material-ui/core';
import { useFormatMessage, useFormatServerMessage } from 'apps/intl';
import { ChatWidget } from 'apps/intercom';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { FiCopy, FiTrash2 } from 'react-icons/fi';
import Typography from '@material-ui/core/Typography';
import { InfoTooltip } from 'apps/ui';
import { useMerchantPlan } from 'apps/merchant';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { QATags } from 'models/QA.model';
import { RiAlertFill } from 'react-icons/ri';
import { useFreemium } from 'apps/Freemiun';
import { FreshChatWidget } from 'apps/freshdesk';
import { ReactComponent as CrownSVG } from '../../assets/crown.svg';
import { useProductFormatMessage } from '../../hooks/useProductFormatMessage';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, useStyles } from './UIProductCard.styles';

const Badge = ({ text, className }: { text: string; className: string}) => (
  <Box className={className}>
    <Box p="6px">
      {text}
    </Box>
  </Box>
);

export function UIProductCard({ card, isNeverUsed = false, defaultExpanded = false, meritBlocksAdditional, isControls, error, onRemove, onOpen, isExpandable = true, disabled, isActive }: {
  card: IProductCard;
  error?: Nullable<string>;
  disabled?: boolean;
  isExpandable?: boolean;
  defaultExpanded?: boolean;
  isControls?: boolean;
  isActive?: boolean;
  onOpen?: () => void;
  onRemove?: () => void;
  meritBlocksAdditional?: IMeritBlocksAdditional;
  isNeverUsed?: boolean;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const productFormatMessage = useProductFormatMessage(card);
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);
  const formatServerMessage = useFormatServerMessage();
  const { comparePlans } = useFreemium();
  const { isFreemium } = useMerchantPlan();

  const handleChange = useCallback(() => setIsExpanded((prev) => !prev), []);
  const onClickActivateMerit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFreemium) {
      comparePlans();
    } else {
      FreshChatWidget.createNewMessage(formatMessage('Intercom.activate.merit.message', { messageValues: { name: card.isExternal ? productFormatMessage('title') : formatMessage(card.title) } }));
    }
  };

  return (
    <Box className={classes.root}>
      <ExpansionPanel
        expanded={isExpandable && meritBlocksAdditional && isExpanded}
        onChange={handleChange}
        onClick={onOpen}
        TransitionProps={{ unmountOnExit: true }}
        className={isActive ? 'isActive' : null}
      >
        <ExpansionPanelSummary expandIcon={isExpandable && meritBlocksAdditional && <ExpandMoreIcon />}>
          <Grid container alignItems="center" wrap="nowrap">
            {isFreemium && card.disabled && (
              <CrownSVG
                className={classes.premiumIcon}
                title={formatMessage('FlowBuilder.products.premiumProduct')}
              />
            )}
            {card.icon && (
              <Grid container justifyContent="center" alignItems="center" className={classNames(classes.iconWrapper, { [classes.iconWrapperDisabled]: card.disabled || disabled })}>
                <Box className={classes.icon}>
                  {card.icon}
                </Box>
              </Grid>
            )}
            {!card.icon && (
              <Grid container justifyContent="center" alignItems="center" className={classNames(classes.iconWrapper, { [classes.iconWrapperDisabled]: card.disabled || disabled })}>
                {card.iconUrl ? (
                  <img
                    alt=""
                    src={card.iconUrl}
                    className={classes.externalIcon}
                    onError={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  />
                ) : (
                  <Box className={classes.icon}>
                    <FiCopy />
                  </Box>
                )}
              </Grid>
            )}
            <Box ml={1} mr={2}>
              {card.isExternal ? (
                <Box mb={0.5} fontSize={16} color={disabled ? 'common.black50' : 'common.black90'}>
                  {productFormatMessage('title')}
                </Box>
              ) : (card.title && (
                <Box mb={0.5} fontSize={16} color={disabled ? 'common.black50' : 'common.black90'}>
                  {formatMessage(card.title)}
                </Box>
              ))}
              {card.disabled && (
                <Box mt={0.5} onClick={(e) => onClickActivateMerit(e)} className={classes.activateBtn}>{formatMessage('FlowBuilder.integration.activate')}</Box>
              )}
              {!card.disabled && isNeverUsed && (
                <Box mt={0.5} className={classes.tag}>
                  {formatMessage('FlowBuilder.products.neverUsed')}
                </Box>
              )}
            </Box>
          </Grid>
        </ExpansionPanelSummary>
        {meritBlocksAdditional && (
          <ExpansionPanelDetails>
            <Box mt={1.2} color="common.black75">
              {meritBlocksAdditional[card.id]?.shortDescription && (
                formatMessage(`Merit.${card.id}.shortDescription`)
              )}
              {' '}
              {meritBlocksAdditional[card.id]?.readmeLink && (
                <a
                  href={meritBlocksAdditional[card.id]?.readmeLink}
                  className={classes.readMoreLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {formatMessage('link.read-more')}
                </a>
              )}
            </Box>
            <Box width="100%" display="flex">
              <Box mt={1.2}>
                {Boolean(meritBlocksAdditional[card.id]?.inputTypes?.length) && (
                  <>
                    <Box display="flex" mt="4px">
                      <Typography className={classes.processesTitle}>{formatMessage('FlowBuilder.products.inputs.title')}</Typography>
                      <Box marginLeft="8px" display="inline-block" marginTop="-1px">
                        <InfoTooltip
                          placement="right"
                          title={formatMessage('FlowBuilder.products.inputsTooltipText')}
                          popperClassname={classes.popperClassName}
                          arrowClassname={classes.arrowClassName}
                        >
                          <AiFillQuestionCircle color={theme.palette.common.darkgrey} size={16} cursor="pointer" />
                        </InfoTooltip>
                      </Box>
                    </Box>
                    <Box className={classes.badgesContainer}>
                      {meritBlocksAdditional[card.id] && (
                        meritBlocksAdditional[card.id].inputTypes?.map((inputType) => <Badge key={inputType.name} text={formatServerMessage(inputType)} className={classes.inputBadge} />)
                      )}
                    </Box>
                  </>
                )}
                {Boolean(meritBlocksAdditional[card.id]?.meritTypes?.length) && (
                  <>
                    <Box display="flex" mt="16px">
                      <Typography className={classes.processesTitle}>{formatMessage('FlowBuilder.products.outputs.title')}</Typography>
                      <Box marginLeft="8px" display="inline-block" marginTop="-1px">
                        <InfoTooltip
                          placement="right"
                          title={formatMessage('FlowBuilder.products.outputsTooltipText')}
                          popperClassname={classes.popperClassName}
                          arrowClassname={classes.arrowClassName}
                        >
                          <AiFillQuestionCircle color={theme.palette.common.darkgrey} size={16} cursor="pointer" />
                        </InfoTooltip>
                      </Box>
                    </Box>
                    <Box className={classes.badgesContainer}>
                      {meritBlocksAdditional[card.id] && (
                        meritBlocksAdditional[card.id].meritTypes?.map((meritType) => <Badge key={meritType.name} text={formatServerMessage(meritType)} className={classes.inputBadge} />)
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
      {isControls && (
        <Box>
          <Button className={classNames(classes.control, classes.controlTrash)} onClick={onRemove}>
            <FiTrash2 size={17} />
          </Button>
          {error && (
            <Box className={classes.controlIssues} data-qa={QATags.Flows.FlowIssueNotification}>
              <InfoTooltip
                placement="right"
                arrowClassname={classes.tooltipArrow}
                title={formatMessage(error)}
              >
                <RiAlertFill color={theme.palette.common.yellow} size={19} />
              </InfoTooltip>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
