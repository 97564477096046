import { CountryCodeTypes } from 'models/Country.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { IdentityStatuses } from 'models/Status.model';
import { StepError } from 'models/Step.model';

export const SUPPORTED_COUNTRIES = [
  CountryCodeTypes.BR,
  CountryCodeTypes.CO,
  CountryCodeTypes.MX,
  CountryCodeTypes.AR,
  CountryCodeTypes.CL,
  CountryCodeTypes.PE,
  CountryCodeTypes.PH,
  CountryCodeTypes.NG,
] as const;

export enum BankAccountDataSourcesTypes {
  Login = 'Login',
  Upload = 'Upload',
}

export enum BankAccountBalanceCheckTypes {
  AverageBalance = 'averageBalance',
  CurrentBalance = 'currentBalance',
}

export const SUPPORTED_BALANCE_CHECKS = [
  BankAccountBalanceCheckTypes.AverageBalance,
  BankAccountBalanceCheckTypes.CurrentBalance,
];

export enum BankAccountTransactionCheckTypes {
  AverageIncome = 'averageIncome',
  TotalDeposits = 'totalDeposits',
  TotalWithdrawals = 'totalWithdrawals',
  AverageExpenses = 'averageExpenses',
  AverageLoanRepayment = 'averageLoanRepayment',
}

export const SUPPORTED_TRANSACTION_CHECKS = [
  BankAccountTransactionCheckTypes.AverageIncome,
  BankAccountTransactionCheckTypes.TotalDeposits,
  BankAccountTransactionCheckTypes.TotalWithdrawals,
  BankAccountTransactionCheckTypes.AverageExpenses,
  BankAccountTransactionCheckTypes.AverageLoanRepayment,
];

export type BankAccountCheckTypes = BankAccountTransactionCheckTypes | BankAccountBalanceCheckTypes;

export const SUPPORTED_NUMBER_OF_MONTHS = [null, 1, 2, 3, 6, 9, 12];
export const DEFAULT_VALIDATION_MONTH = null;

export const SUPPORTED_RECENT_TIMES = [
  { value: null, key: 'default' },
  { value: 14, key: '2-week' },
  { value: 30, key: '1-month' },
  { value: 60, key: '2-month' },
  { value: 90, key: '3-month' },
];
export const DEFAULT_VALIDATION_RECENCY = null;

export const DATA_SOURCES = [
  BankAccountDataSourcesTypes.Login,
  BankAccountDataSourcesTypes.Upload,
] as const;

export enum BankAccountDataCheckTypes {
  IncomeCheck = 'IncomeCheck',
  LoanRepaymentCheck = 'LoanRepaymentCheck',
  AverageBalanceCheck = 'AverageBalanceCheck',
  ExpensesCheck = 'ExpensesCheck',
  RecencyCheck = 'RecencyCheck',
  AlterationDetection = 'AlterationDetection',
}

export enum BankAccountDataSettingTypes {
  CountryCodes = 'countryCodes',
  DataSources = 'dataSources',
  Checks = 'checks',
  ValidationMonth = 'validationMonth',
  ValidationRecency = 'validationRecency',
}

export enum BankAccountTransactionTypes {
  CREDIT = 'Credit',
  DEBIT = 'Debit',
}

export enum BankAccountTransactionCategoryTypes {
  Salary = 'SALARY',
  LoanRepayment = 'LOAN REPAYMENT',
}

export interface IBankAccountDataVerificationTransaction {
  amount: string;
  balance: string;
  category: string;
  date: string;
  description: string;
  type: string;
}

export interface IBankAccountDataVerificationBalance {
  available: string;
  current: string;
  date: string;
}

export interface IBankAccountDataVerificationAccount {
  availableBalance: string;
  balance: IBankAccountDataVerificationBalance;
  clabeNumber: Nullable<string>;
  currency: string;
  currentBalance: string;
  name: string;
  number: string;
  transactions: Nullable<IBankAccountDataVerificationTransaction[]>;
  transactionsStartDate: Nullable<string>;
  transactionsEndDate: Nullable<string>;
  type: string;
}

export interface IBankAccountDataVerificationIdentity {
  email: string;
  name: string;
  phone: string;
  address: any;
  taxpayerId: string;
}

export interface IBankAccountDataVerificationInstitution {
  countryCodes: CountryCodeTypes[];
  id: string;
  name: string;
  type: string;
}

export interface IBankAccountDataVerificationStatement {
  endDate: string;
  fileId: string;
  isProtected: boolean;
  originalFileName: string;
  startDate: string;
  success: boolean;
  url: Promise<string>;
}

export interface IBankAccountDataVerificationChecks {
  success: boolean;
  checksInfo: IBankAccountChecksInfo[];
  currency: string;
}

export interface IBankAccountChecksInfo {
  totalWithdrawals?: Nullable<IBankAccountChecksDetails>;
  averageExpenses?: Nullable<IBankAccountChecksDetails>;
  totalDeposits?: Nullable<IBankAccountChecksDetails>;
  averageIncome?: Nullable<IBankAccountChecksDetails>;
  averageBalance?: Nullable<IBankAccountChecksDetails>;
  currentBalance?: Nullable<IBankAccountChecksDetails>;
}

export interface IBankAccountChecksDetails {
  success: string;
  merchantValue: string;
  resultingValue: string;
}

export interface IBankAccountDataConsolidatedBalance {
  avgMonthly: Nullable<string>;
  highest: Nullable<string>;
  lowest: Nullable<string>;
}

export interface IBankAccountDataConsolidatedDepositWithdrawal {
  avgMonthly: Nullable<string>;
  topAmount: Nullable<string>;
  topDescription: Nullable<string>;
  totalMonthly: Nullable<Record<string, Nullable<string>>>;
}

export interface IBankAccountDataConsolidatedAccounts {
  balance: IBankAccountDataConsolidatedBalance;
  deposits: IBankAccountDataConsolidatedDepositWithdrawal;
  expense?: IBankAccountDataConsolidatedDepositWithdrawal;
  income?: IBankAccountDataConsolidatedDepositWithdrawal;
  withdrawals: IBankAccountDataConsolidatedDepositWithdrawal;
  loanRepayment: IBankAccountDataConsolidatedDepositWithdrawal;
}

export enum BankAccountSourceTypes {
  Statements = 'statements',
  Login = 'login'
}

export interface IBankAccountDataVerification {
  accounts: IBankAccountDataVerificationAccount[];
  checks: Nullable<IBankAccountDataVerificationChecks>;
  consolidatedAccounts: Nullable<IBankAccountDataConsolidatedAccounts>;
  identity: IBankAccountDataVerificationIdentity;
  institution: IBankAccountDataVerificationInstitution;
  source: Nullable<BankAccountSourceTypes>;
  statements: IBankAccountDataVerificationStatement[];
  verificationStatus: IdentityStatuses;
  error: StepError;
}

export interface IMonthwiseIncomeExpenseChartData {
  timePeriod: string;
  totalDeposit: number;
  totalWithdrawal: number;
}

export interface IBalancesChartData {
  timePeriod: string;
  balance: number;
  averageBalance: number;
}

export interface ICashFlowChartData {
  category: string;
  amount: number;
}

export enum BankAccountDataStepTypes {
  FinancialInformationValidation = 'financial-information-validation',
}

export function getBankAccountData(verification: VerificationResponse): Nullable<IBankAccountDataVerification> {
  const bankAccountData = verification?.steps.find((item) => item.id === BankAccountDataStepTypes.FinancialInformationValidation);

  if (!bankAccountData) {
    return null;
  }

  return { ...bankAccountData?.data, error: bankAccountData?.error, verificationStatus: verification?.verificationStatus };
}

export enum TransactionTableKeyTypes {
  date = 'date',
  description = 'description',
  category = 'category',
  amount = 'amount',
}

export interface ITransactionTableColumns {
  id: TransactionTableKeyTypes;
  isSortable?: boolean;
  isHidden?: boolean;
}

export const transactionTableColumnsData: ITransactionTableColumns[] = [
  {
    id: TransactionTableKeyTypes.date,
    isSortable: true,
  },
  {
    id: TransactionTableKeyTypes.description,
  },
  {
    id: TransactionTableKeyTypes.amount,
    isSortable: true,
  },
];

export const TransactionTableSortDirection = {
  asc: 'asc',
  desc: 'desc',
};

export const TransactionTableSortByMap: Partial<Record<TransactionTableKeyTypes, string>> = {
  [TransactionTableKeyTypes.date]: 'date',
  [TransactionTableKeyTypes.amount]: 'amount',
};

export enum BankAccountChecksStatusTypes {
  Valid = 'valid',
  Invalid = 'invalid',
  Unconfigured = 'unconfigured',
}

export enum BankAccountStepErrorTypes {
  UnlistedFlow = 'unlisted_flow',
  MetadataFailed = 'metadata_failed',
  MetadataReviewRequired = 'metadata_review_required',
}

export enum BankAccountErrorBannerTypes {
  Unsupported = 'unsupported',
  UnsupportedNotUploaded = 'unsupportedNotUploaded',
  UnsupportedInvalid = 'unsupportedInvalid',
  PotentialError = 'potentialError'
}
