import Grid from '@material-ui/core/Grid';
import { CountryCodeTypes } from 'models/Country.model';
import React, { useMemo, useState } from 'react';
import { getPatternNameFromConfig, GroupedPatternsConfigType, IFlattenVerificationPatternConfig } from 'models/VerificationPatternsConfigs.model';
import { GovCheckModalDataBase } from '../GovCheckModalDataBase/GovCheckModalDataBase';
import { GovCheckModalSidebar } from '../GovCheckModalSidebar/GovCheckModalSidebar';
import { useStyles } from './GovCheckModalBody.styles';

export function GovCheckModalBody({ selectedCountry, patternsConfigGroupedByCountry, setSelectedCountry, handleToggleGovCheck, handleToggleOption }: {
  patternsConfigGroupedByCountry: GroupedPatternsConfigType;
  selectedCountry?: CountryCodeTypes;
  setSelectedCountry: (value: CountryCodeTypes) => void;
  handleToggleGovCheck: (checked: boolean, govCheck: IFlattenVerificationPatternConfig) => void;
  handleToggleOption: (govCheck: IFlattenVerificationPatternConfig, checked: boolean, option: IFlattenVerificationPatternConfig) => void;
}) {
  const classes = useStyles();
  const [isShowSelected, setIsShowSelected] = useState<boolean>(false);

  const govChecksConfigByCountry = useMemo(() => {
    const result = patternsConfigGroupedByCountry[selectedCountry];
    if (!isShowSelected) {
      return result;
    }
    return result.filter((config) => config[getPatternNameFromConfig(config)].isActive);
  }, [patternsConfigGroupedByCountry, isShowSelected, selectedCountry]);

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item xs={3} className={classes.sidebar}>
        <GovCheckModalSidebar patternsConfigGroupedByCountry={patternsConfigGroupedByCountry} setIsShowSelected={setIsShowSelected} isShowSelected={isShowSelected} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
      </Grid>
      <Grid item xs={9} className={classes.content}>
        {govChecksConfigByCountry?.map((patternConfig) => <GovCheckModalDataBase key={getPatternNameFromConfig(patternConfig)} handleToggleOption={handleToggleOption} handleToggleGovCheck={handleToggleGovCheck} patternConfig={patternConfig} />)}
      </Grid>
    </Grid>
  );
}
