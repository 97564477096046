import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { CustomSelect } from 'apps/CustomSelect';
import { FlowError } from 'apps/ui';
import { useScrollMeIntoView } from 'lib/ScrollIntoView';
import cloneDeep from 'lodash/cloneDeep';
import { CountryCodeTypes, COUNTRY_CURRENCY_MAPPING } from 'models/Country.model';
import { IFlowFinancialInformationChecks } from 'models/Flow.model';
import { ProductSettingsProps } from 'models/Product.model';
import React, { useMemo, useCallback } from 'react';
import { useFormatMessage } from 'apps/intl';
import { AppTheme } from 'apps/theme';
import { BankAccountDataFinancialChecks } from '../BankAccountDataFinancialChecks/BankAccountDataFinancialChecks';
import { BankAccountDataSettingTypes, SUPPORTED_COUNTRIES, DATA_SOURCES, SUPPORTED_NUMBER_OF_MONTHS, SUPPORTED_RECENT_TIMES, BankAccountDataSourcesTypes, DEFAULT_VALIDATION_MONTH, DEFAULT_VALIDATION_RECENCY } from '../../models/BankAccountData.model';

export function BankAccountDataSettings({ settings, onUpdate }: ProductSettingsProps<BankAccountDataSettingTypes>) {
  const formatMessage = useFormatMessage();
  const ScrollMeIntoView = useScrollMeIntoView();

  const supportedCurrencies = useMemo(() => settings?.countryCodes?.value?.map((countryCode) => COUNTRY_CURRENCY_MAPPING[countryCode]), [settings?.countryCodes?.value]);
  const countries = SUPPORTED_COUNTRIES.map((country) => ({ label: formatMessage(`Countries.${country}`), value: country }));
  const dataSources = DATA_SOURCES.map((item) => ({ label: formatMessage(`BankAccountData.dataSources.${item}.option`), value: item }));
  const numberOfMonths = SUPPORTED_NUMBER_OF_MONTHS.map((item) => ({ label: formatMessage(`BankAccountData.numberOfMonths.${item ?? 'default'}-month`), value: item }));
  const recentTimes = SUPPORTED_RECENT_TIMES.map(({ key, value }) => ({ label: formatMessage(`BankAccountData.recentTme.${key}`), value }));

  const handleCountryUpdate = useCallback((codes: CountryCodeTypes[]) => {
    const newSettings = cloneDeep(settings);
    newSettings.countryCodes.value = codes;
    const checks = newSettings?.checks?.value;
    const currencies = newSettings?.countryCodes?.value?.map((countryCode) => COUNTRY_CURRENCY_MAPPING[countryCode]);

    Object.keys(checks)?.forEach((key) => {
      checks[key].thresholds = checks[key].thresholds.filter((threshold) => currencies.includes(threshold.currency));
    });

    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleCheckUpdate = useCallback((checks: IFlowFinancialInformationChecks) => {
    const newSettings = cloneDeep(settings);
    newSettings.checks.value = checks;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleDataSourcesUpdate = useCallback((updatedDataSources: BankAccountDataSourcesTypes[]) => {
    const newSettings = cloneDeep(settings);
    newSettings.dataSources.value = updatedDataSources;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleMonthValidationUpdate = useCallback((updatedValidationMonth: string[]) => {
    const newSettings = cloneDeep(settings);
    newSettings.validationMonth.value = updatedValidationMonth[0];
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleRecencyValidationUpdate = useCallback((updatedValidationRecency: string[]) => {
    const newSettings = cloneDeep(settings);
    newSettings.validationRecency.value = updatedValidationRecency[0];
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  return (
    <Box>
      <Box>
        {settings.countryCodes?.error && (
          <ScrollMeIntoView />
        )}
        <Box mb={1} borderRadius={4} fontWeight={700}>
          {formatMessage('BankAccountData.country.title')}
        </Box>
        {settings.countryCodes?.error && (
          <Box mb={1}>
            <FlowError text={formatMessage(settings.countryCodes.error)} />
          </Box>
        )}
        <CustomSelect
          placeholder={formatMessage('BankAccountData.country.placeholder')}
          defaultValue={settings?.countryCodes?.value}
          list={countries}
          isAllSelectable
          onApply={handleCountryUpdate}
        />
        {settings.dataSources?.error && (
          <ScrollMeIntoView priority={1} />
        )}
        <Box mb={1} mt={3} borderRadius={4} fontWeight={700}>
          {formatMessage('BankAccountData.dataSources.title')}
        </Box>
        {settings.dataSources?.error && (
          <Box mb={1}>
            <FlowError text={formatMessage(settings.dataSources.error)} />
          </Box>
        )}
        <CustomSelect
          placeholder={formatMessage('BankAccountData.dataSources.placeholder')}
          defaultValue={settings?.dataSources?.value}
          list={dataSources}
          onApply={handleDataSourcesUpdate}
        >
          <Box mb={2} p={1} bgcolor={AppTheme.palette.common.greentransparent} color={AppTheme.palette.common.black75} borderRadius={4}>
            <Box>
              <Box fontWeight="bold" display="inline">
                {formatMessage('BankAccountData.dataSources.info.title')}
              </Box>
              {formatMessage('BankAccountData.dataSources.info.block1')}
            </Box>
            <Box mt={2}>
              {formatMessage('BankAccountData.dataSources.info.block2')}
            </Box>
          </Box>
        </CustomSelect>
      </Box>
      <Box mt={3} bgcolor="#F9FAFB" p={1} borderRadius={12} border={1} borderColor={AppTheme.palette.common.black7}>
        <Box my={1} fontWeight={700} fontSize={16}>
          {formatMessage('BankAccountData.dataSources.criteria')}
          <Box mt={1}><Divider /></Box>
        </Box>
        <Box my={1} color="common.black90">
          {formatMessage('BankAccountData.numberOfMonths.title')}
        </Box>
        <CustomSelect
          placeholder={formatMessage('BankAccountData.numberOfMonths.placeholder')}
          defaultValue={[settings?.validationMonth?.value ?? DEFAULT_VALIDATION_MONTH]}
          isMultiple={false}
          list={numberOfMonths}
          onApply={handleMonthValidationUpdate}
          disabled={!settings?.dataSources?.value?.includes(BankAccountDataSourcesTypes.Upload)}
        />
        <Box mt={1} mb={1} color="common.black90">
          {formatMessage('BankAccountData.recentTme.title')}
        </Box>
        <CustomSelect
          placeholder={formatMessage('BankAccountData.recentTme.placeholder')}
          defaultValue={[settings?.validationRecency?.value ?? DEFAULT_VALIDATION_RECENCY]}
          isMultiple={false}
          list={recentTimes}
          onApply={handleRecencyValidationUpdate}
          disabled={!settings?.dataSources?.value?.includes(BankAccountDataSourcesTypes.Upload)}
        />
      </Box>
      <BankAccountDataFinancialChecks
        onUpdate={handleCheckUpdate}
        checks={settings?.checks?.value}
        errorMessage={settings.checks?.error}
        supportedCurrencies={supportedCurrencies}
        disableLibrary={settings?.countryCodes?.value?.length === 0}
      />
    </Box>
  );
}
