import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    width: '36px',
    height: '32px',
    cursor: 'pointer',
    borderRadius: '4px',
    color: theme.palette.common.black75,
  },
  active: {
    background: theme.palette.common.whiteblue,
    color: theme.palette.common.lightblue,
  },
}));
