import { LogoUrls } from 'apps/logo';
import { selectModelValue } from 'lib/loadable.selectors';
import { IFlow, IFlowStyle } from 'models/Flow.model';
import { Loadable } from 'models/Loadable.model';
import { ProductIntegrationTypes, ProductTypes } from 'models/Product.model';
import { createSelector } from 'reselect';
import { FLOW_BUILDER_STORE_KEY, FlowBuilderStore, SliceNameTypes } from './FlowBuilder.store';
import { IPreviewFlow } from '../models/FlowBuilder.model';
import { IFlowAuditEvent } from '../models/FlowAudit.model';

export const flowBuilderStore = (state: {FLOW_BUILDER_STORE_KEY: FlowBuilderStore}): FlowBuilderStore => state[FLOW_BUILDER_STORE_KEY];

export const selectFlowBuilderSelectedId = createSelector<[typeof flowBuilderStore], ProductTypes>(
  flowBuilderStore,
  (store: FlowBuilderStore) => store.selectedId,
);

export const selectIsShowIntegration = (state): boolean => flowBuilderStore(state).isShowIntegration;

export const selectIsShowCustomization = (state): boolean => flowBuilderStore(state).isShowCustomization;

export const selectFlowBuilderHaveUnsavedChanges = createSelector(
  flowBuilderStore,
  (store: FlowBuilderStore): boolean => store.haveUnsavedChanges,
);

export const selectFlowBuilderProductsInGraphModel = createSelector<[typeof flowBuilderStore], Loadable<ProductTypes[]>>(
  flowBuilderStore,
  (store: FlowBuilderStore): Loadable<ProductTypes[]> => store.productsInGraph,
);

export const selectPreviewFlowModel = createSelector<[typeof flowBuilderStore], Loadable<IPreviewFlow>>(
  flowBuilderStore,
  (store: FlowBuilderStore): Loadable<IPreviewFlow> => store.previewFlow,
);

export const selectFlowBuilderProductsInGraph = createSelector<[typeof selectFlowBuilderProductsInGraphModel], ProductTypes[]>(
  selectFlowBuilderProductsInGraphModel,
  selectModelValue(),
);

export const selectFlowBuilderChangeableFlowModel = createSelector<[typeof flowBuilderStore], Loadable<IFlow>>(
  flowBuilderStore,
  (store: FlowBuilderStore): Loadable<IFlow> => store.changeableFlow,
);

export const selectFlowBuilderChangeableFlow = createSelector<[typeof selectFlowBuilderChangeableFlowModel], IFlow>(
  selectFlowBuilderChangeableFlowModel,
  selectModelValue(),
);

export const selectFlowBuilderIntegrationType = createSelector<[typeof selectFlowBuilderChangeableFlow], ProductIntegrationTypes>(
  selectFlowBuilderChangeableFlow,
  (flow: IFlow): ProductIntegrationTypes => flow.integrationType,
);

export const selectFlowBuilderChangeableFlowStyle = createSelector(
  selectFlowBuilderChangeableFlow,
  (flow: IFlow): IFlowStyle => flow.style,
);

export const selectFlowBuilderChangeableLogoUrl = createSelector(
  selectFlowBuilderChangeableFlow,
  (flow: IFlow): LogoUrls => flow.logo,
);

export const selectFlowHistoryModel = createSelector<[typeof flowBuilderStore], Loadable<IFlowAuditEvent[]>>(
  flowBuilderStore,
  (store) => store[SliceNameTypes.FlowHistory],
);

export const selectFlowHistoryList = createSelector<[typeof selectFlowHistoryModel], IFlowAuditEvent[]>(
  selectFlowHistoryModel,
  selectModelValue((changes) => changes),
);

export const selectFlowHistoryTotalCount = createSelector(
  flowBuilderStore,
  (store) => store[SliceNameTypes.HistoryEntriesCount],
);
