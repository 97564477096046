import React from 'react';
import classNames from 'classnames';
import { useStyles } from './ContainerAccessAllowed.styles';

export function ContainerAccessAllowed({ children, disabled }: {
    children: React.ReactElement;
    disabled: boolean;
}) {
  const classes = useStyles();
  return (
    <div className={classNames({ [classes.container]: disabled })}>
      {children}
    </div>
  );
}
