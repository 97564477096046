import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorCatch } from 'apps/Error';
import { ProductTypes } from 'models/Product.model';
import { useFlowBuilderCatchProduct } from '../../hooks/FlowBuilderCatchProduct.hook';
import { selectFlowBuilderSelectedId } from '../../store/FlowBuilder.selectors';

export const FlowBuilderErrorWrapper = ({ children }) => {
  const selectedId = useSelector<any, ProductTypes>(selectFlowBuilderSelectedId);
  const handleError = useFlowBuilderCatchProduct(selectedId);

  return (
    <ErrorCatch onError={handleError}>
      {children}
    </ErrorCatch>
  );
};
