import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useFormatMessage } from 'apps/intl';
import { ErrorStatusTypes } from 'models/Error.model';
import { selectMerchantId } from 'state/merchant/merchant.selectors';
import { WatchlistFileUpload, IWatchlistApiCall, IWatchlistFileUploadErrors } from 'apps/ui';
import { IWatchlist, IWatchlistUpload, IWatchlistValidationError, WATCHLIST_VALIDATION_ERROR_TYPE_MAX_COUNT } from 'models/Watchlist.model';
import { selectCurrentBasicWatchlistIsFileAvailable, selectCurrentBasicWatchlistFileInfo, selectCurrentBasicWatchlistFileError, selectCurrentBasicWatchlistError } from '../../state/Aml.selectors';
import { BasicWatchlistModalValidationInputTypes } from '../../models/Aml.model';
import * as api from '../../client/Aml.client';
import { clearCurrentWatchlistFileError, updateCurrentWatchlist } from '../../state/Aml.actions';

export function BasicWatchlistModalValidationFileUploadForm({ watchlist, onFileUploaded }: {
  watchlist?: IWatchlist;
  onFileUploaded?: (data: IWatchlistUpload) => void;
}) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const merchantId = useSelector<any, string>(selectMerchantId);
  const isFileAvailable = useSelector<any, boolean>(selectCurrentBasicWatchlistIsFileAvailable);
  const currentWatchlistError = useSelector<any, IWatchlistValidationError[] | null>(selectCurrentBasicWatchlistError);
  const currentBasicWatchlistFileInfo = useSelector<any, Partial<{ fileKey: string; fileName: string }>>(selectCurrentBasicWatchlistFileInfo);
  const fileErrorType = useSelector<any, string>(selectCurrentBasicWatchlistFileError);
  const { setValue, setError, clearErrors, formState: { errors } } = useFormContext();

  const isEdit = Boolean(watchlist);

  useEffect(() => {
    if (currentBasicWatchlistFileInfo?.fileKey && currentBasicWatchlistFileInfo?.fileName) {
      setValue(BasicWatchlistModalValidationInputTypes.FileKey, currentBasicWatchlistFileInfo.fileKey);
      setValue(BasicWatchlistModalValidationInputTypes.FileName, currentBasicWatchlistFileInfo.fileName);
    }
  }, [currentBasicWatchlistFileInfo, setValue]);

  useEffect(() => {
    if (fileErrorType) {
      setError(BasicWatchlistModalValidationInputTypes.FileKey, {
        message: formatMessage(`Watchlist.settings.${fileErrorType}`),
      });
    }
  }, [fileErrorType, setError, formatMessage]);

  const onFileSelect = useCallback((valueFileName: string) => {
    dispatch(clearCurrentWatchlistFileError());
    setValue(BasicWatchlistModalValidationInputTypes.FileName, valueFileName);
  }, [dispatch, setValue]);

  const apiCall = useMemo<IWatchlistApiCall<IWatchlistUpload>>(() => ({
    callTo: api.uploadMerchantWatchlist,
    onSuccess: (data) => {
      setValue(BasicWatchlistModalValidationInputTypes.FileKey, data.key);
      clearErrors(BasicWatchlistModalValidationInputTypes.FileKey);
      onFileUploaded(data);

      if (!isFileAvailable && isEdit) {
        dispatch(updateCurrentWatchlist({ isFileAvailable: true }));
      }
    },
    onError: (error: any) => {
      if (error?.response?.status === ErrorStatusTypes.PayloadTooLarge) {
        setError(BasicWatchlistModalValidationInputTypes.FileKey, {
          message: formatMessage('Watchlist.settings.watchlist.fileSizeExceed'),
        });

        return;
      }
      setError(BasicWatchlistModalValidationInputTypes.FileKey, {
        message: formatMessage('Watchlist.settings.watchlist.fileErrorUpload'),
      });
    },
  }), [isFileAvailable, isEdit, setValue, clearErrors, setError, onFileUploaded, formatMessage, dispatch]);

  const watchlistFileUploadErrors = useMemo<IWatchlistFileUploadErrors>(() => ({
    fileKey: errors[BasicWatchlistModalValidationInputTypes.FileKey]?.message,
    csvSeparator: errors[BasicWatchlistModalValidationInputTypes.CsvSeparator]?.message,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [errors[BasicWatchlistModalValidationInputTypes.FileKey], errors[BasicWatchlistModalValidationInputTypes.CsvSeparator]]);

  const isMaxRowCountError = currentWatchlistError?.[0].type === WATCHLIST_VALIDATION_ERROR_TYPE_MAX_COUNT;

  return (
    <WatchlistFileUpload
      initialFileName={watchlist?.process?.inputSourceFileName}
      csvSeparatorFieldName={BasicWatchlistModalValidationInputTypes.CsvSeparator}
      api={apiCall}
      merchantId={merchantId}
      errors={watchlistFileUploadErrors}
      isFileAvailable={isFileAvailable}
      onFileSelect={onFileSelect}
      error={isMaxRowCountError && formatMessage(`Watchlist.settings.modal.button.${currentWatchlistError[0].type}`)}
    />
  );
}
