import { IValidatedInputsFieldTypes, ValidatedInputsKeys, WatchlistValidatedInputsErrors } from 'apps/ui';
import { VerificationDataSourceTypes } from 'apps/CustomWatchlist';
import { IFlowBasicWatchlists, IPremiumAmlWatchlists } from './Aml.model';
import { ICustomWatchList, IFlowWatchlist } from './CustomWatchlist.model';
import { ErrorStatusTypes } from './Error.model';
import { DocumentTypes, PremiumAmlWatchlistStep, VerificationDocument, VerificationDocumentTypes } from './Document.model';
import { IBasicWatchlistStepData, IPremiumWatchlistData } from '../apps/Aml/models/Aml.model';
import { VerificationResponse } from './VerificationOld.model';
import { VerificationPatternTypes } from './VerificationPatterns.model';
import { getStepExtra, IStep, StepError, StepStatus, VerificationStepTypes } from './Step.model';

export const WATCHLIST_VALIDATION_ERROR_TYPE_MAX_COUNT = 'watchlists.maxRowCountReached';
export const WATCHLIST_VALIDATION_NAME_MAXLENGTH = 40;
export const WATCHLIST_VALIDATION_NAME_PATTERN = /^[a-zA-Z0-9()\s]+$/;
export const WATCHLIST_VALIDATION_LOCALISEKEY_MINLENGTH = 5;
export const WATCHLIST_VALIDATION_LOCALISEKEY_MAXLENGTH = 30;
export const WATCHLIST_VALIDATION_LOCALISEKEY_PATTERN = /^[a-zA-Z]+$/;

export type WatchlistIdType = number;

export interface IWatchlistGroup {
  id: number;
  name: string;
}

export interface IWatchlistMappingOptions {
  fuzziness?: number;
}

export interface IWatchlistMapping {
  systemField: ValidatedInputsKeys;
  merchantField: string;
  options?: IWatchlistMappingOptions;
}

export interface IWatchlistValidationError {
  code: ErrorStatusTypes;
  type: string;
  data: IWatchlistValidationErrorData;
  message?: string;
  name?: string;
  stack?: string;
}

export interface IWatchlistValidationErrorData {
  fieldName: ValidatedInputsKeys;
  row: number;
}

export enum WatchlistProcessNameTypes {
  ContentParse = 'contentParse',
}

export interface IWatchlistProcess {
  completedAt: Nullable<string>;
  createdAt: Nullable<string>;
  error: Nullable<IWatchlistValidationError[]>;
  id: number;
  inputSourceFileName: string;
  inputSourceFileKey: string;
  name: WatchlistProcessNameTypes;
  startCount: number;
  status: WatchlistProcessStatusTypes;
  startedAt: Nullable<string>;
  updatedAt: Nullable<string>;
  watchlistId: WatchlistIdType;
  csvSeparator: string;
  fileNames?: string[];
}

export type IWatchlistProcessPartial = Partial<Nullable<IWatchlistProcess>>;

export enum WatchlistProcessStatusTypes {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
}

export enum WatchlistTypes {
  Custom = 'custom',
  Basic = 'basic',
  CustomWatchlistBiometrics = 'custom-watchlist-biometrics'
}

export enum DataSourceValueTypes {
  DocumentVerification = 'document-data',
  CustomField = 'custom-fields',
}

export const dataSourceProductTypesValues = Object.entries(DataSourceValueTypes).reduce((obj, [key, value]) => ({ ...obj, [value]: key }), {});

export interface IWatchlistContent {
  inputSourceFileKey?: string;
  inputSourceFileName?: string;
  csvSeparator?: string;
  fileNames?: string[];
}

export type IWatchlistHeaders = Pick<IWatchlistContent, 'inputSourceFileKey' | 'csvSeparator'>

export interface IWatchlistShortValidation extends Pick<IWatchlistContent, 'inputSourceFileKey' | 'csvSeparator'>, Pick<IWatchlist, 'mapping'> {}

export interface IWatchlistCreateBody {
  name: string;
  mapping?: IWatchlistMapping[];
  watchlistType: WatchlistTypes;
  isVisible?: boolean;
  groupId?: Nullable<number>;
}

export interface IWatchlistValidation {
  valid: boolean;
  errors?: IWatchlistValidationError[];
}

export interface IWatchlistUpload {
  key: string;
}

export interface IWatchlist {
  id: WatchlistIdType;
  name: string;
  createdAt: string;
  updatedAt: string;
  merchantId: string;
  mapping: Nullable<IWatchlistMapping[]>;
  process: IWatchlistProcessPartial;
  isFileAvailable: boolean;
  groupId: Nullable<number>;
  group: Nullable<IWatchlistGroup>;
  isVisible: boolean;
  localiseKey?: string;
  watchlistType?: WatchlistTypes;
  mediaLinks?: string[];
}

export interface IFlowWatchlists {
  basic?: IFlowBasicWatchlists;
  premiumAml?: IPremiumAmlWatchlists;
  custom?: IFlowWatchlist;
  customWatchlistBiometrics?: ICustomWatchList;
}

export function getWatchlistMapping(headers?: string[], mapping?: IWatchlistMapping[]): IValidatedInputsFieldTypes[] {
  if (headers) {
    return headers.map((header) => {
      const mappingFinded = mapping?.find((map) => map.merchantField === header);
      return {
        label: header,
        value: mappingFinded?.systemField ?? ValidatedInputsKeys.NotSelected,
      };
    });
  }

  if (mapping) {
    return mapping.map((fields) => ({ label: fields.merchantField, value: fields.systemField, ...(fields?.options && { options: fields.options }) }));
  }

  return [];
}

export function getWatchlistValidMapping(inputFields: IValidatedInputsFieldTypes[]): IWatchlistMapping[] {
  return inputFields.map((fields) => ({ merchantField: fields.label, systemField: fields.value, ...(fields?.options && { options: fields.options }) }));
}

export function getWatchlistErrorsFormated(errors?: IWatchlistValidationError[]): Nullable<WatchlistValidatedInputsErrors> {
  if (!errors) {
    return null;
  }

  return errors.reduce((memo, cur) => {
    if (cur.code === ErrorStatusTypes.ValidationError) {
      return null;
    }

    const fieldName = cur.data.fieldName;

    memo[fieldName] = errors.filter((item) => item.data.fieldName === fieldName);

    memo[fieldName] = memo[fieldName].map((item) => ({
      systemField: item.data.fieldName,
      type: item.type,
      row: item.data.row,
    }));
    return memo;
  }, {});
}

export const getDataSourceFromList = (data: { dataSource: string; documentType?: VerificationDocumentTypes }[]) => (data?.[0].dataSource === VerificationDataSourceTypes.CustomFields ? VerificationDataSourceTypes.CustomFields : data?.[0].documentType);

export const getDataSourceCountry = (data: { country?: string }[]): string | undefined => data?.[0].country;

type WatchlistDataSourceType = {
  documentType?: VerificationDocumentTypes;
  dataSource: VerificationDataSourceTypes;
};

export const getStepsBySource = <T extends WatchlistDataSourceType>(stepData: T[]): PartialRecord<VerificationDataSourceTypes.CustomFields | DocumentTypes, T[]> => stepData.reduce((acc, data) => {
  const sourceKey = data.dataSource === VerificationDataSourceTypes.CustomFields ? data.dataSource : data.documentType;

  if (!acc[sourceKey]) {
    // eslint-disable-next-line no-param-reassign
    acc[sourceKey] = [data];
  } else {
    acc[sourceKey].push(data);
  }

  return acc;
}, {});

interface IWatchlistStepData<T> {
  data?: T[];
  error: StepError;
  checkStatus?: StepStatus;
}

interface IDataSourceItem {
  country?: string;
  photos?: string[];
  basicWatchlists?: IWatchlistStepData<IBasicWatchlistStepData>;
  premiumWatchlists?: IWatchlistStepData<IPremiumWatchlistData>;
  legacyBasicWatchlist?: IStep;
  legacyPremiumWatchlist?: PremiumAmlWatchlistStep;
}

const findCustomFieldDataSource = (data?: { dataSource: string; country?: string }[]) => data?.find(({ dataSource }) => dataSource === VerificationDataSourceTypes.CustomFields);

export const getDataSourceStructure = ({ steps, documents }: VerificationResponse): PartialRecord<VerificationDataSourceTypes.CustomFields | DocumentTypes, IDataSourceItem> => {
  const basicWatchListStep: IStep<IBasicWatchlistStepData[]> = getStepExtra(steps.find((dataStep) => dataStep.id === VerificationStepTypes.Watchlists));
  const premiumAmlStep: IStep<IPremiumWatchlistData[]> = getStepExtra(steps.find(({ id }) => id === VerificationPatternTypes.PremiumAmlWatchListsValidation));
  const customFieldsDataSource = findCustomFieldDataSource(basicWatchListStep?.data) || findCustomFieldDataSource(premiumAmlStep?.data);

  if (customFieldsDataSource) {
    return {
      [VerificationDataSourceTypes.CustomFields]: {
        country: customFieldsDataSource?.country,
        basicWatchlists: basicWatchListStep && {
          data: basicWatchListStep.data,
          error: basicWatchListStep.error,
          checkStatus: basicWatchListStep.checkStatus,
        },
        premiumWatchlists: premiumAmlStep && {
          data: premiumAmlStep.data,
          error: premiumAmlStep.error,
          checkStatus: premiumAmlStep.checkStatus,
        },
      },
    };
  }

  if (!documents) return {};

  const basicByDataSource = basicWatchListStep?.data && getStepsBySource(basicWatchListStep.data);
  const premiumByDataSource = premiumAmlStep?.data && getStepsBySource(premiumAmlStep.data);

  return (documents as VerificationDocument[]).reduce((acc, {
    type,
    watchlistsStep,
    premiumAmlWatchlistsStep,
    photos,
    country,
  }) => ({
    ...acc,
    [type]: {
      country,
      photos,
      legacyBasicWatchlist: watchlistsStep,
      legacyPremiumWatchlist: premiumAmlWatchlistsStep,
      basicWatchlists: basicWatchListStep && { data: basicByDataSource?.[type], error: basicWatchListStep?.error, checkStatus: basicWatchListStep?.checkStatus },
      premiumWatchlists: premiumAmlStep && { data: premiumByDataSource?.[type], error: premiumAmlStep.error, checkStatus: premiumAmlStep.checkStatus },
    },
  }), {});
};

export interface ISignedUrlEntry {
  url: string;
  fileName: string;
}
export interface ISignedUrlResponse {
  signedUrls: ISignedUrlEntry[];
}

export const allowedFileTypes = ['image/jpeg', 'image/jpg'];
export const maxFileSize = 5 * 1024 * 1024; // 5MB
export const maxFileCount = 50;
