import { SupportedLocales } from 'models/Intl.model';
import { FiDownload, FiLoader } from 'react-icons/fi';
import { IdentityStatuses } from 'models/Status.model';
import { QATags } from 'models/QA.model';
import React, { useCallback, useState } from 'react';
import { pdfDownloaded, pdfGeneratePdf } from 'state/identities/identities.actions';
import { downloadBlob } from 'lib/file';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonHeaderMenu, notification } from 'apps/ui';
import type { VerificationResponse } from 'models/VerificationOld.model';
import { selectLanguage, selectMerchantLegalAddress, selectMerchantLegalName, selectMerchantLegalRegNumber, selectMerchantTags } from 'state/merchant/merchant.selectors';
import { useFormatMessage } from 'apps/intl';
import { MerchantTagsTypes } from 'models/Merchant.model';

export function ButtonVerificationGeneratePdf({ verification, className, isLegacyDownload }: {
  verification: VerificationResponse;
  className: string;
  isLegacyDownload?: boolean;
}) {
  const dispatch = useDispatch();
  const [isPDFGenerating, setIsPDFGenerating] = useState(false);
  const legalName = useSelector(selectMerchantLegalName);
  const legalAddress = useSelector(selectMerchantLegalAddress);
  const legalRegNumber = useSelector(selectMerchantLegalRegNumber);
  const currentLang = useSelector<any, SupportedLocales>(selectLanguage);
  const formatMessage = useFormatMessage();
  const merchantTags = useSelector(selectMerchantTags);

  const handlePDFDownload = useCallback(async () => {
    // TODO: remove FE generation when BE generation is released and field tested
    if (merchantTags.includes(MerchantTagsTypes.ShouldUseFrontendVerificationPdfGeneration) || isLegacyDownload) {
      if (isPDFGenerating) {
        return;
      }
      setIsPDFGenerating(true);
      const { getVerificationDocumentBlob } = await import('../../VerificationDocument.pdf');
      const blob = await getVerificationDocumentBlob(verification, currentLang, { legalName, legalAddress, legalRegNumber });
      downloadBlob(blob, `metamap-verification-${verification?._id}.pdf`);
      setIsPDFGenerating(false);
      dispatch(pdfDownloaded(verification?.identity, verification?._id));
      return;
    }

    dispatch(pdfGeneratePdf(
      verification?._id,
      formatMessage('verificationModal.generatingPDF'),
      () => notification.warn(formatMessage('verificationModal.pdfRpcTimeout')),
    ));
  }, [merchantTags, isLegacyDownload, dispatch, verification, formatMessage, isPDFGenerating, setIsPDFGenerating, currentLang, legalName, legalAddress, legalRegNumber]);

  return (
    <ButtonHeaderMenu
      variant="contained"
      onClick={handlePDFDownload}
      className={className}
      startIcon={isPDFGenerating ? <FiLoader /> : <FiDownload />}
      disabled={verification?.verificationStatus === IdentityStatuses.running}
      data-qa={QATags.Verification.Buttons.DownloadPdf}
    >
      {formatMessage('verificationModal.downloadPDF')}
    </ButtonHeaderMenu>
  );
}
