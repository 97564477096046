export class FontLoader {
  private requested = {};
  private queue = [];
  private BASE_URL = 'https://fonts.googleapis.com/css2';

  load(fontFamily: string) {
    if (this.requested[fontFamily]) {
      return;
    }

    this.requested[fontFamily] = true;
    this.queue.push(new Promise((resolve) => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', `${this.BASE_URL}?family=${fontFamily.replaceAll(' ', '+')}`);
      link.onload = () => resolve(true);
      document.head.appendChild(link);
    }));

    this.process();
  }

  async process() {
    /* eslint-disable */
    for (const item of this.queue) {
      await item;
    }
    /* eslint-enable */
  }
}

export const fontLoader = new FontLoader();
