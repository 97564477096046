import { useCallback, useEffect, useState } from 'react';
import { IReFacematchStep } from 'models/ReVerification.model';
import { getMedia } from 'apps/media/api/media.client';
import { MediaStatusTypes } from 'apps/media/models/Media.model';

export function useUAMWithPrivateMedia(uam: IReFacematchStep): IReFacematchStep {
  const [currentSelfiePhoto, setCurrentSelfiePhoto] = useState<string | MediaStatusTypes>(MediaStatusTypes.MediaIsEmpty);
  const [currentSelfieVideo, setCurrentSelfieVideo] = useState<string | MediaStatusTypes>(MediaStatusTypes.MediaIsEmpty);
  const [previousSelfiePhoto, setPreviousSelfiePhoto] = useState<string | MediaStatusTypes>(MediaStatusTypes.MediaIsEmpty);
  const [previousSelfieVideo, setPreviousSelfieVideo] = useState<string | MediaStatusTypes>(MediaStatusTypes.MediaIsEmpty);

  // This huge condition check is needed to append only the attributes that are returned from the network call
  const uamWithPrivateMedia = { ...uam, data: { currentSelfiePhotoUrl: uam?.data?.currentSelfiePhotoUrl ? currentSelfiePhoto : undefined, currentSelfieVideoUrl: uam?.data?.currentSelfieVideoUrl ? currentSelfieVideo : undefined, previousSelfiePhotoUrl: uam?.data?.previousSelfiePhotoUrl ? previousSelfiePhoto : undefined, previousSelfieVideoUrl: uam?.data?.previousSelfieVideoUrl ? previousSelfieVideo : undefined } };

  const handleLoadMedia = useCallback(async (url: string, onLoad: Function): Promise<void> => {
    if (!url) {
      return;
    }
    try {
      const response = await getMedia(url);
      onLoad(URL.createObjectURL(response.data));
    } catch (error) {
      onLoad(MediaStatusTypes.MediaIsFailed);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (uam?.data?.currentSelfiePhotoUrl) {
      setCurrentSelfiePhoto(MediaStatusTypes.MediaIsLoading);
    }
    if (uam?.data?.currentSelfieVideoUrl) {
      setCurrentSelfieVideo(MediaStatusTypes.MediaIsLoading);
    }
    if (uam?.data?.previousSelfiePhotoUrl) {
      setPreviousSelfiePhoto(MediaStatusTypes.MediaIsLoading);
    }
    if (uam?.data?.previousSelfieVideoUrl) {
      setPreviousSelfieVideo(MediaStatusTypes.MediaIsLoading);
    }
  }, [uam?.data?.currentSelfiePhotoUrl, uam?.data?.currentSelfieVideoUrl, uam?.data?.previousSelfiePhotoUrl, uam?.data?.previousSelfieVideoUrl]);

  useEffect(() => {
    handleLoadMedia(uam?.data?.currentSelfiePhotoUrl, setCurrentSelfiePhoto);
  }, [handleLoadMedia, uam?.data?.currentSelfiePhotoUrl]);

  useEffect(() => {
    handleLoadMedia(uam?.data?.currentSelfieVideoUrl, setCurrentSelfieVideo);
  }, [handleLoadMedia, uam?.data?.currentSelfieVideoUrl]);

  useEffect(() => {
    handleLoadMedia(uam?.data?.previousSelfiePhotoUrl, setPreviousSelfiePhoto);
  }, [handleLoadMedia, uam?.data?.previousSelfiePhotoUrl]);

  useEffect(() => {
    handleLoadMedia(uam?.data?.previousSelfieVideoUrl, setPreviousSelfieVideo);
  }, [handleLoadMedia, uam?.data?.previousSelfieVideoUrl]);

  return uamWithPrivateMedia;
}
