import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './DocumentSubTypesModalFooter.styles';

export function DocumentSubTypesModalFooter({ handleApply, handleClose, isApplyDisabled }: {
  handleApply: () => void;
  handleClose: () => void;
  isApplyDisabled: boolean;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Box className={classes.root}>
      <Button onClick={handleClose} variant="text" color="primary" className={classes.cancelBtn}>
        {formatMessage('DocumentVerification.settings.description.subTypesSelection.button.cancel')}
      </Button>
      <Button onClick={handleApply} variant="contained" color="primary" disabled={isApplyDisabled} className={classes.applyBtn}>
        {formatMessage('DocumentVerification.settings.description.subTypesSelection.button.apply')}
      </Button>
    </Box>
  );
}
