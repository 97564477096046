export enum EventTypes {
  Navigation = 'navigation',
  UserAction = 'userAction',
  ViewElement = 'viewElement',
}

export enum NavigationTypes {
  Analytics = 'Analytics',
  FAQ = 'FAQ',
  WorkflowList = 'Workflow List',
  Workflow = 'Workflow',
  WorkflowHistory = 'Workflow History',
  VerificationList = 'Verification List',
  Verification = 'Verification',
  VerificationHistory = 'Verification History',
  Dev = 'Integrations',
  Settings = 'Settings',
  ReviewMode = 'Verification Review Mode',
  TemplateList = 'Template List',
  Template = 'Template Builder',
  AgentHistory = 'Agent History',
  CustomerList = 'Customer List',
  Automations = 'Automations',
  FremiumVsPremium = 'Fremium Vs Premium',
  FremiumVsPremiumDetail = 'Fremium Vs Premium Detail'
}

export enum TrackTypes {
  ProfileInsightsViewed = 'Profile Insights Viewed',
  VerificationTabViewed = 'Verification Tab Viewed',
  AutomationUpdated = 'Automation Updated',
  WorkflowCreated = 'Workflow Created',
  WorkflowUpdated = 'Workflow Updated',
}

export enum VerificationTabTypes {
    FraudAssessment = 'Fraud Assessment',
}

export enum TrackMetaDataTypes {
  WorkflowBuilder = 'Workflow Builder',
}

export interface ITracker {
  addSuperProperties(params: any);
  trackView(name: EventTypes | string, payload?: any, meta?: any);
  trackClick(name: TrackTypes | EventTypes | string, payload?: any, meta?: any);
  trackNavigation(name?: NavigationTypes| EventTypes | string, payload?: any, meta?: any);
}
