import React from 'react';
import { IAppearance } from 'models/Flow.model';
import Box from '@material-ui/core/Box';
import { TextFieldName } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { Quote } from 'lib/UI';

export function CustomCSS({ appearance, onChange }: {
  appearance: IAppearance,
  onChange: (appearance: IAppearance | ((current: IAppearance) => IAppearance)) => void;
}) {
  const formatMessage = useFormatMessage();

  return (
    <Box mt={1}>
      <Box mb={1}>
        {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.CustomCSS.title')}
      </Box>
      <TextFieldName
        type="text"
        size="small"
        multiline
        fullWidth
        value={appearance.stylesheet || ''}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...appearance,
            stylesheet: value,
          });
        }}
        placeholder=""
        rows={10}
      />
      <Box mt={2}>
        <Quote warning>
          {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.CustomCSS.warning.text', {
            messageValues: {
              prefix: <b>{formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.CustomCSS.warning.prefix')}</b>,
              link: <a href="https://docs.metamap.com/docs/sdk-customization" target="_blank" rel="noopener noreferrer">{formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.CustomCSS.warning.link')}</a>,
            },
          })}
        </Quote>
      </Box>
    </Box>
  );
}
