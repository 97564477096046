import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { cloneDeep } from 'lodash';
import { ProductSettingsProps, ProductTypes } from 'models/Product.model';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { CustomWatchlistSeverityOnMatchTypes, IFlowWatchlistList } from 'models/CustomWatchlist.model';
import { ExtendedDescription, HOCIsAccessAllowed } from 'apps/ui';
import { SelectDataSource } from 'apps/Product';
import { selectMerchantId, selectMerchantTags } from 'state/merchant/merchant.selectors';
import { DataSourceValueTypes } from 'models/Watchlist.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { CustomWatchlistItemSettings } from '../CustomWatchlistItemSettings/CustomWatchlistItemSettings';
import { selectCustomWatchlists } from '../../state/CustomWatchlist.selectors';
import { customWatchlistsClear, customWatchlistsLoad } from '../../state/CustomWatchlist.actions';
import { CustomWatchlistSettingsTypes, IFlowWatchlistUi } from '../../models/CustomWatchlist.model';
import FacialWatchlistsSettings from '../FacialWatchlistSettings/FacialWatchlistSettings';

export function CustomWatchlistSettings({ settings, onUpdate, disabled = false }: ProductSettingsProps<CustomWatchlistSettingsTypes>) {
  const formatMessage = useFormatMessage();
  const watchlists = useSelector(selectCustomWatchlists);
  const merchantId = useSelector(selectMerchantId);
  const dispatch = useDispatch();
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const canUseCustomWatchlistsBiometrics = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseCustomWatchlistsBiometrics)), [merchantTags]);

  const handleUpdateItem = useCallback((watchlist: Partial<IFlowWatchlistUi>) => {
    const newSettings = cloneDeep(settings);
    const settingsWatchlists: Partial<IFlowWatchlistUi>[] = newSettings[CustomWatchlistSettingsTypes.CustomWatchlists].value;
    const settingsWatchlistIndex = settingsWatchlists.findIndex((item) => item.id === watchlist.id);

    if (settingsWatchlistIndex >= 0) {
      settingsWatchlists[settingsWatchlistIndex] = watchlist;
    } else {
      settingsWatchlists.push(watchlist);
    }

    newSettings[CustomWatchlistSettingsTypes.CustomWatchlists].value = settingsWatchlists.filter((settingsWatchlist) => settingsWatchlist.severityOnMatch !== CustomWatchlistSeverityOnMatchTypes.NoAction);
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const flowAndCustomWatchlistsMerged: IFlowWatchlistUi[] = useMemo(() => {
    const flowWatchlists: IFlowWatchlistList[] = settings[CustomWatchlistSettingsTypes.CustomWatchlists].value;
    return watchlists.map((watchlist) => {
      const findedWatchlist = flowWatchlists.find((flowWatchlist) => flowWatchlist.id === watchlist.id);
      return {
        ...watchlist,
        ...findedWatchlist,
        severityOnMatch: findedWatchlist?.severityOnMatch ?? CustomWatchlistSeverityOnMatchTypes.NoAction,
      };
    });
  }, [settings, watchlists]);

  const isBiometricBlockAdded: boolean = useMemo(() => !settings[CustomWatchlistSettingsTypes.facialWatchList].isRequireOtherProduct, [settings]);

  const handleSelectDataSourceChange = useCallback((value: DataSourceValueTypes) => {
    const newSettings = cloneDeep(settings);
    newSettings[CustomWatchlistSettingsTypes.DataSource].value = value;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  useEffect(() => {
    dispatch(customWatchlistsLoad(merchantId));
    return () => {
      dispatch(customWatchlistsClear());
    };
  }, [merchantId, dispatch]);

  const meritIds = useMemo(() => [ProductTypes.DocumentVerification, ProductTypes.CustomField], []);

  return (
    <HOCIsAccessAllowed
      isAccessAllowed={!disabled}
      meritName={formatMessage('CustomWatchlist.card.title')}
    >
      <>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Box color="common.black75" mt={1}>
                <Typography variant="body1">
                  {formatMessage(
                    'CustomWatchlist.settings.addYourWatchlist.subtitle',
                  )}
                </Typography>
              </Box>
            </Box>
            <Box mb={2}>
              <ExtendedDescription
                title={formatMessage('Watchlist.dataSource.title')}
                text={formatMessage('Watchlist.dataSource.description')}
              >
                <SelectDataSource
                  meritIds={meritIds}
                  value={
                    settings[CustomWatchlistSettingsTypes.DataSource].value
                  }
                  onChange={handleSelectDataSourceChange}
                />
              </ExtendedDescription>
            </Box>
            <CustomWatchlistItemSettings
              watchlists={flowAndCustomWatchlistsMerged}
              onUpdate={handleUpdateItem}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        {canUseCustomWatchlistsBiometrics && (
          <FacialWatchlistsSettings
            isEnabled={isBiometricBlockAdded}
            settings={settings}
            onUpdate={onUpdate}
          />
        )}
      </>
    </HOCIsAccessAllowed>
  );
}
