import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { productManagerService } from 'apps/Product/index';
import { ProductTypes } from 'models/Product.model';
import { DataSourceValueTypes, dataSourceProductTypesValues } from 'models/Watchlist.model';
import { useFormatMessage } from 'apps/intl';
import { Select } from 'apps/ui';
import { selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';

export function SelectDataSource({ meritIds, value, onChange }: {
  meritIds: ProductTypes[];
  value: DataSourceValueTypes;
  onChange: (value: DataSourceValueTypes) => void;
}) {
  const formatMessage = useFormatMessage();
  const flowBuilderProductsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);

  useEffect(() => {
    if (flowBuilderProductsInGraph.some((productInGraph) => meritIds.includes(productInGraph)) && !flowBuilderProductsInGraph.includes(dataSourceProductTypesValues[value] as ProductTypes)) {
      onChange(DataSourceValueTypes[flowBuilderProductsInGraph.find((meritId) => meritIds.includes(meritId))] ?? DataSourceValueTypes.DocumentVerification);
    }
  }, [onChange, value, meritIds, flowBuilderProductsInGraph]);

  return (
    <Select variant="outlined" fullWidth value={value} onChange={(event) => onChange(event.target.value as DataSourceValueTypes)}>
      {productManagerService.products.map((merit) => meritIds.includes(merit.id) && (
        <MenuItem key={merit.id} value={DataSourceValueTypes[merit.id]} disabled={!flowBuilderProductsInGraph.includes(merit.id)}>
          {formatMessage(`${merit.id}.card.title`)}
        </MenuItem>
      ))}
    </Select>
  );
}
