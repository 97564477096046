import React from 'react';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { DigitalSignatureProvider } from 'models/DigitalSignature.model';
import { GDPRRangeTypes } from 'models/GDPR.model';
import { IFlow } from 'models/Flow.model';
import { FiCreditCard } from 'react-icons/fi';
import { CountrySpecificCreditChecks, StepStatus } from 'models/Step.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { toIsoPeriod } from 'lib/date';
import { CreditCheckSettings } from '../components/CreditCheckSettings/CreditCheckSettings';
import { CreditCheckSettingTypes, CreditChecksTypes, creditIsInVerification, creditCheckAssociatedMeritInFlow, verificationPatternsCreditChecksDefault, CreditCheckRequiredProductFromSubStep } from '../models/CreditCheck.model';
import { CreditCheckVerificationProduct } from '../components/CreditCheckVerificationProduct/CreditCheckVerificationProduct';

type ProductSettingsCreditCheck = ProductSettings<CreditCheckSettingTypes>;

export class CreditCheck extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.CreditCheck;
  inputs = [
    ProductInputTypes.NationalId,
  ];
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  order = 1000;
  checks = [
    {
      id: CreditChecksTypes.CreditDatabaseCheck,
      isActive: true,
    },
  ];

  icon = FiCreditCard;
  requiredProductTypeFromSubStep = CreditCheckRequiredProductFromSubStep;
  component = CreditCheckSettings;
  componentVerification = CreditCheckVerificationProduct;

  getVerification(verification: VerificationResponse): any {
    return verification?.documents || [];
  }

  onAdd(): Partial<IFlow> {
    return {};
  }

  isInFlow(flow: IFlow): boolean {
    return Object.entries(flow?.verificationPatterns).some(
      ([key, value]) => Object.prototype.hasOwnProperty.call(verificationPatternsCreditChecksDefault, key) && value,
    );
  }

  isInVerification(verification: VerificationResponse): boolean {
    return creditIsInVerification(verification);
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    return verification?.documents?.some((document) => {
      const steps = document?.steps || [];
      const creditChecksSteps = steps.filter((step) => CountrySpecificCreditChecks.includes(step.id));
      return creditChecksSteps.some((step) => step.checkStatus === StepStatus.Failure);
    });
  }

  getCreditCheckIssues(flow: IFlow, productsInGraph?: ProductTypes[]): Nullable<string> {
    const isCreditBureauNotSelected = productsInGraph && !this.isInFlow(flow);
    const isAssociatedMeritInFlow = creditCheckAssociatedMeritInFlow(flow, productsInGraph);

    if (isCreditBureauNotSelected) {
      return 'CreditCheck.issues.creditBureauNotSelected';
    }

    if (!isAssociatedMeritInFlow) {
      return 'CreditCheck.issues.associatedMeritNotAdded';
    }

    return null;
  }

  parser(flow: IFlow, productsInGraph: ProductTypes[]): ProductSettingsCreditCheck {
    return {
      [CreditCheckSettingTypes.CountriesCreditChecks]: {
        value: flow?.verificationPatterns,
        error: this.getCreditCheckIssues(flow, productsInGraph),
      },
    };
  }

  onRemove(): Partial<IFlow> {
    return {
      verificationPatterns: verificationPatternsCreditChecksDefault,
    };
  }

  serialize(settings: ProductSettingsCreditCheck, flow: IFlow): Partial<IFlow> {
    const newFlow = flow;
    const verificationPatterns = settings[CreditCheckSettingTypes.CountriesCreditChecks]?.value;
    if (verificationPatterns[VerificationPatternTypes.CreditMexicoCdc]) {
      newFlow.digitalSignature = DigitalSignatureProvider.NOM151;
      newFlow.policyInterval = newFlow.policyInterval ? toIsoPeriod(GDPRRangeTypes.Exact) : newFlow.policyInterval;
    } else {
      newFlow.digitalSignature = DigitalSignatureProvider.NONE;
    }
    return {
      ...newFlow,
      verificationPatterns,
    };
  }
}
