import React, { useEffect, useRef } from 'react';

interface IRefExtended {
  ref: HTMLElement;
  priority: number;
}

/**
 * Returns a <ScrollMeIntoView priority={number} /> component.
 * Use this component to indicate where should be the top of the scrollable view,
 * if some condition is true.
 * 0 - is the highest priority.
 * If there are two components with same priority,
 * browser will show one, which is below in DOM.
 */
export function useScrollMeIntoView(scrollIntoViewOptions: ScrollIntoViewOptions = { behavior: 'smooth' }): React.FC<{ priority?: number }> {
  const refs = useRef<IRefExtended[]>([]);

  useEffect(() => {
    refs.current.sort((refExtendedA, refExtendedB) => refExtendedB.priority - refExtendedA.priority)
      .some((refExtended) => {
        if (refExtended.ref) {
          refExtended.ref.scrollIntoView(scrollIntoViewOptions);
          return true;
        }
        return false;
      });
  }, [refs.current.length, scrollIntoViewOptions]);

  return function ScrollMeIntoView({ priority = 0 }: { priority?: number }) {
    return (
      <div ref={(ref) => refs.current.push({ ref, priority })} />
    );
  };
}
