import { useOverlay, Modal } from 'apps/overlay';
import React, { useCallback } from 'react';

export function useContactSales(): () => void {
  const [create, close] = useOverlay();

  const openForm = useCallback(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    try {
      create(
        <Modal style={{ minWidth: '50vw' }}>
          <div id="hubspot-contact-sales" />
        </Modal>,
      );

      const result = (window as any).hbspt.forms.create({
        portalId: '44602883',
        formId: '6b6d5b1f-d538-4b7a-a7e3-769bfb107bb8',
        target: '#hubspot-contact-sales',
        onFormSubmitted: () => close(),
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return openForm;
}
