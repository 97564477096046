import React, { useState } from 'react';
import { useSlate } from 'slate-react';
import { BsLink45Deg } from 'react-icons/bs';
import { isMarkActive, getMarkValue, MarkFormatTypes, toggleMark } from '../../models/TextEditor.model';
import { Button as EditorButton } from '../Button/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useStyles } from './LinkButton.style';

export const LinkButton = () => {
  const styles = useStyles();
  const editor = useSlate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link, setLink] = useState<Nullable<string>>(null);

  return (
    <>
      <div className={styles.container}>
        <EditorButton
          active={isMarkActive(editor, MarkFormatTypes.Link)}
          onMouseDown={(event) => {
            event.preventDefault();

            if (isMarkActive(editor, MarkFormatTypes.Link)) {
              const value = getMarkValue(editor, MarkFormatTypes.Link);
              setLink(value as string);
              setIsModalOpen(true);
            } else {
              setIsModalOpen(true);
            }
          }}
        >
          <BsLink45Deg fontSize={18} />
        </EditorButton>
        {isModalOpen && (
          <Box className={styles.modal}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <TextField
                  autoFocus
                  className={styles.input}
                  type="text"
                  variant="outlined"
                  value={link}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLink(event.target.value)}
                  placeholder="placeholder"
                />
              </Grid>
              <Grid item container direction="row-reverse">
                <Grid item>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      setIsModalOpen(false);
                      setLink(null);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      toggleMark(editor, MarkFormatTypes.Link, link);
                      setIsModalOpen(false);
                      setLink(null);
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </>
  );
};
