import { makeStyles } from '@material-ui/core';
import DocumentDisabled from '../../assets/icon-document-disabled.svg';

export const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: 20,
    [theme.breakpoints.up('xl')]: {
      marginBottom: 0,
      paddingRight: 20,
    },
    ...(theme.isDarkMode && ({
      [theme.breakpoints.up('lg')]: {
        maxWidth: '33.33%',
        flexBasis: '33.33%',
        marginBottom: 0,
        paddingRight: 20,
      },
    })),
    '&:last-of-type': {
      marginBottom: 0,
      paddingRight: 0,
    },
  },
  itemWrapper: {
    [theme.isDarkMode ? theme.breakpoints.up('lg') : theme.breakpoints.up('xl')]: {
      height: '100%',
      padding: [[20, 15]],
      borderRadius: 5,
      border: `1px solid ${theme.palette.foreground.main}`,
    },
  },
  title: {
    fontSize: 14,
    color: theme.palette.text.main,
  },
  subtitle: {
    marginTop: 6,
    fontSize: 12,
    color: theme.palette.text.main,
  },
  images: {
    height: '100%',
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.foreground.main,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
  image: {
    width: '100%',
    textAlign: 'center',
    [theme.isDarkMode ? theme.breakpoints.up('lg') : theme.breakpoints.up('xl')]: {
      '& > div': {
        maxHeight: 320,
      },
      '& img': {
        maxHeight: 320,
      },
    },
    '& img': {
      margin: [[0, 'auto']],
      objectFit: 'cover',
      borderRadius: 5,
    },
  },
  imagesVertical: {
    '& > *': {
      maxWidth: 'calc(50% - 10px)',
      margin: [[0, 20, 0, 0]],
      '&:last-of-type': {
        margin: 0,
      },
    },
    [theme.isDarkMode ? theme.breakpoints.up('lg') : theme.breakpoints.up('xl')]: {
      '& > *': {
        maxWidth: '100%',
        minWidth: '50%',
        margin: [[0, 0, 20]],
        '&:last-of-type': {
          margin: 0,
        },
        '& > div': {
          maxHeight: 500,
          minHeight: 300,
        },
        '& img': {
          maxHeight: 500,
          minHeight: 300,
        },
      },
    },
    [theme.isDarkMode ? theme.breakpoints.up('lg') : theme.breakpoints.up('xl')]: {
      '& > *': {
        maxWidth: 'calc(50% - 10px)',
        minWidth: 'auto',
        margin: [[0, 20, 0, 0]],
        '&:last-of-type': {
          margin: 0,
        },
      },
    },
  },
  imagesHorizontal: {
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: 20,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
  skeletonHorizontal: {
    minWidth: 200,
  },
  skeletonVertical: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 80,
    '& > div': {
      height: '100%',
    },
  },
  displayInline: {
    display: 'inline',
  },
  stepFailureReasonWrapper: {
    overflow: 'auto',
    wordBreak: 'break-word',
  },
  skipDocumentContainer: () => ({
    position: 'relative',
    margin: '0 auto 20px',
    padding: '30px 20px 0',
    textAlign: 'center',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: 22,
      height: 22,
      background: `url(${DocumentDisabled}) no-repeat center center`,
      transform: 'translateX(-50%)',
      [theme.breakpoints.up('md')]: {
        left: 0,
        transform: 'translateX(20%) translateY(35%)',
      },
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 0 20px',
      padding: '10px 30px',
      textAlign: 'left',
    },
    border: `1px solid ${theme.palette.foreground.main}`,
    borderRadius: 5,
  }),
  skipDocumentTitle: () => ({
    color: theme.palette.text.main,
  }),
}));
