import { useCallback } from 'react';
import { ProductTypes } from 'models/Product.model';
import { useConfirmOneAction } from 'apps/overlay';
import { useFormatMessage } from 'apps/intl';
import { productManagerService } from 'apps/Product';
import { useDispatch } from 'react-redux';
import { flowBuilderProductRemove } from '../store/FlowBuilder.action';
import { dagreGraphService } from '../services/dagreGraph.service';

export function useFlowBuilderCatchProduct(selectedProductId: ProductTypes): () => Promise<void> {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const titleTokenSelectedProduct = productManagerService.getProduct(selectedProductId)?.getTitle();
  const createOverlay = useConfirmOneAction(
    formatMessage('Product.error.internal.header'),
    formatMessage('Product.error.internal.description', { messageValues: { productName: formatMessage(titleTokenSelectedProduct) } }),
    formatMessage('Product.error.internal.button'),
  );

  const handleRemoveProduct = useCallback((productId: ProductTypes) => {
    dagreGraphService.getGraph().removeNode(productId);
    dispatch(flowBuilderProductRemove(productId));
  }, [dispatch]);

  return useCallback(async () => {
    try {
      await createOverlay();
    } finally {
      handleRemoveProduct(selectedProductId);
    }
  }, [createOverlay, handleRemoveProduct, selectedProductId]);
}
