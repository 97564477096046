import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { Slider, SliderProps } from '@material-ui/core';
import { useStyles } from './RangeSlider.styles';

interface IRangeSliderProps extends Omit<SliderProps, 'onChange'> {
  onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
  hiddenMarks?: number[];
  isShowOptimal?: boolean;
  textId?: {
    lowValue: string;
    middleValue?: string;
    maxValue: string;
  };
}

const defaultTextId = {
  lowValue: 'fuzzinessParameter.slider.StrictMatch',
  middleValue: 'fuzzinessParameter.slider.Optimal',
  maxValue: 'fuzzinessParameter.slider.FuzzyMatch',
};

export const RangeSlider = ({ step = 10, min = 0, max = 100, isShowOptimal = true, hiddenMarks = [], textId = defaultTextId, ...props }: IRangeSliderProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const { lowValue, middleValue, maxValue } = textId;

  const defaultMarks = useMemo(() => {
    const pointsCount = max / step;
    const result: { value: number; label?: React.ReactNode }[] = Array.from({ length: pointsCount + 1 }, (_: number, index: number) => ({ value: index * step }));
    result[0].label = (
      <>
        {!isShowOptimal && (<span className={classes.sliderPointValue}>{min}</span>)}
        <span className={classnames(classes.sliderPointText, classes.sliderPointFirst)}>{intl.formatMessage({ id: lowValue })}</span>
      </>
    );
    if (isShowOptimal) {
      result[Math.round(pointsCount / 2)].label = (
        <>
          <span className={classnames(classes.sliderPointText, classes.sliderPointMiddle)}>{intl.formatMessage({ id: middleValue })}</span>
        </>
      );
    }
    result[pointsCount].label = (
      <>
        {!isShowOptimal && (<span className={classes.sliderPointValue}>100</span>)}
        <span className={classnames(classes.sliderPointText, classes.sliderPointLast)}>{intl.formatMessage({ id: maxValue })}</span>
      </>
    );

    return result.map((mark, index) => ({ value: mark.value, label: !hiddenMarks.includes(index) && mark.label }));
  }, [max, step, classes.sliderPointValue, classes.sliderPointText, classes.sliderPointFirst, classes.sliderPointLast, classes.sliderPointMiddle, min, isShowOptimal, hiddenMarks, intl, lowValue, maxValue, middleValue]);

  return (
    <Slider
      valueLabelDisplay="auto"
      step={step}
      marks={defaultMarks}
      min={min}
      max={max}
      {...props}
    />
  );
};
