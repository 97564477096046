import React from 'react';
import Box from '@material-ui/core/Box';
import { IDocumetSubTypesWithTransalates } from 'models/Country.model';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import { Typography } from '@material-ui/core';
import { ICountriesSettings } from '../../models/DocumentVerification.model';
import { useStyles } from './SubTypeList.styles';

export function SubTypeList({ selectedSubTypes, setSelectedSubTypes, documentSubtypes, selectedCountry }: {
  selectedSubTypes: ICountriesSettings[];
  setSelectedSubTypes?: (value: ICountriesSettings[]) => void;
  documentSubtypes: IDocumetSubTypesWithTransalates[];
  selectedCountry: string;
}) {
  const currentSelectedSubTypes = selectedSubTypes.find(
    (data) => data.country === selectedCountry,
  );
  const classes = useStyles();
  const handleOnChange = (id: string, checked: boolean) => {
    if (checked) {
      let updatedCurrentSelectedSubTypes = { ...currentSelectedSubTypes };
      updatedCurrentSelectedSubTypes = { country: selectedCountry, documentSubtypes: [...updatedCurrentSelectedSubTypes.documentSubtypes, id] };
      const updatedValue = selectedSubTypes.map((data) => (data.country === selectedCountry
        ? updatedCurrentSelectedSubTypes
        : data));
      setSelectedSubTypes(updatedValue);
    } else {
      let updatedCurrentSelectedSubTypes = { ...currentSelectedSubTypes };
      const updatedSubTypes = updatedCurrentSelectedSubTypes.documentSubtypes.filter((item) => item !== id);
      updatedCurrentSelectedSubTypes = { country: updatedCurrentSelectedSubTypes.country, documentSubtypes: updatedSubTypes };
      const updatedValue = selectedSubTypes.map((data) => (data.country === selectedCountry
        ? updatedCurrentSelectedSubTypes
        : data));
      setSelectedSubTypes(updatedValue);
    }
  };
  return (
    <Box className={classes.root}>
      {documentSubtypes.map((data) => (
        <Box key={data.id} display="flex" justifyContent="space-between" alignItems="center">
          <Box color="common.black75">
            <Typography variant="body1" className={classes.subType}>
              {data.title}
            </Typography>
          </Box>
          <Checkbox
            color="primary"
            checked={currentSelectedSubTypes?.documentSubtypes?.includes(data.id)}
            checkedIcon={<CheckboxOn />}
            icon={<CheckboxOff />}
            onChange={(e) => handleOnChange(data.id, !!e.target.checked)}
          />
        </Box>
      ))}
    </Box>
  );
}
