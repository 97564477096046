import { http } from 'lib/client/http';
import { ClientMethodTypes } from 'models/Client.model';

export const downloadMedia = async (url: string, isMediaApi: boolean = true): Promise<Buffer> => {
  let targetUri = url;
  if (process.env.REACT_APP_MEDIA_PROXY) {
    targetUri = url.replace(process.env.REACT_APP_MEDIA_URL, process.env.REACT_APP_MEDIA_PROXY);
  }

  const headers = isMediaApi ? await http.getHeaders(ClientMethodTypes.GET) : {};
  const response = await fetch(targetUri, { headers, credentials: 'include' });
  const blob = await response.blob();
  const buff = await blob.arrayBuffer();
  return Buffer.from(buff);
};

export function startPdfGeneration(verificationId: string) {
  return http.get<string>('/dashboard-gateway/verification/generate-pdf', {
    params: {
      verificationId,
    },
  });
}
