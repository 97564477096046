import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.main,
  },
  summaryListItemValue: {
    fontWeight: 700,
    marginBottom: 5,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
}));
