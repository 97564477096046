import { PasswordExpirationPolicyDurationValue } from 'models/Settings.model';
import { DateString } from 'lib/date';
import { SupportedLocales } from 'models/Intl.model';
import { CollaboratorRoles, ICollaboratorWithID, UserId } from 'models/Collaborator.model';
import { ISubscriptionStatus, ISubscriptionStatusQuota } from 'apps/Permissions';

export const MERCHANT_MAIL = 'sales@metamap.com';

export type MerchantId = string;
export type ClientIdType = string;

export interface IApp {
  clientId: ClientIdType;
  clientSecret: string;
}
export type EmailString = string;

export interface ISenderEmail {
  verified: boolean;
  address: EmailString;
}

export interface IAgentNotesConfig {
  requiredOnChangeVerificationStatus: boolean;
}
export interface IMerchantSettings {
  agentNotesConfig: IAgentNotesConfig;
  senderEmails: ISenderEmail[];
  // TODO: @nikitaafanasyev add correct typing
  customDocumentConfig: unknown[];
}

interface IMerchantUser {
  userRole: CollaboratorRoles;
}

export interface IMerchant {
  logoUrl: string;
  id: MerchantId;
  blockedAt: Date;
  collaborators: ICollaboratorWithID[];
  createdAt: Date;
  displayName: string;
  businessName: string;
  owner: UserId;
  updatedAt: Date;
  tags: MerchantTagsTypes[];
  settings: IMerchantSettings;
  billing: IMerchantBilling;
  indexFields: IIndexFields;
  passwordExpirationPolicy: PasswordExpirationPolicyDurationValue;
  _email: ISenderEmail;
  status: string;
  deleted: boolean;
  firstName: string;
  lastName: string;
  admin: boolean;
  password: string;
  dateCreated: DateString;
  dateUpdated: DateString;
  locale: SupportedLocales;
  salt: string;
  brandName: string;
  legalName: string;
  legalAddress: string;
  legalRegNumber: string;
  subscriptionStatus: ISubscriptionStatus;
  quota: ISubscriptionStatusQuota;
  user: IMerchantUser;
  countryCodes: string[];
}

export enum MerchantTagsTypes {
  Test = 'test',
  Demo = 'demo',
  CanUseDashboardAutomaticLogout = 'can-use-dashboard-automatic-logout',
  CanUseCreditChecks = 'can-use-credit-checks',
  CanUseColombianCreditCheck = 'can-use-colombian-credit-check',
  CanUseBackgroundChecks = 'can-use-background-checks',
  CanUseLongPolling = 'can-use-long-polling',
  CanUseNom151 = 'can-use-nom-151',
  CanUseProofOfOwnership = 'can-use-proof-of-ownership',
  CanDisableFullstory = 'can-disable-fullstory',
  CanUseVerificationPostponedTimeout = 'can-use-verification-postponed-timeout',
  CanUseReverificationFlow = 'can-use-reverification-flow',
  CanUseCustomDocumentAdvancedValidation = 'can-use-custom-document-advanced-validations',
  CanUseCustomDocument = 'can-use-custom-document',
  CanUseCustomField = 'can-use-custom-fields',
  CanUseESignature = 'can-use-esignature',
  CanUsePhoneValidation = 'can-use-phone-validation',
  CanUseEmailValidation = 'can-use-email-validation',
  CanUseCustomWatchlists = 'can-use-custom-watchlists',
  CanUseCreditScore = 'can-use-credit-score',
  CanUseFinancialInformationBankAccountsRetrieving = 'can-use-financial-information-bank-accounts-retrieving',
  CanUseFacematchCPFInAr = 'can-use-facematch-in-ar-govchecks',
  CanUseFacematchCPFInBr = 'can-use-facematch-in-br-govchecks',
  CanUseAddSolutionToCatalog = 'can-add-solution-to-catalog',
  CanUseIndonesianKTP = 'can-use-indonesian-ktp',
  CanUseArRenaperExtended = 'can-use-ar-renaper-extended-govcheck',
  CanUseNigerianBNV = 'can-use-nigerian-bvn',
  CanUseNigerianCac = 'can-use-nigerian-cac',
  CanUseNigerianTin = 'can-use-nigerian-tin',
  CanUseNigerianDL = 'can-use-nigerian-dl',
  CanUseNigerianNIN = 'can-use-nigerian-nin',
  CanUseNigerianVIN = 'can-use-nigerian-vin',
  CanManageBasicWatchlists = 'can-manage-basic-watchlists',
  CanUseDuplicateFaceDetection = 'can-use-duplicate-face-detection',
  CanUseHighAccuracyLocationTracking = 'can-use-high-accuracy-location-tracking',
  CanUseSolutionTemplates = 'can-use-solution-templates',
  CanUsePremiumAmlWatchlistsSearch = 'can-use-premium-aml-watchlists-search',
  CanUsePremiumAmlWatchlistsSearchAndMonitoring = 'can-use-premium-aml-watchlists-search-and-monitoring',
  CanUseAnalyticsWidget = 'can-use-analytics-widget',
  CanUseBrazilianBackgroundFullChecks = 'can-use-brazilian-background-fullcheck',
  CanUseKenyanIprs = 'can-use-kenyan-iprs',
  CanUseKenyanBrs = 'can-use-kenyan-brs',
  CanNotUseHumanAlterationDetection = 'can-not-use-human-alteration-detection',
  CanNotUseDocumentReading='can-not-use-document-reading',
  CanNotUseHumanDocumentReading='can-not-use-human-document-reading',
  CanUseHumanDocumentReading='can-use-human-document-reading',
  CanUseReusage = 'can-use-reusage',
  CanUseVerificationReusage = 'can-use-verification-reusage',
  CanSeePremiumInsights = 'can-see-premium-insights',
  CanUseVideoAgreement = 'can-use-video-agreement',
  CanUseMyPlan = 'can-use-my-plan',
  CanUseActiveLivenss = 'can-use-active-liveness',
  CanUsePassiveLivenss = 'can-use-passive-liveness',
  CanHideDocumentSubTypes = 'can-hide-document-subtypes',
  CanNotUseIdentityValidation = 'can-not-use-identity-validation',
  CanNotUseLegalRecords = 'can-not-use-legal-records',
  CanNotUseDrivingLicenceValidation = 'can-not-use-driving-licence-validation',
  CanNotUseTaxChecks = 'can-not-use-tax-checks',
  CanNotUseBusinessRecordsChecks = 'can-not-use-business-records-checks',
  CanNotUseFaceMatch = 'can-not-use-face-match',
  CanNotUseSelfieVideo = 'can-not-use-selfie-video',
  CanUseOnlyFreemiumDocumentCountries = 'can-use-only-freemium-document-countries',
  CanUseAlterationDetectionFraudScore = 'can-use-alteration-detection-fraud-score',
  CanHideMetamapLogo = 'can-hide-metamap-logo',
  CanUseCustomCss = 'can-use-custom-css',
  ShouldUseFrontendVerificationPdfGeneration = 'should-use-frontend-verification-pdf-generation',
  CanSeeTTV = 'can-see-ttv',
  CanUseLegacyBackgroundChecks = 'can-use-legacy-background-checks',
  CanUseCustomWatchlistsBiometrics = 'can-use-custom-watchlists-biometrics',
  CanUseAutomationRulesUi = 'can-use-automation-rules-ui',
  CanUseProofOfAdress = 'can-use-proof-of-address',
  CanUseDigitalSpoofValidation = 'can-use-digital-spoof-validation',
  CanUseFaceEvasionValidation = 'can-use-face-evasion-validation',
  CanUseAgeCheckSelfie = 'can-use-age-check-selfie',
  CanUseSideMismatch = 'can-use-side-mismatch',
}

export interface IMerchantConfigurationDashboard {
  language: string;
}

export interface IMerchantConfiguration {
  dashboard: IMerchantConfigurationDashboard;
}

export interface IMerchantApp {
  clientId: string;
  clientSecret: string;
}

export interface IPlanDetails{
  plan: string;
  isInvoiced: boolean;
  activatedAt: DateString;
}

export interface IMerchantBilling{
  planDetails: IPlanDetails;
  providers: unknown;
}

export interface IIndexFields{
  emails: EmailString[];
  ownerEmail: EmailString;
  useCustomPlan: boolean;
}

export enum MerchantTagModeTypes {
  Sale = 'sale',
  Hide = 'hide',
}

export const SupportEmail = 'support@metamap.com';

export interface IHasVerificationsType {
  hasVerifications: boolean;
}
