/* eslint-disable @typescript-eslint/naming-convention */
// TODO: @ggrigorev remove deprecated mark once appPalette is replaced with theme.palette in all places
/**
 * @deprecated
 * In components and styles use theme.palette instead.
 * We want to add darkTheme in future.
 */
export enum appPalette {
  // new colors: the names are taken from Figma, in alphabetical order
  blue50 = '#DEEBFF',
  emerald = '#52d869',
  neutralLightN30 = '#EBECF0',
  neutralLightN500 = '#42526E',
  neutralLightN800 = '#172B4D',
  primary200 = '#DCE5FB',
  textYellow = '#E4A61B',
  // nameless colors from Figma, let's use https://www.htmlcsscolor.com/ in alphabetical order
  aliceBlue = '#F8FAFB',
  blackR = '#1a202c',
  cornSilk = '#FEFAE9',
  roseWhite = '#FDF0E8',
  serenade = '#FFEAD4',
  // old colors with random names
  blue = '#3757ff',
  gray = '#F9F9F9',
  grayopacity = 'rgba(0, 0, 0, 0.28)',
  athensgray = '#F9FAFB',
  athensgray2 = '#FCFCFD',
  slategray = '#6B7C93',
  gray68 = '#ADADAD',
  brightgray = '#F2F2F2',
  blueopacity = 'rgba(80, 125, 237, 1)',
  gray70 = '#EBEEFF',
  gray60 = '#F3F4F5',
  darkgrey = '#8392b8',
  lightblue = '#507DED',
  lightbluehover = '#608eed',
  lightblueopacity = 'rgba(237, 242, 253, .5)',
  darkBlue = 'rgb(56, 87, 165)',
  whiteblue = '#EDF2FD',
  tangerineapprox = '#DF9B01',
  white = '#ffffff',
  mediumgrayopacity = 'rgba(21, 20, 57, 0.4)',
  whiteopacity = 'rgba(255, 255, 255, 0.95)',
  white10 = 'rgba(255, 255, 255, 0.1)',
  white15 = 'rgba(255, 255, 255, 0.15)',
  black = '#000000',
  blackopacity = 'rgba(0, 0, 0, .15)',
  black7 = '#EDF0F5',
  black7opacity = 'rgba(237, 240, 245, .6)',
  black50 = '#CBD2E2',
  black60 = '#00000099',
  black75 = '#8392B8',
  black90 = '#232939',
  black90opacity = 'rgba(35, 41, 57, 0.9)',
  black90opacity2 = 'rgba(35, 41, 57, 0.12)',
  black1a = '#1a1a1a',
  black3a = '#3C3C3C',
  lightRed = '#FFE7E8',
  red = '#fe7581',
  redhover = '#fe8188',
  redorange = '#ff3636',
  brightorange = '#DF7F66',
  redopacity = 'rgba(255, 231, 232, .6)',
  lightgray = '#dcdcdc',
  palegray = '#FAFAFA',
  orange = '#f2994a',
  yellow = '#FEC649',
  yellow2 = '#FEB616',
  yellow3 = '#C99319',
  lightyellow = '#FFF4D5',
  lightyellow2 = '#FFF4D560',
  green = '#5ac794',
  green2 = '#48D493',
  greentransparent = '#E5F7EE',
  lightgreen = '#B2E6C5',
  brightgreen = '#89C39B',
  lightgrayopacity = 'rgba(0, 0, 0, 0.05)',
  simplegray = '#EEEEEE',
  silverchalice = '#AAAAAA',
  alto = '#DDDDDD',
  lightgrayopacity2 = 'rgba(0, 0, 0, 0.03)',
  purple70 = '#6929c4',
  cyan50 = '#1192e8',
  teal70 = '#005d5d',
  magenta70 = '#9f1853',
  red50 = '#fa4d56',
  red90 = '#570408',
  green60 = '#198038',
  blue80 = '#002d9c',
  magenta50 = '#ee538b',
  yellow50 = '#b28600',
  teal50 = '#009d9a',
  cyan90 = '#012749',
  orange70 = '#8a3800',
  purple50 = '#a56eff',
  chartSubtitle = '#666666',
  dodgerBlue = '#1890ff',
  summerSky = '#40a9ff',
  pink = '#f9487c;',
  brinkPink = '#FE7581',
  silverTree = '#5AC794',
  galliano = '#E4A61B',
  greySuit = '#C0C0CF',
  darkMediumBlue = '#4A5468',
  blackopacity2 = '#ECEEF3',
  greySuit2 = '#616F94',
  greySuit3 = '#9DA4B6',
  greySuit4 = '#EEEDFE',
  wfBase900 = '#1A202C',
  wfBase500 = '#A0ABC0',
  purple80 = '#5331DC',
  rosado = '#FD51A1',
}
