import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.lightyellow,
    borderRadius: 8,
    padding: 5,
  },
  grid: {
    height: '100%',
  },
  item: {
    marginRight: 15,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));
