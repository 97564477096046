import { makeStyles } from '@material-ui/core';

export const useNewSettingsLeaveConfirmModal = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    color: theme.palette.common.black,
    padding: 24,
  },
  description: {
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.common.black,
    padding: '0px 24px',
  },
  saveChangesButton: {
    width: 150,
    height: 40,
    padding: 12,
    gap: 12,
    borderRadius: 4,
    border: `1px solid ${theme.palette.common.lightblue}`,
    '& .MuiButton-label': {
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.common.lightblue,
    },
    '&:hover': {
      color: theme.palette.common.lightblue,
      border: `1px solid ${theme.palette.common.lightblue}`,
    },
    '&:focus': {
      color: theme.palette.common.lightblue,
      border: `1px solid ${theme.palette.common.lightblue}`,
    },
  },
  cancelChangesButton: {
    width: 150,
    height: 40,
    padding: 12,
    gap: 12,
    marginRight: 8,
    borderRadius: 4,
    border: `1px solid ${theme.palette.common.brinkPink}`,
    '& .MuiButton-label': {
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.common.brinkPink,
    },
    '&:hover': {
      color: theme.palette.common.brinkPink,
      border: `1px solid ${theme.palette.common.brinkPink}`,
    },
    '&:focus': {
      color: theme.palette.common.brinkPink,
      border: `1px solid ${theme.palette.common.brinkPink}`,
    },
  },
}));
