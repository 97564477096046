import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    '& + &': {
      marginTop: '16px',
    },
  },
  linkContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  link: {
    display: 'flex',
    marginRight: '-19px',
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
      marginRight: 0,
    },
  },
  highRisk: {
    color: theme.palette.common.red,
  },
  lowRisk: {
    color: theme.palette.common.yellow,
  },
}));
