import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { BlockedSplash } from 'apps/blocked';
import { PageLoader } from 'apps/layout';
import { selectIsBlockedModel, selectMerchantSubscriptionStatusValue } from 'state/merchant';
import { SubscriptionStatusTypes } from 'apps/SubscriptionStatus';

export function MerchantGuard({ children }) {
  const blockedModel = useSelector(selectIsBlockedModel);
  const subscriptionStatusValue = useSelector(selectMerchantSubscriptionStatusValue);

  if (!blockedModel.isLoaded) {
    return <PageLoader />;
  }

  if (blockedModel.value || subscriptionStatusValue === SubscriptionStatusTypes.Blocked) {
    return <BlockedSplash />;
  }

  return (
    <Switch>
      {children}
    </Switch>
  );
}
