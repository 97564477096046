import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  partialMatchedLabel: {
    color: theme.palette.common.yellow,
  },
  statusCell: {
    minWidth: '106px',
  },
}));
