import React, { DOMAttributes } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['mtm-icon']: DOMAttributes<HTMLElement> & {
        name: string;
      };
    }
  }
}

export function Icon({ name }: {
  name: string;
}) {
  return (
    <mtm-icon name={name} />
  );
}
