import Box from '@material-ui/core/Box';
import { useOtherProductAdding } from 'apps/Product';
import { useFormatMessage } from 'apps/intl';
import { BoxBordered, ExtendedDescription, notification } from 'apps/ui';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appPalette } from 'apps/theme';
import Button from '@material-ui/core/Button';
import { flowBuilderProductAdd } from 'apps/flowBuilder';
import { ProductSettings, ProductTypes } from 'models/Product.model';
import { CustomWatchlistSettingsTypes, IFlowWatchlistUi, defaultFaceMatchThreshold } from 'apps/CustomWatchlist/models/CustomWatchlist.model';
import { clearWatchlist } from 'apps/Aml/state/Aml.actions';
import { useOverlay } from 'apps/overlay';
import { CustomWatchlistSeverityOnMatchTypes, IFlowWatchlistList } from 'models/CustomWatchlist.model';
import { selectFacialWatchlists } from 'apps/CustomWatchlist/state/CustomWatchlist.selectors';
import { cloneDeep } from 'lodash';
import { FaceMatchingThreshold } from 'apps/facematch';
import { useStyles } from './FacialWatchlistSettings.styles';
import { FacialWatchlistModal } from '../FacialWatchlistModal/FacialWatchlistModal';
import { FacialWatchlistItemSettings } from '../FacialWatchlistItemSettings/FacialWatchlistItemSettings';

const FacialWatchlistsSettings = ({ isEnabled, settings, onUpdate }: {
isEnabled: boolean;
settings: ProductSettings<CustomWatchlistSettingsTypes>;
onUpdate: (settings: ProductSettings<CustomWatchlistSettingsTypes>) => void;
}) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const createAddOtherProductModalOverlay = useOtherProductAdding();
  const [createOverlay, closeOverlay] = useOverlay();
  const facialWatchlists = useSelector(selectFacialWatchlists);

  const handleAdd = useCallback((productType) => {
    dispatch(flowBuilderProductAdd(productType));
  }, [dispatch]);

  const handleAddBiometricVerification = useCallback(() => {
    createAddOtherProductModalOverlay(
      ProductTypes.BiometricVerification,
      <Box component="span">
        {formatMessage('CustomWatchlist.settings.facialWatchlists.addMeritBlock.description')}
      </Box>,
      handleAdd,
    );
  }, [createAddOtherProductModalOverlay, formatMessage, handleAdd]);

  const faceMatchScoreValue = useMemo(() => settings[CustomWatchlistSettingsTypes.faceMatchThreshold]?.value || defaultFaceMatchThreshold, [settings]);

  const handleSubmitWatchlist = useCallback(() => {
    notification.success(formatMessage('CustomWatchlist.settings.facialWatchlists.created'));
    closeOverlay();
  }, [closeOverlay, formatMessage]);

  const handleCloseOverlay = useCallback(() => {
    dispatch(clearWatchlist());
    closeOverlay();
  }, [dispatch, closeOverlay]);

  const handleOpenWatchlist = useCallback(() => () => {
    dispatch(clearWatchlist());
    createOverlay(
      <FacialWatchlistModal
        onClose={handleCloseOverlay}
        onSubmit={handleSubmitWatchlist}
      />,
      { onClose: handleCloseOverlay, sticky: true },
    );
  }, [dispatch, createOverlay, handleCloseOverlay, handleSubmitWatchlist]);

  const flowAndFacialWatchlistsMerged: IFlowWatchlistUi[] = useMemo(() => {
    const flowWatchlists: IFlowWatchlistList[] = settings[CustomWatchlistSettingsTypes.facialWatchList].value;
    return facialWatchlists.map((watchlist) => {
      const findedWatchlist = flowWatchlists.find((flowWatchlist) => flowWatchlist.id === watchlist.id);
      return {
        ...watchlist,
        ...findedWatchlist,
        severityOnMatch: findedWatchlist?.severityOnMatch ?? CustomWatchlistSeverityOnMatchTypes.NoAction,
      };
    });
  }, [settings, facialWatchlists]);

  const handleUpdateItem = useCallback((watchlist: Partial<IFlowWatchlistUi>) => {
    const newSettings = cloneDeep(settings);
    const settingsWatchlists: Partial<IFlowWatchlistUi>[] = newSettings[CustomWatchlistSettingsTypes.facialWatchList].value;
    const settingsWatchlistIndex = settingsWatchlists.findIndex((item) => item.id === watchlist.id);

    if (settingsWatchlistIndex >= 0) {
      settingsWatchlists[settingsWatchlistIndex] = watchlist;
    } else {
      settingsWatchlists.push(watchlist);
    }
    newSettings[CustomWatchlistSettingsTypes.facialWatchList].value = settingsWatchlists.filter((settingsWatchlist) => settingsWatchlist.severityOnMatch !== CustomWatchlistSeverityOnMatchTypes.NoAction);
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const updateFaceMatchThreshold = useCallback((value: unknown) => {
    const newSettings = cloneDeep(settings);
    newSettings[CustomWatchlistSettingsTypes.faceMatchThreshold].value = value;
    onUpdate(newSettings);
  }, [onUpdate, settings]);
  return (
    <>
      <Box mt={2}>
        {!isEnabled ? (
          <BoxBordered borderColor={appPalette.lightblue}>
            <Box mb={2}>
              <ExtendedDescription
                title={formatMessage('CustomWatchlist.settings.facialWatchlists.title')}
                text={formatMessage('CustomWatchlist.settings.facialWatchlists.subTitle')}
              />
            </Box>
            <Button onClick={handleAddBiometricVerification} className={classes.fullSizeButton} fullWidth variant="contained" color="primary">
              {formatMessage('DocumentVerification.settings.button.addBiometricProduct')}
            </Button>
          </BoxBordered>
        ) : (
          <Box className={classes.divider} p={1}>
            <Box mb={2}>
              <ExtendedDescription
                title={formatMessage('CustomWatchlist.settings.facialWatchlists.title')}
                text={formatMessage('CustomWatchlist.settings.facialWatchlists.subTitle')}
              />
            </Box>
            <Box mb={2}>
              <ExtendedDescription
                title={formatMessage('CustomWatchlist.settings.facialWatchlists.faceScoreCutOff')}
              >
                <FaceMatchingThreshold
                  facematchThreshold={faceMatchScoreValue}
                  defaultThreshold={defaultFaceMatchThreshold}
                  onUpdate={updateFaceMatchThreshold}
                />
              </ExtendedDescription>
            </Box>
            <FacialWatchlistItemSettings watchlists={flowAndFacialWatchlistsMerged} onUpdate={handleUpdateItem} addNewWatchList={handleOpenWatchlist} />
          </Box>
        )}
      </Box>

    </>
  );
};

export default FacialWatchlistsSettings;
