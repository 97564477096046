import { IReFacematchStep } from 'models/ReVerification.model';

export enum ReVerificationSettingTypes {
  Biometrics = 'biometricsVerification',
  ReFacematchThreshold = 'reFacematchThreshold',
  ProofOfOwnership = 'proofOfOwnership',
  BiometricsVerification = 'biometrics',
  BiometricsVerificationPattern = 'biometricsVerificationPattern',
}

export interface IReverificationVerification {
  reVerification: { reFacematch: IReFacematchStep };
  identity: string;
}

export enum ReVerificationTypes {
  SelfiePhoto = 'selfiePhoto',
  SelfieVideo = 'selfieVideo',
  LivenessNistPassive = 'liveness-nist-passive',
  LivenessNistActive = 'liveness-nist-active',
}

export enum ReVerificationChallengeTypes {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE'
}
