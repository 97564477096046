import { SanctionTypes } from 'apps/Aml/components/SanctionListModal/models/SanctionListModal.model';
import { DataSourceValueTypes } from './Watchlist.model';

export const DEFAULT_AML_FUZZINESS_THRESHOLD = 50;

export const premiumAmlWatchlistsInitialValue: IPremiumAmlWatchlists = {
  fuzzinessThreshold: DEFAULT_AML_FUZZINESS_THRESHOLD,
  countryCodes: [] as string[],
  exactMatch: false,
  dataSource: undefined,
  types: Object.keys(SanctionTypes),
};

export type BasicWatchlistIdType = number;

export const basicWatchlistsInitialValue: IFlowBasicWatchlists = {
  dataSource: undefined,
  list: [],
};

export interface IPremiumAmlWatchlists {
  fuzzinessThreshold: number;
  countryCodes: string[];
  exactMatch: boolean;
  entitySearch?: boolean;
  dataSource?: DataSourceValueTypes;
  types: string[];
}

export interface IFlowBasicWatchlists {
  list: BasicWatchlistIdType[];
  dataSource: DataSourceValueTypes;
}
