import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'lib/debounce.hook';
import Box from '@material-ui/core/Box';
import { selectMerchantId } from 'state/merchant/merchant.selectors';
import { useFormatMessage } from 'apps/intl';
import { IValidatedInputsFieldTypes, WatchlistValidatedInputsErrors, FakeInputs, ValidatedInputs, ValidatedInputsLoadingSkeleton } from 'apps/ui';
import { Loadable } from 'models/Loadable.model';
import { IWatchlistMapping, getWatchlistMapping, getWatchlistValidMapping } from 'models/Watchlist.model';
import { useStyles } from './CustomWatchlistMappingValidation.styles';
import { selectWatchlistsContentErrorType, selectCurrentCustomWatchlistMapping, selectCurrentCustomWatchlistHeaders, selectCurrentCustomWatchlistErrorsFormated, selectCurrentCustomWatchlistHeadersModel } from '../../state/CustomWatchlist.selectors';
import { CustomWatchlistModalValidationInputs } from '../../models/CustomWatchlist.model';
import { getCustomWatchlistShortValidation } from '../../state/CustomWatchlist.actions';

export function CustomWatchlistMappingValidation({ isSubmittingError, isEdit, hasOptions }:
  {
    isEdit: boolean;
    hasOptions: boolean;
    isSubmittingError: boolean;
  }) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const classes = useStyles();
  const merchantId = useSelector<any, string>(selectMerchantId);
  const watchlistsContentErrorType = useSelector<any, string>(selectWatchlistsContentErrorType);
  const currentWatchlistMapping = useSelector<any, IWatchlistMapping[] | null>(selectCurrentCustomWatchlistMapping);
  const currentCustomWatchlistHeaders = useSelector<any, string[] | null>(selectCurrentCustomWatchlistHeaders);
  const currentWatchlistErrors = useSelector<any, WatchlistValidatedInputsErrors>(selectCurrentCustomWatchlistErrorsFormated);
  const { isLoading: isCurrentCustomWatchlistHeadersLoading, error: currentCustomWatchlistHeadersErrorType } = useSelector<any, Loadable<string[]>>(selectCurrentCustomWatchlistHeadersModel);

  const debounced = useDebounce();
  const { setValue, getValues } = useFormContext();

  const watchlistMapping = useMemo(() => getWatchlistMapping(currentCustomWatchlistHeaders, currentWatchlistMapping), [currentCustomWatchlistHeaders, currentWatchlistMapping]);

  const watchlsitError = useMemo(() => {
    if (isSubmittingError) {
      return <Box className={classes.error}>{formatMessage('Watchlist.settings.modal.submit.error.default')}</Box>;
    }
    if (watchlistsContentErrorType) {
      return <Box className={classes.error}>{formatMessage(`Watchlist.settings.modal.submit.error.${watchlistsContentErrorType}`)}</Box>;
    }
    if (currentCustomWatchlistHeadersErrorType && !isCurrentCustomWatchlistHeadersLoading) {
      return <Box className={classes.error}>{formatMessage(`Watchlist.settings.headers.${currentCustomWatchlistHeadersErrorType}`)}</Box>;
    }

    return null;
  }, [classes, isSubmittingError, watchlistsContentErrorType, isCurrentCustomWatchlistHeadersLoading, currentCustomWatchlistHeadersErrorType, formatMessage]);

  const handleInputValidate = useCallback((mapping: IWatchlistMapping[]) => {
    const formValues = getValues();

    if ((formValues[CustomWatchlistModalValidationInputs.FileKey]) && formValues[CustomWatchlistModalValidationInputs.CsvSeparator] && !isEdit) {
      dispatch(getCustomWatchlistShortValidation(
        merchantId, {
          [CustomWatchlistModalValidationInputs.FileKey]: formValues[CustomWatchlistModalValidationInputs.FileKey],
          [CustomWatchlistModalValidationInputs.CsvSeparator]: formValues[CustomWatchlistModalValidationInputs.CsvSeparator],
          mapping,
        },
      ));
    }
  }, [merchantId, isEdit, getValues, dispatch]);

  const onValidatedInputsChange = useCallback((validatedInputsValues: IValidatedInputsFieldTypes[]) => {
    const validatedInputsValuesFormated = getWatchlistValidMapping(validatedInputsValues);

    setValue(CustomWatchlistModalValidationInputs.Mapping, validatedInputsValuesFormated);
    debounced(() => handleInputValidate(validatedInputsValuesFormated));
  }, [setValue, debounced, handleInputValidate]);

  return (
    <>
      {(isCurrentCustomWatchlistHeadersLoading && !isEdit) && <ValidatedInputsLoadingSkeleton />}
      {!isCurrentCustomWatchlistHeadersLoading && (
        ((watchlistMapping.length !== 0 && !currentCustomWatchlistHeadersErrorType) || isEdit) ? (
          <ValidatedInputs fieldValues={watchlistMapping} onChange={onValidatedInputsChange} disabled={isEdit} hasOptions={hasOptions} errors={currentWatchlistErrors} />
        ) : (
          <FakeInputs />
        )
      )}
      {watchlsitError}
    </>
  );
}
