import Button from '@material-ui/core/Button';
import { useFormatMessage } from 'apps/intl';
import React from 'react';
import { useStyles } from './GovCheckAddNew.styles';

export function GovCheckAddNew({ handleOpenModal }: {
  handleOpenModal: () => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const onClick = () => {
    handleOpenModal();
  };

  return (
    <Button className={classes.root} fullWidth variant="outlined" color="primary" onClick={onClick}>
      {formatMessage('GovCheckMerit.settings.button.selectGovCheck')}
    </Button>
  );
}
