import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  spoiler: {
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 10px 6px',
    cursor: 'pointer',
  },
  danger: {
    backgroundColor: theme.palette.common.lightRed,
    color: theme.palette.common.red,
  },
  warning: {
    backgroundColor: theme.palette.common.lightyellow,
    color: theme.palette.common.yellow,
  },
  default: {
    backgroundColor: theme.palette.common.black7opacity,
    color: theme.palette.common.black75,
  },
  statusIcons: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      display: 'flex',
    },
  },
  headerIcon: {},
  chevron: {
    marginLeft: '8px',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transform: 'rotateZ(0deg)',
  },
  chevronCollapsedIn: {
    transform: 'rotateZ(180deg)',
  },
  iconAlert: {},
}));
