import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  page: {
    width: '100%',
    height: '100%',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '32px',
  },
  pageHeaderTitle: {
    display: 'inline-block',
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '43.5px',
    color: theme.palette.common.blackR,
    marginLeft: '8px',
  },
  pageHeaderSubtitle: {
    display: 'inline-block',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.common.darkMediumBlue,
  },
  backArrow: {
    color: theme.palette.common.blackR,
    marginTop: '2px',
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24.2px',
    color: theme.palette.common.black90,
  },
  updatesList: {
    backgroundColor: theme.palette.common.white,
  },
  updatesHeader: {
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.common.lightgray}`,
  },
  updatesRowWrapper: {
    backgroundColor: theme.palette.common.white,
    '&:nth-child(even)': {
      backgroundColor: theme.palette.common.aliceBlue,
    },
  },
  updatesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '32px',
    paddingRight: '32px',
    minHeight: '32px',
    height: '100%',
  },
  updatesRowColumn: {
    minWidth: '50%',
    overflowWrap: 'break-word',
    display: 'flex',
    height: '100%',
  },
  userActionsTableHeaderText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    fontFamily: 'Inter, Lato',
    color: theme.palette.common.darkgrey,
  },
  avatarContainer: {
    borderRadius: '16px',
    width: '24px',
    height: '24px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timestampText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  triggeredByNameText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
  },
  triggeredByEmailText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.common.darkgrey,
  },
}));
