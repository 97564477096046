import cloneDeep from 'lodash/cloneDeep';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { useOtherProductAdding } from 'apps/Product';
import { ProductTypes } from 'models/Product.model';
import { useDispatch, useSelector } from 'react-redux';
import { flowBuilderProductAdd } from 'apps/flowBuilder/store/FlowBuilder.action';
import { useProductAddingMultiple } from 'apps/Product/hooks/ProductAddingMultiple.hook';
import clone from 'lodash/clone';
import { useFormatMessage } from 'apps/intl';
import { selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { IFlattenVerificationPatternConfig, toggleSubVerificationPattern, toggleVerificationPattern, VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { FacematchSourceSelect, IFacematchSourceSelectMenuItem } from './FacematchSourceSelect';
import { FacematchGovCheckSettings } from '../FacematchGovCheckSettings/FacematchGovCheckSettings';
import { IFacematchSourceDocumentOptions, IFacematchSource, FacematchCheckSettingsTypes, IFacematchSourceGovCheckOptions, meritDependencyOrder, requiredMeritsMapping, hasGovernmentCheckProductInGraph } from '../../models/Facematch.model';

export function FacematchSource({ patternsConfig, availableSources, sources, index, handleSourceChange, documentTypes }: {
  availableSources: FacematchSourceTypes[];
  sources: IFacematchSource[];
  index: number;
  handleSourceChange: (index: number, source: Partial<IFacematchSource>, setts?: { settingId: FacematchCheckSettingsTypes; value: unknown }[]) => void;
  documentTypes: string[][];
  patternsConfig: VerificationPatternsConfigType;
}) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const createAddOtherProductModalOverlay = useOtherProductAdding();
  const productsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);
  const addMultipleProducts = useProductAddingMultiple();

  const [filteredSources, setFilteredSources] = useState<IFacematchSourceSelectMenuItem[]>([]);

  const currentSourceType = useMemo<FacematchSourceTypes>(() => sources[index]?.type, [index, sources]);
  const currentDocumentType = useMemo<number>(() => (sources[index]?.options as IFacematchSourceDocumentOptions)?.verificationStepIndex, [index, sources]);

  const handleChangeGovCheck = useCallback((config: IFlattenVerificationPatternConfig, option: IFlattenVerificationPatternConfig) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newVerificationPatterns = option ? toggleSubVerificationPattern(checked, config, option) : toggleVerificationPattern(checked, config);
    let govCheckIds = cloneDeep((sources[index]?.options as IFacematchSourceGovCheckOptions)?.govCheckIds);
    if (checked) {
      govCheckIds.push(config.patternName);
    } else {
      govCheckIds = govCheckIds.filter((id) => id !== config.patternName);
    }

    const setts = [
      { settingId: FacematchCheckSettingsTypes.CountriesGovChecks, value: newVerificationPatterns },
    ];
    const options = { options: { govCheckIds } };

    if (!productsInGraph.includes(config.product)) {
      createAddOtherProductModalOverlay(
        config.product,
        null,
        () => {
          handleSourceChange(index, options, setts);
          dispatch(flowBuilderProductAdd(config.product));
        },
      );
      return;
    }

    handleSourceChange(index, options, setts);
  }, [createAddOtherProductModalOverlay, dispatch, handleSourceChange, index, productsInGraph, sources]);

  const handleChangeDocumentType = useCallback((event) => {
    handleSourceChange(index, { options: { verificationStepIndex: event.target.value } });
  }, [index, handleSourceChange]);

  const optionComponent = useMemo(() => {
    switch (currentSourceType) {
      case FacematchSourceTypes.Document: {
        return (
          <>
            <Box mb={0.5} fontWeight="bold" color="common.black90">{formatMessage('Facematch.settings.verificationSteps.title')}</Box>
            <FacematchSourceSelect
              onChange={handleChangeDocumentType}
              menuItems={documentTypes.map((types, i) => ({
                value: i,
                title: formatMessage('Facematch.settings.verificationStepsItem.title', {
                  messageValues: {
                    stepIndex: i + 1,
                    types: types.map((type) => formatMessage(`flow.documentTypeStep.${type}`)).join(' or '),
                  },
                }),
              }))}
              value={currentDocumentType}
              displayEmpty={false}
            />
          </>
        );
      }
      case FacematchSourceTypes.GovermentCheck: {
        return (
          <FacematchGovCheckSettings patternsConfig={patternsConfig} onChange={handleChangeGovCheck} />
        );
      }
      default: {
        return undefined;
      }
    }
  }, [currentDocumentType, currentSourceType, documentTypes, formatMessage, handleChangeDocumentType, handleChangeGovCheck, patternsConfig]);

  const checkProductsInGraph = useCallback((sourceType: FacematchSourceTypes): boolean => {
    switch (sourceType) {
      case FacematchSourceTypes.Document:
        return productsInGraph.includes(ProductTypes.DocumentVerification);
      case FacematchSourceTypes.Biometrics:
        return productsInGraph.includes(ProductTypes.BiometricVerification);
      case FacematchSourceTypes.GovermentCheck:
        return hasGovernmentCheckProductInGraph(productsInGraph);
      case FacematchSourceTypes.VideoAgreement:
        return productsInGraph.includes(ProductTypes.VideoAgreement);
      default:
        return true;
    }
  }, [productsInGraph]);

  const askAddMultipleProducts = useCallback((sourceType: FacematchSourceTypes) => {
    const onChangeProductsinGraph = (productType: ProductTypes) => {
      dispatch(flowBuilderProductAdd(productType));
      handleSourceChange(index, { type: sourceType });
    };

    const sourcesLength = Object.keys(sources).length;
    if (sourcesLength === 0) {
      switch (sourceType) {
        case FacematchSourceTypes.Biometrics: {
          addMultipleProducts(ProductTypes.BiometricVerification, onChangeProductsinGraph);
          break;
        }
        case FacematchSourceTypes.Document: {
          addMultipleProducts(ProductTypes.DocumentVerification, onChangeProductsinGraph);
          break;
        }
        case FacematchSourceTypes.GovermentCheck: {
          addMultipleProducts([ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], onChangeProductsinGraph);
          break;
        }
        case FacematchSourceTypes.VideoAgreement: {
          addMultipleProducts(ProductTypes.VideoAgreement, onChangeProductsinGraph);
          break;
        }
        default:
          break;
      }
    }

    if (sourcesLength === 1) {
      const newSources = clone(sources);
      newSources[index] = { type: sourceType };
      const nextIndex = index === 0 ? 1 : 0;
      const firstIndex = meritDependencyOrder.indexOf(newSources[nextIndex].type);
      const secondIndex = meritDependencyOrder.indexOf(sourceType);
      const meritsRequired = requiredMeritsMapping[firstIndex][secondIndex];

      addMultipleProducts(meritsRequired, onChangeProductsinGraph);
    }

    if (sourcesLength === 2) {
      const newSources = clone(sources);
      newSources[index] = { type: sourceType };
      const firstIndex = meritDependencyOrder.indexOf(newSources[0].type);
      const secondIndex = meritDependencyOrder.indexOf(newSources[1].type);
      const meritsRequired = requiredMeritsMapping[firstIndex][secondIndex];
      addMultipleProducts(meritsRequired, onChangeProductsinGraph);
    }
  }, [dispatch, index, sources, addMultipleProducts, handleSourceChange]);

  const handleChangeSourceType = useCallback((event) => {
    const sourceType = event.target.value;
    if (!checkProductsInGraph(sourceType)) {
      askAddMultipleProducts(sourceType);
      return;
    }
    handleSourceChange(index, { type: sourceType });
  }, [checkProductsInGraph, index, handleSourceChange, askAddMultipleProducts]);

  useEffect(() => {
    const sourcesInAnotherInputs = sources.filter((source, sourceIndex) => sourceIndex !== index)
      .map((source) => source.type);

    const res = availableSources.filter((availableSource) => !sourcesInAnotherInputs.includes(availableSource)).map((source) => ({ value: source, title: formatMessage(`Facematch.source.${source}.title`) }));

    setFilteredSources(res);
  }, [availableSources, sources, index, formatMessage]);

  return (
    <Box>
      <FacematchSourceSelect key="sourceType" onChange={handleChangeSourceType} menuItems={filteredSources} value={currentSourceType || ''} />
      {optionComponent}
    </Box>
  );
}
