import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderWidth: 1,
    borderColor: theme.palette.common.black7,
    borderRadius: 4,
    borderStyle: 'solid',
    height: '100%',
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
  },
  boxTitle: {
    color: theme.palette.common.black75,
    marginTop: 15,
    marginLeft: 20,
    marginBottom: 14,
  },
  statementItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 14,
    marginLeft: 24,
    marginRight: 24,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    '&:last-child': {
      marginBottom: 20,
    },
  },
  statementNameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 12,
    paddingTop: 6,
    paddingBottom: 9,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  statementName: {
    fontSize: 14,
    color: theme.palette.common.black90,
    marginLeft: 9,
    fontWeight: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  pdfIcon: {
    minWidth: 16,
  },
  statementModal: {
    width: 'inherit',
    overflowY: 'unset',
  },
  statementAction: {
    color: theme.palette.common.lightblue,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.lightblue,
    },
  },
  actions: {
    marginRight: 14,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:last-of-type:not(:only-of-type)': {
      marginRight: 0,
    },
  },
  actionsContainer: {
    minWidth: 160,
    maxWidth: 180,
  },
  previewUnavailableText: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.red,
    marginLeft: 8,
    marginRight: 8,
  },
  previewUnavailableIcon: {
    color: theme.palette.common.red,
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  tooltip: {
    textAlign: 'center',
  },
}));
