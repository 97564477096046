export function reloadPage() {
  window.location.reload();
}

/*
Since events are not bubled from iframe it poses a problem for detecting clicks
Because of that we have to emulate click when focus goes to iframe
TODO: reasearch less hacky way to deal with this @vadim.korelin @german.grigorev
*/
window.addEventListener('blur', () => {
  if (document.activeElement.tagName === 'IFRAME') {
    const newEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      clientX: 100,
      clientY: 10,
    });
    document.activeElement.dispatchEvent(newEvent);
  }
});
