import Box from '@material-ui/core/Box';
import React from 'react';
import classNames from 'classnames';
import { useFormatMessage } from 'apps/intl';
import { Warning } from 'apps/ui';
import { useMerchantPlan } from 'apps/merchant';
import { ButtonLink } from 'apps/ui/components/ButtonLink/ButtonLink';
import { CurlSnippet } from 'apps/forDevelopers/components/CurlSnippet/CurlSnippet';
import { urls, TabID } from 'models/Integration.model';
import { useStyles } from './IntegrationAPI.styles';

export function IntegrationAPI() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { isFreemium } = useMerchantPlan();

  return (
    <Box>
      {isFreemium && (
        <Box mb={2}>
          <Warning
            label={formatMessage('Freemium.productNotAvailable')}
            isLabelColored={false}
            meritName={formatMessage('amlCheck.name')}
            bordered
          />
        </Box>
      )}
      <Box
        mb={2}
        className={classNames({ [classes.grayedOut]: isFreemium })}
      >
        <ButtonLink
          url={urls[TabID.Api].documentationURL}
        >
          {formatMessage('forDevs.documentationBanner.button', {
            messageValues: { platform: 'API' },
          })}
        </ButtonLink>
      </Box>

      <Box
        mb={2}
        className={classNames({ [classes.grayedOut]: isFreemium })}
      >
        <CurlSnippet />
      </Box>
    </Box>
  );
}
