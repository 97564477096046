import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  chart: {
    minWidth: 'calc(50% - 10px)',
    position: 'relative',
  },
  chartTitle: {
    color: 'var(--mgi-theme-palette-darkgrey)',
    fontWeight: 600,
    fontSize: '21px',
  },
  chartSubTitle: {
    marginTop: '6px',
    color: 'var(--mgi-theme-palette-darkgrey)',
    fontWeight: 500,
    fontSize: '12px',
  },
  chartName: {
    color: 'var(--mgi-theme-palette-darkgrey)',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '16px',
  },
  charOrder: {
    color: 'var(--mgi-theme-palette-darkgrey)',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '12px',
  },
  exclamation: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
}));
