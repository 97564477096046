import React, { ComponentType, ComponentProps } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './BackgroundCheckStructureItem.styles';

export function BackgroundCheckStructureItem({
  title,
  detailsComponent: DetailsComponent,
  items,
}: {
  title: string;
  detailsComponent: ComponentType<unknown>;
  items: ComponentProps<typeof DetailsComponent>[];
}) {
  const classes = useStyles();
  return (
    <Paper className={classes.wrapper}>
      <Box p={2}>
        <Typography className={classes.count}>
          {title}
        </Typography>
        {items?.map((item, key) => <DetailsComponent key={key} {...item as {}} />)}
      </Box>
    </Paper>
  );
}
