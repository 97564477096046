import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@material-ui/core/MenuItem';
import { BoxBordered, ExtendedDescription, RadioButton, TextFieldName, HOCIsAccessAllowed, Select } from 'apps/ui';
import { useDebounce } from 'lib/debounce.hook';
import { validateMaxLength } from 'lib/validations';
import cloneDeep from 'lodash/cloneDeep';
import { ProductSettingsProps } from 'models/Product.model';
import React, { useCallback, useState } from 'react';
import { PhoneOwnershipValidationTypes } from 'models/PhoneCheck.model';
import { useFormatMessage } from 'apps/intl';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import classNames from 'classnames';
import {
  COMPANY_NAME_LENGTH_LIMIT,
  getDefaultRiskThresholdMode,
  getPhoneAnalyseStep,
  PhoneAnalyseStepTypes,
  PhoneCheckFAQ,
  PhoneCheckSettingTypes,
  PhoneRiskCheckMinVersionAndroid,
  PhoneRiskCheckMinVersionIOS,
  PhoneRiskThresholdModes,
  ScoreMapping,
  SDKUpdateLink,
} from '../../models/PhoneCheck.model';
import { useStyles } from './PhoneCheckSettings.styles';
import { SMSPreview } from '../SMSPreview/SMSPreview';

const phoneAnalyseSteps = Object.values(PhoneAnalyseStepTypes);

export function PhoneCheckSettings({
  settings,
  onUpdate,
  disabled,
}: ProductSettingsProps<PhoneCheckSettingTypes>) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const debounced = useDebounce();

  const [companyName, setCompanyName] = useState<string>(
    settings[PhoneCheckSettingTypes.CompanyName].value,
  );
  const [riskThresholdMode, setRiskThresholdMode] = useState<PhoneRiskThresholdModes>(
    getDefaultRiskThresholdMode(
      settings[PhoneCheckSettingTypes.PhoneRiskThreshold].value,
    ),
  );
  const [companyNameError, setCompanyNameError] = useState<string>('');

  const handleUpdate = useCallback(
    (settingId: PhoneCheckSettingTypes, value: unknown) => {
      const newSettings = cloneDeep(settings);
      newSettings[settingId].value = value;
      onUpdate(newSettings);
    },
    [onUpdate, settings],
  );

  const handlePhoneCheckOptionalChange = useCallback(
    ({ target: { checked } }) => {
      handleUpdate(PhoneCheckSettingTypes.IsPhoneCheckOptional, checked);
    },
    [handleUpdate],
  );

  const handlePhoneAnalysisChange = useCallback(
    (value: PhoneAnalyseStepTypes) => {
      const newSettings = cloneDeep(settings);
      switch (value) {
        case PhoneAnalyseStepTypes.Otp:
          newSettings.phoneOwnershipValidation.value = newSettings
            .isPhoneCheckOptional.value
            ? PhoneOwnershipValidationTypes.Sms
            : PhoneOwnershipValidationTypes.SmsOptional;
          newSettings.phoneRiskValidation.value = false;
          break;
        case PhoneAnalyseStepTypes.FilterByRisk:
          newSettings.phoneOwnershipValidation.value = newSettings
            .isPhoneCheckOptional.value
            ? PhoneOwnershipValidationTypes.SmsNotNeededOptional
            : PhoneOwnershipValidationTypes.SmsNotNeeded;
          newSettings.phoneRiskValidation.value = true;
          newSettings.companyName.value = undefined;
          break;

        case PhoneAnalyseStepTypes.OtpAndFilterByRisk:
          newSettings.phoneOwnershipValidation.value = newSettings
            .isPhoneCheckOptional.value
            ? PhoneOwnershipValidationTypes.Sms
            : PhoneOwnershipValidationTypes.SmsOptional;
          newSettings.phoneRiskValidation.value = true;
          break;
        default:
          newSettings.phoneOwnershipValidation.value = newSettings
            .isPhoneCheckOptional.value
            ? PhoneOwnershipValidationTypes.SmsNotNeededOptional
            : PhoneOwnershipValidationTypes.SmsNotNeeded;
          newSettings.phoneRiskValidation.value = false;
          newSettings.companyName.value = undefined;
          break;
      }
      setCompanyName(newSettings.companyName.value);
      onUpdate(newSettings);
    },
    [onUpdate, settings],
  );

  const handleCompanyNameChange = useCallback(
    ({ target: { value } }) => {
      const validationError = validateMaxLength(value, COMPANY_NAME_LENGTH_LIMIT);
      setCompanyNameError(validationError);
      setCompanyName(value);
      debounced(() => handleUpdate(PhoneCheckSettingTypes.CompanyName, value));
    },
    [handleUpdate, debounced],
  );

  const handlePhoneRiskModeChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setRiskThresholdMode(value as PhoneRiskThresholdModes);
      handleUpdate(
        PhoneCheckSettingTypes.PhoneRiskThreshold,
        ScoreMapping[value],
      );
    },
    [handleUpdate],
  );

  const phoneAnalysisStep = getPhoneAnalyseStep(
    settings.phoneOwnershipValidation.value,
    settings.phoneRiskValidation.value,
  );
  return (
    <HOCIsAccessAllowed
      isAccessAllowed={!disabled}
      meritName={formatMessage('PhoneCheck.card.title')}
    >
      <Box mt={2} mb={1}>
        <Typography className={classNames(classes.boldTitle, classes.paddingBottom)}>
          {formatMessage('PhoneCheck.settings.step1')}
        </Typography>

        <Grid container alignItems="baseline" wrap="nowrap" className={classes.paddingBottom}>
          <Grid item className={classes.alignLeft}>
            <RadioButton color="primary" checked disableRipple className={classes.alignLeft} />
          </Grid>
          <Grid item>
            <Box>
              <Typography className={classNames(classes.title, classes.textPaddingBottom)}>
                {formatMessage('PhoneCheck.settings.phoneAnalysis')}
              </Typography>
              <Typography className={classes.titleFaded}>
                {formatMessage('PhoneCheck.settings.phoneAnalysis.description')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="baseline" wrap="nowrap" className={classes.paddingBottom}>
          <Grid item className={classes.alignLeft}>
            <Checkbox
              color="primary"
              checkedIcon={<CheckboxOn />}
              icon={<CheckboxOff />}
              checked={settings.isPhoneCheckOptional.value}
              onChange={handlePhoneCheckOptionalChange}
              className={classes.alignLeft}
            />
          </Grid>
          <Grid item>
            <Box>
              <Typography className={classNames(classes.title, classes.hoverContainer)}>
                {formatMessage('PhoneCheck.settings.phoneCheckOptional')}
                <span>
                  <ExtendedDescription
                    title=""
                    info={formatMessage('PhoneCheck.settings.phoneCheckOptional.description')}
                  />
                </span>
              </Typography>

            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Box mt={2}>
          <Typography className={classNames(classes.boldTitle, classes.paddingBottom)}>
            {formatMessage('PhoneCheck.settings.step2')}
          </Typography>
        </Box>
        <Typography className={classNames(classes.title, classes.paddingBottom)}>
          {formatMessage('PhoneCheck.settings.select')}
        </Typography>
        <Box mt={0.5}>
          <Select
            variant="outlined"
            fullWidth
            value={phoneAnalysisStep}
            onChange={(event) => {
              if (event.target?.value) {
                handlePhoneAnalysisChange(
                  event.target.value as PhoneAnalyseStepTypes,
                );
              }
            }}
          >
            {phoneAnalyseSteps.map((step) => (
              <MenuItem key={step} value={step}>
                {formatMessage(`PhoneCheck.settings.phoneAnalysisStep.${step}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {(phoneAnalysisStep === PhoneAnalyseStepTypes.OtpAndFilterByRisk
          || phoneAnalysisStep === PhoneAnalyseStepTypes.FilterByRisk) && (
            <Box mt={1.5}>
              <Typography className={classNames(classes.titleFaded, classes.paddingBottom)}>
                {formatMessage('PhoneCheck.settings.filterByRisk.sdk', { messageValues: { minAndroidVersion: PhoneRiskCheckMinVersionAndroid, miniOSVersion: PhoneRiskCheckMinVersionIOS } })}
                <a className={classes.link} href={SDKUpdateLink} target="_blank" rel="noreferrer">
                  {formatMessage('PhoneCheck.settings.filterByRisk.sdkUpdate')}
                </a>
              </Typography>
              <Typography className={classNames(classes.titleFaded, classes.paddingBottom)}>
                {formatMessage('PhoneCheck.settings.filterByRisk.description')}
                <a className={classes.link} href={PhoneCheckFAQ} target="_blank" rel="noreferrer">
                  {formatMessage('PhoneCheck.settings.filterByRisk.riskFAQ')}
                </a>
              </Typography>
              <Typography className={classNames(classes.titleFaded, classes.textPaddingBottom)}>
                {formatMessage('PhoneCheck.settings.filterByRisk.title')}
              </Typography>
              <RadioGroup
                aria-label="risk-analysis-configuration"
                name="risk-analysis-configuration"
                value={riskThresholdMode}
                onChange={handlePhoneRiskModeChange}
              >
                {Object.values(PhoneRiskThresholdModes).map((mode) => (
                  <BoxBordered
                    key={mode}
                    mt={1}
                    width="100%"
                    className={classNames(classes.border, {
                      [classes.active]: riskThresholdMode === mode,
                    })}
                  >
                    <FormControlLabel
                      value={mode}
                      control={<RadioButton color="primary" />}
                      className={classes.riskAnalysis}
                      label={(
                        <>
                          <Box mb={0.5}>
                            <Typography className={classes.title}>
                              {formatMessage(
                                `PhoneCheck.settings.riskAnalysis.${mode}.title`,
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.titleFaded}>
                              {formatMessage(
                                `PhoneCheck.settings.riskAnalysis.${mode}.description`,
                              )}
                            </Typography>
                          </Box>
                        </>
                      )}
                    />
                  </BoxBordered>
                ))}
              </RadioGroup>
            </Box>
        )}
        {(phoneAnalysisStep === PhoneAnalyseStepTypes.Otp
          || phoneAnalysisStep === PhoneAnalyseStepTypes.OtpAndFilterByRisk) && (
            <Box mt={2}>
              <Typography className={classNames(classes.titleFaded, classes.paddingBottom)}>
                {formatMessage(
                  'PhoneCheck.settings.otpSettings',
                )}
              </Typography>
              <Typography className={classes.title}>
                {formatMessage(
                  'PhoneCheck.settings.companyName.title',
                )}
              </Typography>
              <TextFieldName
                type="text"
                value={companyName}
                onChange={handleCompanyNameChange}
                error={!!companyNameError}
                className={classes.companyName}
                helperText={
                  companyNameError
                  && formatMessage(
                    `PhoneCheck.settings.companyName.${companyNameError}`,
                  )
                }
              />
              <SMSPreview senderName={companyName} />
            </Box>
        )}
      </Box>
    </HOCIsAccessAllowed>
  );
}
