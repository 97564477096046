export function getRandomString<T = string>(stringsArray: T[]): T | undefined {
  let response;
  if (window?.crypto?.getRandomValues) {
    const cryptoArray = new Uint32Array(1);
    window.crypto.getRandomValues(cryptoArray);
    const randomIndex = cryptoArray[0] % stringsArray.length;
    response = stringsArray[randomIndex];
  } else {
    // Fallback to Math.random() for unsupported environments
    const randomIndex = Math.floor(Math.random() * stringsArray.length);
    response = stringsArray[randomIndex];
  }
  return response;
}

export enum SignUpVersionTypes {
  VersionA = 'versionA',
  VersionB = 'versionB',
}

export function getSignUpVarian(): SignUpVersionTypes | undefined {
  return window.localStorage?.getItem('freemiumPageVersion') as SignUpVersionTypes;
}

export function determineSignUpVarian(): SignUpVersionTypes | undefined {
  try {
    const variant = getSignUpVarian();
    if (!variant) {
      const randomVersion = getRandomString([SignUpVersionTypes.VersionA, SignUpVersionTypes.VersionB]);
      window.localStorage.setItem('freemiumPageVersion', randomVersion);
      return randomVersion;
    }
    return variant;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
