import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { FiPlusSquare } from 'react-icons/fi';
import type { MerchantTagsTypes } from 'models/Merchant.model';
import type { IFlow } from 'models/Flow.model';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductTypes } from 'models/Product.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import type { VerificationResponse } from 'models/VerificationOld.model';
import { InputTypes } from 'models/Input.model';
import { CustomFieldDataForVerificationComponent, flattenTree, customFieldExample, getDataForVerificationComponent } from 'models/CustomField.model';
import { DataSourceValueTypes } from 'models/Watchlist.model';
import { DocumentTypes } from 'models/Document.model';
import { CustomFieldProductSettings, CustomFieldSettingTypes, CustomFieldTypes } from '../models/CustomField.model';
import { CustomFieldSettings } from '../components/CustomFieldSettings/CustomFieldSettings';
import { CustomFieldVerification } from '../components/CustomFieldVerification/CustomFieldVerification';

export class CustomFieldService extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.CustomField;
  order = 75;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
  ];
  inputs = [
    ProductInputTypes.CustomDataEntry,
  ];
  checks = [
    {
      id: CustomFieldTypes.InputTypes,
      isActive: true,
    },
    {
      id: CustomFieldTypes.FormatValidation,
      isActive: true,
    },
    {
      id: CustomFieldTypes.MappingDocuments,
      isActive: true,
    },
  ];
  merchantTags: MerchantTagsTypes[] = [];
  dependentProductTypes = [
    ProductTypes.AmlCheck,
    ProductTypes.CustomWatchlist,
  ];

  icon = FiPlusSquare;
  component = CustomFieldSettings;
  componentVerification = CustomFieldVerification;

  isInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.CustomFieldsValidation] && !!flow?.customFieldsConfig?.fields?.length;
  }

  getVerification(verification: VerificationResponse): CustomFieldDataForVerificationComponent {
    return getDataForVerificationComponent(verification);
  }

  isInVerification(verification: VerificationResponse): boolean {
    return verification.inputs.some((i) => i.id === InputTypes.CustomFields);
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    const watchListsConfig = { ...flow.watchlists };
    const dataSource = this.hasDocumentVerificationInFlow(flow) ? DataSourceValueTypes.DocumentVerification : undefined;

    return {
      customFieldsConfig: { fields: [] },
      verificationPatterns: {
        [VerificationPatternTypes.CustomFieldsValidation]: false,
      },
      watchlists: {
        ...watchListsConfig,
        basic: {
          ...watchListsConfig.basic,
          dataSource,
        },
        premiumAml: {
          ...watchListsConfig.premiumAml,
          dataSource,
        },
        custom: {
          ...watchListsConfig.custom,
          dataSource,
        },
      },
    };
  }

  onAdd(): Partial<IFlow> {
    return {
      customFieldsConfig: {
        fields: [customFieldExample],
      },
      verificationPatterns: {
        [VerificationPatternTypes.CustomFieldsValidation]: true,
      },
    };
  }

  serialize(settings: CustomFieldProductSettings): Partial<IFlow> {
    const fields = settings[CustomFieldSettingTypes.Fields].value;
    return {
      customFieldsConfig: { fields },
      supportedCountries: settings[CustomFieldSettingTypes.CountryRestriction].value,
      verificationPatterns: {
        [VerificationPatternTypes.CustomFieldsValidation]: !!settings[CustomFieldSettingTypes.Fields].value.length,
      },
    };
  }

  parser(flow?: IFlow): CustomFieldProductSettings {
    const value = flow?.customFieldsConfig?.fields;
    return {
      [CustomFieldSettingTypes.Fields]: {
        value,
        error: value.length === 0 ? 'CustomField.issues.description' : null,
      },
      [CustomFieldSettingTypes.FlattenedFields]: {
        value: flattenTree(value),
      },
      [CustomFieldSettingTypes.CountryRestriction]: {
        value: flow?.supportedCountries || [],
      },
    };
  }

  haveIssues(flow: IFlow): boolean {
    return !flow.customFieldsConfig.fields.length;
  }

  hasDocumentVerificationInFlow(flow: IFlow): boolean {
    const allSteps = flow?.verificationSteps?.flatMap((step) => step) || [];
    const documents: string[] = Object.values(DocumentTypes)
      .map((item: DocumentTypes) => item as string);

    return allSteps.some((step) => documents.includes(step));
  }
}
