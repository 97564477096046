import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';
import cloneDeep from 'lodash/cloneDeep';
import * as api from 'lib/client/merchant';
import { verificationCustomFieldUpdate } from 'apps/Verification';
import { ICustomField, IMapping, ISelectOptions, IStaticTextParams } from 'models/CustomField.model';
import * as client from '../client/CustomField.client';
import { CustomFieldService } from '../services/CustomField.service';
import { types } from './CustomField.store';
import { CustomFieldModalTypes, EMPTY_CUSTOM_ATOMIC_FIELD, EMPTY_CUSTOM_GROUP_FIELD, EMPTY_CUSTOM_SELECT_FIELD } from '../models/CustomField.model';

export const customFieldInit = (registered: ProductTypes[] = []) => (_, getState): ProductTypes => {
  // don't register old version if PRS version is availible
  if (registered.includes(ProductTypes.CustomField)) {
    return null;
  }

  const customField = new CustomFieldService({
    disabled: !selectMerchantTags(getState()).includes(MerchantTagsTypes.CanUseCustomField),
  });
  productManagerService.register(customField);
  return customField.id;
};

export const updateCustomFieldModalStep = (data: CustomFieldModalTypes) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_MODAL_STEP_SUCCESS, payload: data, isReset: true });
};

export const updateCustomUploadingThumbnail = (data: boolean) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_UPLOADING_THUMBNAIL_SUCCESS, payload: data, isReset: true });
};

export const updateCustomFieldEditedField = (data: ICustomField) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_FIELD_SUCCESS, payload: data, isReset: true });
};

export const updateCustomFieldEditedStaticTextParams = (data: IStaticTextParams) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_STATIC_TEXT_PARAMS_SUCCESS, payload: data, isReset: true });
};

export const updateCustomFieldEditedMapping = (data: IMapping) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_MAPPING_SUCCESS, payload: data, isReset: true });
};

export const updateCustomFieldEditedSelectOptions = (data: ISelectOptions[]) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_SELECT_OPTIONS_SUCCESS, payload: data, isReset: true });
};

export const updateCustomFieldEditedDefaultValue = (data: string) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_DEFAULT_VALUE_SUCCESS, payload: data, isReset: true });
};

export const updateCustomFieldEditedThumbnail = (data: any) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_THUMBNAIL_SUCCESS, payload: data, isReset: true });
};

export const setCustomFieldEditedIndex = (data: number) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_INDEX_SUCCESS, payload: data, isReset: true });
};

export const setCustomFieldEditedSystemName = (data: string) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_SYSTEM_NAME_SUCCESS, payload: data, isReset: true });
};

export const setCustomFieldEditedParent = (data: string) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_EDITED_PARENT_SUCCESS, payload: data, isReset: true });
};

export const setCustomFieldFlattenListFields = (data: ICustomField[]) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_FLATTEN_LIST_FIELDS_SUCCESS, payload: data, isReset: true });
};

export const setCustomFieldListFields = (data: ICustomField[]) => (dispatch) => {
  dispatch({ type: types.CUSTOM_FIELD_LIST_FIELDS_SUCCESS, payload: data, isReset: true });
};

export const setEmptyField = (modalType: CustomFieldModalTypes) => (dispatch) => {
  switch (modalType) {
    case CustomFieldModalTypes.ConfigureField:
      dispatch(updateCustomFieldEditedField(cloneDeep(EMPTY_CUSTOM_ATOMIC_FIELD)));
      break;
    case CustomFieldModalTypes.ConfigureGroup:
    case CustomFieldModalTypes.ConfigureOption:
      dispatch(updateCustomFieldEditedField(cloneDeep(EMPTY_CUSTOM_GROUP_FIELD)));
      break;
    case CustomFieldModalTypes.ConfigureSelection:
      dispatch(updateCustomFieldEditedField(cloneDeep(EMPTY_CUSTOM_SELECT_FIELD)));
      break;
    default:
      break;
  }
  dispatch(setCustomFieldEditedSystemName(null));
};

export const prepareDataForModal = (modalType: CustomFieldModalTypes, editedField: ICustomField = null) => (dispatch) => {
  if (!editedField) {
    dispatch(setEmptyField(modalType));
  } else {
    dispatch(setCustomFieldEditedSystemName(editedField.name));
    dispatch(updateCustomFieldEditedField(editedField));
  }
  dispatch(updateCustomFieldModalStep(modalType));
};

export const uploadCustomFieldGroupThumbnail = (form: FormData) => async (dispatch) => {
  dispatch(updateCustomUploadingThumbnail(true));

  try {
    const { data } = await api.uploadMerchantMedia(form);
    dispatch(updateCustomFieldEditedThumbnail(data));
    dispatch(updateCustomUploadingThumbnail(false));
  } catch (error) {
    dispatch(updateCustomUploadingThumbnail(false));
    throw error;
  }
};

export const customFieldStartUpdate = (verificationId: string, fields: ICustomField[], country: string) => async (dispatch) => {
  const { data: { customFieldsDataCopy } } = await client.customFieldUpdate(verificationId, fields, country);
  dispatch(verificationCustomFieldUpdate(customFieldsDataCopy));
};
