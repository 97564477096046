import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { overlayClose } from 'apps/overlay';
import { isNil } from 'lib/isNil';
import { useFormatMessage } from 'apps/intl';
import { KeyboardKeys } from 'models/Keyboard.model';
import { AtomicCustomFieldTypes, ICustomField } from 'models/CustomField.model';
import { mutableFindChildren, HandleUpdateFields, prepareCustomField, atomicFieldIsValid, MAX_TEXT_INPUT_LENGTH, FIELD_WITH_SELECTION_OPTION, FIELD_WITH_HINT, IDefaultValues, FIELD_CAN_BE_MANDATORY } from '../../models/CustomField.model';
import { CustomFieldCheckboxSetting } from './components/CustomFieldCheckboxSetting/CustomFieldCheckboxSetting';
import { CustomFieldDisplayNameSetting } from './components/CustomFieldDisplayNameSetting/CustomFieldDisplayNameSetting';
import { CustomFieldHintSetting } from './components/CustomFieldHintSetting/CustomFieldHintSetting';
import { CustomFieldMandatorySetting } from './components/CustomFieldMandatorySetting/CustomFieldMandatorySetting';
import { CustomFieldStaticTextSettings } from './components/CustomFieldStaticTextSettings/CustomFieldStaticTextSettings';
import { CustomFieldSystemNameSetting } from './components/CustomFieldSystemNameSetting/CustomFieldSystemNameSetting';
import { CustomFieldTextSetting } from './components/CustomFieldTextSetting/CustomFieldTextSetting';
import { CustomFieldModalFooter } from '../CustomFieldModalFooter/CustomFieldModalFooter';
import { selectCustomFieldEditedField, selectCustomFieldEditedIndex, selectCustomFieldEditedParent, selectCustomFieldEditedSystemName, selectCustomFieldFlattenListFields, selectCustomFieldListFields } from '../../state/CustomField.selectors';
import { CustomFieldSelectionOptions } from './components/CustomFieldSelectionOptions/CustomFieldSelectionOptions';
import { CustomFieldTypeChanger } from './components/CustomFieldTypeChanger/CustomFieldTypeChanger';
import { updateCustomFieldEditedField } from '../../state/CustomField.actions';

export function CustomFieldModalConfigureAtomic({ handleUpdateFields }: {
  handleUpdateFields: HandleUpdateFields;
}) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const selectedCustomField = useSelector<any, ICustomField>(selectCustomFieldEditedField);
  const listFields = useSelector<any, ICustomField[]>(selectCustomFieldListFields);
  const listFlattenFields = useSelector<any, ICustomField[]>(selectCustomFieldFlattenListFields);
  const editedParent = useSelector<any, string>(selectCustomFieldEditedParent);
  const editedIndex = useSelector<any, number>(selectCustomFieldEditedIndex);
  const oldSystemName = useSelector<any, string>(selectCustomFieldEditedSystemName);

  const [defaults, setDefaults] = useState<IDefaultValues>({
    index: (selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.Select && selectedCustomField?.atomicFieldParams?.defaultValue) ? selectedCustomField?.atomicFieldParams?.selectOptions?.findIndex((option) => option.value === selectedCustomField?.atomicFieldParams?.defaultValue) : null,
    text: selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.Text ? selectedCustomField?.atomicFieldParams?.defaultValue as string : '',
    checked: selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.Checkbox ? selectedCustomField?.atomicFieldParams?.defaultValue as boolean : false,
  });
  const [isShowDefaultText, setIsShowDefaultText] = useState<boolean>(!!selectedCustomField?.atomicFieldParams?.defaultValue);
  const [isAddHeading, setIsAddHeading] = useState<boolean>(!!selectedCustomField.atomicFieldParams?.staticTextParams?.header);

  const handleAtomicFieldParamsChange = ({ target: { value, name } }) => {
    const clone = cloneDeep(selectedCustomField);
    clone.atomicFieldParams[name] = value;
    dispatch(updateCustomFieldEditedField(clone));
  };

  const handleKeyDown = (event) => {
    if (![KeyboardKeys.ArrowRight, KeyboardKeys.ArrowLeft, KeyboardKeys.Delete, KeyboardKeys.Backspace].includes(event.key) && event.target.value.length > MAX_TEXT_INPUT_LENGTH) {
      event.preventDefault();
    }
  };

  const onForward = useCallback(() => {
    const copy = cloneDeep(listFields);
    const preparedCustomField = prepareCustomField(selectedCustomField, defaults);
    const child = mutableFindChildren(copy, editedParent);
    if (isNil(editedIndex)) {
      child.push(preparedCustomField);
    } else {
      child[editedIndex] = preparedCustomField;
    }
    handleUpdateFields(copy);
    dispatch(overlayClose());
  }, [listFields, selectedCustomField, defaults, editedParent, editedIndex, handleUpdateFields, dispatch]);

  const isValid: boolean = atomicFieldIsValid(selectedCustomField, listFlattenFields, oldSystemName);
  const onBack = useCallback(() => dispatch(overlayClose()), [dispatch]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h3">
            {formatMessage('CustomField.settings.modal.configureField.title')}
          </Typography>
        </Grid>
        <CustomFieldDisplayNameSetting handleKeyDown={handleKeyDown} />
        <CustomFieldSystemNameSetting />
        <Grid item container direction="column">
          <hr />
        </Grid>
        <CustomFieldTypeChanger />
        {FIELD_CAN_BE_MANDATORY.includes(selectedCustomField.atomicFieldParams.type) && (
          <CustomFieldMandatorySetting />
        )}
        {selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.StaticText && (
          <CustomFieldStaticTextSettings isAddHeading={isAddHeading} setIsAddHeading={setIsAddHeading} />
        )}
        {selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.Checkbox && (
          <CustomFieldCheckboxSetting setDefaults={setDefaults} checked={defaults.checked} />
        )}
        {(!selectedCustomField.atomicFieldParams.type || FIELD_WITH_HINT.includes(selectedCustomField.atomicFieldParams.type)) && (
          <CustomFieldHintSetting handleKeyDown={handleKeyDown} handleAtomicFieldParamsChange={handleAtomicFieldParamsChange} />
        )}
        {(!selectedCustomField.atomicFieldParams.type || selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.Text) && (
          <CustomFieldTextSetting text={defaults.text} handleAtomicFieldParamsChange={handleAtomicFieldParamsChange} isShowDefaultText={isShowDefaultText} setIsShowDefaultText={setIsShowDefaultText} setDefaults={setDefaults} />
        )}
        {FIELD_WITH_SELECTION_OPTION.includes(selectedCustomField.atomicFieldParams.type) && (
          <CustomFieldSelectionOptions indexOfDefault={defaults.index} setDefaults={setDefaults} />
        )}
      </Grid>
      <CustomFieldModalFooter
        backTitle="Back"
        forwardTitle="Done"
        onForward={onForward}
        canMoveForward={isValid}
        onBack={onBack}
      />
    </>
  );
}
