export enum AppDarkPalette {
  blue = '#3757ff',
  gray = '#F9F9F9',
  verydarkgray = '#515151',
  gray68 = '#ADADAD',
  lightblue = '#507DED',
  lightbluehover = '#608eed',
  lightblueopacity = 'rgba(237, 242, 253, .5)',
  lightskyblue = '#D9F7F8',
  whiteblue = '#EDF2FD',
  white = '#ffffff',
  whiteopacity = 'rgba(255, 255, 255, 0.95)',
  black = '#000000',
  black7 = '#EDF0F5',
  black7opacity = 'rgba(237, 240, 245, .1)',
  black50 = '#CBD2E2',
  black75 = '#8392B8',
  black90 = '#232939',
  black90opacity = 'rgba(35, 41, 57, 0.9)',
  red = '#fe7581',
  redhover = '#fe8188',
  redorange = '#ff3636',
  redopacity = 'rgba(255, 231, 232, .6)',
  lightgray = '#dcdcdc',
  orange = '#f2994a',
  yellow = '#FEC649',
  lightyellow = '#FFF4D560',
  green = '#5ac794',
}
