import { createSelector } from 'reselect';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';
import { Loadable } from 'models/Loadable.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ValidatedInputsKeys, IValidationErrorFormated } from 'apps/ui';
import { IWatchlistValidationError, IWatchlistProcessPartial, IWatchlist, WatchlistProcessStatusTypes, IWatchlistMapping, getWatchlistErrorsFormated, IWatchlistContent, WatchlistTypes } from 'models/Watchlist.model';
import { CustomWatchlistsStore, CUSTOM_WATCHLISTS_STORE_KEY } from './CustomWatchlist.store';

export const selectCustomWatchlistsStore = (state: {CUSTOM_WATCHLISTS_STORE_KEY: CustomWatchlistsStore}): CustomWatchlistsStore => state[CUSTOM_WATCHLISTS_STORE_KEY];

export const selectWatchlists = createSelector<[typeof selectCustomWatchlistsStore], IWatchlist[]>(
  selectCustomWatchlistsStore,
  (store) => store.watchlists.value,
);

export const selectWatchlistsContentErrorType = createSelector<[typeof selectCustomWatchlistsStore], string>(
  selectCustomWatchlistsStore,
  (store) => store.watchlistContent.error,
);

export const selectCurrentCustomWatchlist = createSelector<[typeof selectCustomWatchlistsStore], Nullable<IWatchlist>>(
  selectCustomWatchlistsStore,
  (store) => store.currentWatchlist.value,
);

export const selectWatchlistsModel = createSelector<[typeof selectCustomWatchlistsStore], Loadable<IWatchlist[]>>(
  selectCustomWatchlistsStore,
  (store) => store.watchlists,
);

export const selectCurrentCustomWatchlistModel = createSelector<[typeof selectCustomWatchlistsStore], Loadable<Nullable<IWatchlist>>>(
  selectCustomWatchlistsStore,
  (store) => store.currentWatchlist,
);

export const selectIsWatchlistsContentModel = createSelector<[typeof selectCustomWatchlistsStore], Loadable<IWatchlistContent>>(
  selectCustomWatchlistsStore,
  (store) => store.watchlistContent,
);

export const selectCanUseCustomWatchlists = createSelector<[typeof selectMerchantTags], boolean>(
  selectMerchantTags,
  (tags) => tags.includes(MerchantTagsTypes.CanUseCustomWatchlists),
);

export const selectCurrentCustomWatchlistId = createSelector<[typeof selectCurrentCustomWatchlist], number>(
  selectCurrentCustomWatchlist,
  (currentWatchlist) => currentWatchlist?.id ?? null,
);

export const selectCurrentCustomWatchlistProcess = createSelector<[typeof selectCurrentCustomWatchlist], IWatchlistProcessPartial>(
  selectCurrentCustomWatchlist,
  (currentWatchlist) => currentWatchlist?.process,
);

export const selectCurrentCustomWatchlistStatus = createSelector<[typeof selectCurrentCustomWatchlistProcess], Nullable<WatchlistProcessStatusTypes>>(
  selectCurrentCustomWatchlistProcess,
  (currentWatchlistProcess) => currentWatchlistProcess?.status ?? null,
);

export const selectCurrentCustomWatchlistError = createSelector<[typeof selectCurrentCustomWatchlistProcess], Nullable<IWatchlistValidationError[]>>(
  selectCurrentCustomWatchlistProcess,
  (currentWatchlistProcess) => currentWatchlistProcess?.error,
);

export const selectCurrentCustomWatchlistFileInfo = createSelector<[typeof selectCurrentCustomWatchlistProcess], Partial<{ fileKey: string; fileName: string }>>(
  selectCurrentCustomWatchlistProcess,
  (currentWatchlistProcess) => ({
    fileKey: currentWatchlistProcess?.inputSourceFileKey,
    fileName: currentWatchlistProcess?.inputSourceFileName,
  }),
);

export const selectCurrentCustomWatchlistMapping = createSelector<[typeof selectCurrentCustomWatchlist], Nullable<IWatchlistMapping[]>>(
  selectCurrentCustomWatchlist,
  (currentWatchlist) => currentWatchlist?.mapping,
);

export const selectCurrentCustomWatchlistFileError = createSelector<[typeof selectCustomWatchlistsStore], string>(
  selectCustomWatchlistsStore,
  (store) => store.fileErrorType,
);

export const selectCurrentCustomWatchlistErrorsFormated = createSelector<[typeof selectCurrentCustomWatchlistError], Partial<Nullable<Record<ValidatedInputsKeys, IValidationErrorFormated[]>>>>(
  selectCurrentCustomWatchlistError,
  (errors) => getWatchlistErrorsFormated(errors),
);

export const selectCurrentCustomWatchlistHeadersModel = createSelector<[typeof selectCustomWatchlistsStore], Loadable<Nullable<string[]>>>(
  selectCustomWatchlistsStore,
  (store) => store.currentWatchlistHeaders,
);

export const selectCurrentCustomWatchlistHeaders = createSelector<[typeof selectCustomWatchlistsStore], Nullable<string[]>>(
  selectCustomWatchlistsStore,
  (store) => store.currentWatchlistHeaders.value,
);

export const selectCurrentCustomWatchlistIsFileAvailable = createSelector<[typeof selectCurrentCustomWatchlist], boolean>(
  selectCurrentCustomWatchlist,
  (store) => store?.isFileAvailable ?? true,
);

export const selectCustomWatchlists = createSelector<[typeof selectCustomWatchlistsStore], IWatchlist[]>(
  selectCustomWatchlistsStore,
  (store) => store.watchlists.value.filter((watchlist) => watchlist.watchlistType === WatchlistTypes.Custom),
);

export const selectFacialWatchlists = createSelector<[typeof selectCustomWatchlistsStore], IWatchlist[]>(
  selectCustomWatchlistsStore,
  (store) => store.watchlists.value.filter((watchlist) => watchlist.watchlistType === WatchlistTypes.CustomWatchlistBiometrics),
);
