import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRole, PermissionAccessTypes, PermissionsTypes } from 'lib/Auth0';
import { appLoad } from 'state/merchant/merchant.actions';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { selectClientIdModel } from 'state/merchant/merchant.selectors';

export const useApps = () => {
  const dispatch = useDispatch();
  const { checkPermission } = useRole();
  const clientIdModel = useSelector(selectClientIdModel);

  useEffect(() => {
    const loadData = async () => {
      if (LoadableAdapter.isPristine(clientIdModel)) {
        try {
          await dispatch(appLoad());
        } catch (error) {
          console.error(error);
        }
      }
    };
    if (checkPermission(PermissionsTypes.integrationSettings, PermissionAccessTypes.Read) || checkPermission(PermissionsTypes.flows, PermissionAccessTypes.Read)) {
      loadData();
    }
  }, [clientIdModel]);
};
