import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import React from 'react';
import { AppTheme } from 'apps/theme';
import { riskScoreLimits, RiskScoreTypes } from 'models/RiskModel';
import { IGaugeChartParams, GaugeChartTypes } from '../../models/GaugeChart.model';
import { useStyles } from './GaugeChart.styles';

export function GaugeChart({ value, type, progressColor = AppTheme.palette.chartCategory.brinkPink, className }: IGaugeChartParams) {
  const getProgressBarColor = () => {
    if ((value > riskScoreLimits[RiskScoreTypes.NO_RISK]) && (value <= riskScoreLimits[RiskScoreTypes.WARNING])) {
      return AppTheme.palette.common.galliano;
    }

    if (value > riskScoreLimits[RiskScoreTypes.WARNING]) {
      return AppTheme.palette.common.brinkPink;
    }

    return AppTheme.palette.common.silverTree;
  };

  const classes = useStyles({ value, type, progressColor: type === GaugeChartTypes.RiskAssessment ? getProgressBarColor() : progressColor });

  return (
    <Box className={classnames(classes.root, className)}>
      <Box className={classes.gauge}>
        <Box className={classes.percentage} />
      </Box>
      <Box className={classes.value} component="span">{value}</Box>
    </Box>
  );
}
