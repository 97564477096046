import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const useStyles = makeStyles((theme) => ({
  colorGrey: {
    color: theme.palette.text.main,
  },
  reportTitle: {
    fontWeight: 'bold',
  },
  reportSubTitle: {
    maxWidth: 376,
  },
  ultraLargeButton: {
    padding: '16px 65px',
    fontSize: '14px',
  },
}));

export const StyledButton = withStyles(() => ({
  root: {
    marginRight: 16,
  },
}))(Button);
