import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { IFlow } from 'models/Flow.model';
import { IMeritBlocksAdditional, useLoadMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { Loadable } from 'models/Loadable.model';
import { useFormatMessage } from 'apps/intl';
import { useFreemium } from 'apps/Freemiun';
import { useMerchantPlan } from 'apps/merchant';
import { isSdkOnly, Product, ProductIntegrationTypes, ProductSettingsProps, ProductTypes } from 'models/Product.model';
import { NavigationTypes, useTrackNavigation } from 'apps/Tracking';
import { ProductAdditionalDetails } from '../ProductAdditionalDetails/ProductAdditionalDetails';
import { ProductSettingsDetails } from '../ProductSettingsDetails/ProductSettingsDetails';
import { ProductTitleWithData } from '../ProductTitleWithData/ProductTitleWithData';
import { productManagerService } from '../../services/ProductManager.service';
import { useProductFormatMessage } from '../../hooks/useProductFormatMessage';
import { useStyles } from './ProductSettingsWithData.styles';
import { ReactComponent as ActivateIcon } from '../../../../assets/icon-activate.svg';
import { FreshChatWidget } from '../../../freshdesk/service/FreshDesk.service';

export function ProductSettingsWithData({ flow, productId, onUpdate }: {
  flow: IFlow;
  productId: ProductTypes;
  onUpdate: (patch: Partial<IFlow>) => void;
}) {
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraph);
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  const product: Product<IFlow> = useMemo(() => productManagerService.getProduct(productId), [productId]);
  const settings = useMemo(() => product.parser(flow, productsInGraph), [product, flow, productsInGraph]);
  const card = product.getCard();
  const productFormatMessage = useProductFormatMessage(card);
  const { comparePlans } = useFreemium();
  const { isFreemium } = useMerchantPlan();
  const error = isSdkOnly(product.integrationTypes) && (flow as IFlow).integrationType === ProductIntegrationTypes.Api
    ? 'FlowBuilder.issue.sdkOnly'
    : null;

  useTrackNavigation(NavigationTypes.Workflow, {
    workflowId: flow?.id,
    workflowName: flow?.name,
    workflowType: flow?.integrationType,
  });

  const handleUpdate = useCallback((newSettings: any) => {
    const newFlow = product.serialize(newSettings, flow);
    onUpdate(newFlow);
  }, [onUpdate, product, flow]);

  const settingsEl = useMemo(() => React.createElement<ProductSettingsProps>(product.component, {
    settings,
    onUpdate: handleUpdate,
    disabled: product.disabled,
  }), [product, settings, handleUpdate]);

  const onClickActivateMerit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFreemium) {
      comparePlans();
    } else {
      FreshChatWidget.createNewMessage(formatMessage('Intercom.activate.merit.message', {
        messageValues: { name: card.isExternal ? productFormatMessage('title') : formatMessage(card.title) },
      }));
    }
  };

  const meritBlocksAdditionalModel: Loadable<IMeritBlocksAdditional> = useLoadMeritBlocksAdditional();
  const meritBlocksAdditional: IMeritBlocksAdditional = meritBlocksAdditionalModel.value || {};

  if (!product.component) {
    return null;
  }

  return (
    <Box>
      <Box mb={2}>
        <ProductTitleWithData card={card} error={error} meritBlocksAdditional={meritBlocksAdditional} isSdkOnly={isSdkOnly(product.integrationTypes)} />
      </Box>
      <Box>
        <ProductSettingsDetails
          summary={formatMessage('FlowBuilder.products.howItWorks')}
          details={(
            <Box mt={1}>
              <ProductAdditionalDetails card={card} meritBlocksAdditional={meritBlocksAdditional} />
            </Box>
          )}
        />
        <ProductSettingsDetails
          summary={formatMessage('FlowBuilder.products.blockSettings')}
          details={(
            <Box ml="6px" mt={1}>
              {settingsEl}
            </Box>
          )}
          defaultExpanded
        />
        {card.disabled && (
          <Box>
            <Button
              className={classes.settingsButton}
              variant="outlined"
              disableElevation
              onClick={(e) => onClickActivateMerit(e)}
            >
              <ActivateIcon />
              {formatMessage('FlowBuilder.integration.activateThisBlock')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
