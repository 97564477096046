import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '17px 31px 0 26px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.black7,
      borderRadius: 10,
    },
  },
  navigationBar: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 9px 27px 9px',
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.common.black90,
    '& > *': {
      marginRight: 14,
    },
  },
  returnIcon: {
    position: 'relative',
    padding: 0,
    left: -15.5,
    margin: '0 !important',
    minHeight: '0 !important',
    color: theme.palette.common.black90,
    fontSize: '16px !important',
  },
  header: {
    margin: '0 5px 27px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: 8,
    color: theme.palette.common.black,
    fontSize: 36,
    lineHeight: '44px',
  },
  description: {
    color: theme.palette.common.black90,
    fontSize: 16,
    lineHeight: '19px',
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  buttonSelect: {
    marginBottom: 12,
    padding: '12px 20px 12px 15px',
    boxSizing: 'border-box',
    maxWidth: 'fit-content',
    alignItems: 'center',
    borderRadius: '6px',
    color: theme.palette.common.white,
    background: theme.palette.common.lightblue,
    whiteSpace: 'nowrap',
    fontSize: '16px !important',
    lineHeight: '24px',
    fontWeight: 700,
    '&:hover, &:focus': {
      color: theme.palette.common.white,
      background: theme.palette.common.lightbluehover,
    },
    '& svg': {
      fontSize: 16,
      marginRight: 11,
    },
  },
  content: {
    display: 'flex',
  },
}));
