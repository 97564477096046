import { selectFlowBuilderChangeableFlow, selectFlowBuilderProductsInGraph } from 'apps/flowBuilder/store/FlowBuilder.selectors';
import { IProductCard, ProductTypes } from 'models/Product.model';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UIProductCard } from '../UIProductCard/UIProductCard';
import { productManagerService } from '../../services/ProductManager.service';

export function ProductCard({ id, isIssues = false, isControls = false, isExpandable = true, defaultExpanded = false, isActive = false, onSelect, onRemove, onModalProductSelect }: {
  id: ProductTypes;
  isIssues?: boolean;
  isControls?: boolean;
  isExpandable?: boolean;
  defaultExpanded?: boolean;
  isActive?: boolean;
  onSelect?: (id: ProductTypes) => void;
  onRemove?: (id: ProductTypes) => void;
  onModalProductSelect?: (id: ProductTypes) => void;
}) {
  const flow = useSelector(selectFlowBuilderChangeableFlow);
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraph);
  const product = productManagerService.getProduct(id);
  const card: IProductCard = product.getCard();

  const handleOpen = useCallback(() => {
    if (isControls) onSelect(card.id);
    if (onModalProductSelect) {
      onModalProductSelect(card.id);
    }
  }, [onSelect, isControls, card.id, onModalProductSelect]);

  const handleRemove = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const error = useMemo(() => (isIssues && product.getIssuesComponent(flow, productsInGraph)), [flow, productsInGraph, isIssues, product]);
  return (
    <UIProductCard
      card={card}
      error={error}
      disabled={product.disabled}
      isControls={isControls}
      isExpandable={isExpandable}
      defaultExpanded={defaultExpanded}
      onOpen={handleOpen}
      onRemove={handleRemove}
      isActive={isActive}
    />
  );
}
