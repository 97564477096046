import React from 'react';
import { useFormatMessage } from 'apps/intl';
import type { StructureSection } from 'models/BackgroundCheck.model';
import { BackgroundCheckStructureItem } from './components/BackgroundCheckStructureItem/BackgroundCheckStructureItem';

export function BackgroundCheckStructure({ sections }: { sections: StructureSection[] }) {
  const formatMessage = useFormatMessage();

  return (
    <>
      {sections.map(({
        titleKey,
        component,
        items,
      }) => (items.length ? (
        <BackgroundCheckStructureItem
          key={titleKey}
          title={formatMessage(titleKey, { messageValues: { count: items.length } })}
          items={items}
          detailsComponent={component}
        />
      ) : null))}
    </>
  );
}
