import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
}));
