import React from 'react';
import Box from '@material-ui/core/Box';
import { useStyles } from './ErrorMessage.styles';

export const ErrorMessage = ({ error }: { error: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.labelError}>
      {error}
    </Box>
  );
};
