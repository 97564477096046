import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    border: '1px solid',
    boxShadow: 'none',
    [theme.breakpoints.down(1120)]: {
      overflow: 'visible',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  retriesBlocked: {
    color: theme.palette.common.red,
    borderColor: theme.palette.common.red,
  },
  retriesUnBlocked: {
    color: theme.palette.common.green,
    borderColor: theme.palette.common.green,
  },
  label: {
    [theme.breakpoints.down(1120)]: {
      paddingLeft: 5,
    },
  },
}));
