import React from 'react';
import { useRole, PermissionAccessTypes, PermissionsTypes } from 'lib/Auth0';

export function RoleRenderGuard({ permission, access, children = null }: {
  permission: PermissionsTypes;
  access: PermissionAccessTypes;
  children: React.ReactNode;
}) {
  const { checkPermission } = useRole();

  if (!checkPermission(permission, access)) {
    return null;
  }

  return (<>{children}</>);
}
