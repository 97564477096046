import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.common.black7}`,
    borderRadius: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    flexBasis: 81,
    height: 88,
    padding: '12px 12px 4px',
    fontSize: 12,
    color: theme.palette.common.darkgrey,
    textAlign: 'center',
  },
  cardCheck: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: -6,
    right: -6,
    fill: theme.palette.common.green,
  },
  warning: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: -6,
    right: -6,
    fill: theme.palette.common.yellow,
  },
}));
