import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100%',
  },
  sidebar: {
    position: 'sticky',
    top: 0,
    padding: '36px 24px',
    color: theme.palette.common.black90,
    width: 348,
    minWidth: 348,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  description: {
    padding: '0 12px',
    marginBottom: 32,
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '29px',
    marginBottom: 8,
  },
  modalSubtitle: {
    color: theme.palette.common.black90,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
  },
  content: {
    width: '100%',
    padding: '0 0 18px 12px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      margin: '20px 0',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.black7,
      borderRadius: 10,
    },
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '36px 28px 42px 0',
  },
  headerRight: {
    display: 'flex',
    gap: 10,
  },
  headerFilters: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    border: `1px solid ${theme.palette.common.whiteblue}`,
    padding: 8,
    gap: 8,
  },
  headerFilterLabel: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    fontWeight: 700,
    color: theme.palette.common.lightblue,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    whiteSpace: 'nowrap',
    color: theme.palette.common.black75,
    border: `1px solid ${theme.palette.common.black50}`,
    borderRadius: 99,
    fontWeight: 500,
    fontSize: '12px !important',
    lineHeight: '15px',
    cursor: 'pointer',
    '& svg': {
      minWidth: 13,
      minHeight: 13,
      maxWidth: 13,
      maxHeight: 13,
      color: theme.palette.common.lightblue,
      marginRight: 4,
    },
    '&:hover': {
      color: theme.palette.common.black90,
    },
  },
  filterValue: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
    marginLeft: 5,
    borderLeft: `1px solid ${theme.palette.common.black50}`,
    color: theme.palette.common.lightblue,
    '& svg': {
      color: theme.palette.common.black75,
      marginLeft: 4,
      marginRight: 0,
    },
  },
  filterOptions: {
    maxHeight: 552,
    width: '100%',
    minWidth: 'fit-content',
    right: 0,
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    padding: 16,
    marginTop: '3%',
    zIndex: 200,
  },
  countriesOptions: {
    minWidth: 380,
  },
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  },
}));
