import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { selectVerification, selectVerificationStepsExtra } from 'apps/Verification';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { IStep, StepErrorsToHide, StepIds } from 'models/Step.model';
import { IBackgroundCheckStepData, StepExtraBrazil, StepExtraLatam, StepExtraMexicanBuholegal } from 'models/BackgroundCheck.model';
import { getPostResultPhase, IdentityStatuses, VerificationStatusChangeReason } from 'models/Status.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { BackgroundChecksBrazilianChecks } from '../BackgroundChecksBrazilianChecks/BackgroundChecksBrazilianChecks';
import { BackgroundChecksMexicanBuholegal } from '../BackgroundChecksMexicanBuholegal/BackgroundChecksMexicanBuholegal';
import { BackgroundChecksLatam } from '../BackgroundChecksLatam/BackgroundChecksLatam';
import { BackgroundCheckShowManualButton } from '../BackgroundCheckShowManualButton/BackgroundCheckShowManualButton';

const getCountriesFromDocuments = (documents: {country: string}[]): string[] => documents.map(({ country }) => country);

const getStep = (stepType: VerificationPatternTypes, verificationStepsExtra?: IStep<IBackgroundCheckStepData>[]): IStep<IBackgroundCheckStepData> | undefined => verificationStepsExtra?.find(({ id, error }) => id === stepType && !StepErrorsToHide[error?.code]);

export function BackgroundCheckVerificationProduct() {
  const verificationStepsExtra = useSelector(selectVerificationStepsExtra);
  const { verificationStatusDetails, _id: verificationId, documents } = useSelector(selectVerification);
  const { value: verificationStatus, reasonCode } = verificationStatusDetails;
  const isPostResultPhase = getPostResultPhase(verificationStatus);
  const isVerified = verificationStatus === IdentityStatuses.verified && reasonCode !== VerificationStatusChangeReason.ManualChange;
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const canUseLegacyBackgroundChecks = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseLegacyBackgroundChecks)), [merchantTags]);
  const shouldShowManualButtons = isPostResultPhase && !isVerified && !canUseLegacyBackgroundChecks;
  const mexicanBuholegalStep = useMemo(() => getStep(VerificationPatternTypes.BackgroundMexicanBuholegal, verificationStepsExtra), [verificationStepsExtra]);
  const brazilianChecksStep = useMemo(() => getStep(VerificationPatternTypes.BackgroundBrazilianChecks, verificationStepsExtra), [verificationStepsExtra]);
  const latinAmericaChecksStep = useMemo(() => getStep(VerificationPatternTypes.BackgroundLatinAmericaChecks, verificationStepsExtra), [verificationStepsExtra]);

  const getAvailableManualIds = (): StepIds[] => {
    const backgroundCheckSteps = [mexicanBuholegalStep, brazilianChecksStep, latinAmericaChecksStep];

    return backgroundCheckSteps.filter((step) => {
      if (!step) {
        return false;
      }
      const { data, startedManuallyAt } = step;
      return !data && !startedManuallyAt;
    }).map(({ id }) => id);
  };

  return (
    <Grid>
      {shouldShowManualButtons && (
        <BackgroundCheckShowManualButton
          stepIds={getAvailableManualIds()}
          verificationId={verificationId}
          documentsCountries={getCountriesFromDocuments(documents)}
        />
      )}
      {mexicanBuholegalStep && (
        <Box mt={2}><BackgroundChecksMexicanBuholegal step={mexicanBuholegalStep as IStep<IBackgroundCheckStepData<StepExtraMexicanBuholegal>>} /></Box>
      )}
      {brazilianChecksStep && (
        <Box mt={2}><BackgroundChecksBrazilianChecks step={brazilianChecksStep as IStep<IBackgroundCheckStepData<StepExtraBrazil>>} /></Box>
      )}
      {latinAmericaChecksStep && (
        <Box mt={2}><BackgroundChecksLatam step={latinAmericaChecksStep as IStep<IBackgroundCheckStepData<StepExtraLatam>>} /></Box>
      )}
    </Grid>
  );
}
