import { isNil } from 'lib/isNil';
import { validateEmpty, validateMaxLength } from 'lib/validations';
import { IFlow } from 'models/Flow.model';
import { PhoneOwnershipValidationTypes } from 'models/PhoneCheck.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export const COMPANY_NAME_LENGTH_LIMIT = 30;

export enum PhoneCheckCheckTypes {
  PhoneValidation = 'phoneValidation',
  RiskAnalysis = 'riskAnalysis',
}

export enum PhoneCheckSettingTypes {
  PhoneOwnershipValidation = 'phoneOwnershipValidation',
  CompanyName = 'companyName',
  PhoneRiskValidation = 'phoneRiskValidation',
  PhoneRiskThreshold = 'phoneRiskThreshold',
  IsPhoneCheckOptional = 'isPhoneCheckOptional'
}

export enum PhoneRiskThresholdModes {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum PhoneRiskPredefinedThreshold {
  Min = 0,
  Low = 40,
  Default = 60,
  High = 80,
  Max = 100
}

export const ScoreMapping = {
  [PhoneRiskThresholdModes.Low]: PhoneRiskPredefinedThreshold.Low,
  [PhoneRiskThresholdModes.Medium]: PhoneRiskPredefinedThreshold.Default,
  [PhoneRiskThresholdModes.High]: PhoneRiskPredefinedThreshold.High,
};

export const RiskScoreFallback = {
  [PhoneRiskPredefinedThreshold.Low]: PhoneRiskThresholdModes.Low,
  [PhoneRiskPredefinedThreshold.Default]: PhoneRiskThresholdModes.Medium,
  [PhoneRiskPredefinedThreshold.High]: PhoneRiskThresholdModes.High,
};

export enum PhoneCheckErrorTypes {
  Empty = 'empty',
  OutOfRange = 'outOfRange',
}

export const validateRiskThreshold = (score: number): string | null => {
  if (score < PhoneRiskPredefinedThreshold.Min || score > PhoneRiskPredefinedThreshold.Max) {
    return PhoneCheckErrorTypes.OutOfRange;
  }
  if (Number.isNaN(score)) {
    return PhoneCheckErrorTypes.Empty;
  }
  return null;
};

export const getDefaultRiskThresholdMode = (score: string | number): PhoneRiskThresholdModes => {
  if (isNil(score)) {
    return PhoneRiskThresholdModes.Medium;
  }
  return RiskScoreFallback[score];
};

export function getPhoneCheckCompanyNameError(flow: IFlow): Nullable<string> {
  if (validateMaxLength(flow.phoneOwnership?.companyName, COMPANY_NAME_LENGTH_LIMIT)) {
    return 'PhoneCheck.settings.companyName.validations.tooLong';
  }
  return null;
}

export enum PhoneAnalyseStepTypes {
  Otp = 'otp',
  FilterByRisk = 'filterByRisk',
  OtpAndFilterByRisk = 'otpAndFliterByRisk',
  None = 'none',
}

const isSmsRequired = (value) => value === PhoneOwnershipValidationTypes.Sms || value === PhoneOwnershipValidationTypes.SmsOptional;

export function getPhoneAnalyseStep(phoneOwnershipValidation: PhoneOwnershipValidationTypes, phoneRiskAnalysisValidation: Boolean): PhoneAnalyseStepTypes {
  const isOtpRequired = isSmsRequired(phoneOwnershipValidation);

  if (phoneRiskAnalysisValidation) {
    return isOtpRequired ? PhoneAnalyseStepTypes.OtpAndFilterByRisk : PhoneAnalyseStepTypes.FilterByRisk;
  }
  return isOtpRequired ? PhoneAnalyseStepTypes.Otp : PhoneAnalyseStepTypes.None;
}

export const PhoneCheckFAQ = 'https://docs.metamap.com/docs/phone-check-faq';
export const SDKUpdateLink = 'https://docs.metamap.com/docs/sdk';

export const PhoneRiskCheckMinVersionAndroid = '3.19.0';
export const PhoneRiskCheckMinVersionIOS = '3.39.0';
