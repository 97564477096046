import { FiAlertTriangle, FiX, FiCheck } from 'react-icons/fi';

export const labelRanges0To100 = [
  { value: 0 },
  { value: 20 },
  { value: 15 },
  { value: 15 },
  { value: 15 },
  { value: 15 },
  { value: 20 },
];

export const labelRanges0To1000 = [
  { value: 0 },
  { value: 200 },
  { value: 150 },
  { value: 150 },
  { value: 150 },
  { value: 150 },
  { value: 200 },
];

export const labelRanges0To5000 = [
  { value: 0 },
  { value: 500 },
  { value: 1000 },
  { value: 1000 },
  { value: 1000 },
  { value: 1000 },
  { value: 500 },
];

export const labelRanges0To10000 = [
  { value: 0 },
  { value: 2000 },
  { value: 1500 },
  { value: 1500 },
  { value: 1500 },
  { value: 1500 },
  { value: 2000 },
];

export const icons = {
  error: FiX,
  worning: FiAlertTriangle,
  success: FiCheck,
};

export const colorRangesList0To100 = [{ value: 25 }, { value: 25 }, { value: 50 }];
export const colorRangesList0To1000 = [{ value: 250 }, { value: 250 }, { value: 500 }];
export const colorRangesList0To5000 = [{ value: 2500 }, { value: 1000 }, { value: 1500 }];
export const colorRangesList0To10000 = [{ value: 5050 }, { value: 1950 }, { value: 3000 }];
