import React from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFormatMessage } from 'apps/intl';
import { CurrenciesTypes } from 'models/Country.model';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { Controller, useFormContext } from 'react-hook-form';
import { ONLY_POSITIVE_NUMBERS_REG_EXP } from 'lib/validations';
import { BankAccountCheckTypes } from '../../models/BankAccountData.model';
import { BALANCE_CHECKS_ICONS } from '../../assets';
import { useStyles } from './FinancialChecksModal.styles';

const invalidCharacters = ['-', '+', 'e'];

export function FinancialChecksModalListBlock({ list, supportedCurrencies }: {
  list: BankAccountCheckTypes[];
  supportedCurrencies: CurrenciesTypes[];
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { register, watch, control, formState: { errors } } = useFormContext();
  const values = watch();

  return (
    <Box mt={2} className={classes.block}>
      {list.map((listItem: BankAccountCheckTypes) => (
        <React.Fragment key={listItem}>
          <FormControlLabel
            className={classes.blockItem}
            control={(
              <Controller
                name={`${listItem}_check`}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={field.onChange}
                    color="primary"
                    checkedIcon={<CheckboxOn />}
                    icon={<CheckboxOff />}
                    checked={Boolean(field.value)}
                  />
                )}
              />
            )}
            label={(
              <Box className={classes.itemLabel}>
                <Icon component="img" src={BALANCE_CHECKS_ICONS[listItem]} />
                {formatMessage(`BankAccountData.financialChecks.${listItem}`)}
              </Box>
            )}
          />
          <Box px={2.5} pt={1}>
            {values[`${listItem}_check`] && (
              <Box>
                <Box className={classes.subTitle}>
                  {formatMessage(`BankAccountData.financialChecks.library.${listItem}`)}
                </Box>
                {supportedCurrencies.map((currency, index) => (
                  <Box className={classes.collapsibleItem} key={currency}>
                    <TextField
                      {...register(`${listItem}.thresholds.${index}.value`, {
                        required: formatMessage('validations.required'),
                        pattern: { value: ONLY_POSITIVE_NUMBERS_REG_EXP, message: formatMessage('validations.invalidInput') },
                      })}
                      onWheel={(event) => (event.target as HTMLInputElement)?.blur()}
                      onKeyDown={(event) => invalidCharacters.includes(event.key) && event.preventDefault()}
                      type="number"
                      helperText={errors?.[listItem]?.thresholds?.[index]?.value?.message}
                      error={!!errors?.[listItem]?.thresholds?.[index]?.value}
                      variant="outlined"
                    />
                    <TextField
                      {...register(`${listItem}.thresholds.${index}.currency`)}
                      value={currency}
                      type="hidden"
                    />
                    <InputLabel className={classes.marginTop14}>
                      <Typography variant="subtitle2">
                        {currency}
                      </Typography>
                    </InputLabel>
                  </Box>
                ))}
              </Box>
            )}
            <Divider light />
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}
