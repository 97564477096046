import React, { useCallback, useRef } from 'react';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { selectFlowBuilderProductsInGraph } from 'apps/flowBuilder/store/FlowBuilder.selectors';
import { useOverlay } from 'apps/overlay';
import { ProductTypes } from 'models/Product.model';
import { productManagerService } from '../services/ProductManager.service';
import { ProductDependencyModal } from '../components/ProductDependencyModal/ProductDependencyModal';
import { ProductCard } from '../components/ProductCard/ProductCard';
import { getMatchesAddedProductInGraph } from '../models/Product.model';
import { ProductCardList } from '../components/ProductCardList/ProductCardList';

export function useProductAdding() {
  const ref = useRef(null);
  const [createOverlay, closeOverlay] = useOverlay();
  const formatMessage = useFormatMessage();
  const productsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);

  return useCallback((productType: ProductTypes, onAdd: (productType: ProductTypes) => void) => {
    const { requiredProductType } = productManagerService.getProduct(productType).getCard();
    const productsMustBeInGrapch = getMatchesAddedProductInGraph(requiredProductType, productsInGraph);
    const isMultiMode = Array.isArray(productsMustBeInGrapch);
    const defaultProducTypeId = (isMultiMode && productsMustBeInGrapch[0]) ?? null;

    const handleConfirm = () => {
      onAdd(productType);
      onAdd(isMultiMode ? ref.current || defaultProducTypeId : requiredProductType);
      ref.current = null;
      closeOverlay();
    };

    const handleClose = () => {
      ref.current = null;
      closeOverlay();
    };

    const handleMultyModalSelect = (productId: ProductTypes) => {
      ref.current = productId;
    };

    if (productsMustBeInGrapch) {
      createOverlay(
        <ProductDependencyModal
          title={formatMessage('FlowBuilder.addingProductModal.title')}
          subTitle={formatMessage('FlowBuilder.addingProductModal.multiple.subTitle')}
          leftComponent={<ProductCard id={productType} isExpandable={false} defaultExpanded />}
          rightComponent={(
            Array.isArray(productsMustBeInGrapch) ? (
              <ProductCardList cardIds={productsMustBeInGrapch} onModalProductSelect={handleMultyModalSelect} defaultProducTypeId={defaultProducTypeId} />
            ) : (
              <ProductCard id={productsMustBeInGrapch} isExpandable={false} defaultExpanded />
            )
          )}
          leftSubtitle={formatMessage('FlowBuilder.addingProductModal.dependentProductTitle')}
          rightSubtitle={formatMessage('FlowBuilder.addingProductModal.requiredProductTitle')}
          confirmText={isMultiMode ? formatMessage('FlowBuilder.addingProductModal.multy.confirmBoth') : formatMessage('FlowBuilder.addingProductModal.confirmBoth')}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />,
      );
    } else {
      onAdd(productType);
    }
  }, [createOverlay, closeOverlay, formatMessage, productsInGraph]);
}
