import { makeStyles } from '@material-ui/core/styles';

export const PREVIEW_HEIGHT = 378;

export const useStyles = makeStyles((theme) => ({
  previewContainer: {
    height: PREVIEW_HEIGHT,
    boxSizing: 'border-box',
  },
  search: {
    flexGrow: 1,
    marginRight: '8px',
  },
  option: {
    fontSize: '16px',
    cursor: 'pointer',

    '&:hover:not($selected)': {
      backgroundColor: theme.palette.common.lightgrayopacity2,
    },
  },
  selected: {
    backgroundColor: theme.palette.common.lightblue,
    color: theme.palette.common.white,
  },
  placeholder: {
    color: theme.palette.common.black50,
    fontSize: '12px',
  },
  selectPlaceholder: {
    color: theme.palette.common.black50,
  },
}));
