import { AllowedRegions } from 'models/Country.model';
import { IStep, StepStatus } from 'models/Step.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export enum LocationIntelligenceSettingsTypes {
  IpValidation = 'ipValidation',
  VpnDetection = 'vpnDetection',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  AllowedRegions = 'allowedRegions',
  HighAccuracy = 'highAccuracy',
}

export enum LocationIntelligenceValidationTypes {
  None = 'none',
  Basic = 'basic',
  RestrictionInvisible = 'restriction:invisible',
  RestrictionVisible = 'restriction:visible',
  RestrictionBlock = 'restriction:block',
}

export enum LocationIntelligenceCheckTypes {
  Basic = 'basic',
  GeoRestrictions = 'geoRestrictions',
  VpnDetection = 'vpnDetection',
  HighAccuracy = 'highAccuracyDetection'
}

export enum LocationIntelligenceErrorCodes { // TODO: @ggrigorev WF move to old if it's not used for IpCheckMeritOutput
  VpnDetected = 'ip.vpnDetected',
  Restricted = 'ip.restricted',
}

export enum LocationIntelligencePlatformTypes {
  Android = 'android',
  IOs = 'iOS',
  WebMobile = 'web_mobile',
  WebDesktop = 'web_desktop',
}

export enum LocationIntelligenceIconTypes {
  Mobile = 'locIntMobile',
  Desktop = 'locIntDesktop',
  LatLong = 'locIntLatLong',
  HighAccuracy = 'locIntHighAccuracy',
  VpnDetect = 'locIntVpnDetect',
  GeoRestriction = 'locIntGeoRestriction'
}

export enum SystemSubtype {
  iOS = 'ios',
  Android = 'android',
}

export interface LocationValidation {
  allowedRegions: AllowedRegions[];
}

export interface LocationIntelligenceStep extends IStep{
  startCount: number;
  status: 0 | 100 | 200;
  id: VerificationPatternTypes.IpValidation;
  startedAt: string;
  data: LocationIntelligenceVerificationOutput;
  completedAt: string;
  isRunning: boolean;
}

export interface LocationIntelligenceVerificationOutput {
  country: string;
  countryCode: string;
  region: string;
  regionCode: string;
  city: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  highAccuracy: boolean;
  platform: string;
  address: string;
  vpnDetectionEnabled: boolean;
  ipRestrictionEnabled: boolean;
  zip: string;
  safe: boolean;
  // TODO: @ggrigorev WF following is a draft. Maybe replace safe and isRunning with status field
  vpnDetection: boolean;
  geoRestriction: boolean;
  isRunning: boolean;
}

export function getAllAllowedRegions(countries): AllowedRegions {
  return countries.map((country) => ({
    country: country.id,
    regions: country.regions,
  }));
}

export function getLocationIntelligenceStatus(locationStep: LocationIntelligenceStep): StepStatus {
  if (locationStep?.status < 200) {
    return StepStatus.Checking;
  }
  if (locationStep?.data?.safe) {
    return StepStatus.Success;
  }
  return StepStatus.Failure;
}

export function getLocationIntelligenceUrl(data) {
  if (data === undefined) {
    return null;
  }
  return `https://maps.googleapis.com/maps/api/staticmap?size=404x202&zoom=10&scale=2&format=png&maptype=roadmap&key=${process.env.REACT_APP_STATIC_GOOGLE_MAP_API_KEY}&markers=size:normal%7C${data.latitude},${data.longitude}`;
}

export function getLocationIntelligenceStep(steps: IStep[] = []): LocationIntelligenceStep {
  const ipStep = steps.find((item) => item.id === VerificationPatternTypes.IpValidation);
  const locationStep = steps.find((item) => item.id === VerificationPatternTypes.Geolocation);

  if (!ipStep && !locationStep) {
    return null;
  }

  return {
    ...(ipStep ?? locationStep),
    isRunning: ipStep ? (ipStep?.status < 200) : (locationStep?.status < 200),
    data: {
      ...(ipStep?.data ?? {}),
      ...(locationStep?.data ?? {}),
      vpnDetection: ipStep?.error?.code === LocationIntelligenceErrorCodes.VpnDetected,
      geoRestriction: locationStep?.error?.code === LocationIntelligenceErrorCodes.Restricted || ipStep?.error?.code === LocationIntelligenceErrorCodes.Restricted,
      highAccuracy: !!locationStep,
      mapUrl: getLocationIntelligenceUrl(ipStep?.data || locationStep?.data),
    },
  } as LocationIntelligenceStep;
}
