import { createReducer } from 'state/store.utils';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import cloneDeep from 'lodash/cloneDeep';
import { ActionGroupNameTypes, ICustomFieldStore, SliceNameTypes, types } from './CustomField.store';
import { CustomFieldModalTypes } from '../models/CustomField.model';

const initialState: ICustomFieldStore = {
  [SliceNameTypes.CustomFieldModalType]: LoadableAdapter.createState(CustomFieldModalTypes.ConfigureField),
  [SliceNameTypes.CustomFieldEditedField]: LoadableAdapter.createState(null),
  [SliceNameTypes.CustomFieldFlattenListFields]: LoadableAdapter.createState([]),
  [SliceNameTypes.CustomFieldListFields]: LoadableAdapter.createState([]),
  [SliceNameTypes.CustomFieldEditedIndex]: LoadableAdapter.createState(null),
  [SliceNameTypes.CustomFieldEditedParent]: LoadableAdapter.createState(null),
  [SliceNameTypes.CustomFieldUploadingThumbnail]: LoadableAdapter.createState(false),
  [SliceNameTypes.CustomFieldEditedSystemName]: LoadableAdapter.createState(null),
};

export const customFieldReducer = createReducer(initialState, {
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldModalStep, SliceNameTypes.CustomFieldModalType),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldFlattenListFields, SliceNameTypes.CustomFieldFlattenListFields),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldListFields, SliceNameTypes.CustomFieldListFields),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldEditedField, SliceNameTypes.CustomFieldEditedField),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldEditedIndex, SliceNameTypes.CustomFieldEditedIndex),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldEditedParent, SliceNameTypes.CustomFieldEditedParent),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldUploadingThumbnail, SliceNameTypes.CustomFieldUploadingThumbnail),
  ...LoadableAdapter.createHandlers(ActionGroupNameTypes.CustomFieldEditedSystemName, SliceNameTypes.CustomFieldEditedSystemName),
  [types.CUSTOM_FIELD_EDITED_DEFAULT_VALUE_SUCCESS](state, { payload }) {
    const value = cloneDeep(state[SliceNameTypes.CustomFieldEditedField].value);
    value.atomicFieldParams.defaultValue = payload;
    return {
      ...state,
      [SliceNameTypes.CustomFieldEditedField]: {
        ...state[SliceNameTypes.CustomFieldEditedField],
        value,
      },
    };
  },
  [types.CUSTOM_FIELD_EDITED_MAPPING_SUCCESS](state, { payload }) {
    const value = cloneDeep(state[SliceNameTypes.CustomFieldEditedField].value);
    value.atomicFieldParams.mapping = payload;
    return {
      ...state,
      [SliceNameTypes.CustomFieldEditedField]: {
        ...state[SliceNameTypes.CustomFieldEditedField],
        value,
      },
    };
  },
  [types.CUSTOM_FIELD_EDITED_SELECT_OPTIONS_SUCCESS](state, { payload }) {
    const value = cloneDeep(state[SliceNameTypes.CustomFieldEditedField].value);
    value.atomicFieldParams.selectOptions = payload;
    return {
      ...state,
      [SliceNameTypes.CustomFieldEditedField]: {
        ...state[SliceNameTypes.CustomFieldEditedField],
        value,
      },
    };
  },
  [types.CUSTOM_FIELD_EDITED_THUMBNAIL_SUCCESS](state, { payload }) {
    const value = cloneDeep(state[SliceNameTypes.CustomFieldEditedField].value);
    value.thumbnail = payload;
    return {
      ...state,
      [SliceNameTypes.CustomFieldEditedField]: {
        ...state[SliceNameTypes.CustomFieldEditedField],
        value,
      },
    };
  },
  [types.CUSTOM_FIELD_EDITED_STATIC_TEXT_PARAMS_SUCCESS](state, { payload }) {
    const value = cloneDeep(state[SliceNameTypes.CustomFieldEditedField].value);
    value.atomicFieldParams.staticTextParams = payload;
    return {
      ...state,
      [SliceNameTypes.CustomFieldEditedField]: {
        ...state[SliceNameTypes.CustomFieldEditedField],
        value,
      },
    };
  },
});
