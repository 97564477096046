import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { SketchPicker } from 'react-color';
import { useCustomColorPickerStyles } from './CustomcolorPicker.style';

const CustomColorPicker = ({
  dataQa,
  colorHexCode,
  handleChangeColor,
  isBottomElement,
}: {
  dataQa: string;
  colorHexCode: string;
  handleChangeColor: (param: string) => void;
  isBottomElement: boolean;
}) => {
  const [colorHex, setColorHex] = useState(() => colorHexCode);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const classes = useCustomColorPickerStyles({ colorHex, isBottomElement });
  const handleClick = () => {
    setDisplayColorPicker((state) => !state);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  useEffect(() => {
    setColorHex(colorHexCode);
  }, [colorHexCode]);

  const handleChange = (colorA: any) => {
    setColorHex(colorA?.hex);
    handleChangeColor(colorA?.hex);
  };
  return(
    <div className={classes.customColorPickerWrapper}>
      <div data-qa={dataQa} role="button" tabIndex={0} className={classes.root} onClick={handleClick} onKeyDown={handleClick}>
        <span className={classes.colorRect} />
        <span className={classes.text}>{colorHex}</span>
      </div>
      {
        displayColorPicker ? (
          <Box className={classes.popover}>
            <Box className={classes.cover} onClick={handleClose} />
            <SketchPicker color={colorHex} onChange={handleChange} />
          </Box>
        ) : (
          null
        )
      }
    </div>
  );
};
export default CustomColorPicker;
