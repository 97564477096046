import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  icon: {
    height: 25,
    '& svg': {
      width: 25,
      height: 25,
    },
  },
  wrapper: {
    margin: '12px, 0',
  },
}));
