import { QATags } from 'models/QA.model';
import { LinkButton } from 'models/Integration.model';

export enum TabIDTypes {
  Api = 'api',
  Sdk = 'sdk',
  Web = 'web',
  Mobile = 'mobile',
  Native = 'native',
  Ios = 'ios',
  Android = 'android',
  Hybrid = 'hybrid',
  ReactNative = 'reactNative',
  Xamarin = 'xamarin',
  Cordova = 'cordova',
  CordovaIonic = 'cordovaIonic',
  DirectLink = 'directLink',
}

export enum InformationImageTypes {
  DirectLink = 'directLink',
  Mobile = 'mobile',
  Web = 'web',
  Api = 'api',
  Ios = 'ios',
  Android = 'android',
}

export enum ForDevelopersInputTypes {
  Secret = 'secret',
  Url = 'url',
}

export interface ITab {
  id: TabIDTypes;
  qa: string;
  defaultOpen?: boolean;
  children?: ITab[];
  link?: string;
}

export const menuStructure: ITab[] = [
  { id: TabIDTypes.Api, qa: QATags.Integration.Tabs.Api },
  {
    id: TabIDTypes.Sdk,
    qa: QATags.Integration.Tabs.Sdk,
    defaultOpen: true,
    children: [
      { id: TabIDTypes.Web, qa: QATags.Integration.Tabs.Web },
      {
        id: TabIDTypes.Mobile,
        qa: QATags.Integration.Tabs.Mobile,
        link: LinkButton.documentationURL,
      },
    ],
  },
  { id: TabIDTypes.DirectLink, qa: QATags.Integration.Tabs.DirectLink },
];

export function getIsSelected(tab: ITab, selectedId: TabIDTypes): boolean {
  if (tab.id === selectedId) return true;
  if (!tab.children) return false;
  return tab.children.some((child) => getIsSelected(child, selectedId));
}
