import { IChatWidget } from 'models/ChatWidget.model';
import { IntercomChatCommandTypes, IntercomEventTypes } from '../models/Intercom.model';

class IntercomChat implements IChatWidget {
  open() {
    if (this.isIntercomInit()) {
      window.Intercom(IntercomChatCommandTypes.Show);
    }
  }

  hide() {
    if (this.isIntercomInit()) {
      window.Intercom(IntercomChatCommandTypes.Hide);
    }
  }

  createNewMessage(message: string) {
    if (this.isIntercomInit()) {
      window.Intercom(IntercomChatCommandTypes.ShowNewMessage, message);
    }
  }

  trackEvent(eventName: IntercomEventTypes, metadata?: Record<string, string | number | boolean>) {
    if (this.isIntercomInit()) {
      window.Intercom(IntercomChatCommandTypes.TrackEvent, eventName, metadata);
    }
  }

  update(data?: Record<string, string | number | boolean>) {
    if (this.isIntercomInit()) {
      window.Intercom(IntercomChatCommandTypes.Update, data);
    }
  }

  isIntercomInit() {
    return window.Intercom;
  }
}

export const ChatWidget = new IntercomChat();
