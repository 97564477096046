import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  score: {
    width: 'auto',
    margin: '0 auto',
  },
  label: {
    color: theme.palette.text.main,
  },
  statusValue: {
    margin: '20px 0 5px 0',
    fontWeight: 700,
  },
  highRisk: {
    color: theme.palette.common.red,
  },
  lowRisk: {
    color: theme.palette.common.yellow,
  },
}));
