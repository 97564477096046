import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '14px 12px 20px 16px',
  },
  item: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  spoiler: {
    paddingLeft: '16px !important',
  },
}));
