import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black90,
    fontWeight: 600,
    marginBottom: 16,
  },
  radio: {
    '&:hover': {
      backgroundColor: 'inherit !important',
    },
    '&.MuiIconButton-colorPrimary': {
      color: theme.palette.common.black75,
    },
    '&.MuiRadio-colorPrimary.Mui-checked': {
      color: theme.palette.common.lightblue,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      fontSize: '16px !important',
      padding: '0 16px',
    },
  },
  submitButton: {
    minWidth: 170,
    marginLeft: 16,
  },
}));
