import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    padding: '21px 40px',
    minHeight: 600,
    height: 650,
    width: 750,
    overflow: 'scroll',
  },
  modalTitle: {
    lineHeight: '29px',
  },
  modalSubTitle: {
    marginTop: 5,
    color: theme.palette.text.main,
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px',
    width: '20px',
    height: '20px',
    color: theme.palette.button.close.main,
  },
  marginBottom50: {
    marginBottom: 50,
  },
  buttonContainer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: '0 40px 20px',
    background: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    minHeight: 50,
    fontSize: 14,
    fontWeight: 'bold',
    transition: '.2s all ease-in-out',
    '&.Mui-disabled': {
      opacity: 0.3,
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
}));
