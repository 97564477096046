import Grid from '@material-ui/core/Grid';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { ICustomField } from 'models/CustomField.model';
import { IDefaultValues } from '../../../../models/CustomField.model';
import { selectCustomFieldEditedField } from '../../../../state/CustomField.selectors';
import { TextFieldInput } from '../../CustomFieldModalConfigureAtomic.styles';

export function CustomFieldTextSetting({ isShowDefaultText, setIsShowDefaultText, text, setDefaults, handleAtomicFieldParamsChange }: {
  isShowDefaultText: boolean;
  setIsShowDefaultText: Dispatch<SetStateAction<boolean>>;
  setDefaults: Dispatch<SetStateAction<IDefaultValues>>;
  text: string;
  handleAtomicFieldParamsChange: StandardInputProps['onChange'];
}) {
  const formatMessage = useFormatMessage();

  const selectedCustomField = useSelector<any, ICustomField>(selectCustomFieldEditedField);

  const handleIsShowDefaultTextChange = useCallback(() => setIsShowDefaultText((prev) => !prev), [setIsShowDefaultText]);
  const setDefaultText = useCallback(({ target: { value } }) => setDefaults((prev) => ({ ...prev, text: value })), [setDefaults]);

  return (
    <>
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="body1">
            {formatMessage('CustomField.settings.defaultValue')}
          </Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={isShowDefaultText}
            color="primary"
            size="small"
            onChange={handleIsShowDefaultTextChange}
          />
        </Grid>
      </Grid>
      {isShowDefaultText && (
        <Grid item container direction="column">
          <Grid item>
            <TextFieldInput
              fullWidth
              value={text}
              type="text"
              name="defaultValue"
              onChange={setDefaultText}
              placeholder={formatMessage('CustomField.settings.defaultValue.placeholder')}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="subtitle2">
            {formatMessage('CustomField.settings.fieldRegex')}
          </Typography>
        </Grid>
        <Grid item>
          <TextFieldInput
            fullWidth
            value={selectedCustomField.atomicFieldParams.regex}
            type="text"
            name="regex"
            onChange={handleAtomicFieldParamsChange}
            placeholder={formatMessage('CustomField.settings.fieldRegex.placeholder')}
          />
        </Grid>
      </Grid>
    </>
  );
}
