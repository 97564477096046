import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Close from '@material-ui/icons/Close';
import { useOverlay } from 'apps/overlay';
import { IntegrationType } from 'apps/Integration';
import { ForDevsWebhookModal } from 'apps/forDevelopers/components/ForDevsWebhookModal/ForDevsWebhookModal';
import { useFormatMessage } from 'apps/intl';
import { PermissionSubscriptionStatusTypes, LimiterSubscriptionStatus } from 'apps/Permissions';
import { useStyles } from './FlowBuilderIntegrationDetails.styles';
import { Button, Icon } from 'lib/UI';

export function FlowBuilderIntegrationDetails({ closePanel }: { closePanel: () => void }) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [createOverlay] = useOverlay();

  const handleOpenWebhookModal = useCallback(() => {
    createOverlay(<ForDevsWebhookModal />);
  }, [createOverlay]);

  return (
    <Box p={0.4} className={classes.root}>
      <Box p={1.6} pt={0.6} className={classes.container}>
        <Box mb={1}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            gridGap="9px"
          >
            <Box fontWeight="bold" color="common.black90">
              {formatMessage('FlowBuilder.integration')}
            </Box>
            <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canUseWebhooksModal}>
              <Button
                type="secondary"
                size="large"
                onClick={handleOpenWebhookModal}
              >
                <Icon name="settings" />
                {formatMessage('forDevs.webhook.button')}
              </Button>
            </LimiterSubscriptionStatus>
            <Box flex={1} />
            <Box
              ml={2}
              onClick={closePanel}
              className={classes.closeButton}
            >
              <Close />
            </Box>
          </Box>
        </Box>
        <IntegrationType />
      </Box>
    </Box>
  );
}
