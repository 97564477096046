import { Box, Button, Grid, List, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { FileErrorTypes } from 'apps/CustomWatchlist/models/CustomWatchlist.model';
import { useSelector } from 'react-redux';
import { selectMerchantId } from 'state/merchant';
import { ISignedUrlEntry, allowedFileTypes, maxFileCount, maxFileSize } from 'models/Watchlist.model';
import { notification } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './UploadFacialWatchlistItems.styles';
import { ImageList } from '../ImageList/ImageList';
import * as api from '../../client/CustomWatchlist.client';

export const UploadFacialWatchlistItems = ({ setFacialWatchList, uploadInProgress, setUploadInProgress, currentWatchListImageCount }:
  {
    setFacialWatchList: (fileName: string) => void;
    uploadInProgress: boolean;
    setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
    currentWatchListImageCount: number;
  }) => {
  const merchantId = useSelector<any, string>(selectMerchantId);
  const formatMessage = useFormatMessage();
  const [processingFiles, setProcessingFiles] = useState(false);
  const [validFiles, setValidFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [signedUrls, setSingedUrls] = useState<ISignedUrlEntry[] | null>(null);

  const classes = useStyles();

  const getPresignedS3Urls = async (files: File[]) => {
    if (files.length > 0) {
      try {
        const response = await api.getPreSignedUrls(merchantId, files.length);
        const updatedSingedUrls = signedUrls ? [...signedUrls, ...response.data.signedUrls] : response.data.signedUrls;
        setSingedUrls(updatedSingedUrls);
        setValidFiles([...validFiles, ...files]);
        setUploadInProgress(true);
        setProcessingFiles(false);
      } catch (error) {
        notification.error(formatMessage('CustomWatchlist.settings.facialWatchlists.modal.imageUplodError'));
      }
    } else {
      setProcessingFiles(false);
    }
  };

  const getFileError = (file: File) => {
    let error = null;
    if (!allowedFileTypes.includes(file.type)) {
      error = FileErrorTypes.UnSupportedType;
      return error;
    }
    if (file.size > maxFileSize) {
      error = FileErrorTypes.FileSizeError;
      return error;
    }
    return error;
  };

  const handleFileChange = (e) => {
    setProcessingFiles(true);
    const images: File[] = Array.from(e.target.files);

    const files = [];
    const errorFiles = [];

    images.forEach((file) => {
      const fileError = getFileError(file);
      if (!fileError) {
        files.push({ file });
      } else {
        errorFiles.push({ file, error: fileError });
      }
    });

    const totalFilesCount = [...validFiles, ...files].length;
    const totalFilesWithWatchlistCount = totalFilesCount + currentWatchListImageCount;

    if (totalFilesWithWatchlistCount <= maxFileCount) {
      getPresignedS3Urls(files);
    } else {
      notification.error(formatMessage('CustomWatchlist.settings.facialWatchlists.modal.maxImages', {
        messageValues: {
          maxFileCount,
        },
      }));
      setProcessingFiles(false);
    }
    setInvalidFiles(errorFiles);
  };

  const handleFileUploadComplete = (index, fileName?: string) => {
    validFiles[index].uploaded = true;
    if (fileName) {
      setFacialWatchList(fileName);
    }
    const allFilesUploaded = validFiles.every((file) => file.uploaded);
    if (allFilesUploaded) {
      setUploadInProgress(false);
    }
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle2">
          {formatMessage('CustomWatchlist.settings.facialWatchlists.upload.description')}
        </Typography>
        <Typography variant="subtitle2">
          {formatMessage('CustomWatchlist.settings.facialWatchlists.upload.description2')}
        </Typography>
      </Box>
      <Button className={classes.button} component="label" variant="contained" color="primary" startIcon={<FiUploadCloud />} disabled={(processingFiles || uploadInProgress)}>
        {formatMessage('CustomWatchlist.settings.facialWatchlists.modal.uploadFile')}
        <input className={classes.input} type="file" multiple accept="image/jpeg" onChange={handleFileChange} />
      </Button>
      {processingFiles && <Box mt={1}>{formatMessage('CustomWatchlist.settings.facialWatchlists.modal.processingFiles')}</Box>}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          {validFiles.length > 0 && (
            <List>
              <h4>{formatMessage('CustomWatchlist.settings.facialWatchlists.modal.validImages')}</h4>
            </List>
          )}
          <List>
            {validFiles.map((validFile, index) => (
              <ImageList key={index} file={validFile.file} uploadUrl={signedUrls[index]} onUploadComplete={(fileName: string) => handleFileUploadComplete(index, fileName)} />
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          {invalidFiles.length > 0 && (
            <List>
              <h4>{formatMessage('CustomWatchlist.settings.facialWatchlists.modal.invalidImages')}</h4>
            </List>
          )}
          {invalidFiles.map((invalidFile, index) => <ImageList file={invalidFile.file} key={index} error={invalidFile.error} />)}
        </Grid>
      </Grid>
    </>
  );
};
