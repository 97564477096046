import { Box, Link } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { ReactComponent as MoneyIcon } from '../../assets/money.svg';
import { SalesTooltip, useStyles } from './SalesToolTip.style';

export function SalesToolTip({ children }: {children?: React.ReactElement}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <SalesTooltip
      interactive
      title={(
        <Box display="flex" flexDirection="column">
          <span className={classes.tooltipTitle}>{formatMessage('salesTooltip.title')}</span>
          <span>{formatMessage('salesTooltip.body')}</span>
          <Link className={classes.emailLink} color="primary" href="mailto:sales@mati.io" rel="noopener">
            sales@mati.io
          </Link>
        </Box>
    )}
      arrow
      placement="right"
    >
      {children || <MoneyIcon className={classNames(classes.moneyIcon)} />}
    </SalesTooltip>
  );
}
