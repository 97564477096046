import React, { useCallback, useEffect, useState } from 'react';
import { ErrorListener } from 'apps/Error';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { selectProductIsInited, useProduct } from 'apps/Product';
import { Loader, notification, Placeholder } from 'apps/ui';
import { ReactComponent as EmptyBuilderIcon } from 'assets/empty-flow-builder.svg';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { useQuery } from 'lib/url';
import { IFlow } from 'models/Flow.model';
import { Routes } from 'models/Router.model';
import { FiChevronLeft } from 'react-icons/fi';
import { useFlowListLoad } from 'apps/FlowList';
import { updateCurrentFlowId, setFlowPristineState } from 'state/merchant/merchant.actions';
import { selectPristineFlowIds } from 'state/merchant/merchant.selectors';
import { Loadable } from 'models/Loadable.model';
import { useFormatMessage } from 'apps/intl';
import { TemplatesModal } from 'apps/SolutionCatalog';
import { clearCurrentTemplate, selectTemplateApplicationState } from 'apps/Templates';
import { useOverlay } from 'apps/overlay';
import { ProductTypes } from 'models/Product.model';
import { FlowBuilderIntegrationDetails } from '../FlowBuilderIntegrationDetails/FlowBuilderIntegrationDetails';
import { ProductListSidebar } from '../ProductListSidebar/ProductListSidebar';
import { loadAndApplyTemplateToMetamap, flowBuilderChangeableFlowLoad, flowBuilderChangeableFlowUpdate, flowBuilderClearStore, flowBuilderShowIntegration, flowBuilderDontShowIntegration, flowBuilderShowCustomization, flowBuilderDontShowCustomization, clearProductSelection } from '../../store/FlowBuilder.action';
import { selectFlowBuilderChangeableFlowModel, selectFlowBuilderSelectedId, selectIsShowIntegration, selectIsShowCustomization } from '../../store/FlowBuilder.selectors';
import { FlowProductsGraph } from '../FlowProductsGraph/FlowProductsGraph';
import { FlowBuilderProductDetails } from '../FlowBuilderProductDetails/FlowBuilderProductDetails';
import { FlowInfoHeader } from '../FlowInfoHeader/FlowInfoHeader';
import { FlowBuilderErrorWrapper } from '../FlowBuilderErrorWrapper/FlowBuilderErrorWrapper';
import { useStyles } from './FlowBuilder.styles';
import { dagreGraphService } from '../../services/dagreGraph.service';
import { SDKCustomization } from '../SDKCustomization/SDKCustomization';

export function FlowBuilderContent() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [createOverlay, closeOverlay] = useOverlay();
  const [newFlowName, setNewFlowName] = useState<string>('');
  const selectedId = useSelector<any, ProductTypes>(selectFlowBuilderSelectedId);
  const isShowIntegration = useSelector<any, boolean>(selectIsShowIntegration);
  const isShowCustomization = useSelector<any, boolean>(selectIsShowCustomization);
  const changeableFlowModel = useSelector<any, Loadable<IFlow>>(selectFlowBuilderChangeableFlowModel);
  const isTemplateApplying = useSelector<any, boolean>(selectTemplateApplicationState);
  const isBigScreen = useMediaQuery('(min-width:768px)', { noSsr: true });
  const isHoverableScreen = useMediaQuery('(hover:hover) and (pointer:fine)', { noSsr: true });
  const classes = useStyles();
  const { asMerchantId } = useQuery();
  const flowListModel = useFlowListLoad();
  const formatMessage = useFormatMessage();
  const pristineFlowIds = useSelector<any, string[]>(selectPristineFlowIds);

  useEffect(() => () => {
    dispatch(clearCurrentTemplate());
    dispatch(flowBuilderClearStore());
  }, [dispatch]);

  useEffect(() => {
    const isChangedId = changeableFlowModel?.value?.id !== id;
    if (isChangedId) {
      dispatch(updateCurrentFlowId(id));
    }
    if (isChangedId || LoadableAdapter.isPristine(changeableFlowModel)) {
      dispatch(flowBuilderChangeableFlowLoad());
    }
    dagreGraphService.createGraph();
  }, [dispatch, id, asMerchantId, flowListModel.isLoaded, changeableFlowModel]);

  const handleTemplateCardClick = useCallback(async (templateId: string) => {
    try {
      await dispatch(loadAndApplyTemplateToMetamap(templateId));
      closeOverlay();
    } catch {
      notification.error(formatMessage('Error.common'));
    }
  }, [dispatch, closeOverlay, formatMessage]);

  useEffect(() => {
    let timeout;
    if (pristineFlowIds.includes(id)) {
      timeout = setTimeout(() => {
        dispatch(setFlowPristineState(id, false));
        createOverlay(<TemplatesModal handleCardClick={handleTemplateCardClick} />);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [dispatch, pristineFlowIds, id, createOverlay, handleTemplateCardClick, closeOverlay]);

  const handleProductUpdate = useCallback((patch: Partial<IFlow>) => {
    dispatch(flowBuilderChangeableFlowUpdate(patch));
  }, [dispatch]);

  if (!flowListModel.isLoaded || isTemplateApplying) {
    return <Loader />;
  }

  return (
    <Box p={0.8} className={classes.container}>
      <Grid>
        <Grid className={classes.wrapperHeader}>
          <Box>
            <FlowInfoHeader
              newFlowName={newFlowName}
              setNewFlowName={setNewFlowName}
              handleIntegrate={() => {
                dispatch(flowBuilderShowIntegration());
                dispatch(flowBuilderDontShowCustomization());
                dispatch(clearProductSelection());
              }}
              handleCustomize={() => {
                dispatch(flowBuilderShowCustomization());
                dispatch(flowBuilderDontShowIntegration());
                dispatch(clearProductSelection());
              }}
            />
          </Box>
        </Grid>
        <Grid>
          {(isBigScreen || isHoverableScreen) ? (
            <Grid container wrap="nowrap" spacing={2} className={classes.wrapper}>
              <Grid id="products-catalog" item container direction="column" wrap="nowrap" className={classes.sidebar}>
                <ProductListSidebar />
              </Grid>
              <Grid item xs={12} container direction="column" alignItems="center" className={classes.graphContainer}>
                <Box mb={1.5} color="common.black90" fontWeight="bold" textAlign="center">
                  {formatMessage('FlowBuilder.graph.usersFlow')}
                </Box>
                <Grid id="flow-graph" container direction="column" alignItems="center" className={classes.graph}>
                  <FlowProductsGraph />
                </Grid>
              </Grid>
              <Grid id="details" item container direction="column" wrap="nowrap" className={classes.details}>
                {!selectedId && isShowCustomization && (
                  <SDKCustomization closePanel={() => dispatch(flowBuilderDontShowCustomization())} />
                )}
                {!selectedId && isShowIntegration && (
                  <FlowBuilderIntegrationDetails closePanel={() => dispatch(flowBuilderDontShowIntegration())} />
                )}
                {selectedId && (
                  <FlowBuilderProductDetails
                    flow={changeableFlowModel.value}
                    productId={selectedId}
                    onUpdate={handleProductUpdate}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Link to={Routes.flow.root} className={classes.buttonBack}>
                <FiChevronLeft fontSize={20} />
              </Link>
              <Paper className={classes.placeholder}>
                <Placeholder
                  text={formatMessage('FlowBuilder.placeholder')}
                  icon={<EmptyBuilderIcon />}
                />
              </Paper>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export function FlowBuilder() {
  const isProductInited = useSelector<any, boolean>(selectProductIsInited);

  useProduct();

  if (!isProductInited) {
    return <Loader />;
  }

  return (
    <FlowBuilderErrorWrapper>
      <ErrorListener>
        <FlowBuilderContent />
      </ErrorListener>
    </FlowBuilderErrorWrapper>
  );
}
