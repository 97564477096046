import { ITemplateMetadata, MetadataTypes } from 'apps/Templates';
import { IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';
import { productManagerService } from 'apps/Product';

export interface ICardsOptions {
  name: string;
  description: string;
  __v?: number;
  _id: string;
  flow?: IFlow;
  meritsList: ProductTypes[];
  metadata: ITemplateMetadata[];
  isAvailableToFreemium: boolean;
}

export type IFilterOptions = Record<MetadataTypes, ITemplateMetadata[]>;

export const initialFiltersData: IFilterOptions = { [MetadataTypes.Industry]: [], [MetadataTypes.UseCase]: [], [MetadataTypes.Country]: [] };

export interface IModifiedFiltersOptions {
  title: MetadataTypes;
  data: ITemplateMetadata[];
}

export interface ICountryTag {
  name: ITemplateMetadata['name'];
  disabled: boolean;
}

export function getFiltersOptions(filtersData: ITemplateMetadata[]): IModifiedFiltersOptions[] {
  const titles = Array.from(new Set(filtersData.map((item) => item.type)));
  return titles.map((title: MetadataTypes) => {
    const uniqueOptions = filtersData.filter((item) => item.type === title);
    return { title, data: [...uniqueOptions] };
  });
}

export function getSelectedFilterName(currentFilters: IFilterOptions): Nullable<string> {
  if (currentFilters[MetadataTypes.UseCase][0]) {
    return currentFilters[MetadataTypes.UseCase][0].name;
  }
  if (currentFilters[MetadataTypes.Industry][0]) {
    return currentFilters[MetadataTypes.Industry][0].name;
  }
  return null;
}

export const flowBuilderProductListForTemplates = (flow: IFlow, registered: ProductTypes[]): ProductTypes[] => {
  const activated = registered.filter((item) => {
    const product = productManagerService.getProduct(item);
    if (!product) {
      return false;
    }
    return product.isInFlow(flow);
  });
  return productManagerService.sortProductTypes(activated);
};
