import { Product, ProductSettings, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { IFlow } from 'models/Flow.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { DocumentStepTypes } from 'models/Step.model';
import RiskScore from '../components/RiskScore/RiskScore';

export class FraudAssessmentScore extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.ADFraudScore;
  order = 20000;
  icon = null;

  component = null;
  componentVerification = RiskScore;
  isConfigurable = false;

  getVerification(verification: VerificationResponse): any {
    const data = verification.steps.find((obj) => obj.id === DocumentStepTypes.FruadAssessment);
    return {
      ...data,
      verificationDetails: {
        id: verification.id, verificationStatus: verification.verificationStatus,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInFlow(flow: IFlow): boolean {
    return false;
  }

  isInVerification(verification: VerificationResponse): boolean {
    const fruadAssessment = verification.steps.find((obj) => obj.id === DocumentStepTypes.FruadAssessment);
    if (fruadAssessment?.data) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parser(flow: IFlow, productsInGraph?: ProductTypes[]): ProductSettings {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasFailedCheck(verification: VerificationResponse): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  serialize(settings: ProductSettings<any>): Partial<IFlow> {
    return undefined;
  }
}
