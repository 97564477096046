import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import classnames from 'classnames';
import { FixedSizeNodeComponentProps, ITreeWalkerNodeData } from 'lib/FixedSizeTree';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import { ReactComponent as CheckboxPartial } from 'assets/icon-checkbox-partial.svg';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { IListItemData, ITree } from '../../models/CountryAndWorldPartSelectingForm.model';
import { useStyles, StyledCheckbox } from './CountryAndWorldPartSelectingFormItem.styles';

interface ICountryAndWorldPartSelectingFormItemProps extends FixedSizeNodeComponentProps<ITreeWalkerNodeData<ITree>> {
  treeData?: IListItemData;
}
export const CountryAndWorldPartSelectingFormItem = ({
  style,
  data,
  treeData,
}: ICountryAndWorldPartSelectingFormItemProps) => {
  const classes = useStyles();
  const { handleSelect, getIsChecked } = treeData;
  const { nestingLevel, item } = data;
  const { label, id } = item;
  const isFirstLevel = nestingLevel === 0;

  const parentCheckIcon = useMemo(() => {
    if (isFirstLevel) {
      return getIsChecked(id) ? <CheckboxOn /> : <CheckboxPartial />;
    }
    return <CheckboxOn />;
  }, [getIsChecked, isFirstLevel, id]);

  return (
    <ListItem style={style} className={classnames(classes.listItem, { [classes.firstLevelItem]: isFirstLevel })}>
      <FormLabel
        className={classnames(classes.listName, { [classes.listNameChild]: isFirstLevel })}
        htmlFor="name"
      >
        {label}
      </FormLabel>
      <Box ml={0.5}>
        <FormControl>
          <StyledCheckbox
            disableRipple
            onChange={(_, checked) => handleSelect(checked, id)}
            name={id}
            checked={getIsChecked(id)}
            color="primary"
            checkedIcon={parentCheckIcon}
            icon={<CheckboxOff />}
          />
        </FormControl>
      </Box>
    </ListItem>
  );
};
