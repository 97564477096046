import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  extendedDescription: {
    maxWidth: '100% !important',
  },
  radioAlign: {
    alignItems: 'end',
  },
  customAlign: {
    alignItems: 'center',
  },
}));
