import React from 'react';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes } from 'models/Product.model';
import { IFlow } from 'models/Flow.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { IVideoAgreementStep, VideoAgreementCheckTypes, VideoAgreementSettingTypes, defaultLanguageId, validateScript, IVideoAgreementVerificationOutput } from '../models/VideoAgreement.model';
import { ReactComponent as VideoAgreementIcon } from '../assets/video-agreement-icon.svg';
import { VideoAgreementSettings } from '../components/VideoAgreementSettings/VideoAgreementSettings';
import { VideoAgreementVerification } from '../components/VideoAgreementVerification/VideoAgreementVerification';

type ProductSettingsVideoAgreement = ProductSettings<VideoAgreementSettingTypes>

export class VideoAgreement extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.VideoAgreement;
  order = 1050;
  icon = () => <VideoAgreementIcon />;
  inputs = [
    ProductInputTypes.VideoSelfie,
    ProductInputTypes.VoiceRecording,
  ];
  checks = [{
    id: VideoAgreementCheckTypes.VerbalAgreement,
    isActive: true,
  }];
  integrationTypes = [ProductIntegrationTypes.Sdk];
  component = VideoAgreementSettings;
  componentVerification = VideoAgreementVerification;

  serialize(settings: ProductSettingsVideoAgreement, flow: any): Partial<IFlow> {
    return {
      verificationPatterns: {
        [VerificationPatternTypes.VideoAgreementValidation]: Boolean(settings[VideoAgreementSettingTypes.Script]?.value.length),
      },
      videoAgreement: {
        script: settings[VideoAgreementSettingTypes.Script]?.value,
        language: settings[VideoAgreementSettingTypes.Language]?.value,
        textMatching: settings[VideoAgreementSettingTypes.TextMatching]?.value,
      },
    };
  }

  parser(flow?: IFlow): ProductSettingsVideoAgreement {
    const { videoAgreement: { script } } = flow;
    return {
      [VideoAgreementSettingTypes.Script]: {
        value: flow?.videoAgreement?.[VideoAgreementSettingTypes.Script],
        error: validateScript(script),
      },
      [VideoAgreementSettingTypes.Language]: {
        value: flow?.videoAgreement?.[VideoAgreementSettingTypes.Language],
      },
      [VideoAgreementSettingTypes.TextMatching]: {
        value: flow?.videoAgreement?.[VideoAgreementSettingTypes.TextMatching],
      },
    };
  }

  onAdd(): Partial<IFlow> {
    return {
      videoAgreement: {
        script: '',
        textMatching: 70,
        language: defaultLanguageId,
      },
    };
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    return {
      verificationPatterns: {
        [VerificationPatternTypes.VideoAgreementValidation]: false,
      },
      videoAgreement: {
        script: '',
        textMatching: 70,
        language: 'en-US',
      },
    };
  }

  isInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.VideoAgreementValidation];
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const step = verification.steps.find((dataStep) => dataStep.id === VerificationPatternTypes.VideoAgreementValidation);
    return Boolean(step?.data?.errors?.length);
  }

  getVerification(verification: VerificationResponse): IVideoAgreementStep {
    // need to change it VideoAgreementValidation when backend change it in their side
    const videoAgreementStep = verification?.steps.find((step) => step?.id === VerificationPatternTypes.VideoAgreementValidation);
    if (!videoAgreementStep) {
      return null;
    }
    return {
      ...videoAgreementStep,
      data: {
        error: videoAgreementStep.error,
        isRunning: videoAgreementStep.status < 200,
        ...videoAgreementStep.data,
      },
    };
  }
}
