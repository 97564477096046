import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  fullSizeButton: {
    minHeight: 50,
    fontSize: 14,
    fontWeight: 'bold',
    transition: '.2s all ease-in-out',
    '&.Mui-disabled': {
      opacity: 0.3,
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
  divider: {
    backgroundColor: theme.palette.common.black7opacity,
  },
  button: {
    width: 30,
    height: 30,
    padding: 0,
  },
  buttonEdit: {
    color: theme.palette.common.lightblue,
    backgroundColor: theme.palette.common.whiteblue,
  },
  buttonTrash: {
    marginLeft: 10,
    color: theme.palette.common.red,
    backgroundColor: theme.palette.common.redopacity,
  },
  colorGrey: {
    color: theme.palette.common.black75,
  },
  colorGreen: {
    color: theme.palette.common.green,
  },
  colorRed: {
    color: theme.palette.common.red,
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },
}));
