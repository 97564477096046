import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFlowBuilderChangeableFlow, selectFlowBuilderProductsInGraph, FlowSettingsModel, IRetryBlockerConfig } from 'apps/flowBuilder';

export const useFlowSettings = (currentPostponedTimeout) => {
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraph);
  const { id: flowId, name: flowName, verificationPatterns, policyInterval, digitalSignature, redirectOptions, allowedRedirectionUrls, retryBlockerConfig } = useSelector(selectFlowBuilderChangeableFlow);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState<boolean>(false);
  const [policyIntervalError, setPolicyIntervalError] = useState<string | null>(null);
  const [newFlowName, setNewFlowName] = useState<string>('');
  const [isInit, setIsInit] = useState<boolean>(true);
  const [oldSettings, setOldSettings] = useState<FlowSettingsModel>(null);
  const [postponedTimeout, setPostponedTimeout] = useState<string>(currentPostponedTimeout);
  const [retryVerificationBlockerConfig, setRetryVerificationBlockerConfig] = useState<IRetryBlockerConfig>(retryBlockerConfig);
  return {
    postponedTimeout,
    setPostponedTimeout,
    flowId,
    flowName,
    productsInGraph,
    isEditable,
    setIsEditable,
    isSaveButtonLoading,
    setIsSaveButtonLoading,
    policyIntervalError,
    setPolicyIntervalError,
    newFlowName,
    setNewFlowName,
    isInit,
    setIsInit,
    oldSettings,
    setOldSettings,
    verificationPatterns,
    policyInterval,
    digitalSignature,
    redirectOptions,
    allowedRedirectionUrls,
    retryBlockerConfig,
    retryVerificationBlockerConfig,
    setRetryVerificationBlockerConfig,
  };
};
