import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import classNames from 'classnames';
import { useFormatMessage } from 'apps/intl';
import { EmailRiskValidationStepData } from 'models/EmailCheck.model';
import { useStyles } from './EmailVariables.style';
import { EmailRiskVariablesFilterTypes, EmailRiskVariablesMap, EmailRiskVariablesOrder, EmailRiskVariablesWeightTypes } from '../../models/EmailRisk.model';

export function EmailVariables({ data }: { data: EmailRiskValidationStepData }) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [order, setOrder] = useState<EmailRiskVariablesOrder>(EmailRiskVariablesOrder.ASC);
  const [filter, setFilter] = useState<EmailRiskVariablesFilterTypes>(EmailRiskVariablesFilterTypes.ALL);
  const onHandleOrder = () => setOrder((orderType) => (orderType === EmailRiskVariablesOrder.ASC ? EmailRiskVariablesOrder.DESC : EmailRiskVariablesOrder.ASC));
  const list = Object.entries(EmailRiskVariablesMap)
    .filter(([_, item]) => (filter === EmailRiskVariablesFilterTypes.ALL || filter === item.filterType))
    .sort(([_, itemA], [__, itemB]) => (order === EmailRiskVariablesOrder.ASC ? itemA.weight - itemB.weight : itemB.weight - itemA.weight));

  return (
    <Grid>
      <Box className={classes.filter}>
        <Typography className={classes.filterTitle}>{formatMessage('EmailRisk.filter.title')}</Typography>
        <Typography onClick={() => setFilter(EmailRiskVariablesFilterTypes.ALL)} className={classNames(classes.filterItem, { [classes.filterItemActive]: filter === EmailRiskVariablesFilterTypes.ALL })}>{formatMessage('EmailRisk.filter.item.all')}</Typography>
        <Typography onClick={() => setFilter(EmailRiskVariablesFilterTypes.RISK)} className={classNames(classes.filterItem, { [classes.filterItemActive]: filter === EmailRiskVariablesFilterTypes.RISK })}>{formatMessage('EmailRisk.filter.item.risk')}</Typography>
        <Typography onClick={() => setFilter(EmailRiskVariablesFilterTypes.DELIVERABILITY)} className={classNames(classes.filterItem, { [classes.filterItemActive]: filter === EmailRiskVariablesFilterTypes.DELIVERABILITY })}>{formatMessage('EmailRisk.filter.item.deliverability')}</Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classNames(classes.tableCell, classes.tableHeadCell)}>{formatMessage('EmailRisk.table.title.signal')}</TableCell>
                <TableCell className={classNames(classes.tableCell, classes.tableHeadCell)}>{formatMessage('EmailRisk.table.title.value')}</TableCell>
                <TableCell className={classNames(classes.tableCell, classes.tableHeadCell)}>{formatMessage('EmailRisk.table.title.description')}</TableCell>
                <TableCell className={classNames(classes.tableCell, classes.tableHeadCell)}>
                  <Box className={classes.sort}>
                    <Box>{formatMessage('EmailRisk.table.title.impact')}</Box>
                    <Box onClick={onHandleOrder} className={classes.sortIcon}>
                      {order === EmailRiskVariablesOrder.ASC ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map(([key, item]) => (
                <TableRow key={key}>
                  <TableCell className={classes.tableCell}>
                    <Box className={classNames(classes.rowItem, 'fs-exclude')}>
                      <Typography>{formatMessage(`EmailRisk.table.signal.${key}.title`)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box className={classNames(classes.rowItem, 'fs-exclude')}>
                      <Typography>{typeof data[key] === 'object' ? data[key].human : String(data[key])}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box className={classNames(classes.rowItem, 'fs-exclude')}>
                      <Typography>{formatMessage(`EmailRisk.table.signal.${key}.description`)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box className={classNames(classes.rowItem, 'fs-exclude')}>
                      <Typography className={classNames(classes.weight, {
                        [classes.weightLow]: EmailRiskVariablesWeightTypes.LOW === item.weight,
                        [classes.weightMedium]: EmailRiskVariablesWeightTypes.MEDIUM === item.weight,
                        [classes.weightHeavy]: EmailRiskVariablesWeightTypes.HEADVY === item.weight,
                        [classes.weightVeryHeavy]: EmailRiskVariablesWeightTypes.VERYHEAVY === item.weight,
                      })}
                      >
                        {formatMessage(`EmailRisk.table.weight.${item.weight}`)}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
}
