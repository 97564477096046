import React, { useCallback } from 'react';
import { useOverlay } from 'apps/overlay';
import { useDispatch, useSelector } from 'react-redux';
import { selectFlowBuilderHaveUnsavedChanges, loadAndApplyTemplateToMetamap } from 'apps/flowBuilder';
import { notification } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { CustomAppcuesActions, appcues } from 'apps/Appcues';
import { TemplatesModal } from '../TemplatesModal/TemplatesModal';
import { TemplateSelectAttemptModal } from '../../TemplateSelectAttemptModal/TemplateSelectAttemptModal';
import { Button, Icon } from 'lib/UI';

export const TemplatesButton = () => {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const [createOverlay, closeOverlay] = useOverlay();
  const haveUnsavedChanges = useSelector<any, boolean>(selectFlowBuilderHaveUnsavedChanges);

  const handleTemplateCardClick = useCallback(async (templateId: string) => {
    try {
      await dispatch(loadAndApplyTemplateToMetamap(templateId));
      closeOverlay();
    } catch {
      notification.error(formatMessage('Error.common'));
    }
  }, [dispatch, closeOverlay, formatMessage]);

  const handleTemplatesContinueButtonClick = useCallback(() => {
    closeOverlay();
    createOverlay(<TemplatesModal handleCardClick={handleTemplateCardClick} />);
  }, [closeOverlay, createOverlay, handleTemplateCardClick]);

  const handleTemplatesButtonClick = useCallback(() => {
    appcues.action(CustomAppcuesActions.ClickedFlowTemplatesButton);
    if (haveUnsavedChanges) {
      createOverlay(<TemplateSelectAttemptModal handleContinue={handleTemplatesContinueButtonClick} closeOverlay={closeOverlay} />);
    } else {
      createOverlay(<TemplatesModal handleCardClick={handleTemplateCardClick} />);
    }
  }, [closeOverlay, createOverlay, handleTemplateCardClick, haveUnsavedChanges, handleTemplatesContinueButtonClick]);

  return (
    <Button
      id="flow-templates"
      type="secondary"
      size="large"
      onClick={handleTemplatesButtonClick}
    >
      <Icon name="stack" />
      {formatMessage('Templates.builder.viewTemplates')}
    </Button>
  );
};
