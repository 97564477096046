import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormatMessage } from 'apps/intl';
import { FiChevronDown } from 'react-icons/fi';
import { useStyles } from './CustomDocumentBasicInfoSelect.styles';
import { CustomDocumentPageTypes } from '../../models/CustomDocument.model';

export function CustomDocumentBasicInfoSelect({ numberOfPages, handleUpdateNumberOfPages }: {
  numberOfPages: CustomDocumentPageTypes;
  handleUpdateNumberOfPages: (value: CustomDocumentPageTypes) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  return (
    <Select
      className={classes.select}
      disableUnderline
      onChange={({ target: { value } }) => handleUpdateNumberOfPages(value as CustomDocumentPageTypes)}
      value={numberOfPages}
      IconComponent={FiChevronDown}
    >
      <MenuItem value={CustomDocumentPageTypes.One}>
        {formatMessage(`CustomDocuments.settings.numberOfPages.${CustomDocumentPageTypes.One}`)}
      </MenuItem>
      <MenuItem value={CustomDocumentPageTypes.Two}>
        {formatMessage(`CustomDocuments.settings.numberOfPages.${CustomDocumentPageTypes.Two}`)}
      </MenuItem>
      <MenuItem value={CustomDocumentPageTypes.Many}>
        {formatMessage(`CustomDocuments.settings.numberOfPages.${CustomDocumentPageTypes.Many}`)}
      </MenuItem>
    </Select>
  );
}
