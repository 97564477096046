import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'lib/debounce.hook';
import { FiSearch, FiX } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import { KeyboardKeys } from 'models/Keyboard.model';
import { IconButtonSearch, InputAdornmentSearch, TextFieldSearch } from './SearchFilter.styles';

export function SearchFilter({ onSetFilter }: {
  onSetFilter: (filter: any) => any;
}) {
  const formatMessage = useFormatMessage();
  const debounced = useDebounce();
  const [search, setSearch] = useState<string>('');
  const [adornment, setAdornment] = useState(null);

  const handleClear = useCallback(() => {
    setSearch('');
    debounced(() => onSetFilter(''));
  }, [debounced, onSetFilter]);

  const handleSearchChange = (e: React.ChangeEvent & { target: HTMLInputElement | HTMLTextAreaElement }) => {
    const value = e.target?.value;
    setSearch(value);
    debounced(() => onSetFilter(value));
  };

  const handleSearchExecute = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter) {
      onSetFilter(search);
    }
  };

  useEffect(() => {
    setAdornment(search.length === 0
      ? {
        endAdornment: (
          <InputAdornmentSearch position="end">
            <IconButtonSearch size="small">
              <FiSearch />
            </IconButtonSearch>
          </InputAdornmentSearch>
        ),
      }
      : {
        endAdornment: (
          <InputAdornmentSearch position="end">
            <IconButtonSearch size="small" onClick={handleClear}>
              <FiX />
            </IconButtonSearch>
          </InputAdornmentSearch>
        ),
      });
  }, [search, handleClear]);

  return (
    <TextFieldSearch
      value={search}
      fullWidth
      variant="outlined"
      placeholder={formatMessage('BankAccountData.country.search.placeholder')}
      onChange={handleSearchChange}
      onKeyDown={handleSearchExecute}
      InputProps={adornment}
    />
  );
}
