import { useFormatMessage } from 'apps/intl';
import { useRole } from 'lib/Auth0';
import React from 'react';
import { ChipText } from 'apps/ui';
import Button from '@material-ui/core/Button';
import { QATags } from 'models/QA.model';
import { FreshChatWidget } from 'apps/freshdesk';
import { useStyles } from './BannerExpiredSubscriptionStatus.styles';
import { BannerSubscriptionStatus } from '../BannerSubscriptionStatus/BannerSubscriptionStatus';

export function BannerExpiredSubscriptionStatus() {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { isOwner } = useRole();

  const onClickActivateAccount = () => {
    FreshChatWidget.createNewMessage(formatMessage('SubscriptionStatus.message.startActivationChat'));
  };
  return (
    <BannerSubscriptionStatus
      prefixElement={(
        <ChipText
          className={classes.chip}
          text={formatMessage('SubscriptionStatus.label.expired')}
          qaTag={QATags.SubscriptionStatus.PostTrial.ExpiredChip}
        />
      )}
      message={formatMessage('SubscriptionStatus.message.trialIsOver')}
    >
      {isOwner && (
        <Button
          onClick={onClickActivateAccount}
          className={classes.activateButton}
          variant="contained"
          color="primary"
          data-qa={QATags.SubscriptionStatus.PostTrial.Activate}
        >
          {formatMessage('SubscriptionStatus.button.activatePlan')}
        </Button>
      )}
    </BannerSubscriptionStatus>
  );
}
