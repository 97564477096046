import { Box } from '@material-ui/core';
import React from 'react';
import { ReFacematch } from '../ReFacematch/ReFacematch';
import { IReverificationVerification } from '../../models/ReVerification.model';

export function ReVerificationVerification({ data }: {
  data: IReverificationVerification;
}) {
  if (!data.reVerification?.reFacematch) {
    return null;
  }

  return (
    <Box>
      <ReFacematch reFacematch={data.reVerification.reFacematch} identity={data.identity} />
    </Box>
  );
}
