import { IFlow } from 'models/Flow.model';
import { Loadable } from 'models/Loadable.model';
import { ProductTypes } from 'models/Product.model';
import { IPreviewFlow } from '../models/FlowBuilder.model';
import { IFlowAuditEvent } from '../models/FlowAudit.model';

export const FLOW_BUILDER_STORE_KEY = 'FlowBuilder';

export enum SliceNameTypes {
  ProductsInGraph = 'productsInGraph',
  ChangeableFlow = 'changeableFlow',
  PreviewFlow = 'previewFlow',
  FlowHistory = 'flowHistory',
  HistoryEntriesCount = 'historyEntriesCount',
}

export enum FlowBuilderActionGroups {
  ProductsInGraph = 'PRODUCTS_IN_GRAPH',
  ChangeableFlow = 'CHANGEABLE_FLOW',
  PreviewFlow = 'PREVIEW_FLOW',
}

export interface FlowBuilderStore {
  [SliceNameTypes.ProductsInGraph]: Loadable<ProductTypes[]>;
  [SliceNameTypes.ChangeableFlow]: Loadable<IFlow>;
  [SliceNameTypes.PreviewFlow]: Loadable<IPreviewFlow>;
  [SliceNameTypes.FlowHistory]: Loadable<IFlowAuditEvent[]>;
  haveUnsavedChanges: boolean;
  isShowIntegration: boolean;
  isShowCustomization: boolean;
  selectedId: ProductTypes;
  historyEntriesCount: number;
}
