import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { IBankAccountDataStore, SliceNamesTypes } from './BankAccountData.store';

export const BANK_ACCOUNT_DATA_STORE_KEY = 'BANK_ACCOUNT_DATA';

const initialState: IBankAccountDataStore = {
  [SliceNamesTypes.ExcelFile]: LoadableAdapter.createState(null),
};

export default createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(SliceNamesTypes.ExcelFile),
});
