import cloneDeep from 'lodash/cloneDeep';
import { Product, ProductTypes, REUSAGE_PRODUCT_ORDER } from 'models/Product.model';
import { IFlow } from 'models/Flow.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { StepTypes } from 'models/Step.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export class ProductManagerService<S = IFlow, T = VerificationResponse> {
  readonly products: Product<S, T>[] = [];

  register(product: Product<S, T>): void {
    if (this.getProduct(product.id)) {
      return;
    }
    this.products.push(product);
  }

  getProduct(id: ProductTypes): Product<S, T> {
    return this.products.find((item) => item.id === id);
  }

  getProductsConfigurable(): Product<S, T>[] {
    return this.products
      .sort((a, b) => a.order - b.order)
      .filter((product) => product.isConfigurable);
  }

  sortProductTypes(productTypes: ProductTypes[]): ProductTypes[] {
    return productTypes.sort((a, b) => this.getProduct(a).order - this.getProduct(b).order);
  }

  sortReusageProductTypes(productTypes: ProductTypes[], changableFlow: IFlow): ProductTypes[] {
    const isBiometric = productTypes.find((productType) => productType === ProductTypes.BiometricVerification);
    const isLiveness = isBiometric && changableFlow?.verificationPatterns?.biometrics === StepTypes.LivenessMovement;
    const isNistLiveness = isBiometric && changableFlow?.verificationPatterns?.[VerificationPatternTypes.BiometricsVerification];
    const isEmail = productTypes.find((productType) => productType === ProductTypes.EmailCheck);
    const isPhone = productTypes.find((productType) => productType === ProductTypes.PhoneCheck);

    const getReorderedProduct = (reorderProductType: ProductTypes): Product<S, T> => {
      const product = cloneDeep(this.getProduct(reorderProductType));
      const reorderedProduct = {
        ...product,
        order: REUSAGE_PRODUCT_ORDER,
      };

      if (isLiveness || isNistLiveness) {
        return product.id === ProductTypes.BiometricVerification ? reorderedProduct : product;
      }

      if (isEmail && !isLiveness && !isNistLiveness) {
        return product.id === ProductTypes.EmailCheck ? reorderedProduct : product;
      }

      if (isPhone && !isEmail && !isLiveness && !isNistLiveness) {
        return product.id === ProductTypes.PhoneCheck ? reorderedProduct : product;
      }

      return product;
    };

    return productTypes.sort((a, b) => getReorderedProduct(a).order - getReorderedProduct(b).order);
  }
}

export const productManagerService = new ProductManagerService();
