import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { BasicWatchlistsActionTypes, IAmlStore, SliceNamesTypes, types } from './Aml.store';

const initialState: IAmlStore = {
  [SliceNamesTypes.Watchlists]: LoadableAdapter.createState([]),
  [SliceNamesTypes.WatchlistsGroups]: LoadableAdapter.createState([]),
  [SliceNamesTypes.CurrentWatchlist]: LoadableAdapter.createState(null),
  [SliceNamesTypes.WatchlistContent]: LoadableAdapter.createState(null),
  [SliceNamesTypes.CurrentWatchlistHeaders]: LoadableAdapter.createState(null),
  fileErrorType: null,
  [SliceNamesTypes.watchlistAdmin]: LoadableAdapter.createState(null),
};

export const amlReducer = createReducer(initialState, {
  ...LoadableAdapter.createHandlers(BasicWatchlistsActionTypes.Watchlists, SliceNamesTypes.Watchlists),
  ...LoadableAdapter.createHandlers(BasicWatchlistsActionTypes.WatchlistsGroups, SliceNamesTypes.WatchlistsGroups),
  ...LoadableAdapter.createHandlers(BasicWatchlistsActionTypes.CurrentWatchlist, SliceNamesTypes.CurrentWatchlist),
  ...LoadableAdapter.createHandlers(BasicWatchlistsActionTypes.WatchlistContent, SliceNamesTypes.WatchlistContent),
  ...LoadableAdapter.createHandlers(BasicWatchlistsActionTypes.CurrentWatchlistHeaders, SliceNamesTypes.CurrentWatchlistHeaders),
  ...LoadableAdapter.createHandlers(BasicWatchlistsActionTypes.WatchlistsAdmin, SliceNamesTypes.watchlistAdmin),

  [BasicWatchlistsActionTypes.CurrentWatchlistFileFailure](state, { payload }) {
    return {
      ...state,
      fileErrorType: payload,
    };
  },
  [types.BasicWatchlistsErrorsClear](state) {
    return {
      ...state,
      [SliceNamesTypes.Watchlists]: {
        ...state[SliceNamesTypes.Watchlists],
        error: null,
        isFailed: false,
      },
    };
  },
});
