export enum GaugeChartTypes {
  RiskAssessment = 'riskAssessment',
  Deliverability = 'deliverability',
}

export interface IGaugeChartParams {
  value: number;
  type: GaugeChartTypes;
  progressColor?: string;
  className?: string;
}
