import { Loadable } from 'models/Loadable.model';
import { IMeritBlocksAdditional } from '../models/MeritBlockAdditional.model';

export const MERIT_BLOCK_ADDITIONAL_STORE_KEY = 'MERIT_BLOCK_ADDITIONAL';

export enum SliceNameTypes {
  MeritBlocksAdditional = 'meritBlocksAdditional',
}

export interface IMeritBlockAdditionalStore {
  [SliceNameTypes.MeritBlocksAdditional]: Loadable<IMeritBlocksAdditional>;
}
