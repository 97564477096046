import { AnalyticsBrowser } from '@segment/analytics-next';
import { selectUserId } from 'apps/user/state/user.selectors';
import { isProduction, isSegmentDisabled, segmentkey } from 'models/Environment.model';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantCreatedAt, selectMerchantEmail, selectMerchantId, selectMerchantLegalName, selectMerchantName, selectMerchantSubscriptionStatusValue, selectUserCreatedAt, selectUserEmail, selectUserName, selectUserRole } from 'state/merchant/merchant.selectors';
import { getRole } from 'models/Collaborator.model';
import { isMetamapEmail } from 'lib/validations';
import Segment from '../segment/Segment';

const METAMAP_USER_ID = 'metamapUserID';

export function useSegmentInit() {
  useEffect(() => {
    const segmentInit = async () => {
      if (!segmentkey && !isSegmentDisabled) {
        return;
      }
      if (!Segment.getSegment()) {
        const analytics = AnalyticsBrowser.load({ writeKey: segmentkey });
        Segment.setSegment(analytics);
      }
    };
    segmentInit();
  }, []);
}

export function useSegment() {
  const segmentInitialized = useRef(false);
  const userId = useSelector(selectUserId);
  const userCreatedAt = useSelector(selectUserCreatedAt);
  const name = useSelector(selectUserName);
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);

  const merchantId = useSelector(selectMerchantId);
  const merchantDisplayName = useSelector(selectMerchantName);
  const merchantLegalName = useSelector(selectMerchantLegalName);
  const merchantCreatedAt = useSelector(selectMerchantCreatedAt);
  const merchantAccountStatus = useSelector(selectMerchantSubscriptionStatusValue);
  const ownerEmail = useSelector(selectMerchantEmail);

  useEffect(() => {
    const segmentLoad = async () => {
      if ((!segmentkey && !isSegmentDisabled) || !merchantId || !userId || !ownerEmail) {
        return;
      }
      const storedUserID = localStorage.getItem(METAMAP_USER_ID);
      const isNewUser = storedUserID ? storedUserID !== userId : true;
      if (!segmentInitialized.current && isNewUser && Segment.analytics && (!isMetamapEmail(ownerEmail) || !isProduction)) {
        Segment.analytics.identify(userId, {
          createdAt: userCreatedAt,
          name,
          role: getRole(userRole),
          uniqueId: userId,
          email: userEmail,
        });
        Segment.analytics.group(merchantId, {
          merchantCreatedAt,
          merchantDisplayName: merchantDisplayName || '',
          merchantId,
          merchantLegalName: merchantLegalName || '',
          merchantPlan: merchantAccountStatus,
        });
        segmentInitialized.current = true;
      }
      localStorage.setItem(METAMAP_USER_ID, userId);
    };

    segmentLoad();
  }, [userId, userCreatedAt, name, userRole, merchantId, merchantCreatedAt, merchantDisplayName, merchantLegalName, merchantAccountStatus, userEmail, ownerEmail]);
}
