import { createSelector } from 'reselect';
import { ICustomField, IMapping, ISelectOptions, IStaticTextParams } from 'models/CustomField.model';
import { CUSTOM_FIELD_STORE_KEY, ICustomFieldStore, SliceNameTypes } from './CustomField.store';
import { CustomFieldModalTypes } from '../models/CustomField.model';

export const selectCustomFieldStore = (state: {CUSTOM_FIELD_STORE_KEY: ICustomFieldStore}): ICustomFieldStore => state[CUSTOM_FIELD_STORE_KEY];

export const selectCustomFieldModalType = createSelector<[typeof selectCustomFieldStore], CustomFieldModalTypes>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldModalType].value,
);

export const selectCustomFieldEditedField = createSelector<[typeof selectCustomFieldStore], ICustomField | null>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldEditedField].value,
);

export const selectCustomFieldEditedStaticTextParams = createSelector<[typeof selectCustomFieldEditedField], IStaticTextParams>(
  selectCustomFieldEditedField,
  (field) => field.atomicFieldParams.staticTextParams,
);

export const selectCustomFieldEditedMapping = createSelector<[typeof selectCustomFieldEditedField], IMapping>(
  selectCustomFieldEditedField,
  (field) => field.atomicFieldParams.mapping,
);

export const selectCustomFieldEditedSelectOptions = createSelector<[typeof selectCustomFieldEditedField], ISelectOptions[]>(
  selectCustomFieldEditedField,
  (field) => field.atomicFieldParams.selectOptions,
);

export const selectCustomFieldEditedParent = createSelector<[typeof selectCustomFieldStore], string | null>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldEditedParent].value,
);

export const selectCustomFieldEditedSystemName = createSelector<[typeof selectCustomFieldStore], string | null>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldEditedSystemName].value,
);

export const selectCustomFieldListFields = createSelector<[typeof selectCustomFieldStore], ICustomField[]>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldListFields].value,
);

export const selectCustomFieldFlattenListFields = createSelector<[typeof selectCustomFieldStore], ICustomField[]>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldFlattenListFields].value,
);

export const selectCustomFieldEditedIndex = createSelector<[typeof selectCustomFieldStore], number>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldEditedIndex].value,
);

export const selectCustomFieldUploadingThumbnail = createSelector<[typeof selectCustomFieldStore], boolean>(
  selectCustomFieldStore,
  (store) => store[SliceNameTypes.CustomFieldUploadingThumbnail].value,
);
