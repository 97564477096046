import { Action } from 'redux';

export enum ActionSubTypes {
  Request = 'REQUEST',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Updating = 'UPDATING',
  Clear = 'CLEAR',
}

export const storeAction = <T>(type: string) => (payload: T) => (dispatch) => dispatch({ type, payload });

export function createReducer<S, H>(initialState: S, handlers: H) {
  return function reducer(state = initialState, action: Action) {
    if (!Object.hasOwnProperty.call(handlers, action.type)) {
      return state;
    }
    return handlers[action.type](state, action);
  };
}

export type TypesSequences<T> = {
  [P in keyof T as `${Uncapitalize<string & P>}_${ActionSubTypes}`]: string;
};

export function createTypesSequences<T>(sliceNames: T): TypesSequences<T> {
  return Object.keys(sliceNames).reduce((result, key) => ({
    ...result,
    ...Object.values(ActionSubTypes).reduce((memo, actionType) => {
      const type = `${key[0].toLowerCase() + key.slice(1)}_${actionType}`;
      memo[type] = type;
      return memo;
    }, {}),
  }), {} as TypesSequences<T>);
}

// TODO: @ggrigorev remove deprecated
/**
 * @deprecated
 * useless type
 * use createTypesSequences function
 */
export type TypesSequence = {
  [key: string]: string;
};

// TODO: @ggrigorev remove deprecated
/**
 * @deprecated
 * use createTypesSequences for better types
 */
export function createTypesSequence<T>(baseName: T): TypesSequence {
  return Object.values(ActionSubTypes).reduce((memo, item) => {
    const type = `${baseName}_${item}`;
    memo[type] = type;
    return memo;
  }, {});
}
