import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React, { useCallback, useState } from 'react';
import { copyToClipboard } from 'lib/copyToClipboard';
import { useStyles } from './CopyTextToClipboard.styles';
import { ReactComponent as CopyLinkIcon } from '../../../flowBuilder/assets/icon-copy-link.svg';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';

export function CopyTextToClipboard({ children, text, isOverlay = false, withCopyText = false, withCopy = true, qa, tooltipText }: {
  children: React.ReactNode;
  text: string;
  isOverlay?: boolean;
  withCopy?: boolean;
  withCopyText?: boolean;
  qa?: string;
  tooltipText?: string;
}) {
  const classes = useStyles();
  const [isShow, setIsShow] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    copyToClipboard(text);
    setIsShow(true);
  }, [text]);

  return (
    <Box className={classes.root}>
      <Box flexGrow="0" className={isOverlay ? classes.overlayedText : classes.text}>
        {children}
      </Box>
      {text && (
        <Box ml="0.5rem" flexGrow="0" className={isOverlay ? classes.overlayed : null}>
          <InfoTooltip
            placement="bottom"
            arrowClassname={classes.tooltipArrow}
            title={tooltipText}
            isOpen={isShow}
          >
            {withCopy && (
            <IconButton
              size="small"
              className={classes.button}
              onClick={handleClick}
              onMouseOut={() => setIsShow(false)}
              data-qa={qa}
            >
              <CopyLinkIcon />
            </IconButton>
            )}
          </InfoTooltip>
        </Box>
      )}
    </Box>
  );
}
