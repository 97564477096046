import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormatMessage } from 'apps/intl';
import { Routes } from 'models/Router.model';
import { Modal } from 'apps/overlay';
import { useStyles } from './SessionExpiredModal.styles';

export function SessionExpiredModal() {
  const { logout } = useAuth0();
  const formatMessage = useFormatMessage();
  const styles = useStyles();

  const goToSigninPage = () => {
    logout({ returnTo: `${window.location.origin}${Routes.auth.signIn}` });
  };

  return (
    <Modal onClose={goToSigninPage} className={styles.modal}>
      <Box mb={2} color="common.black90">
        <Typography variant="h3">
          {formatMessage('auth.session.expired')}
        </Typography>
      </Box>
      <Box mb={4} color="common.black90">
        <Typography>
          {formatMessage('auth.session.expired.description')}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={goToSigninPage}
      >
        {formatMessage('routes.go-to.login')}
      </Button>
    </Modal>
  );
}
