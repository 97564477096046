import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 800,
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 21,
  },
  subtitle: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
    opacity: '70%',
  },
  columnTitle: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 14,
    opacity: '70%',
    display: 'inline-block',
  },
  cancelButton: {
    width: '48%',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.common.lightblue,
    textUnderlineOffset: '5px',
    color: theme.palette.common.lightblue,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  confirmButton: {
    width: '48%',
    backgroundColor: theme.palette.common.lightblue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
  },
}));
