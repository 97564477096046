import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { KeyboardEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { ICustomField } from 'models/CustomField.model';
import { InfoTooltip } from 'apps/ui';
import { REGEXP_BANNED_SYMBOLS_IN_FIELD_SYSTEM_NAME, REPLACEMENT_SYMBOL } from '../../../../models/CustomField.model';
import { updateCustomFieldEditedField } from '../../../../state/CustomField.actions';
import { selectCustomFieldEditedField } from '../../../../state/CustomField.selectors';
import { InfoIcon, TextFieldInput } from '../../CustomFieldModalConfigureAtomic.styles';

export function CustomFieldDisplayNameSetting({ handleKeyDown }: {
  handleKeyDown: KeyboardEventHandler;
}) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const selectedCustomField = useSelector<any, ICustomField>(selectCustomFieldEditedField);

  const handleUpdateField = ({ target: { value } }) => {
    dispatch(updateCustomFieldEditedField({ ...selectedCustomField, label: value, name: value.replace(REGEXP_BANNED_SYMBOLS_IN_FIELD_SYSTEM_NAME, REPLACEMENT_SYMBOL) }));
  };

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="subtitle2">
          {formatMessage('CustomField.settings.fieldDisplayName')}
          <InfoTooltip
            placement="right"
            title={formatMessage('CustomField.settings.displayName.tooltip')}
          >
            <InfoIcon />
          </InfoTooltip>
        </Typography>
      </Grid>
      <Grid item>
        <TextFieldInput
          fullWidth
          type="text"
          value={selectedCustomField.label}
          name="label"
          onChange={handleUpdateField}
          onKeyDown={handleKeyDown}
          placeholder={formatMessage('CustomField.settings.fieldDisplayName.placeholder')}
        />
      </Grid>
    </Grid>
  );
}
