import { ITemplateMetadata } from 'apps/Templates';
import { createTypesSequence, TypesSequence } from 'state/store.utils';
import { selectProductRegistered } from 'apps/Product';
import { flowBuilderProductListForTemplates, ICardsOptions } from '../model/SolutionCatalog.model';
import * as api from '../api/SolutionCatalog.client';
import { SolutionCatalogActionGroups } from './SolutionCatalog.store';

export const solutionCatalogTypes: TypesSequence = {
  ...createTypesSequence(SolutionCatalogActionGroups.AllTemplates),
};

export const loadTemplates = (filters: ITemplateMetadata[]) => async (dispatch, getState) => {
  const registered = selectProductRegistered(getState());
  dispatch({ type: solutionCatalogTypes.ALL_TEMPLATES_UPDATING });

  try {
    const response = await api.getTemplates(filters);
    const withMerits = response.data.map<ICardsOptions>((template) => ({ ...template, meritsList: flowBuilderProductListForTemplates(template.flow, registered) }));
    dispatch({ type: solutionCatalogTypes.ALL_TEMPLATES_SUCCESS, payload: withMerits, isReset: true });
  } catch (error) {
    dispatch({ type: solutionCatalogTypes.ALL_TEMPLATES_FAILURE });
  }
};
