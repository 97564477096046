import { createReducer } from 'state/store.utils';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { SliceNameTypes } from './verificationHooks.store';

const initialState = {
  [SliceNameTypes.Hooks]: LoadableAdapter.createState([]),
};

export default createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.Hooks),
});
