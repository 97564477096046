import { useSelector } from 'react-redux';
import { selectMerchantSubscriptionStatusValue } from 'state/merchant';
import { SubscriptionStatusTypes } from 'apps/SubscriptionStatus';

export function useMerchantPlan() {
  const subscriptionStatusValue = useSelector(selectMerchantSubscriptionStatusValue);

  return {
    isFreemium: subscriptionStatusValue === SubscriptionStatusTypes.Freemium,
    isFreeTrial: subscriptionStatusValue === SubscriptionStatusTypes.Trial,
  };
}
