import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { BusinessRegionsTypes, ICountriesSettings } from 'apps/documentVerification/models/DocumentVerification.model';
import { useFormatMessage } from 'apps/intl';
import { Country, IDocumetSubTypesWithTransalates } from 'models/Country.model';
import { FiInfo } from 'react-icons/fi';
import { DocumentSubTypesSideBar } from '../DocumentSubTypesSideBar/DocumentSubTypesSideBar';
import { SubTypeList } from '../SubTypeList/SubTypeList';
import { useStyles } from './DocumentSubTypesModalBody.styles';

export function DocumentSubTypesModalBody({ countries, selectedCountry, setSelectedCountry, selectedSubTypes, setSelectedSubTypes, countryWiseSubTypes, handleSearchChange }: {
  countries: Record<BusinessRegionsTypes, Country[]>;
  selectedCountry?: {
    country: string;
    name: string;
    documentSubtypes: IDocumetSubTypesWithTransalates[];
  };
  setSelectedCountry?: (value: {
    country: string;
    name: string;
    documentSubtypes: IDocumetSubTypesWithTransalates[];
  }) => void;
  selectedSubTypes: ICountriesSettings[];
  setSelectedSubTypes?: (value: ICountriesSettings[]) => void;
  countryWiseSubTypes: {
    country: string;
    name: string;
    documentSubtypes: IDocumetSubTypesWithTransalates[];
}[];
handleSearchChange: (query: string) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [searchValue, setSearchValue] = useState<string>('');
  const [curentCountrySelectedSubType, setCurentCountrySelectedSubType] = useState<undefined | number>();

  const handleCountrySelection = (country: string): void => {
    const selectedCountryDetails = countryWiseSubTypes.find(
      (data) => data.country === country,
    );
    setSelectedCountry(selectedCountryDetails);
  };

  const handleDeSelectAll = (): void => {
    const updatedCurrentSelectedSubTypes = { country: selectedCountry.country, documentSubtypes: [] };
    const updatedValue = selectedSubTypes.map((data) => (data.country === selectedCountry.country
      ? updatedCurrentSelectedSubTypes
      : data));
    setSelectedSubTypes(updatedValue);
  };

  const handleSelectAll = (): void => {
    const currentSelectedSubTypes = selectedSubTypes.find(
      (data) => data.country === selectedCountry.country,
    );
    let updatedCurrentSelectedSubTypes = { ...currentSelectedSubTypes };
    const allSubTypes = selectedCountry.documentSubtypes.map((data) => data.id);
    updatedCurrentSelectedSubTypes = { country: selectedCountry.country, documentSubtypes: [...allSubTypes] };
    const updatedValue = selectedSubTypes.map((data) => (data.country === selectedCountry.country
      ? updatedCurrentSelectedSubTypes
      : data));
    setSelectedSubTypes(updatedValue);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const query = event.target.value;
    setSearchValue(query);
    handleSearchChange(query);
  };

  const handleSearchReset = (): void => {
    setSearchValue('');
    handleSearchChange('');
  };

  useEffect(() => {
    if (selectedCountry?.country) {
      const currentSelectedSubTypes = selectedSubTypes.find(
        (data) => data.country === selectedCountry?.country,
      );
      const totalSelected = currentSelectedSubTypes?.documentSubtypes ? currentSelectedSubTypes.documentSubtypes.length : 0;
      setCurentCountrySelectedSubType(totalSelected);
    } else {
      setCurentCountrySelectedSubType(undefined);
    }
  }, [selectedSubTypes, selectedCountry]);

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item xs={5} className={classes.sidebar}>
        <Box width="100%" className={classes.sidebarContent}>
          <>
            <OutlinedInput
              className={classes.outlinedInput}
              fullWidth
              type="search"
              startAdornment={<SearchIcon />}
              placeholder={formatMessage('DocumentVerification.settings.description.subTypesSelection.searchCountries')}
              value={searchValue}
              onChange={handleSearch}
            />
          </>
          {selectedCountry?.country ? (
            <>
              {Object.keys(countries).map((region) => {
                const countriesArray: {country: string; noOfDocumentSubTypes: number}[] = countries[region].map(({ code, documentSubtypesWithTranslates }) => ({ country: code, noOfDocumentSubTypes: documentSubtypesWithTranslates.length }));
                return (<DocumentSubTypesSideBar key={region} countriesData={countriesArray} region={region} selectedCountry={selectedCountry.country} setSelectedCountry={handleCountrySelection} selectedSubTypes={selectedSubTypes} />);
              })}
            </>
          ) : (
            <Box m={4} textAlign="center">
              <Box>
                <Typography className={classes.searchNotFoundText}>{formatMessage('DocumentVerification.settings.description.subTypesSelection.searchNoMatch')}</Typography>
              </Box>
              <Button onClick={handleSearchReset} variant="text" color="primary" className={classes.selectionButton}>
                {formatMessage('DocumentVerification.settings.description.subTypesSelection.button.resetSearch')}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
      {selectedCountry?.country && (
        <Grid item xs={7} className={classes.content}>
          <Grid container direction="row" className={classes.wrapper}>
            <Grid item xs={6}>
              <Typography className={classes.selectedCountry}>
                {formatMessage(`Countries.${selectedCountry.country}`)}
                &nbsp;
                -
                &nbsp;
                {selectedCountry.documentSubtypes.length}
              &nbsp;
                {formatMessage('DocumentVerification.settings.description.subTypesSelection.available')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={handleDeSelectAll} variant="text" color="primary" className={classes.deselectionButton} disabled={curentCountrySelectedSubType === 0}>
                {formatMessage('DocumentVerification.settings.description.subTypesSelection.button.deSelectAll')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={handleSelectAll} variant="text" color="primary" className={classes.selectionButton} disabled={curentCountrySelectedSubType === selectedCountry.documentSubtypes.length}>
                {formatMessage('DocumentVerification.settings.description.subTypesSelection.button.selectAll')}
              </Button>
            </Grid>
          </Grid>
          <SubTypeList selectedSubTypes={selectedSubTypes} setSelectedSubTypes={setSelectedSubTypes} documentSubtypes={selectedCountry.documentSubtypes} selectedCountry={selectedCountry.country} />
          {curentCountrySelectedSubType === 0 && (
          <Box className={classes.alertWrapper} display="flex" flexDirection="row" p={1} alignItems="center">
            <FiInfo size={35} className={classes.alertIcon} />
            <Box ml={1} className={classes.alertText}>
              <Typography>
                {formatMessage('DocumentVerification.settings.description.subTypesSelection.allDeSelectedAlert', { messageValues: { country: selectedCountry.name } })}
              </Typography>
            </Box>
          </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
}
