import React, { useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { BackgroundCheckBaseOptionsListItemExtended, backgroundCheckConfigurations, backgroundCheckCountriesOrder, backgroundCheckParse, BackgroundCheckTypes, IBackgroundCheck } from 'models/BackgroundCheck.model';
import { useFormatMessage } from 'apps/intl';
import { IVerificationPatterns } from 'models/VerificationPatterns.model';
import { flagMap } from 'assets/flags';
import { ExtendedDescription } from 'apps/ui';
import { useSelector } from 'react-redux';
import { selectMerchantTags } from 'state/merchant';
import { useStyles } from './BackgroundCheckCountriesSettings.styles';

export function BackgroundCheckCountriesSettings({
  verificationPattern,
  onChange,
}: {
  verificationPattern: Partial<IVerificationPatterns>;
  onChange: (value: Partial<IVerificationPatterns>) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tags = useSelector(selectMerchantTags);
  const countryCheckList = useMemo(() => {
    const checkListForCountry = (country: string) => {
      const found = backgroundCheckConfigurations.find((item) => item.country === country);
      if (!found) {
        return null;
      }

      return backgroundCheckParse(found.checks, verificationPattern, tags);
    };

    return backgroundCheckCountriesOrder.map((country) => ({
      country,
      toggle: checkListForCountry(country),
    }));
  }, [tags, verificationPattern]);

  const handleCheckChange = (value: string | boolean, country: string): string | boolean => {
    if (typeof value === 'boolean') {
      return value;
    }

    if (value === BackgroundCheckTypes.None) {
      return country;
    }

    const countries = new Set([...value.split(',')]);

    if (countries.has(country)) {
      countries.delete(country);
    } else {
      countries.add(country);
    }

    return Array.from(countries)
      .join() || BackgroundCheckTypes.None;
  };

  const handleSwitch = useCallback((item: IBackgroundCheck<BackgroundCheckBaseOptionsListItemExtended>, country: string) => (_, checked) => {
    onChange({
      [item.id]: item.default === BackgroundCheckTypes.None
        ? handleCheckChange(item.value, country)
        : checked,
    });
  }, [onChange]);

  const handleExtendedDescriptionSwitch = useCallback((item: IBackgroundCheck<BackgroundCheckBaseOptionsListItemExtended>, value: string | boolean) => {
    onChange({ [item.id]: item.value === value ? item.default : value });
  }, [onChange]);

  const toggleValueCheck = (value: boolean | string, country: string): boolean => (typeof value === 'string' ? value?.includes(country) : !!value);

  return (
    <FormControl className={classes.control}>
      {countryCheckList.map(({
        country,
        toggle: countryToggle,
      }) => (
        <Box key={country} className={classes.wrapper} p={2.4} pr={1} pl={1} mt={1}>
          <Grid container alignItems="center" wrap="nowrap" className={classes.title}>
            <Box mr={1} className={classes.icon}>{flagMap[country]}</Box>
            <Box color="common.black90" fontWeight="bold">
              {formatMessage(`Countries.${country}`)}
            </Box>
          </Grid>
          {countryToggle.map((toggle, idx) => (toggle.options?.list?.length ? (
            <Box key={idx} className={classes.extendedDescription}>
              {toggle.options?.list?.map((checks) => (!checks.isDisabled && (
                <ExtendedDescription
                  key={checks.id}
                  title={formatMessage(checks.title)}
                  titleColor="common.black75"
                  postfix={(
                    <Switch
                      checked={checks.value === toggle.value}
                      onClick={() => handleExtendedDescriptionSwitch(toggle, checks.value)}
                      color="primary"
                    />
                  )}
                />
              )))}
            </Box>
          ) : (
            <Box key={idx} className={classes.check}>
              <Switch
                checked={toggleValueCheck(toggle.value, country)}
                onChange={handleSwitch(toggle, country)}
                color="primary"
              />
            </Box>
          )))}
        </Box>
      ))}
    </FormControl>
  );
}
