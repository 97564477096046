import { CountrySpecificCreditChecks, DocumentStepTypes } from 'models/Step.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { CountryCodeTypes } from 'models/Country.model';
import { IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';

export enum CreditCheckSettingTypes {
  CountriesCreditChecks = 'countriesCreditChecks',
}

export enum CreditChecksTypes {
  CreditDatabaseCheck = 'creditDatabaseCheck'
}

export const verificationPatternsCreditChecksDefault = {
  [VerificationPatternTypes.CreditArgentinianFidelitas]: false,
  [VerificationPatternTypes.CreditBrazilianSerasa]: false,
  [VerificationPatternTypes.CreditBrazilianSerasaChecks]: false,
  [VerificationPatternTypes.CreditMexicoCdc]: false,
  [VerificationPatternTypes.CreditColombiaTransunion]: false,
};

export const creditCheckCountriesOrder = [
  CountryCodeTypes.AR,
  CountryCodeTypes.BR,
  CountryCodeTypes.MX,
  CountryCodeTypes.CO,
];
// TODO delete disabled then
export interface CreditCheck {
  id: string;
  default: boolean;
  value?: boolean;
}

export interface CreditCheckConfiguration {
  country: CountryCodeTypes;
  checks: CreditCheck[];
}

export const CreditCheckConfigurations: CreditCheckConfiguration[] = [{
  country: CountryCodeTypes.AR,
  checks: [
    {
      id: DocumentStepTypes.CreditArgentinianFidelitas,
      default: false,
    },
  ],
},
{
  country: CountryCodeTypes.BR,
  checks: [
    {
      id: DocumentStepTypes.CreditBrazilianSerasa,
      default: false,
    },
    {
      id: DocumentStepTypes.CreditBrazilianSerasaChecks,
      default: false,
    },
  ],
},
{
  country: CountryCodeTypes.MX,
  checks: [
    {
      id: DocumentStepTypes.CreditMexicoCdc,
      default: false,
    },
  ],
},
{
  country: CountryCodeTypes.CO,
  checks: [
    {
      id: DocumentStepTypes.CreditColombiaTransunion,
      default: false,
    },
  ],
}];

export const CreditCheckRequiredProductFromSubStep = {
  [VerificationPatternTypes.CreditArgentinianFidelitas]: ProductTypes.DocumentVerification,
  [VerificationPatternTypes.CreditBrazilianSerasa]: ProductTypes.DocumentVerification,
  [VerificationPatternTypes.CreditBrazilianSerasaChecks]: ProductTypes.DocumentVerification,
  [VerificationPatternTypes.CreditColombiaTransunion]: ProductTypes.DocumentVerification,
};

export function creditCheckParse(list: CreditCheck[], pattern): CreditCheck[] {
  return list.map((item) => ({
    ...item,
    value: pattern[item.id] !== undefined ? pattern[item.id] : item.default,
  }));
}

export function creditIsInVerification(verification: VerificationResponse): boolean {
  const hasCreditDocumentsSteps = verification?.documents?.some((document) => {
    const creditChecksSteps = document?.steps.filter((step) => CountrySpecificCreditChecks.includes(step.id));
    return creditChecksSteps?.length > 0;
  });
  const creditChecksSteps = verification?.steps?.filter((step) => CountrySpecificCreditChecks.includes(step.id));
  return creditChecksSteps?.length > 0 || hasCreditDocumentsSteps;
}

// function to check whether the selected credit check bureau has its associated merit in the flow
export function creditCheckAssociatedMeritInFlow(flow: IFlow, productsInGraph?: ProductTypes[]): boolean {
  return Object.entries(flow?.verificationPatterns)
    .filter(([verificationPattern, isPatternActive]) => {
      const isCreditCheckVerificationPattern = CountrySpecificCreditChecks.includes(verificationPattern as VerificationPatternTypes);
      return isCreditCheckVerificationPattern && isPatternActive;
    })
    .every(([verificationPattern]) => {
      const associatedProductExistsInFlow = productsInGraph.includes(CreditCheckRequiredProductFromSubStep[verificationPattern]);
      return associatedProductExistsInFlow || !CreditCheckRequiredProductFromSubStep[verificationPattern];
    });
}

export const creditMexicoCdcCursorRotation = {
  start: 300,
  end: 850,
};

export const creditColombiaTransunionCursorRotation = {
  start: 100,
  end: 999,
};
