import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useFormatMessage } from 'apps/intl';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { QATags } from 'models/QA.model';
import React, { useState, useEffect, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useStyles, PREVIEW_HEIGHT } from './FontSelect.style';
import { Fonts } from '../../models/Fonts.model';
import { fontLoader } from '../../services/FontLoader.service';
import 'react-perfect-scrollbar/dist/css/styles.css';

function Option({ fontFamily }: {
  fontFamily: string;
}) {
  useEffect(() => {
    fontLoader.load(fontFamily);
  }, [fontFamily]);

  return (
    <Box
      component="span"
      pl={1}
      pr={1}
      style={{ fontFamily }}
    >
      {fontFamily}
    </Box>
  );
}

export function FontSelect({ value, defaultValue, onChange }: {
  value: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}) {
  const formatMessage = useFormatMessage();
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayedCount, setDisplayedCount] = useState<number>(30);
  const [search, setSearch] = useState<string>('');
  const [fonts, setFonts] = useState<string[]>([...Fonts]);
  const displayedFonts = useMemo<string[]>(() => fonts.slice(0, displayedCount), [fonts, displayedCount]);

  const handleSearch = debounce((searchValue?: string) => {
    const normalizedSearch = searchValue?.toLowerCase().trim() ?? null;
    setFonts(Fonts.filter((item) => (normalizedSearch ? item.toLowerCase().includes(normalizedSearch) : true)));
  }, 300);

  useEffect(() => () => {
    handleSearch.cancel();
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [value]);

  return (
    <Box>
      <Select
        fullWidth
        displayEmpty
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        renderValue={() => {
          if (value) {
            return (
              <Option fontFamily={value} />
            );
          }

          if (defaultValue) {
            return (
              <Option fontFamily={defaultValue} />
            );
          }

          return (
            <Box
              component="span"
              className={styles.selectPlaceholder}
            >
              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Typography.FontSelectPlaceholder')}
            </Box>
          );
        }}
        variant="outlined"
        open={isOpen}
        onClick={() => setIsOpen(true)}
        data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.cta.fontSelect}
      >
        <div>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div>
              <Box p={1}>
                <TextField
                  fullWidth
                  className={styles.search}
                  variant="outlined"
                  value={search}
                  placeholder={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Typography.FindFontPlaceholder')}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    if (event.target.value.length === 0) {
                      setDisplayedCount(20);
                      handleSearch();
                    } else if (event.target.value.length > 3) {
                      handleSearch(event.target.value);
                    }
                  }}
                  data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.cta.fontSelectSearch}
                />
              </Box>
              <Box
                width="100%"
                className={styles.previewContainer}
              >
                <PerfectScrollbar onYReachEnd={(container) => (container.scrollHeight > PREVIEW_HEIGHT) && setDisplayedCount((prev) => prev + 1)}>
                  {displayedFonts.map((item) => (
                    <Box
                      key={item}
                      width="100%"
                      p={1}
                      className={classNames(styles.option, { [styles.selected]: value === item })}
                      onClick={() => onChange(item)}
                      data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.cta.formSelectOption}
                    >
                      <Option fontFamily={item} />
                    </Box>
                  ))}
                  {(displayedFonts.length === 0) && (
                    <Box
                      width="100%"
                      height="100%"
                      p={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      className={styles.placeholder}
                    >
                      {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Typography.NoFontsFound')}
                    </Box>
                  )}
                </PerfectScrollbar>
              </Box>
            </div>
          </ClickAwayListener>
        </div>
      </Select>
    </Box>
  );
}
