import { Box, Grid } from '@material-ui/core';
import { CheckStepDetails, CheckBarExpandable } from 'apps/checks';
import { BiometricStep } from 'models/Biometric.model';
import React from 'react';
import { useStyles } from './LivenessPremium.styles';
import { useFormatMessage } from 'apps/intl';

export function LivenessPremium({ steps }: {
  steps: BiometricStep[];
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Box className={classes.status}>
      <Grid container spacing={1} className={classes.result}>
        {steps.map((step) => (
          <Grid key={step.id} item xs={12}>
            <CheckBarExpandable step={step} title={`SecurityCheckStep.${step.id}.${(step.status === 200) ? 'success' : 'failure'}`}>
              <CheckStepDetails step={step} />
            </CheckBarExpandable>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
