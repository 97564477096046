import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  confirmButton: {
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
  },
  button: {
    margin: '5px 0',
  },
}));
