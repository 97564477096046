import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CountryCodeTypes } from 'models/Country.model';
import React, { useState } from 'react';
import { FlattenVerificationPatternConfigType } from 'models/VerificationPatternsConfigs.model';
import { GovernmentCheckSettingTypes, ProductSettingsGovCheck } from '../../models/GovCheckMerit.model';
import { GovCheckCountryHeader } from '../GovCheckCountryHeader/GovCheckCountryHeader';
import { GovCheckMeritPanelFooter } from '../GovCheckMeritPanelFooter/GovCheckMeritPanelFooter';
import { GovCheckMeritPanelGovCheck } from '../GovCheckMeritPanelGovCheck/GovCheckMeritPanelGovCheck';
import { useStyles } from './GovCheckMeritPanel.styles';

export function GovCheckMeritPanel({ settings, handleUpdate, selectedVerificationPatternsConfig, country, handleOpenModal }: {
  handleUpdate: (newData: {settingId: GovernmentCheckSettingTypes; value: unknown}[]) => void;
  selectedVerificationPatternsConfig: FlattenVerificationPatternConfigType[];
  country: CountryCodeTypes;
  handleOpenModal: (country?: CountryCodeTypes) => void;
  settings: ProductSettingsGovCheck;
}) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleChangeExpanded = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const onClick = () => {
    handleOpenModal(country);
  };

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
    >
      <Button
        fullWidth
        onClick={handleChangeExpanded}
      >
        <GovCheckCountryHeader
          country={country}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </GovCheckCountryHeader>
      </Button>
      {isExpanded && (
        <>
          {selectedVerificationPatternsConfig.map((patternConfig) => {
            const govCheck = Object.values(patternConfig)[0];
            return (
              <GovCheckMeritPanelGovCheck
                key={govCheck.patternName}
                settings={settings}
                handleUpdate={handleUpdate}
                govCheck={govCheck}
              />
            );
          })}
        </>
      )}
    </Box>
  );
}
