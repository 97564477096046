import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useFormatMessage } from 'apps/intl';
import { productManagerService } from 'apps/Product';
import { ITemplateMetadata, MetadataTypes } from 'apps/Templates';
import classNames from 'classnames';
import { ProductTypes } from 'models/Product.model';
import React, { useMemo, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { TemplateCountryTags } from '../TemplateCountryTags/TemplateCountryTags';
import { useStyles } from './TemplateCard.styles';

const MAX_NUMBER_OF_MERIT_BLOCK_ICONS = 4;
const MAX_NUMBER_OF_COUNTRY_TAGS = 4;

export function TemplateCard({ id, onSelectTemplate, onOpenTemplateDetails, meritsList, countryFilters, metadata, flowName }: {
  id: string;
  onSelectTemplate: () => void;
  onOpenTemplateDetails: () => void;
  meritsList?: ProductTypes[];
  countryFilters: ITemplateMetadata[];
  metadata: ITemplateMetadata[];
  flowName: string;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const slicedMeritsList = useMemo<ProductTypes[]>(() => meritsList.slice(0, MAX_NUMBER_OF_MERIT_BLOCK_ICONS), [meritsList]);
  const localizeKey: string = flowName.replace(/\s/g, '');
  const countryWithWorldPartMetadatas = useMemo<ITemplateMetadata[]>(() => metadata.filter((templateMetadata) => templateMetadata.type === MetadataTypes.Country && templateMetadata?.data?.worldPart), [metadata]);

  return (
    <Box role="listitem" className={classes.templateCard}>
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={classes.interactiveContainer}
      >
        {!isHovered ? (
          <Box className={classes.iconsContainer}>
            {slicedMeritsList.map((merit, index) => {
              if (index < MAX_NUMBER_OF_MERIT_BLOCK_ICONS - 1 || meritsList.length === MAX_NUMBER_OF_MERIT_BLOCK_ICONS) {
                return (
                  <Box key={merit} className={classes.iconContainer}>
                    <Box className={classes.iconBorder}>
                      <Box className={classes.icon}>
                        {productManagerService.getProduct(merit).icon}
                      </Box>
                    </Box>
                    <Box className={classes.label} textAlign="center">
                      {formatMessage(productManagerService.getProduct(merit).getTitle())}
                    </Box>
                  </Box>
                );
              }
              return (
                <Box key="otherMerits" className={classes.iconContainer}>
                  <Box className={classNames(classes.iconBorder, classes.otherMeritsIcon)}>
                    <FiMoreHorizontal size={24} />
                  </Box>
                  <Box className={classes.label}>
                    {formatMessage('TemplateCard.label.otherMerits', {
                      messageValues: {
                        number: (meritsList.length - MAX_NUMBER_OF_MERIT_BLOCK_ICONS) + 1,
                      },
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box className={classes.hoveredContainer}>
            <Button
              onClick={onSelectTemplate}
              className={`${classes.button} ${classes.buttonFilled}`}
              data-analytics-templateId={id}
              color="primary"
            >
              {formatMessage('TemplateCard.button.select')}
            </Button>
            <Button
              onClick={onOpenTemplateDetails}
              className={`${classes.button} ${classes.buttonOutlined}`}
              color="primary"
            >
              {formatMessage('TemplateCard.button.details')}
            </Button>
          </Box>
        )}
      </Box>
      <Box className={classes.infoContainer}>
        <Box className={classes.textContainer}>
          <Box className={classes.title}>{formatMessage(`Template.${localizeKey}.name`)}</Box>
          <Box className={classes.description}>{formatMessage(`Template.${localizeKey}.description`)}</Box>
        </Box>
        <TemplateCountryTags
          maxNumTags={MAX_NUMBER_OF_COUNTRY_TAGS}
          countryMetadatas={countryWithWorldPartMetadatas}
          countryFilters={countryFilters}
        />
      </Box>
    </Box>
  );
}
