import { useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { AppPaths } from 'apps/routing/AppPaths';
import { history } from '../../routing/history/history';

export function useSentry() {
  useEffect(() => {
    const sentryLoad = async () => {
      if (!process.env.REACT_APP_SENTRY_DSN) {
        return;
      }

      const Sentry = await import('@sentry/react');
      const { BrowserTracing } = await import('@sentry/tracing');

      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        release: process.env.REACT_APP_VERSION || 'notFound',
        debug: Boolean(process.env.REACT_APP_SENTRY_DEBUG),
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, AppPaths, matchPath),
            tracingOrigins: [
              'localhost',
              process.env.REACT_APP_API_URL,
              process.env.REACT_APP_SIGNUP_URL,
              process.env.REACT_APP_MATI_BUTTON_URL,
            ],
          }),
        ],
        tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE),
        allowUrls: [
          /https:\/\/dashboard\.(getmati|metamap)\.com/,
        ],
        ingoreErros: [
          'Failed to fetch',
          /Request failed with status code d+/,
          'Network Error',
          'Request aborted',
          'timeout exceeded',
          /Loading chunk d+ failed/,
        ],
      });
    };

    sentryLoad();
  });
}
