import { Loadable } from 'models/Loadable.model';
import { INotificationsSummary, INotifications } from '../models/Notifications.model';

export const NOTIFICATIONS_STORE_KEY = 'NOTIFICATIONS';

export enum SliceNameTypes {
  NotificationsSummary = 'notificationsSummary',
  Notifications = 'notifications',
}

export interface INotificationsStore {
  [SliceNameTypes.NotificationsSummary]: Loadable<INotificationsSummary>;
  [SliceNameTypes.Notifications]: Loadable<INotifications>;
}
