import { useAuth0 } from '@auth0/auth0-react';
import { auth0DatabaseUserIdPrefix } from '../models/Auth0.model';

export function useAuth0UserInformation() {
  const { user } = useAuth0();

  const isDatabaseConnection = user?.sub?.split('|')[0] === auth0DatabaseUserIdPrefix;
  const userProfile = {
    id: user.sub,
    isDatabaseConnection,
    email: {
      address: user.address,
      verified: user.email_verified,
    },
    name: {
      firstName: user.given_name,
      lastName: user.family_name,
    },
    picture: user.picture,
    organizationId: user.org_id,
  };
  return { userProfile };
}
