import React from 'react';
import { BackgroundCheckStatusesTypes } from 'models/BackgroundCheck.model';
import { ReactComponent as GreenShield } from 'assets/step-greenShield.svg';
import { ReactComponent as RedShield } from 'assets/step-redShield.svg';
import { ReactComponent as ApprovedSVG } from 'assets/icon-approved.svg';
import { ReactComponent as LowRiskSVG } from 'assets/icon-low-risk.svg';
import { ReactComponent as HighRiskSVG } from 'assets/icon-high-risk.svg';

const iconsMap = {
  [BackgroundCheckStatusesTypes.Accepted]: GreenShield,
  [BackgroundCheckStatusesTypes.Rejected]: RedShield,
  [BackgroundCheckStatusesTypes.Approved]: ApprovedSVG,
  [BackgroundCheckStatusesTypes.LowRisk]: LowRiskSVG,
  [BackgroundCheckStatusesTypes.HighRisk]: HighRiskSVG,
};

export const VerificationStatusIcon = ({
  type,
  ...rest
}: { type: BackgroundCheckStatusesTypes }) => {
  const IconComponent = iconsMap[type] || (() => null);
  return <IconComponent {...rest} />;
};
