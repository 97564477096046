import React from 'react';
import dayjs from 'dayjs';
import {
  BackgroundCheckDateFormatsTypes,
  backgroundCheckDisplayOptions,
  IBackgroundCheckStepData,
} from 'models/BackgroundCheck.model';
import { DateFormatTypes } from 'lib/date';
import { useFormatMessage } from 'apps/intl';
import { StepIds } from 'models/Step.model';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { SummaryUserInfoItem } from './components/SummaryUserInfoItem/SummaryUserInfoItem';

const nameFields = new Set([
  'fullName',
  'name',
]);

const isHiddenField = (field: string, stepId: StepIds): boolean => backgroundCheckDisplayOptions[stepId][field]?.hidden;

const isNameField = (field: string): boolean => nameFields.has(field);

const formatDateShort = (value: string) => dayjs(value, [DateFormatTypes.DateShort, DateFormatTypes.DateShortStroke])
  .format(DateFormatTypes.FullMonthDateAndFullYear);

const fieldValueFormatter = (field: string, value: string): string => {
  const gettersMap = {
    [BackgroundCheckDateFormatsTypes.DateOfBirth]: formatDateShort,
    [BackgroundCheckDateFormatsTypes.BirthDay]: formatDateShort,
  };

  return gettersMap[field] ? gettersMap[field]?.(value) : value;
};

export const SummaryUserInfo = ({
  data,
  stepId,
  className,
  fieldsOrder,
}: {
  data: IBackgroundCheckStepData;
  stepId: StepIds;
  fieldsOrder?: string[];
  className?: string;
}) => {
  const formatMessage = useFormatMessage();
  const nameField = Object.keys(data)
    .find((field) => isNameField(field));
  const result: Record<string, string> = fieldsOrder ? fieldsOrder.reduce((acc, field) => ({
    ...acc,
    [field]: data[field],
  }), {}) : data;

  return (
    <Paper className={className}>
      <Box p={2}>
        <Grid container spacing={3}>
          {nameField && (
            <Grid item xs={12}>
              <SummaryUserInfoItem
                label={formatMessage(`identity.field.${nameField}`)}
                value={data[nameField]}
              />
            </Grid>
          )}
          {Object.entries(result)
            .map(([key, value]: [string, string]) => {
              if (isHiddenField(key, stepId) || isNameField(key)) {
                return null;
              }

              return (
                <Grid item key={key} xs={12} md={6}>
                  <SummaryUserInfoItem
                    key={key}
                    label={formatMessage(`identity.field.${key}`)}
                    value={fieldValueFormatter(key, value) || '-'}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Paper>
  );
};
