import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  crossIcon: {
    flexShrink: 0,
    marginRight: '10px',
    '& > path': {
      stroke: theme.palette.common.red,
    },
  },
  detailsContent: {
    padding: '0 0 0 38px',
    marginTop: '14px',
    marginBottom: '8px',
  },
  warningIcon: {
    flexShrink: 0,
    width: 17,
    height: 17,
  },
  recordStatus: {
    color: theme.palette.common.red,
  },
  row: {
    marginTop: 16,
  },
}));
