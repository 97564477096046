import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as IconSuccess } from 'assets/icon-identity-done.svg';
import { ReactComponent as IconError } from 'assets/icon-identity-error.svg';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, useStyles } from './CheckBarExpandableSummary.styles';

export function CheckBarExpandableSummary({ title, children, isSuccess = true, isShowError = true }: {
  title: React.ReactNode;
  children: React.ReactNode;
  isSuccess?: boolean;
  isShowError?: boolean;
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = () => {
    setExpanded((isExpanded) => !isExpanded);
  };

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange}
      disabled={false}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
        className={classNames({
          error: !isSuccess && isShowError,
        })}
      >
        <Grid container alignItems="center" wrap="nowrap">
          {isSuccess && <IconSuccess className={classes.labelSuccessIcon} />}
          {!isSuccess && <IconError className={classes.labelSuccessIcon} />}
          <Box fontWeight={600} className={classes.labelContainer}>
            {title}
          </Box>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
