import { Product, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes, IProductCURLExampleParams } from 'models/Product.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { FiUserCheck } from 'react-icons/fi';
import { IFlow, IFlowTagTypes } from 'models/Flow.model';
import { BiometricSteps, BiometricTypes, IDuplicateSelfieStepData, SelfieStepTypes } from 'models/Biometric.model';
import { BiometricsVerificationProduct } from 'apps/biometrics';
import { VerificationResponse } from 'models/VerificationOld.model';
import { getStepStatus, IStep, StepStatus } from 'models/Step.model';
import { IESignatureFlow } from 'models/ESignature.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { FacialWatchlistDataSourceTypes } from 'apps/CustomWatchlist/models/CustomWatchlist.model';
import { ICustomWatchList } from 'models/CustomWatchlist.model';
import { cloneDeep } from 'lodash';
import { BiometricVerificationChallengeTypes, BiometricVerificationCheckTypes, BiometricVerificationExampleFiles, BiometricVerificationInputTypes, BiometricVerificationSettingsTypes, DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD, IVerificationStatusFilterStatus, defaultVerificationStatusFilter } from '../models/BiometricVerification.model';
import { BiometricVerificationSettings } from '../components/BiometricVerificationSettings';
import { BiometricVerificationRemovingAlert } from '../components/BiometricVerificationRemovingAlert';

type ProductSettingsBiometric = ProductSettings<BiometricVerificationSettingsTypes>;
const helperTextDuplicateSettings = 'Biometrics.settings.duplicateUserDetection.flowStatus.helpText';
export class BiometricVerification extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.BiometricVerification;
  order = 200;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  icon = FiUserCheck;
  inputs = [
    ProductInputTypes.Selfie,
    ProductInputTypes.Liveness,
  ];
  checks = [
    {
      id: BiometricVerificationCheckTypes.Liveness,
      isActive: true,
    },
  ];
  component = BiometricVerificationSettings;
  componentVerification = BiometricsVerificationProduct;
  requiredProductType = null;
  defaultType = BiometricTypes.liveness;

  constructor({ isVideoDisabled }: { isVideoDisabled: boolean }) {
    super();
    if (isVideoDisabled) {
      this.defaultType = BiometricTypes.selfie;
    }
  }

  isInFlow(flow: IFlow): boolean {
    return (flow?.verificationPatterns?.[VerificationPatternTypes.Biometrics] !== BiometricTypes.none && !flow?.verificationPatterns?.[VerificationPatternTypes.ReFacematch]) || (flow?.verificationPatterns?.[VerificationPatternTypes.BiometricsVerification] && !flow?.verificationPatterns?.[VerificationPatternTypes.ReFacematch]);
  }

  onAdd(): Partial<IFlow> {
    return {
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold]: DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD,
      [BiometricVerificationSettingsTypes.IsDuplicateFaceRejection]: true,
      [BiometricVerificationSettingsTypes.IsLivenessRejection]: true,
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings]: {
        verificationStatusFilter: defaultVerificationStatusFilter,
      },
      verificationPatterns: {
        [VerificationPatternTypes.Biometrics]: this.defaultType,
        [VerificationPatternTypes.DuplicateFaceDetection]: false,
        [VerificationPatternTypes.BiometricsVerification]: false,
      },
    };
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    let electronicSignature: IESignatureFlow = flow?.electronicSignature;
    if (flow?.electronicSignature?.acceptanceCriteria.isFaceMatchRequired) {
      electronicSignature = {
        ...flow.electronicSignature,
        acceptanceCriteria: {
          ...flow.electronicSignature.acceptanceCriteria,
          isDocumentsRequired: false,
          isFaceMatchRequired: false,
        },
      };
    }

    const facematchSources = flow?.facematchServiceConfig?.sources.filter((source) => source.type !== FacematchSourceTypes.Biometrics);

    return {
      facematchThreshold: undefined,
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold]: undefined,
      [BiometricVerificationSettingsTypes.IsDuplicateFaceRejection]: undefined,
      [BiometricVerificationSettingsTypes.IsLivenessRejection]: undefined,
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings]: undefined,
      verificationPatterns: {
        [VerificationPatternTypes.Biometrics]: BiometricTypes.none,
        [VerificationPatternTypes.ProofOfOwnership]: false,
        [VerificationPatternTypes.DuplicateFaceDetection]: false,
        [VerificationPatternTypes.BiometricsVerification]: false,
      },
      electronicSignature,
      facematchServiceConfig: {
        ...flow?.facematchServiceConfig,
        sources: facematchSources,
      },
      advancedLivenessConfig: undefined,
    };
  }

  getRemovingAlertComponent(flow: IFlow, productsInGraph?: ProductTypes[]): any {
    return productsInGraph.includes(ProductTypes.DocumentVerification) ? BiometricVerificationRemovingAlert : null;
  }

  getCURLExampleParams(flow: IFlow): Nullable<IProductCURLExampleParams> {
    const flowInputTypes = flow?.inputTypes || [];
    const biometricVerificationInputTypesValues: string[] = Object.values(BiometricVerificationInputTypes);
    const biometricInputTypes = flowInputTypes.map((type) => type.id).filter((type) => biometricVerificationInputTypesValues.includes(type));

    if (!biometricInputTypes.length) {
      return null;
    }

    return biometricInputTypes.reduce((acc, type) => ({
      inputs: [
        ...acc.inputs,
        {
          inputType: type,
          data: {
            type,
            filename: type === BiometricVerificationInputTypes.SelfiePhoto ? `${type}.jpg` : `${type}.mp4`,
          },
        },
      ],
      files: [
        ...acc.files,
        BiometricVerificationExampleFiles[type],
      ],
    }), {
      inputs: [],
      files: [],
    });
  }

  getBiometricType(biometricType: string) {
    switch (biometricType) {
      case BiometricTypes.liveness:
        return FacialWatchlistDataSourceTypes.SELFIE_VIDEO;
      case BiometricTypes.selfie:
        return FacialWatchlistDataSourceTypes.SELFIE_PHOTO;
      default:
        return FacialWatchlistDataSourceTypes.LIVENESS_NIST;
    }
  }

  getDuplicateFaceSettingError(verificationStatusFilter: IVerificationStatusFilterStatus[]): Nullable<string> {
    return verificationStatusFilter.length === 0 ? helperTextDuplicateSettings : undefined;
  }

  parser(flow: IFlow): ProductSettingsBiometric {
    let integrationError: Nullable<string> = null;
    if (flow?.integrationType === ProductIntegrationTypes.Api && flow?.verificationPatterns?.[VerificationPatternTypes.BiometricsVerification]) {
      if (flow?.[BiometricVerificationSettingsTypes.BiometricsVerification].challenge === BiometricVerificationChallengeTypes.ACTIVE) {
        integrationError = 'Biometrics.setting.nistLivenessActive.warning';
      }
      if (flow?.[BiometricVerificationSettingsTypes.BiometricsVerification].challenge === BiometricVerificationChallengeTypes.PASSIVE) {
        integrationError = 'Biometrics.setting.nistLivenessPassive.warning';
      }
    }
    return {
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold]: {
        value: flow?.[BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold],
      },
      [BiometricVerificationSettingsTypes.DuplicateFaceDetection]: {
        value: flow?.verificationPatterns?.[VerificationPatternTypes.DuplicateFaceDetection],
      },
      [BiometricVerificationSettingsTypes.Biometrics]: {
        value: flow?.verificationPatterns?.biometrics,
        isCantBeUsedWithOtherSetting: !!flow?.verificationPatterns?.[VerificationPatternTypes.ProofOfOwnership],
      },
      [BiometricVerificationSettingsTypes.IsDuplicateFaceRejection]: {
        value: flow?.[BiometricVerificationSettingsTypes.IsDuplicateFaceRejection],
      },
      [BiometricVerificationSettingsTypes.BiometricsVerification]: {
        value: flow?.[BiometricVerificationSettingsTypes.BiometricsVerification], // NIST- liveness config
      },
      [BiometricVerificationSettingsTypes.BiometricsVerificationPattern]: {
        value: !!flow?.verificationPatterns?.[VerificationPatternTypes.BiometricsVerification],
        error: integrationError,
      },
      [BiometricVerificationSettingsTypes.CanUseAgeConsistencyCheck]: {
        value: flow?.tags?.includes(IFlowTagTypes.CanUseAgeConsistencyCheck),
      },
      [BiometricVerificationSettingsTypes.AgeConsistencyCheckEnabled]: {
        value: flow?.verificationPatterns?.[VerificationPatternTypes.AgeConsistencyCheckEnabled],
      },
      [BiometricVerificationSettingsTypes.IsLivenessRejection]: {
        value: flow?.isLivenessRejection,
      },
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings]: {
        value: flow?.duplicateFaceDetectionSettings?.verificationStatusFilter || [],
        error: flow?.verificationPatterns?.[VerificationPatternTypes.DuplicateFaceDetection] && this.getDuplicateFaceSettingError(flow?.duplicateFaceDetectionSettings?.verificationStatusFilter || []),
      },
      [BiometricVerificationSettingsTypes.AdvancedLivenessConfiguration]: {
        value: flow?.advancedLivenessConfig || {
          isDigitalSpoofEnabled: false,
          isFaceEvasionEnabled: false,
          isAgeVerificationEnabled: false,
          ageVerificationThreshold: 18,
        },
        error: (flow?.advancedLivenessConfig?.isAgeVerificationEnabled && !flow?.advancedLivenessConfig?.ageVerificationThreshold) ? 'Biometrics.setting.livenessPassive.error.thresholdNotset' : undefined,
      },
    };
  }

  serialize(settings: ProductSettingsBiometric, flow: IFlow): Partial<IFlow> {
    const updatedWatchlist = flow?.watchlists ? cloneDeep(flow?.watchlists) : {};
    const updateCustomWatchListSource = flow?.verificationPatterns?.[VerificationPatternTypes.CustomWatchlistsValidation];
    if (updateCustomWatchListSource) {
      const updatedCustomWatchListSource: ICustomWatchList = cloneDeep(flow.watchlists.customWatchlistBiometrics);
      if (updatedCustomWatchListSource.dataSource) {
        updatedCustomWatchListSource.dataSource = this.getBiometricType(settings[BiometricVerificationSettingsTypes.Biometrics].value);
        updatedWatchlist.customWatchlistBiometrics = updatedCustomWatchListSource;
      }
    }

    return {
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold]: settings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold].value,
      [BiometricVerificationSettingsTypes.IsDuplicateFaceRejection]: settings[BiometricVerificationSettingsTypes.IsDuplicateFaceRejection].value,
      [BiometricVerificationSettingsTypes.BiometricsVerification]: settings[BiometricVerificationSettingsTypes.BiometricsVerification].value,
      [BiometricVerificationSettingsTypes.IsLivenessRejection]: settings[BiometricVerificationSettingsTypes.Biometrics].value === BiometricTypes.liveness ? settings[BiometricVerificationSettingsTypes.IsLivenessRejection].value : false,
      [BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings]: {
        verificationStatusFilter: settings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings].value,
      },
      verificationPatterns: {
        [VerificationPatternTypes.DuplicateFaceDetection]: settings[BiometricVerificationSettingsTypes.DuplicateFaceDetection].value,
        [VerificationPatternTypes.Biometrics]: settings[BiometricVerificationSettingsTypes.Biometrics].value,
        [VerificationPatternTypes.BiometricsVerification]: settings[BiometricVerificationSettingsTypes.BiometricsVerificationPattern].value,
        [VerificationPatternTypes.AgeConsistencyCheckEnabled]: settings[BiometricVerificationSettingsTypes.AgeConsistencyCheckEnabled].value,
      },
      watchlists: updatedWatchlist,
      advancedLivenessConfig: settings[BiometricVerificationSettingsTypes.AdvancedLivenessConfiguration].value,
    };
  }

  getVerification(verification: VerificationResponse): VerificationResponse {
    return verification;
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const steps = verification?.steps || [];
    const biometric = steps.filter((item) => BiometricSteps.includes(item?.id));
    const duplicateFaceDetectionStep: IStep<IDuplicateSelfieStepData> = steps.find((step) => step.id === SelfieStepTypes.DuplicateSelfieValidation);
    if (biometric.length === 0) {
      return false;
    }
    return biometric.some((step) => getStepStatus(step) === StepStatus.Failure) || getStepStatus(duplicateFaceDetectionStep) === StepStatus.Failure;
  }
}
