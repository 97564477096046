import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 700,
    '& > main': {
      minHeight: 490,
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginBottom: '40px',
  },
  subtitle: {
    marginBottom: '6px',
  },
  helpText: {
    color: theme.palette.common.black75,
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    '&::placeholder': {
      color: theme.palette.common.black50,
    },
    'input.MuiOutlinedInput-root': {
      color: theme.palette.common.black50,
    },
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontFamily: 'Lato',
    lineHeight: '17px',
    borderColor: theme.palette.common.black75,
    borderRadius: '5px',
    resize: 'none',
    '&::placeholder': {
      color: theme.palette.common.black50,
    },
  },
  contentHolder: {
    marginBottom: '40px',
  },
  error: {
    color: theme.palette.common.red,
  },
}));
