import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import { CountryCodeTypes, RegionsTypes } from 'models/Country.model';
import React, { useState } from 'react';
import { GovCheckCountryHeader } from '../GovCheckCountryHeader/GovCheckCountryHeader';
import { useStyles } from './GovCheckModalRegion.styles';

export function GovCheckModalRegion({ countries, region, selectedCountry, setSelectedCountry }: {
  countries: CountryCodeTypes[];
  region: RegionsTypes;
  selectedCountry?: CountryCodeTypes;
  setSelectedCountry: (value: CountryCodeTypes) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleChangeExpanded = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const handleChangeSelectedCountry = (country: CountryCodeTypes) => () => {
    setSelectedCountry(country);
  };

  return (
    <>
      <Button
        onClick={handleChangeExpanded}
        className={classes.regiondBlock}
      >
        {formatMessage(`region.${region}`)}
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      {isExpanded && (
        countries.map((country) => (
          <Button
            key={country}
            onClick={handleChangeSelectedCountry(country)}
            className={classnames(classes.countryBlock, { [classes.selectedCountry]: country === selectedCountry })}
          >
            <GovCheckCountryHeader
              country={country}
            >
              <NavigateNextIcon className={classnames(classes.countryIcon, { [classes.selectedCountryIcon]: country === selectedCountry })} />
            </GovCheckCountryHeader>
          </Button>
        )))}
    </>
  );
}
