import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { useFormatMessage } from 'apps/intl';
import { CustomChipInput } from 'apps/ui';
import { QATags } from 'models/QA.model';
import React from 'react';
import { isEmpty } from 'lib/isEmpty';
import { useStyles } from './EOFRedirection.style';

const DELIPITER_PATTERN = /[,;\s]+/;

export function EOFRedirection({ allowedUrls, onUpdate }: {
  allowedUrls: string[] | undefined;
  onUpdate: (values: string[]) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const handleAdd = (url: string) => {
    onUpdate([...allowedUrls, url]);
  };

  const handleDelete = (url: string) => {
    onUpdate(allowedUrls.filter((item) => item !== url));
  };

  const handlePaste = (event) => {
    let urls = event?.clipboardData?.getData('text')?.split(DELIPITER_PATTERN);
    urls = urls?.filter((item: string) => !isEmpty(item));
    onUpdate([...allowedUrls, ...urls]);
  };

  return (
    <FormControl component="fieldset">
      <Box
        mb={0.5}
        fontWeight="bold"
        color="common.black90"
      >
        {formatMessage('FlowBuilder.settings.title.EOFRedirection')}
      </Box>
      <Box
        mb={2}
        color="common.black75"
      >
        {formatMessage('FlowBuilder.settings.subtitle.EOFRedirection')}
      </Box>
      <FormGroup>
        <CustomChipInput
          delimiterPattern={DELIPITER_PATTERN}
          variant="outlined"
          placeholder={formatMessage('FlowBuilder.settings.EOFRedirection.placeholder')}
          value={allowedUrls}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onPaste={handlePaste}
          fullWidth
          alwaysShowPlaceholder
          error={false}
          clearInputValueOnChange
          blurBehavior="add"
          classes={{
            chip: classes.chip,
          }}
          InputProps={{
            'data-qa': QATags.NewSettings.Team.Members.AddMemberModal.EmailsInput,
          }}
        />
      </FormGroup>
    </FormControl>
  );
}
