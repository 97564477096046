import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  enterpriseChipStyle: {
    fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '21px',
    color: theme.palette.common.textYellow,
    background: theme.palette.common.lightyellow,
  },
  formControl: {
    marginLeft: '0px',
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      fontSize: '16px',
      fontWeight: 500,
      color: theme.palette.common.black,
    },
  },
}));
