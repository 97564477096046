import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.text.main,
    marginBottom: 9,
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minWidth: '264px',
    '& svg': {
      maxWidth: '90%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '65%',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 18,
      },
    },
    [theme.breakpoints.down('lg')]: {
      flexShrink: 1,
      minWidth: 200,
    },
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
  },
  label: {
    color: theme.palette.text.main,
  },
  summaryContainer: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  summaryInfoBlock: {
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('xl')]: {
      flexGrow: 0,
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  summaryInfo: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '432px',
    },
  },
  summaryInfoContent: {
    height: '100%',
  },
  checkScore: {
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      flexBasis: 'auto',
    },
  },
  score: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '432px',
    },
  },
}));
