import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import { Placeholder } from 'apps/ui';
import { ReactComponent as EmptyListIcon } from 'assets/empty-list-round.svg';
import { DateFormatTypes, formatDate } from 'lib/date';
import { getCollaboratorColorById } from 'models/Collaborator.model';
import { Routes } from 'models/Router.model';
import { AiOutlineUser } from 'react-icons/ai';
import { FiChevronLeft } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';
import { useHistory, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationTypes, useTrackNavigation } from 'apps/Tracking';
import { useStyles } from './FlowBuilderHistory.styles';
import { selectFlowHistoryModel, selectFlowHistoryList, selectFlowHistoryTotalCount } from '../../store/FlowBuilder.selectors';
import { clearFlowHistory, loadFlowHistory, loadFlowHistoryEventCount } from '../../store/FlowBuilder.action';

export function FlowBuilderHistory() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const formatMessage = useFormatMessage();
  const { id: flowId } = useParams();
  const goBackPath = history.location.state?.from || `${Routes.flow.root}/${flowId}`;
  const { ref: isInViewRef, inView } = useInView({});
  const flowHistoryList = useSelector(selectFlowHistoryList);
  const { isLoading, isLoaded, isFailed, error: loadingError } = useSelector(selectFlowHistoryModel);
  const changesTotalCount = useSelector(selectFlowHistoryTotalCount);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useTrackNavigation(NavigationTypes.WorkflowHistory);

  useEffect(() => {
    dispatch(clearFlowHistory());
    dispatch(loadFlowHistory(flowId, page));
    dispatch(loadFlowHistoryEventCount(flowId));
  }, [dispatch, history]);

  useEffect(() => {
    if (inView && !isLoading && hasMore) {
      dispatch(loadFlowHistory(flowId, page + 1, true));
      setPage(((prevState) => prevState + 1));
    }
  }, [inView, dispatch, hasMore, flowId, isLoading, page]);

  useEffect(() => {
    setHasMore(!isLoading && isLoaded && flowHistoryList?.length < changesTotalCount);
  }, [isLoaded, page, changesTotalCount, flowHistoryList, isLoading]);

  return (
    <Box className={classes.page}>
      <Box id="page-header">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link to={{ pathname: goBackPath, state: { from: history.location.state?.rootList } }}>
            <FiChevronLeft size={24} className={classes.backArrow} />
          </Link>
          <Typography className={classes.pageHeaderTitle}>
            {formatMessage('FlowBuilderHistory.header.title')}
          </Typography>
        </Box>
        <Box marginLeft="32px" marginTop="4px">
          <Typography className={classes.pageHeaderSubtitle}>
            {formatMessage('FlowBuilderHistory.header.subtitle')}
          </Typography>
        </Box>
      </Box>
      <Box mt="32px">
        <Box ml="2px" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography className={classes.tableTitle}>{formatMessage('FlowBuilderHistory.table.title')}</Typography>
        </Box>
        <Box mt="12px">
          <Box className={classes.updatesHeader}>
            <Box className={classes.updatesRow}>
              <Box className={classes.updatesRowColumn}>
                <Typography className={classes.userActionsTableHeaderText}>{formatMessage('FlowBuilderHistory.table.header.timestamp')}</Typography>
              </Box>
              <Box className={classes.updatesRowColumn}>
                <Typography className={classes.userActionsTableHeaderText}>{formatMessage('FlowBuilderHistory.table.header.triggeredBy')}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.updatesList}>
            {(isFailed || (!flowHistoryList?.length && isLoaded)) && (
              <Box key="placeholder" className={classes.updatesRow} justifyContent="center">
                <Placeholder icon={<EmptyListIcon />} subtitle={formatMessage('FlowBuilderHistory.placeholder')} />
              </Box>
            )}
            {Boolean(flowHistoryList?.length) && flowHistoryList.map((item, index) => (
              /* Somehow MUI Box does not have typings for ref prop */
              <div ref={index === flowHistoryList.length - 1 ? isInViewRef : null} key={item._id} className={classes.updatesRowWrapper}>
                <Box key={item._id} className={classes.updatesRow}>
                  <Box className={classes.updatesRowColumn}>
                    <Box className={classes.timestampText}>
                      {formatDate(item.updatedAt, DateFormatTypes.FullDateWithTimeDashed)}
                    </Box>
                  </Box>
                  <Box className={classes.updatesRowColumn}>
                    {item.eventBody?.changedBy && (
                      <Box display="flex">
                        <Box className={classes.avatarContainer} bgcolor={getCollaboratorColorById(item.eventBody.changedBy._email.address)}>
                          <AiOutlineUser size={16} />
                        </Box>
                        <Box display="inline-flex" flexDirection="column" ml="4px">
                          <Box className={classes.triggeredByNameText} height="24px" display="flex" alignItems="center">
                            {item.eventBody.changedBy.firstName}
                            {item.eventBody.changedBy.lastName}
                          </Box>
                          <Box className={classes.triggeredByEmailText}>
                            {item.eventBody.changedBy._email.address}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
