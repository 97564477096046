import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    border: `1px solid ${theme.palette.common.lightblue}`,
    borderRadius: 6,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black90,
    transition: 'height 2s',
  },
  containerClosed: {
    border: `1px solid ${theme.palette.common.black7}`,
    color: theme.palette.common.darkgrey,
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 500,
  },
  icon: {
    marginRight: 12,
    strokeWidth: 1.5,
    width: 24,
    height: 24,
  },
  expandButton: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.black50,
  },
  label: {
    marginTop: 16,
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.common.darkgrey,
    fontWeight: 500,
  },
  badgeContainer: {
    marginTop: 13,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 13,
  },
  badge: {
    padding: 6,
    fontSize: 12,
    lineHeight: '17px',
    color: theme.palette.common.black90,
    border: `1px solid ${theme.palette.common.whiteblue}`,
    borderRadius: 4,
  },
}));
