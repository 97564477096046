import React, { useCallback, useMemo } from 'react';
import throttle from 'lodash/throttle';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

export function TooltipFollowingCursor({ children, ...tooltipProps }:
  {
    children: React.ReactElement;
  } & TooltipProps) {
  const [positionTooltip, setPositionTooltip] = React.useState({ x: undefined, y: undefined });
  const throttledSetPositionTooltip = useMemo(() => throttle((event) => setPositionTooltip({ x: event.clientX, y: event.clientY }), 20), []);
  const handleMouseMove = useCallback((event) => {
    event.persist();
    throttledSetPositionTooltip(event);
  }, [throttledSetPositionTooltip]);

  return (
    <Tooltip
      {...tooltipProps}
      onMouseMove={handleMouseMove}
      PopperProps={{
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: positionTooltip.y,
            left: positionTooltip.x,
            right: positionTooltip.x,
            bottom: positionTooltip.y + 5,
            width: 0,
            height: 0,
          } as DOMRect),
        },
      }}
    >
      {children}
    </Tooltip>
  );
}
