import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    width: 'inherit',
  },
  backButton: {
    border: '1px solid black',
    color: 'black',
    borderRadius: '40px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Anybody',
    fontSize: '24px',
    lineHeight: '45px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '19px',
  },
}));
