import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fullScreenLoader: {
    width: 600,
    height: 900,
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  footerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  previousDisabled: {
    justifyContent: 'flex-end',
  },
  pageButton: {
    marginLeft: 10,
    marginRight: 10,
    color: theme.palette.common.black90,
    '&:last-child': {
      alignSelf: 'flex-end',
    },
  },
  pdfContainer: {
    overflowY: 'scroll',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 70,
      height: 65,
      zIndex: 1,
      width: '100%',
      background: '-webkit-linear-gradient(90deg,#fff,hsla(0,0%,100%,.9),hsla(0,0%,100%,0))',
    },
  },
  singlePdf: {
    '&::before': {
      bottom: 0,
    },
  },
  footerButtonText: {
    marginLeft: 10,
    marginRight: 10,
    lineHeight: 'unset',
  },
}));
