import { http, httpNew } from 'lib/client/http';
import { Collaborator, IUser, TeamInviteFormInputs, UserId } from 'models/Collaborator.model';
import { MerchantId } from 'models/Merchant.model';

export function getCollaborators(merchantId: MerchantId) {
  return http.get<Collaborator[]>(`/api/v1/merchants/${merchantId}/collaborators`);
}

export function getCollaborator(merchantId: MerchantId, id: UserId) {
  return http.get<Collaborator[]>(`/api/v1/merchants/${merchantId}/collaborators/${id}`);
}

export function postCollaborators(users: TeamInviteFormInputs[]) {
  return httpNew.post<{ collaborators: Collaborator[]; created: number }>('/dashboard-gateway/merchant/collaborators', { users });
}

export function deleteCollaborators(id: UserId) {
  return httpNew.delete<Collaborator[]>(`/dashboard-gateway/merchant/collaborators/${id}`);
}

export function patchCollaborators(merchantId: MerchantId, id: UserId, data: Partial<Collaborator>) {
  return http.patch<Collaborator[]>(`/api/v1/merchants/${merchantId}/collaborators/${id}`, data);
}

export function patchCollaboratorsRole(merchantId: MerchantId, id: UserId, data: Partial<Collaborator>) {
  return http.patch<Collaborator[]>(`/dashboard-gateway/merchant/collaborators/${id}/changeRole`, data);
}

export function userChangeBlockStatus(id: UserId, blockedStatus: boolean) {
  return httpNew.patch<IUser>(`/dashboard-gateway/merchant/collaborators/${id}/changeBlockStatus`, { blockedStatus });
}

export function resendCollaboratorInvitation(id: UserId) {
  return httpNew.post<IUser>(`/dashboard-gateway/merchant/collaborators/${id}/resendInvitation`, { });
}
