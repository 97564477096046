import { amlCheckInit } from 'apps/Aml/state/Aml.actions';
import { biometricVerificationInit } from 'apps/biometricVerification';
import { documentVerificationInit, PoaVerificationInit } from 'apps/documentVerification';
import { customWatchlistInit } from 'apps/CustomWatchlist/state/CustomWatchlist.actions';
import { emailCheckInit } from 'apps/EmailCheck/state/EmailCheck.actions';
import { phoneCheckInit } from 'apps/PhoneCheck/state/PhoneCheck.actions';
import { reVerificationInit } from 'apps/reverification/state/ReVerification.actions';
import { bankAccountDataInit } from 'apps/BankAccountData';
import compact from 'lodash/compact';
import { VerificationResponse } from 'models/VerificationOld.model';
import { storeAction } from 'state/store.utils';
import { ProductTypes, IRegistryProduct } from 'models/Product.model';
import { creditCheckInit } from 'apps/CreditCheck/state/CreditCheck.actions';
import { backgroundCheckInit } from 'apps/BackgroundCheck/state/BackgroundCheck.actions';
import { deviceFingerprintInit } from 'apps/DeviceFingerPrint/state/deviceFingerprint.actions';
import { customDocumentInit } from 'apps/customDocument/state/customDocument.actions';
import { facematchInit } from 'apps/FacematchService/state/Facematch.actions';
import { eSignatureInit } from 'apps/ESignature';
import { customFieldInit } from 'apps/CustomField';
import { metadataInit } from 'apps/metadata/state/Metadata.actions';
import { certifiedTimestampInit } from 'apps/CertifiedTimestamp/state/CertifiedTimestamp.actions';
import { locationIntelligenceInit } from 'apps/LocationIntelligence';
import { govCheckMeritInit } from 'apps/GovCheckMerit';
import { selectMerchantTags } from 'state/merchant';
import { VideoAgreementInit } from 'apps/VideoAgreement';
import { fraudAssessmentScoreInit } from 'apps/FraudAssessmentScore/state/FraudAssessmentScore.actions';
import { selectProductRegistered } from './Product.selectors';
import { ProductActionTypes } from './Product.store';
import { productManagerService } from '../services/ProductManager.service';
import { ExternalProduct } from '../services/ExternalProduct.service';
import { getExternalProducts } from '../api/Product.client';

export const productIsInitedUpdate = storeAction<boolean>(ProductActionTypes.ProductIsInitedUpdate);
export const productRegisteredUpdate = storeAction<string[]>(ProductActionTypes.ProductRegistered);

export const productInitExternalProducts = () => async (dispatch, getState) => {
  const merchantTags = selectMerchantTags(getState());
  let externalProducts: IRegistryProduct[] = [];

  try {
    const { data } = await getExternalProducts();
    externalProducts = data;
  } catch (error) {
    console.error(error);
  }

  return externalProducts.map((item) => {
    try {
      if (ExternalProduct.checkIsPartial(item)) {
        return null;
      }

      const externalProduct = new ExternalProduct(item, merchantTags, productManagerService);
      productManagerService.register(externalProduct);
      return externalProduct.id;
    } catch (error) {
      console.error(error);
      return null;
    }
  }).filter(Boolean);
};

export const productFlowbuilderInit = () => async (dispatch) => {
  const registered: ProductTypes[] = await dispatch(productInitExternalProducts());
  registered.push(
    dispatch(emailCheckInit(registered)),
    dispatch(phoneCheckInit()),
    dispatch(documentVerificationInit()),
    dispatch(PoaVerificationInit()),
    dispatch(reVerificationInit()),
    dispatch(amlCheckInit()),
    dispatch(biometricVerificationInit()),
    dispatch(facematchInit()),
    dispatch(creditCheckInit()),
    dispatch(deviceFingerprintInit()),
    dispatch(bankAccountDataInit(registered)),
    dispatch(metadataInit()),
    dispatch(customDocumentInit()),
    dispatch(certifiedTimestampInit()),
    dispatch(backgroundCheckInit()),
    dispatch(customWatchlistInit()),
    dispatch(eSignatureInit()),
    dispatch(customFieldInit(registered)),
    dispatch(locationIntelligenceInit()),
    dispatch(govCheckMeritInit(ProductTypes.IdentityValidation)),
    dispatch(govCheckMeritInit(ProductTypes.LegalRecords)),
    dispatch(govCheckMeritInit(ProductTypes.DrivingLicenseValidation)),
    dispatch(govCheckMeritInit(ProductTypes.TaxChecks)),
    dispatch(govCheckMeritInit(ProductTypes.BusinessRecordsChecks)),
    dispatch(govCheckMeritInit(ProductTypes.EmploymentInformation)),
    dispatch(VideoAgreementInit()),
    dispatch(fraudAssessmentScoreInit()),
  );
  dispatch(productRegisteredUpdate(compact(registered)));
  dispatch(productIsInitedUpdate(true));
};

export const verificationProductListInit = (verification: VerificationResponse) => (dispatch, getState): ProductTypes[] => {
  const registered = selectProductRegistered(getState());
  const activated = registered.filter((item) => {
    const product = productManagerService.getProduct(item);
    if (!product) {
      return false;
    }
    return product.isInVerification(verification);
  });
  return productManagerService.sortProductTypes(activated);
};
