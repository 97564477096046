import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { EMAIL_REG_EXP } from 'lib/validations';
import { ReactComponent as MatiLogo } from 'assets/metamap-logo.svg';
import { IntlButton, useFormatMessage } from 'apps/intl';
import { Routes } from 'models/Router.model';
import { QATags } from 'models/QA.model';
import { IIdentifierFirstInputs, useAuth0IdentifierFirst } from 'lib/Auth0';
import { AuthInputTypes, NoAccess } from 'apps/auth';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import classnames from 'classnames';
import { useStyles } from './SignIn.styles';

export function SignIn() {
  const recaptchaRef = useRef();
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const [checkedCaptcha, setCheckedCaptcha] = useState<boolean>(false);
  const [isNoAccessToOrganization, setIsNoAccessToOrganization] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');

  const { register, watch, setValue, setError, handleSubmit, formState: { errors, isSubmitting } } = useForm<IIdentifierFirstInputs>({
    mode: 'onChange',
  });
  const values = watch();
  const emailRegister = register(AuthInputTypes.Email, {
    required: formatMessage('validations.required'),
    pattern: {
      value: EMAIL_REG_EXP,
      message: formatMessage('validations.email'),
    },
  });

  const setFormError = (field, error) => {
    setError(field, { type: 'custom', message: formatMessage(`SignIn.form.${error}.error`) });
  };

  const noAccessToOrganizationRedirect = (displayName: string) => {
    setCompanyName(displayName);
    setIsNoAccessToOrganization(true);
  };

  const [handleFormSubmit, isLoading] = useAuth0IdentifierFirst({
    setCheckedCaptcha, setFormError, recaptchaRef, noAccessToOrganizationRedirect,
  });

  const isEmailCorrect: boolean = values[AuthInputTypes.Email] && !errors[AuthInputTypes.Email];
  const recaptchaWhitelistDomains = (process.env.REACT_APP_RECAPTCHA_WHITELIST_DOMAINS && process.env.REACT_APP_RECAPTCHA_WHITELIST_DOMAINS.toLowerCase().split(',')) || ['metamap.com', 'mati.io'];
  const domain = values[AuthInputTypes.Email]?.toLowerCase()?.split('@')?.[1];
  const isCaptchaDisabled: boolean = !!process.env.REACT_APP_RECAPTCHA_DISABLED
    || (process.env.REACT_APP_RECAPTCHA_WHITELIST && process.env.REACT_APP_RECAPTCHA_WHITELIST.toLowerCase().includes(values[AuthInputTypes.Email]?.toLowerCase()))
    || recaptchaWhitelistDomains.includes(domain);

  const handleCaptchaToken = (token: Nullable<string>) => {
    setCheckedCaptcha(!!token);
    setValue(AuthInputTypes.CaptchaToken, token || '');
  };

  const getHelperText = () => {
    if (errors?.[AuthInputTypes.Email]?.message) {
      return (
        <Box className={classes.helperTextWrapper}>
          <AiOutlineInfoCircle className={classes.errorIcon} />
          {errors?.[AuthInputTypes.Email]?.message}
        </Box>
      );
    }

    return null;
  };

  if (isLoading) {
    return null;
  }

  return (
    <Grid container className={classes.container}>
      <Box
        className={classnames(classes.signInContainer, {
          [classes.removePadding]: isNoAccessToOrganization,
        })}
      >
        <Box mb={3} className={classes.appBar}>
          <IntlButton isSync={false} />
        </Box>
        {isNoAccessToOrganization ? (
          <NoAccess companyName={companyName} onGoBack={() => setIsNoAccessToOrganization(false)} />
        ) : (
          <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
            <MatiLogo width={160} height={40} />
            <Box>
              <Box className={classes.title}>
                {formatMessage('SignIn.title')}
              </Box>
              <Box className={classes.subtitle}>
                {formatMessage('SignIn.subtitle')}
              </Box>
            </Box>
            <TextField
              {...emailRegister}
              inputProps={{ 'data-qa': QATags.Auth.SignIn.EmailInput }}
              FormHelperTextProps={{
                // @ts-ignore
                component: 'div',
              }}
              helperText={getHelperText()}
              error={!!errors[AuthInputTypes.Email]}
              type="input"
              variant="outlined"
              placeholder={formatMessage('SignIn.form.labels.email')}
              fullWidth
            />
            {isEmailCorrect && !isCaptchaDisabled && (
            <ReCAPTCHA
              onChange={handleCaptchaToken}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={recaptchaRef}
            />
            )}
            <Button
              data-qa={QATags.Auth.SignIn.SubmitButton}
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              disabled={isSubmitting || !isEmailCorrect || (!isCaptchaDisabled && !checkedCaptcha)}
              type="submit"
            >
              {formatMessage('SignIn.cta')}
            </Button>
            <span className={classes.redirect}>
              {formatMessage('SignIn.redirect')}
                &nbsp;
              <Link to={Routes.signUp.root}>
                {formatMessage('SignIn.redirect.link')}
              </Link>
            </span>
          </form>
        )}
      </Box>
    </Grid>
  );
}
