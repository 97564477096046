import { useFormatMessage } from 'apps/intl';
import React from 'react';
import Button from '@material-ui/core/Button';
import { DateString } from 'lib/date';
import { QATags } from 'models/QA.model';
import { DurationFreeTrialChip } from '../DurationFreeTrialChip/DurationFreeTrialChip';
import { BannerSubscriptionStatus } from '../BannerSubscriptionStatus/BannerSubscriptionStatus';
import { useStyles } from './BannerDaysLeftSubscriptionStatus.styles';

export function BannerDaysLeftSubscriptionStatusUI({ startDate, endDate, onClickActivateAccount }:
  {
    startDate?: DateString;
    endDate?: DateString;
    onClickActivateAccount?: () => void;
  }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <BannerSubscriptionStatus
      prefixElement={startDate && endDate && <DurationFreeTrialChip startDate={startDate} endDate={endDate} />}
      message={formatMessage('SubscriptionStatus.message.freeTrial')}
    >
      {onClickActivateAccount && (
        <Button
          onClick={onClickActivateAccount}
          className={classes.activateButton}
          variant="contained"
          color="primary"
          data-qa={QATags.SubscriptionStatus.FreeTrial.Activate}
        >
          {formatMessage('SubscriptionStatus.button.activatePlan')}
        </Button>
      )}
    </BannerSubscriptionStatus>
  );
}
