import { ITemplateMetadata } from 'apps/Templates';
import { http } from 'lib/client/http';
import Qs from 'qs';
import { ICardsOptions } from '../model/SolutionCatalog.model';

export function getTemplates(filters: ITemplateMetadata[]) {
  return http.get<Omit<ICardsOptions, 'meritsList'>[]>('/api/v1/dashboard/template', {
    params: { metadata: filters },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'indices' });
    },
  });
}
