import type { FormatMessage } from 'apps/intl';
import type { BasicWatchlistIdType } from 'models/Aml.model';
import { IStep, IStepExtra, StepError } from 'models/Step.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import type { IWatchlist, IWatchlistGroup, IWatchlistMapping } from 'models/Watchlist.model';
import type { DocumentField, VerificationDocumentTypes } from 'models/Document.model';
import type { VerificationDataSourceTypes } from 'apps/CustomWatchlist';

export const basicWatchlistsPollingDelay = 5000;
export const DEFAULT_WATCHLIST_GROUP_NAME = 'Other';

export enum BasicWatchlistModalValidationInputTypes {
  Name = 'name',
  FileKey = 'inputSourceFileKey',
  Mapping = 'mapping',
  CsvSeparator = 'csvSeparator',
  FileName = 'inputSourceFileName',
  Group = 'groupId',
  LocaliseKey = 'localiseKey'
}

export interface IBasicWatchlistModalValidationInputs {
  [BasicWatchlistModalValidationInputTypes.Name]: string;
  [BasicWatchlistModalValidationInputTypes.FileKey]: Nullable<string>;
  [BasicWatchlistModalValidationInputTypes.Mapping]: IWatchlistMapping[];
  [BasicWatchlistModalValidationInputTypes.CsvSeparator]: Nullable<string>;
  [BasicWatchlistModalValidationInputTypes.FileName]: string;
  [BasicWatchlistModalValidationInputTypes.Group]: number;
  [BasicWatchlistModalValidationInputTypes.LocaliseKey]: string;
}

export interface IBasicWatchlistItemUI extends IWatchlist {
  formatedName: string;
  checked: boolean;
}

export interface IAmlBasicWatchlistGroupUI extends IWatchlistGroup {
  watchlists: IBasicWatchlistItemUI[];
}

export enum AmlSettingsTypes {
  Search = 'search',
  Monitoring = 'monitoring',
  AmlThreshold = 'amlThreshold',
  BasicWatchlists = 'basic',
  BasicWatchlistsPattern = 'basicWatchlistsPattern',
  CountriesSearched = 'countriesSearched',
  SearchMode = 'searchMode',
  EntitySearch = 'entitySearch',
  DataSource = 'dataSource',
  WebhookVersion = 'webhookVersion',
  Sanctions = 'sanctions'
}

export enum AmlCheckTypes {
  Watchlist = 'watchlist',
  Search = 'search',
  Monitoring = 'monitoring',
}

export enum SearchModeTypes {
  Exact = 'Exact',
  Fuzzy = 'Fuzzy',
}

export enum WebhookVersionTypes {
  Legacy = 'legacy',
  New = 'new',
}

export const MatchTypes = {
  exact: 'exact',
  noMatch: 'noMatch',
  partial: 'partial',
};

const MatchTypesMap = {
  name_exact: MatchTypes.exact,
  aka_exact: MatchTypes.exact,
  no_match: MatchTypes.noMatch,
};

export enum AmlValidationTypes {
  None = 'none',
  Search = 'search',
  SearchMonitoring = 'search+monitoring',
}

export enum AmlDocumentStepTypes {
  Watchlists = 'watchlists',
}

export const AmlDocumentSteps = [
  AmlDocumentStepTypes.Watchlists,
  VerificationPatternTypes.PremiumAmlWatchListsSearchValidation,
];

export interface IBasicWatchlistGroupCreateUpdate {
  name: string;
}

export interface IBasicWatchlistGroupCreated {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
}

export interface IBasicWatchlistGroupsOption {
  label: string;
  value: number;
}

export interface IBasicWatchlistStepDataWatchlist {
  id: string;
  name: string;
  watchlistType?: string;
}

export interface IBasicWatchlistStepDataSearchParams {
  fullName?: string;
  dateOfBirth?: string;
  documentType?: string;
  documentNumber?: string;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
}

export type BasicWatchlistStepDataSearchResultType = Record<string, string>;

export interface IBasicWatchlistStepData {
  documentType?: VerificationDocumentTypes;
  dataSource: VerificationDataSourceTypes;
  watchlist: IBasicWatchlistStepDataWatchlist;
  searchedAt: string;
  searchParams: Nullable<IBasicWatchlistStepDataSearchParams>;
  searchResult: Nullable<BasicWatchlistStepDataSearchResultType>;
  error?: StepError;
}

export type BasicWatchlistStepType = IStepExtra<IBasicWatchlistStepData[]>;

export const getPremiumAmlMatchType = (matchStatus: string): typeof MatchTypes => (matchStatus ? MatchTypesMap[matchStatus] || MatchTypes.partial : null);

interface IDocumentPremiumStepData<T = { monitored: boolean }> extends Omit<IStep<T>, 'error'> {
  error: StepError & {
    details: { matchStatus: string };
  };
}

export function getPremiumAmlWatchlistsCheckExtraData(step: IDocumentPremiumStepData, document: { fields?: Record<string, DocumentField> }) {
  if (!step?.data) {
    return step;
  }

  return {
    ...step,
    data: {
      ...step.data,
      dateOfBirth: document?.fields?.dateOfBirth?.value,
      matchType: getPremiumAmlMatchType(step?.error?.details?.matchStatus),
      isMonitored: step.data.monitored,
    },
  };
}

export function getBasicWatchlistsUI(watchlists: IWatchlist[], groups: IWatchlistGroup[], watchlistIds: BasicWatchlistIdType[], isAdmin: boolean, formatMessage: FormatMessage): IAmlBasicWatchlistGroupUI[] {
  let uniqueGroupId = 0;
  const watchlistGroups: IAmlBasicWatchlistGroupUI[] = [];

  const sortByLocalLang = (a: IBasicWatchlistItemUI, b: IBasicWatchlistItemUI) => a.name.localeCompare(b.name);

  const groupsUi = groups.map((group) => {
    const watchlistsUi = watchlists.map((watchlist) => {
      const hasWatchlists = group.id === watchlist.groupId;
      return hasWatchlists && ({
        ...watchlist,
        // field for sorting by local lang
        formatedName: formatMessage(`Watchlist.settings.modal.input.name.${watchlist.localiseKey}`, { defaultMessage: watchlist.name }),
        checked: watchlistIds.includes(watchlist.id),
      });
    })
      .filter(Boolean) ?? [];

    uniqueGroupId += group.id;

    watchlistsUi.sort(sortByLocalLang);

    // PGS-1152 don't show empty groups for merchant
    if (!isAdmin && watchlistsUi.length === 0) {
      return null;
    }

    return ({ ...group, watchlists: watchlistsUi });
  }).filter(Boolean);

  const otherGroups = {
    id: uniqueGroupId,
    name: DEFAULT_WATCHLIST_GROUP_NAME,
    watchlists: watchlists.filter((watchlist) => !watchlist.groupId).map((watchlist) => ({
      ...watchlist,
      formatedName: formatMessage(`Watchlist.settings.modal.input.name.${watchlist.localiseKey}`, { defaultMessage: watchlist.name }),
      checked: watchlistIds.includes(watchlist.id),
    }))
      .sort(sortByLocalLang),
  };

  watchlistGroups.push(...groupsUi);

  if (otherGroups.watchlists.length !== 0) {
    watchlistGroups.push(otherGroups);
  }

  return watchlistGroups;
}

export interface IPremiumWatchlistData {
  birthYear?: number;
  countriesSearched?: string[];
  dataSource: VerificationDataSourceTypes;
  documentType?: VerificationDocumentTypes;
  error?: StepError;
  isAmlMonitoringAvailable?: boolean;
  isMonitored?: boolean;
  matchFound: boolean;
  matchType: typeof MatchTypes;
  monitored?: boolean;
  nameSearched: string;
  originalMatchStatus?: string;
  profileUrl?: string;
  searchedOn: string;
  searchId: number;
  updatedOn?: string;
}

export interface ILastMonitoredTimestamps {
  id: number;
  lastMonitored: string;
}
