import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  regiondBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
    width: '100%',
    fontWeight: 600,
    height: '41px',
    color: theme.palette.common.black90,
    background: theme.palette.common.white,
    boxShadow: `0px 1px 2px ${theme.palette.common.black90opacity2}`,
    borderBottom: `1px solid ${theme.palette.common.black7}`,
  },
  countryBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    width: '100%',
    height: '57px',
    fontWeight: 500,
    background: theme.palette.common.white,
    color: theme.palette.common.black75,
    borderBottom: `1px solid ${theme.palette.common.black7}`,
  },
  selectedCountry: {
    color: theme.palette.common.black90,
    background: theme.palette.common.white,
  },
  countryIcon: {
    color: theme.palette.common.black50,
  },
  selectedCountryIcon: {
    color: theme.palette.common.black90,
  },
}));
