import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    borderWidth: 1,
    borderColor: theme.palette.common.black7,
    borderRadius: 4,
    borderStyle: 'solid',
    margin: 6,
  },
  tableContainer: {
    margin: 17,
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  tableTitle: {
    color: theme.palette.common.black75,
    marginTop: 15,
    marginLeft: 20,
    marginBottom: 14,
  },
  paginator: {
    alignSelf: 'center',
    marginRight: 16,
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
  },
  table: {
    borderWidth: 1,
    borderColor: theme.palette.common.black7,
    borderRadius: 4,
    borderStyle: 'solid',
    tableLayout: 'fixed',
  },
  tableHead: {
  },
  tableHeadCell: {
    borderWidth: 1,
    borderColor: theme.palette.common.black7,
    borderStyle: 'solid',
    '&:nth-child(1)': {
      width: 130,
    },
    '&:nth-child(2)': {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&:nth-child(3)': {
      width: 115,
      textAlign: 'end',
    },
  },
  hideBorderLeft: {
    borderLeft: 0,
  },
  hideBorderRight: {
    borderRight: 0,
  },
  tableHeadTitle: {
    color: theme.palette.common.black90,
    fontSize: 12,
  },
  tableRowDataText: {
    color: theme.palette.common.black1a,
    fontWeight: 400,
    fontSize: 14,
  },
  descriptionContainer: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  descriptionText: {
    color: theme.palette.common.black1a,
    fontWeight: 400,
    fontSize: 12,
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tag: {
    marginLeft: 10,
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 12,
    alignSelf: 'center',
    backgroundColor: theme.palette.common.lightyellow,
    textAlign: 'center',
  },
  credits: {
    color: theme.palette.common.green,
    fontWeight: 600,
  },
  debits: {
    color: theme.palette.common.red,
    fontWeight: 600,
  },
  tagText: {
    color: theme.palette.common.black90,
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 400,
  },
  title: {
    color: theme.palette.common.black75,
  },
}));
