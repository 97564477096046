import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    border: `1px solid ${theme.palette.common.black7}`,
    borderRadius: 5,
  },
  buttonsWrapper: {
    gap: '10px',
    [theme.breakpoints.up('lg')]: {
      marginTop: 20,
    },
  },
}));
