import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { VerificationDocumentTypes } from 'models/Document.model';
import { useFormatMessage } from 'apps/intl';
import { StyledZoomableImage, useStyles } from './ChecksByDocument.styles';
import classNames from 'classnames';

export function ChecksByDocument({ docType, country, children, photos, detailsSlot }: {
  docType?: VerificationDocumentTypes;
  country?: string;
  children: React.ReactNode;
  photos?: string[];
  detailsSlot?: React.ReactNode;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Box p={1.5} className={classes.container}>
      <Box mb={1} color="common.black75" fontWeight="bold">
        {docType && formatMessage(`flow.documentTypeStep.${docType}`)}
        {country && `, ${country}`}
      </Box>
      {detailsSlot}
      {(photos?.length > 0) && (
        <Grid container spacing={1}>
          {photos.map((photo, index) => (
            <Grid key={index} item xs={6}>
              <Box className={classNames(classes.image, 'fs-exclude')}>
                <StyledZoomableImage src={photo} alt={docType} />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      <Box mt={2} className={classes.wrapper}>
        {children}
      </Box>
    </Box>
  );
}
