export enum RiskScoreTypes {
  NO_RISK = 'noRisk',
  WARNING = 'warning',
  HIGH_RISK = 'highRisk'
}

export const riskScoreLimits = {
  [RiskScoreTypes.NO_RISK]: 33.3,
  [RiskScoreTypes.WARNING]: 66.6,
  [RiskScoreTypes.HIGH_RISK]: 100,
};
