import { IFlow } from 'models/Flow.model';
import { Product, ProductInputTypes, ProductTypes, ProductIntegrationTypes, ProductSettings } from 'models/Product.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { BankAccountDataVerification } from '../components/BankAccountDataVerification/BankAccountDataVerification';
import { BankAccountDataSettings } from '../components/BankAccountDataSettings/BankAccountDataSettings';
import { BankLogo } from '../components/BankLogo/BankLogo';
import { getBankAccountData, BankAccountDataSettingTypes, BankAccountDataSourcesTypes, IBankAccountDataVerification, BankAccountDataCheckTypes, SUPPORTED_COUNTRIES, DEFAULT_VALIDATION_MONTH, DEFAULT_VALIDATION_RECENCY, BankAccountDataStepTypes } from '../models/BankAccountData.model';

type ProductSettingsBankAccountData = ProductSettings<BankAccountDataSettingTypes>;

export class BankAccountData extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.BankAccountData;
  order = 1200;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
  ];
  icon = BankLogo;
  inputs = [
    ProductInputTypes.AccountCredentials,
    ProductInputTypes.BankStatements,
  ];
  checks = [
    {
      id: BankAccountDataCheckTypes.IncomeCheck,
      isActive: true,
    },
    {
      id: BankAccountDataCheckTypes.LoanRepaymentCheck,
      isActive: true,
    },
    {
      id: BankAccountDataCheckTypes.AverageBalanceCheck,
      isActive: true,
    },
    {
      id: BankAccountDataCheckTypes.ExpensesCheck,
      isActive: true,
    },
    {
      id: BankAccountDataCheckTypes.RecencyCheck,
      isActive: true,
    },
    {
      id: BankAccountDataCheckTypes.AlterationDetection,
      isActive: true,
    },
  ];
  component = BankAccountDataSettings;
  componentVerification = BankAccountDataVerification;

  parser(flow: IFlow): ProductSettingsBankAccountData {
    const verificationPatterns = flow?.verificationPatterns;
    const hasParsing = verificationPatterns[VerificationPatternTypes.FinancialInformationBankStatementParsingRetrieving];
    const hasScraping = verificationPatterns[VerificationPatternTypes.FinancialInformationBankAccountsRetrieving];
    const { financialInformationBankAccountsRetrieving, financialInformationBankStatementParsingRetrieving } = flow;
    const countryCodes = hasParsing ? financialInformationBankStatementParsingRetrieving.countryCodes : financialInformationBankAccountsRetrieving.countryCodes;
    const checks = hasParsing ? financialInformationBankStatementParsingRetrieving?.checks : financialInformationBankAccountsRetrieving?.checks;
    const validationMonth = hasParsing ? (financialInformationBankStatementParsingRetrieving?.validationMonth ?? DEFAULT_VALIDATION_MONTH) : null;
    const validationRecency = hasParsing ? (financialInformationBankStatementParsingRetrieving?.validationRecency ?? DEFAULT_VALIDATION_RECENCY) : null;

    const dataSources = [];
    if (hasScraping) {
      dataSources.push(BankAccountDataSourcesTypes.Login);
    }
    if (hasParsing) {
      dataSources.push(BankAccountDataSourcesTypes.Upload);
    }
    return {
      [BankAccountDataSettingTypes.CountryCodes]: {
        value: countryCodes || [],
        error: countryCodes.length === 0 ? 'FlowBuilder.issue.countriesNotSpecified' : null,
      },
      [BankAccountDataSettingTypes.Checks]: {
        value: checks || {},
        error: (checks && Object.keys(checks).length > 0 && Object.values(checks)[0]?.thresholds?.length !== countryCodes?.length)
          ? 'FlowBuilder.issue.checkNotFullyConfigured'
          : null,
      },
      [BankAccountDataSettingTypes.DataSources]: {
        value: dataSources,
        error: dataSources.length === 0 ? 'FlowBuilder.issue.dataSoursesNotSpecified' : null,
      },
      [BankAccountDataSettingTypes.ValidationMonth]: {
        value: validationMonth,
      },
      [BankAccountDataSettingTypes.ValidationRecency]: {
        value: validationRecency,
      },
    };
  }

  serialize(settings: ProductSettingsBankAccountData): Partial<IFlow> {
    return {
      financialInformationBankAccountsRetrieving: {
        countryCodes: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Login) ? settings.countryCodes.value : [],
        checks: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Login) ? settings.checks.value : {},
      },
      financialInformationBankStatementParsingRetrieving: {
        countryCodes: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Upload) ? settings.countryCodes.value : [],
        checks: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Upload) ? settings.checks.value : {},
        validationMonth: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Upload) ? (settings.validationMonth?.value ?? DEFAULT_VALIDATION_MONTH) : null,
        validationRecency: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Upload) ? (settings.validationRecency?.value ?? DEFAULT_VALIDATION_RECENCY) : null,
      },
      verificationPatterns: {
        [VerificationPatternTypes.FinancialInformationBankAccountsRetrieving]: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Login),
        [VerificationPatternTypes.FinancialInformationBankStatementParsingRetrieving]: settings.dataSources.value.includes(BankAccountDataSourcesTypes.Upload),
      },
    };
  }

  onAdd(): Partial<IFlow> {
    return {
      financialInformationBankAccountsRetrieving: {
        countryCodes: [...SUPPORTED_COUNTRIES],
        checks: {},
      },
      financialInformationBankStatementParsingRetrieving: {
        countryCodes: [...SUPPORTED_COUNTRIES],
        checks: {},
        validationMonth: DEFAULT_VALIDATION_MONTH,
        validationRecency: DEFAULT_VALIDATION_RECENCY,
      },
      verificationPatterns: {
        [VerificationPatternTypes.FinancialInformationBankAccountsRetrieving]: true,
        [VerificationPatternTypes.FinancialInformationBankStatementParsingRetrieving]: true,
      },
    };
  }

  onRemove(): Partial<IFlow> {
    return {
      financialInformationBankAccountsRetrieving: {
        countryCodes: [],
        checks: {},
      },
      financialInformationBankStatementParsingRetrieving: {
        countryCodes: [],
        checks: {},
        validationMonth: DEFAULT_VALIDATION_MONTH,
        validationRecency: DEFAULT_VALIDATION_RECENCY,
      },
      verificationPatterns: {
        [VerificationPatternTypes.FinancialInformationBankAccountsRetrieving]: false,
        [VerificationPatternTypes.FinancialInformationBankStatementParsingRetrieving]: false,
      },
    };
  }

  isInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.FinancialInformationBankAccountsRetrieving] || flow?.verificationPatterns?.[VerificationPatternTypes.FinancialInformationBankStatementParsingRetrieving];
  }

  getVerification(verification: VerificationResponse): IBankAccountDataVerification {
    return getBankAccountData(verification);
  }

  isInVerification(verification: VerificationResponse): boolean {
    return verification.steps?.some((dataStep) => dataStep.id === BankAccountDataStepTypes.FinancialInformationValidation);
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const step = verification.steps.find((dataStep) => dataStep.id === BankAccountDataStepTypes.FinancialInformationValidation);
    return !!step?.error;
  }
}
