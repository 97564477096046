import { LoadableAdapter } from 'lib/Loadable.adapter';
import { types } from 'state/merchant/merchant.actions';
import { types as collaboratorActiontypes } from './collaborator.actions';
import { createReducer } from 'state/store.utils';
import { CollaboratorActionGroups, CollaboratorSliceName } from './collaborator.store';

const initialState = {
  [CollaboratorSliceName.CollaboratorList]: LoadableAdapter.createState([]),
  [CollaboratorSliceName.Collaborator]: LoadableAdapter.createState(),
  [CollaboratorSliceName.CollaboratorRole]: LoadableAdapter.createState(),
};

export default createReducer(initialState, {
  ...LoadableAdapter.createHandlers(CollaboratorActionGroups.CollaboratorList, CollaboratorSliceName.CollaboratorList),
  ...LoadableAdapter.createHandlers(CollaboratorActionGroups.Collaborator, CollaboratorSliceName.Collaborator),
  ...LoadableAdapter.createHandlers(CollaboratorActionGroups.CollaboratorRole, CollaboratorSliceName.CollaboratorRole),
  [types.TRANSFER_OWNERSHIP_SUCCESS](state, { payload }) {
    return {
      ...state,
      collaboratorList: {
        ...state.collaboratorList,
        value: payload.collaborators,
      },
      collaborator: {
        ...state.collaborator,
        value: {
          ...state.collaborator.value,
          roleId: payload.collaborators.find((collaborator) => (collaborator?.user?.id === state.collaborator?.value?.user?.id))?.roleId,
        },
      },
    };
  },
  [collaboratorActiontypes.COLLABORATOR_ROLE_SUCCESS](state, { payload }) {
    return {
      ...state,
      collaboratorList: {
        ...state.collaboratorList,
        value: state?.collaboratorList?.value?.map((colaboratorObj) => {
          if (colaboratorObj?.user?.id === payload?.collaboratorId) {
            return {
              ...colaboratorObj,
              roleId: payload?.roleId,
            };
          }
          return colaboratorObj;
        }),
      },
      collaborator: {
        ...state.collaborator,
        value: {
          ...state.collaborator.value,
          roleId: state.collaborator.value?.user?.id === payload?.collaboratorId ? payload?.roleId : state?.collaborator?.value?.roleId,
        },
      },
      collaboratorRole: {
        ...state.collaboratorRole,
        value: {
          ...state.collaboratorRole.value,
          ...payload,
        },
      },
    };
  },
});
