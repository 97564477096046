import { EmailCheck } from 'apps/EmailCheck/services/EmailCheck.service';
import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';

export const emailCheckInit = (registered: ProductTypes[] = []) => (_, getState): ProductTypes => {
  // don't register old version if PRS version is availible
  if (registered.includes(ProductTypes.EmailCheck)) {
    return null;
  }

  const emailCheck = new EmailCheck({
    disabled: !selectMerchantTags(getState()).includes(MerchantTagsTypes.CanUseEmailValidation),
  });
  productManagerService.register(emailCheck);
  return emailCheck.id;
};
