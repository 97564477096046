import { IProductCard, Product, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import React, { useMemo } from 'react';
import { UIProductTab } from '../UIProductTab/UIProductTab';
import { productManagerService } from '../../services/ProductManager.service';

export function ProductTab({ id, isSelected, onSelect, verification, isDisabled }: {
  id: ProductTypes;
  isSelected: boolean;
  onSelect: (id: ProductTypes) => void;
  verification: VerificationResponse;
  isDisabled?: boolean;
}) {
  const product: Product = productManagerService.getProduct(id);
  const card: IProductCard = useMemo(() => product?.getCard(), [product]);
  let isFailed = false;
  let isProductTabCrashed = false;

  try {
    if (!product?.isInVerification(verification as any)) {
      return null;
    }
    isFailed = product?.hasFailedCheck(verification as any);
  } catch (error) {
    // usually it will only fall during the initial render when verification is an empty object
    // and there are missed nullish checks in isInVerification or hasFailedCheck methods
    console.error(error);
    isProductTabCrashed = true;
  }

  return (
    <UIProductTab
      card={card}
      onSelect={onSelect}
      isSelected={isSelected}
      hasBadge={isFailed && !isDisabled && !isProductTabCrashed}
      isDisabled={isDisabled || isProductTabCrashed}
    />
  );
}
