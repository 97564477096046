import React from 'react';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import { ElementTypes } from '../../models/TextEditor.model';

export const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case ElementTypes.BlockQuote:
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case ElementTypes.BulletedList:
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case ElementTypes.HeadingOne:
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case ElementTypes.HeadingTwo:
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case ElementTypes.ListItem:
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case ElementTypes.NumberedList:
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case ElementTypes.Paragraph:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
    default:
      return children;
  }
};
