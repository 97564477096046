import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { BoxBordered } from 'apps/ui';
import { StepError } from 'models/Step.model';
import { EmailRiskValidationStepData, EmailValidationStepData } from 'models/EmailCheck.model';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './EmailResult.style';
import { Valid, InValid } from '../../assets';

export function EmailResult({ title, data, error, subTitle = '' }: {
  title: string,
  subTitle?: string,
  data: EmailRiskValidationStepData | EmailValidationStepData,
  error: StepError
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <BoxBordered>
      <Grid>
        <Box className={classes.content}>
          <Box>
            {!error ? <Valid /> : <InValid />}
          </Box>
          <Box>
            <Grid>
              <Typography className={classNames(classes.title, { [classes.green]: !error, [classes.red]: error })}>{formatMessage(title)}</Typography>
              <Typography className={classes.subTitle}>{formatMessage(subTitle)}</Typography>
              {data?.emailAddress && <Typography className={classes.emailTitle}>{formatMessage('EmailRisk.result.email')}</Typography>}
              <Typography className={classNames(classes.email, 'fs-exclude')}>{data?.emailAddress}</Typography>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </BoxBordered>
  );
}
