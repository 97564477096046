import React from 'react';
import { CheckBarExpandable } from 'apps/checks';
import { PremiumAmlWatchlistStep } from 'models/Document.model';
import { PremiumAmlWatchlistsStepDetails } from '../PremiumAmlWatchlistsStepDetails/PremiumAmlWatchlistsStepDetails';

export const LegacyPremiumWatchlists = ({ step, isAmlMonitoringAvailable }:
  { step: PremiumAmlWatchlistStep; isAmlMonitoringAvailable?: boolean }) => (
    <CheckBarExpandable step={step}>
      <PremiumAmlWatchlistsStepDetails
        name={step.data?.nameSearched}
        dateOfBirth={step.data?.dateOfBirth}
        countries={step.data?.countriesSearched?.join(', ')}
        profileUrl={step.data?.profileUrl}
        searchId={step.data?.searchId}
        status={step.checkStatus}
        isMonitored={step.data?.isMonitored}
        isMonitoringAvailable={isAmlMonitoringAvailable}
        matchType={step.data?.matchType}
        errorCode={step.error?.code}
      />
    </CheckBarExpandable>
);
