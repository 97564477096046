import { BiometricTypes } from 'models/Biometric.model';

export const DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD = 99;
export const MIN_DUPLICATE_FACE_DETECTION_THRESHOLD = 40;
export const MAX_DUPLICATE_FACE_DETECTION_THRESHOLD = 100;
export const MAX_DUPLICATE_FACE_DETECTION_THRESHOLD_FRACTION = 3;

// Changes for new workflow builder UI
// export const HIGH_PRECISION_FACE_DETECTION_THRESHOLD = 99;
// export const MEDIUM_PRECISION_FACE_DETECTION_THRESHOLD = 80;
// export const LOW_PRECISION_FACE_DETECTION_THRESHOLD = 20;
// export const DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD = HIGH_PRECISION_FACE_DETECTION_THRESHOLD;

export enum BiometricVerificationCheckTypes {
  Liveness = 'liveness',
  VoiceLiveness = 'voice+liveness'
}

export enum BiometricVerificationSettingsTypes {
  Biometrics = 'biometricVerification',
  DuplicateFaceDetection = 'duplicateFaceDetection',
  DuplicateFaceDetectionThreshold = 'duplicateFaceMatchThreshold',
  IsDuplicateFaceRejection= 'isDuplicateFaceRejection',
  AdvancedLivenessConfiguration = 'advancedLivenessConfiguration',
  BiometricsVerification = 'biometrics',
  BiometricsVerificationPattern = 'biometricsVerificationPattern',
  CanUseAgeConsistencyCheck = 'canUseAgeConsistencyCheck',
  AgeConsistencyCheckEnabled = 'ageConsistencyCheckEnabled',
  IsLivenessRejection = 'isLivenessRejection',
  DuplicateFaceDetectionSettings= 'duplicateFaceDetectionSettings',
}

export enum BiometricVerificationTypes {
  SelfiePhoto = 'selfiePhoto',
  SelfieVideo = 'selfieVideo',
  LivenessNistPassive = 'liveness-nist-passive',
  LivenessNistActive = 'liveness-nist-active',
}

export enum BiometricVerificationInputTypes {
  VoiceVideo = 'voice-video',
  SelfieVideo = 'selfie-video',
  SelfiePhoto = 'selfie-photo',
}

export const BiometricVerificationExampleFiles = {
  [BiometricVerificationInputTypes.SelfiePhoto]: '--form selfie=@/C:/PATH_TO_FILE/selfie-photo.jpg',
  [BiometricVerificationInputTypes.SelfieVideo]: '--form video=@/C:/PATH_TO_FILE/Liveness_video.mp4',
  [BiometricVerificationInputTypes.VoiceVideo]: '--form video=@/C:/PATH_TO_FILE/Liveness_video.mp4',
};

export enum BiometricVerificationThresholdErrorTypes {
  OutOfRange = 'outOfRange',
}

export enum BiometricVerificationThresholdOptionTypes {
  RecommendedThreshold = 'recommendedThreshold',
  CustomThreshold = 'customThreshold',
}

export enum BiometricDuplicateUserDetectionFlowTypes {
  ManualReview = 'manualReview',
  Rejected = 'rejected',
}

export function getVerificationType(value: string): BiometricVerificationTypes {
  // TODO: wtf?!
  switch (value) {
    case BiometricTypes.selfie:
      return BiometricVerificationTypes.SelfiePhoto;
    case BiometricVerificationTypes.LivenessNistActive:
      return BiometricVerificationTypes.LivenessNistActive;
    case BiometricVerificationTypes.LivenessNistPassive:
      return BiometricVerificationTypes.LivenessNistPassive;
    default:
      return BiometricVerificationTypes.SelfieVideo;
  }
}

export function getBiometricType(verificationType: BiometricVerificationTypes): string {
  if (verificationType === BiometricVerificationTypes.SelfiePhoto) {
    return BiometricTypes.selfie;
  }
  if (verificationType === BiometricVerificationTypes.LivenessNistActive || verificationType === BiometricVerificationTypes.LivenessNistPassive) {
    return verificationType;
  }
  return BiometricTypes.liveness;
}

export function hasDuplicateFaceDetectionThresholdError(threshold: number) {
  return threshold < MIN_DUPLICATE_FACE_DETECTION_THRESHOLD || threshold > MAX_DUPLICATE_FACE_DETECTION_THRESHOLD;
}

export function getDuplicateFaceDetectionThresholdOptionType(value: string): BiometricVerificationThresholdOptionTypes {
  if (BiometricVerificationThresholdOptionTypes.CustomThreshold === value) {
    return BiometricVerificationThresholdOptionTypes.CustomThreshold;
  }
  return BiometricVerificationThresholdOptionTypes.RecommendedThreshold;
}

export enum BiometricVerificationChallengeTypes {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE'
}

export enum BiometricVerificationNistProviderTypes {
  IDEMIA = '4I',
}

export enum BiometricVerificationNistInputTypes {
  LivenessNist = 'liveness-nist',
}
export interface IBiometricVerification {
  input: BiometricVerificationNistInputTypes;
  challenge?: BiometricVerificationChallengeTypes;
  provider?: BiometricVerificationNistProviderTypes;
}

export enum LivenessRejectionTypes {
  Rejected = 'true',
  ManualReview = 'false'
}

export enum VerificationStatusFilterTypes {
  Verified = 'verified',
  Rejected = 'rejected',
  ReviewNeeded = 'reviewNeeded',
}

export interface IVerificationStatusFilterStatus {
  status: VerificationStatusFilterTypes;
  manualChange?: boolean;
}

export interface IDuplicateFaceDetectionSettings {
  verificationStatusFilter?: IVerificationStatusFilterStatus[];
}

export const VerificationStatusFilter = Object.values(VerificationStatusFilterTypes);

export const defaultVerificationStatusFilter = [
  {
    status: VerificationStatusFilterTypes.Verified,
    manualChange: true,
  },
  {
    status: VerificationStatusFilterTypes.Rejected,
    manualChange: true,
  },
  {
    status: VerificationStatusFilterTypes.ReviewNeeded,
    manualChange: true,
  },
];

export interface IAdvancedLivenessConfig {
  isDigitalSpoofEnabled: boolean;
  isFaceEvasionEnabled: boolean;
  isAgeVerificationEnabled: boolean;
  ageVerificationThreshold: number;
}
