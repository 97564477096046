import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

export function isBlobString(blobString) {
  return typeof blobString === 'string' && blobString.includes('blob:');
}

export function downloadBlob(blob, fileName) {
  const url = isBlobString(blob) ? blob : window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function openBlob(blob) {
  const blobURL = URL.createObjectURL(blob);
  window.open(blobURL);
}

export function downloadUrl(publicUrl, fileName) {
  const a = document.createElement('a');
  a.href = publicUrl;
  a.download = fileName;
  a.target = '_blank';
  a.rel = 'noopener';
  a.click();
}

export function downloadAsZip(files, zipFileName) {
  const zip = new JSZip();
  let count = 0;
  files.forEach(({ file, fileName }) => {
    file.then((resolvedFile) => {
      JSZipUtils.getBinaryContent(resolvedFile, async (error, data) => {
        if (error) {
          throw error;
        }
        zip.file(fileName, data, { binary: true });
        count += 1;
        if (count === files.length) {
          const zipFile = await zip.generateAsync({ type: 'blob' });
          downloadBlob(zipFile, zipFileName);
        }
      });
    });
  });
}
