import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ExtendedDescription } from 'apps/ui';
import { BiometricVerificationChallengeTypes, BiometricVerificationSettingsTypes, BiometricVerificationTypes, IAdvancedLivenessConfig, IBiometricVerification, IVerificationStatusFilterStatus, defaultVerificationStatusFilter } from 'apps/biometricVerification/models/BiometricVerification.model';
import cloneDeep from 'lodash/cloneDeep';
import { ProductSettingsProps, ProductTypes } from 'models/Product.model';
import { useFormatMessage } from 'apps/intl';
import { selectFlowBuilderProductsInGraphModel } from 'apps/flowBuilder';
import { BiometricConfiguration } from './BiometricConfiguration/BiometricConfiguration';

export function BiometricVerificationSettings({ settings, onUpdate }: ProductSettingsProps<BiometricVerificationSettingsTypes>) {
  const formatMessage = useFormatMessage();
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraphModel);
  const isDocumentInProductGraph = productsInGraph.value.includes(ProductTypes.DocumentVerification);

  const handleBiometricsChanges = useCallback((biometrics: Nullable<string>, biometricVerificationPattern?: boolean, biometricsVerification?: Nullable<IBiometricVerification>): void => {
    const newSettings = cloneDeep(settings);
    if (biometricsVerification) {
      newSettings[BiometricVerificationSettingsTypes.BiometricsVerification] = {
        value: biometricsVerification,
      };
    } else {
      newSettings[BiometricVerificationSettingsTypes.BiometricsVerification] = {
        value: { input: null, challenge: null, provider: null },
      };
    }
    if (newSettings[BiometricVerificationSettingsTypes.BiometricsVerificationPattern].value !== BiometricVerificationTypes.SelfiePhoto) {
      newSettings[BiometricVerificationSettingsTypes.AdvancedLivenessConfiguration].value = {
        isDigitalSpoofEnabled: false,
        isFaceEvasionEnabled: false,
        isAgeVerificationEnabled: false,
        ageVerificationThreshold: undefined,
      };
    }
    newSettings[BiometricVerificationSettingsTypes.Biometrics].value = biometrics;
    newSettings[BiometricVerificationSettingsTypes.BiometricsVerificationPattern].value = !!biometricVerificationPattern;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleBiometricsDuplicateUserThresholdChanges = useCallback((threshold: Nullable<number>): void => {
    const newSettings = cloneDeep(settings);
    newSettings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold].value = threshold;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handlePatternsChange = useCallback(() => {
    const newSettings = cloneDeep(settings);
    const updatedValue = !settings[BiometricVerificationSettingsTypes.DuplicateFaceDetection].value;
    newSettings[BiometricVerificationSettingsTypes.DuplicateFaceDetection].value = updatedValue;
    if (updatedValue) {
      newSettings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings].value = defaultVerificationStatusFilter;
    }
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleBiometricsDuplicateUserFlowStatusChange = useCallback((status: boolean) => {
    const newSettings = cloneDeep(settings);
    newSettings[BiometricVerificationSettingsTypes.IsDuplicateFaceRejection].value = status;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleAgeConsistencyCheckToggle = useCallback(() => {
    const newSettings = cloneDeep(settings);
    newSettings[BiometricVerificationSettingsTypes.AgeConsistencyCheckEnabled].value = !newSettings[BiometricVerificationSettingsTypes.AgeConsistencyCheckEnabled].value;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleLivenessRejectionChange = useCallback((updatedValue: boolean) => {
    const newSettings = cloneDeep(settings);
    newSettings[BiometricVerificationSettingsTypes.IsLivenessRejection].value = updatedValue;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleDuplicateFaceDetectionSettingsChange = useCallback((updatedValue: IVerificationStatusFilterStatus[]) => {
    const newSettings = cloneDeep(settings);
    newSettings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings].value = updatedValue;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const getBioMetricValue = useCallback(() => {
    if (settings[BiometricVerificationSettingsTypes.BiometricsVerificationPattern].value && settings[BiometricVerificationSettingsTypes.BiometricsVerification].value?.challenge) {
      if (settings[BiometricVerificationSettingsTypes.BiometricsVerification].value?.challenge === BiometricVerificationChallengeTypes.ACTIVE) {
        return BiometricVerificationTypes.LivenessNistActive;
      }
      return BiometricVerificationTypes.LivenessNistPassive;
    }
    return settings[BiometricVerificationSettingsTypes.Biometrics].value;
  }, [settings]);

  const handleChangeAdvancedLivenessConfiguration = useCallback((updatedValue: IAdvancedLivenessConfig) => {
    const newSettings = cloneDeep(settings);
    newSettings[BiometricVerificationSettingsTypes.AdvancedLivenessConfiguration].value = updatedValue;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  return (
    <Box>
      <ExtendedDescription
        title={formatMessage('ReVerification.settings.biometrics.title')}
        text={formatMessage('ReVerification.settings.biometrics.description')}
      >
        <BiometricConfiguration
          duplicateFaceDetection={settings[BiometricVerificationSettingsTypes.DuplicateFaceDetection].value}
          duplicateFaceDetectionThreshold={settings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionThreshold].value}
          biometrics={getBioMetricValue()}
          proofOfOwnership={settings[BiometricVerificationSettingsTypes.Biometrics].isCantBeUsedWithOtherSetting}
          isDuplicateFaceRejection={settings[BiometricVerificationSettingsTypes.IsDuplicateFaceRejection].value}
          onUpdate={handleBiometricsChanges}
          onThresholdChange={handleBiometricsDuplicateUserThresholdChanges}
          onPatternsChange={handlePatternsChange}
          onDuplicateUserFlowStatusChange={handleBiometricsDuplicateUserFlowStatusChange}
          onAgeConsistencyCheckToggle={handleAgeConsistencyCheckToggle}
          canUseAgeConsistencyCheck={settings[BiometricVerificationSettingsTypes.CanUseAgeConsistencyCheck]?.value && isDocumentInProductGraph}
          isAgeConsistencyCheckEnabled={settings[BiometricVerificationSettingsTypes.AgeConsistencyCheckEnabled].value}
          sdkError={!!settings[BiometricVerificationSettingsTypes.BiometricsVerificationPattern]?.error}
          isLivenessRejection={!!settings[BiometricVerificationSettingsTypes.IsLivenessRejection].value}
          onLivenessRejectionChange={handleLivenessRejectionChange}
          duplicateFaceDetectionSettingsError={!!settings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings].error}
          duplicateFaceDetectionSettings={settings[BiometricVerificationSettingsTypes.DuplicateFaceDetectionSettings].value}
          onDuplicateFaceDetectionSettingsChange={handleDuplicateFaceDetectionSettingsChange}
          advancedLivenessConfiguration={settings[BiometricVerificationSettingsTypes.AdvancedLivenessConfiguration].value}
          onChangeAdvancedLivenessConfiguration={handleChangeAdvancedLivenessConfiguration}
        />
      </ExtendedDescription>
    </Box>
  );
}
