import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classnames from 'classnames';
import { CountryCode } from 'apps/countries';
import { ICountriesSettings } from 'apps/documentVerification/models/DocumentVerification.model';
import { useStyles } from './DocumentSubTypesSideBar.styles';
import { DocumentSubTypesCountrySelector } from '../DocumentSubTypesCountrySelector/DocumentSubTypesCountrySelector';

export function DocumentSubTypesSideBar({ countriesData, region, selectedCountry, setSelectedCountry, selectedSubTypes }: {
  countriesData: {country: string; noOfDocumentSubTypes: number}[] ;
  region: string;
  selectedCountry?: CountryCode;
  setSelectedCountry: (value: CountryCode) => void;
  selectedSubTypes: ICountriesSettings[];
}) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleChangeExpanded = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const handleChangeSelectedCountry = (country: CountryCode) => () => {
    setSelectedCountry(country);
  };

  const getNoOfSelected = useCallback((country: string): number | undefined => {
    const currentDeSelectedSubTypes = selectedSubTypes.find(
      (data) => data.country === country,
    );
    const totalSelected = currentDeSelectedSubTypes?.documentSubtypes ? currentDeSelectedSubTypes.documentSubtypes.length : 0;
    return totalSelected === 0 ? undefined : totalSelected;
  }, [selectedSubTypes]);

  return (
    <>
      <Button
        onClick={handleChangeExpanded}
        className={classes.regiondBlock}
      >
        {region}
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      {isExpanded && (
        countriesData.map((data) => (
          <Button
            key={data.country}
            onClick={handleChangeSelectedCountry(data.country)}
            className={classnames(classes.countryBlock, { [classes.selectedCountry]: data.country === selectedCountry })}
          >
            <DocumentSubTypesCountrySelector
              country={data.country}
              noOfSelected={getNoOfSelected(data.country)}
            >
              <NavigateNextIcon className={classnames(classes.countryIcon, { [classes.selectedCountryIcon]: data.country === selectedCountry })} />
            </DocumentSubTypesCountrySelector>
          </Button>
        )))}
    </>
  );
}
