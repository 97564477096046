import { useScrollMeIntoView } from 'lib/ScrollIntoView';
import Box from '@material-ui/core/Box';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { useMerchantPlan } from 'apps/merchant';
import { ExtendedDescription, Warning } from 'apps/ui';
import { CustomFieldProductSettingsProps, CustomFieldSettingTypes } from '../../models/CustomField.model';
import { CustomFieldContainer } from '../CustomFieldContainer/CustomFieldContainer';

export function CustomFieldSettings({ settings, onUpdate, disabled }: CustomFieldProductSettingsProps) {
  const formatMessage = useFormatMessage();
  const { isFreemium } = useMerchantPlan();
  const ScrollMeIntoView = useScrollMeIntoView();

  return (
    <Box>
      {disabled
        && (
          <Box mb={2}>
            {isFreemium ? (
              <Warning
                label={formatMessage('Freemium.productNotAvailable')}
                isLabelColored={false}
                meritName={formatMessage('CustomDocument')}
                bordered
              />
            ) : (
              <Warning
                label={formatMessage('CustomDocuments.settings.customDocumentNotAvailable')}
                linkLabel={formatMessage('CustomDocuments.settings.helpEmail')}
                isLabelColored={false}
                meritName={formatMessage('CustomField.card.title')}
                bordered
              />
            )}
          </Box>
        )}
      <Box>
        {settings[CustomFieldSettingTypes.Fields]?.error && !disabled && (
          <ScrollMeIntoView />
        )}
        <ExtendedDescription
          isDisabled={disabled}
          title={formatMessage('CustomField.card.check.title')}
          text={formatMessage('CustomField.card.check.subTitle')}
          error={settings[CustomFieldSettingTypes.Fields]?.error && formatMessage(settings[CustomFieldSettingTypes.Fields].error)}
        />
      </Box>
      <CustomFieldContainer disabled={disabled} onUpdate={onUpdate} settings={settings} />
    </Box>
  );
}
