import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  line: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.common.gray70,
    margin: '24px auto',
  },
}));
