import React, { useEffect } from 'react';
import { useThrowErrorFromRender } from 'apps/flowBuilder/hooks/useThrowErrorFromRender';

export const ErrorListener = ({ children }) => {
  const throwError = useThrowErrorFromRender();
  useEffect(() => {
    const handleGlobalError = (error) => {
      console.warn('Supressed error from render ', error);
      // throwError('FlowBuilder merit error');
      // return `true` will supress all uncaught erors;
      return true;
    };

    window.addEventListener('error', handleGlobalError);

    return () => window.removeEventListener('error', handleGlobalError);
  }, [throwError]);

  return children;
};
