import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  productFrame: {
    border: 0,
    width: '100%',
    boxSizing: 'border-box',
  },
  overlay: {
    maxWidth: '80vw',
  },
}));
