import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { TabIDTypes, InformationImageTypes } from '../../models/ForDevelopers.model';
import { GithubDocumentationBanner } from '../GithubDocumentationBanner/GithubDocumentationBanner';
import { InformationImage } from '../InformationImage/InformationImage';
import { VideoExplainer } from '../VideoExplainer/VideoExplainer';
import { WebCodeSnippet } from '../WebCodeSnippet/WebCodeSnippet';

export function WebIntegrationInformation() {
  const formatMessage = useFormatMessage();

  return (
    <Box key={TabIDTypes.Web}>
      <Box mb={4}>
        <GithubDocumentationBanner
          tabId={TabIDTypes.Web}
          documentationURL="https://docs.metamap.com/docs/sdk"
          platform={formatMessage(`forDevs.sideMenu.${TabIDTypes.Web}`)}
        />
      </Box>
      <Typography
        variant="subtitle2"
        gutterBottom
      >
        {formatMessage(`forDevs.informationPage.${TabIDTypes.Web}.header`)}
      </Typography>
      <Box
        mb={2}
        color="common.black75"
      >
        {formatMessage(`forDevs.informationPage.${TabIDTypes.Web}.subheader`)}
      </Box>
      <Box mb={4}>
        <InformationImage type={InformationImageTypes.Web} />
      </Box>
      <Box
        pt={4}
        mb={4}
        borderTop={1}
        borderColor="common.black7"
      >
        <WebCodeSnippet />
      </Box>
      <Box
        pt={4}
        borderTop={1}
        borderColor="common.black7"
      >
        <VideoExplainer
          videoCover="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati%2BWeb%2BSDK.png"
          videoURL="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati+Web+SDK.mp4"
          tabId={TabIDTypes.Web}
        />
      </Box>
    </Box>
  );
}
