import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    boxShadow: 'none',
    border: 'none',
    filter: 'none',
  },
  cardContent: {
    padding: '10px 0 0 !important',
  },
}));
