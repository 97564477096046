import { ProductTypes } from 'models/Product.model';
import React, { useCallback, useState } from 'react';
import { productManagerService } from '../../services/ProductManager.service';
import { ProductCard } from '../ProductCard/ProductCard';

export function ProductCardList({ cardIds, onModalProductSelect, defaultProducTypeId }: {
  cardIds: ProductTypes[];
  onModalProductSelect: (productId: ProductTypes) => void;
  defaultProducTypeId: Nullable<ProductTypes>;
}) {
  const [selectedProductId, setSelectedProductId] = useState<Nullable<ProductTypes>>(defaultProducTypeId);

  const handleModalProductSelect = useCallback((productId: ProductTypes) => {
    onModalProductSelect(productId);
    setSelectedProductId(productId);
  }, [onModalProductSelect]);

  return (
    <>
      {cardIds.map((cardId) => {
        const productCard = productManagerService.getProduct(cardId).getCard();
        return (
          <ProductCard
            key={productCard.id}
            id={productCard.id}
            isExpandable={false}
            defaultExpanded={false}
            onModalProductSelect={handleModalProductSelect}
            isActive={selectedProductId === productCard.id}
          />
        );
      })}
    </>
  );
}
