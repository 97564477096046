import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  activateButton: {
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 4,
    width: 134,
  },
}));
