import React from 'react';
import { CheckBarExpandable, CheckStepDetails } from 'apps/checks';
import { useFormatMessage } from 'apps/intl';
import { GovernmentChecksStep } from 'models/GovernmentChecksMerit.model';
import { getCheckTitle } from '../../../../models/GovCheckMerit.model';

export const GovCheckInfo = ({ step }: {step: GovernmentChecksStep}) => {
  const formatMessage = useFormatMessage();
  const title = getCheckTitle(step);
  return (
    <CheckBarExpandable title={title} step={step}>
      <CheckStepDetails step={step}>
        {formatMessage(`SecurityCheckStep.govCheck.${step.checkStatus}`)}
      </CheckStepDetails>
    </CheckBarExpandable>
  );
};
