import { LoadableAdapter } from 'lib/Loadable.adapter';
import { Loadable } from 'models/Loadable.model';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountryAndWorldPartsModel } from '../state/CountryAndWorldPart.selectors';
import { ICountryAndWorldPartMetadata } from '../models/CountryAndWorldPart.model';
import { getCountryAndWorldParts } from '../state/CountryAndWorldPart.actions';

export function useLoadCountryAndWorldParts(isAuthorized: boolean): Loadable<ICountryAndWorldPartMetadata[]> {
  const dispatch = useDispatch();
  const countryAndWorldPartsModel = useSelector<any, Loadable<ICountryAndWorldPartMetadata[]>>(selectCountryAndWorldPartsModel);

  useEffect(() => {
    if (isAuthorized && LoadableAdapter.isPristine(countryAndWorldPartsModel)) {
      try {
        dispatch(getCountryAndWorldParts());
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, countryAndWorldPartsModel, isAuthorized]);

  return countryAndWorldPartsModel;
}
