import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px',
    gap: '16px',
    background: theme.palette.common.white,
    color: theme.palette.common.black75,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    borderBottom: `1px solid ${theme.palette.common.black7}`,
    borderLeft: `1px solid ${theme.palette.common.black7}`,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '4px',
    width: '100%',
  },
  description: {
    display: 'flex',
    padding: '6px 0px',
  },
  link: {
    borderRadius: 9999,
    width: 24,
    height: 24,
    background: theme.palette.common.black7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
