export const CsvActionTypes = {
  CSV_IS_DOWNLOADING: 'SET_IS_DOWNLOADING',
};

export const csvDownloadingStarted = () => (dispatch) => {
  dispatch({ type: CsvActionTypes.CSV_IS_DOWNLOADING, payload: true });
};

export const csvDownloadingFinished = () => (dispatch) => {
  dispatch({ type: CsvActionTypes.CSV_IS_DOWNLOADING, payload: false });
};
