import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const defaultFontSize = 20;

export const useStyles = makeStyles((theme) => ({
  flowName: {
    flexGrow: 3,
    fontSize: defaultFontSize,
    '&:hover': {
      color: theme.palette.common.lightblue,
    },
  },
  editableField: {
    flexGrow: 3,
    '& input': {
      '&::placeholder': {
        color: theme.palette.common.lightblue,
      },
    },
  },
  cssOutlinedInput: {
    paddingRight: 5,
    fontSize: defaultFontSize,
    '&$cssFocused $notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover $notchedOutline': {
      borderColor: 'transparent',
    },
    '& input': {
      padding: '0px 0px',
    },
  },
  cssFocused: {},
  notchedOutline: {
    borderColor: 'transparent',
  },
}));
