import React from 'react';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import { normalizeLink } from 'lib/url';
import { LinkButton, RecordView } from 'apps/ui';
import { useStyles } from './BrazilRecordViewPassed.styles';

export type BrazilRecordViewPassedItem = {
  title: string;
  link: string;
};

export const BrazilRecordViewPassed = ({
  title,
  link,
}: BrazilRecordViewPassedItem) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.institution'),
        value: title,
        gridClass: classes.gridCell,
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.noRecordsFound'),
        gridClass: classnames(classes.gridCell, classes.statusCell),
        extraContent: link && <Box px="24px" py="14px"><LinkButton link={normalizeLink(link)} /></Box>,
      }}
    />
  );
};
