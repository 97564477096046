class Segment {
  static analytics;

  static getSegment() {
    return this.analytics;
  }

  static setSegment(newValue) {
    this.analytics = newValue;
  }
}

export default Segment;
