import React, { ReactNode } from 'react';
import { useSlate } from 'slate-react';
import { BlockFormatTypes, BlockTypes, isBlockActive, TEXT_ALIGN, TextAlignTypes, toggleBlock } from '../../models/TextEditor.model';
import { Button } from '../Button/Button';

export const BlockButton = ({ format, icon }: {
  format: BlockFormatTypes;
  icon: ReactNode;
}) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN.includes(format as TextAlignTypes) ? BlockTypes.Align : BlockTypes.Type,
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};
