import React from 'react';
import { ProductSettingsProps } from 'models/Product.model';
import { POAVerificationTypes } from '../../models/DocumentVerification.model';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Warning } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { selectMerchantTags } from 'state/merchant';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { cloneDeep } from 'lodash';
import { DocumentTypes } from 'models/Document.model';

export function PoaVerificationSettings({ settings, onUpdate }: ProductSettingsProps<POAVerificationTypes>) {
  const formatMessage = useFormatMessage();
  const merchantTags = useSelector(selectMerchantTags);
  const canUseProofOfAddress = merchantTags.includes(MerchantTagsTypes.CanUseProofOfAdress);

  if (settings.steps.error) {
    return (
      <Box>
        <Warning
          label={formatMessage('POAVerification.card.warning.legacy')}
          isLabelColored={false}
          bordered
        />
        {canUseProofOfAddress && (
          <Box mt={1}>
            <Button
              onClick={() => {
                const updated = cloneDeep(settings);
                updated.steps.value = updated.steps.value.map((group) => group.filter((item) => item !== DocumentTypes.ProofOfResidency)).filter(Boolean);
                updated.steps.value.push([DocumentTypes.ProofOfResidency]);
                onUpdate(updated);
              }}
              fullWidth
              variant="contained"
              color="primary"
            >
              {formatMessage('POAVerification.settings.updateSettings')}
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  return null;
}
