import { DateString } from 'lib/date';
import { SubscriptionStatusTypes } from 'apps/SubscriptionStatus';

export enum PermissionSubscriptionStatusTypes {
  canUseWebhooksModal = 'canUseWebhooksModal',
  canUploadLogo = 'canUploadLogo',
  canSwitchIntegrationType = 'canSwitchIntegrationType',
  canCopyWebCodeSnippet = 'canCopyWebCodeSnippet',
  canCopyDirectLink = 'canCopyDirectLink',
  canUsePreviewButton = 'canUsePreviewButton',
  canSeeVerificationQuota = 'canSeeVerificationQuota',
}

export const PermissionsSubscriptionStatus: Record<SubscriptionStatusTypes, Record<PermissionSubscriptionStatusTypes, boolean>> = {
  [SubscriptionStatusTypes.Active]: {
    [PermissionSubscriptionStatusTypes.canUseWebhooksModal]: true,
    [PermissionSubscriptionStatusTypes.canUploadLogo]: true,
    [PermissionSubscriptionStatusTypes.canSwitchIntegrationType]: true,
    [PermissionSubscriptionStatusTypes.canCopyWebCodeSnippet]: true,
    [PermissionSubscriptionStatusTypes.canCopyDirectLink]: true,
    [PermissionSubscriptionStatusTypes.canUsePreviewButton]: true,
    [PermissionSubscriptionStatusTypes.canSeeVerificationQuota]: false,
  },
  [SubscriptionStatusTypes.Trial]: {
    [PermissionSubscriptionStatusTypes.canUseWebhooksModal]: true,
    [PermissionSubscriptionStatusTypes.canUploadLogo]: true,
    [PermissionSubscriptionStatusTypes.canSwitchIntegrationType]: true,
    [PermissionSubscriptionStatusTypes.canCopyWebCodeSnippet]: true,
    [PermissionSubscriptionStatusTypes.canCopyDirectLink]: true,
    [PermissionSubscriptionStatusTypes.canUsePreviewButton]: true,
    [PermissionSubscriptionStatusTypes.canSeeVerificationQuota]: true,
  },
  [SubscriptionStatusTypes.PostTrial]: {
    [PermissionSubscriptionStatusTypes.canUseWebhooksModal]: false,
    [PermissionSubscriptionStatusTypes.canUploadLogo]: false,
    [PermissionSubscriptionStatusTypes.canSwitchIntegrationType]: false,
    [PermissionSubscriptionStatusTypes.canCopyWebCodeSnippet]: false,
    [PermissionSubscriptionStatusTypes.canCopyDirectLink]: false,
    [PermissionSubscriptionStatusTypes.canUsePreviewButton]: false,
    [PermissionSubscriptionStatusTypes.canSeeVerificationQuota]: false,
  },
  [SubscriptionStatusTypes.Blocked]: {
    [PermissionSubscriptionStatusTypes.canUseWebhooksModal]: false,
    [PermissionSubscriptionStatusTypes.canUploadLogo]: false,
    [PermissionSubscriptionStatusTypes.canSwitchIntegrationType]: false,
    [PermissionSubscriptionStatusTypes.canCopyWebCodeSnippet]: false,
    [PermissionSubscriptionStatusTypes.canCopyDirectLink]: false,
    [PermissionSubscriptionStatusTypes.canUsePreviewButton]: false,
    [PermissionSubscriptionStatusTypes.canSeeVerificationQuota]: false,
  },
  [SubscriptionStatusTypes.Freemium]: {
    [PermissionSubscriptionStatusTypes.canUseWebhooksModal]: true,
    [PermissionSubscriptionStatusTypes.canUploadLogo]: true,
    [PermissionSubscriptionStatusTypes.canSwitchIntegrationType]: true,
    [PermissionSubscriptionStatusTypes.canCopyWebCodeSnippet]: true,
    [PermissionSubscriptionStatusTypes.canCopyDirectLink]: true,
    [PermissionSubscriptionStatusTypes.canUsePreviewButton]: true,
    [PermissionSubscriptionStatusTypes.canSeeVerificationQuota]: true,
  },
};

export interface ISubscriptionStatus {
  value: SubscriptionStatusTypes;
  updatedAt: DateString;
}

export interface ISubscriptionDurationQuota{
  start: DateString;
  end: DateString;
}

export interface ISubscriptionVerificationsQuota{
  used: number;
  total: number;
}

export interface ISubscriptionStatusQuota {
  verifications: ISubscriptionVerificationsQuota;
  duration: ISubscriptionDurationQuota;
}
