import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  validityBox: {
    marginTop: 15,
    marginLeft: 20,
    marginBottom: 14,
    marginRight: 20,
  },
  bankLogo: {
    marginTop: 5,
  },
  isSuccess: {
    color: theme.palette.common.green,
  },
  isError: {
    color: theme.palette.common.yellow,
  },
  isUnconfigured: {
    color: theme.palette.common.black75,
  },
  validitySubtitle: {
    color: theme.palette.common.black75,
    fontWeight: 400,
    marginTop: 14,
    marginRight: 5,
  },
  card: {
    width: '100%',
    boxShadow: 'none',
  },
  checkText: {
    color: theme.palette.common.black75,
    marginLeft: 10,
    marginRight: 5,
  },
}));
