import { createTypesSequence, TypesSequence } from 'state/store.utils';
import { Loadable } from 'models/Loadable.model';
import { ICustomField } from 'models/CustomField.model';
import { CustomFieldModalTypes } from '../models/CustomField.model';

export const CUSTOM_FIELD_STORE_KEY = 'customField';

export enum SliceNameTypes {
  CustomFieldModalType = 'customFieldModalType',
  CustomFieldFlattenListFields = 'customFieldListFields',
  CustomFieldListFields = 'CustomFieldListFields',
  CustomFieldEditedField = 'customFieldEditedField',
  CustomFieldEditedIndex = 'customFieldEditedIndex',
  CustomFieldEditedParent = 'customFieldEditedParent',
  CustomFieldUploadingThumbnail = 'CustomFieldUploadingThumbnail',
  CustomFieldEditedSystemName = 'CustomFieldEditedSystemName',
}

export enum ActionGroupNameTypes {
  CustomFieldModalStep = 'CUSTOM_FIELD_MODAL_STEP',
  CustomFieldFlattenListFields = 'CUSTOM_FIELD_FLATTEN_LIST_FIELDS',
  CustomFieldListFields = 'CUSTOM_FIELD_LIST_FIELDS',
  CustomFieldEditedField = 'CUSTOM_FIELD_EDITED_FIELD',
  CustomFieldEditedIndex = 'CUSTOM_FIELD_EDITED_INDEX',
  CustomFieldEditedParent = 'CUSTOM_FIELD_EDITED_PARENT',
  CustomFieldUploadingThumbnail = 'CUSTOM_FIELD_UPLOADING_THUMBNAIL',
  CustomFieldEditedSystemName = 'CUSTOM_FIELD_EDITED_SYSTEM_NAME',
}

export const types: TypesSequence = {
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldEditedField),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldModalStep),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldFlattenListFields),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldListFields),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldEditedField),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldEditedIndex),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldEditedParent),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldUploadingThumbnail),
  ...createTypesSequence(ActionGroupNameTypes.CustomFieldEditedSystemName),
  CUSTOM_FIELD_EDITED_SELECT_OPTIONS_SUCCESS: 'customField/CUSTOM_FIELD_EDITED_SELECT_OPTIONS_SUCCESS',
  CUSTOM_FIELD_EDITED_MAPPING_SUCCESS: 'customField/CUSTOM_FIELD_EDITED_MAPPING_SUCCESS',
  CUSTOM_FIELD_EDITED_THUMBNAIL_SUCCESS: 'customField/CUSTOM_FIELD_EDITED_THUMBNAIL_SUCCESS',
  CUSTOM_FIELD_EDITED_DEFAULT_VALUE_SUCCESS: 'customField/CUSTOM_FIELD_EDITED_DEFAULT_VALUE_SUCCESS',
  CUSTOM_FIELD_EDITED_STATIC_TEXT_PARAMS_SUCCESS: 'customField/CUSTOM_FIELD_EDITED_STATIC_TEXT_PARAMS_SUCCESS',
};

export interface ICustomFieldStore {
  [SliceNameTypes.CustomFieldModalType]: Loadable<CustomFieldModalTypes>;
  [SliceNameTypes.CustomFieldFlattenListFields]: Loadable<ICustomField[]>;
  [SliceNameTypes.CustomFieldListFields]: Loadable<ICustomField[]>;
  [SliceNameTypes.CustomFieldEditedField]: Loadable<Nullable<ICustomField>>;
  [SliceNameTypes.CustomFieldEditedIndex]: Loadable<Nullable<number>>;
  [SliceNameTypes.CustomFieldEditedParent]: Loadable<Nullable<string>>;
  [SliceNameTypes.CustomFieldUploadingThumbnail]: Loadable<boolean>;
  [SliceNameTypes.CustomFieldEditedSystemName]: Loadable<Nullable<string>>;
}
