import { notification } from 'apps/ui';
import React, { useEffect } from 'react';
import { useFormatMessage } from 'apps/intl';
import { appcues, CustomAppcuesActions } from 'apps/Appcues';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'lib/UI';
import { Loadable } from 'models/Loadable.model';
import { ProductTypes } from 'models/Product.model';
import { useOverlay } from 'apps/overlay';
import { flowBuilderPreview } from '../../store/FlowBuilder.action';
import { selectFlowBuilderHaveUnsavedChanges, selectFlowBuilderProductsInGraphModel, selectFlowBuilderProductsInGraph } from '../../store/FlowBuilder.selectors';
import { PreviewModal } from '../PreviewModal/PreviewModal';

export function Preview() {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const [createOverlay, closeOverlay] = useOverlay();
  const productsInGraphModel = useSelector<any, Loadable<ProductTypes[]>>(selectFlowBuilderProductsInGraphModel);
  const haveUnsavedChanges = useSelector<any, boolean>(selectFlowBuilderHaveUnsavedChanges);
  const productsInGraph = useSelector(selectFlowBuilderProductsInGraph);

  useEffect(() => {
    function handleBeforeUnload(event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }

    if (haveUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [haveUnsavedChanges]);

  const handlePreviewFlow = async (products: Array<string>) => {
    appcues.action(CustomAppcuesActions.ClickedPreviewFlowButton);
    createOverlay(<PreviewModal onClose={closeOverlay} />);
    try {
      await dispatch(flowBuilderPreview(products));
    } catch (e) {
      notification.error(formatMessage('Error.common'));
    }
  };

  return (
    <Button
      id="flow-preview-button"
      disabled={productsInGraphModel.value.length === 0 || productsInGraph.length === 0}
      type="secondary"
      size="large"
      onClick={() => handlePreviewFlow(productsInGraph)}
    >
      <Icon name="preview" />
      {formatMessage('FlowBuilder.button.preview')}
    </Button>
  );
}
