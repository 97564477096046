import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  confirmButton: {
    height: 52,
    fontSize: 16,
    padding: '16px 24px',
    borderRadius: 6,
    lineHeight: 19,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: 'normal',

    },
  },
  button: {
    margin: '5px 0',
  },
}));
