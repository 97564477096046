import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiChip-root': {
      backgroundColor: theme.palette.common.white,
      height: 24,
    },
  },
}));
