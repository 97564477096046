import React from 'react';
import Box from '@material-ui/core/Box';
import { SyntaxHighlighter, SyntaxHighlighterLanguagesTypes } from 'apps/ui';
import stringify from 'lib/stringify';

export function NewVerificationMetadata({ metadata = {}, isReviewMode = false }: {
  metadata?: any;
  isReviewMode?: boolean;
}) {
  return (
    <Box>
      <SyntaxHighlighter
        className="fs-exclude"
        code={stringify(metadata)}
        language={SyntaxHighlighterLanguagesTypes.JavaScript}
        isDarkTheme={isReviewMode}
        isLightYellowTheme={!isReviewMode}
        withCopyText
      />
    </Box>
  );
}
