import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { RecordView } from 'apps/ui';

export type MexicanRecordViewPassedItem = {
  state: string;
};

export const MexicanRecordViewPassed = ({ state }: MexicanRecordViewPassedItem) => {
  const formatMessage = useFormatMessage();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.state'),
        value: state,
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.noRecordsFound'),
      }}
    />
  );
};
