import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStep, StepCodeStatus } from 'models/Step.model';
import { IBackgroundCheckStepData } from 'models/BackgroundCheck.model';
import { selectVerification } from 'apps/Verification';
import { getPostResultPhase, IdentityStatuses, VerificationStatusChangeReason } from 'models/Status.model';
import { selectMerchantTags } from 'state/merchant';
import { MerchantTagsTypes } from 'models/Merchant.model';

export function useBackgroundCheckParams({
  data,
  startedManuallyAt,
  status,
}: IStep<IBackgroundCheckStepData>) {
  const [isJsonVisible, setJsonVisible] = useState<boolean>(true);
  const { verificationStatusDetails } = useSelector(selectVerification);
  const { value: verificationStatus, reasonCode } = verificationStatusDetails;
  const isPostResultPhase = getPostResultPhase(verificationStatus);
  const isVerified = verificationStatus === IdentityStatuses.verified && reasonCode !== VerificationStatusChangeReason.ManualChange;
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const canUseLegacyBackgroundChecks = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseLegacyBackgroundChecks)), [merchantTags]);
  const couldBeRunManually = !data && isPostResultPhase && !isVerified && !startedManuallyAt && !canUseLegacyBackgroundChecks;
  const isInProgress: boolean = [StepCodeStatus.Pending, StepCodeStatus.Running].includes(status) && !couldBeRunManually;

  return {
    isJsonVisible,
    setJsonVisible,
    isInProgress,
    couldBeRunManually,
  } as const;
}
