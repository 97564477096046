import React from 'react';
import { Routes } from 'models/Router.model';
import { Route, Switch } from 'react-router-dom';
import { SignIn } from 'pages/SignIn';
import { UserEmailVerification } from 'pages/UserEmailVerification';

export function AuthRouter() {
  return (
    <Switch>
      <Route path={Routes.auth.emailVerification} component={UserEmailVerification} />
      <Route path={Routes.auth.signIn} component={SignIn} />
    </Switch>
  );
}
