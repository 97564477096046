import { useScrollMeIntoView } from 'lib/ScrollIntoView';
import React, { useState, useCallback, useRef, ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import { ESignatureCheckSettingsTypes, ESignatureRadioOptionsTypes, IESignatureTemplate, MAX_DOCUMENT_SIZE_MB, MAX_UPLOAD_DOCUMENTS } from 'models/ESignature.model';
import { getBytes } from 'lib/convertFileSize';
import { selectFlowBuilderProductsInGraphModel } from 'apps/flowBuilder/store/FlowBuilder.selectors';
import { flowBuilderProductAdd } from 'apps/flowBuilder/store/FlowBuilder.action';
import { useMerchantPlan } from 'apps/merchant';
import { ProductCard, useOtherProductAdding } from 'apps/Product';
import { ExtendedDescription, WarningSize, WarningTypes, Warning } from 'apps/ui';
import { cloneDeep } from 'lodash';
import { ProductSettingsProps, ProductTypes } from 'models/Product.model';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiUpload } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import { useDispatch, useSelector } from 'react-redux';
import * as api from 'lib/client/e-signature';
import { SupportEmail } from 'models/Merchant.model';
import classNames from 'classnames';
import { DocumentsList } from '../DocumentList/DocumentsList';
import { useStyles } from './ESignatureSettings.style';

export function ESignatureSettings({ settings, onUpdate, disabled }: ProductSettingsProps<ESignatureCheckSettingsTypes>) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileUploadRef = useRef(null);
  const { isFreemium } = useMerchantPlan();
  const createAddOtherProductModalOverlay = useOtherProductAdding();
  const productsInGraph = (useSelector(selectFlowBuilderProductsInGraphModel)).value;
  const [isDocLoading, setDocLoading] = useState<boolean>(false);
  const [hasFileSizeError, setFileSizeError] = useState<boolean>(false);
  const [hasFileExtensionError, setFileExtensionError] = useState<boolean>(false);
  const ScrollMeIntoView = useScrollMeIntoView();

  const handleUpdateSettings = useCallback((field: ESignatureCheckSettingsTypes, value: any) => {
    const newSettings = cloneDeep(settings);
    newSettings[field].value = value;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleFileInput = useCallback((files: IESignatureTemplate[], order: string[]) => {
    const newSettings = cloneDeep(settings);
    newSettings[ESignatureCheckSettingsTypes.Terms].value = files;
    newSettings[ESignatureCheckSettingsTypes.TermsOrder].value = order;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleMethodSwitch = (option: ESignatureRadioOptionsTypes, requiredProducts: ProductTypes[] = []) => () => {
    const updateSettings = () => handleUpdateSettings(ESignatureCheckSettingsTypes.SignatureMethod, option);
    const missingProducts = requiredProducts.filter((product) => !productsInGraph.includes(product));
    if (missingProducts.length === 0) {
      updateSettings();
      return;
    }
    createAddOtherProductModalOverlay(
      missingProducts[0],
      missingProducts[1] && (
        <Box mt={2}>
          <ProductCard id={missingProducts[1]} isExpandable={false} defaultExpanded />
        </Box>
      ),
      () => {
        missingProducts.forEach((product) => dispatch(flowBuilderProductAdd(product)));
        updateSettings();
      },
    );
  };

  const handleTouch = useCallback(({ target: { checked } }) => {
    handleUpdateSettings(ESignatureCheckSettingsTypes.SignatureTouch, checked);
  }, [handleUpdateSettings]);

  const onFileUpload = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      event.persist();
      const file = event.target.files[0];

      if (!(/\.pdf/i.test(file.name))) {
        setFileSizeError(false);
        setFileExtensionError(true);
        return;
      }

      if (file.size > getBytes(MAX_DOCUMENT_SIZE_MB)) {
        setFileExtensionError(false);
        setFileSizeError(true);
        return;
      }

      setFileSizeError(false);
      setFileExtensionError(false);
      setDocLoading(true);

      const form = new FormData();
      form.append('template-document', file);

      const { data } = await api.storeTemplate(form);
      handleFileInput([...settings?.terms.value, data], [...settings?.termsOrder.value, data.id]);
    } finally {
      setDocLoading(false);
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
    }
  }, [handleFileInput, settings]);

  const onTermDelete = useCallback((id: string) => {
    handleFileInput(settings?.terms.value.filter((term) => term.id !== id), settings?.termsOrder.value.filter((termId) => termId !== id));
  }, [handleFileInput, settings]);

  const onTermReorder = useCallback((reorderedIds: string[]) => {
    handleFileInput(settings?.terms.value, reorderedIds);
  }, [handleFileInput, settings]);

  return (
    <Box>
      {disabled && (
        <Box mb={2}>
          {isFreemium ? (
            <Warning
              label={formatMessage('Freemium.productNotAvailable')}
              isLabelColored={false}
              meritName={formatMessage('CustomDocument')}
              bordered
            />
          ) : (
            <Warning
              type={WarningTypes.Warning}
              size={WarningSize.Normal}
              label={formatMessage('ESignature.disabled', {
                messageValues: {
                  // TODO: create ui component for email
                  email: <Link href={`mailto:${SupportEmail}`} rel="noopener">{SupportEmail}</Link>,
                },
              })}
              meritName={formatMessage('Product.configuration.eSignature')}
              bordered
            />
          )}
        </Box>
      )}
      <Box mt={2}>
        <ExtendedDescription
          title={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.title`)}
          isDisabled={disabled}
        />
        <Box mt={2} className={classNames(classes.root, { [classes.rootDisabled]: disabled })}>
          <RadioGroup value={settings?.signatureMethod.value}>
            <FormControlLabel
              control={<Radio color="primary" />}
              value={ESignatureRadioOptionsTypes.NameTyping}
              disabled={disabled}
              onClick={handleMethodSwitch(ESignatureRadioOptionsTypes.NameTyping)}
              label={(
                <Box mt={0.5}>
                  <Box color="common.black90" fontWeight="bold">
                    {formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.radio.${ESignatureRadioOptionsTypes.NameTyping}.label`)}
                  </Box>
                  <Box color="common.black75">
                    {formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.radio.${ESignatureRadioOptionsTypes.NameTyping}.description`)}
                  </Box>
                </Box>
              )}
            />
            <Box mt={4}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value={ESignatureRadioOptionsTypes.UploadDocumentAndTypeName}
                disabled={disabled}
                onClick={handleMethodSwitch(ESignatureRadioOptionsTypes.UploadDocumentAndTypeName, [ProductTypes.DocumentVerification])}
                label={(
                  <Box mt={0.5}>
                    <Box color="common.black90" fontWeight="bold">{formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.radio.${ESignatureRadioOptionsTypes.UploadDocumentAndTypeName}.label`)}</Box>
                    <Box color="common.black75">{formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.radio.${ESignatureRadioOptionsTypes.UploadDocumentAndTypeName}.description`)}</Box>
                  </Box>
                )}
              />
            </Box>
            <Box mt={4}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value={ESignatureRadioOptionsTypes.FaceAndDocumentSignature}
                disabled={disabled}
                onClick={handleMethodSwitch(ESignatureRadioOptionsTypes.FaceAndDocumentSignature, [ProductTypes.DocumentVerification, ProductTypes.BiometricVerification])}
                label={(
                  <Box mt={0.5}>
                    <Box color="common.black90" fontWeight="bold">{formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.radio.${ESignatureRadioOptionsTypes.FaceAndDocumentSignature}.label`)}</Box>
                    <Box color="common.black75">{formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.radio.${ESignatureRadioOptionsTypes.FaceAndDocumentSignature}.description`)}</Box>
                  </Box>
                )}
              />
            </Box>
          </RadioGroup>
        </Box>
        <Box mt={2}>
          <ExtendedDescription
            title={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.touch.title`)}
            info={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.SignatureMethod}.touch.description`)}
            prefix={(
              <Switch
                checked={settings[ESignatureCheckSettingsTypes.SignatureTouch].value}
                onClick={handleTouch}
                color="primary"
              />
              )}
            badge={<Box>{formatMessage('FlowBuilder.integration.sdkOnly')}</Box>}
          />
        </Box>
      </Box>

      <Box mt={4}>
        {settings.terms?.error && !disabled && !isDocLoading && (
          <ScrollMeIntoView />
        )}
        <ExtendedDescription
          title={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.Terms}.title`)}
          info={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.Terms}.description`)}
          isDisabled={disabled}
          error={settings.terms?.error && !isDocLoading && formatMessage(settings.terms.error)}
        />
        <Box mt={2}>
          {settings?.terms.value.length >= MAX_UPLOAD_DOCUMENTS && (
            <Box mb={0.5} className={classes.warningWrap}>
              <Warning
                type={WarningTypes.Notify}
                label={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.Terms}.tooltip`)}
                isLabelColored
                isIconExist={false}
              />
            </Box>
          )}
          {hasFileSizeError && (
            <Box mb={0.5} className={classes.warningWrap}>
              <Warning
                type={WarningTypes.Error}
                label={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.Terms}.fileSizeError`, { messageValues: { maxSize: MAX_DOCUMENT_SIZE_MB } })}
                isLabelColored
                isIconExist={false}
              />
            </Box>
          )}
          {hasFileExtensionError && (
            <Box mb={0.5} className={classes.warningWrap}>
              <Warning
                type={WarningTypes.Error}
                label={formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.Terms}.fileExtensionError`, { messageValues: { maxSize: MAX_DOCUMENT_SIZE_MB } })}
                isLabelColored
                isIconExist={false}
              />
            </Box>
          )}
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="contained"
            size="large"
            onClick={() => fileUploadRef.current?.click()}
            startIcon={isDocLoading ? <CircularProgress color="inherit" size="1rem" thickness={7} /> : <FiUpload />}
            disabled={isDocLoading || disabled || settings?.terms.value.length >= MAX_UPLOAD_DOCUMENTS}
          >
            {formatMessage(`ESignature.settings.${ESignatureCheckSettingsTypes.Terms}.upload`)}
          </Button>

          <input
            className={classes.uploadInput}
            type="file"
            onChange={onFileUpload}
            multiple={false}
            accept="application/pdf"
            ref={fileUploadRef}
          />
        </Box>
        {!disabled && (
          <Box mt={2}>
            <DocumentsList
              documents={{ order: settings?.termsOrder.value, list: settings?.terms.value }}
              onChangeOrder={onTermReorder}
              onDocumentDelete={onTermDelete}
              isLoading={isDocLoading}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
