import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import { CheckBarExpandable, CheckStepDetailsEntry } from 'apps/checks';
import { StepStatus } from 'models/Step.model';
import type { IBasicWatchlistStepData } from 'apps/Aml/models/Aml.model';
import { CustomWatchlistSettingsTypes, type ICustomWatchlistStepData } from 'apps/CustomWatchlist/models/CustomWatchlist.model';
import { useStyles } from './WatchListItem.styles';

const mediaUrl = 'mediaUrl';
const watchlistImage = 'watchlistImage';

export const WatchListItem = ({ item, status }: { item: IBasicWatchlistStepData | ICustomWatchlistStepData; status?: StepStatus }) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  return (
    <CheckBarExpandable
      step={{ checkStatus: status }}
      isError={!!item.searchResult}
      isNoBadge
      name={item.watchlist.name}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box>
            {formatMessage(`Watchlist.verification.step.watchlist.${item?.searchResult ? 'matchFound' : 'noMatchFound'}`, { messageValues: { watchlistName: item.watchlist.name } })}
          </Box>
          {item.searchParams && (
            <Box mt={0.5}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                  >
                    {formatMessage('Watchlist.verification.step.subtitle.searchParams')}
                  </Typography>
                </Grid>
                {item?.searchResult && item?.watchlist?.watchlistType !== CustomWatchlistSettingsTypes.facialWatchList && (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                    >
                      {formatMessage('Watchlist.verification.step.subtitle.searchResult')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container direction={item?.searchResult ? 'column' : 'row'}>
                {Object.entries(item.searchParams)
                  .map((value) => (
                    <Grid
                      xs={item?.searchResult ? 12 : 6}
                      container
                      item
                      spacing={item?.searchResult && 1}
                      key={value[0]}
                    >
                      <Grid xs={6} item>
                        <CheckStepDetailsEntry
                          label={value[0]}
                          value={value[1]}
                          isMediaUrl={item?.watchlist?.watchlistType === CustomWatchlistSettingsTypes.facialWatchList && value[0] === mediaUrl}
                        />
                      </Grid>
                      {item?.searchResult && item?.watchlist?.watchlistType !== CustomWatchlistSettingsTypes.facialWatchList && (
                        <Grid xs={6} item>
                          {item.searchResult[value[0]] ? (
                            <CheckStepDetailsEntry
                              label={value[0]}
                              value={item.searchResult[value[0]]}
                            />
                          ) : '-'}
                        </Grid>
                      )}
                      {item?.searchResult && item?.watchlist?.watchlistType === CustomWatchlistSettingsTypes.facialWatchList && (
                        <Grid xs={6} item>
                          {item.searchResult[value[0]] && item.searchResult?.fileName && (
                            <CheckStepDetailsEntry
                              label={watchlistImage}
                              value={item.searchResult[value[0]]}
                              isMediaUrl
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </CheckBarExpandable>
  );
};
