import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useFormatMessage } from 'apps/intl';
import React from 'react';
import { useStyles } from './GovCheckModalFooter.styles';

export function GovCheckModalFooter({ handleApply, handleClose }: {
  handleApply: () => void;
  handleClose: () => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Box className={classes.root}>
      <Button onClick={handleClose} variant="text" color="primary" className={classes.cancelBtn}>
        {formatMessage('GovCheckMerit.settings.button.cancel')}
      </Button>
      <Button onClick={handleApply} variant="contained" color="primary" className={classes.applyBtn}>
        {formatMessage('GovCheckMerit.settings.button.apply')}
      </Button>
    </Box>
  );
}
