import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  country: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    marginRight: 6,
    padding: 6,
    borderRadius: '999px',
    fontWeight: 500,
    color: theme.palette.common.black75,
    backgroundColor: theme.palette.common.black7,
    '&::before': {
      content: '""',
      display: 'inline-block',
      height: 16,
      width: 16,
      marginRight: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.common.green,
    },
  },
  countyDisabled: {
    opacity: 0.2,
  },
  label: {
    fontWeight: 500,
    color: theme.palette.common.black75,
  },
}));
