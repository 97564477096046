import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantTags, selectPatternsConfigValue } from 'state/merchant';
import { GovCheckMerit } from '../services/GovCheckMerit.service';

export function getIsDisabled(id: ProductTypes, merchantTags: MerchantTagsTypes[]) {
  if (merchantTags.includes(MerchantTagsTypes.CanNotUseDocumentReading)) {
    return true;
  }

  switch (id) {
    case ProductTypes.IdentityValidation:
      return merchantTags.includes(MerchantTagsTypes.CanNotUseIdentityValidation);

    case ProductTypes.LegalRecords:
      return merchantTags.includes(MerchantTagsTypes.CanNotUseLegalRecords);

    case ProductTypes.DrivingLicenseValidation:
      return merchantTags.includes(MerchantTagsTypes.CanNotUseDrivingLicenceValidation);

    case ProductTypes.TaxChecks:
      return merchantTags.includes(MerchantTagsTypes.CanNotUseTaxChecks);

    case ProductTypes.BusinessRecordsChecks:
      return merchantTags.includes(MerchantTagsTypes.CanNotUseBusinessRecordsChecks);

    default:
      return false;
  }
}

export const govCheckMeritInit = (id: ProductTypes) => (dispatch, getState): ProductTypes => {
  const merchantTags = selectMerchantTags(getState());
  const patternsConfig = selectPatternsConfigValue(getState());
  const gvCheckMerits = new GovCheckMerit({
    id,
    patternsConfig,
    disabled: getIsDisabled(id, merchantTags),
  });
  productManagerService.register(gvCheckMerits);
  return gvCheckMerits.id;
};
