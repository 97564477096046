import averageBalance from './averageBalance.svg';
import currentBalance from './closingBalance.svg';
import averageIncome from './averageIncome.svg';
import totalDeposits from './totalDeposits.svg';
import totalWithdrawals from './totalWithdrawals.svg';
import averageExpenses from './averageExpenses.svg';
import averageLoanRepayment from './averageLoanRepayment.svg';

export { ReactComponent as BankLogoSVG } from './bankLogo.svg';
export { ReactComponent as BankLogoSuccess } from './bankLogoGreen.svg';
export { ReactComponent as BankLogoError } from './bankLogoYellow.svg';
export { ReactComponent as BankLogoUnconfigured } from './bankLogoGrey.svg';
export { ReactComponent as PdfIconSmall } from './tinyPdf.svg';
export { ReactComponent as DownloadFileIcon } from './download.svg';
export { ReactComponent as VerificationError } from './verificationError.svg';

export const BALANCE_CHECKS_ICONS = {
  averageBalance,
  currentBalance,
  averageIncome,
  totalDeposits,
  totalWithdrawals,
  averageExpenses,
  averageLoanRepayment,
};
