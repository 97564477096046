import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useOverlay } from 'apps/overlay';
import { ICustomField, MAIN_DROPPABLE_ID } from 'models/CustomField.model';
import { CountryRestrictionSettings } from 'apps/countries';
import { BoxBordered, ExtendedDescription } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { CustomFieldActions } from '../CustomFieldActions/CustomFieldActions';
import { CustomFieldModalTypes, CustomFieldProductSettingsProps, CustomFieldSettingTypes, HandleOpenModal, HandleUpdateFields } from '../../models/CustomField.model';
import { prepareDataForModal, setCustomFieldEditedIndex, setCustomFieldEditedParent, setCustomFieldFlattenListFields, setCustomFieldListFields } from '../../state/CustomField.actions';
import { CustomFieldModal } from '../CustomFieldModal/CustomFieldModal';
import { CustomFieldDragAndDrop } from '../CustomFieldDragAndDrop/CustomFieldDragAndDrop';

export function CustomFieldContainer({ onUpdate, settings, disabled }: CustomFieldProductSettingsProps) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const [createOverlay] = useOverlay();
  const [isCountryRestrictionEnabled, setIsCountryRestrictionEnabled] = useState<boolean>(!!settings[CustomFieldSettingTypes.CountryRestriction]?.value.length);

  useLayoutEffect(() => {
    dispatch(setCustomFieldFlattenListFields(settings[CustomFieldSettingTypes.FlattenedFields].value));
    dispatch(setCustomFieldListFields(settings[CustomFieldSettingTypes.Fields].value));
  }, [dispatch, settings]);

  const handleUpdate = useCallback((settingId: CustomFieldSettingTypes) => (newValue: unknown) => {
    const newSettings = cloneDeep(settings);
    newSettings[settingId].value = newValue;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleUpdateFields: HandleUpdateFields = useCallback((fields: ICustomField[]) => {
    handleUpdate(CustomFieldSettingTypes.Fields)([...fields]);
  }, [handleUpdate]);

  const handleChangeCountryRestriction = useCallback((settingId: CustomFieldSettingTypes) => () => {
    const newSettings = cloneDeep(settings);
    if (settingId === CustomFieldSettingTypes.CountryRestriction) {
      setIsCountryRestrictionEnabled((prevState) => !prevState);
      newSettings[settingId].value = isCountryRestrictionEnabled ? [] : newSettings[settingId].value;
    }
    onUpdate(newSettings);
  }, [isCountryRestrictionEnabled, onUpdate, settings]);

  const handleOpenModal: HandleOpenModal = useCallback((modalType: CustomFieldModalTypes, editedField: ICustomField = null, index: number = null, parent: string = MAIN_DROPPABLE_ID) => () => {
    dispatch(setCustomFieldEditedParent(parent));
    dispatch(setCustomFieldEditedIndex(index));
    dispatch(prepareDataForModal(modalType, editedField));
    createOverlay(<CustomFieldModal handleUpdateFields={handleUpdateFields} />, { sticky: true });
  }, [createOverlay, dispatch, handleUpdateFields]);

  return (
    <Box>
      {!disabled && (<CustomFieldDragAndDrop handleUpdateFields={handleUpdateFields} handleOpenModal={handleOpenModal} />)}
      <CustomFieldActions disabled={disabled} handleUpdateFields={handleUpdateFields} handleOpenModal={handleOpenModal} />
      <Box mb={4}>
        <BoxBordered>
          <Box px={1}>
            <ExtendedDescription
              title={formatMessage(`CustomField.settings.title.${CustomFieldSettingTypes.CountryRestriction}`)}
              info={formatMessage(`CustomField.settings.description.${CustomFieldSettingTypes.CountryRestriction}`)}
              isDisabled={settings[CustomFieldSettingTypes.CountryRestriction]?.isDisabled}
              prefix={(
                <Switch
                  checked={isCountryRestrictionEnabled}
                  onClick={handleChangeCountryRestriction(CustomFieldSettingTypes.CountryRestriction)}
                  color="primary"
                />
              )}
              badge={<Box>{formatMessage('FlowBuilder.integration.sdkOnly')}</Box>}
            >
              {isCountryRestrictionEnabled && (
                <CountryRestrictionSettings
                  countries={settings[CustomFieldSettingTypes.CountryRestriction]?.value}
                  onUpdate={handleUpdate(CustomFieldSettingTypes.CountryRestriction)}
                />
              )}
            </ExtendedDescription>
          </Box>
        </BoxBordered>
      </Box>
    </Box>
  );
}
