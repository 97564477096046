import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { BackgroundCheckStatusesTypes } from 'models/BackgroundCheck.model';
import { useStyles } from './BackgroundCheckScoreItem.styles';

export const BackgroundCheckScoreItem = ({
  title,
  status,
  value,
}: {
  title: string;
  status: BackgroundCheckStatusesTypes;
  value: number;
}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.scoreValueContainer}>
      <Typography
        variant="body1"
        className={classnames(classes.scoreValue, classes[status], 'fs-exclude')}
      >
        {value}
      </Typography>
      <Typography variant="body1" className={classes.label}>
        {title}
      </Typography>
    </Grid>
  );
};
