import { PopperPlacementType } from '@material-ui/core/Popper/Popper';
import React, { useState } from 'react';
import Popper from '@material-ui/core/Popper';
import { useStyles } from './PopperUI.style';

export function PopperUI({ children, id, isPopperOpen, anchorEl, isShowArrow = true, placement = 'left' }: {
  children: React.ReactNode;
  id: string;
  isPopperOpen: boolean;
  anchorEl: HTMLElement;
  isShowArrow?: boolean;
  placement?: PopperPlacementType;
}) {
  const [arrowRef, setArrowRef] = useState<HTMLElement>(null);
  const classes = useStyles();

  return (
    <Popper
      id={id}
      open={isPopperOpen}
      anchorEl={anchorEl}
      placement={placement}
      className={classes.popper}
      modifiers={{
        arrow: {
          enabled: isShowArrow,
          element: arrowRef,
        },
      }}
    >
      {isShowArrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
      {children}
    </Popper>
  );
}
