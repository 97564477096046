import React from 'react';
import { useFormatMessage } from 'apps/intl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Placeholder } from 'apps/ui';
import classNames from 'classnames';
import { useStyles } from './SMSPreview.styles';
import { ReactComponent as UserIcon } from './PhoneSMSUser.svg';

export const SMSPreview = ({ senderName = '' }: {
  senderName?: string;
}) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Box className={classes.container} padding={1} mt={2}>
      <Typography className={classNames(classes.text, classes.paddingLeft)}>This is an example:</Typography>
      <Box className={classes.message}>
        <Placeholder icon={<UserIcon />} />
        <Box className={classes.message}>
          <div className={classes.sms}>
            <div className={classes.smsBefore} />
            <Typography className={classNames(classes.text, classes.padding)}>
              {formatMessage('PhoneCheck.settings.sms.example', { messageValues: { value: senderName } })}
            </Typography>
          </div>
        </Box>
      </Box>
      <Grid />
    </Box>
  );
};
