declare global {
  interface Window {
    Appcues: {
      identify: (id: string, properties: Record<string, number | boolean| string>) => void;
      page: () => void;
      track: (id: string, properties?: Record<string, number | boolean| string>) => void;
    };
  }
}


export class Appcues {
  start(id: string, properties: Record<string, number | boolean| string>) {
    window.Appcues?.identify(id, properties);
  }

  page() {
    window.Appcues?.page();
  }

  action(id: string, properties?: Record<string, number | boolean| string>) {
    window.Appcues?.track(id, properties);
  }
}

export const appcues = new Appcues();
