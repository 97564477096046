import React from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import { AiFillCheckCircle, AiFillInfoCircle } from 'react-icons/ai';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './BankAccountCheckCard.styles';
import { BALANCE_CHECKS_ICONS } from '../../assets';

export function BankAccountCheckCard({ name, isChecked = false, isWarning }: {
  name: string;
  isChecked?: boolean;
  isWarning: boolean;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Icon component="img" src={BALANCE_CHECKS_ICONS[name]} />
      {formatMessage(`BankAccountData.financialChecks.${name}`)}
      {isChecked && isWarning && <AiFillInfoCircle className={classes.warning} />}
      {isChecked && !isWarning && <AiFillCheckCircle className={classes.cardCheck} />}
    </Box>
  );
}
