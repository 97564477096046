import React, { ChangeEvent } from 'react';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { TextFieldName } from 'apps/ui';
import { ISelectOption } from '../../models/AutocompleteSelect.model';
import { useStyles } from './TextFieldNameWithOptions.styles';

export function TextFieldNameWithOptions({
  options,
  onChange,
  value,
  disabled,
}: {
  options: ISelectOption [];
  onChange: (event: ChangeEvent<{}>, value: ISelectOption, reason: AutocompleteChangeReason) => void;
  value?: ISelectOption;
  disabled?: boolean;
}) {
  const classes = useStyles();
  return (
    <Autocomplete
      renderInput={(params) => <TextFieldName {...params} className={classes.autocompleteInput} />}
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue, reason) => onChange(event, newValue as ISelectOption, reason)}
      blurOnSelect
      value={value}
      disabled={disabled}
    />
  );
}
