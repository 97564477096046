import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { IFlattenVerificationPatternConfig, PatternErrorType, ProductByVerificationInput, VerificationConfigurationErrorTypes } from 'models/VerificationPatternsConfigs.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { getMissingOptionsText } from '../../../../models/GovCheckMerit.model';

export function DataSourceError({ errorType, govCheck }: {
  errorType: PatternErrorType;
  govCheck: IFlattenVerificationPatternConfig;
}) {
  const formatMessage = useFormatMessage();

  if (errorType.type === VerificationConfigurationErrorTypes.DataSourceBadConfig && govCheck?.govCheckName === VerificationPatternTypes.NigerianAv) {
    return (
      <>
        {
        formatMessage(`GovCheck.${govCheck?.govCheckName}.${errorType.type}`)
      }
      </>
    );
  }

  if (errorType.type === VerificationConfigurationErrorTypes.DataSourceNotInFlow) {
    return (
      <>
        {formatMessage('tooltip.issue.product', {
          messageValues: {
            messageContinued: formatMessage(`${ProductByVerificationInput[errorType.input.id]}.card.title`),
          },
        })}
      </>
    );
  }

  return (
    <>
      {formatMessage('tooltip.issue.products.configuration', {
        messageValues: {
          messageContinued: `${formatMessage(`${ProductByVerificationInput[errorType.input.id]}.card.title`)}. ${getMissingOptionsText(errorType, formatMessage)}.`,
        },
      })}
    </>
  );
}
