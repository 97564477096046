import { getStepStatus, IStep } from 'models/Step.model';
import { VerificationPatternTypes } from './VerificationPatterns.model';

export interface EmailValidationStepData {
  emailAddress: string;
}
/* eslint-disable camelcase */
export interface EmailRiskValidationStepData {
  emailAddress: string;
  riskScore: number;
  riskThreshold: number;
  message: string;
  success: true;
  valid: true;
  disposable: true;
  smtp_score: number,
  overall_score: number;
  first_name: string;
  generic: boolean;
  common: boolean;
  dns_valid: boolean;
  honeypot: boolean;
  deliverability: string;
  frequent_complainer: boolean;
  spam_trap_score: string,
  catch_all: boolean,
  timed_out: boolean,
  suspect: boolean,
  recent_abuse: boolean,
  suggested_domain: string,
  leaked: boolean,
  domain_age: {
    human: string;
    timestamp: number;
    iso: number;
  };
  first_seen: {
    human: string;
    timestamp: number;
    iso: number;
  };
  sanitized_email: string;
  domain_velocity: string;
  user_activity: string;
  associated_names: {
    status: string;
    names: string[];
  };
  associated_phone_numbers: {
    status: string;
    phone_numbers: string[];
  };
  request_id: string;
}

export type EmailValidationStep = IStep<EmailValidationStepData>;
export type EmailRiskValidationStep = IStep<EmailRiskValidationStepData>;

export const EmailValidationFields = [
  { fieldName: 'emailAddress' },
  { fieldName: 'isSourceVerified', inline: true },
];

export function getEmailValidationExtra(step: IStep): EmailValidationStep {
  if (!step) {
    return null;
  }

  return {
    ...step,
    data: {
      ...step.data,
      isSourceVerified: step?.data?.emailAddress && !step?.error,
    },
    checkStatus: getStepStatus(step),
  };
}

export function getEmailValidationStep(steps: IStep[]): EmailValidationStep {
  const step = steps.find((item) => item.id === VerificationPatternTypes.EmailOwnershipValidation);
  if (!step) {
    return null;
  }
  return getEmailValidationExtra(step);
}

interface EmailRiskStepData {
  emailAddress: string;
  riskScore: number;
  riskThreshold: number;
}

export type EmailRiskStep = IStep<EmailRiskStepData>;

export const EmailRiskFields = [
  { fieldName: 'emailAddress' },
  { fieldName: 'riskScore', inline: true },
  { fieldName: 'riskThreshold', inline: true },
];

export enum EmailRiskFieldTypes {
  EmailAddress = 'emailAddress',
  RiskScore = 'riskScore',
  RiskThreshold = 'riskThreshold',
}

export function getEmailRiskExtra(emailRiskStep: IStep): EmailRiskValidationStep {
  if (!emailRiskStep) {
    return null;
  }

  return {
    ...emailRiskStep,
    checkStatus: getStepStatus(emailRiskStep),
  };
}

export function getEmailRiskStep(steps: IStep[]): EmailRiskValidationStep {
  const step = (steps || []).find((item) => item.id === VerificationPatternTypes.EmailRiskValidation);
  if (!step) {
    return null;
  }
  return getEmailRiskExtra(step);
}
