import React from 'react';
import { CheckBarExpandable, CheckStepDetails, CheckStepDetailsEntry } from 'apps/checks';
import { GovernmentChecksStep } from 'models/GovernmentChecksMerit.model';
import { showMinorErrorAsDefaultError } from 'models/Step.model';
import { useGovCheckData } from 'hooks/useGovCheckData';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFormatMessage } from 'apps/intl';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { getCheckTitle } from '../../../../models/GovCheckMerit.model';

export const GovCheckMinorError = ({ step }: { step: GovernmentChecksStep }) => {
  const stepDataEntries = useGovCheckData(step, false, CheckStepDetailsEntry);
  const formatMessage = useFormatMessage();
  const title = getCheckTitle(step);
  const { id, error: { code }, checkStatus } = step;

  return (
    <CheckBarExpandable
      title={title ?? `SecurityCheckStep.${id}.title`}
      isError={showMinorErrorAsDefaultError(code)}
      isNoBadge
    >
      <CheckStepDetails step={step}>
        <Box mt={1}>
          <ErrorMessage error={formatMessage(`SecurityCheckStep.${code}.message`, { defaultMessage: formatMessage(`SecurityCheckStep.govCheck.${checkStatus}`) })} />
          <Box mt={2}>
            <Grid container>
              {stepDataEntries}
            </Grid>
          </Box>
        </Box>
      </CheckStepDetails>
    </CheckBarExpandable>
  );
};
