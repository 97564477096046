import classNames from 'classnames';
import React from 'react';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './Badge.styles';

export function BetaBadge() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  return (
    <Box className={classNames(classes.badge, classes.beta)}>
      {formatMessage('Biometrics.setting.badge.beta')}
    </Box>
  );
}

export function NewBadge() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  return (
    <Box className={classNames(classes.badge, classes.new)}>
      {formatMessage('Biometrics.setting.badge.new')}
    </Box>
  );
}
