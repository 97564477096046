/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import { Button, FormHelperText, InputLabel, TextField } from '@material-ui/core';
import { WATCHLIST_VALIDATION_NAME_MAXLENGTH, WATCHLIST_VALIDATION_NAME_PATTERN, WatchlistTypes } from 'models/Watchlist.model';
import { selectMerchantId } from 'state/merchant';
import { notification } from 'apps/ui';
import { IFlowWatchlistUi, WatchListErrorTypes } from 'apps/CustomWatchlist/models/CustomWatchlist.model';
import { selectCurrentCustomWatchlistId } from '../../state/CustomWatchlist.selectors';
import { useStyles } from './FacialWatchlistModal.styles';
import { UploadFacialWatchlistItems } from '../UploadFacialWatchlistItems/UploadFacialWatchlistItems';
import * as api from '../../client/CustomWatchlist.client';
import { types } from '../../state/CustomWatchlist.store';
import { FacialWatchlistImages } from '../FacailWatchlistImages/FacialWatchlistImages';

export function FacialWatchlistModal({ onClose, onSubmit }: {
  onClose: () => void;
onSubmit: (watchlist: Partial<IFlowWatchlistUi>) => void;
}) {
  const formatMessage = useFormatMessage();
  const merchantId = useSelector<any, string>(selectMerchantId);
  const currentWatchlistId = useSelector<any, number>(selectCurrentCustomWatchlistId);
  const classes = useStyles();
  const [watchListName, setWatchListName] = useState(undefined);
  const [isInValidWatchListName, setIsInValidWatchListName] = useState<WatchListErrorTypes | undefined>(undefined);
  const [facialWatchFiles, setFacialWatchFiles] = useState<string[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [watchlistCreateInProgress, setWatchlistCreateInProgress] = useState(false);
  const [isUploadImage, setIsUploadImage] = useState(false);
  const [currentWatchListImageCount, setCurrentWatchListImageCount] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    if (watchListName !== undefined) {
      if (watchListName === '') {
        setIsInValidWatchListName(WatchListErrorTypes.Empty);
      } else if (watchListName.length > WATCHLIST_VALIDATION_NAME_MAXLENGTH) {
        setIsInValidWatchListName(WatchListErrorTypes.MaxLength);
      } else if (!watchListName.match(WATCHLIST_VALIDATION_NAME_PATTERN)) {
        setIsInValidWatchListName(WatchListErrorTypes.PatternMatchFailed);
      } else {
        setIsInValidWatchListName(undefined);
      }
    }
  }, [watchListName]);

  const handleUpdateWatchList = async () => {
    try {
      setWatchlistCreateInProgress(true);
      setUploadInProgress(true);
      await api.updateMerchantWatchlistContentById(merchantId, currentWatchlistId, {
        fileNames: facialWatchFiles,
      });
      onSubmit({ id: currentWatchlistId });
      notification.info(formatMessage('Watchlist successfully updated'));
      setWatchlistCreateInProgress(false);
      setFacialWatchFiles([]);
      setUploadInProgress(false);
      setIsUploadImage(false);
    } catch (error) {
      setWatchlistCreateInProgress(false);
      setUploadInProgress(false);
      notification.error(formatMessage('CustomWatchlist.settings.facialWatchlists.modal.errorUpdatingWatchlist'));
    }
  };

  const handleCreateWatchList = async () => {
    try {
      setWatchlistCreateInProgress(true);
      setUploadInProgress(true);
      dispatch({ type: types.CUSTOM_WATCHLISTS_REQUEST });
      const response = await api.createMerchantWatchlist(merchantId, {
        name: watchListName,
        watchlistType: WatchlistTypes.CustomWatchlistBiometrics,
        mapping: [],
      });
      await api.updateMerchantWatchlistContentById(merchantId, response.data.id, {
        fileNames: facialWatchFiles,
      });
      dispatch({ type: types.CUSTOM_WATCHLISTS_SUCCESS, payload: [response.data] });
      onSubmit({ id: response.data.id });
      setWatchlistCreateInProgress(false);
      setUploadInProgress(false);
    } catch (error) {
      setWatchlistCreateInProgress(false);
      setUploadInProgress(false);
      notification.error(formatMessage('CustomWatchlist.settings.facialWatchlists.modal.errorCreatingWatchlist'));
    }
  };

  const handleFacialWatchList = (fileName: string) => {
    setFacialWatchFiles((prevFacialWatchFiles) => [...prevFacialWatchFiles, fileName]);
  };

  const handleUploadMore = (currentFacialImageCount: number) => {
    setCurrentWatchListImageCount(currentFacialImageCount);
    setIsUploadImage(true);
  };

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Box mb={4}>
          <Typography variant="h3" className={classes.modalTitle}>
            {formatMessage('CustomWatchlist.settings.facialWatchlists.modal.title')}
          </Typography>
          {Boolean(currentWatchlistId) && (
          <Typography variant="subtitle2" className={classes.modalSubTitle}>
            {formatMessage('CustomWatchlist.settings.facialWatchlists.modal.subTitle')}
            &#58;
              {' '}
            {currentWatchlistId}
          </Typography>
          )}
        </Box>
        <div onClick={onClose} onKeyPress={onClose} role="button" tabIndex={0} className={classes.closeButton}>
          <Close />
        </div>
        {currentWatchlistId && !isUploadImage && (
        <>
          <FacialWatchlistImages handleUploadMore={handleUploadMore} />
        </>
        )}

        <>
          <Grid container direction="row" spacing={2} className={classes.marginBottom50}>
            {!currentWatchlistId && (
            <Grid item xs={12}>
              <Box mb={1}>
                <InputLabel htmlFor="watchlist-name">
                  <Typography variant="subtitle2">
                    {formatMessage('Watchlist.settings.modal.input.name.label')}
                  </Typography>
                </InputLabel>
                <TextField
                  id="watchlist-name"
                  type="input"
                  variant="outlined"
                  value={watchListName}
                  onChange={(e) => setWatchListName(e.target.value)}
                  fullWidth
                  placeholder={formatMessage('Watchlist.settings.modal.input.name.placeholder')}
                />
                {isInValidWatchListName && <FormHelperText error>{formatMessage(`CustomWatchlist.settings.facialWatchlists.modal.name.error.${isInValidWatchListName}`, { messageValues: { count: WATCHLIST_VALIDATION_NAME_MAXLENGTH } })}</FormHelperText> }
              </Box>
            </Grid>
            )}

            {(isUploadImage || !currentWatchlistId) && (
            <Grid item xs={12}>
              <UploadFacialWatchlistItems setFacialWatchList={handleFacialWatchList} uploadInProgress={uploadInProgress} setUploadInProgress={setUploadInProgress} currentWatchListImageCount={currentWatchListImageCount} />
            </Grid>
            )}

          </Grid>
          {!currentWatchlistId && (
          <Grid container spacing={2} className={classes.buttonContainer}>
            <Button className={classes.button} variant="contained" color="primary" disabled={((!watchListName) || (uploadInProgress) || (!!isInValidWatchListName) || (facialWatchFiles.length < 1))} onClick={handleCreateWatchList}>
              {formatMessage(watchlistCreateInProgress ? 'CustomWatchlist.settings.facialWatchlists.modal.uploadInProgress' : 'CustomWatchlist.settings.facialWatchlists.modal.createWatchlist')}
            </Button>
          </Grid>
          )}
          {isUploadImage && (
          <Grid container spacing={2} className={classes.buttonContainer}>
            <Button className={classes.button} variant="contained" color="primary" disabled={((uploadInProgress) || (facialWatchFiles.length < 1))} onClick={handleUpdateWatchList}>
              {formatMessage(watchlistCreateInProgress ? 'CustomWatchlist.settings.facialWatchlists.modal.updateInProgress' : 'CustomWatchlist.settings.facialWatchlists.modal.updateWatchlist')}
            </Button>
          </Grid>
          )}
        </>

      </Grid>
    </Box>
  );
}
