import React, { HTMLAttributeAnchorTarget } from 'react';
import Link from '@material-ui/core/Link';
import { FiExternalLink } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import { StyledLinkButton } from './LinkButton.styles';

export const LinkButton = ({
  link,
  className,
  target = '_blank',
}: { link: string; className?: string; target?: HTMLAttributeAnchorTarget }) => {
  const formatMessage = useFormatMessage();

  return (
    <Link href={link} underline="none" target={target} className={className}>
      <StyledLinkButton endIcon={<FiExternalLink />}>
        {formatMessage('BackgroundCheck.verification.linkToEvidence')}
      </StyledLinkButton>
    </Link>
  );
};
