import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: ' 522px',
    border: `1px solid ${theme.palette.common.black7}`,
  },
  sidebar: {
    height: '520px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0px',
    borderRight: `1px solid ${theme.palette.common.black7}`,
  },
  content: {
    overflow: 'auto',
    height: '520px',
  },
}));
