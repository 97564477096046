import React from 'react';
import Grid from '@material-ui/core/Grid';
import type { StepStatus } from 'models/Step.model';
import { ChecksByDocument, WatchListItem } from 'apps/ui';
import { getDataSourceCountry, getDataSourceFromList } from 'models/Watchlist.model';
import type { ICustomWatchlistStepData } from 'apps/CustomWatchlist';
import { VerificationDataSourceTypes } from 'apps/CustomWatchlist';
import type { DocumentTypes } from 'models/Document.model';
import { CustomFieldVerificationInfo, CustomFieldVerificationList, CustomFieldVerificationSectionInfo } from 'apps/CustomField';
import type { IVerificationCustomFieldsInputData } from 'models/CustomField.model';

export const WatchListsBySource = ({ data, status, customFieldsDataCopy, photos }:
  { data: PartialRecord<VerificationDataSourceTypes.CustomFields | DocumentTypes, ICustomWatchlistStepData[]>;
    status?: StepStatus;
    customFieldsDataCopy?: IVerificationCustomFieldsInputData;
    photos?: PartialRecord<VerificationDataSourceTypes.CustomFields | DocumentTypes, string[]>;
  }) => (
    <Grid container spacing={2}>
      {Object.entries(data)
        .map(([dataSourceKey, dataSourceValue]) => (
          <Grid item xs={12} lg={6} xl={4} key={dataSourceKey}>
            <ChecksByDocument
              docType={getDataSourceFromList(dataSourceValue)}
              country={getDataSourceCountry(dataSourceValue)}
              photos={photos && photos[dataSourceKey]}
              detailsSlot={dataSourceKey === VerificationDataSourceTypes.CustomFields
              && (
                <CustomFieldVerificationList
                  country={customFieldsDataCopy?.country}
                  fields={customFieldsDataCopy?.fields}
                  atomicContainer={CustomFieldVerificationInfo}
                  sectionContainer={CustomFieldVerificationSectionInfo}
                />
              )}
            >
              {dataSourceValue.map((item) => (
                <WatchListItem item={item} status={status} key={item.watchlist.id} />
              ))}
            </ChecksByDocument>
          </Grid>
        ))}
    </Grid>
);
