import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { ExtendedDescription, RangeSlider, Warning, BoxBordered, CountryModalSelectContainer, ToggleFloatingButtonGroup, Separator } from 'apps/ui';
import cloneDeep from 'lodash/cloneDeep';
import { useDebounce } from 'lib/debounce.hook';
import { ProductSettingsProps, ProductTypes } from 'models/Product.model';
import { useFormatMessage } from 'apps/intl';
import { SelectDataSource } from 'apps/Product';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOverlay } from 'apps/overlay';
import classNames from 'classnames';
import { DataSourceValueTypes } from 'models/Watchlist.model';
import { useMerchantPlan } from 'apps/merchant';
import { AmlCheckTypes, AmlSettingsTypes, SearchModeTypes, WebhookVersionTypes } from '../../models/Aml.model';
import { selectCanUsePremiumWatchlistsSearch, selectCanUsePremiumWatchlistsSearchAndMonitoring } from '../../state/Aml.selectors';
import { BasicWatchlist } from '../BasicWatchlist/BasicWatchlist';
import { useStyles } from './AmlSettings.styles';
import { SanctionListModalContainer } from '../SanctionListModal/components/SanctionListModalContainer/SanctionListModalContainer';

export function AmlSettings({ settings, onUpdate }: ProductSettingsProps<AmlSettingsTypes>) {
  const debounced = useDebounce();
  const [createOverlay] = useOverlay();
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { isFreemium } = useMerchantPlan();
  const allowedCountriesAmount = settings[AmlSettingsTypes.CountriesSearched].value?.length || 0;
  const canUsePremiumWatchlistsSearch = useSelector<any, boolean>(selectCanUsePremiumWatchlistsSearch);
  const canUsePremiumWatchlistsSearchAndMonitoring = useSelector<any, boolean>(selectCanUsePremiumWatchlistsSearchAndMonitoring);
  const isPremiumEnabled = canUsePremiumWatchlistsSearch || canUsePremiumWatchlistsSearchAndMonitoring;
  const [isCountriesSearchEnabled, setCountriesSearch] = useState<boolean>(Boolean(allowedCountriesAmount) && isPremiumEnabled);

  const handleSearchToggle = useCallback((_, checked: boolean) => {
    const newSettings = cloneDeep(settings);
    newSettings[AmlSettingsTypes.Search].value = checked;
    if (!checked) {
      newSettings[AmlSettingsTypes.Monitoring].value = false;
      newSettings[AmlSettingsTypes.CountriesSearched].value = [];
      setCountriesSearch(false);
    }
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const updateSettingField = useCallback((field: AmlSettingsTypes, value: unknown) => {
    const newSettings = cloneDeep(settings);
    newSettings[field].value = value;
    debounced(() => onUpdate(newSettings));
  }, [settings, debounced, onUpdate]);

  const updateToggleSettingField = useCallback((field: AmlSettingsTypes, value: unknown) => {
    const newSettings = cloneDeep(settings);
    newSettings[field].value = value;
    onUpdate(newSettings);
  }, [settings, onUpdate]);

  const handleCountriesToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    updateSettingField(AmlSettingsTypes.CountriesSearched, []);
    setCountriesSearch(event.target.checked);
  }, [updateSettingField]);

  const openCountryModal = useCallback(() => {
    const initialValues = settings[AmlSettingsTypes.CountriesSearched].value.map((countryCode: string) => ({ country: countryCode, regions: [] }));
    createOverlay(
      <CountryModalSelectContainer
        title={formatMessage('AmlCheck.settings.countriesModal.title')}
        description={formatMessage('AmlCheck.settings.countriesModal.description')}
        initialValues={initialValues}
        onSubmit={(data) => updateSettingField(AmlSettingsTypes.CountriesSearched, data.map((item) => item.country))}
        flat
      />,
    );
  }, [createOverlay, formatMessage, settings, updateSettingField]);

  const openSanctionModal = useCallback(() => {
    createOverlay(
      <SanctionListModalContainer
        title={formatMessage('AmlCheck.settings.sanctionsModal.title')}
        description={formatMessage('AmlCheck.settings.sanctionsModal.description')}
        selectedValues={settings[AmlSettingsTypes.Sanctions].value}
        onSubmit={(data) => updateSettingField(AmlSettingsTypes.Sanctions, data)}
      />,
    );
  }, [createOverlay, formatMessage, settings, updateSettingField]);

  const handleSelectDataSourceChange = useCallback((value: DataSourceValueTypes) => {
    const newSettings = cloneDeep(settings);
    newSettings[AmlSettingsTypes.DataSource].value = value;
    if (value === DataSourceValueTypes.CustomField) {
      newSettings[AmlSettingsTypes.WebhookVersion].value = WebhookVersionTypes.New;
    }
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const searchModeOptions = [
    {
      name: formatMessage('AmlCheck.settings.searchMode.fuzzy'),
      value: SearchModeTypes.Fuzzy,
    },
    {
      name: formatMessage('AmlCheck.settings.searchMode.exact'),
      value: SearchModeTypes.Exact,
    },
  ];

  const webhookVersionsOptions = [
    {
      name: formatMessage('AmlCheck.settings.webhookVersion.legacy'),
      value: WebhookVersionTypes.Legacy,
      disabled: settings[AmlSettingsTypes.DataSource].value === DataSourceValueTypes.CustomField,
    },
    {
      name: formatMessage('AmlCheck.settings.webhookVersion.new'),
      value: WebhookVersionTypes.New,
    },
  ];

  const meritIds = useMemo(() => [ProductTypes.DocumentVerification, ProductTypes.CustomField], []);

  return (
    <Box>
      <ExtendedDescription
        title={formatMessage('Watchlist.dataSource.title')}
        text={formatMessage('Watchlist.dataSource.description')}
      >
        <SelectDataSource meritIds={meritIds} value={settings[AmlSettingsTypes.DataSource].value} onChange={handleSelectDataSourceChange} />
      </ExtendedDescription>
      <Separator />
      <Box mb={2.4}>
        <BasicWatchlist
          basicWatchlistsIds={settings[AmlSettingsTypes.BasicWatchlists].value}
          isBasicWatchlistChecked={settings[AmlSettingsTypes.BasicWatchlistsPattern].value}
          onBasicWatchlistsSelected={(watchlistChecked) => updateSettingField(AmlSettingsTypes.BasicWatchlists, watchlistChecked)}
          onBasicWatchlistValidationToggle={(checked: boolean) => updateToggleSettingField(AmlSettingsTypes.BasicWatchlistsPattern, checked)}
        />
      </Box>
      <Divider className={classes.divider} />
      {!isPremiumEnabled && (
        <Box mb={2.4} mt={2}>
          {isFreemium ? (
            <Warning
              label={formatMessage('Freemium.productNotAvailable')}
              isLabelColored={false}
              meritName={formatMessage('amlCheck.name')}
              bordered
            />
          ) : (
            <Warning
              label={formatMessage('AmlCheck.settings.amlNotAvailable')}
              linkLabel={formatMessage('AmlCheck.settings.helpEmail')}
              isLabelColored={false}
              meritName={formatMessage('amlCheck.name')}
              bordered
            />
          )}
        </Box>
      )}
      <BoxBordered className={classNames(classes.boxBordered, { [classes.disabled]: !isPremiumEnabled })}>
        <ExtendedDescription
          title={formatMessage('AmlCheck.settings.watchlist.title')}
          info={formatMessage('AmlCheck.settings.watchlist.description')}
          prefix={(
            <Switch
              checked={isPremiumEnabled && settings[AmlSettingsTypes.Search].value}
              onChange={handleSearchToggle}
              color="primary"
              disabled={!isPremiumEnabled}
            />
          )}
        />
        {settings[AmlSettingsTypes.Search].value
        && (
        <Box mt={2.4}>
          <ExtendedDescription
            title={formatMessage('AmlCheck.settings.webhookVersion.title')}
            text={formatMessage('AmlCheck.settings.webhookVersion.description', {
              messageValues: {
                linkLegacy: <Link href="https://docs.getmati.com/docs/step-completed-ph-to-pr#premium-aml-watchlists-search-validation" target="__blank">{formatMessage('AmlCheck.settings.webhookVersion.linkLegacy')}</Link>,
                linkNew: <Link href="https://docs.getmati.com/docs/step-completed-ph-to-pr#premium-aml-watchlists-validation" target="__blank">{formatMessage('AmlCheck.settings.webhookVersion.linkNew')}</Link>,
              },
            })}
          >
            <ToggleFloatingButtonGroup
              value={settings[AmlSettingsTypes.WebhookVersion].value}
              options={webhookVersionsOptions}
              onChange={(version) => updateSettingField(AmlSettingsTypes.WebhookVersion, version)}
            />
          </ExtendedDescription>
          <Box mt={2.4}>
            <ExtendedDescription
              title={formatMessage('AmlCheck.settings.searchMode.title')}
              info={formatMessage('AmlCheck.settings.searchMode.description')}
            >
              <ToggleFloatingButtonGroup
                value={settings[AmlSettingsTypes.SearchMode].value}
                options={searchModeOptions}
                onChange={(mode) => updateSettingField(AmlSettingsTypes.SearchMode, mode)}
              />
            </ExtendedDescription>
          </Box>
          {settings[AmlSettingsTypes.SearchMode].value === SearchModeTypes.Fuzzy && (
          <Box mt={2.4}>
            <RangeSlider
              defaultValue={settings[AmlSettingsTypes.AmlThreshold].value}
              onChange={(_, value) => updateSettingField(AmlSettingsTypes.AmlThreshold, value)}
              disabled={!isPremiumEnabled}
            />
          </Box>

          )}
          {settings[AmlSettingsTypes.WebhookVersion].value === WebhookVersionTypes.New && (
          <Box mt={4}>
            <ExtendedDescription
              isDisabled={!isPremiumEnabled}
              title={formatMessage(`AmlCheck.settings.${AmlSettingsTypes.EntitySearch}.title`)}
              info={formatMessage(`AmlCheck.settings.${AmlSettingsTypes.EntitySearch}.description`)}
              className={classes.productBlock}
              prefix={(
                <Switch
                  checked={canUsePremiumWatchlistsSearchAndMonitoring && settings[AmlSettingsTypes.EntitySearch].value}
                  onChange={(_, checked) => updateToggleSettingField(AmlSettingsTypes.EntitySearch, checked)}
                  color="primary"
                />
                )}
            />
          </Box>
          )}
          <Box mt={2.4}>
            <ExtendedDescription
              title={formatMessage(`AmlCheck.settings.${AmlSettingsTypes.Sanctions}.title`)}
              text={formatMessage(`AmlCheck.settings.${AmlSettingsTypes.Sanctions}.description`)}
              className={classes.productBlock}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={openSanctionModal}
                className={classes.openSanctionButton}
              >
                {formatMessage(`AmlCheck.settings.${AmlSettingsTypes.Sanctions}.editButton`)}
              </Button>
            </ExtendedDescription>
          </Box>
          <Box mt={2.4}>
            <ExtendedDescription
              title={formatMessage(`AmlCheck.settings.${AmlSettingsTypes.CountriesSearched}.title`)}
              info={formatMessage(`AmlCheck.settings.${AmlSettingsTypes.CountriesSearched}.description`)}
              className={classes.productBlock}
              prefix={(
                <Switch
                  checked={isPremiumEnabled && isCountriesSearchEnabled}
                  onChange={handleCountriesToggle}
                  color="primary"
                />
            )}
            >
              {isPremiumEnabled && isCountriesSearchEnabled && (
              <>
                {Boolean(allowedCountriesAmount) && isPremiumEnabled && (
                <Box mb={1} className={classes.countriesAmount}>
                  {allowedCountriesAmount}
                  {formatMessage(`AmlCheck.settings.${AmlSettingsTypes.CountriesSearched}.amount`)}
                </Box>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={openCountryModal}
                  disabled={!isCountriesSearchEnabled || !isPremiumEnabled}
                  className={classes.openCountriesButton}
                >
                  {formatMessage(`AmlCheck.settings.${AmlSettingsTypes.CountriesSearched}.editButton`)}
                </Button>
              </>
              )}
            </ExtendedDescription>
          </Box>
          <Divider className={classes.divider} />
          <Box mt={2.4}>
            <ExtendedDescription
              isDisabled={isPremiumEnabled && settings[AmlSettingsTypes.Search].value && !canUsePremiumWatchlistsSearchAndMonitoring}
              title={formatMessage(`AmlCheck.settings.${AmlCheckTypes.Monitoring}.title`)}
              info={formatMessage(`AmlCheck.settings.${AmlCheckTypes.Monitoring}.description`)}
              className={classes.productBlock}
              prefix={(
                <Switch
                  checked={canUsePremiumWatchlistsSearchAndMonitoring && settings[AmlSettingsTypes.Monitoring].value}
                  onChange={(_, checked) => updateToggleSettingField(AmlSettingsTypes.Monitoring, checked)}
                  color="primary"
                  disabled={!canUsePremiumWatchlistsSearchAndMonitoring}
                />
            )}
            />
          </Box>
        </Box>
        )}
      </BoxBordered>
    </Box>
  );
}
