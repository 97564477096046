import { DateFormatTypes, dateSortCompare } from 'lib/date';
import dayjs from 'dayjs';
import { GovcheckLegalSearchProcess } from 'apps/GovCheckMerit/components/GovcheckLegalSearchProcess/GovcheckLegalSearchProcess';
import { GovCheckEmploymentHistory } from 'apps/GovCheckMerit/components/GovCheckEmploymentHistory/GovCheckEmploymentHistory';
import { NationalIdTypes } from './Document.model';
import { ProductTypes } from './Product.model';
import { DocumentStepTypes, IStep, StepStatus, VerificationStepTypes } from './Step.model';
import { VerificationPatternTypes } from './VerificationPatterns.model';

export type GovernmentChecksStepDataType = { subStepId?: string } | null;

export type GovernmentChecksStep = IStep<GovernmentChecksStepDataType>;

interface INigerianShareholdersCac {
  name: string;
  position: string;
  status: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  city: string;
  address: string;
  idType: string;
  idNumber: string;
  shares: string;
  accreditationNumber: string;
}

interface IShareHolders {
  name: string;
  shares: string;
  idType: string;
  idNumber: string;
  applicantNameMatched: boolean;
  isShowError: boolean;
  position: string;
}

export const GovernmentChecksProducts = [ProductTypes.IdentityValidation, ProductTypes.LegalRecords, ProductTypes.DrivingLicenseValidation, ProductTypes.TaxChecks, ProductTypes.BusinessRecordsChecks, ProductTypes.EmploymentInformation];

export const NigerianLegalValidationSubSteps = {
  [VerificationStepTypes.NigerianBvn]: {
    govDBPhotoUrl: {
      isCentered: true,
      hiddenIfNotExists: true,
    },
    firstName: {
      inline: true,
    },
    middleName: {
      inline: true,
    },
    lastName: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    bvn: {
      hidden: true,
      formatter: (bvn = [], data) => ({ ...data, documentNumber: bvn, documentType: NationalIdTypes.BVN }),
    },
    documentNumber: {},
    documentType: {
      inline: true,
    },
    subStepId: {
      hidden: true,
    },
    photo: {
      hidden: true,
    },
    signature: {
      hidden: true,
    },
  },
  [VerificationStepTypes.NigerianVin]: {
    govDBPhotoUrl: {
      isCentered: true,
      hiddenIfNotExists: true,
    },
    firstName: {
      inline: true,
    },
    middleName: {
      inline: true,
    },
    lastName: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    vin: {
      hidden: true,
      formatter: (vin = [], data) => ({ ...data, documentNumber: vin, documentType: NationalIdTypes.VIN }),
    },
    documentNumber: {},
    documentType: {
      inline: true,
    },
    profession: {
      inline: true,
    },
    subStepId: {
      hidden: true,
    },
    pollingUnitCode: {
      hidden: true,
    },
    fullName: {
      hidden: true,
    },
    occupation: {
      hidden: true,
    },
    photo: {
      hidden: true,
    },
    signature: {
      hidden: true,
    },
  },
  [VerificationStepTypes.NigerianNin]: {
    govDBPhotoUrl: {
      isCentered: true,
      hiddenIfNotExists: true,
    },
    firstName: {
      inline: true,
    },
    middleName: {
      inline: true,
    },
    lastName: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    nin: {
      hidden: true,
      formatter: (nin = [], data) => ({ ...data, documentNumber: nin, documentType: NationalIdTypes.NIN }),
    },
    documentNumber: {},
    documentType: {
      inline: true,
    },
    profession: {
      inline: true,
    },
    phone: {
      inline: true,
    },
    subStepId: {
      hidden: true,
    },
    photo: {
      hidden: true,
    },
    stateOfOrigin: {
      hidden: true,
    },
    lgaOfOrigin: {
      hidden: true,
    },
    placeOfOrigin: {
      hidden: true,
    },
    title: {
      hidden: true,
    },
    height: {
      hidden: true,
    },
    email: {
      hidden: true,
    },
    birthState: {
      hidden: true,
    },
    birthCountry: {
      hidden: true,
    },
    nextOfKin: {
      hidden: true,
    },
    nspokenlang: {
      hidden: true,
    },
    religion: {
      hidden: true,
    },
    signature: {
      hidden: true,
    },
    residence: {
      hidden: true,
    },
    fieldMatches: {
      hidden: true,
    },
  },
  [VerificationStepTypes.NigerianVirtualNin]: {
    govDBPhotoUrl: {
      isCentered: true,
      hiddenIfNotExists: true,
    },
    photo: { hidden: true },
    vNin: {},
    dateOfBirth: {
      formatter: (dob, data) => ({ ...data, dateOfBirth: dayjs(dob).format(DateFormatTypes.DateShort) }),
    },
  },
};

export const NigerianAffiliatesCACDisplayOptions = {
  shareholders: {
    formatter: (shareholders: INigerianShareholdersCac[] = [], data) => ({
      ...data,
      shareholders: shareholders.map((shareholder) => ({
        name: shareholder.name,
        position: shareholder.position,
        dateOfBirth: shareholder.dateOfBirth,
        status: shareholder.status,
        phoneNumber: shareholder.phoneNumber,
        email: shareholder.email,
        address: shareholder.address,
        city: shareholder.city,
        idType: shareholder.idType,
        idNumber: shareholder.idNumber,
        share: shareholder.shares,
        accreditationNumber: shareholder.accreditationNumber,
        title: `${shareholder.name}, ${shareholder.position}`.toUpperCase(),
      })),
    }),
  },
};

export const GovernmentChecksStepTitleOptions = {
  [VerificationStepTypes.MexicanImss]: {
    [StepStatus.Success]: {
      formatter: (step) => (step?.data?.salarySummary?.length > 0 ? 'SecurityCheckStep.mexican-imss-validation.found' : 'SecurityCheckStep.mexican-imss-validation.notFound'),
    },
  },
};

export const GovernmentChecksDisplayOptions = {
  [VerificationStepTypes.NigerianAv]: {
    confidence: {
      hidden: true,
    },
    message: {
      hidden: true,
    },
  },
  [VerificationStepTypes.NigerianLegalValidation]: {
    ...NigerianLegalValidationSubSteps,
  },
  ...NigerianLegalValidationSubSteps,
  [VerificationStepTypes.PhilippineUMIDSSNValidation]: {
    documentNumber: {},
    valid: {},
  },
  [DocumentStepTypes.ArgentinianDni]: {
    documentNumber: {
      hidden: true,
    },
  },
  [DocumentStepTypes.BolivianOep]: {
    fullName: {},
    documentNumber: {},
    city: {
      inline: true,
    },
    state: {
      inline: true,
    },
    country: {},
  },
  [DocumentStepTypes.BrazilianCpf]: {
    fullName: {},
    dateOfBirth: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    gender: {},
    cpfNumber: {
      inline: true,
    },
    taxStatus: {
      inline: true,
    },
    documentType: {
      inline: true,
    },
    documentNumber: {
      inline: true,
    },
    expirationDate: {
      inline: true,
    },
    governmentFaceMatched: {
      hidden: true,
    },
    governmentFaceMatchScorePercentage: {
      dependentFieldForFailedCheck: 'governmentFaceMatched',
      hiddenIfNotExists: true,
    },
  },
  [DocumentStepTypes.BrazilianCpfLight]: {
    fullName: {},
    dateOfBirth: {
      inline: true,
    },
    taxStatus: {
      inline: true,
    },
  },
  [DocumentStepTypes.DominicanJce]: {
    valid: {},
  },
  [DocumentStepTypes.DominicanRnc]: {
    valid: {
      hidden: true,
    },
    fullName: {},
    rnc: {},
    commercialName: {},
    category: {
      inline: true,
    },
    paymentScheme: {
      inline: true,
    },
    status: {},
  },
  [DocumentStepTypes.CostaRicanAtv]: {
    fullName: {},
    registeredTaxPayer: {},
    dateOfRegistration: {},
    documentNumber: {
      inline: true,
    },
    hasDebts: {},
    documentStatus: {
      hidden: true,
    },
  },
  [DocumentStepTypes.ColombianNationalPolice]: {
    documentNumber: {
      inline: true,
    },
    fullName: {},
    criminalRecords: {},
  },
  [DocumentStepTypes.GuatemalanTse]: {
    fullName: {},
  },
  [DocumentStepTypes.ColombianContraloria]: {
    fiscalStatusValid: {},
    documentNumber: {
      inline: true,
    },
    verificationCode: {
      inline: true,
    },
  },
  [DocumentStepTypes.ColombianMigraciones]: {
    fullName: {},
    nationality: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    status: {
      inline: true,
    },
    dateOfIssue: {
      inline: true,
    },
    dateOfExpiry: {},
    certificateCode: {},
  },
  [DocumentStepTypes.ColombianProcuraduria]: {
    fullName: {},
    documentNumber: {
      inline: true,
    },
    criminalRecords: {},
  },
  [DocumentStepTypes.ColombianRegistraduria]: {
    fullName: {},
    name: {
      inline: true,
    },
    middleName: {
      inline: true,
    },
    surname: {
      inline: true,
    },
    secondSurname: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    ageRange: {
      inline: true,
    },
    documentNumber: {
    },
    emissionDate: {
      inline: true,
    },
    issuePlace: {
      inline: true,
    },
    savingAccountsCount: {
      inline: true,
    },
    solidarityIndustryDebtsCount: {
      inline: true,
    },
    serviceIndustryDebtsCount: {
      inline: true,
    },
    financialIndustryDebtsCount: {
      inline: true,
    },
    commercialIndustryDebtsCount: {
      inline: true,
    },
    bankAccountsCount: {
      inline: true,
    },
  },
  [DocumentStepTypes.ColombianNit]: {
    taxId: {},
  },
  [DocumentStepTypes.ColombianPpt]: {
    fullName: {
      inline: true,
    },
    phone: {
      inline: true,
    },
    email: {
      inline: true,
    },
    documentType: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    dni: {
      inline: true,
    },
    status: {
      inline: true,
    },
    photoUrl: {
      hidden: true,
    },
  },
  [DocumentStepTypes.ColombianSisben]: {
    sisbenGroup: {
      inline: true,
    },
    sisbenGroupDescription: {
      inline: true,
    },
    firstName: {
      inline: true,
    },
    lastName: {
      inline: true,
    },
    documentType: {
      inline: true,
    },
    documentNumber: {
      inline: true,
    },
    municipality: {
      inline: true,
    },
    department: {
      inline: true,
    },
    surveyDate: {
      inline: true,
    },
    lastUpdate: {
      inline: true,
    },
  },
  [DocumentStepTypes.CostaRicanTse]: {
    firstName: {
      inline: true,
    },
    lastName: {
      inline: true,
    },
    secondSurname: {},
    dateOfBirth: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    documentNumber: {
      inline: true,
    },
    deceased: {
      inline: true,
    },
  },
  [DocumentStepTypes.GhanaianGra]: {
    personalNumber: {
      inline: true,
    },
    taxStatusValid: {},
  },
  [DocumentStepTypes.HonduranRnp]: {
    documentNumber: {
      inline: true,
    },
    fullName: {
      inline: true,
    },
    city: {},
  },
  [DocumentStepTypes.MexicanPep]: {
    fullName: {},
    isPep: {},
    Position: {},
    State: {},
    Institution: {},
    Period: {},
    curp: {},
    rfc: {},
    gender: {},
    pepData: {
      hidden: true,
    },
    stepExtra: {
      hidden: true,
    },
  },
  [VerificationStepTypes.NigerianCacValidation]: {
    companyName: {},
    cacNumber: {
      hidden: true,
    },
    status: {
      hidden: true,
    },
    registrationDate: {
      hidden: true,
    },
    type: {
      hidden: true,
    },
    companyAddress: {},
    companyEmail: {},
  },
  [VerificationStepTypes.NigerianAffiliatesCac]: NigerianAffiliatesCACDisplayOptions,
  [VerificationStepTypes.NigerianShareholdersCac]: NigerianAffiliatesCACDisplayOptions,
  [DocumentStepTypes.NigerianDl]: {
    govDBPhotoUrl: {
      isCentered: true,
      hiddenIfNotExists: true,
    },
    firstName: {
      inline: true,
    },
    lastName: {
      inline: true,
    },
    middleName: {
    },
    gender: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    stateOfIssue: {
      inline: true,
    },
    issuedDate: {
      inline: true,
    },
    licenseNo: {
      inline: true,
    },
    expiryDate: {
      inline: true,
    },
    photo: {
      hidden: true,
    },
  },
  [DocumentStepTypes.ParaguayanRcp]: {
    fullName: {},
    gender: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    city: {
      inline: true,
    },
    state: {
      inline: true,
    },
  },
  [DocumentStepTypes.PeruvianSunat]: {},
  [DocumentStepTypes.SalvadorianTse]: {
    fullName: {},
    documentNumber: {},
    address: {},
    city: {
      inline: true,
    },
    state: {
      inline: true,
    },
  },
  [DocumentStepTypes.VenezuelanCne]: {
    fullName: {},
    documentNumber: {},
    city: {
      inline: true,
    },
    state: {
      inline: true,
    },
  },
  [DocumentStepTypes.VenezuelanSeniat]: {
    documentNumber: {},
    fullName: {},
  },
  [DocumentStepTypes.CostaRicanSocialSecurity]: {
    fullName: {},
    insuranceStatus: {},
  },
  [DocumentStepTypes.ChileanRut]: {
    fullName: {},
    rut: {
      inline: true,
    },
    active: {
      inline: true,
    },
    economicActivities: {
      hidden: true,
      formatter: (economicActivities = [], data) => ({ ...data, ...economicActivities.sort((a, b) => dateSortCompare(a.createdAt, b.createdAt))[0] }),
    },
    activity: {
      hiddenIfNotExists: true,
    },
    code: {
      hiddenIfNotExists: true,
      inline: true,
    },
    affectsVat: {
      hiddenIfNotExists: true,
      inline: true,
    },
    category: {
      hiddenIfNotExists: true,
      inline: true,
    },
    date: {
      hiddenIfNotExists: true,
      inline: true,
    },
  },
  [DocumentStepTypes.ChileanDriverLicense]: {
    fullName: {},
    run: {
      inline: true,
    },
    blocked: {
      inline: true,
    },
    blockType: {
      inline: true,
      hideIsField: false,
      filedCondition: 'blocked',
    },
    blockedDate: {
      inline: true,
      hideIsField: false,
      filedCondition: 'blocked',
    },
    blockedReason: {
      inline: true,
      hideIsField: false,
      filedCondition: 'blocked',
    },
  },
  [DocumentStepTypes.ColombianRunt]: {
    fullName: {},
    documentNumber: {
      inline: true,
    },
    activeDriver: {
      inline: true,
    },
    dateOfRegistration: {
      inline: true,
    },
    licenseData: {
      hidden: true,
      formatter: (licenseData: any = {}, data) => ({ ...data, hasFines: licenseData.hasFines, activeLicense: licenseData.driverLicenses?.some((dl) => dl.activeLicense) }),
    },
    activeLicense: {
      inline: true,
    },
    hasFines: {
      inline: true,
    },
  },
  [DocumentStepTypes.ArgentinianRenaper]: {
    fullName: {},
    dateOfBirth: {},
    dni: {
      inline: true,
    },
    cuit: {
      inline: true,
    },
  },
  [DocumentStepTypes.ArgentinianRenaperExtended]: {
    fullName: {
    },
    firstName: {
      inline: true,
    },
    surname: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    nationality: {
      inline: true,
    },
    dateOfDeath: {
      inline: true,
    },
    documentNumber: {
      inline: true,
    },
    dateOfIssue: {
      inline: true,
    },
    dateOfExpiry: {
      inline: true,
    },
    transactionNumber: {
      inline: true,
    },
    version: {
      inline: true,
    },
    taxIdType: {
      inline: true,
    },
    taxNumber: {
      inline: true,
    },
    activityCode: {
      inline: true,
    },
    activityDescription: {
    },
    address: {
    },
    email: {
    },
    phoneNumbers: {
      inline: true,
    },
    pep: {
      inline: true,
    },
    sanctioned: {
      inline: true,
    },
    sujetoObligado: {
      inline: true,
    },
    dniNumber: {
      hidden: true,
    },
    cuit: {
      hidden: true,
    },
    deceased: {
      hidden: true,
    },
  },
  [VerificationStepTypes.KenyanIprsValidation]: {
    documentNumber: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    givenNames: {
      hidden: true,
    },
    lastName: {
      hidden: true,
    },
    fullName: {
      inline: true,
    },
    fullNameMatch: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    dobMatch: {
      inline: true,
    },
  },
  [VerificationStepTypes.NigerianTinValidation]: {
    companyName: {},
    FirsNumber: {
      inline: true,
    },
    CacNumber: {
      inline: true,
    },
    JtbNumber: {
      inline: true,
    },
    taxOffice: {
      inline: true,
    },
    companyPhone: {
      inline: true,
    },
    companyEmail: {
      inline: true,
    },
  },
  [VerificationStepTypes.KenyanBRC]: {
    companyName: {},
    registrationNumber: {
      inline: true,
    },
    companyType: {
      inline: true,
    },
    registrationDate: {
      inline: true,
    },
    registrationStatus: {
      inline: true,
    },
    postalAddress: {
    },
    physicalAddress: {
    },
    phoneNumber: {
      inline: true,
    },
    kraPIN: {
      inline: true,
    },
    shareCapital: {
      inline: true,
    },
    shareNominalValue: {
      inline: true,
    },
    stepExtra: {
      formatter: (stepExtra: IShareHolders[] = [], data) => ({
        ...data,
        stepExtra: stepExtra.map((extra) => ({
          name: extra.name,
          idNumber: extra.idNumber,
          shares: extra.shares,
          idType: extra.idType,
          applicantNameMatched: extra.applicantNameMatched,
          position: extra.position,
          isShowError: data.applicantName && !stepExtra.some(({ applicantNameMatched }) => applicantNameMatched),
        })),
      }),
    },
    applicantName: {
      inline: true,
    },
    verified: {
      inline: true,
    },
  },
  [VerificationStepTypes.IndonesianKTPValidation]: {
    name: {
      inline: true,
    },
    nameMatch: {
      inline: true,
    },
    nik: {
      inline: true,
    },
    nikStatus: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    dateOfBirthMatch: {
      inline: true,
    },
    score: {
      inline: true,
      hiddenIfNotExists: true,
    },
    faceMatch: {
      inline: true,
      hiddenIfNotExists: true,
    },
  },
  [VerificationStepTypes.PhilippineDlValidation]: {
    licenseNumber: {},
    serialNumber: {},
    expirationDate: {},
    fullName: {},
    dateOfBirth: {},
    gender: {},
    valid: {},
  },
  [DocumentStepTypes.EcuadorianSocialSecurity]: {
    fullName: {},
    dateOfBirth: {},
    age: {},
    insurance: {
      hidden: true,
    },
  },
  [VerificationPatternTypes.MexicanCurp]: {
    birthDate: {},
    curp: {},
    fullName: {},
    gender: {},
    nationality: {},
    name: {},
    surname: {},
    secondSurname: {},
    curpStatus: {
      formatter: (_, { curpStatus, ...data }) => ({ ...data, curpStatusType: curpStatus?.type, curpStatusDescription: curpStatus?.description }),
      hidden: true,
    },
    curpStatusType: {},
    curpStatusDescription: {},
    registration: {
      formatter: (_, { registration, ...data }) => ({ ...data, registrationState: registration?.state, registrationMunicipality: registration?.municipality?.name, registrationYear: registration?.year }),
      hidden: true,
    },
  },
  [VerificationStepTypes.PhilippineNbiValidation]: {
    govDBPhotoUrl: {
      isCentered: true,
      hiddenIfNotExists: true,
    },
    name: {
      formatter: (_, data) => ({ ...data, name: `${data.lastName}, ${data.firstName} ${data.middleName}` }),
    },
    firstName: {
      hidden: true,
    },
    middleName: {
      hidden: true,
    },
    lastName: {
      hidden: true,
    },
    documentNumber: {
      inline: true,
    },
    clearanceStatus: {
      inline: true,
    },
    bday: {
      inline: true,
    },
    gender: {
      inline: true,
    },
    civilStatus: {},
  },
  [VerificationStepTypes.PhilippinePrcValidation]: {
    fullName: {},
    profession: {
      inline: true,
    },
    dateOfBirth: {
      inline: true,
    },
    documentNumber: {
      inline: true,
    },
    licenseStatus: {
      inline: true,
    },
    registrationDate: {
      inline: true,
    },
    expirationDate: {
      inline: true,
    },
  },
  [VerificationStepTypes.PhilippinePNPValidation]: {
    documentNumber: {},
    fullName: {},
    clearanceStatus: {},
  },
  [DocumentStepTypes.ColombianUnifiedLegalSearch]: {
    fullName: {
      hidden: true,
    },
    results: {
      formatter: (value, data) => {
        const mapRecordData = (record) => ({
          region: record.despacho,
          involvedParties: record.sujetosProcesales,
          processId: record.idProceso,
          processNumber: record.llaveProceso,
          processType: record.processType,
          processClass: record.processClass,
          openedAt: record.fechaProceso,
          updatedAt: record.fechaUltimaActuacion,
        });

        const departmentMap = new Map();

        value?.forEach((record) => {
          const departmentRecords = departmentMap.get(record.departamento);
          if (!departmentRecords) {
            departmentMap.set(record.departamento, [mapRecordData(record)]);
          } else {
            departmentRecords.push(mapRecordData(record));
          }
        });

        return {
          ...data,
          results: Array.from(departmentMap.entries())
            .map(([department, records]) => ({
              department,
              records,
            })),
        };
      },
      component: GovcheckLegalSearchProcess,
    },
  },
  [VerificationPatternTypes.ArgentinianRenaperPremium]: {
    dateOfBirth: {},
    firstName: {},
    lastName: {},
    nationalId: {},
    taxid: {},
    issueDate: {},
    expiryDate: {},
    copyCode: {},
    deathNotice: {},
    nationality: {},
    gender: {},
    citizenId: {},
    streetName: {},
    streetNumber: {},
    floor: {},
    apartment: {},
    zipCode: {},
    neighborhood: {},
    city: {},
    town: {},
    state: {},
    country: {},
    idMainIssue: {
      hidden: true,
    },
    idReprint: {
      hidden: true,
    },
    monobloc: {
      hidden: true,
    },
    dateOfDeath: {
      hidden: true,
    },
    placeOfBirth: {
      hidden: true,
    },
    nationalIdValidation: {
      hidden: true,
    },
    issueDateValidation: {
      hidden: true,
    },
    genderValidation: {
      hidden: true,
    },
  },
  [VerificationPatternTypes.MexicanRfcStatus]: {
    stepExtra: {
      hidden: true,
    },
  },
  [VerificationPatternTypes.BrazilianCnpj]: {
    companyName: {},
    commercialName: {
      inline: true,
    },
    dateOfOpening: {
      inline: true,
    },
    status: {
      inline: true,
    },
    cnpj: {
      inline: true,
    },
    companySize: {
      inline: true,
    },
    statusReason: {
      inline: true,
    },
    specialStatusDate: {
      inline: true,
    },
    statusDate: {
      inline: true,
    },
    companyType: {},
    mainActivity: {},
    secondaryActivities: {
      hidden: true,
      formatter: (value, data) => {
        const {
          secondaryActivities,
          ...restData
        } = data;
        return {
          ...restData,
          companySecondaryActivities: secondaryActivities?.map((activity) => ({ activity })) ?? [],
        };
      },
    },
    contact: {
      hidden: true,
      formatter: (value, data) => {
        const {
          email,
          telephone,
          ...restData
        } = data;

        return {
          ...restData,
          companyContact: {
            email,
            telephone,
          },
        };
      },
    },
    address: {
      hidden: true,
      formatter: (value, data) => {
        const {
          number,
          zipCode,
          district,
          municipality,
          details,
          street,
          state,
          ...restData
        } = data;

        return {
          ...restData,
          companyAddress: {
            street,
            number,
            zipCode,
            district,
            municipality,
            state,
            details,
          },
        };
      },
    },
    municipality: {
      hidden: true,
    },
    details: {
      hidden: true,
    },
    district: {
      hidden: true,
    },
    number: {
      hidden: true,
    },
    zipCode: {
      hidden: true,
    },
    state: {
      hidden: true,
    },
    EFR: {
      hidden: true,
    },
  },
  [VerificationPatternTypes.BrazilianCNPJExtended]: {
    address: {
      hidden: true,
      formatter: (val, { address, ...data }) => ({
        ...data,
        addressInfo: {
          locality: address?.locality,
          street: address?.street,
          areaNumber: address?.areaNumber,
          postcode: address?.postcode,
          localitySector: address?.localitySector,
          additionalAddress: address?.additionalAddress,
          administrativeCode: address?.county?.administrativeCode,
          administrativeName: address?.county?.administrativeName,
          country: address?.country?.description,
        },
      }),
    },
    cadastralStatus: {
      hidden: true,
      formatter: (val, { cadastralStatus, ...data }) => ({ ...data, registrationStatus: cadastralStatus?.registrationStatus }),
    },
    legalNature: {
      hidden: true,
      formatter: (val, { legalNature, ...data }) => ({ ...data, legalCode: legalNature?.legalCode, legalDescription: legalNature?.legalDescription }),
    },
    cnaePrincipal: {
      hidden: true,
      formatter: (val, { cnaePrincipal, ...data }) => ({ ...data, cnaeCode: cnaePrincipal?.cnaeCode, cnaeDescription: cnaePrincipal?.cnaeDescription }),
    },
    companyName: {},
    registrationNumber: {
      inline: true,
    },
    legalName: {
      inline: true,
    },
    registrationDate: {
      inline: true,
    },
    registrationStatus: {
      inline: true,
    },
    legalCode: {},
    legalDescription: {},
    cnaeCode: {},
    cnaeDescription: {},
    addressInfo: {},
    phones: {},
    email: {},
    shareholdersInfo: {
      formatter: (val, { shareholdersInfo, ...data }) => ({
        ...data,
        shareholdersInfo: shareholdersInfo ? shareholdersInfo.map((shareholder) => ({
          shareholderName: shareholder.shareholderName,
          shareholderType: shareholder.shareholderType,
          cpfNumber: shareholder.cpfNumber,
          country: shareholder.country.description,
          assignationDate: shareholder.assignationDate,
          legalRepresentationName: shareholder.legalRepresentation.representativeName,
          representativeCpf: shareholder.legalRepresentation.representativeCpf,
        })) : [],
      }),
    },
    tipoEstabelecimento: { hidden: true },
    municipalitiesJurisdiction: { hidden: true },
    capitalSocial: { hidden: true },
    companySize: { hidden: true },
    situacaoEspecial: { hidden: true },
    dataSituacaoEspecial: { hidden: true },
    informacoesAdicionais: { hidden: true },
  },
  [VerificationStepTypes.MexicanImss]: {
    employmentSummary: {
      hidden: true,
      formatter: (value, { employmentSummary, ...data }) => ({
        ...data,
        ...(employmentSummary ?? {}),
      }),
    },
    status: { inline: true },
    curp: { inline: true },
    activeJob: { inline: true },
    ageFirstJob: { inline: true },
    averageWorkingDays: { inline: true },
    discountedWeeks: { inline: true },
    employedLast3Months: { inline: true },
    employedLast3Years: { inline: true },
    imssWeeks: { inline: true },
    isssteWeeks: { inline: true },
    listedWeeks: { inline: true },
    longestJobPeriod: { inline: true },
    maxBaseSalary: { inline: true },
    minBaseSalary: { inline: true },
    netMonthlySalary: { inline: true },
    nss: { inline: true },
    projectedMonthlySalary: { inline: true },
    reinstatedWeeks: { inline: true },
    shortestJobPeriod: { inline: true },
    totalActiveJobs: { inline: true },
    totalEmployers: { inline: true },
    totalJobs: { inline: true },
    salarySummary: {
      component: GovCheckEmploymentHistory,
    },
  },
};
