export const ChecksWithImage = [
  'govdbphotourl',
];

export const ChecksWithObject = [
  'stepextra',
  'shareholders',
  'afip',
  'addressinfo',
  'shareholdersinfo',
  'phones',
  'companycontact',
  'companyaddress',
  'companysecondaryactivities',
];

export const TitleKeyByLabel = {
  stepExtra: 'name',
  shareholders: 'title',
  shareholdersInfo: 'shareholderName',
};

export const isShowTitleInData = {
  stepExtra: true,
  shareholders: false,
};

export const IsSuccessByKey = {
  stepExtra: 'applicantNameMatched',
};

export const IsShowErrorByKey = {
  stepExtra: 'isShowError',
};
