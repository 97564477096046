import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { AntTab, AntTabs, useStyles } from './Tabbing.styles';

interface ICustomizedTabsProps{
  tabs: string[];
  categoryTabsDisplayNames: string[];
  children: ReactNode;
  updateCurrSelectedCategory: (currSelectedCategory: string) => void;
}

export function TabsComponent({ tabs, categoryTabsDisplayNames, children, updateCurrSelectedCategory }: ICustomizedTabsProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    updateCurrSelectedCategory(tabs[newValue]);
  };
  const customizedTabsArr = categoryTabsDisplayNames || [];
  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        <Grid container className={classes.gridContainerRoot} spacing={4} direction="row">
          <AntTabs value={value} onChange={handleChange} aria-label="customizedTabs">
            {
              customizedTabsArr.map((tab, i) => (
                <AntTab key={i} label={tab} />
              ))
            }
          </AntTabs>
        </Grid>
      </div>
      <div className={classes.meritBlockList}>
        {children}
      </div>
    </div>
  );
}
