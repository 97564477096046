import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { GovernmentChecksStep } from 'models/GovernmentChecksMerit.model';
import { CheckBarExpandable, CheckStepDetails, CheckStepDetailsEntry } from 'apps/checks';
import { useGovCheckData } from 'hooks/useGovCheckData';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { isSecondaryError } from 'models/Step.model';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { getCheckTitle } from '../../../../models/GovCheckMerit.model';

export const GovCheckError = ({ step }: { step: GovernmentChecksStep }) => {
  const formatMessage = useFormatMessage();
  const stepDataEntries = useGovCheckData(step, false, CheckStepDetailsEntry);
  const { id, checkStatus, error: { code } } = step;
  const title = getCheckTitle(step);
  const shouldShowDetails = !!stepDataEntries.length && isSecondaryError(id, code);
  const errorMessage = formatMessage(`SecurityCheckStep.${code}.message`, { defaultMessage: formatMessage(`SecurityCheckStep.govCheck.${checkStatus}`) });

  return (
    <CheckBarExpandable
      title={title ?? `SecurityCheckStep.${id}.title`}
      isError
      isNoBadge
    >
      <CheckStepDetails step={step}>
        {shouldShowDetails && (
        <Box mt={1} className="error">
          <ErrorMessage error={errorMessage} />
          <Box mt={2}>
            <Grid container>
              {stepDataEntries}
            </Grid>
          </Box>
        </Box>
        )}
        {!shouldShowDetails && errorMessage}
      </CheckStepDetails>
    </CheckBarExpandable>
  );
};
