import React from 'react';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './ItemValue.styles';

export const ItemValue = ({
  value,
  title,
  classNames,
}: {
  value: React.ReactNode;
  title: React.ReactNode;
  classNames?: {
    value?: string;
    title?: string;
    container?: string;
  };
}) => {
  const classes = useStyles();
  return (
    <Box className={classNames?.container}>
      <Typography className={classnames(classes.value, classNames?.value, 'fs-exclude')}>
        {value || '-'}
      </Typography>
      <Typography className={classnames(classes.title, classNames?.title)}>
        {title || '-'}
      </Typography>
    </Box>
  );
};
