import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  watchlistImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  button: {
    minHeight: 50,
    fontSize: 14,
    fontWeight: 'bold',
    transition: '.2s all ease-in-out',
    '&.Mui-disabled': {
      opacity: 0.3,
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
}));
