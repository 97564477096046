import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ProductSettingsProps } from 'models/Product.model';
import cloneDeep from 'lodash/cloneDeep';
import { useFormatMessage } from 'apps/intl';
import { useMerchantPlan } from 'apps/merchant';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDebounce } from 'lib/debounce.hook';
import { ExtendedDescription, RangeSlider, TextFieldName, TextFieldNameWithOptions, Warning } from 'apps/ui';
import { validateRangeLength } from 'lib/validations';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import { selectLanguage, selectMerchantTags } from 'state/merchant';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { CHANGE_SELECT_REASON, getLanguageObject, ILanguage, LocaleKeysAdapter, MAX_SCRIPT_LENGTH, MIN_SCRIPT_LENGTH, MockLanguagesId, SCRIPT_ROW_COUNT, VideoAgreementSettingTypes } from '../../models/VideoAgreement.model';
import { useStyles } from './VideoAgreementSettings.style';

function VideoAgreementSettingsComponent({ settings, onUpdate }: ProductSettingsProps<VideoAgreementSettingTypes>) {
  const [script, setScript] = useState<string>('');
  const [scriptError, setScriptError] = useState<Nullable<string>>('');
  const { isFreemium } = useMerchantPlan();
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const debounced = useDebounce();
  const scriptLength = script.length;
  const selectLanguageId = useSelector<any, string>(selectLanguage);
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const isVideoAgreementFeatureEnabled = merchantTags.includes(MerchantTagsTypes.CanUseVideoAgreement);

  useEffect(() => {
    setScript(settings?.[VideoAgreementSettingTypes.Script].value);
  }, [settings]);

  useEffect(() => {
    const errorCode = validateRangeLength(script, MIN_SCRIPT_LENGTH, MAX_SCRIPT_LENGTH);
    setScriptError(errorCode ? formatMessage(`Product.configuration.VideoAgreement.${errorCode}`) : null);
  }, [script]);

  const selectedLanguage = useMemo<ILanguage>(() => {
    const selectedLocaleId = settings?.language?.value;
    return getLanguageObject(selectedLocaleId || LocaleKeysAdapter[selectLanguageId], formatMessage);
  }, [formatMessage, settings, selectLanguageId]);

  const handleUpdate = useCallback((settingId: VideoAgreementSettingTypes, value: unknown) => {
    const newSettings = cloneDeep(settings);
    newSettings[settingId].value = value;
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleChangeScript = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setScript(value);
    handleUpdate(VideoAgreementSettingTypes.Script, value);
  }, [debounced, handleUpdate, formatMessage]);

  const handleChangeLanguage = useCallback((event: ChangeEvent<{}>, value: ILanguage, changeReason: AutocompleteChangeReason) => {
    if (changeReason === CHANGE_SELECT_REASON) {
      handleUpdate(VideoAgreementSettingTypes.Language, value.id);
    }
  }, [handleUpdate]);

  const handleChangeThreshold = useCallback((event: React.ChangeEvent<{}>, value: number | number[]) => {
    debounced(() => handleUpdate(VideoAgreementSettingTypes.TextMatching, value));
  }, [debounced, handleUpdate]);

  const mockLanguages = useMemo(() => MockLanguagesId.map((id) => ({
    label: formatMessage(`Languages.${id}`),
    id,
  })), [MockLanguagesId]);

  return (
    <Grid container direction="row" xs={12}>
      {!isVideoAgreementFeatureEnabled && (
        <Box mb={2.4} mt={2.4}>
          {isFreemium ? (
            <Warning
              label={formatMessage('Freemium.productNotAvailable')}
              isLabelColored={false}
              meritName={formatMessage('CustomDocument')}
              bordered
            />
          ) : (
            <Warning
              label={formatMessage('VideoAgreement.settings.VideoAgreementNotAvailable')}
              linkLabel={formatMessage('VideoAgreement.settings.helpEmail')}
              isLabelColored={false}
              bordered
            />
          )}
        </Box>
      )}
      <Box mt={2.4}>
        <Grid container direction="row" xs={12}>
          <ExtendedDescription
            title={formatMessage('Product.configuration.VideoAgreement.script.title')}
            text={formatMessage('Product.configuration.VideoAgreement.script.description')}
          />
          <Box mt={1.2} width="100%">
            <TextFieldName
              type="text"
              size="small"
              error={!!scriptError}
              helperText={scriptError}
              multiline
              fullWidth
              value={script}
              onChange={handleChangeScript}
              placeholder=""
              rows={SCRIPT_ROW_COUNT}
              disabled={!isVideoAgreementFeatureEnabled}
            />
            <Box color="common.black75" pl={0.5}>
              <Typography>
                {`${scriptLength}/${MAX_SCRIPT_LENGTH}`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box mt={2.4} width="100%">
        <ExtendedDescription
          title={formatMessage('Product.configuration.VideoAgreement.language.title')}
          text={formatMessage('Product.configuration.VideoAgreement.language.description')}
        />
        <TextFieldNameWithOptions
          options={mockLanguages}
          onChange={handleChangeLanguage}
          value={selectedLanguage}
          disabled={!isVideoAgreementFeatureEnabled}
        />
      </Box>
      <Box className={classes.sliderContainer} mt={2.4}>
        <ExtendedDescription
          title={formatMessage('Product.configuration.VideoAgreement.textmatch.title')}
          text={formatMessage('Product.configuration.VideoAgreement.textmatch.description')}
        />
        <Box className={classes.sliderBox}>
          <RangeSlider
            defaultValue={settings[VideoAgreementSettingTypes.TextMatching].value}
            onChange={handleChangeThreshold}
            className={classes.slider}
            isShowOptimal={false}
            disabled={!isVideoAgreementFeatureEnabled}
            textId={{
              lowValue: 'Product.configuration.VideoAgreement.textmatch.low',
              maxValue: 'Product.configuration.VideoAgreement.textmatch.max',
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
}

export const VideoAgreementSettings = React.memo(VideoAgreementSettingsComponent);
