export const RISK_ASSESSMENT_RANGE = [[0, 30], [30, 60], [60, Infinity]];
export const DELIVERABILITY_RANGE = [[0, 0], [1, 2], [3, 4]];
export const RISK_ASSESSMENT_COLORS = ['green', 'yellow', 'red'];
export const DELIVERABILITY_COLORS = ['red', 'yellow', 'green'];

export enum EmailRiskVariablesOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum EmailRiskVariablesFilterTypes {
  ALL = 'all',
  RISK = 'risk',
  DELIVERABILITY = 'deliverability',
}

export enum EmailRiskVariablesWeightTypes {
  LOW = 0,
  MEDIUM = 1,
  HEADVY = 2,
  VERYHEAVY = 3,
}

export const EmailRiskVariablesMap: { [key: string]: { filterType: EmailRiskVariablesFilterTypes, weight: EmailRiskVariablesWeightTypes } } = {
  emailAddress: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  disposable: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  smtp_score: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  overall_score: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  generic: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  common: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  dns_valid: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  honeypot: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  deliverability: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.HEADVY,
  },
  frequent_complainer: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  spam_trap_score: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.HEADVY,
  },
  catch_all: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  timed_out: {
    filterType: EmailRiskVariablesFilterTypes.DELIVERABILITY,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  suspect: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.LOW,
  },
  recent_abuse: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.VERYHEAVY,
  },
  leaked: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  domain_age: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  first_seen: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  domain_velocity: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.MEDIUM,
  },
  user_activity: {
    filterType: EmailRiskVariablesFilterTypes.RISK,
    weight: EmailRiskVariablesWeightTypes.HEADVY,
  },
};
