import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as CrossIcon } from 'assets/icon-btn-close.svg';
import classnames from 'classnames';
import { FailedTrialStatus, TrialRecord } from 'models/BackgroundCheck.model';
// TODO: @aleksei.rasskazov refactor SpoilerTypes to be able to import it from apps/ui
import { SpoilerTypes } from 'apps/ui/components/Spoiler/SpoilerTypes';
import { WarningBadge, Spoiler } from 'apps/ui';
import { useStyles } from './BrazilTrialRecord.styles';
import { BrazilRecordFailedContent } from './components/BrazilRecordFailedContent/BrazilRecordFailedContent';

const typeByStatus: Record<FailedTrialStatus, Exclude<SpoilerTypes, SpoilerTypes.Default>> = {
  highRisk: SpoilerTypes.Danger,
  lowRisk: SpoilerTypes.Warning,
};

export const BrazilTrialRecord = ({
  record,
}: { record: TrialRecord }) => {
  const classes = useStyles();
  const type = typeByStatus[record.status];

  return (
    <Box pb="14px" px="12px">
      <Paper>
        <Box p="12px">
          <Spoiler
            type={type}
            title={record.subject}
            extraIcon={<WarningBadge className={classes.warningIcon} isDefaultPosition={false} />}
            headerIcon={(
              <CrossIcon className={classnames(classes.crossIcon, {
                [classes.crossIconDanger]: type === SpoilerTypes.Danger,
                [classes.crossIconWarning]: type === SpoilerTypes.Warning,
              })}
              />
            )}
          >
            <BrazilRecordFailedContent className={classes.detailsContent} record={record} />
          </Spoiler>
        </Box>
      </Paper>
    </Box>
  );
};
