import classnames from 'classnames';
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useFormatMessage } from 'apps/intl';
import { InfoTooltip, notification } from 'apps/ui';
import { Modal, useOverlay } from 'apps/overlay';
import { downloadAsZip, downloadBlob } from 'lib/file';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { PdfIconSmall } from '../../assets';
import { IBankAccountDataVerificationStatement } from '../../models/BankAccountData.model';
import { useStyles } from './BankAccountStatements.styles';
import { PdfViewer } from '../PdfViewer/PdfViewer';

export function BankAccountStatements({ statements }: {
  statements: IBankAccountDataVerificationStatement[];
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();

  const openPdf = (selectedPdf) => {
    createOverlay(
      <Modal className={classnames(classes.statementModal, 'fs-exclude')} customCloseIcon={<div />}>
        <PdfViewer pdf={selectedPdf} pdfList={statements} closeOverlay={closeOverlay} />
      </Modal>,
    );
  };

  const downloadPdf = (pdfPromise, filename) => {
    pdfPromise.then((resolvedPdf) => downloadBlob(resolvedPdf, filename))
      .catch((error) => {
        notification.error(formatMessage('Error.common'));
        throw error;
      });
  };

  const downloadAll = () => {
    downloadAsZip(statements.map(({ url, originalFileName }) => ({ file: url, fileName: originalFileName })), formatMessage('BankAccountData.bankStatements.zipFileName'));
  };

  return (
    <Grid xs={12} item>
      <Box className={classes.boxContainer}>
        <Grid container justifyContent="space-between">
          <Typography variant="subtitle2" className={classes.boxTitle}>
            {formatMessage('BankAccountData.bankStatements.title')}
          </Typography>
          <Button onClick={downloadAll} variant="text" color="primary" className={classes.actions}>
            {formatMessage('BankAccountData.bankStatements.downloadAll')}
          </Button>
        </Grid>
        <Divider light className={classes.divider} />
        {statements.map((statement: IBankAccountDataVerificationStatement) => (
          <Box className={classes.statementItem} key={statement.fileId}>
            <Box className={classes.statementNameContainer}>
              <PdfIconSmall className={classes.pdfIcon} />
              <Typography variant="subtitle2" className={classes.statementName}>{statement.originalFileName}</Typography>
            </Box>
            {statement.isProtected !== true && (
              <Grid container justifyContent="flex-end" className={classes.actionsContainer}>
                <Button onClick={() => openPdf(statement)} variant="text" color="primary" className={classes.actions}>
                  {formatMessage('BankAccountData.bankStatements.preview')}
                </Button>
                <Button onClick={() => downloadPdf(statement.url, statement.originalFileName)} variant="text" color="primary" className={classes.actions}>
                  {formatMessage('BankAccountData.bankStatements.download')}
                </Button>
              </Grid>
            )}
            {statement.isProtected === true && (
              <Grid container alignItems="center" justifyContent="flex-end" wrap="nowrap" className={classes.actionsContainer}>
                <Typography variant="subtitle2" className={classes.previewUnavailableText}>
                  {formatMessage('BankAccountData.bankStatements.previewUnavailable')}
                </Typography>
                <InfoTooltip title={formatMessage('BankAccountData.bankStatements.previewComingSoon')} popperClassname={classes.tooltip}>
                  <AiOutlineInfoCircle className={classes.previewUnavailableIcon} size="1.2em" />
                </InfoTooltip>
              </Grid>
            )}
          </Box>
        ))}
      </Box>
    </Grid>
  );
}
