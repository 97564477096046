import React, { ReactNode, useCallback, useState, useMemo } from 'react';
import { useFormatMessage } from 'apps/intl';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Warning, WarningTypes } from 'apps/ui';
import { CheckBarExpandable } from 'apps/checks';
import ReactJsonViewer from 'react-json-view';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DocumentTypes } from 'models/Document.model';
import { selectVerificationDocumentsType } from 'apps/Verification';
import { creditCheckDisplayOptions, propsListToBeExcluded, IBadCheck, IActiveDebt, IExpiredDebt } from 'models/CreditCheck.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { creditCheckManualRun } from '../../state/CreditCheck.actions';
import { Speedometer } from '../Speedometer/Speedometer';
import { useStyles } from './CreditCheckVerificationProduct.styles';
import { useCreditCheck } from '../../hooks/creditCheck.hook';
import { creditMexicoCdcCursorRotation, creditColombiaTransunionCursorRotation } from '../../models/CreditCheck.model';
import { ChartSpeedometer, ChartSpeedometerLegend, labelRanges0To5000, labelRanges0To10000, colorRangesList0To100, colorRangesList0To5000, colorRangesList0To10000, labelRanges0To1000, colorRangesList0To1000 } from '../ChartSpeedometer';
import './CreditCheckVerificationProduct.module.scss';

function FieldSet({ title, data, pageSize = 20 }: {
  pageSize?: number;
  title: ReactNode;
  data: (IBadCheck | IActiveDebt | IExpiredDebt)[];
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const displayed = useMemo(() => data?.slice(0, (page + 1) * pageSize) ?? [], [pageSize, data, page]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.colorGrey}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(displayed.length > 0) ? displayed.map((item, index) => (
          <Grid container key={index} className={classes.separated}>
            {Object.entries(item).map(([key, value]) => (
              <Grid container item xs={12} sm={6} key={key}>
                <Box key={key} className={classes.summaryListItem}>
                  <Typography variant="body1" className={classnames(classes.summaryListItemValue, 'fs-exclude')}>
                    {value}
                  </Typography>
                  <Typography variant="body1" className={classes.colorGrey}>
                    {formatMessage(`CreditCheck.verification.report.${key}`, { defaultMessage: key })}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )) : (
          <Box mb={2}>
            <Typography className={classes.colorGrey}>
              {formatMessage('CreditCheck.verification.report.noData')}
            </Typography>
          </Box>
        )}
      </Grid>
      {(displayed.length < data?.length) && (
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Button
            variant="text"
            className={classes.colorGrey}
            onClick={() => setPage((value) => value + 1)}
          >
            {formatMessage('CreditCheck.verification.report.showMore')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export function CreditCheckVerificationProduct() {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const documentsType = useSelector<any, DocumentTypes[]>(selectVerificationDocumentsType)[0];
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const canUseLegacyBackgroundChecks = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseLegacyBackgroundChecks)), [merchantTags]);
  const {
    creditStep,
    id,
    isShowManualCreditCheckButton,
    isCheckInProgress,
    isPostResultPhase,
    isCreditMexicoCdc,
    isCreditBrazilianSerasa,
    isCreditColombiaTransunion,
    isCreditBrazilianSerasaChecks,
  } = useCreditCheck();
  const handleCreditCheckManualRun = useCallback(() => {
    dispatch(creditCheckManualRun(id, documentsType, creditStep.id));
  }, [dispatch, creditStep, documentsType, id]);

  if (isShowManualCreditCheckButton && !canUseLegacyBackgroundChecks) {
    return (
      <Box mt={2} className={classes.manualButtonWrap}>
        <Typography variant="h4" gutterBottom className={classes.colorGrey}>
          {formatMessage('CreditCheck.creditReport.title')}
        </Typography>
        <Box mb={2} className={classes.colorGrey}>
          {formatMessage('CreditCheck.creditReport.subTitle')}
        </Box>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreditCheckManualRun}
          size="large"
          className={classes.ultraLargeButton}
        >
          {formatMessage('CreditCheck.requestCreditReport')}
        </Button>
      </Box>
    );
  }

  if (isCheckInProgress) {
    const messageType = isPostResultPhase ? 'running' : 'waiting';
    return (
      <Box mt={2} className={classes.manualButtonWrap}>
        <Warning
          type={WarningTypes.Notify}
          label={formatMessage(`CreditCheck.warning.${messageType}`)}
          filled
          isLabelColored
        />
      </Box>
    );
  }

  if (creditStep.error || !creditStep.data) {
    const errorCode = creditStep?.error?.code;
    const errorMessage = creditStep?.error?.message;

    return (
      <Box mt={2}>
        <CheckBarExpandable
          title="CreditCheck.check.failed.title"
          isError
        >
          <Card raised={false} className={classes.card}>
            <CardContent>
              {formatMessage(`CreditCheck.check.${errorCode}.message`, {
                defaultMessage: errorMessage || formatMessage('SecurityCheckStep.system.internalError.message'),
              })}
            </CardContent>
          </Card>
        </CheckBarExpandable>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classnames(classes.colorGrey, classes.marginBottom10)}>
          {formatMessage('CreditCheck.verification.summary')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item className={classes.speedometerWrap}>
            { (isCreditBrazilianSerasa || isCreditBrazilianSerasaChecks) ? (
              <>
                <ChartSpeedometer className="fs-exclude" value={creditStep.data?.creditScore} data={labelRanges0To1000} colorRanges={colorRangesList0To1000} />
                <Typography align="center" variant="body1" className={classes.speedometerText}>
                  {formatMessage('CreditCheck.verification.creditBrazilianSerasa.score')}
                </Typography>
                <Typography align="center" variant="body1" className={classes.speedometerText}>
                  {formatMessage('CreditCheck.verification.higherIsBetter')}
                </Typography>
              </>
            ) : (
              <Speedometer
                className="fs-exclude"
                value={creditStep.data?.creditScore}
                range={(isCreditMexicoCdc && creditMexicoCdcCursorRotation) || (isCreditColombiaTransunion && creditColombiaTransunionCursorRotation)}
              />
            ) }
          </Grid>
          <Grid container item className={classes.summaryList}>
            {creditStep.data && Object.entries(creditStep.data)
              .map(([key, value]) => {
                if (creditCheckDisplayOptions[creditStep.id]?.[key]?.hidden || typeof value === 'object' || propsListToBeExcluded.includes(key)) {
                  return null;
                }

                return (
                  <Box key={key} className={classes.summaryListItem}>
                    <Typography variant="body1" className={classnames(classes.summaryListItemValue, 'fs-exclude')}>
                      {value}
                    </Typography>
                    <Typography variant="body1" className={classes.colorGrey}>
                      {formatMessage(`identity.field.${key}`)}
                    </Typography>
                  </Box>
                );
              })}
          </Grid>
        </Grid>
        {isCreditBrazilianSerasa && (
          <>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classnames(classes.colorGrey, classes.verticalMargin10)}>
                  {formatMessage('CreditCheck.verification.mainIndicators')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item className={classes.speedometerWrap}>
                    <ChartSpeedometer
                      className="fs-exclude"
                      value={creditStep.data?.incomeCommitment}
                      labelSufix="%"
                      isHigherBetter={false}
                    />
                    <Typography align="center" variant="body1" className={classes.speedometerText}>
                      {formatMessage('CreditCheck.verification.creditBrazilianSerasa.incomeCommitment')}
                    </Typography>
                    <Typography align="center" variant="body1" className={classes.speedometerText}>
                      {formatMessage('CreditCheck.verification.lowerIsBetter')}
                    </Typography>
                  </Grid>
                  <Grid container item className={classes.summaryList}>
                    <Grid container item className={classes.info}>
                      <ChartSpeedometerLegend value={creditStep.data?.incomeCommitment} colorRangesList={colorRangesList0To100} isHigherBetter={false}>
                        {(type, ranges) => (
                          <>
                            <Typography component="span" className={classes[type]}>
                              {formatMessage('CreditCheck.verification.creditBrazilianSerasa.incomeCommitment')}
                              {' - '}
                              {creditStep.data?.incomeCommitment
                                ? formatMessage('CreditCheck.verification.creditBrazilianSerasa.between', { messageValues: ranges })
                                : formatMessage('CreditCheck.verification.notAvailable')}
                            </Typography>
                            <Typography variant="body1">
                              {formatMessage('CreditCheck.verification.creditBrazilianSerasa.incomeCommitment.text', { messageValues: ranges })}
                            </Typography>
                          </>
                        )}
                      </ChartSpeedometerLegend>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                  <Grid item className={classes.speedometerWrap}>
                    <ChartSpeedometer
                      className="fs-exclude"
                      value={creditStep.data?.paymentCapacity}
                      colorRanges={colorRangesList0To5000}
                      data={labelRanges0To5000}
                      labelPrefix="R$ "
                      isAllowedAddition
                      isHigherBetter
                    />
                    <Typography align="center" variant="body1" className={classes.speedometerText}>
                      {formatMessage('CreditCheck.verification.creditBrazilianSerasa.paymentCapacity')}
                    </Typography>
                    <Typography align="center" variant="body1" className={classes.speedometerText}>
                      {formatMessage('CreditCheck.verification.higherIsBetter')}
                    </Typography>
                  </Grid>
                  <Grid container item className={classes.summaryList}>
                    <Grid container item className={classes.info}>
                      <ChartSpeedometerLegend value={creditStep.data?.paymentCapacity} colorRangesList={colorRangesList0To5000}>
                        {(type) => (
                          <>
                            <Typography component="span" className={classes[type]}>
                              {formatMessage('CreditCheck.verification.creditBrazilianSerasa.paymentCapacity')}
                              {' - '}
                              {creditStep.data?.paymentCapacity
                                ? formatMessage(`CreditCheck.verification.creditBrazilianSerasa.${type === 'error' ? 'lessThan' : 'greaterThan'}`, { messageValues: { value: type === 'error' ? 1500 : 5000 } })
                                : formatMessage('CreditCheck.verification.notAvailable')}
                            </Typography>
                            <Typography variant="body1">
                              {formatMessage('CreditCheck.verification.creditBrazilianSerasa.paymentCapacity.text', { messageValues: { value: 5000 } })}
                            </Typography>
                          </>
                        )}
                      </ChartSpeedometerLegend>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                  <Grid item className={classes.speedometerWrap}>
                    <ChartSpeedometer
                      className="fs-exclude"
                      value={creditStep.data?.estimatedIncome}
                      colorRanges={colorRangesList0To10000}
                      data={labelRanges0To10000}
                      labelPrefix="R$ "
                      isAllowedAddition
                    />
                    <Typography align="center" variant="body1" className={classes.speedometerText}>
                      {formatMessage('CreditCheck.verification.creditBrazilianSerasa.estimatedIncome')}
                    </Typography>
                    <Typography align="center" variant="body1" className={classes.speedometerText}>
                      {formatMessage('CreditCheck.verification.higherIsBetter')}
                    </Typography>
                  </Grid>
                  <Grid container item className={classes.summaryList}>
                    <Grid container item className={classes.info}>
                      <ChartSpeedometerLegend value={creditStep.data?.estimatedIncome} colorRangesList={colorRangesList0To10000}>
                        {(type) => (
                          <>
                            <Typography component="span" className={classes[type]}>
                              {formatMessage('CreditCheck.verification.creditBrazilianSerasa.estimatedIncome')}
                              {' - '}
                              {creditStep.data?.estimatedIncome
                                ? formatMessage(`CreditCheck.verification.creditBrazilianSerasa.${type === 'error' ? 'lessThan' : 'greaterThan'}`, { messageValues: { value: type === 'error' ? 1500 : 10000 } })
                                : formatMessage('CreditCheck.verification.notAvailable')}
                            </Typography>
                            <Typography variant="body1">
                              {formatMessage('CreditCheck.verification.creditBrazilianSerasa.estimatedIncome.text', { messageValues: { value: 10000 } })}
                            </Typography>
                          </>
                        )}
                      </ChartSpeedometerLegend>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.colorGrey}>
          {formatMessage('CreditCheck.verification.creditReport')}
        </Typography>
      </Grid>
      {(creditStep.data?.badChecks || creditStep.data?.activeDebts || creditStep.data?.expiredDebts) && (
        <Grid item xs={12}>
          <FieldSet
            title={(
              <Typography variant="subtitle2" className={classes.colorGrey}>
                {formatMessage('CreditCheck.verification.badChecks')}
              </Typography>
            )}
            data={creditStep.data?.badChecks}
          />
          <FieldSet
            title={(
              <Typography variant="subtitle2" className={classes.colorGrey}>
                {formatMessage('CreditCheck.verification.activeDebts')}
              </Typography>
            )}
            data={creditStep.data?.activeDebts}
          />
          <FieldSet
            title={(
              <Typography variant="subtitle2" className={classes.colorGrey}>
                {formatMessage('CreditCheck.verification.expiredDebts')}
              </Typography>
            )}
            data={creditStep.data?.expiredDebts}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container className={classes.marginBottom10} justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" className={classes.colorGrey}>
            {formatMessage('CreditCheck.verification.rawData')}
          </Typography>
          <Grid container item className={classes.downloadButtonsBox}>
            <Button
              className={classes.colorGrey}
              href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(creditStep.data))}`}
              download="credit_check.json"
            >
              {formatMessage('CreditCheck.verification.downloadJson')}
            </Button>
          </Grid>
        </Grid>
        <Box className="fs-exclude">
          <ReactJsonViewer
            src={creditStep.data}
            collapsed
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
