import { createTypesSequences } from 'state/store.utils';
import { Loadable } from 'models/Loadable.model';

export const VERIFICATION_HOOKS_STORE_KEY = 'verificationHooks';

export enum SliceNameTypes {
  Hooks = 'hooks',
}

export interface IVerificationHook {
  _id: string;
  completedAt: string;
  createdAt: string;
  eventName: string;
  stepId?: string;
  verificationId: string;
  response?: {
    httpMessage: string;
    httpStatus: number;
  };
  updatedAt: string;
  url: string;
}

export interface IVerificationHooksStore {
  [SliceNameTypes.Hooks]: Loadable<IVerificationHook[]>;
}

export const types = {
  ...createTypesSequences(SliceNameTypes),
};
