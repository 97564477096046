import classnames from 'classnames';
import React, { Ref } from 'react';
import { useStyles } from './Button.style';

export const Button = React.forwardRef(({ active, ...props }: {
  active: boolean;
  [key: string]: unknown;
}, ref: Ref<Nullable<HTMLSpanElement>>) => {
  const classes = useStyles();
  return (
    <span
      {...props}
      ref={ref}
      className={classnames(classes.root, { [classes.active]: active })}
    />
  );
});
