import { useIntl } from 'react-intl';
import { FieldBooleanPatterns, FieldMatchObjectPatterns, formatValue, includesPattern } from './string';

export function useFormattedValue(label: string, value: string | null | boolean, customFormatter: { pattern: string[]; formatter: (label: string, value: unknown) => unknown }[] = []) {
  const intl = useIntl();

  for (let i = 0; i < customFormatter.length; i += 1) {
    const { pattern, formatter } = customFormatter[i];
    if (pattern && formatter && includesPattern(label, pattern)) {
      return formatter(label, value);
    }
  }

  if (includesPattern(label, FieldMatchObjectPatterns)) {
    const number = !Number.isNaN(value) ? parseInt(value as string, 10) : '??';
    return `${number}% ${intl.formatMessage({ id: 'identity.field.match' })}`;
  }

  if (includesPattern(label, FieldBooleanPatterns)) {
    return intl.formatMessage({
      id: value ? `identity.field.${label}.positive` : `identity.field.${label}.negative`,
      // eslint-disable-next-line no-nested-ternary
      defaultMessage: intl.formatMessage({ id: value === true ? 'yes' : value === false ? 'no' : '-' }),
    });
  }

  return formatValue(label, value);
}
