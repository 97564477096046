import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFormatMessage } from 'apps/intl';
import { codedFlagMap } from 'assets/flags';
import { CountryCodeTypes } from 'models/Country.model';
import React, { ReactNode } from 'react';
import { useStyles } from './GovCheckCountryHeader.styles';

export function GovCheckCountryHeader({ country, children }: {
  country: CountryCodeTypes;
  children: ReactNode;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Grid container wrap="nowrap">
      <Grid container item alignItems="center" wrap="nowrap">
        <Box mr={1} className={classes.icon}>
          {codedFlagMap[country]}
        </Box>
        <Box fontWeight="bold" textAlign="left">
          {formatMessage(`Countries.${country}`)}
        </Box>
      </Grid>
      <Grid item>
        <Box>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}
