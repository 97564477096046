import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.common.palegray,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  promoWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    minWidth: 580,
    width: '40%',
    backgroundColor: theme.palette.common.roseWhite,
  },
  promoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    margin: '48px 44.5px',
  },
  logo: {
    alignSelf: 'start',
  },
  promoImage: {
    margin: '61px 23.5px 0',
  },
  greenDot: {
    width: 8.5,
    height: 8.5,
    marginRight: 8,
    backgroundColor: theme.palette.common.green,
    transform: 'rotate(45deg)',
  },
  banner: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px 16px',
    borderRadius: 1000,
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
  },
  promoTitle: {
    maxWidth: 491,
    textAlign: 'center',
    fontFamily: 'Anybody',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '33px',
  },
  signInContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
  },
  appBar: {
    position: 'fixed',
    top: 16,
    right: 24,
  },
  footer: {
    color: theme.palette.text.main,
    fontWeight: 'normal',
  },
  form: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    padding: 48,
    gap: 24,
  },
  backButton: {
    border: '1px solid black',
    borderRadius: '40px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.black90,
  },
  button: {
    width: '100%',
    height: 50,
    padding: '15px 22px',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '26px',
    backgroundColor: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
  },
  helperTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 2px 0px 2px',
    fontSize: '12px',
  },
  errorIcon: {
    marginRight: '8px',
    fontSize: '14px',
  },
  title: {
    fontFamily: 'Anybody',
    fontSize: '24px',
    lineHeight: '45px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '19.2px',
    color: theme.palette.common.black75,
  },
  email: {
    color: theme.palette.common.black90,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.common.lightblue,
    textDecoration: 'underline',
  },
}));
