import React from 'react';

export function LineChartDot({ cx, cy, color }) {
  return (
    <svg>
      <svg x={cx - 38} y={cy - 58} width="75" height="66" viewBox="0 0 75 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <rect x="15" y="11" width="45" height="30" rx="5" fill="#232939" />
          <path d="M38 46.5L32 40.5H44L38 46.5Z" fill="#232939" />
        </g>
      </svg>
      <svg x={cx - 8} y={cy - 8} width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle className="recharts-tooltip-cursor1" cx="8.5" cy="8.5" r="8.5" fill={color} />
        <circle cx="8.50039" cy="8.50015" r="5.1" fill="white" />
      </svg>
    </svg>
  );
}
