import React from 'react';
import { codedFlagMap } from 'assets/flags';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { ExtendedDescription, SalesToolTip } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { FlattenVerificationPatternConfigType, IFlattenVerificationPatternConfig, isCanUsePattern } from 'models/VerificationPatternsConfigs.model';
import { useStyles } from './FacematchCountrySettings.styles';

export function FacematchCountrySettings({ patternsConfig, country, onChange }: {
  patternsConfig: FlattenVerificationPatternConfigType[];
  country: string;
  onChange: (config: IFlattenVerificationPatternConfig, option: IFlattenVerificationPatternConfig) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);

  return (
    <Box className={classes.wrapper} p={1} pr={2} mt={1}>
      <Grid container alignItems="center" wrap="nowrap">
        <Box mr={1} className={classes.icon}>{codedFlagMap[country]}</Box>
        <Box color="common.black90" fontWeight="bold">
          {formatMessage(`Countries.${country}`)}
        </Box>
      </Grid>
      {patternsConfig.map((patternConfig) => {
        const config = Object.values(patternConfig)[0];
        const govCheck = config;
        const govCheckOption = config.options?.find((option) => option.isFacematchSource);

        const facematchSource = govCheck.isFacematchSource ? govCheck : govCheckOption;

        const isNeedRequest: boolean = !isCanUsePattern(facematchSource, merchantTags);

        return (
          <Box key={config.patternName} mt={1} className={classes.check}>
            <ExtendedDescription
              title={(
                <>
                  {formatMessage(`GovCheck.check.${facematchSource.patternName}`)}
                  {isNeedRequest && (<SalesToolTip />)}
                </>
              )}
              titleColor="common.black75"
              textFontSize={10}
              postfix={(
                <Switch
                  checked={facematchSource.isActive}
                  onChange={onChange(facematchSource, govCheck.isFacematchSource ? null : govCheckOption)}
                  color="primary"
                  disabled={isNeedRequest}
                />
              )}
            />
          </Box>
        );
      })}
    </Box>
  );
}
