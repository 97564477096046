import React, { ReactNode } from 'react';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';
import { useLagendStyles } from './ChartSpeedometer.styles';
import { icons } from './ChartSpeedometer.config';

export function ChartSpeedometerLegend({
  value,
  colorRangesList,
  children,
  isHigherBetter = true,
}: {
  value: number;
  colorRangesList: { value: number }[];
  children: (type: string, ranges: {min: number; max: number}) => ReactNode;
  isHigherBetter?: boolean;
}) {
  let sum: number = 0;
  const sumRanges = colorRangesList.map((elem) => {
    sum += elem.value;
    return sum;
  });
  let type = isHigherBetter ? 'success' : 'error';
  let ranges = { min: sumRanges[1], max: sumRanges[2] };

  if (typeof value === 'undefined') {
    type = 'error';
    ranges = { min: 0, max: sumRanges[0] };
  } else if (value <= sumRanges[0]) {
    type = isHigherBetter ? 'error' : 'success';
    ranges = { min: 0, max: sumRanges[0] };
  } else if (value <= sumRanges[1]) {
    type = 'worning';
    ranges = { min: sumRanges[0], max: sumRanges[1] };
  }

  const classes = useLagendStyles();
  return (
    <>
      <Box className={classnames(classes.roundIcon, type)}>
        <SvgIcon component={icons[type]} />
      </Box>
      {children(type, ranges)}
    </>
  );
}
