import { Box, Typography } from '@material-ui/core';
import { ReactComponent as IconSoundOff } from 'assets/icon-sound-off.svg';
import { ReactComponent as IconSoundOn } from 'assets/icon-sound-on.svg';
import { ReactComponent as IconPlay } from 'assets/icon-play-media.svg';
import { ReactComponent as Placeholder } from 'assets/placeholder.svg';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MediaStatusTypes, useLoadPrivateMedia, VideoContainer } from 'apps/media';
import { useStyles } from './MediaVideo.styles';
import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';

export function MediaVideo({ title, subtitle, video, withSoundButton = false, isNotLoaded = true }: {
  title: string;
  subtitle?: string;
  video?: string;
  withSoundButton?: boolean;
  isNotLoaded?: boolean;
}) {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [muted, setMuted] = useState(!withSoundButton);
  const [objectUrl, isLoading] = useLoadPrivateMedia(isNotLoaded ? video : null);

  const mediaSrc = useMemo(() => (isNotLoaded ? objectUrl : video), [isNotLoaded, objectUrl, video]);
  const resultMediaSrc = useMemo<string | MediaStatusTypes>(() => (isLoading ? MediaStatusTypes.MediaIsLoading : mediaSrc), [isLoading, mediaSrc]);
  const handlePlayEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const toggleSound = useCallback(() => {
    setMuted((prev) => !prev);
  }, []);

  useEffect(() => {
    const videoCurrent = videoRef.current;
    return () => {
      if (videoCurrent && video) {
        videoCurrent.removeEventListener('ended', handlePlayEnded);
      }
    };
  }, [handlePlayEnded, video]);

  const handleLoadedMetadata = useCallback(() => {
    if (video) {
      videoRef.current.pause();
      videoRef.current.addEventListener('ended', handlePlayEnded);
    }
  }, [handlePlayEnded, video]);

  const handlePlayerToggle = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  if (!video) {
    return <Placeholder />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.videoBox}>
        <Box onClick={handlePlayerToggle}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <VideoContainer
            ref={videoRef}
            className={classes.media}
            autoPlay
            muted={muted}
            onLoadedMetadata={handleLoadedMetadata}
            src={resultMediaSrc}
          />
          {isLoading && <SkeletonLoader className={classes.loader} animation="wave" variant="rect" width="100%" height="100%" />}
          {!isPlaying && <IconPlay className={classes.play} />}
        </Box>
        {withSoundButton && (
          muted ? (
            <IconSoundOff onClick={toggleSound} className={classes.sound} />
          ) : (
            <IconSoundOn onClick={toggleSound} className={classes.sound} />
          )
        )}
      </Box>
      {(title || subtitle) && (
        <Box className={classes.titleBox}>
          <Typography variant="body1" className={classes.title}>{title}</Typography>
          <Typography variant="body2" className={classes.subtitle}>{subtitle}</Typography>
        </Box>
      )}
    </Box>
  );
}
