import React from 'react';
import Box from '@material-ui/core/Box';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { ItemValue, LinkButton } from 'apps/ui';
import { FailedTrialStatus, TrialRecord } from 'models/BackgroundCheck.model';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './BrazilRecordFailedContent.styles';

const statusTextMap: Record<FailedTrialStatus, string> = {
  highRisk: 'BackgroundCheck.verification.summary.highRisk',
  lowRisk: 'BackgroundCheck.verification.summary.lowRisk',
};

export const BrazilRecordFailedContent = ({
  className,
  record,
}: {
  className: string;
  record: TrialRecord;
}) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const statusClassesMap: Record<FailedTrialStatus, string> = {
    highRisk: classes.highRisk,
    lowRisk: classes.lowRisk,
  };
  const trialFields: Record<string, { size?: GridSize; formatValue?: (field: string) => string; fieldClassName?: (field) => string }> = {
    type: { size: 12 },
    class: { size: 12 },
    status: {
      size: 2,
      formatValue: (status: string) => (statusTextMap[status] ? formatMessage(statusTextMap[status]) : status),
      fieldClassName: (status: string) => statusClassesMap[status],
    },
    processNumber: { size: 4 },
    lastMovementDate: { size: 4 },
    area: { size: 2 },
  };

  return (
    <Box className={className}>
      <Grid container>
        <Grid item container xs={12} lg={9} spacing={2}>
          {Object.keys(trialFields).map((fieldKey, idx) => (
            <Grid key={idx} item xs={12} md={6} xl={trialFields[fieldKey]?.size}>
              <ItemValue
                title={formatMessage(`BackgroundCheck.verification.jsonView.${fieldKey}`)}
                value={trialFields[fieldKey]?.formatValue ? trialFields[fieldKey]?.formatValue(record[fieldKey]) : record[fieldKey]}
                classNames={{
                  container: classes.item,
                  value: trialFields[fieldKey]?.fieldClassName?.(record[fieldKey]),
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid xs={12} lg={3} item className={classes.linkContainer}>
          {record.link && <Box><LinkButton className={classes.link} link={record.link} /></Box>}
        </Grid>
      </Grid>
    </Box>
  );
};
