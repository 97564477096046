import React from 'react';
import Button from '@material-ui/core/Button';
import { useMerchantPlan } from 'apps/merchant';
import { useFreemium } from 'apps/Freemiun';
import { FreshChatWidget } from 'apps/freshdesk';
import { useStyles } from './UpgradeFreemiumButton.styles';

export function UpgradeFreemiumButton({ text, intercomMessage, dataQa }: {
  text: string;
  intercomMessage: string;
  dataQa: string;
}) {
  const classes = useStyles();
  const { comparePlans } = useFreemium();
  const { isFreemium } = useMerchantPlan();

  const handleUpgradeButtonClick = () => {
    if (isFreemium) {
      comparePlans();
    } else {
      FreshChatWidget.createNewMessage(intercomMessage);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      onClick={handleUpgradeButtonClick}
      className={classes.button}
      data-qa={dataQa}
    >
      {text}
    </Button>
  );
}
