import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 80px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  wrapper: {
    position: 'relative',
  },
  buttonClose: {
    position: 'absolute',
    top: -20,
    right: -16,
    padding: 10,
    color: theme.palette.common.black75,
  },
}));
