import { ONLY_NUMBERS_REG_EXP } from 'lib/validations';
import cloneDeep from 'lodash/cloneDeep';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { IFlattenVerificationPatternConfig, isPatternActive, isSubPatternActive, IVerificationPatternConfig } from 'models/VerificationPatternsConfigs.model';
import isNil from 'lodash/isNil';
import { IFlow } from 'models/Flow.model';
import { GOVERNMENT_CHECKS_PRODUCT_TYPES, ProductTypes } from 'models/Product.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { GovCheckStepTypes } from 'models/Step.model';

export const FACEMATCH_DEFAULT_REJECT_THRESHOLD = 30;
export const FACEMATCH_DEFAULT_APPROVE_THRESHOLD = 70;
export const FACEMATCH_MAX_THRESHOLD = 100;

export const FACEMATCH_SOURCES_COUNT = 2;

export interface IFacematchStep {
  status?: string;
  facematchScore: number;
  sources: {
    type: FacematchSourceTypes;
    url?: string;
  }[];
}

export interface IFacematchCheckStepData {
  status?: string;
  facematchScore: number;
  sources: {
    type: FacematchSourceTypes;
    url?: string;
  }[];
}

export enum FacematchCheckTypes {
  FacematchCheck = 'facematchCheck',
}

export enum FacematchCheckSettingsTypes {
  FacematchEnabled = 'facematchEnabled',
  FacematchSettings = 'facematchSettings',
  Sources = 'sources',
  RejectThreshold = 'rejectThreshold',
  ApproveThreshold = 'approveThreshold',
  DocumentTypes = 'documentTypes',
  CountriesGovChecks = 'countriesGovChecks',
  PatternsConfig = 'patternsConfig'
}

export interface IFacematchSourceGovCheckOptions {
  govCheckIds: string[];
}

export interface IFacematchSourceDocumentOptions {
  verificationStepIndex: number;
}
export interface IFacematchSource {
  type: FacematchSourceTypes;
  options?: IFacematchSourceGovCheckOptions | IFacematchSourceDocumentOptions;
}

export interface IFacematchFlow {
  sources: Nullable<IFacematchSource>[];
  approveThreshold: number;
  rejectThreshold: number;
}

export const getThresholdValue = (value: string): number => {
  const result: string = value ? value.replace(ONLY_NUMBERS_REG_EXP, '') : '';
  const num = Number(result);
  return num > FACEMATCH_MAX_THRESHOLD ? FACEMATCH_MAX_THRESHOLD : num;
};

export function parseFacematchPatternConfig(flow: IFlow, patternName: string, patternConfigs: IVerificationPatternConfig[]): IFlattenVerificationPatternConfig {
  const clone = cloneDeep(patternConfigs);

  const mainConfig = clone.splice(0, 1)[0];
  const flattenPatternConfig: IFlattenVerificationPatternConfig = {
    ...mainConfig,
    patternName,
    isActive: isPatternActive(flow.verificationPatterns[patternName]),
  };

  if (clone.length) {
    flattenPatternConfig.options = clone.map((config) => ({
      ...config,
      patternName,
      isActive: isSubPatternActive(flow.verificationPatterns[patternName], config.patternValue),
    }));
  }

  return flattenPatternConfig;
}

export const hasGovernmentCheckProductInGraph = (productsInGraph: ProductTypes[]) => GOVERNMENT_CHECKS_PRODUCT_TYPES.some((productType) => productsInGraph.includes(productType));

function getMissingProductsInGraph(productsInGraph: ProductTypes[] = [], sources?: IFacematchSource[]): IFacematchSource[] {
  const isBiometricStepActive = productsInGraph.includes(ProductTypes.BiometricVerification);
  const isDocumentStepActive = productsInGraph.includes(ProductTypes.DocumentVerification);
  const isGovCheckStepActive = hasGovernmentCheckProductInGraph(productsInGraph);

  return sources.filter(({ type }) => {
    switch (type) {
      case FacematchSourceTypes.Document:
        return !isDocumentStepActive;
      case FacematchSourceTypes.Biometrics:
        return !isBiometricStepActive;
      case FacematchSourceTypes.GovermentCheck:
        return !isGovCheckStepActive;
      default:
        return false;
    }
  });
}

export function getFacematchSourcesError(flow: IFlow, productsInGraph: ProductTypes[]): Nullable<string> {
  const { facematchServiceConfig: { sources }, verificationPatterns } = flow;
  const missingProducts = getMissingProductsInGraph(productsInGraph, sources);

  if (!sources[0] || !sources[1]) { // nullish check
    return 'Facematch.issues.notEnoughSources.description';
  }

  if (missingProducts.length > 0) {
    return 'Facematch.issues.missingProducts.description';
  }

  const haveRequiredOptions = sources.every(({ type, options }) => {
    switch (type) {
      case FacematchSourceTypes.Document:
        return !isNil((options as IFacematchSourceDocumentOptions)?.verificationStepIndex);
      case FacematchSourceTypes.GovermentCheck: {
        const govCheckOptions = (options as IFacematchSourceGovCheckOptions)?.govCheckIds || [];
        const isEnabledGovchecks = govCheckOptions.every((id) => {
          if (id === VerificationPatternTypes.NigerianLegal) {
            return [
              VerificationPatternTypes.NigerianNin,
              VerificationPatternTypes.NigerianDl,
              VerificationPatternTypes.NigerianBvn,
              VerificationPatternTypes.NigerianVirtualNin,
            ].some((pattern) => verificationPatterns[pattern] === true);
          }
          return [
            true,
            GovCheckStepTypes.CpfFacematch,
            GovCheckStepTypes.KtpFacematch,
          ].includes(verificationPatterns[id]);
        });

        return govCheckOptions.length && isEnabledGovchecks;
      }
      default:
        return true;
    }
  });

  if (productsInGraph.includes(ProductTypes.DocumentVerification) && productsInGraph.includes(ProductTypes.BiometricVerification)) {
    const sourceTypes = sources?.map((source) => source.type);
    const isFacematchSettingRedundant = sourceTypes.includes(FacematchSourceTypes.Biometrics) && sourceTypes.includes(FacematchSourceTypes.Document);
    if (isFacematchSettingRedundant) {
      return 'Facematch.issues.redundantFacematchSetting.description';
    }
  }

  if (!haveRequiredOptions) {
    return 'Facematch.issues.notHaveRequiredOptions.description';
  }

  return null;
}

export const meritDependencyOrder = [FacematchSourceTypes.Biometrics, FacematchSourceTypes.Document, FacematchSourceTypes.GovermentCheck, FacematchSourceTypes.MerchantDatabase, FacematchSourceTypes.VideoAgreement];
export const requiredMeritsMapping = [
  [null, [ProductTypes.BiometricVerification, ProductTypes.DocumentVerification], [ProductTypes.BiometricVerification, ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], ProductTypes.BiometricVerification, [ProductTypes.BiometricVerification, ProductTypes.VideoAgreement]],
  [[ProductTypes.BiometricVerification, ProductTypes.DocumentVerification], null, [ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], ProductTypes.DocumentVerification, [ProductTypes.DocumentVerification, ProductTypes.VideoAgreement]],
  [[ProductTypes.BiometricVerification, ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], [ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], null, [ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], [ProductTypes.IdentityValidation, ProductTypes.VideoAgreement]],
  [ProductTypes.BiometricVerification, ProductTypes.DocumentVerification, [ProductTypes.IdentityValidation, ProductTypes.DocumentVerification], null, ProductTypes.VideoAgreement],
  [[ProductTypes.BiometricVerification, ProductTypes.VideoAgreement], [ProductTypes.DocumentVerification, ProductTypes.VideoAgreement], [ProductTypes.IdentityValidation, ProductTypes.DocumentVerification, ProductTypes.VideoAgreement], ProductTypes.VideoAgreement, null],
];

export const requiredMeritsMappingSingle = {
  [FacematchSourceTypes.Biometrics]: ProductTypes.BiometricVerification,
  [FacematchSourceTypes.Document]: ProductTypes.DocumentVerification,
  [FacematchSourceTypes.GovermentCheck]: [ProductTypes.IdentityValidation, ProductTypes.DocumentVerification],
};
