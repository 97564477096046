import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  failedLabel: {
    color: theme.palette.common.red,
  },
  unreachableLabel: {
    color: theme.palette.common.yellow,
  },
  gridCell: {
    width: '50%',
    alignItems: 'center',
  },
  statusCell: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
