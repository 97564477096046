export const Fonts = [
  'Roboto',
  'Open Sans',
  'Noto Sans JP',
  'Montserrat',
  'Lato',
  'Poppins',
  'Roboto Condensed',
  'Inter',
  'Roboto Mono',
  'Oswald',
  'Raleway',
  'Noto Sans',
  'Nunito Sans',
  'Ubuntu',
  'Roboto Slab',
  'Nunito',
  'Playfair Display',
  'Rubik',
  'Merriweather',
  'PT Sans',
  'Noto Sans KR',
  'Kanit',
  'Mukta',
  'Work Sans',
  'Lora',
  'Noto Sans TC',
  'Fira Sans',
  'Quicksand',
  'Barlow',
  'DM Sans',
  'Mulish',
  'Heebo',
  'Inconsolata',
  'PT Serif',
  'IBM Plex Sans',
  'Noto Serif',
  'Titillium Web',
  'Manrope',
  'Libre Franklin',
  'Karla',
  'Nanum Gothic',
  'Josefin Sans',
  'Hind Siliguri',
  'Noto Color Emoji',
  'Arimo',
  'Libre Baskerville',
  'Dosis',
  'PT Sans Narrow',
  'Bebas Neue',
  'Cabin',
  'Bitter',
  'Oxygen',
  'Cairo',
  'Dancing Script',
  'Abel',
  'Source Code Pro',
  'Anton',
  'Maven Pro',
  'Hind',
  'EB Garamond',
  'Barlow Condensed',
  'Assistant',
  'Noto Sans SC',
  'Jost',
  'Pacifico',
  'Prompt',
  'Space Grotesk',
  'Rajdhani',
  'Lobster',
  'Crimson Text',
  'Noto Serif JP',
  'Exo 2',
  'Teko',
  'Fjalla One',
  'Signika Negative',
  'Public Sans',
  'Varela Round',
  'Comfortaa',
  'Arvo',
  'Archivo',
  'IBM Plex Mono',
  'Overpass',
  'Caveat',
  'Slabo 27px',
  'Abril Fatface',
  'Asap',
  'Cormorant Garamond',
  'Outfit',
  'M PLUS Rounded 1c',
  'Shadows Into Light',
  'Noto Sans Arabic',
  'Tajawal',
  'Fira Sans Condensed',
  'Merriweather Sans',
  'Asap Condensed',
  'Hind Madurai',
  'Red Hat Display',
  'Play',
  'Indie Flower',
  'Catamaran',
  'Barlow Semi Condensed',
  'Zilla Slab',
  'Archivo Black',
  'Satisfy',
  'Nanum Myeongjo',
  'Chakra Petch',
  'Noto Sans HK',
  'Questrial',
  'Signika',
  'Yanone Kaffeesatz',
  'Almarai',
  'IBM Plex Sans Arabic',
  'Domine',
  'Frank Ruhl Libre',
  'Vollkorn',
  'IBM Plex Serif',
  'Sarabun',
  'Lilita One',
  'M PLUS 1p',
  'Acme',
  'Archivo Narrow',
  'Noto Kufi Arabic',
  'Alegreya',
  'Amatic SC',
  'Russo One',
  'Plus Jakarta Sans',
  'Permanent Marker',
  'Exo',
  'Saira Condensed',
  'Bree Serif',
  'Didact Gothic',
  'DM Serif Display',
  'Alegreya Sans',
  'ABeeZee',
  'Rowdies',
  'Cinzel',
  'Lexend',
  'Alfa Slab One',
  'Orbitron',
  'Sora',
  'Chivo',
  'Source Serif 4',
  'Courgette',
  'Righteous',
  'Urbanist',
  'Tinos',
  'Figtree',
  'Kalam',
  'Great Vibes',
  'Montserrat Alternates',
  'Lobster Two',
  'Martel',
  'Yantramanav',
  'Cantarell',
  'Amiri',
  'Zeyada',
  'Patua One',
  'Changa',
  'Noticia Text',
  'Neuton',
  'Cardo',
  'Cormorant',
  'Noto Serif TC',
  'PT Sans Caption',
  'Spectral',
  'Space Mono',
  'Philosopher',
  'Crete Round',
  'Source Sans 3',
  'Roboto Flex',
  'Ubuntu Condensed',
  'Prata',
  'Marcellus',
  'Encode Sans',
  'Passion One',
  'Francois One',
  'Gloria Hallelujah',
  'Kaushan Script',
  'Sawarabi Mincho',
  'Noto Serif KR',
  'Pathway Gothic One',
  'Sacramento',
  'Concert One',
  'Alata',
  'Noto Sans Display',
  'Alice',
  'Old Standard TT',
  'Bodoni Moda',
  'Arsenal',
  'Gruppo',
  'Khand',
  'El Messiri',
  'Crimson Pro',
  'Baloo 2',
  'Yellowtail',
  'Ubuntu Mono',
  'Architects Daughter',
  'Josefin Slab',
  'Gothic A1',
  'Secular One',
  'Sawarabi Gothic',
  'Noto Sans Thai',
  'Antic Slab',
  'Rokkitt',
  'Cookie',
  'Fira Sans Extra Condensed',
  'Press Start 2P',
  'Unna',
  'Quattrocento Sans',
  'Gelasio',
  'Staatliches',
  'Readex Pro',
  'Sen',
  'Paytone One',
  'Saira',
  'Lexend Deca',
  'Sanchez',
  'Handlee',
  'Yatra One',
  'Advent Pro',
  'Commissioner',
  'IBM Plex Sans Condensed',
  'Mate',
  'Mitr',
  'Quattrocento',
  'Alegreya Sans SC',
  'Luckiest Guy',
  'Poiret One',
  'Itim',
  'Tangerine',
  'Cuprum',
  'Special Elite',
  'Literata',
  'Tenor Sans',
  'Noto Naskh Arabic',
  'Encode Sans Condensed',
  'Aleo',
  'Playfair Display SC',
  'News Cycle',
  'Carter One',
  'Bangers',
  'Faustina',
  'Mate SC',
  'Mukta Malar',
  'Rubik Mono One',
  'Allura',
  'Yeseva One',
  'Vidaloka',
  'Kosugi Maru',
  'Titan One',
  'Patrick Hand',
  'Caladea',
  'Ultra',
  'Noto Sans Devanagari',
  'Viga',
  'Bungee',
  'Be Vietnam Pro',
  'Blinker',
  'Ropa Sans',
  'Libre Caslon Text',
  'DM Serif Text',
  'Taviraj',
  'Comic Neue',
  'PT Mono',
  'Unbounded',
  'Roboto Serif',
  'Inter Tight',
  'Red Hat Text',
  'Neucha',
  'Gudea',
  'Volkhov',
  'Eczar',
  'Nanum Gothic Coding',
  'Marck Script',
  'Hammersmith One',
  'Mr Dafoe',
  'Hind Vadodara',
  'Gochi Hand',
  'Antonio',
  'Ruda',
  'Parisienne',
  'Homemade Apple',
  'Abhaya Libre',
  'Macondo',
  'League Spartan',
  'Zen Kaku Gothic New',
  'Bai Jamjuree',
  'Merienda',
  'Shrikhand',
  'Amaranth',
  'Adamina',
  'Istok Web',
  'Cousine',
  'Unica One',
  'Playball',
  'Petrona',
  'Alex Brush',
  'Nanum Pen Script',
  'Rock Salt',
  'Monoton',
  'Voltaire',
  'Bad Script',
  'Mada',
  'Calistoga',
  'Noto Serif SC',
  'Jaldi',
  'Share Tech Mono',
  'Sriracha',
  'Saira Semi Condensed',
  'Noto Serif Bengali',
  'Courier Prime',
  'Epilogue',
  'Lalezar',
  'Monda',
  'Niramit',
  'Black Ops One',
  'Lusitana',
  'Castoro',
  'Electrolize',
  'Oleo Script',
  'Zen Maru Gothic',
  'Fira Mono',
  'Laila',
  'Anonymous Pro',
  'Cabin Condensed',
  'Jura',
  'Audiowide',
  'BenchNine',
  'Nothing You Could Do',
  'Kumbh Sans',
  'Pragati Narrow',
  'Creepster',
  'Martel Sans',
  'Julius Sans One',
  'Economica',
  'Londrina Solid',
  'Mandali',
  'Reem Kufi',
  'Albert Sans',
  'Shippori Mincho',
  'Actor',
  'Pridi',
  'Khula',
  'Fugaz One',
  'Baskervville',
  'Varela',
  'Noto Sans Tamil',
  'Sarala',
  'Damion',
  'Black Han Sans',
  'Gilda Display',
  'Sorts Mill Goudy',
  'BIZ UDPGothic',
  'Forum',
  'Antic',
  'Italianno',
  'Pangolin',
  'Alef',
  'Pontano Sans',
  'Athiti',
  'Squada One',
  'Koulen',
  'Noto Sans Malayalam',
  'Rufina',
  'Reenie Beanie',
  'Sansita',
  'Leckerli One',
  'Six Caps',
  'DM Mono',
  'Sintony',
  'Syne',
  'Fraunces',
  'Krub',
  'VT323',
  'Libre Barcode 39',
  'Pinyon Script',
  'Quantico',
  'Glegoo',
  'Palanquin',
  'Holtwood One SC',
  'Days One',
  'Aclonica',
  'Markazi Text',
  'Chewy',
  'Ramabhadra',
  'Cutive Mono',
  'Karma',
  'Overlock',
  'Basic',
  'STIX Two Text',
  'Lemonada',
  'Hind Guntur',
  'Saira Extra Condensed',
  'Berkshire Swash',
  'Arapey',
  'Syncopate',
  'Oxanium',
  'Newsreader',
  'Alatsi',
  'Fredericka the Great',
  'Armata',
  'Amita',
  'JetBrains Mono',
  'Playfair',
  'Yrsa',
  'Noto Sans Mono',
  'Rammetto One',
  'Julee',
  'PT Serif Caption',
  'Livvic',
  'Covered By Your Grace',
  'Cantata One',
  'Kreon',
  'Palanquin Dark',
  'Charm',
  'Nanum Brush Script',
  'Candal',
  'Cabin Sketch',
  'Short Stack',
  'Carrois Gothic',
  'Coda',
  'Aldrich',
  'K2D',
  'GFS Didot',
  'Fira Code',
  'Rancho',
  'Cinzel Decorative',
  'Mrs Saint Delafield',
  'Trirong',
  'Scada',
  'Michroma',
  'Caveat Brush',
  'La Belle Aurore',
  'Bellefair',
  'Mali',
  'Averia Serif Libre',
  'Jua',
  'Capriola',
  'Noto Sans Hebrew',
  'Shadows Into Light Two',
  'Allerta Stencil',
  'Big Shoulders Display',
  'Average Sans',
  'Changa One',
  'Oranienbaum',
  'Racing Sans One',
  'Noto Nastaliq Urdu',
  'BioRhyme',
  'Quintessential',
  'Bevan',
  'Boogaloo',
  'Herr Von Muellerhoff',
  'Kiwi Maru',
  'Monsieur La Doulaise',
  'Libre Bodoni',
  'Cormorant Infant',
  'Arizonia',
  'Lustria',
  'Pattaya',
  'Atkinson Hyperlegible',
  'Bowlby One SC',
  'Just Another Hand',
  'Krona One',
  'Corben',
  'Arbutus Slab',
  'Graduate',
  'Rozha One',
  'Allerta',
  'Norican',
  'Noto Serif Display',
  'Telex',
  'Niconne',
  'Alexandria',
  'Belleza',
  'Cambay',
  'Marcellus SC',
  'Annie Use Your Telescope',
  'Noto Serif Devanagari',
  'IBM Plex Sans Thai',
  'Cedarville Cursive',
  'Knewave',
  'Enriqueta',
  'Rubik Moonrocks',
  'Bubblegum Sans',
  'Do Hyeon',
  'Yesteryear',
  'Hanuman',
  'Coming Soon',
  'Alike',
  'Overpass Mono',
  'Shippori Mincho B1',
  'Maitree',
  'Seaweed Script',
  'Amiko',
  'Grandstander',
  'Darker Grotesque',
  'Pathway Extreme',
  'Wallpoet',
  'Kristi',
  'Biryani',
  'Headland One',
  'Vollkorn SC',
  'Rye',
  'Alegreya SC',
  'Coustard',
  'Hanken Grotesk',
  'Chonburi',
  'Spinnaker',
  'Marvel',
  'Ovo',
  'Contrail One',
  'Rambla',
  'Nobile',
  'Sofia Sans',
  'Averia Libre',
  'M PLUS 1',
  'Grand Hotel',
  'Marmelad',
  'Kosugi',
  'Manjari',
  'Kurale',
  'Golos Text',
  'Klee One',
  'Judson',
  'Proza Libre',
  'Pirata One',
  'Petit Formal Script',
  'Caudex',
  'Suez One',
  'Kameron',
  'Smokum',
  'Amethysta',
  'Rochester',
  'Arya',
  'Bungee Inline',
  'Average',
  'Podkova',
  'Delius',
  'Quando',
  'Schoolbell',
  'Fredoka',
  'Halant',
  'Miriam Libre',
  'Irish Grover',
  'Balsamiq Sans',
  'Limelight',
  'Rasa',
  'Alumni Sans',
  'Magra',
  'B612',
  'Aladin',
  'Sofia',
  'Henny Penny',
  'Hepta Slab',
  'Stardos Stencil',
  'Cormorant Upright',
  'NTR',
  'Thasadith',
  'Lateef',
  'Nixie One',
  'Encode Sans Semi Condensed',
  'Padauk',
  'Brygada 1918',
  'ZCOOL QingKe HuangYou',
  'Trocchi',
  'Calligraffitti',
  'Mallanna',
  'Dawning of a New Day',
  'Sniglet',
  'Baloo Da 2',
  'League Gothic',
  'Big Shoulders Text',
  'Fauna One',
  'Vazirmatn',
  'Rakkas',
  'Georama',
  'Love Ya Like A Sister',
  'Metrophobic',
  'B612 Mono',
  'Spectral SC',
  'Italiana',
  'Qwigley',
  'Trykker',
  'Jockey One',
  'Grenze Gotisch',
  'Zen Old Mincho',
  'Sunflower',
  'Goudy Bookletter 1911',
  'Gurajada',
  'Lemon',
  'Baloo Paaji 2',
  'Fahkwang',
  'IM Fell English SC',
  'Oxygen Mono',
  'Odibee Sans',
  'Rosario',
  'Aref Ruqaa',
  'Gabriela',
  'Share',
  'Sansita Swashed',
  'Kaisei Decol',
  'Style Script',
  'David Libre',
  'Sofia Sans Condensed',
  'Turret Road',
  'Mansalva',
  'Noto Sans Bengali',
  'Slabo 13px',
  'Sigmar One',
  'IM Fell DW Pica',
  'IM Fell English',
  'Molengo',
  'Brawler',
  'Dela Gothic One',
  'IBM Plex Sans KR',
  'Waiting for the Sunrise',
  'Homenaje',
  'Bentham',
  'Cormorant SC',
  'Gravitas One',
  'Copse',
  'Hahmlet',
  'Montserrat Subrayada',
  'Baloo Thambi 2',
  'Megrim',
  'Tillana',
  'Zen Kaku Gothic Antique',
  'Oleo Script Swash Caps',
  'Allison',
  'Cutive',
  'Bowlby One',
  'Fjord One',
  'Gugi',
  'UnifrakturMaguntia',
  'Baloo Tamma 2',
  'Patrick Hand SC',
  'Anek Malayalam',
  'Zen Antique',
  'KoHo',
  'ZCOOL XiaoWei',
  'Della Respira',
  'Oooh Baby',
  'Kelly Slab',
  'Germania One',
  'Zen Kurenaido',
  'Lexend Zetta',
  'Fondamento',
  'Suranna',
  'Almendra',
  'Inder',
  'Mochiy Pop One',
  'Glory',
  'Chelsea Market',
  'Andika',
  'Vesper Libre',
  'Mr De Haviland',
  'Kodchasan',
  'Bungee Shade',
  'Buenard',
  'Mukta Vaani',
  'Kadwa',
  'Montez',
  'Syne Mono',
  'Tenali Ramakrishna',
  'Notable',
  'Sedgwick Ave',
  'Fanwood Text',
  'Galada',
  'Mirza',
  'Tulpen One',
  'Radley',
  'Goldman',
  'Antic Didone',
  'Flamenco',
  'Kite One',
  'Silkscreen',
  'Ms Madi',
  'Tilt Prism',
  'RocknRoll One',
  'Cambo',
  'Tomorrow',
  'Ma Shan Zheng',
  'Rouge Script',
  'Noto Sans Kannada',
  'BhuTuka Expanded One',
  'Modak',
  'Harmattan',
  'Stint Ultra Condensed',
  'Farro',
  'Bellota Text',
  'Original Surfer',
  'Mouse Memoirs',
  'Oregano',
  'Akshar',
  'Sulphur Point',
  'Carme',
  'Jomhuria',
  'Chivo Mono',
  'Alike Angular',
  'Noto Sans Sinhala',
  'DotGothic16',
  'Loved by the King',
  'Dokdo',
  'Give You Glory',
  'Meddon',
  'Aboreto',
  'Federo',
  'Supermercado One',
  'Battambang',
  'Raleway Dots',
  'Duru Sans',
  'Sue Ellen Francisco',
  'Skranji',
  'Poly',
  'Pompiere',
  'Noto Sans Telugu',
  'Encode Sans Expanded',
  'McLaren',
  'Nova Mono',
  'Ceviche One',
  'Clicker Script',
  'Nova Round',
  'Expletus Sans',
  'Yusei Magic',
  'Numans',
  'Rampart One',
  'Amarante',
  'Bakbak One',
  'Happy Monkey',
  'Lekton',
  'Elsie',
  'Baloo Chettan 2',
  'Shojumaru',
  'Allan',
  'Over the Rainbow',
  'Aguafina Script',
  'Anaheim',
  'Goblin One',
  'Azeret Mono',
  'Schibsted Grotesk',
  'Codystar',
  'Bayon',
  'Anuphan',
  'Meera Inimai',
  'Euphoria Script',
  'Tienne',
  'Kufam',
  'Mukta Mahee',
  'Sail',
  'Freehand',
  'Besley',
  'Esteban',
  'Noto Sans Chorasmian',
  'Ledger',
  'Montaga',
  'Mako',
  'Prosto One',
  'Libre Barcode 39 Extended Text',
  'Averia Sans Libre',
  'Andada Pro',
  'Shantell Sans',
  'Chau Philomene One',
  'Doppio One',
  'Inknut Antiqua',
  'Cormorant Unicase',
  'Scope One',
  'Metamorphous',
  'Emilys Candy',
  'Ibarra Real Nova',
  'Sofia Sans Semi Condensed',
  'Walter Turncoat',
  'Convergence',
  'Vast Shadow',
  'Barriecito',
  'Finger Paint',
  'Geo',
  'Asul',
  'Gaegu',
  'Noto Serif Malayalam',
  'Bricolage Grotesque',
  'Wendy One',
  'Freckle Face',
  'Eater',
  'Baumans',
  'Reggae One',
  'BIZ UDPMincho',
  'Saira Stencil One',
  'Nerko One',
  'Fasthand',
  'Frijole',
  'Gloock',
  'M PLUS 2',
  'Solway',
  'Coiny',
  'Ruwudu',
  'Fresca',
  'Port Lligat Slab',
  'Orienta',
  'Mogra',
  'Hi Melody',
  'Atma',
  'Charmonman',
  'Timmana',
  'Baloo Bhaina 2',
  'Dynalight',
  'Chango',
  'Bilbo Swash Caps',
  'Pavanam',
  'Noto Sans Georgian',
  'Ruslan Display',
  'Share Tech',
  'Delius Unicase',
  'Scheherazade New',
  'Rubik Dirt',
  'Libre Caslon Display',
  'Londrina Shadow',
  'Libre Barcode 39 Text',
  'Gemunu Libre',
  'Inria Serif',
  'Inspiration',
  'Mountains of Christmas',
  'Sarpanch',
  'Imprima',
  'Sumana',
  'Spline Sans',
  'Prociono',
  'Libre Barcode 128',
  'Sonsie One',
  'Salsa',
  'Sono',
  'Xanh Mono',
  'Faster One',
  'Life Savers',
  'Gayathri',
  'Birthstone',
  'Anek Tamil',
  'Balthazar',
  'Katibeh',
  'Nokora',
  'ZCOOL KuaiLe',
  'Gluten',
  'Ephesis',
  'Shalimar',
  'Recursive',
  'Poller One',
  'League Script',
  'Cherry Cream Soda',
  'Lexend Exa',
  'Varta',
  'Major Mono Display',
  'Gamja Flower',
  'Vibur',
  'Noto Sans Myanmar',
  'Comforter Brush',
  'Bellota',
  'Red Rose',
  'Delius Swash Caps',
  'Encode Sans Semi Expanded',
  'Medula One',
  'Hurricane',
  'Anek Telugu',
  'Belgrano',
  'Bigshot One',
  'Orelega One',
  'Ranchers',
  'Cherry Swash',
  'Crafty Girls',
  'Slackey',
  'Lexend Peta',
  'Lexend Giga',
  'MuseoModerno',
  'Kaisei Tokumin',
  'Inria Sans',
  'Kranky',
  'Just Me Again Down Here',
  'Londrina Outline',
  'Port Lligat Sans',
  'Mina',
  'Potta One',
  'Puritan',
  'Wix Madefor Display',
  'Dongle',
  'Noto Serif Hebrew',
  'Lovers Quarrel',
  'Peralta',
  'Sura',
  'Piazzolla',
  'Stick',
  'IM Fell French Canon',
  'Spicy Rice',
  'Strait',
  'Noto Sans Armenian',
  'Familjen Grotesk',
  'Voces',
  'Artifika',
  'Gantari',
  'Lily Script One',
  'Baloo Bhai 2',
  'Song Myung',
  'Ranga',
  'Baloo Bhaijaan 2',
  'Asar',
  'Kavoon',
  'Trade Winds',
  'Ribeye',
  'Kaisei Opti',
  'Radio Canada',
  'Macondo Swash Caps',
  'The Girl Next Door',
  'Hachi Maru Pop',
  'Edu SA Beginner',
  'Ramaraja',
  'Red Hat Mono',
  'Uncial Antiqua',
  'Galdeano',
  'Corinthia',
  'Overlock SC',
  'Tauri',
  'Odor Mean Chey',
  'Geologica',
  'Iceland',
  'Piedra',
  'Manuale',
  'UnifrakturCook',
  'IBM Plex Sans Thai Looped',
  'Gafata',
  'Akaya Telivigala',
  'Abyssinica SIL',
  'Stylish',
  'Carrois Gothic SC',
  'Qwitcher Grypen',
  'Monofett',
  'Tiro Devanagari Hindi',
  'IM Fell Double Pica',
  'Square Peg',
  'Gotu',
  'Noto Serif Thai',
  'Wire One',
  'Akronim',
  'Gowun Dodum',
  'Bubbler One',
  'Cute Font',
  'Sahitya',
  'Miniver',
  'Denk One',
  'Fragment Mono',
  'Suwannaphum',
  'Sree Krushnadevaraya',
  'Rum Raisin',
  'Kotta One',
  'Farsan',
  'Caprasimo',
  'Rationale',
  'Unkempt',
  'Flow Circular',
  'Gowun Batang',
  'East Sea Dokdo',
  'Habibi',
  'Hina Mincho',
  'Unlock',
  'Shanti',
  'Kulim Park',
  'Yaldevi',
  'Lexend Mega',
  'Ruthie',
  'Tilt Warp',
  'Road Rage',
  'Nova Square',
  'Autour One',
  'Rosarivo',
  'Donegal One',
  'Mystery Quest',
  'Paprika',
  'Murecho',
  'IBM Plex Sans Devanagari',
  'Baloo Tammudu 2',
  'Sofia Sans Extra Condensed',
  'Moul',
  'Khmer',
  'Sancreek',
  'Cantora One',
  'Noto Sans Lao',
  'Fontdiner Swanky',
  'Yuji Syuku',
  'Stoke',
  'Alkalami',
  'Barrio',
  'WindSong',
  'Kaisei HarunoUmi',
  'Zhi Mang Xing',
  'Grape Nuts',
  'Chicle',
  'Nova Flat',
  'Zen Dots',
  'Beth Ellen',
  'IBM Plex Sans JP',
  'Offside',
  'Noto Serif HK',
  'Meow Script',
  'Karantina',
  'Dr Sugiyama',
  'Noto Sans Math',
  'Jomolhari',
  'Dorsa',
  'Dekko',
  'Black And White Picture',
  'Moon Dance',
  'Stalemate',
  'Redressed',
  'Engagement',
  'Beau Rivage',
  'Princess Sofia',
  'Vujahday Script',
  'Smooch Sans',
  'Zilla Slab Highlight',
  'BIZ UDGothic',
  'Iceberg',
  'Arima',
  'Noto Sans Anatolian Hieroglyphs',
  'Train One',
  'Crushed',
  'Molle',
  'Fenix',
  'Comforter',
  'Trispace',
  'MonteCarlo',
  'IM Fell Great Primer',
  'Wellfleet',
  'Long Cang',
  'Sarina',
  'Jolly Lodger',
  'Poor Story',
  'Shippori Antique',
  'Meie Script',
  'Benne',
  'Stint Ultra Expanded',
  'Stalinist One',
  'Bigelow Rules',
  'Noto Emoji',
  'Vampiro One',
  'New Rocker',
  'Bona Nova',
  'Montagu Slab',
  'Spline Sans Mono',
  'Angkor',
  'Milonga',
  'Yomogi',
  'Gorditas',
  'MedievalSharp',
  'Akaya Kanadaka',
  'Libre Barcode 128 Text',
  'Anek Devanagari',
  'Bruno Ace SC',
  'Margarine',
  'Rhodium Libre',
  'Alumni Sans Inline One',
  'Bilbo',
  'Instrument Serif',
  'Tiro Kannada',
  'Mochiy Pop P One',
  'Mohave',
  'The Nautigal',
  'Noto Serif Georgian',
  'Cagliostro',
  'Dangrek',
  'Fuzzy Bubbles',
  'Eagle Lake',
  'Underdog',
  'Kavivanar',
  'Viaoda Libre',
  'Gulzar',
  'Borel',
  'Liu Jian Mao Cao',
  'Chathura',
  'Content',
  'Noto Sans Khmer',
  'Kantumruy Pro',
  'Anek Bangla',
  'Srisakdi',
  'Tiro Bangla',
  'Diplomata',
  'Ravi Prakash',
  'Waterfall',
  'Passions Conflict',
  'Felipa',
  'Shippori Antique B1',
  'Buda',
  'Swanky and Moo Moo',
  'Instrument Sans',
  'Anek Latin',
  'Linden Hill',
  'Chela One',
  'Anybody',
  'Simonetta',
  'Licorice',
  'Noto Serif Lao',
  'Modern Antiqua',
  'Caesar Dressing',
  'Nosifer',
  'Spirax',
  'Fascinate',
  'Condiment',
  'Joti One',
  'Metal Mania',
  'Ruluko',
  'Carattere',
  'DynaPuff',
  'Text Me One',
  'Kirang Haerang',
  'REM',
  'Mrs Sheppards',
  'Girassol',
  'Griffy',
  'Romanesco',
  'Encode Sans SC',
  'Junge',
  'Chilanka',
  'Handjet',
  'Single Day',
  'Atomic Age',
  'Preahvihear',
  'Elsie Swash Caps',
  'Risque',
  'Englebert',
  'Jacques Francois Shadow',
  'Inika',
  'Zen Tokyo Zoo',
  'Tektur',
  'Birthstone Bounce',
  'Averia Gruesa Libre',
  'Arbutus',
  'Plaster',
  'Cherry Bomb One',
  'Lancelot',
  'Aoboshi One',
  'Snippet',
  'Stick No Bills',
  'Kumar One',
  'Keania One',
  'Maiden Orange',
  'Braah One',
  'Akatab',
  'Sunshiney',
  'Delicious Handrawn',
  'Joan',
  'Marko One',
  'Rubik Wet Paint',
  'Charis SIL',
  'M PLUS 1 Code',
  'Croissant One',
  'Qahiri',
  'Jacques Francois',
  'Fascinate Inline',
  'Noto Sans Oriya',
  'Hanalei Fill',
  'Alkatra',
  'Noto Sans Ethiopic',
  'IBM Plex Sans Hebrew',
  'Noto Sans Gujarati',
  'Labrada',
  'Smooch',
  'Redacted',
  'Siemreap',
  'Glass Antiqua',
  'Yeon Sung',
  'Sirin Stencil',
  'Noto Serif Vithkuqi',
  'Nuosu SIL',
  'IM Fell French Canon SC',
  'Tiro Gurmukhi',
  'Peddana',
  'Bahiana',
  'BioRhyme Expanded',
  'Ewert',
  'Noto Serif Armenian',
  'Passero One',
  'Seymour One',
  'Jim Nightshade',
  'IM Fell DW Pica SC',
  'Lakki Reddy',
  'Emblema One',
  'Ribeye Marrow',
  'BIZ UDMincho',
  'Belanosima',
  'Grenze',
  'Water Brush',
  'Genos',
  'New Tegomin',
  'Festive',
  'Imbue',
  'Zen Antique Soft',
  'Victor Mono',
  'Diplomata SC',
  'Bungee Hairline',
  'Gidugu',
  'Oldenburg',
  'Devonshire',
  'Metal',
  'Sedgwick Ave Display',
  'Almendra SC',
  'Kumar One Outline',
  'Carlito',
  'Bokor',
  'Whisper',
  'Revalia',
  'Combo',
  'Fuggles',
  'Lisu Bosa',
  'Noto Serif Sinhala',
  'Purple Purse',
  'Noto Sans Gothic',
  'Trochut',
  'Nova Slim',
  'Sassy Frass',
  'IM Fell Great Primer SC',
  'Smythe',
  'Bungee Outline',
  'Wix Madefor Text',
  'Flavors',
  'Noto Serif Kannada',
  'Noto Sans Multani',
  'Gwendolyn',
  'Tiro Devanagari Marathi',
  'Anek Gujarati',
  'Tai Heritage Pro',
  'Texturina',
  'Mynerve',
  'Luxurious Script',
  'Galindo',
  'Butterfly Kids',
  'Amiri Quran',
  'Kenia',
  'Bahianita',
  'Praise',
  'Foldit',
  'Asset',
  'Caramel',
  'Libre Barcode 39 Extended',
  'Lavishly Yours',
  'Gentium Plus',
  'Ysabeau Infant',
  'Lumanosimo',
  'Solitreo',
  'Erica One',
  'Langar',
  'Almendra Display',
  'Miss Fajardose',
  'Neonderthaw',
  'Big Shoulders Stencil Display',
  'Noto Sans Samaritan',
  'Big Shoulders Stencil Text',
  'Astloch',
  'Noto Serif Khojki',
  'Vina Sans',
  'Geostar Fill',
  'Noto Sans Adlam Unjoined',
  'Nabla',
  'Lacquer',
  'Gupter',
  'Bonheur Royale',
  'Dai Banna SIL',
  'Ysabeau',
  'Rubik Beastly',
  'Kdam Thmor Pro',
  'Agdasima',
  'Noto Sans Thai Looped',
  'Aubrey',
  'Butcherman',
  'Mr Bedfort',
  'Federant',
  'Babylonica',
  'Poltawski Nowy',
  'Ballet',
  'Bruno Ace',
  'Monomaniac One',
  'Martian Mono',
  'Mea Culpa',
  'Ruge Boogie',
  'Rubik Glitch',
  'Londrina Sketch',
  'Nova Oval',
  'GFS Neohellenic',
  'Taprom',
  'Noto Serif Khmer',
  'Ysabeau Office',
  'Tiro Devanagari Sanskrit',
  'Rubik Distressed',
  'Sofadi One',
  'Lexend Tera',
  'Noto Serif Myanmar',
  'Edu QLD Beginner',
  'Gentium Book Plus',
  'Truculenta',
  'Bungee Spice',
  'Rubik Puddles',
  'Chenla',
  'Noto Serif Gujarati',
  'Tilt Neon',
  'Imperial Script',
  'IM Fell Double Pica SC',
  'Ysabeau SC',
  'Fruktur',
  'Cairo Play',
  'Lugrasimo',
  'Koh Santepheap',
  'Nova Cut',
  'Tiro Tamil',
  'Yuji Boku',
  'Dhurjati',
  'Marhey',
  'Noto Sans Meetei Mayek',
  'Vibes',
  'Hubballi',
  'Comme',
  'Tourney',
  'Darumadrop One',
  'Lunasima',
  'Anek Gurmukhi',
  'Nova Script',
  'Suravaram',
  'Snowburst One',
  'Alumni Sans Collegiate One',
  'Climate Crisis',
  'Bonbon',
  'Gideon Roman',
  'Geostar',
  'Phudu',
  'Noto Serif Tangut',
  'Big Shoulders Inline Text',
  'Moulpali',
  'Rubik Vinyl',
  'Miltonian Tattoo',
  'Finlandica',
  'Rubik Bubbles',
  'Kablammo',
  'Reem Kufi Ink',
  'Anek Kannada',
  'Bacasime Antique',
  'Miltonian',
  'Uchen',
  'Updock',
  'Blaka Hollow',
  'Sevillana',
  'Big Shoulders Inline Display',
  'Anek Odia',
  'Island Moments',
  'Fleur De Leah',
  'Noto Sans Syriac Eastern',
  'Noto Sans Javanese',
  'Edu NSW ACT Foundation',
  'Aref Ruqaa Ink',
  'Noto Sans Vithkuqi',
  'Luxurious Roman',
  'Oi',
  'Noto Sans Cypro Minoan',
  'Castoro Titling',
  'Diphylleia',
  'Redacted Script',
  'Blaka',
  'My Soul',
  'Alumni Sans Pinstripe',
  'Noto Sans Mro',
  'Konkhmer Sleokchher',
  'Sigmar',
  'Rubik Pixels',
  'Rubik Spray Paint',
  'Splash',
  'Edu TAS Beginner',
  'Kolker Brush',
  'Edu VIC WA NT Beginner',
  'Zen Loop',
  'Noto Serif Ottoman Siyaq',
  'Petemoss',
  'Send Flowers',
  'Libre Barcode EAN13 Text',
  'Noto Sans Gurmukhi',
  'Noto Sans Adlam',
  'Grechen Fuemen',
  'Narnoor',
  'Syne Tactile',
  'Palette Mosaic',
  'Bagel Fat One',
  'Noto Serif Khitan Small Script',
  'Rubik 80s Fade',
  'Tsukimi Rounded',
  'Rubik Iso',
  'Tiro Telugu',
  'Noto Serif Ethiopic',
  'Reem Kufi Fun',
  'Noto Serif Makasar',
  'Yuji Mai',
  'Noto Serif Telugu',
  'Love Light',
  'Flow Block',
  'Noto Serif Tibetan',
  'Tapestry',
  'Orbit',
  'Noto Sans Coptic',
  'Gajraj One',
  'Hanalei',
  'Estonia',
  'Rubik Microbe',
  'Noto Sans Indic Siyaq Numbers',
  'Noto Rashi Hebrew',
  'Grey Qo',
  'Noto Serif Tamil',
  'Moirai One',
  'Warnes',
  'Rock 3D',
  'Chokokutai',
  'Noto Sans Syloti Nagri',
  'Slackside One',
  'Are You Serious',
  'Rubik Storm',
  'Explora',
  'Noto Sans Cypriot',
  'Noto Sans Mongolian',
  'Gasoek One',
  'Noto Sans Nag Mundari',
  'Noto Sans Lepcha',
  'Noto Sans Sora Sompeng',
  'Noto Serif Ahom',
  'Twinkle Star',
  'Noto Sans Carian',
  'Noto Sans Canadian Aboriginal',
  'Noto Music',
  'Grandiflora One',
  'Noto Sans Old Italic',
  'Noto Sans Lao Looped',
  'Noto Sans Old Hungarian',
  'Shizuru',
  'Ole',
  'Kings',
  'Cherish',
  'Noto Sans Tangsa',
  'M PLUS Code Latin',
  'Moo Lah Lah',
  'Rubik Gemstones',
  'Puppies Play',
  'Noto Sans Miao',
  'Rubik Marker Hatch',
  'Noto Sans Cherokee',
  'Noto Sans Deseret',
  'Noto Sans Sundanese',
  'Noto Sans Tagalog',
  'Rubik Burned',
  'Mingzat',
  'Noto Sans Tai Viet',
  'Noto Sans New Tai Lue',
  'Flow Rounded',
  'Noto Sans Nandinagari',
  'Noto Sans Imperial Aramaic',
  'Noto Sans Egyptian Hieroglyphs',
  'Noto Serif Toto',
  'Noto Sans Thaana',
  'Noto Traditional Nushu',
  'Yuji Hentaigana Akari',
  'Ingrid Darling',
  'Noto Sans Yi',
  'Noto Sans Cham',
  'Noto Sans Avestan',
  'Noto Sans Old Persian',
  'Noto Sans Tifinagh',
  'Rubik Maze',
  'Noto Sans Kayah Li',
  'Noto Sans Tai Tham',
  'Blaka Ink',
  'Noto Serif Gurmukhi',
  'Noto Sans Marchen',
  'Noto Sans Tagbanwa',
  'Noto Sans Balinese',
  'Noto Serif NP Hmong',
  'Noto Sans Ugaritic',
  'Noto Sans Takri',
  'Noto Sans Cuneiform',
  'Noto Sans Ol Chiki',
  'Noto Sans Old Turkic',
  'Noto Sans Bamum',
  'Noto Sans Sharada',
  'Noto Sans Lydian',
  'Noto Serif Grantha',
  'Noto Sans Pahawh Hmong',
  'Noto Sans Psalter Pahlavi',
  'Noto Sans Glagolitic',
  'Yuji Hentaigana Akebono',
  'Noto Sans Brahmi',
  'Noto Sans Limbu',
  'Noto Serif Balinese',
  'Padyakke Expanded One',
  'Noto Sans Saurashtra',
  'Noto Sans Bassa Vah',
  'Noto Sans Inscriptional Pahlavi',
  'Noto Sans Buhid',
  'Noto Serif Oriya',
  'Noto Sans Medefaidrin',
  'Noto Sans Buginese',
  'Noto Sans Hanunoo',
  'Noto Sans Lisu',
  'Noto Sans Tai Le',
  'Noto Sans Palmyrene',
  'Noto Sans Mayan Numerals',
  'Noto Sans Osage',
  'Noto Sans Khudawadi',
  'Noto Sans Tirhuta',
  'Noto Sans Mende Kikakui',
  'Noto Sans Newa',
  'Noto Serif Yezidi',
  'Noto Sans Masaram Gondi',
  'Noto Sans Bhaiksuki',
  'Noto Sans Phoenician',
  'Noto Sans Warang Citi',
  'Noto Sans Modi',
  'Noto Sans Chakma',
  'Noto Sans Rejang',
  'Noto Sans Vai',
  'Noto Sans Siddham',
  'Noto Sans Hatran',
  'Noto Sans Shavian',
  'Noto Sans Zanabazar Square',
  'Noto Sans Grantha',
  'Noto Sans Lycian',
  'Noto Sans Batak',
  'Noto Serif Dogra',
  'Noto Sans Runic',
  'Noto Sans Wancho',
  'Noto Sans Inscriptional Parthian',
  'Noto Sans Old South Arabian',
  'Noto Sans SignWriting',
  'Noto Sans Linear A',
  'Noto Sans Syriac',
  'Noto Sans Old North Arabian',
  'Noto Sans Kaithi',
  'Noto Sans Tamil Supplement',
  'Noto Sans Mandaic',
  'Noto Sans Caucasian Albanian',
  'Noto Sans Ogham',
  'Noto Sans Nushu',
  'Noto Sans Hanifi Rohingya',
  'Noto Sans Old Sogdian',
  'Noto Sans Elbasan',
  'Noto Sans Duployan',
  'Noto Sans Khojki',
  'Noto Sans Old Permic',
  'Noto Sans Kharoshthi',
  'Noto Sans NKo',
  'Noto Sans Soyombo',
  'Noto Sans Pau Cin Hau',
  'Noto Sans Linear B',
  'Noto Sans Gunjala Gondi',
  'Noto Sans Meroitic',
  'Noto Sans Manichaean',
  'Noto Sans Nabataean',
  'Noto Sans Osmanya',
  'Noto Sans Sogdian',
  'Noto Sans Elymaic',
  'Noto Sans Mahajani',
  'Noto Sans Phags Pa',
] as const;
