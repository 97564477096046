import { QATags } from 'models/QA.model';
import React, { useCallback } from 'react';
import { Routes } from 'models/Router.model';
import { useHistory, useLocation, generatePath, matchPath } from 'react-router-dom';
import { NavTab } from 'apps/ui';
import { useIntl } from 'react-intl';
import { ReactComponent as ProfileSVG } from '../../assets/profile.svg';

export function ButtonGoVerification({ path = Routes.identity.profile.details.verificationDetails, disabled = false, identityId, verificationId, className }: {
  path?: string;
  disabled?: boolean;
  verificationId: string;
  identityId: string;
  className?: string;
}) {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const isPathMatch = matchPath(location.pathname, { path, exact: true });

  const openVerification = useCallback(() => {
    if (identityId && verificationId) {
      history.push(
        generatePath(path, {
          identityId,
          verificationId,
        }),
        {
          from: history.location.pathname + history.location.search,
          rootList: history.location.state?.from,
        },
      );
    }
  }, [history, identityId, verificationId]);

  return (
    <NavTab
      selected={isPathMatch}
      disabled={disabled}
      onClick={openVerification}
      icon={<ProfileSVG />}
      qa={QATags.Verification.Buttons.History}
      className={className}
    >
      {intl.formatMessage({ id: 'Verification.button.profile' })}
    </NavTab>
  );
}
