import { PhoneCheck } from 'apps/PhoneCheck/services/PhoneCheck.service';
import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';

export const phoneCheckInit = () => (_, getState): ProductTypes => {
  const phoneCheck = new PhoneCheck({
    disabled: !selectMerchantTags(getState()).includes(MerchantTagsTypes.CanUsePhoneValidation),
  });
  productManagerService.register(phoneCheck);
  return phoneCheck.id;
};
