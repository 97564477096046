import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useFormatMessage } from 'apps/intl';
import { ErrorStatusTypes } from 'models/Error.model';
import { selectMerchantId } from 'state/merchant/merchant.selectors';
import { WatchlistFileUpload, IWatchlistApiCall, IWatchlistFileUploadErrors } from 'apps/ui';
import { IWatchlist, IWatchlistUpload, IWatchlistValidationError, WATCHLIST_VALIDATION_ERROR_TYPE_MAX_COUNT } from 'models/Watchlist.model';
import { selectCurrentCustomWatchlistIsFileAvailable, selectCurrentCustomWatchlistFileInfo, selectCurrentCustomWatchlistFileError, selectCurrentCustomWatchlistError } from '../../state/CustomWatchlist.selectors';
import { CustomWatchlistModalValidationInputs } from '../../models/CustomWatchlist.model';
import * as api from '../../client/CustomWatchlist.client';
import { clearCurrentWatchlistFileError, updateCurrentWatchlist } from '../../state/CustomWatchlist.actions';

export function CustomWatchlistModalValidationFileUploadForm({ watchlist, onFileUploaded }: {
  watchlist?: IWatchlist;
  onFileUploaded?: (data: IWatchlistUpload) => void;
}) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const merchantId = useSelector(selectMerchantId);
  const isFileAvailable = useSelector(selectCurrentCustomWatchlistIsFileAvailable);
  const currentCustomWatchlistFileInfo = useSelector(selectCurrentCustomWatchlistFileInfo);
  const currentWatchlistError = useSelector<any, IWatchlistValidationError[] | null>(selectCurrentCustomWatchlistError);
  const fileErrorType = useSelector<any, string>(selectCurrentCustomWatchlistFileError);
  const { setValue, setError, clearErrors, formState: { errors } } = useFormContext();

  const isEdit = Boolean(watchlist);

  useEffect(() => {
    if (currentCustomWatchlistFileInfo?.fileKey && currentCustomWatchlistFileInfo?.fileName) {
      setValue(CustomWatchlistModalValidationInputs.FileKey, currentCustomWatchlistFileInfo.fileKey);
      setValue(CustomWatchlistModalValidationInputs.FileName, currentCustomWatchlistFileInfo.fileName);
    }
  }, [currentCustomWatchlistFileInfo, setValue]);

  useEffect(() => {
    if (fileErrorType) {
      setError(CustomWatchlistModalValidationInputs.FileKey, {
        message: formatMessage(`Watchlist.settings.${fileErrorType}`),
      });
    }
  }, [fileErrorType, setError, formatMessage]);

  const onFileSelect = useCallback((valueFileName: string) => {
    dispatch(clearCurrentWatchlistFileError());
    setValue(CustomWatchlistModalValidationInputs.FileName, valueFileName);
  }, [dispatch, setValue]);

  const apiCall = useMemo<IWatchlistApiCall<IWatchlistUpload>>(() => ({
    callTo: api.uploadMerchantWatchlist,
    onSuccess: (data) => {
      setValue(CustomWatchlistModalValidationInputs.FileKey, data.key);
      clearErrors(CustomWatchlistModalValidationInputs.FileKey);
      onFileUploaded(data);

      if (!isFileAvailable && isEdit) {
        dispatch(updateCurrentWatchlist({ isFileAvailable: true }));
      }
    },
    onError: (error: any) => {
      if (error?.response?.status === ErrorStatusTypes.PayloadTooLarge) {
        setError(CustomWatchlistModalValidationInputs.FileKey, {
          message: formatMessage('Watchlist.settings.watchlist.fileSizeExceed'),
        });

        return;
      }
      setError(CustomWatchlistModalValidationInputs.FileKey, {
        message: formatMessage('Watchlist.settings.watchlist.fileErrorUpload'),
      });
    },
  }), [isFileAvailable, isEdit, setValue, clearErrors, setError, onFileUploaded, formatMessage, dispatch]);

  const watchlistFileUploadErrors = useMemo<IWatchlistFileUploadErrors>(() => ({
    fileKey: errors[CustomWatchlistModalValidationInputs.FileKey]?.message,
    csvSeparator: errors[CustomWatchlistModalValidationInputs.CsvSeparator]?.message,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [errors[CustomWatchlistModalValidationInputs.FileKey], errors[CustomWatchlistModalValidationInputs.CsvSeparator]]);

  const isMaxRowCountError = currentWatchlistError && currentWatchlistError[0].type === WATCHLIST_VALIDATION_ERROR_TYPE_MAX_COUNT;

  return (
    <WatchlistFileUpload
      initialFileName={watchlist?.process?.inputSourceFileName}
      csvSeparatorFieldName={CustomWatchlistModalValidationInputs.CsvSeparator}
      api={apiCall}
      merchantId={merchantId}
      errors={watchlistFileUploadErrors}
      isFileAvailable={isFileAvailable}
      onFileSelect={onFileSelect}
      error={isMaxRowCountError && formatMessage(`Watchlist.settings.modal.button.${currentWatchlistError[0].type}`)}
    />
  );
}
