import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'lib/debounce.hook';
import { FixedSizeTree, treeWalker } from 'lib/FixedSizeTree';
import { CountryCode, WorldPartsBackendTypes } from 'apps/countries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormatMessage } from 'apps/intl';
import { BoxBordered } from 'apps/ui';
import { getInitialCountryAndWorldPartState, ITree, IListItemData, ICountryAndWorldPart, createTrees, ICountryAndWorldPartState, setCountryOrWorldPartChecked, SelectItemHandler, getIsCountryOrWorldPartChecked, IsItemCheckedGetter } from '../../models/CountryAndWorldPartSelectingForm.model';
import { CountryAndWorldPartSelectingFormItem } from '../CountryAndWorldPartSelectingFormItem/CountryAndWorldPartSelectingFormItem';
import { useStyles, StyledButtonBase } from './CountryAndWorldPartSelectingForm.styles';

export function CountryAndWorldPartSelectingForm({ onSubmit, onCancel, initialValues, possibleValues, selectorHeight = 340 }: {
  onSubmit: (data: ICountryAndWorldPart[]) => void;
  onCancel: () => void;
  possibleValues: ICountryAndWorldPart[];
  initialValues?: ICountryAndWorldPart[];
  selectorHeight?: number;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const debounced = useDebounce();
  const [countryAndWorldPartStates, setCountryAndWorldPartStates] = useState<ICountryAndWorldPartState[]>(getInitialCountryAndWorldPartState(possibleValues, initialValues));
  const allTrees = useMemo<ITree[]>(() => createTrees(possibleValues, formatMessage), [formatMessage, possibleValues]);
  const [displayedTrees, setDisplayedTrees] = useState<ITree[]>(allTrees);

  useEffect(() => {
    setCountryAndWorldPartStates(getInitialCountryAndWorldPartState(possibleValues, initialValues));
  }, [possibleValues, initialValues]);

  useEffect(() => {
    setDisplayedTrees(allTrees);
  }, [allTrees]);

  const handleSelectAll = () => setCountryAndWorldPartStates((prevStates) => prevStates.map((prevState) => ({ ...prevState, checked: true })));

  const handleDeselectAll = () => setCountryAndWorldPartStates((prevStates) => prevStates.map((prevState) => ({ ...prevState, checked: false })));

  const handleSubmit = () => onSubmit(countryAndWorldPartStates.filter((state) => state.checked)
    .map((state) => state.countryAndWorldPart));

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const query: string = event.target.value.toLowerCase();
    const newTrees: ITree[] = query === '' ? allTrees : allTrees.map<ITree>((worldPartTree) => ({
      ...worldPartTree,
      children: worldPartTree.children.filter((countryTreeChild) => countryTreeChild.label.toLowerCase().includes(query)),
    }))
      .filter((worldPartTree) => worldPartTree.children?.length > 0 || worldPartTree.label.toLowerCase().includes(query));
    debounced(() => setDisplayedTrees(newTrees));
  }, [debounced, allTrees]);

  const handleTreeWalker = (refresh: boolean) => treeWalker<ITree>(refresh, displayedTrees, () => true);

  const handleSelectItem = (
    (checked: boolean, id: WorldPartsBackendTypes | CountryCode) => setCountryAndWorldPartStates((prev) => setCountryOrWorldPartChecked(prev, id, checked))
  ) as SelectItemHandler;

  const getIsItemChecked = (
    (id: WorldPartsBackendTypes | CountryCode): boolean => getIsCountryOrWorldPartChecked(countryAndWorldPartStates, id)
  ) as IsItemCheckedGetter;

  const listItemData: IListItemData = {
    handleSelect: handleSelectItem,
    getIsChecked: getIsItemChecked,
  };

  const selectedFiltersCount = countryAndWorldPartStates.reduce<number>((count, countryAndWorldPartState) => (countryAndWorldPartState.checked ? count + 1 : count), 0);

  return (
    <Box>
      <Box className={classes.headerControls}>
        <Box className={classes.selectedAmount}>
          {selectedFiltersCount}
          {formatMessage('CountryModalSelect.selectedAmount')}
        </Box>
        <Box mr={0.4}>
          <StyledButtonBase disableRipple onClick={handleSelectAll}>{formatMessage('CountryModalSelect.selectAll')}</StyledButtonBase>
        </Box>
        <Box>
          <StyledButtonBase disableRipple onClick={handleDeselectAll}>{formatMessage('CountryModalSelect.deselectAll')}</StyledButtonBase>
        </Box>
      </Box>
      <BoxBordered mb={2} className={classes.formBox}>
        <OutlinedInput
          fullWidth
          className={classes.searchInput}
          type="search"
          startAdornment={<SearchIcon className={classes.searchIcon} />}
          placeholder={formatMessage('CountryModalSelect.filter')}
          onChange={handleSearchChange}
        />
        <List className={classes.tree}>
          <FixedSizeTree
            treeWalker={handleTreeWalker}
            itemSize={30}
            height={selectorHeight}
            width="100%"
            itemData={listItemData}
          >
            {CountryAndWorldPartSelectingFormItem}
          </FixedSizeTree>
        </List>
      </BoxBordered>
      <Box className={classes.footer}>
        <Button variant="text" color="primary" onClick={onCancel}>
          {formatMessage('CountryModalSelect.cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
          {formatMessage('CountryModalSelect.submit')}
        </Button>
      </Box>
    </Box>
  );
}
