import mapValues from 'lodash/mapValues';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { getMedia } from 'apps/media/api/media.client';
import { notification } from 'apps/ui';
import { ErrorMessagesTokenTypes } from 'models/Error.model';

export enum MediaStatusTypes {
  MediaIsEmpty = 'MEDIA_IS_EMPTY',
  MediaIsLoading = 'MEDIA_IS_LOADING',
  MediaIsFailed = 'MEDIA_IS_FAILED'
}

export type MediaBlobUrl = string;
export const MEDIA_URL_REGEX = /^https?:\/\/media\./i;
export const CDN_URL_REGEX = /^(?=.*https:\/\/)(?=.*cdn)(?=.*\bExpires\b)/i;
export const BLOB_URL = /^blob:(.*)$/;

async function downloadMedia(media: string, pushToBlobUrlCollection?: (blob: MediaBlobUrl) => void): Promise<string> {
  try {
    const response = await getMedia(media);
    const blobUrl = URL.createObjectURL(response.data);
    if (pushToBlobUrlCollection) {
      pushToBlobUrlCollection(blobUrl);
    }
    return blobUrl;
  } catch (error) {
    console.error(error);
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
    return MediaStatusTypes.MediaIsFailed;
  }
}

async function downloadCDNMedia(media: string, pushToBlobUrlCollection?: (blob: MediaBlobUrl) => void): Promise<string> {
  try {
    const blob = await (await fetch(media)).blob();
    const blobUrl = URL.createObjectURL(blob);
    if (pushToBlobUrlCollection) {
      pushToBlobUrlCollection(blobUrl);
    }
    return blobUrl;
  } catch (error) {
    console.error(error);
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
    return MediaStatusTypes.MediaIsFailed;
  }
}

export function startDownloadMedia(objectWithMedia: any | any[], pushToBlobUrlCollection?: (blob: MediaBlobUrl) => void): any | any[] {
  const splitActionsByType = (item) => {
    if (!item) {
      return item;
    }

    if (isString(item) && MEDIA_URL_REGEX.test(item)) {
      return downloadMedia(item, pushToBlobUrlCollection);
    }

    if (isString(item) && CDN_URL_REGEX.test(item)) {
      return downloadCDNMedia(item, pushToBlobUrlCollection);
    }

    return startDownloadMedia(item);
  };

  if (Array.isArray(objectWithMedia)) {
    return objectWithMedia.map(splitActionsByType);
  }

  if (isObject(objectWithMedia)) {
    return mapValues(objectWithMedia, splitActionsByType);
  }

  return objectWithMedia;
}
