import { useState } from 'react';

/** A hacky hook to throw error in render to trigger ErrorBoundary. */

export const useThrowErrorFromRender = () => {
  const [, setError] = useState();
  return (error) => {
    setError(() => {
      throw error;
    });
  };
};
