import React from 'react';
import { PreventClosingTabWrapper } from 'apps/routing/components/PreventClosingTabWrapper/PreventClosingTabWrapper';
import { toast, ToastContainer, ToastContent, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MessageDescriptor, useFormatMessage } from 'apps/intl';
import styles from './style.module.scss';

function NotificationMessage({ messageToken, messageDescriptor }: { messageToken: string; messageDescriptor?: MessageDescriptor<any> }) {
  const formatMessage = useFormatMessage();

  return (
    <>
      {formatMessage(messageToken, messageDescriptor)}
    </>
  );
}

function NotificationProgress({ message, duration }: {
  message: string;
  duration?: number;
}) {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.progressContainer}>
      <div>
        {formatMessage(message)}
      </div>
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{
            animation: `${styles.grow} ${duration}s forwards linear`,
          }}
        />
      </div>
    </div>
  );
}

let blocked = false;

const blockableErrorNotification: typeof toast.error = (content, ...args) => {
  if (blocked) {
    return 'blocked';
  }
  return toast.error(content, ...args);
};

const blockableFormatMessageErrorNotification = (content: string, options?: ToastOptions & { messageDescriptor?: MessageDescriptor<string | number | JSX.Element> }) => {
  if (blocked) {
    return 'blocked';
  }
  return toast.error(<NotificationMessage messageToken={content} messageDescriptor={options?.messageDescriptor as MessageDescriptor<any>} />, options);
};

const progressNotification = (message: string, options?: ToastOptions & { duration: number }) => toast(<NotificationProgress message={message} duration={options?.duration} />, {
  ...(options ?? {}),
  autoClose: false,
});

export const notification = {
  block: () => { blocked = true; },
  unblock: () => { blocked = false; },
  ...toast,
  error: blockableErrorNotification,
  errorFormatMessage: blockableFormatMessageErrorNotification,
  spinner: (content: ToastContent, options?: ToastOptions) => toast(
    <PreventClosingTabWrapper>{content}</PreventClosingTabWrapper>,
    {
      ...options,
      autoClose: false,
      className: 'Toastify__toast--spinner',
    },
  ),
  progress: progressNotification,
};

export function NotificationsContainer() {
  return (
    <ToastContainer
      hideProgressBar
      pauseOnHover={false}
      draggable={false}
      autoClose={5000}
      closeOnClick={false}
      className={styles.notification}
    />
  );
}
