import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mediaContainer: {
    minWidth: 160,
    maxWidth: 180,
  },
  checkData: {
    color: theme.palette.common.black90,
    marginTop: 12,
  },
  checkTitle: {
    marginTop: 7,
    color: theme.palette.common.black75,
  },
  checkDetailsContainer: {
    paddingLeft: 40,
  },
  script: {
    wordBreak: 'break-word',
  },
}));
