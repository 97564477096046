import React, { useEffect, useState } from 'react';
import { ChecksByDocument } from 'apps/ui';
import { CheckBarExpandable, CheckStepDetails } from 'apps/checks';
import { DocumentTypes } from 'models/Document.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import Grid from '@material-ui/core/Grid';
import { getDataSourceStructure } from 'models/Watchlist.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { VerificationDataSourceTypes } from 'apps/CustomWatchlist';
import { AmlValidationTypes, ILastMonitoredTimestamps } from 'apps/Aml/models/Aml.model';
import { CustomFieldVerificationInfo, CustomFieldVerificationList, CustomFieldVerificationSectionInfo } from 'apps/CustomField';
import { useStyles } from './AmlVerificationProduct.styles';
import { PremiumWatchlists } from './components/PremiumWatchlists/PremiumWatchlists';
import { LegacyPremiumWatchlists } from './components/LegacyPremiumWatchlists/LegacyPremiumWatchlists';
import { BasicWatchlists } from './components/BasicWatchlists/BasicWatchlists';
import { getLastMonitoredTimestamps } from 'apps/Aml/client/Aml.client';
import { useSelector } from 'react-redux';
import { selectVerification } from 'apps/Verification';

export function AmlVerificationProduct({ data }: { data: VerificationResponse }) {
  const classes = useStyles();
  const [lastMonitoredTimestamps, setLastMonitoredTimestamps] = useState<ILastMonitoredTimestamps[]>();
  const dataSources = data && getDataSourceStructure(data);
  const { _id: verificationId } = useSelector(selectVerification);
  const isLegacyAmlMonitoringAvailable = data.flow.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsSearchValidation] === AmlValidationTypes.SearchMonitoring;
  const isAmlMonitoringAvailable = data.flow.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsValidation] === AmlValidationTypes.SearchMonitoring;

  useEffect(() => {
    const getLastTimestamps = async () => {
      const { data: { searches } } = await getLastMonitoredTimestamps(verificationId);
      setLastMonitoredTimestamps(searches);
    };
    if (data.flow.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsSearchValidation] !== AmlValidationTypes.None
        || data.flow.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsValidation] !== AmlValidationTypes.None) {
      getLastTimestamps();
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        {Object.entries(dataSources)
          .map(([dataSourceKey, {
            country,
            photos,
            premiumWatchlists,
            basicWatchlists,
            legacyBasicWatchlist,
            legacyPremiumWatchlist,
          }]) => (
            <Grid key={dataSourceKey} item xs={12} xl={4} className={classes.wrapper}>
              <ChecksByDocument
                docType={dataSourceKey as VerificationDataSourceTypes.CustomFields | DocumentTypes}
                country={country}
                photos={photos}
                detailsSlot={dataSourceKey === VerificationDataSourceTypes.CustomFields
                  && (
                    <CustomFieldVerificationList
                      country={data?.customFieldsDataCopy?.country}
                      fields={data?.customFieldsDataCopy?.fields}
                      atomicContainer={CustomFieldVerificationInfo}
                      sectionContainer={CustomFieldVerificationSectionInfo}
                    />
                  )}
              >
                {premiumWatchlists && (
                  <PremiumWatchlists
                    watchlists={premiumWatchlists.data}
                    errorCode={premiumWatchlists.error?.code}
                    status={premiumWatchlists.checkStatus}
                    isAmlMonitoringAvailable={isAmlMonitoringAvailable}
                    lastMonitoredTimestamps={lastMonitoredTimestamps}
                  />
                )}
                {legacyPremiumWatchlist && (
                  <LegacyPremiumWatchlists
                    step={legacyPremiumWatchlist}
                    isAmlMonitoringAvailable={isLegacyAmlMonitoringAvailable}
                  />
                )}
                {legacyBasicWatchlist && (
                  <CheckBarExpandable step={legacyBasicWatchlist}>
                    <CheckStepDetails step={legacyBasicWatchlist} />
                  </CheckBarExpandable>
                )}
                {basicWatchlists && (
                  <BasicWatchlists
                    watchlists={basicWatchlists?.data}
                    checkStatus={basicWatchlists?.checkStatus}
                    errorCode={basicWatchlists?.error?.code}
                  />
                )}
              </ChecksByDocument>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}
