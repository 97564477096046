import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  table: {
    border: `1px solid ${theme.palette.foreground.main}`,
  },
  tableHeadCell: {
    fontWeight: 400,
    color: theme.palette.common.darkgrey,
  },
  tableCell: {
    borderRight: `1px solid ${theme.palette.foreground.main}`,
    borderBottom: `1px solid ${theme.palette.foreground.main}`,
  },
  weight: {
    width: '115px',
    height: '35px',
    borderRadius: '100px',
    textAlign: 'center',
    lineHeight: '35px',
  },
  weightLow: {
    color: theme.palette.common.lightblue,
    backgroundColor: '#F6F8FE',
  },
  weightMedium: {
    color: theme.palette.common.lightblue,
    backgroundColor: theme.palette.common.whiteblue,
  },
  weightHeavy: {
    color: '#305BC5',
    backgroundColor: '#DAE4FB',
  },
  weightVeryHeavy: {
    color: theme.palette.common.white,
    backgroundColor: '#304373',
  },
  rowItem: {
    display: 'flex',
    gap: '5px',
    fontWeight: 400,
    fontSize: '14px',
    alignItems: 'center',
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    border: `1px solid ${theme.palette.foreground.main}`,
    borderBottom: 'none',
    padding: '15px',
  },
  filterTitle: {
    color: theme.palette.common.darkgrey,
    fontSize: '16px',
  },
  filterItem: {
    color: theme.palette.common.lightblue,
    fontSize: '14px',
    fontWeight: 600,
    minWidth: '40px',
    padding: '5px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  filterItemActive: {
    borderRadius: '100px',
    backgroundColor: theme.palette.common.whiteblue,
  },
  sort: {
    display: 'flex',
    gap: '50px',
  },
  sortIcon: {
    cursor: 'pointer',
  },
}));
