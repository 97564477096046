import { CountryCode } from 'apps/countries';

export interface IDocumetSubTypesWithTransalates {
  id: string;
  title: string;
}
export interface Country {
  code: CountryCode;
  dialingCode: number;
  id: string;
  name: string;
  regions: string[];
  unsupported: boolean;
  businessRegion?: string;
  documentSubtypesWithTranslates?: IDocumetSubTypesWithTransalates[];
}

export enum CountryCodeTypes {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AR = 'AR',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FM = 'FM',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GH = 'GH',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GT = 'GT',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IN = 'IN',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KV = 'KV',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MR = 'MR',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NE = 'NE',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PS = 'PS',
  PT = 'PT',
  PY = 'PY',
  QA = 'QA',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SI = 'SI',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SV = 'SV',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TT = 'TT',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VN = 'VN',
  VU = 'VU',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export enum RegionsTypes {
  LatinAmerica = 'latinAmerica',
  Africa = 'africa',
  SouthEastAsia = 'southEastAsia',
}

export const CountriesByRegions: Record<RegionsTypes, CountryCodeTypes[]> = {
  [RegionsTypes.LatinAmerica]: [
    CountryCodeTypes.AR,
    CountryCodeTypes.BO,
    CountryCodeTypes.BR,
    CountryCodeTypes.CL,
    CountryCodeTypes.CO,
    CountryCodeTypes.CR,
    CountryCodeTypes.DO,
    CountryCodeTypes.EC,
    CountryCodeTypes.SV,
    CountryCodeTypes.GT,
    CountryCodeTypes.HN,
    CountryCodeTypes.MX,
    CountryCodeTypes.PA,
    CountryCodeTypes.PY,
    CountryCodeTypes.PE,
    CountryCodeTypes.UY,
    CountryCodeTypes.VE,
  ],
  [RegionsTypes.Africa]: [
    CountryCodeTypes.GH,
    CountryCodeTypes.KE,
    CountryCodeTypes.NG,
    CountryCodeTypes.UG,
  ],
  [RegionsTypes.SouthEastAsia]: [
    CountryCodeTypes.PH,
    CountryCodeTypes.ID,
  ],
};

export interface ICountry {
  code: CountryCodeTypes;
  selected: boolean;
}

export const COUNTRY_CURRENCY_MAPPING = {
  [CountryCodeTypes.BR]: 'BRL',
  [CountryCodeTypes.CO]: 'COP',
  [CountryCodeTypes.MX]: 'MXN',
  [CountryCodeTypes.AR]: 'ARS',
  [CountryCodeTypes.CL]: 'CLP',
  [CountryCodeTypes.PE]: 'PEN',
  [CountryCodeTypes.PH]: 'PHP',
  [CountryCodeTypes.NG]: 'NGN',
};

export type CurrenciesTypes = keyof typeof COUNTRY_CURRENCY_MAPPING;

export interface AllowedRegions {
  country: string;
  regions: string[];
}

export const Countries = [
  {
    code: CountryCodeTypes.AD,
    name: 'Andorra',
  },
  {
    code: CountryCodeTypes.AE,
    name: 'United Arab Emirates',
  },
  {
    code: CountryCodeTypes.AF,
    name: 'Afghanistan',
  },
  {
    code: CountryCodeTypes.AG,
    name: 'Antigua And Barbuda',
  },
  {
    code: CountryCodeTypes.AL,
    name: 'Albania',
  },
  {
    code: CountryCodeTypes.AM,
    name: 'Armenia',
  },
  {
    code: CountryCodeTypes.AO,
    name: 'Angola',
  },
  {
    code: CountryCodeTypes.AR,
    name: 'Argentina',
  },
  {
    code: CountryCodeTypes.AT,
    name: 'Austria',
  },
  {
    code: CountryCodeTypes.AU,
    name: 'Australia',
  },
  {
    code: CountryCodeTypes.AW,
    name: 'Aruba',
  },
  {
    code: CountryCodeTypes.AZ,
    name: 'Azerbaijan',
  },
  {
    code: CountryCodeTypes.BA,
    name: 'Bosnia And Herzegovina',
  },
  {
    code: CountryCodeTypes.BB,
    name: 'Barbados',
  },
  {
    code: CountryCodeTypes.BD,
    name: 'Bangladesh',
  },
  {
    code: CountryCodeTypes.BE,
    name: 'Belgium',
  },
  {
    code: CountryCodeTypes.BF,
    name: 'Burkina Faso',
  },
  {
    code: CountryCodeTypes.BG,
    name: 'Bulgaria',
  },
  {
    code: CountryCodeTypes.BH,
    name: 'Bahrain',
  },
  {
    code: CountryCodeTypes.BI,
    name: 'Burundi',
  },
  {
    code: CountryCodeTypes.BJ,
    name: 'Benin',
  },
  {
    code: CountryCodeTypes.BM,
    name: 'Bermuda',
  },
  {
    code: CountryCodeTypes.BN,
    name: 'Brunei Darussalam',
  },
  {
    code: CountryCodeTypes.BO,
    name: 'Bolivia',
  },
  {
    code: CountryCodeTypes.BR,
    name: 'Brazil',
  },
  {
    code: CountryCodeTypes.BS,
    name: 'Bahamas',
  },
  {
    code: CountryCodeTypes.BT,
    name: 'Bhutan',
  },
  {
    code: CountryCodeTypes.BW,
    name: 'Botswana',
  },
  {
    code: CountryCodeTypes.BY,
    name: 'Belarus',
  },
  {
    code: CountryCodeTypes.BZ,
    name: 'Belize',
  },
  {
    code: CountryCodeTypes.CA,
    name: 'Canada',
  },
  {
    code: CountryCodeTypes.CD,
    name: 'Congo, The Democratic Republic Of The',
  },
  {
    code: CountryCodeTypes.CF,
    name: 'Central African Republic',
  },
  {
    code: CountryCodeTypes.CG,
    name: 'Congo',
  },
  {
    code: CountryCodeTypes.CH,
    name: 'Switzerland',
  },
  {
    code: CountryCodeTypes.CI,
    name: 'Cote D\'ivoire',
  },
  {
    code: CountryCodeTypes.CL,
    name: 'Chile',
  },
  {
    code: CountryCodeTypes.CM,
    name: 'Cameroon',
  },
  {
    code: CountryCodeTypes.CN,
    name: 'China',
  },
  {
    code: CountryCodeTypes.CO,
    name: 'Colombia',
  },
  {
    code: CountryCodeTypes.CR,
    name: 'Costa Rica',
  },
  {
    code: CountryCodeTypes.CU,
    name: 'Cuba',
  },
  {
    code: CountryCodeTypes.CV,
    name: 'Cape Verde',
  },
  {
    code: CountryCodeTypes.CW,
    name: 'Curacao',
  },
  {
    code: CountryCodeTypes.CY,
    name: 'Cyprus',
  },
  {
    code: CountryCodeTypes.CZ,
    name: 'Czech Republic',
  },
  {
    code: CountryCodeTypes.DE,
    name: 'Germany',
  },
  {
    code: CountryCodeTypes.DJ,
    name: 'Djibouti',
  },
  {
    code: CountryCodeTypes.DK,
    name: 'Denmark',
  },
  {
    code: CountryCodeTypes.DM,
    name: 'Dominica',
  },
  {
    code: CountryCodeTypes.DO,
    name: 'Dominican Republic',
  },
  {
    code: CountryCodeTypes.DZ,
    name: 'Algeria',
  },
  {
    code: CountryCodeTypes.EC,
    name: 'Ecuador',
  },
  {
    code: CountryCodeTypes.EE,
    name: 'Estonia',
  },
  {
    code: CountryCodeTypes.EG,
    name: 'Egypt',
  },
  {
    code: CountryCodeTypes.EH,
    name: 'Western Sahara',
  },
  {
    code: CountryCodeTypes.ER,
    name: 'Eritrea',
  },
  {
    code: CountryCodeTypes.ES,
    name: 'Spain',
  },
  {
    code: CountryCodeTypes.ET,
    name: 'Ethiopia',
  },
  {
    code: CountryCodeTypes.FI,
    name: 'Finland',
  },
  {
    code: CountryCodeTypes.FJ,
    name: 'Fiji',
  },
  {
    code: CountryCodeTypes.FM,
    name: 'Micronesia, Federated States Of',
  },
  {
    code: CountryCodeTypes.FR,
    name: 'France',
  },
  {
    code: CountryCodeTypes.GA,
    name: 'Gabon',
  },
  {
    code: CountryCodeTypes.GB,
    name: 'United Kingdom',
  },
  {
    code: CountryCodeTypes.GD,
    name: 'Grenada',
  },
  {
    code: CountryCodeTypes.GE,
    name: 'Georgia',
  },
  {
    code: CountryCodeTypes.GH,
    name: 'Ghana',
  },
  {
    code: CountryCodeTypes.GM,
    name: 'Gambia',
  },
  {
    code: CountryCodeTypes.GN,
    name: 'Guinea',
  },
  {
    code: CountryCodeTypes.GP,
    name: 'Guadeloupe',
  },
  {
    code: CountryCodeTypes.GQ,
    name: 'Equatorial Guinea',
  },
  {
    code: CountryCodeTypes.GR,
    name: 'Greece',
  },
  {
    code: CountryCodeTypes.GT,
    name: 'Guatemala',
  },
  {
    code: CountryCodeTypes.GW,
    name: 'Guinea-bissau',
  },
  {
    code: CountryCodeTypes.GY,
    name: 'Guyana',
  },
  {
    code: CountryCodeTypes.HK,
    name: 'Hong Kong',
  },
  {
    code: CountryCodeTypes.HN,
    name: 'Honduras',
  },
  {
    code: CountryCodeTypes.HR,
    name: 'Croatia',
  },
  {
    code: CountryCodeTypes.HT,
    name: 'Haiti',
  },
  {
    code: CountryCodeTypes.HU,
    name: 'Hungary',
  },
  {
    code: CountryCodeTypes.ID,
    name: 'Indonesia',
  },
  {
    code: CountryCodeTypes.IE,
    name: 'Ireland',
  },
  {
    code: CountryCodeTypes.IL,
    name: 'Israel',
  },
  {
    code: CountryCodeTypes.IN,
    name: 'India',
  },
  {
    code: CountryCodeTypes.IQ,
    name: 'Iraq',
  },
  {
    code: CountryCodeTypes.IR,
    name: 'Iran, Islamic Republic Of',
  },
  {
    code: CountryCodeTypes.IS,
    name: 'Iceland',
  },
  {
    code: CountryCodeTypes.IT,
    name: 'Italy',
  },
  {
    code: CountryCodeTypes.JM,
    name: 'Jamaica',
  },
  {
    code: CountryCodeTypes.JO,
    name: 'Jordan',
  },
  {
    code: CountryCodeTypes.JP,
    name: 'Japan',
  },
  {
    code: CountryCodeTypes.KE,
    name: 'Kenya',
  },
  {
    code: CountryCodeTypes.KG,
    name: 'Kyrgyzstan',
  },
  {
    code: CountryCodeTypes.KH,
    name: 'Cambodia',
  },
  {
    code: CountryCodeTypes.KI,
    name: 'Kiribati',
  },
  {
    code: CountryCodeTypes.KP,
    name: 'Korea, Democratic People\'s Republic Of',
  },
  {
    code: CountryCodeTypes.KR,
    name: 'Korea, Republic Of',
  },
  {
    code: CountryCodeTypes.KV,
    name: 'Kosovo',
  },
  {
    code: CountryCodeTypes.KW,
    name: 'Kuwait',
  },
  {
    code: CountryCodeTypes.KY,
    name: 'Cayman Islands',
  },
  {
    code: CountryCodeTypes.KZ,
    name: 'Kazakstan',
  },
  {
    code: CountryCodeTypes.LA,
    name: 'Lao People\'s Democratic Republic',
  },
  {
    code: CountryCodeTypes.LB,
    name: 'Lebanon',
  },
  {
    code: CountryCodeTypes.LI,
    name: 'Liechtenstein',
  },
  {
    code: CountryCodeTypes.LK,
    name: 'Sri Lanka',
  },
  {
    code: CountryCodeTypes.LR,
    name: 'Liberia',
  },
  {
    code: CountryCodeTypes.LS,
    name: 'Lesotho',
  },
  {
    code: CountryCodeTypes.LT,
    name: 'Lithuania',
  },
  {
    code: CountryCodeTypes.LU,
    name: 'Luxembourg',
  },
  {
    code: CountryCodeTypes.LV,
    name: 'Latvia',
  },
  {
    code: CountryCodeTypes.LY,
    name: 'Libyan Arab Jamahiriya',
  },
  {
    code: CountryCodeTypes.MA,
    name: 'Morocco',
  },
  {
    code: CountryCodeTypes.MC,
    name: 'Monaco',
  },
  {
    code: CountryCodeTypes.MD,
    name: 'Moldova, Republic Of',
  },
  {
    code: CountryCodeTypes.ME,
    name: 'Montenegro',
  },
  {
    code: CountryCodeTypes.MF,
    name: 'Saint Marteen',
  },
  {
    code: CountryCodeTypes.MG,
    name: 'Madagascar',
  },
  {
    code: CountryCodeTypes.MK,
    name: 'Macedonia, The Former Yugoslav Republic Of',
  },
  {
    code: CountryCodeTypes.ML,
    name: 'Mali',
  },
  {
    code: CountryCodeTypes.MM,
    name: 'Myanmar',
  },
  {
    code: CountryCodeTypes.MN,
    name: 'Mongolia',
  },
  {
    code: CountryCodeTypes.MO,
    name: 'Macau',
  },
  {
    code: CountryCodeTypes.MR,
    name: 'Mauritania',
  },
  {
    code: CountryCodeTypes.MT,
    name: 'Malta',
  },
  {
    code: CountryCodeTypes.MU,
    name: 'Mauritius',
  },
  {
    code: CountryCodeTypes.MV,
    name: 'Maldives',
  },
  {
    code: CountryCodeTypes.MW,
    name: 'Malawi',
  },
  {
    code: CountryCodeTypes.MX,
    name: 'Mexico',
  },
  {
    code: CountryCodeTypes.MY,
    name: 'Malaysia',
  },
  {
    code: CountryCodeTypes.MZ,
    name: 'Mozambique',
  },
  {
    code: CountryCodeTypes.NA,
    name: 'Namibia',
  },
  {
    code: CountryCodeTypes.NE,
    name: 'Niger',
  },
  {
    code: CountryCodeTypes.NG,
    name: 'Nigeria',
  },
  {
    code: CountryCodeTypes.NI,
    name: 'Nicaragua',
  },
  {
    code: CountryCodeTypes.NL,
    name: 'Netherlands',
  },
  {
    code: CountryCodeTypes.NO,
    name: 'Norway',
  },
  {
    code: CountryCodeTypes.NP,
    name: 'Nepal',
  },
  {
    code: CountryCodeTypes.NR,
    name: 'Nauru',
  },
  {
    code: CountryCodeTypes.NZ,
    name: 'New Zealand',
  },
  {
    code: CountryCodeTypes.OM,
    name: 'Oman',
  },
  {
    code: CountryCodeTypes.PA,
    name: 'Panama',
  },
  {
    code: CountryCodeTypes.PE,
    name: 'Peru',
  },
  {
    code: CountryCodeTypes.PG,
    name: 'Papua New Guinea',
  },
  {
    code: CountryCodeTypes.PH,
    name: 'Philippines',
  },
  {
    code: CountryCodeTypes.PK,
    name: 'Pakistan',
  },
  {
    code: CountryCodeTypes.PL,
    name: 'Poland',
  },
  {
    code: CountryCodeTypes.PS,
    name: 'Palestinian Territory, Occupied',
  },
  {
    code: CountryCodeTypes.PT,
    name: 'Portugal',
  },
  {
    code: CountryCodeTypes.PY,
    name: 'Paraguay',
  },
  {
    code: CountryCodeTypes.QA,
    name: 'Qatar',
  },
  {
    code: CountryCodeTypes.RO,
    name: 'Romania',
  },
  {
    code: CountryCodeTypes.RS,
    name: 'Serbia',
  },
  {
    code: CountryCodeTypes.RU,
    name: 'Russian Federation',
  },
  {
    code: CountryCodeTypes.RW,
    name: 'Rwanda',
  },
  {
    code: CountryCodeTypes.SA,
    name: 'Saudi Arabia',
  },
  {
    code: CountryCodeTypes.SC,
    name: 'Seychelles',
  },
  {
    code: CountryCodeTypes.SD,
    name: 'Sudan',
  },
  {
    code: CountryCodeTypes.SE,
    name: 'Sweden',
  },
  {
    code: CountryCodeTypes.SG,
    name: 'Singapore',
  },
  {
    code: CountryCodeTypes.SI,
    name: 'Slovenia',
  },
  {
    code: CountryCodeTypes.SK,
    name: 'Slovakia',
  },
  {
    code: CountryCodeTypes.SL,
    name: 'Sierra Leone',
  },
  {
    code: CountryCodeTypes.SM,
    name: 'San Marino',
  },
  {
    code: CountryCodeTypes.SN,
    name: 'Senegal',
  },
  {
    code: CountryCodeTypes.SO,
    name: 'Somalia',
  },
  {
    code: CountryCodeTypes.SR,
    name: 'Suriname',
  },
  {
    code: CountryCodeTypes.SV,
    name: 'El Salvador',
  },
  {
    code: CountryCodeTypes.SY,
    name: 'Syrian Arab Republic',
  },
  {
    code: CountryCodeTypes.SZ,
    name: 'Swaziland',
  },
  {
    code: CountryCodeTypes.TC,
    name: 'Turks and Caicos',
  },
  {
    code: CountryCodeTypes.TD,
    name: 'Chad',
  },
  {
    code: CountryCodeTypes.TG,
    name: 'Togo',
  },
  {
    code: CountryCodeTypes.TH,
    name: 'Thailand',
  },
  {
    code: CountryCodeTypes.TJ,
    name: 'Tajikistan',
  },
  {
    code: CountryCodeTypes.TM,
    name: 'Turkmenistan',
  },
  {
    code: CountryCodeTypes.TN,
    name: 'Tunisia',
  },
  {
    code: CountryCodeTypes.TO,
    name: 'Tonga',
  },
  {
    code: CountryCodeTypes.TP,
    name: 'East Timor',
  },
  {
    code: CountryCodeTypes.TR,
    name: 'Turkey',
  },
  {
    code: CountryCodeTypes.TT,
    name: 'Trinidad And Tobago',
  },
  {
    code: CountryCodeTypes.TW,
    name: 'Taiwan',
  },
  {
    code: CountryCodeTypes.TZ,
    name: 'Tanzania, United Republic Of',
  },
  {
    code: CountryCodeTypes.UA,
    name: 'Ukraine',
  },
  {
    code: CountryCodeTypes.UG,
    name: 'Uganda',
  },
  {
    code: CountryCodeTypes.US,
    name: 'United States',
  },
  {
    code: CountryCodeTypes.UY,
    name: 'Uruguay',
  },
  {
    code: CountryCodeTypes.UZ,
    name: 'Uzbekistan',
  },
  {
    code: CountryCodeTypes.VA,
    name: 'Holy See (vatican City State)',
  },
  {
    code: CountryCodeTypes.VC,
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: CountryCodeTypes.VE,
    name: 'Venezuela',
  },
  {
    code: CountryCodeTypes.VN,
    name: 'Viet Nam',
  },
  {
    code: CountryCodeTypes.VU,
    name: 'Vanatu',
  },
  {
    code: CountryCodeTypes.WS,
    name: 'Samoa',
  },
  {
    code: CountryCodeTypes.YE,
    name: 'Yemen',
  },
  {
    code: CountryCodeTypes.YT,
    name: 'Mayotte',
  },
  {
    code: CountryCodeTypes.ZA,
    name: 'South Africa',
  },
  {
    code: CountryCodeTypes.ZM,
    name: 'Zambia',
  },
  {
    code: CountryCodeTypes.ZW,
    name: 'Zimbabwe',
  },
] as const;
