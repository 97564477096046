import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  companyName: {
    marginTop: 10,
    width: '100%',
  },
  riskAnalysis: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  title: {
    ...theme.typography.body1,
    color: theme.palette.common.black3a,
  },
  titleFaded: {
    ...theme.typography.body1,
    color: theme.palette.common.darkgrey,
  },
  border: {
    borderColor: theme.palette.common.darkgrey,
    padding: '16px',
    borderRadius: '8px',
  },
  paddingBottom: {
    paddingBottom: '16px',
  },
  textPaddingBottom: {
    paddingBottom: '5px',
  },
  boldTitle: {
    ...theme.typography.body1,
    color: theme.palette.common.black3a,
    fontSize: '15px',
    fontWeight: 600,
  },
  link: {
    color: theme.palette.common.blue,
    textDecoration: 'underline',
  },
  hoverContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  active: {
    backgroundColor: theme.palette.common.lightblueopacity,
  },
  alignLeft: {
    paddingLeft: 0,
  },
}));

export const TextFieldInputScore = withStyles({
  input: {
    padding: 10,
  },
  helperText: {
    margin: 0,
  },
})(({ classes, ...props }: any) => (
  <TextField
    variant="outlined"
    InputProps={{
      classes: {
        input: classes.input,
      },
    }}
    FormHelperTextProps={{
      classes: {
        root: classes.helperText,
      },
    }}
    {...props}
  />
));
