export const COLLABORATOR_STORE_KEY = 'collaborators';

export const CollaboratorActionGroups = {
  CollaboratorList: 'COLLABORATOR_LIST',
  Collaborator: 'COLLABORATOR',
  CollaboratorRole: 'COLLABORATOR_ROLE',
};

export const CollaboratorSliceName = {
  CollaboratorList: 'collaboratorList',
  Collaborator: 'collaborator',
  CollaboratorRole: 'collaboratorRole',
};
