import Box from '@material-ui/core/Box';
import { selectFlowBuilderChangeableFlow, selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { useOverlay } from 'apps/overlay';
import cloneDeep from 'lodash/cloneDeep';
import { CountryCodeTypes } from 'models/Country.model';
import { IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { groupPatternsConfig, VerificationInputTypes } from 'models/VerificationPatternsConfigs.model';
import { GovernmentCheckSettingTypes, parseGovCheckPatternConfig, ProductSettingsPropsGovCheck } from '../../models/GovCheckMerit.model';
import { DataSourceSelect } from '../DataSourceSelect/DataSourceSelect';
import { GovCheckAddNew } from '../GovCheckAddNew/GovCheckAddNew';
import { GovCheckExternalDB } from '../GovCheckExternalDB/GovCheckExternalDB';
import { GovCheckModal } from '../GovCheckModal/GovCheckModal';

export function GovCheckSettings({ settings, onUpdate }: ProductSettingsPropsGovCheck) {
  const [createOverlay] = useOverlay();

  const flow = useSelector<any, IFlow>(selectFlowBuilderChangeableFlow);
  const productsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);

  const verificationPatterns = settings[GovernmentCheckSettingTypes.VerificationPatterns].value;
  const patternsConfig = settings[GovernmentCheckSettingTypes.PatternsConfig].value;
  const productSettings = settings[GovernmentCheckSettingTypes.ProductSetting].value;

  const patternsConfigGroupedByCountry = groupPatternsConfig('country', patternsConfig, parseGovCheckPatternConfig.bind({}, verificationPatterns, flow, productsInGraph, productSettings));

  const handleUpdate = useCallback((newData: {settingId: GovernmentCheckSettingTypes; value: unknown}[]) => {
    const newSettings = cloneDeep(settings);
    newData.forEach(({ settingId, value }) => {
      newSettings[settingId].value = value;
    });
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  const handleChangeProductDataSource = (dataSource: VerificationInputTypes) => {
    handleUpdate([{
      settingId: GovernmentCheckSettingTypes.ProductSetting,
      value: {
        ...productSettings.value,
        dataSource,
      },
    }]);
  };

  const handleOpenModal = (country?: CountryCodeTypes) => {
    const defaultCountry = Object.keys(patternsConfigGroupedByCountry)[0] as CountryCodeTypes;
    createOverlay(<GovCheckModal
      initialCountry={country || defaultCountry}
      settings={settings}
      handleUpdate={handleUpdate}
    />, { sticky: true });
  };

  return (
    <>
      <Box mb={2}>
        <DataSourceSelect selectedDataSource={productSettings.dataSource} handleChangeDataSource={handleChangeProductDataSource} />
      </Box>
      <GovCheckExternalDB
        settings={settings}
        handleUpdate={handleUpdate}
        handleOpenModal={handleOpenModal}
        patternsConfigGroupedByCountry={patternsConfigGroupedByCountry}
      />
      <GovCheckAddNew handleOpenModal={handleOpenModal} />
    </>
  );
}
