import React from 'react';
import Box from '@material-ui/core/Box';
import { IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';
import { ProductSettingsWithData } from 'apps/Product';
import { useStyles } from './FlowBuilderProductDetails.styles';

export function FlowBuilderProductDetails({ flow, productId, onUpdate }: {
  flow: IFlow;
  productId: ProductTypes;
  onUpdate: (patch: Partial<IFlow>) => void;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box>
          <ProductSettingsWithData
            flow={flow}
            productId={productId}
            onUpdate={onUpdate}
          />
        </Box>
      </Box>
    </Box>
  );
}
