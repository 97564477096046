import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.main,
  },
  value: {
    paddingBottom: '5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
}));
