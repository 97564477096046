import { formatDate } from 'lib/date';
import { isNil } from 'lib/isNil';
import { InputTypes as InputsType } from './Input.model';
import { VerificationResponse } from './VerificationOld.model';

export interface IStaticTextParams {
  header?: string;
  text: string;
  isCollapsed?: boolean;
}

export enum MappingSystemFieldTypes {
  documentNumber = 'documentNumber'
}

export enum MappingEnityTypeTypes {
  person = 'person'
}

export interface IAtomicCustomField {
  placeholder?: string;
  defaultValue?: string | Date | boolean;
  type: AtomicCustomFieldTypes;
  selectOptions?: ISelectOptions[];
  mapping?: IMapping;
  regex?: string;
  value?: string;
  staticTextParams?: IStaticTextParams;
}

export interface ICustomField {
  name: string;
  type: MainCustomFieldTypes;
  isMandatory: boolean;
  label: string;
  thumbnail?: {
    publicUrl: string;
    url: string;
  };
  atomicFieldParams?: IAtomicCustomField;
  children?: ICustomField[];
  selectedGroup?: string;
}

export interface ISelectOptions {
  label: string;
  value: string;
}

export interface IMapping {
  country: string;
  key: string;
  shouldCheckFormat: boolean;
  shouldFilter: boolean;
  systemField?: MappingSystemFieldTypes;
  enityType?: MappingEnityTypeTypes;
}

export enum AtomicCustomFieldTypes {
  Text = 'text',
  Date = 'date',
  Checkbox = 'checkbox',
  Select = 'select',
  StaticText = 'static-text',
}

export enum MainCustomFieldTypes {
  Group = 'group',
  Select = 'select',
  Atomic = 'atomic',
  Separator = 'separator',
}

export interface IFlowCustomFieldsInputData {
  fields: ICustomField[];
}

export interface IVerificationCustomFieldsInputData {
  fields: ICustomField[];
  country: string;
}

export type CustomFieldDataForVerificationComponent = IVerificationCustomFieldsInputData & { isOldVerification: boolean };

// eslint-disable-next-line no-nested-ternary
export const formatedValue = (field: ICustomField, value: string): string => (field.type === MainCustomFieldTypes.Atomic && field.atomicFieldParams.type === AtomicCustomFieldTypes.Date
  ? value ? formatDate(value) : '-'
  : !isNil(value) ? `${value}` : '-');

export const getDataForVerificationComponent = (verification: VerificationResponse): CustomFieldDataForVerificationComponent | null => {
  const customFieldInput = verification?.inputs?.find((input) => input?.id === InputsType.CustomFields);
  if (!customFieldInput) {
    return null;
  }
  let isOldVerification = true;
  let { fields, country } = customFieldInput?.data;
  if (verification?.customFieldsDataCopy) {
    isOldVerification = false;
    fields = verification.customFieldsDataCopy.fields;
    country = verification.customFieldsDataCopy.country;
  }
  return {
    fields,
    country,
    isOldVerification,
  };
};

export enum MappingValueKeyTypes {
  UnifiedMultiPurposeIdUMIDSSNcrn = 'unified-multi-purpose-id.UMID_SSN.crn',
  SocialSecuritySystemCardUMIDSSNssn = 'social-security-system-card.UMID_SSN.ssn',
  NationalIdFirstName = 'national-id.firstName',
  NationalIdLastName = 'national-id.lastName',
  NationalIdDateOfBirth = 'national-id.dateOfBirth',
  NationalIdNINDocumentNumber = 'national-id.NIN.documentNumber',
  NationalIdBVNDocumentNumber = 'national-id.BVN.documentNumber',
  NationalIdVINDocumentNumber = 'national-id.VIN.documentNumber',
  CertificateOfTaxTINTin = 'certificate-of-tax.TIN.tin',
  CertificateOfIncorporationCACRcBnNumber = 'certificate-of-incorporation.CAC.rc-bn-number',
  NationalIdKTPName = 'national-id.KTP.name',
  NationalIdKTPNik = 'national-id.KTP.nik',
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  FullName = 'fullName',
  MiddleName = 'middleName',
  DrivingLicenseDLNo = 'driver-license.DL.license-no',
  DrivingLicenseDLSerialNumber = 'driver-license.DL.serial-number',
  DrivingLicenseDLExpirationDate = 'driver-license.DL.expiration-date',
  BusinessRegistrationSystemCompanyRegistrationNumber = 'business-registration-system.brs.companyRegistrationNumber',
  NationalIdIprsIdNumber = 'national-id.IPRS.idNumber',
  AlienCardIprsIdNumber = 'alien-card.IPRS.idNumber',
  PassportIprsIdNumber = 'passport.IPRS.documentNumber',
  PoliceClearanceCertificateClearanceNumber = 'police-clearance-certificate.clearance-number',
  PoliceClearanceCertificatePnpClearanceNumber = 'police-clearance-certificate.PNP.clearance-number',
  PrcProfessionalLicensePRCLicenseNumber = 'prc-professional-license.PRC.license-number',
  Profession = 'profession',
  NationalIdVirtualNINDocumentNumber = 'national-id.vNIN.virtualNinToken',
  NationalIdDniDocumentNumber = 'national-id.dni.documentNumber',
  NationalIdDniDateOfIssue = 'national-id.dni.dateOfIssue',
  NationalIdDniProcedureNumber = 'national-id.dni.procedureNumber',
  Gender = 'gender',
  RegistroCivilDocumentNumber = 'registro-civil.documentNumber',
  CompanyName = 'companyName',
  Rfc = 'rfc',
  CPF = 'cpf',
  DrivingLicenseNo = 'driver-license.documentNumber',
  DrivingLicenseExpirationDate = 'driver-license.expirationDate',
  NationalIdCICDocNumber = 'national-id.documentNumber',
  Ne = 'ne',
  Ocr = 'ocr',
  Cde = 'cde',
  Cnpj = 'cnpj',
  Curp = 'curp',
  BrazilianCNPJ = 'cnpj',
}

export interface IFlattenCustomField extends ICustomField {
  value: string;
}

export const MAIN_DROPPABLE_ID = 'MAIN_DROPPABLE_ID';

export const flattenTree = (
  items: ICustomField[],
  parent: string | null = MAIN_DROPPABLE_ID,
): IFlattenCustomField[] => {
  if (!items) {
    return [];
  }
  return items.reduce((acc, item) => {
    let flattenChildren: ICustomField[] = [];
    if (item?.children?.length) {
      flattenChildren = flattenTree(item?.children, item.name);
    }
    return [
      ...acc,
      {
        ...item,
        parent,
      },
      ...flattenChildren,
    ];
  }, []);
};

export const customFieldExample: ICustomField = {
  name: 'Document_ID',
  label: 'Document ID',
  type: MainCustomFieldTypes.Atomic,
  isMandatory: false,
  atomicFieldParams: {
    type: AtomicCustomFieldTypes.Text,
  },
};
