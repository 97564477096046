import { createReducer } from 'state/store.utils';
import { ICsvStore } from './Csv.store';
import { CsvActionTypes } from './Csv.action';

const initialState: ICsvStore = {
  isDownloading: false,
};

export const csvReducer = createReducer(initialState, {
  [CsvActionTypes.CSV_IS_DOWNLOADING](state: ICsvStore, { payload }) {
    return {
      ...state,
      isDownloading: payload,
    };
  },
});
