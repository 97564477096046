import { ButtonHeaderMenu, notification } from 'apps/ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FiLoader, FiLock, FiUnlock } from 'react-icons/fi';
import { QATags } from 'models/QA.model';
import { useFormatMessage } from 'apps/intl';
import { ErrorMessagesTokenTypes } from 'models/Error.model';
import * as client from '../../client/Verification.client';
import { useStyles } from './VerificationRetryBlockerButton.styles';

function VerificationRetryBlockerButton({ verificationId, retriesEnabled, retryBlockerId }: {
  verificationId: string;
  retriesEnabled: boolean;
  retryBlockerId: string;
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean| undefined>();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    setEnabled(retriesEnabled);
  }, [retriesEnabled]);

  const updateRetryBlocker = async () => {
    try {
      setIsLoading(true);
      const updatedRetriesEnabled = !enabled;
      await client.patchVerificationRetryBlocker(verificationId, {
        retryBlockerId,
        enabled: updatedRetriesEnabled,
      });
      setEnabled(updatedRetriesEnabled);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
    }
  };

  return (
    <ButtonHeaderMenu
      variant="contained"
      onClick={updateRetryBlocker}
      startIcon={isLoading ? <FiLoader /> : (
        (() => {
          if (enabled) {
            return <FiLock />;
          }
          return <FiUnlock />;
        })()
      )}
      disabled={isLoading}
      className={classNames(classes.button, enabled ? classes.retriesBlocked : classes.retriesUnBlocked)}
      data-qa={QATags.Verification.Buttons.Delete}
    >
      {formatMessage(
        enabled
          ? 'verificationModal.blocked'
          : 'verificationModal.unBlocked',
      )}
    </ButtonHeaderMenu>
  );
}

export default VerificationRetryBlockerButton;
