import { IFlow } from 'models/Flow.model';
import { DataSourceValueTypes } from 'models/Watchlist.model';
import { DEFAULT_AML_FUZZINESS_THRESHOLD, premiumAmlWatchlistsInitialValue, basicWatchlistsInitialValue } from 'models/Aml.model';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { FiDollarSign } from 'react-icons/fi';
import { DocumentStepTypes, getStepStatus, StepStatus, VerificationStepTypes } from 'models/Step.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { AmlSettings } from '../components/AmlSettings/AmlSettings';
import { AmlVerificationProduct } from '../components/AmlVerificationProduct/AmlVerificationProduct';
import { AmlCheckTypes, AmlDocumentSteps, AmlSettingsTypes, AmlValidationTypes, SearchModeTypes, WebhookVersionTypes } from '../models/Aml.model';

type ProductSettingsAml = ProductSettings<AmlSettingsTypes>;

export class AmlCheck extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.AmlCheck;
  order = 400;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  icon = FiDollarSign;
  inputs = [
    ProductInputTypes.NameAndDobOrDocument,
  ];
  checks = [
    {
      id: AmlCheckTypes.Watchlist,
      isActive: true,
    },
    {
      id: AmlCheckTypes.Search,
      isActive: true,
    },
    {
      id: AmlCheckTypes.Monitoring,
      isActive: true,
    },
  ];
  requiredProductType = [ProductTypes.DocumentVerification, ProductTypes.CustomField];
  component = AmlSettings;
  componentVerification = AmlVerificationProduct;

  parser(flow: IFlow): ProductSettingsAml {
    const legacyPattern = flow?.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsSearchValidation];
    const newPattern = flow?.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsValidation];

    return {
      [AmlSettingsTypes.Search]: {
        value: legacyPattern !== AmlValidationTypes.None || newPattern !== AmlValidationTypes.None,
      },
      [AmlSettingsTypes.Monitoring]: {
        value: legacyPattern === AmlValidationTypes.SearchMonitoring || newPattern === AmlValidationTypes.SearchMonitoring,
      },
      [AmlSettingsTypes.BasicWatchlistsPattern]: {
        value: flow?.verificationPatterns[VerificationPatternTypes.Watchlists],
      },
      [AmlSettingsTypes.BasicWatchlists]: {
        value: flow?.watchlists?.[AmlSettingsTypes.BasicWatchlists]?.list ?? [],
      },
      [AmlSettingsTypes.AmlThreshold]: {
        value: flow?.watchlists?.premiumAml?.fuzzinessThreshold ?? DEFAULT_AML_FUZZINESS_THRESHOLD,
      },
      [AmlSettingsTypes.CountriesSearched]: {
        value: flow?.watchlists?.premiumAml?.countryCodes ?? [],
      },
      [AmlSettingsTypes.SearchMode]: {
        value: flow?.watchlists?.premiumAml?.exactMatch ? SearchModeTypes.Exact : SearchModeTypes.Fuzzy,
      },
      [AmlSettingsTypes.EntitySearch]: {
        value: flow?.watchlists?.premiumAml?.entitySearch,
      },
      [AmlSettingsTypes.DataSource]: {
        value: flow?.watchlists?.[AmlSettingsTypes.BasicWatchlists]?.dataSource ?? DataSourceValueTypes.DocumentVerification,
      },
      [AmlSettingsTypes.WebhookVersion]: {
        value: flow?.verificationPatterns[VerificationPatternTypes.PremiumAmlWatchListsSearchValidation] !== AmlValidationTypes.None ? WebhookVersionTypes.Legacy : WebhookVersionTypes.New,
      },
      [AmlSettingsTypes.Sanctions]: {
        value: flow?.watchlists?.premiumAml?.types ?? [],
      },
    };
  }

  serialize(settings: ProductSettingsAml): Partial<IFlow> {
    let pattern = AmlValidationTypes.None;
    const patternType = settings[AmlSettingsTypes.WebhookVersion].value === WebhookVersionTypes.Legacy ? VerificationPatternTypes.PremiumAmlWatchListsSearchValidation : VerificationPatternTypes.PremiumAmlWatchListsValidation;

    if (settings[AmlSettingsTypes.Search].value) {
      pattern = AmlValidationTypes.Search;
    }

    if (settings[AmlSettingsTypes.Monitoring].value) {
      pattern = AmlValidationTypes.SearchMonitoring;
    }

    return {
      verificationPatterns: {
        [VerificationPatternTypes.PremiumAmlWatchListsSearchValidation]: patternType === VerificationPatternTypes.PremiumAmlWatchListsSearchValidation ? pattern : AmlValidationTypes.None,
        [VerificationPatternTypes.PremiumAmlWatchListsValidation]: patternType === VerificationPatternTypes.PremiumAmlWatchListsValidation ? pattern : AmlValidationTypes.None,
        [VerificationPatternTypes.Watchlists]: settings[AmlSettingsTypes.BasicWatchlistsPattern].value,
      },
      watchlists: {
        [AmlSettingsTypes.BasicWatchlists]: {
          list: settings[AmlSettingsTypes.BasicWatchlists].value,
          dataSource: settings[AmlSettingsTypes.DataSource].value,
        },
        premiumAml: {
          fuzzinessThreshold: settings[AmlSettingsTypes.AmlThreshold].value,
          countryCodes: settings[AmlSettingsTypes.CountriesSearched].value,
          exactMatch: settings[AmlSettingsTypes.SearchMode].value === SearchModeTypes.Exact,
          entitySearch: Boolean(settings[AmlSettingsTypes.EntitySearch].value),
          dataSource: settings[AmlSettingsTypes.DataSource].value,
          types: settings[AmlSettingsTypes.Sanctions].value,
        },
      },
    };
  }

  onAdd(): Partial<IFlow> {
    return {
      verificationPatterns: {
        [VerificationPatternTypes.PremiumAmlWatchListsSearchValidation]: AmlValidationTypes.None,
        [VerificationPatternTypes.PremiumAmlWatchListsValidation]: AmlValidationTypes.None,
        [VerificationPatternTypes.Watchlists]: false,
      },
      watchlists: {
        basic: basicWatchlistsInitialValue,
        premiumAml: premiumAmlWatchlistsInitialValue,
      },
    };
  }

  onRemove(): Partial<IFlow> {
    return {
      verificationPatterns: {
        [VerificationPatternTypes.PremiumAmlWatchListsSearchValidation]: AmlValidationTypes.None,
        [VerificationPatternTypes.PremiumAmlWatchListsValidation]: AmlValidationTypes.None,
        [VerificationPatternTypes.Watchlists]: false,
      },
      watchlists: {
        basic: basicWatchlistsInitialValue,
        premiumAml: premiumAmlWatchlistsInitialValue,
      },
    };
  }

  isInFlow(flow: IFlow): boolean {
    return (flow?.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsSearchValidation] && flow.verificationPatterns[VerificationPatternTypes.PremiumAmlWatchListsSearchValidation] !== AmlValidationTypes.None)
     || (flow?.verificationPatterns?.[VerificationPatternTypes.PremiumAmlWatchListsValidation] && flow.verificationPatterns[VerificationPatternTypes.PremiumAmlWatchListsValidation] !== AmlValidationTypes.None)
      || (flow?.verificationPatterns?.[VerificationPatternTypes.Watchlists]);
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const failedStatuses = [StepStatus.Failure, StepStatus.Incomplete];

    const isLegacyPremiumAmlFailed = verification.documents?.some((document) => document?.steps?.filter((step) => AmlDocumentSteps.includes(step?.id))
      .map((step) => getStepStatus(step))
      .some((status) => failedStatuses.includes(status)));

    const isPremiumAmlFailed = verification.steps?.filter((step) => step.id === VerificationPatternTypes.PremiumAmlWatchListsValidation)
      .map((step) => getStepStatus(step))
      .some((status) => failedStatuses.includes(status));

    const isBasicWatchlistsFailed = verification.steps?.filter((step) => step.id === VerificationPatternTypes.Watchlists)
      .map((step) => getStepStatus(step))
      .some((status) => failedStatuses.includes(status));

    return isLegacyPremiumAmlFailed || isPremiumAmlFailed || isBasicWatchlistsFailed;
  }

  getVerification(verification: VerificationResponse): VerificationResponse {
    return verification;
  }

  isInVerification(verification: VerificationResponse): boolean {
    return verification.steps?.some((dataStep) => dataStep.id === VerificationStepTypes.Watchlists)
      || verification.steps?.some((dataStep) => dataStep.id === VerificationPatternTypes.PremiumAmlWatchListsValidation)
      || verification.documents?.some((document) => document?.steps?.some((step) => step.id === DocumentStepTypes.PremiumAmlWatchlistsCheck));
  }
}
