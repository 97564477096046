import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CopyToClipboard } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { permalinkUrl } from 'lib/client/urls';
import { Routes } from 'models/Router.model';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { selectClientId, selectCurrentFlowId } from 'state/merchant/merchant.selectors';
import classnames from 'classnames';
import { LimiterSubscriptionStatus, PermissionSubscriptionStatusTypes, useHasPermissionSubscription } from 'apps/Permissions';
import { useStyles } from './DirectLinkCopy.styles';

export function DirectLinkCopy() {
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const classes = useStyles();
  const clientId = useSelector(selectClientId);
  const flowId = useSelector(selectCurrentFlowId);
  const hasPermission = useHasPermissionSubscription(PermissionSubscriptionStatusTypes.canCopyDirectLink);
  const link = permalinkUrl({
    clientId: hasPermission ? clientId : '',
    flowId: hasPermission ? flowId : '',
  });

  return (
    <Grid container direction="column">
      <Typography variant="subtitle2" gutterBottom>{formatMessage('forDevs.directLinkCopy.header')}</Typography>
      <Box mb={2} color="common.black75">
        {formatMessage('forDevs.directLinkCopy.subheader')}
      </Box>
      <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canCopyDirectLink}>
        <Box mb={2} className={classnames(classes.resourceUrl, { [classes.disabled]: !hasPermission })}>
          <CopyToClipboard withCopy={hasPermission} withCopyText={hasPermission} isOverlay text={link}>
            <Box px={2} py={1.5}>
              {link}
            </Box>
          </CopyToClipboard>
        </Box>
      </LimiterSubscriptionStatus>
      <Box mb={2} display="flex" justifyContent={location.pathname === Routes.dev.root ? 'flex-start' : 'center'}>
        <QRCode value={link} />
      </Box>
    </Grid>
  );
}
