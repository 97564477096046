import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CheckBarExpandable } from 'apps/checks';
import { useFormatMessage } from 'apps/intl';
import { formatCurrency } from 'lib/currency';
import classNames from 'classnames';
import { useStyles } from './BankAccountVerificationChecks.styles';
import { BankLogoError, BankLogoSuccess, BankLogoUnconfigured } from '../../assets';
import { BankAccountChecksStatusTypes, BankAccountTransactionCheckTypes, IBankAccountDataVerificationChecks } from '../../models/BankAccountData.model';

export function BankAccountVerificationChecks({ checks }: {
  checks: IBankAccountDataVerificationChecks;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const checksStatus = useMemo(() => {
    if (!checks || checks?.checksInfo.length === 0) {
      return BankAccountChecksStatusTypes.Unconfigured;
    }

    const isChecksFailed = checks?.checksInfo?.find((check) => {
      const title = Object.keys(check)[0];
      return check[title]?.success === false;
    });

    if (isChecksFailed) {
      return BankAccountChecksStatusTypes.Invalid;
    }

    return BankAccountChecksStatusTypes.Valid;
  }, [checks]);

  const checksLogoRender = () => {
    if (checksStatus === BankAccountChecksStatusTypes.Unconfigured) {
      return <BankLogoUnconfigured className={classes.bankLogo} />;
    }

    if (checksStatus === BankAccountChecksStatusTypes.Invalid) {
      return <BankLogoError className={classes.bankLogo} />;
    }

    return <BankLogoSuccess className={classes.bankLogo} />;
  };

  return (
    <>
      <Grid container wrap="nowrap" className={classes.validityBox}>
        <Grid item>{checksLogoRender()}</Grid>
        <Grid item>
          <Box mr={2} ml={2}>
            <Typography
              variant="h4"
              className={classNames({
                [classes.isSuccess]: checksStatus === BankAccountChecksStatusTypes.Valid,
                [classes.isError]: checksStatus === BankAccountChecksStatusTypes.Invalid,
                [classes.isUnconfigured]: checksStatus === BankAccountChecksStatusTypes.Unconfigured,
              })}
            >
              {formatMessage(`BankAccountData.checks.${checksStatus}Bank.title`)}
            </Typography>
            <Typography variant="subtitle2" className={classes.validitySubtitle}>
              {formatMessage(`BankAccountData.checks.${checksStatus}Bank.subtitle`)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {checks?.checksInfo?.map((check) => {
        const title = Object.keys(check)[0];
        return (
          <Box ml={2} mr={2} key={title}>
            <CheckBarExpandable
              title={`BankAccountData.checks.${title}.title`}
              isError={!check[title]?.success}
              tooltipMessage={formatMessage(`BankAccountData.checks.${title}.info`)}
              isNoBadge
            >
              <Card raised={false} className={classes.card}>
                <CardContent>
                  <Box mb={2} className="fs-exclude">
                    {formatCurrency(check[title]?.resultingValue, checks?.currency)}
                    {' '}
                    (
                    {formatMessage([BankAccountTransactionCheckTypes.AverageExpenses, BankAccountTransactionCheckTypes.TotalWithdrawals, BankAccountTransactionCheckTypes.AverageLoanRepayment].includes(title as BankAccountTransactionCheckTypes)
                      ? 'BankAccountData.checks.maxAllowed' : 'BankAccountData.checks.minNeeded', {
                      messageValues: {
                        minAmount: check[title]?.merchantValue,
                        maxAmount: check[title]?.merchantValue,
                        currency: checks?.currency,
                      },
                    })}
                    )
                  </Box>
                </CardContent>
              </Card>
            </CheckBarExpandable>
          </Box>
        );
      })}
    </>
  );
}
