import { GDPRRanges } from 'models/GDPR.model';
import { useFormatMessage } from 'apps/intl';
import { DaysRangeErrorTypes } from '../models/FlowBuilder.model';

export function useValidatePolicyInterval() {
  const formatMessage = useFormatMessage();
  return (value: number, range: {from: number; to: number} = GDPRRanges.default): string => {
    const { from, to } = range;
    if (!value) {
      return formatMessage(`FlowBuilder.settings.gdpr.input.error.${DaysRangeErrorTypes.empty}`);
    }
    if (value < from) {
      return formatMessage(`FlowBuilder.settings.gdpr.input.error.${DaysRangeErrorTypes.minDays}`, { messageValues: { min: from } });
    }
    if (value < from || value > to) {
      return formatMessage(`FlowBuilder.settings.gdpr.input.error.${DaysRangeErrorTypes.outOfRange}`, { messageValues: { min: from, max: to } });
    }
    return null;
  };
}
