import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Routes } from 'models/Router.model';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigation, NavigationEventTypes } from 'apps/layout';
import { useLocation, useHistory } from 'react-router-dom';
import { getGoBackToListLink } from 'models/Identity.model';
import { Loadable } from 'models/Loadable.model';
import { IFlow } from 'models/Flow.model';
import { flowNameValidator } from 'lib/validations';
import { selectMerchantFlowsModel } from 'state/merchant/merchant.selectors';
import { toggleUnsavedChanges } from '../../store/FlowBuilder.action';
import { FlowHeader } from '../FlowHeader/FlowHeader';
import { Button, Icon } from 'lib/UI';

export function FlowInfoHeader({ newFlowName, setNewFlowName, handleIntegrate, handleCustomize }: {
  newFlowName?: string;
  setNewFlowName?: (name: string) => void;
  handleIntegrate?: () => void;
  handleCustomize?: () => void;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const location = useLocation();
  const merchantFlowsModel = useSelector<any, Loadable<IFlow[]>>(selectMerchantFlowsModel);
  const goBackToListLink = useMemo(() => getGoBackToListLink(location, Routes.flow.root), [location]);

  const validator = useCallback((text: string) => {
    const duplicate = merchantFlowsModel.value.find((item) => item.name === text.trim());
    return flowNameValidator({ hasDuplicate: !!duplicate, name: text });
  }, [merchantFlowsModel.value]);

  const handleSubmit = (name: string) => {
    setNewFlowName(name);
    dispatch(toggleUnsavedChanges(true));
  };

  const handleCancel = () => {
    setIsEditable(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      gridGap="5px"
      alignItems="center"
      p="8px 10px"
    >
      <Button
        type="icon"
        onClick={() => {
          const interrupted = navigation.trigger(NavigationEventTypes.BeforeNavigation, () => history.push(goBackToListLink));
          if (!interrupted) {
            history.push(goBackToListLink);
          }
        }}
      >
        <Icon name="backarrow" />
      </Button>
      <FlowHeader
        canEdit
        isEditable={isEditable}
        newFlowName={newFlowName}
        setIsEditable={setIsEditable}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        validator={validator}
        handleIntegrate={handleIntegrate}
        handleCustomize={handleCustomize}
      />
    </Box>
  );
}
