import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationDocument } from './Document.model';
import { DocumentStepTypes, IStep } from './Step.model';

export interface IBadCheck {
  message?: string;
  dateOfOccurrence?: string;
  checkNumber?: string;
  checkClause?: string;
  ccfQuantity?: string;
  checkAmount?: string;
  bankNumber?: string;
  bankName?: string;
  agencyNumber?: string;
  agencyCity?: string;
  agencyState?: string;
  cadusCode?: string;
}

export interface IActiveDebt {
  message?: string;
  dateOfOccurrence?: string;
  currencyType?: string;
  amount?: string;
  registryOffice?: string;
  city?: string;
  stateCode?: string;
}

export interface IExpiredDebt {
  message?: string;
  dateOfOccurrence?: string;
  modality?: string;
  guarantor?: string;
  currencyType?: string;
  amount?: string;
  contractId?: string;
  origin?: string;
  embratelCode?: string;
}

export interface CreditCheckStep {
  age: number;
  stepExtra: [];
  badChecks?: IBadCheck[];
  activeDebts?: IActiveDebt[];
  expiredDebts?: IExpiredDebt[];
  creditScore: number | 'NA';
  incomeCommitment?: number;
  paymentCapacity?: number;
  estimatedIncome?: number;
  dateOfBirth: string;
  documentNumber: string;
  fullName: string;
  gender: string;
  taxpayerNumber: string;
  paymentPunctuality?: number;
  errorMessage?: string;
}
export const CreditCheckStepTypes = {
  incomeCommitment: 'incomeCommitment',
  paymentCapacity: 'paymentCapacity',
  estimatedIncome: 'estimatedIncome',
  errorMessage: 'errorMessage',
  paymentPunctuality: 'paymentPunctuality',
};

export interface IDataForCreditCheck {
  creditStep: IStep<CreditCheckStep>;
  verification: VerificationResponse;
  id: string;
}

export interface CreditCheckManulRunResponse {
  _id: string;
  documents: VerificationDocument[];
}

export const creditCheckDisplayOptions = {
  [DocumentStepTypes.CreditArgentinianFidelitas]: {
    stepExtra: {
      hidden: true,
    },
  },
  [DocumentStepTypes.CreditBrazilianSerasa]: {
    stepExtra: {
      hidden: true,
    },
  },
  [DocumentStepTypes.CreditBrazilianSerasaChecks]: {
    stepExtra: {
      hidden: true,
    },
  },
  [DocumentStepTypes.CreditColombiaTransunion]: {
    stepExtra: {
      hidden: true,
    },
  },
};

export const propsListToBeExcludedFromPDF = [CreditCheckStepTypes.errorMessage];
export const propsListToBeExcluded = [CreditCheckStepTypes.incomeCommitment, CreditCheckStepTypes.paymentCapacity, CreditCheckStepTypes.estimatedIncome, CreditCheckStepTypes.errorMessage, CreditCheckStepTypes.paymentPunctuality];
