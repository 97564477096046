import { Box } from '@material-ui/core';
import React from 'react';
import { ImageContainer } from 'apps/media';
import { useStyles } from './CheckStepDetailsImage.styles';

export const CheckStepDetailsImage = (label: string, value: unknown) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      <ImageContainer src={value as string} className={classes.groupImage} alt={label} />
    </Box>
  );
};
