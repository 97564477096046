import { ESignatureDocumentId, getAcceptanceCriteria, IESignatureFlow } from 'models/ESignature.model';
import sampleContractPdf from '../assets/sample-contract.pdf';

const MEDIA_SERVER_TEMPLATE_FOLDER = 'template-document';

/* Backend works only with two fields: fileName and folder.
 * They are used to find a correct reference in the file system.
 * FileNames vary in different environments, template-document folder name is same.
 * PublicUrl field is regenerated on every request.
 */
export function getESignatureExample(): Nullable<IESignatureFlow> {
  const fileName = process?.env?.REACT_APP_MEDIA_ESIGNATURE_DEFAULT_DOCUMENT_FILENAME;
  if (!fileName) {
    return null;
  }

  const id: ESignatureDocumentId = '00000000-0000-0000-0000-000000000000'; // backend validation: valid guid
  return {
    templates: {
      order: [id],
      list: [{
        id,
        originalDocument: {
          fileName,
          folder: MEDIA_SERVER_TEMPLATE_FOLDER,
          originalFileName: 'Sample contract',
          publicUrl: sampleContractPdf,
          url: 'notEmptyUrl', // backend validation: not empty string
        },
      }],
    },
    acceptanceCriteria: getAcceptanceCriteria(),
  };
}
