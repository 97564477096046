import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantId } from 'state/merchant';
import { selectUserEmail, selectUserFullName } from 'apps/user/state/user.selectors';
import { isProduction } from 'models/Environment.model';

declare global {
  interface Window {
    fcWidget: any;
    fcSettings: any;
  }
}

export function useFreshworks() {
  const shouldLoadFreshworks = isProduction || process.env.REACT_APP_LOAD_FRESHDESK_LOCALLY;
  const merchantId = useSelector(selectMerchantId);
  const name = useSelector(selectUserFullName) || ' ';
  const email = useSelector(selectUserEmail);

  const freshworksSettings = useMemo(() => ({
    onInit() {
      window.fcWidget.setExternalId(merchantId);
      window.fcWidget.user.setFirstName(name);
      window.fcWidget.user.setEmail(email);
    },
  }), [merchantId, name, email]);

  useEffect(() => {
    if (shouldLoadFreshworks && merchantId && email) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://fw-cdn.com/10779879/3562027.js';
      script.setAttribute('chat', 'true');
      const checkAvailability = setInterval(() => {
        if (window.fcWidget) {
          freshworksSettings.onInit();
          clearInterval(checkAvailability);
        }
      }, 1000);
      document.head.appendChild(script);
    }
  }, [email, freshworksSettings, merchantId, shouldLoadFreshworks]);
}
