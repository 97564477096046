import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProductTypes } from 'models/Product.model';
import { selectFlowBuilderSelectedId } from 'apps/flowBuilder';
import { ReactComponent as BankIcon } from '../../assets/Bank.svg';
import { useStyles } from './GovCheckModalHeader.styles';

export function GovCheckModalHeader() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const product = useSelector<any, ProductTypes>(selectFlowBuilderSelectedId);
  return (
    <Box display="flex" flexDirection="row">
      <Box className={classes.iconWrapper} color="common.black50">
        <BankIcon />
      </Box>
      <Box ml={1.5} mb={2}>
        <Typography variant="h4">
          {formatMessage('GovCheckMerit.settings.modal.title', { messageValues: { product: formatMessage(`${product}.card.title`) } })}
        </Typography>
        <Box color="common.black75" mt={1}>
          <Typography variant="body1">
            {formatMessage('GovCheckMerit.settings.modal.subTitle')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
