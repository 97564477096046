import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import type { IStep } from 'models/Step.model';
import { getBrazilChecks, IBackgroundCheckStepData, brazilSummaryFieldsOrder, StructureSection } from 'models/BackgroundCheck.model';
import ReactJsonViewer from 'react-json-view';
import { BackgroundCheckSummary } from '../BackgroundCheckSummary/BackgroundCheckSummary';
import { BackgroundChecksError } from '../BackgroundChecksError/BackgroundChecksError';
import { BackgroundCheckInProgress } from '../BackgroundCheckInProgress/BackgroundCheckInProgress';
import { BackgroundCheckControls } from '../BackgroundCheckControls/BackgroundCheckControls';
import { useBackgroundCheckParams } from '../../hooks/backgroundCheck.hook';
import { BackgroundCheckStructure } from '../BackgroundCheckStructure/BackgroundCheckStructure';
import { BrazilRecordViewPassed } from './components/BrazilRecordViewPassed/BrazilRecordViewPassed';
import { BrazilRecordViewFailed } from './components/BrazilRecordViewFailed/BrazilRecordViewFailed';
import { BrazilRecordViewUnreachable } from './components/BrazilRecordViewUnreachable/BrazilRecordViewUnreachable';

export function BackgroundChecksBrazilianChecks({ step }: { step: IStep<IBackgroundCheckStepData> }) {
  const {
    isJsonVisible,
    setJsonVisible,
    isInProgress,
    couldBeRunManually,
  } = useBackgroundCheckParams(step);

  const records = getBrazilChecks(step.data?.results || []);

  useEffect(() => {
    setJsonVisible(!step.data?.results?.length);
  }, [step.data?.results, setJsonVisible]);

  if (couldBeRunManually) {
    return null;
  }

  if (isInProgress) return <BackgroundCheckInProgress />;

  if (step.error && !step.data) return <BackgroundChecksError errorCode={step.error.code} />;

  const sections: StructureSection[] = [
    {
      titleKey: 'BackgroundCheck.verification.jsonView.failedChecks',
      items: records.failed,
      component: BrazilRecordViewFailed,
    },
    {
      titleKey: 'BackgroundCheck.verification.jsonView.passedChecks',
      items: records.passed,
      component: BrazilRecordViewPassed,
    },
    {
      titleKey: 'BackgroundCheck.verification.jsonView.unreachableChecks',
      items: records.unreachable,
      component: BrazilRecordViewUnreachable,
    },
  ];

  return (
    <Grid container spacing={2}>
      <BackgroundCheckSummary step={step} fieldsOrder={brazilSummaryFieldsOrder} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BackgroundCheckControls
            step={step}
            isVisible={isJsonVisible}
            onSetVisibility={setJsonVisible}
          />
          {isJsonVisible && (
            <ReactJsonViewer
              src={step}
              collapsed
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          )}
          {!isJsonVisible && <BackgroundCheckStructure sections={sections} />}
        </Grid>
      </Grid>
    </Grid>
  );
}
