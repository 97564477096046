import React, { DOMAttributes, ReactNode } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['mtm-tooltip']: DOMAttributes<HTMLElement> & {
        tooltip?: ReactNode;
        bias?: string;
      };
    }
  }
}

export function Tooltip({ tooltip, bias, children }: {
  tooltip?: ReactNode;
  bias?: 'top' | 'right' | 'bottom' | 'left';
  children?: ReactNode;
}) {
  return (
    <mtm-tooltip
      tooltip={tooltip}
      bias={bias}
    >
      {children}
      {tooltip && (
        <div slot="tooltip">
          {tooltip}
        </div>
      )}
    </mtm-tooltip>
  );
}
