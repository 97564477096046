import React, { Ref } from 'react';
import { useStyles } from './Toolbar.style';

export const Toolbar = React.forwardRef(({ ...props }: {
  [key: string]: unknown;
}, ref: Ref<Nullable<HTMLDivElement>>) => {
  const classes = useStyles();
  return (
    <div
      {...props}
      ref={ref}
      className={classes.toolbar}
    />
  );
});
