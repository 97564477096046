import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  settingsButton: {
    position: 'absolute',
    margin: 20,
    bottom: -6,
    borderColor: theme.palette.common.lightblue,
    color: theme.palette.common.lightblue,
    minWidth: 420,
    background: theme.palette.common.white,
    height: 43,
    minHeight: 43,
    marginRight: 10,
    fontSize: 14,
    fontWeight: 'bold',
    width: '20%',
    '& svg': {
      marginRight: 5,
    },
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette.common.whiteblue,
    },
  },
}));
