import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    marginBottom: 16,
  },
  header: {
    color: theme.palette.text.main,
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
  },
  toggleLabel: {
    fontSize: '13px',
    padding: '6px 8px',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const DownloadJsonButton = withStyles((theme) => ({
  root: {
    fontSize: 12,
    padding: [[3, 10]] as any,
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.main,
    '&:hover': {
      backgroundColor: theme.palette.common.black7,
    },
  },
  label: {
    paddingLeft: 5,
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
  },
  startIcon: {
    width: 17,
    marginRight: 10,
  },
}))(Button) as typeof Button;
