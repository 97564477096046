import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px',
    gap: '12px',
    backgroundColor: theme.palette.common.athensgray,
    border: `1px solid ${theme.palette.common.black7}`,
    borderRadius: '8px',
  },
}));
