import cloneDeep from 'lodash/cloneDeep';
import { RegionsTypes } from 'models/Country.model';
import { VerificationDocument } from 'models/Document.model';
import { IFlow, ISettings } from 'models/Flow.model';
import { GovernmentChecksStep, GovernmentChecksStepTitleOptions } from 'models/GovernmentChecksMerit.model';
import { ProductSettings, ProductSettingsProps, ProductTypes } from 'models/Product.model';
import { getErrorProperties, IFlattenVerificationPatternConfig, isPatternActive, isSubPatternActive, IVerificationPatternConfig, PatternErrorType, VerificationConfigurationErrorTypes, VerificationInputTypes, VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { FiFileText } from 'react-icons/fi';
import { IVerificationPatterns, VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { MessageDescriptor } from 'apps/intl';
import { GovCheckStepTypes, VerificationStepTypes } from 'models/Step.model';

export type LegalSearchRecord = {
  region: string;
  involvedParties: string;
  processId: number;
  processNumber: string;
  openedAt: string;
  updatedAt: string;
  processClass: string;
  processType: string;
}

export enum GovernmentCheckSettingTypes {
  PatternsConfig = 'patternsConfig',
  PatternSettings = 'patternSettings',
  ProductSetting = 'ProductSetting',
  VerificationPatterns = 'verificationPatterns',
  AgeThreshold = 'ageThreshold',
}

export const verificationPatternsGovchecksDefault = {
  [VerificationPatternTypes.ArgentinianDni]: false,
  [VerificationPatternTypes.ArgentinianRenaperPremium]: false,
  [VerificationPatternTypes.ArgentinianRenaper]: GovCheckStepTypes.None,
  [VerificationPatternTypes.ArgentinianRenaperExtended]: false,
  [VerificationPatternTypes.ArgentinianRenaperFacematch]: false,
  [VerificationPatternTypes.BolivianOep]: false,
  [VerificationPatternTypes.BrazilianCpf]: GovCheckStepTypes.None,
  [VerificationPatternTypes.BrazilianCpfLight]: false,
  [VerificationPatternTypes.ChileanRut]: false,
  [VerificationPatternTypes.ChileanDriverLicense]: false,
  [VerificationPatternTypes.ChileanRegistroCivil]: false,
  [VerificationPatternTypes.ColombianUnifiedLegalSearch]: false,
  [VerificationPatternTypes.ColombianBdua]: false,
  [VerificationPatternTypes.ColombianContraloria]: false,
  [VerificationPatternTypes.ColombianMigraciones]: false,
  [VerificationPatternTypes.ColombianNationalPolice]: false,
  [VerificationPatternTypes.ColombianNit]: false,
  [VerificationPatternTypes.ColombianProcuraduria]: false,
  [VerificationPatternTypes.ColombianRegistraduria]: false,
  [VerificationPatternTypes.ColombianRunt]: false,
  [VerificationPatternTypes.ColombianSisben]: false,
  [VerificationPatternTypes.CostaRicanAtv]: false,
  [VerificationPatternTypes.CostaRicanTse]: false,
  [VerificationPatternTypes.CostaRicanSocialSecurity]: false,
  [VerificationPatternTypes.DominicanJce]: false,
  [VerificationPatternTypes.DominicanRnc]: false,
  [VerificationPatternTypes.EcuadorianRegistroCivil]: false,
  [VerificationPatternTypes.EcuadorianSri]: false,
  [VerificationPatternTypes.GhanaianGra]: false,
  [VerificationPatternTypes.GuatemalanTse]: false,
  [VerificationPatternTypes.HonduranRnp]: false,
  [VerificationPatternTypes.IndonesianKPTValidation]: GovCheckStepTypes.None,
  [VerificationPatternTypes.KenyanEcitizen]: false,
  [VerificationPatternTypes.KenyanIprs]: false,
  [VerificationPatternTypes.MexicanCurp]: false,
  [VerificationPatternTypes.MexicanIne]: false,
  [VerificationPatternTypes.MexicanPep]: false,
  [VerificationPatternTypes.MexicanRfc]: false,
  [VerificationPatternTypes.NigerianCac]: false,
  [VerificationPatternTypes.NigerianShareholdersCac]: false,
  [VerificationPatternTypes.NigerianDl]: false,
  [VerificationPatternTypes.NigerianNin]: false,
  [VerificationPatternTypes.NigerianVirtualNin]: false,
  [VerificationPatternTypes.NigerianVin]: false,
  [VerificationPatternTypes.NigerianBvn]: false,
  [VerificationPatternTypes.NigerianAv]: false,
  [VerificationPatternTypes.KenyanBRC]: false,
  [VerificationPatternTypes.NigerianTin]: false,
  [VerificationPatternTypes.ParaguayanRcp]: false,
  [VerificationPatternTypes.PeruvianReniec]: false,
  [VerificationPatternTypes.PeruvianHealthSocialSecurity]: false,
  [VerificationPatternTypes.PeruvianSunat]: false,
  [VerificationPatternTypes.PhilippineNbi]: false,
  [VerificationPatternTypes.PhilippineUMIDSSS]: false,
  [VerificationPatternTypes.PhilippineDl]: false,
  [VerificationPatternTypes.PhilippinePNP]: false,
  [VerificationPatternTypes.SalvadorianTse]: false,
  [VerificationPatternTypes.PanamenianTribunalElectoral]: false,
  [VerificationPatternTypes.VenezuelanCne]: false,
  [VerificationPatternTypes.VenezuelanSeniat]: false,
  [VerificationPatternTypes.UgandanElectoralCommission]: false,
  [VerificationPatternTypes.PeruvianMigrationInstitute]: false,
  [VerificationPatternTypes.BrazilianNoCriminalRecordsValidation]: false,
  [VerificationPatternTypes.ColombianRegistraduriaLight]: false,
  [VerificationPatternTypes.EcuadorianSocialSecurity]: false,
  [VerificationPatternTypes.PhilippinePrcValidation]: false,
  [VerificationPatternTypes.BrazilianCnpj]: false,
  [VerificationPatternTypes.MexicanRfcStatus]: false,
  [VerificationPatternTypes.BrazilianCNPJExtended]: false,
};

export type ProductSettingsGovCheck = ProductSettings<GovernmentCheckSettingTypes>;

export type ProductSettingsPropsGovCheck = ProductSettingsProps<GovernmentCheckSettingTypes>;

export enum GovernmentChecksTypes {
  GovernmentDatabaseCheck = 'governmentDatabaseCheck',
}

export const GovernmentChecksPatterns = [
  VerificationPatternTypes.NigerianCac,
];

export const IconByProductId = {
  [ProductTypes.DrivingLicenseValidation]: FiFileText,
  [ProductTypes.TaxChecks]: FiFileText,
  [ProductTypes.LegalRecords]: FiFileText,
  [ProductTypes.EmploymentInformation]: FiFileText,
};

export interface IGovernmentChecksVerificationData {
  document: VerificationDocument[];
  rootSteps: GovernmentChecksStep[];
}

export const GovernmentChecksRegionsOrder = [
  RegionsTypes.LatinAmerica,
  RegionsTypes.Africa,
  RegionsTypes.SouthEastAsia,
];

export const GovCheckNonDataSourceDependents = {
  [VerificationPatternTypes.NigerianAv]: [ProductTypes.PhoneCheck],
};

export const getDefaultSettings = (verificationPatternConfigs: IVerificationPatternConfig): ISettings => ({
  ...(verificationPatternConfigs.isAgeCheckAllowed ? { ageCheckOn: false } : {}),
  ...(verificationPatternConfigs.fuzzyNameMatch ? { fuzzyNameMatchThreshold: verificationPatternConfigs.defaultFuzzyNameMatchThreshold } : {}),
  ...(verificationPatternConfigs.inputs.length > 1 ? { dataSource: null } : {}),
});

export function parseGovCheckPatternConfig(verificationPatterns: Partial<IVerificationPatterns>, flow: IFlow, productsInGraph: ProductTypes[], productSettings: ISettings, patternName: string, patternConfigs: IVerificationPatternConfig[]): IFlattenVerificationPatternConfig {
  const clone = cloneDeep(patternConfigs);

  const mainConfig = clone.splice(0, 1)[0];
  const patternSetting = flow.patternSettings?.[patternName] || getDefaultSettings(mainConfig);

  const flattenPatternConfig: IFlattenVerificationPatternConfig = {
    ...mainConfig,
    ...getErrorProperties(patternName, mainConfig, patternSetting, productSettings, productsInGraph, flow, verificationPatterns),
    patternSetting,
    patternName,
    isActive: isPatternActive(verificationPatterns[patternName]),
  };

  if (clone.length) {
    flattenPatternConfig.options = clone.map((config) => ({
      ...config,
      ...getErrorProperties(patternName, config, patternSetting, productSettings, productsInGraph, flow, verificationPatterns, true),
      patternSetting,
      patternName,
      isActive: isSubPatternActive(verificationPatterns[patternName], config.patternValue),
    }));
  }

  return flattenPatternConfig;
}

export function getError(govCheck: IFlattenVerificationPatternConfig) {
  const error = [];
  const govCheckOption = govCheck.options?.find((option) => option.isActive);
  const isHaveOptionsError = govCheckOption && govCheckOption?.error.type !== VerificationConfigurationErrorTypes.None;
  const isHaveGovCheckError = govCheck.error.type !== VerificationConfigurationErrorTypes.None;
  if (isHaveGovCheckError) error.push(govCheck.error);
  if (isHaveOptionsError) error.push(govCheckOption.error);
  return error;
}

export function getMissingOptionsText(errorType: PatternErrorType, formatMessage: (id: string, descriptor?: MessageDescriptor<string>) => string): string {
  if (errorType.input.id === VerificationInputTypes.CustomField) {
    return `${formatMessage('tooltip.issue.product.missing.customField.option', {
      messageValues: {
        fields: errorType.input.requiredMappingOptionGroups.map((requiredMappingOptionGroup) => requiredMappingOptionGroup.map(({ key }) => formatMessage(`CustomField.settings.fieldSpecificDocument.${key}`)).join(` ${formatMessage('And')} `)).join(` ${formatMessage('or')} `),
      },
    })}`;
  }
  return `${formatMessage('tooltip.issue.product.missing.option', {
    messageValues: {
      option: errorType?.input?.documents?.map(({ type }) => formatMessage(`flow.documentTypeStep.${type}`)).join(` ${formatMessage('or')} `),
    },
  })}`;
}

/*
/* flow.configuration.patternConfig  which contains the validation information for GovChecks
     is being considered only for Data Source. There are GovChecks(Nigerian adress validation)
     which have dependent merits but they are not data sources for the GovCheck.
     We are maintaining config for this in the frontend(GovCheckNonDataSourceDependents) and the inputs
     being sent from backend will be considered only as merits needed as data sources.

  // Optimised code for fetching an array of all merit blocks that are needed for the GovChecks selected that are not part of input array returned from backend
  const dependentMeritsForGovCheck = Object.keys(flow?.verificationPatterns)
    .flatMap((key) => (flow?.verificationPatterns[key] && GovCheckNonDataSourceDependents[key] ? GovCheckNonDataSourceDependents[key] : []))
    .filter((merit, index, array) => array.indexOf(merit) === index);
  return (
    (!productsInGraph.includes(ProductTypes.CustomField)
      && !productsInGraph.includes(ProductTypes.DocumentVerification))
      || !dependentMeritsForGovCheck.every((merit) => productsInGraph?.includes(merit))
  );
 */

function isGovCheckHaveDependsIssue(flow: IFlow, productsInGraph?: ProductTypes[]): boolean {
  /* flow.configuration.patternConfig  which contains the validation information for GovChecks
     is being considered only for Data Source. There are GovChecks(Nigerian adress validation)
     which have dependent merits but they are not data sources for the GovCheck.
     We are maintaining config for this in the frontend(GovCheckNonDataSourceDependents) and the inputs
     being sent from backend will be considered only as merits needed as data sources.
` */
  // Optimised code for fetching an array of all merit blocks that are needed for the GovChecks selected that are not part of input array returned from backend
  const dependentMeritsForGovCheck = Object.keys(flow?.verificationPatterns)
    .flatMap((key) => (flow?.verificationPatterns[key] && GovCheckNonDataSourceDependents[key] ? GovCheckNonDataSourceDependents[key] : []))
    .filter((merit, index, array) => array.indexOf(merit) === index);
  return (
    (!productsInGraph.includes(ProductTypes.CustomField)
      && !productsInGraph.includes(ProductTypes.DocumentVerification))
      || !dependentMeritsForGovCheck.every((merit) => productsInGraph?.includes(merit))
  );
}

export const DEFAULT_GOVERNMENT_CHECK_PRODUCT_SETTINGS = { dataSource: VerificationInputTypes.DocumentVerification };

function hasGovernmentCheckError(productId: string, flow: IFlow, productVerificationPatternsKeys: string[], verificationPatternsConfig: VerificationPatternsConfigType, productsInGraph: any) {
  const verificationPatterns = flow.verificationPatterns;
  const productSettings = flow.productSettings?.[productId] ?? DEFAULT_GOVERNMENT_CHECK_PRODUCT_SETTINGS;

  return productVerificationPatternsKeys.some((patternName) => {
    if (!isPatternActive(flow.verificationPatterns[patternName])) {
      return false;
    }
    const { error } = parseGovCheckPatternConfig(verificationPatterns, flow, productsInGraph, productSettings, patternName, verificationPatternsConfig[patternName]);
    return error.type !== VerificationConfigurationErrorTypes.None;
  });
}

export function hasProductGovCheckInFlow(flow: IFlow, productVerificationPatternsKeys: string[]): boolean {
  const isGovChecksEnabled = productVerificationPatternsKeys.some((patternKey) => isPatternActive(flow?.verificationPatterns[patternKey]));

  return !!flow?.postponedTimeout || isGovChecksEnabled;
}

export function getGovCheckError(productId: string, flow: IFlow, productsInGraph: ProductTypes[], verificationPatternsConfig: VerificationPatternsConfigType): Nullable<string> {
  const productVerificationPatternsKeys = Object.keys(verificationPatternsConfig);

  if (isGovCheckHaveDependsIssue(flow, productsInGraph)) {
    return 'GovernmentCheck.issues.description';
  }

  if (!hasProductGovCheckInFlow(flow, productVerificationPatternsKeys)) {
    return 'GovernmentCheck.issues.noConfig.description';
  }

  if (hasGovernmentCheckError(productId, flow, productVerificationPatternsKeys, verificationPatternsConfig, productsInGraph)) {
    return 'GovernmentCheck.issues.description';
  }

  return null;
}

// TODO: @anatoliy.turkin hardcode, remove after the end of support NigerianLegalValidation/NigerianShareholdersCac
export function fixOldVerificationStep(verificationSteps: string[], productId: ProductTypes): string[] {
  const legacyChecksMapping = {
    [ProductTypes.IdentityValidation]: [
      VerificationStepTypes.NigerianLegalValidation,
      VerificationPatternTypes.EcuadorianRegistroCivil,
      VerificationPatternTypes.HonduranRnp,
      VerificationPatternTypes.ParaguayanRcp,
    ],
    [ProductTypes.BusinessRecordsChecks]: [VerificationStepTypes.NigerianShareholdersCac],
    [ProductTypes.TaxChecks]: [VerificationPatternTypes.CostaRicanAtv],
    [ProductTypes.EmploymentInformation]: [VerificationStepTypes.MexicanImss],
  };

  let result = verificationSteps.slice();

  Object.entries(legacyChecksMapping)
    .forEach(([productType, productIds]) => {
      if (productId === productType) {
        result = result.concat(productIds);
      }
    });

  return result;
}

export interface ILegalSearchRecord {
  region: string;
  involvedParties: string;
  processId: number;
  processNumber: string;
  openedAt: string;
  updatedAt: string;
}

export function getCheckTitle(step: GovernmentChecksStep): string | undefined {
  if (!step) {
    return undefined;
  }

  const config = GovernmentChecksStepTitleOptions[step.id]?.[step.checkStatus];

  if (!config) {
    return undefined;
  }

  if (config.formatter) {
    return config.formatter(step) as string;
  }

  return config.value as string;
}
