import React from 'react';
import { PermissionSubscriptionStatusTypes } from 'apps/Permissions/models/PermissionsSubscriptionStatus.model';
import { useHasPermissionSubscription } from '../../hooks/hasPermissionSubscription.hook';

export function SubscriptionStatusGate({ children, permission, fallback }: {
  children: React.ReactElement;
  permission?: PermissionSubscriptionStatusTypes;
  fallback?: React.ReactElement;
}) {
  const permissionGranted = useHasPermissionSubscription(permission);

  if (!permissionGranted) {
    return fallback ?? null;
  }

  return children;
}
