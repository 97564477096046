import React from 'react';
import { BannerDaysLeftSubscriptionStatus } from '../components/BannerDaysLeftSubscriptionStatus/BannerDaysLeftSubscriptionStatus';
import { BannerExpiredSubscriptionStatus } from '../components/BannerExpiredSubscriptionStatus/BannerExpiredSubscriptionStatus';

export enum SubscriptionStatusTypes {
  Trial = 'freeTrial',
  PostTrial = 'postTrial',
  Active = 'active',
  Blocked = 'blocked',
  Freemium = 'freemium',
}

export const FREEMIUM_MAX_NUMBER_OF_VERIFICATIONS: number = 1000;

export const BannerByStatus = {
  [SubscriptionStatusTypes.Trial]: <BannerDaysLeftSubscriptionStatus />,
  [SubscriptionStatusTypes.PostTrial]: <BannerExpiredSubscriptionStatus />,
};
