import Grid from '@material-ui/core/Grid';
import { selectCurrentCustomWatchlistId } from 'apps/CustomWatchlist/state/CustomWatchlist.selectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantId } from 'state/merchant';
import { PrivateImage } from 'apps/media';
import { notification } from 'apps/ui';
import { PageLoader } from 'apps/layout';
import { useFormatMessage } from 'apps/intl';
import { Box, Button, ImageList, ImageListItem } from '@material-ui/core';
import classNames from 'classnames';
import * as api from '../../client/CustomWatchlist.client';
import { useStyles } from './FacialWatchlistImages.styles';

export const FacialWatchlistImages = ({ handleUploadMore }: {
  handleUploadMore: (totalImages: number) => void;
}) => {
  const formatmessage = useFormatMessage();
  const classes = useStyles();
  const merchantId = useSelector<any, string>(selectMerchantId);
  const currentWatchlistId = useSelector<any, number>(selectCurrentCustomWatchlistId);
  const [images, setImages] = useState([]);
  const [processing, setIsProcessing] = useState(true);
  useEffect(() => {
    const getWatchListDetails = async () => {
      try {
        const res = await api.getMerchantWatchlistById(merchantId, currentWatchlistId);
        setImages(res.data?.mediaLinks ? res.data?.mediaLinks : []);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        notification.error(formatmessage('CustomWatchlist.settings.facialWatchlists.error.fetchWatchlist'));
      }
    };
    getWatchListDetails();
  }, [currentWatchlistId, formatmessage, merchantId]);

  return (
    <>
      <Grid container spacing={2}>
        {processing && (
        <PageLoader />
        )}
        <ImageList cols={4} className={classes.imageList}>
          {images.map((image: string, index: React.Key) => (
            <ImageListItem key={index}>
              <PrivateImage src={image} alt="image" className={classNames(classes.watchlistImage, 'fs-exclude')} />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      {!processing && (
      <Box mt={2}>
        <Button className={classes.button} variant="outlined" color="primary" onClick={() => { handleUploadMore(images.length); }}>
          {formatmessage('CustomWatchlist.settings.facialWatchlists.modal.addMoreImages')}
        </Button>
      </Box>
      )}
    </>
  );
};
