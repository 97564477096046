import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CheckStepDetails, CheckBarExpandable } from 'apps/checks';
import { POOImage } from 'apps/ProofOfOwnership';
import { CheckResultLogo, Warning, WarningTypes, ZoomableImage } from 'apps/ui';
import classNames from 'classnames';
import { DocumentErrorCodeTypes, DocumentSideTypes, getDocumentSideLabel, PhotosOrientations, VerificationDocument, VerificationDocumentTypes } from 'models/Document.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { AgeCheck } from 'apps/AgeCheck';
import { useFormatMessage } from 'apps/intl';
import { DuplicateUserDetectionCheck } from 'apps/checks/components/DuplicateUserDetectionCheck/DuplicateUserDetectionCheck';
import { AD_FRAUD_ATTEMPT, DocumentStepTypes, IStep, AD_NEGLIGENCE, StepStatus, DocumentStepFrontendChecksTypes, AD_SUSPICIOUS_PROFILE, AgeConsistencyCheckErrors } from 'models/Step.model';
import { useSelector } from 'react-redux';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';
import { IdentityStatuses } from 'models/Status.model';
import { ProductTypes } from 'models/Product.model';
import { Link } from '@material-ui/core';
import { useStyles } from './NewDocumentStep.styles';
import { useDocumentTitle, usePhotosOrientation } from '../../hooks/document.hook';
import { DocumentReadingStep } from '../DocumentReadingStep/DocumentReadingStep';

export function NewDocumentStep({ document, verification, documentIndex, onDocumentUpdate, onSelect }: {
  document: VerificationDocument;
  verification: VerificationResponse;
  documentIndex: number;
  onDocumentUpdate: (normalizedData: any, documentType: VerificationDocumentTypes) => Promise<void>;
  onSelect: (id: ProductTypes) => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const title = useDocumentTitle(document);
  const photosOrientation = usePhotosOrientation(document);
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const canNotUseDocumentReading = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanNotUseDocumentReading)), [merchantTags]);
  const { ageCheck, duplicateUserDetectionStep, type, securityCheckSteps, documentFailedCheckSteps, isSanctioned, fields, documentReadingStep, onReading, documentStatus, proofOfOwnership, photos, areTwoSides, ageConsistencyCheck } = document;
  const isFormEditable = verification.isEditable && verification.isOwner;
  const isVerificationSucess = verification.verificationStatus === IdentityStatuses.verified;
  const fraudAssessment = verification.steps.find((obj) => obj.id === DocumentStepTypes.FruadAssessment);
  const isDocumentSkipped = document.documentReadingStep?.error?.code === DocumentErrorCodeTypes.DocumentSkipped;

  const getSecurityStepDetail = (step: IStep) => {
    if (step.id === DocumentStepTypes.TemplateMatching && canNotUseDocumentReading && isVerificationSucess) {
      return (
        <CheckBarExpandable step={step} key={step.id} isWarning>
          <CheckStepDetails>
            <Typography className={classes.displayInline} variant="body1">
              {formatMessage(`SecurityCheckStep.${(step.id)}.noDocumentReading`)}
            </Typography>
          </CheckStepDetails>
        </CheckBarExpandable>
      );
    }
    if (step.id === DocumentStepTypes.AlternationDetection && [AD_FRAUD_ATTEMPT, AD_NEGLIGENCE].includes(step.error?.code) && step.checkStatus === StepStatus.Failure && step.error?.details?.reason?.code) {
      return (
        <CheckBarExpandable step={step} key={step.id}>
          <CheckStepDetails>
            <Box className={classes.stepFailureReasonWrapper}>
              <Typography className={classNames(classes.title, classes.displayInline)} variant="subtitle2">
                {formatMessage(`SecurityCheckStep.${(step.error.code)}.subTitle`)}
              </Typography>
            &nbsp;
              <Typography className={classes.displayInline} variant="body1">
                {formatMessage(`SecurityCheckStep.${(step.error.code)}.${(step.error.details.reason.code)}`)}
              </Typography>
            </Box>
          </CheckStepDetails>
        </CheckBarExpandable>
      );
    }
    if (step.id === DocumentStepTypes.AlternationDetection && [AD_SUSPICIOUS_PROFILE].includes(step.error?.code) && step.error?.reasonCode) {
      return (
        <CheckBarExpandable step={step} key={step.id}>
          <CheckStepDetails>
            <Box className={classes.stepFailureReasonWrapper}>
              <Typography className={classNames(classes.title, classes.displayInline)} variant="subtitle2">
                {formatMessage(`SecurityCheckStep.${(step.error.code)}.subTitle`)}
              </Typography>
            &nbsp;
              <Typography className={classes.displayInline} variant="body1">
                {formatMessage(`SecurityCheckStep.${(step.error.code)}.${(step.error.reasonCode)}`)}
              </Typography>
              &nbsp;
              {fraudAssessment?.data?.score && (
              <>
                <Typography className={classes.displayInline} variant="body1">
                  {formatMessage(`SecurityCheckStep.${(step.error.code)}.${(step.error.reasonCode)}.seeOur`)}
                </Typography>
              &nbsp;
                <Link onClick={() => onSelect(ProductTypes.ADFraudScore)}>
                  <Typography className={classes.displayInline} variant="body1">
                    {formatMessage(`SecurityCheckStep.${(step.error.code)}.${(step.error.reasonCode)}.fraudAssessmentTab`)}
                  </Typography>
                </Link>
              &nbsp;
                <Typography className={classes.displayInline} variant="body1">
                  {formatMessage(`SecurityCheckStep.${(step.error.code)}.${(step.error.reasonCode)}.moreDetails`)}
                </Typography>
              </>
              )}
            </Box>
          </CheckStepDetails>
        </CheckBarExpandable>
      );
    }

    if ((step.id === DocumentStepTypes.FaceMatch) && step.data?.status) {
      return (
        <CheckBarExpandable step={step} key={step.id}>
          <CheckStepDetails step={step}>
            {formatMessage(`SecurityCheckStep.${step.checkStatus}`)}
          </CheckStepDetails>
        </CheckBarExpandable>
      );
    }

    return (
      <CheckBarExpandable step={step} key={step.id}>
        <CheckStepDetails step={step} />
      </CheckBarExpandable>
    );
  };

  const getDocumentFailedCheckSteps = (step: IStep) => {
    if (step.id === DocumentStepFrontendChecksTypes.EmptyFields && canNotUseDocumentReading && isVerificationSucess) {
      return (
        <CheckBarExpandable step={step} key={step.id} isWarning>
          <CheckStepDetails>
            <Typography className={classes.displayInline} variant="body1">
              {formatMessage(`SecurityCheckStep.${(step.id)}.noDocumentReading`)}
            </Typography>
          </CheckStepDetails>
        </CheckBarExpandable>
      );
    }
    return (
      <CheckBarExpandable step={step} key={step.id}>
        <CheckStepDetails step={step} />
      </CheckBarExpandable>
    );
  };

  const getAgeConsistencyCheckStep = (step: IStep) => (
    <CheckBarExpandable step={step} key={step.id} isError={AgeConsistencyCheckErrors.includes(step?.error?.code)} title={formatMessage('AgeConsistencyCheck.title')}>
      <CheckStepDetails>
        <Typography>
          {formatMessage(step?.error?.code ? `AgeConsistencyCheck.${step?.error?.code}` : 'AgeConsistencyCheck.passed')}
        </Typography>
      </CheckStepDetails>
    </CheckBarExpandable>
  );

  if (isDocumentSkipped) {
    return (
      <Box>
        <Typography className={classes.title} variant="subtitle2" gutterBottom>{formatMessage('DocumentStep.Data.title', { messageValues: { index: documentIndex + 1 } })}</Typography>
        <Box className={classes.skipDocumentContainer} mb={4}>
          <Typography className={classes.skipDocumentTitle} variant="h4" gutterBottom>
            {formatMessage('DocumentReadingStep.error')}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.skipDocumentTitle} variant="h4" gutterBottom>{formatMessage('Checks.result.document.skipped.title')}</Typography>
          <Typography className={classes.skipDocumentTitle} variant="body1" gutterBottom>{formatMessage('Checks.result.document.skipped.description')}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography className={classes.title} variant="subtitle2" gutterBottom>{formatMessage('DocumentStep.Data.title', { messageValues: { index: documentIndex + 1 } })}</Typography>
        <Grid container alignItems="center">
          <Typography className={classes.title} variant="subtitle2">{formatMessage('DocumentStep.Data.subtitle')}</Typography>
          &nbsp;
          <Typography className={classes.title} variant="body1">{title}</Typography>
        </Grid>
      </Box>

      {isSanctioned && (
        <Box mb={2}>
          <Warning
            type={WarningTypes.Error}
            label={formatMessage('SanctionCheck.title')}
          />
        </Box>
      )}

      <Grid container>
        {/* images */}
        <Grid item xs={12} xl={4} className={classes.item}>
          <Grid container direction="column" alignItems="center" className={classes.images}>
            <Grid
              container
              justifyContent="center"
              className={classNames({
                [classes.imagesHorizontal]: areTwoSides && photosOrientation === PhotosOrientations.Horizontal,
                [classes.imagesVertical]: areTwoSides && photosOrientation !== PhotosOrientations.Horizontal,
                [classes.image]: !areTwoSides,
              })}
            >
              {photos.map((photo, index) => (
                <Grid item key={index} className={classNames(classes.image, 'fs-exclude')}>
                  <ZoomableImage src={photo} alt={type} />
                  {photos.length > 1 && (
                  <Typography className={classes.subtitle} align="center" variant="subtitle2">
                    {formatMessage(getDocumentSideLabel(index === 0 ? DocumentSideTypes.Front : DocumentSideTypes.Back))}
                  </Typography>
                  )}
                </Grid>
              ))}
            </Grid>

            {/* proof of ownership */}
            {/* TODO @vladislav.snimshchikov: add proofOfOwnership caching on identity level */}
            {proofOfOwnership && (
              <Grid item className={classNames(classes.image, 'fs-exclude')}>
                <POOImage step={proofOfOwnership} />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* data */}
        <Grid item xs={12} xl={4} className={classes.item}>
          <Box className={classes.itemWrapper}>
            {documentReadingStep && (
              <DocumentReadingStep
                documentType={type}
                step={documentReadingStep}
                fields={fields}
                isEditable={isFormEditable}
                onReading={onReading}
                onDocumentUpdate={onDocumentUpdate}
                identityId={verification?.identity}
              />
            )}
          </Box>
        </Grid>
        {/* checks */}
        <Grid item xs={12} xl={4} className={classes.item}>
          <Box className={classes.itemWrapper}>
            {securityCheckSteps && (
              <Grid container>
                <Box mx={{ xs: 'auto', md: 0.7 }}>
                  <CheckResultLogo status={(canNotUseDocumentReading && isVerificationSucess) ? StepStatus.Success : documentStatus} />
                </Box>
                <Grid item container>
                  {documentFailedCheckSteps.map((step) => getDocumentFailedCheckSteps(step))}
                  {securityCheckSteps.map((step) => getSecurityStepDetail(step))}
                  {ageCheck && (<AgeCheck stepData={ageCheck} />)}
                  {ageConsistencyCheck && getAgeConsistencyCheckStep(ageConsistencyCheck)}
                  {duplicateUserDetectionStep && (<DuplicateUserDetectionCheck stepData={duplicateUserDetectionStep} />)}
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
