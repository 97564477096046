import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React from 'react';
import classNames from 'classnames';
import { useFormatMessage } from 'apps/intl';
import { ModalMetamap } from '../ModalMetamap/ModalMetamap';
import { useStyles } from './ConfirmModalMetamap.styles';

export function ConfirmModalMetamap({ onClose, onConfirm, title, subtitle, confirmButtonMessage, withCancelButton = true }: {
  onClose: () => void;
  onConfirm: () => void;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  confirmButtonMessage?: React.ReactNode;
  withCancelButton?: boolean;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <ModalMetamap
      data-role="confirmationModal"
      title={title}
      subtitle={subtitle}
      onClose={onClose}
    >
      <Box height="100%" display="flex" justifyContent="flex-end">
        <Button
          className={classNames(classes.button, classes.confirmButton)}
          type="submit"
          data-role="confirm"
          onClick={onConfirm}
        >
          {confirmButtonMessage || formatMessage('confirm')}
        </Button>
        {withCancelButton && (
          <Button
            className={classes.button}
            variant="contained"
            disableElevation
            fullWidth
            data-role="cancel"
            onClick={onClose}
          >
            {formatMessage('cancel')}
          </Button>
        )}
      </Box>
    </ModalMetamap>
  );
}
