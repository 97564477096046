import React from 'react';
import Box from '@material-ui/core/Box';
import styles from 'apps/overlay/components/Modal/Modal.module.scss';
import { Button, Typography } from '@material-ui/core';
import { useFormatMessage } from 'apps/intl';
import { QATags } from 'models/QA.model';
import { useNewSettingsLeaveConfirmModal } from './ConfirmLeaveModal.styles';

export default function ConfirmLeaveModal({
  title,
  subtitle,
  onCancelChanges,
  onSaveChanges,
  onClose,
  onConfirm,
}: {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  onCancelChanges: () => void;
  onSaveChanges: () => void;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useNewSettingsLeaveConfirmModal();

  const handleCancelChangesClick = () => {
    onCancelChanges();
    onClose();
  };

  const handleSaveChangesClick = () => {
    onSaveChanges();
    onConfirm();
  };
  return (
    <Box p="0px" borderRadius="6px" width="496px" height="170px" className={styles.window}>
      <Typography className={classes.heading} component="h2">
        {title}
      </Typography>
      <Typography className={classes.description} component="p">
        {subtitle}
      </Typography>
      <Box m="0px" pt="24px" pr="24px" display="flex" justifyContent="flex-end">
        <Button data-qa={QATags.NewSettings.RouterPromptModal.cancelChangesButton} onClick={handleCancelChangesClick} disableRipple className={classes.cancelChangesButton} variant="contained">{formatMessage('settings.tabs.leaveModal.cancelButton')}</Button>
        <Button data-qa={QATags.NewSettings.RouterPromptModal.saveChangesButton} onClick={handleSaveChangesClick} disableRipple className={classes.saveChangesButton} variant="contained">{formatMessage('settings.tabs.leaveModal.saveButton')}</Button>
      </Box>
    </Box>
  );
}
