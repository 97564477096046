import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { IProductCard, ProductTypes } from 'models/Product.model';
import React, { useCallback } from 'react';
import { useFormatMessage } from 'apps/intl';
import { WarningBadge } from 'apps/ui';
import { useStyles } from './UIProductTab.styles';
import { useProductFormatMessage } from '../../hooks/useProductFormatMessage';

export function UIProductTab({ card, isSelected, hasBadge, onSelect, isDisabled }: {
  card: IProductCard;
  isSelected?: boolean;
  hasBadge?: boolean;
  onSelect: (id: ProductTypes) => void;
  isDisabled?: boolean;
}) {
  const formatMessage = useFormatMessage();
  const productFormatMessage = useProductFormatMessage(card);

  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onSelect(card?.id);
    }
  }, [isDisabled, onSelect, card]);

  const classes = useStyles();

  return (
    <Box
      p={1}
      mb={1}
      onClick={handleClick}
      className={classNames(classes.tab, {
        [classes.selected]: isSelected,
        [classes.disabled]: isDisabled,
      })}
    >
      {hasBadge && <WarningBadge />}
      {card?.isExternal ? productFormatMessage('title') : formatMessage(card?.title)}
    </Box>
  );
}
