import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  badge: {
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 10px',
    marginLeft: 6,
    fontSize: 12,
  },
  beta: {
    backgroundColor: theme.palette.common.lightyellow,
    color: theme.palette.common.yellow,
  },
  new: {
    backgroundColor: '#E6F7C3',
    color: '#87C600',
  },
}));
