import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    minWidth: 230,
  },
  disabled: {
    filter: 'opacity(0.35)',
  },
}));
