import { makeStyles } from '@material-ui/core';
import { BackgroundCheckStatusesTypes } from 'models/BackgroundCheck.model';

export const useStyles = makeStyles(({ palette }) => ({
  label: {
    color: palette.text.main,
  },
  scoreValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& + &': {
      marginLeft: '24px',
      '&::before': {
        content: '""',
        display: 'block',
        height: '100%',
        width: '2px',
        backgroundColor: palette.common.black7,
        position: 'absolute',
        left: '-11px',
      },
    },
  },
  scoreValue: {
    fontSize: '37px',
    lineHeight: '44px',
    fontWeight: 700,
  },
  [BackgroundCheckStatusesTypes.Approved]: {
    color: palette.common.green,
  },
  [BackgroundCheckStatusesTypes.HighRisk]: {
    color: palette.common.red,
  },
  [BackgroundCheckStatusesTypes.LowRisk]: {
    color: palette.common.yellow,
  },
}));
