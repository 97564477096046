import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  colorGrey: {
    color: theme.palette.text.main,
  },
  card: {
    width: '100%',
    border: 'none',
    filter: 'none',
    boxShadow: 'none',
  },
  summaryList: {
    height: 210,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    flexBasis: 'calc(100% - 230px - 60px)',
  },
  info: {
    minWidth: 320,
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 10px',
    fontFamily: 'Lato',
    '& span': {
      fontWight: 600,
      fontSize: 16,
      width: 'calc(100% - 40px)',
      wordWrap: 'break-word',
    },
    '&>p': {
      color: theme.palette.common.black90,
      fontWeight: 500,
      fontSize: 14,
      marginTop: 10,
    },
  },
  summaryListItem: {
    minWidth: 200,
    maxWidth: 300,
    marginBottom: 20,
  },
  summaryListItemValue: {
    fontWeight: 700,
    marginBottom: 5,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  verticalMargin10: {
    marginTop: 10,
    marginBottom: 10,
  },
  marginRight20: {
    marginRight: 20,
  },
  speedometerWrap: {
    flexBasis: 180,
    margin: '30px 0 10px',
  },
  downloadButtonsBox: {
    width: 'auto',
  },
  manualButtonWrap: {
    maxWidth: 305,
  },
  ultraLargeButton: {
    padding: '16px 82px',
  },
  divider: {
    backgroundColor: theme.palette.common.black7opacity,
  },
  speedometerText: {
    fontFamily: 'Inter',
    color: theme.palette.text.main,
    fontSize: 12,
    margin: '0 0 14px',
    fontWeight: 500,
    width: 230,
    '&:first-of-type': {
      margin: '12px 0 3px',
      fontSize: 16,
      fontWeight: 400,
    },
  },
  error: {
    color: theme.palette.common.red,
  },
  worning: {
    color: theme.palette.common.black75,
  },
  success: {
    color: theme.palette.common.black90,
  },
  separated: {
    '& + $separated': {
      borderTop: `1px solid ${theme.palette.common.black7}`,
      paddingTop: 16,
    },
  },
}));
