import Box from '@material-ui/core/Box';
import { useFormatMessage, useFormatServerMessage } from 'apps/intl';
import { IMeritBlockContent } from 'apps/MeritBlockAdditional';
import { productManagerService } from 'apps/Product';
import classNames from 'classnames';
import { ProductTypes } from 'models/Product.model';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useStyles } from './TemplateMeritBlockCard.styles';

export function TemplateMeritBlockCard({ type, meritBlockContent, isOpen, onClick }: {
  type: ProductTypes;
  meritBlockContent: IMeritBlockContent;
  isOpen: boolean;
  onClick: () => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const formatServerMessage = useFormatServerMessage();
  const meritBlock = productManagerService.getProduct(type);

  if (!meritBlock) {
    return null;
  }

  return (
    <Box onClick={onClick} className={classNames(classes.container, { [classes.containerClosed]: !isOpen })}>
      <Box className={classes.header}>
        <Box className={classes.title}>
          <Box className={classes.icon}>
            {meritBlock.icon}
          </Box>
          {formatMessage(meritBlock.getTitle())}
        </Box>
        {!isOpen && (
          <Box className={classNames(classes.expandButton)}>
            <FiChevronDown size={24} />
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box>
          {meritBlockContent?.inputTypes?.length > 0 && (
            <>
              <Box className={classes.label}>{formatMessage('TemplateCard.label.inputs')}</Box>
              <Box className={classes.badgeContainer}>
                {meritBlockContent.inputTypes.map((message) => (
                  <Box key={message.name} className={classes.badge}>
                    {formatServerMessage(message)}
                  </Box>
                ))}
              </Box>
            </>
          )}
          {meritBlockContent?.meritTypes?.length > 0 && (
            <>
              <Box className={classes.label}>{formatMessage('TemplateCard.label.merits')}</Box>
              <Box className={classes.badgeContainer}>
                {meritBlockContent.meritTypes.map((message) => (
                  <Box key={message.name} className={classes.badge}>
                    {formatServerMessage(message)}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
