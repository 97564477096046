import { useCallback } from 'react';
import { PermissionsTypes, PermissionAccessTypes, getRole } from '../models/Auth0.model';

export function useRole() {
  const roleName = getRole()?.name;
  const isOwner = getRole()?.isOwner;
  const checkPermission = useCallback((permission: PermissionsTypes, accessTypes: PermissionAccessTypes): boolean => {
    const role = getRole();
    if (role?.isOwner) {
      return true;
    }

    return role?.permissions[permission]?.accessTypes?.includes(accessTypes) || false;
  }, []);

  return {
    checkPermission,
    roleName,
    isOwner,
  };
}
