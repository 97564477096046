import classnames from 'classnames';
import React from 'react';
import { Box } from '@material-ui/core';
import { ReactComponent as SpeedometerCursor } from './assets/speedometer-cursor.svg';
import { useStyles } from './Speedometer.styles';

const cursorRotation = {
  start: 0,
  end: 300,
};

export function Speedometer({ value, range, className }: {
   value: number | 'NA';
   range?: { start: number; end: number};
   className?: string;
  }) {
  const score = Number.isNaN(value) ? 0 : value as number;
  const start = range ? range.start : cursorRotation.start;
  const end = range ? range.end : cursorRotation.end;
  const nativeCursorRange = end - start;
  const cursorRangeValue = score <= start ? 0 : (score >= end ? 300 : ((score - start) / nativeCursorRange) * 300);
  const classes = useStyles({ cursorRotation: cursorRangeValue });

  if (cursorRangeValue < start || cursorRangeValue > end) {
    console.error(`value must be between [${start} - ${end}]`);
  }

  return (
    <Box className={classnames(classes.root, className)}>
      <Box className={classes.faceWrap}>
        <Box className={classes.faceBox}>
          <Box className={classnames(classes.part, classes.partItem)} />
          <Box className={classnames(classes.part, classes.partItem)} />
          <Box className={classnames(classes.part, classes.partItem)} />
          <Box className={classnames(classes.part, classes.partItem)} />
        </Box>
        <Box className={classes.faceCenter} />
      </Box>
      <Box className={classes.cursor}>
        <SpeedometerCursor className={classes.cursorIcon} />
      </Box>
      <Box className={classes.valueWrap}>
        <Box className={classes.value}>{value}</Box>
      </Box>
    </Box>
  );
}
