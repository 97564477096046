import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { ICustomField } from 'models/CustomField.model';
import { updateCustomFieldEditedField } from '../../../../state/CustomField.actions';
import { selectCustomFieldEditedField } from '../../../../state/CustomField.selectors';

export function CustomFieldMandatorySetting() {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const selectedCustomField = useSelector<any, ICustomField>(selectCustomFieldEditedField);

  const handleChangeSelectionIsMandatory = () => {
    dispatch(updateCustomFieldEditedField({
      ...selectedCustomField,
      isMandatory: !selectedCustomField.isMandatory,
    }));
  };

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item>
        <Typography variant="body1">
          {formatMessage('CustomField.settings.fieldMandatory')}
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={selectedCustomField.isMandatory}
          color="primary"
          size="small"
          onChange={handleChangeSelectionIsMandatory}
        />
      </Grid>
    </Grid>
  );
}
