import React from 'react';
import { CheckBarExpandable } from 'apps/checks';
import { StepStatus } from 'models/Step.model';
import { IBasicWatchlistStepData } from 'apps/Aml/models/Aml.model';
import { WatchListItem } from 'apps/ui';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './BasicWatchlists.styles';

export const BasicWatchlists = ({ checkStatus, watchlists, errorCode }: {
  checkStatus: StepStatus;
  watchlists?: IBasicWatchlistStepData[];
  errorCode?: string;
}) => {
  const hasErrors = watchlists ? watchlists?.some((stepWatchlist) => !!stepWatchlist.error) : errorCode;
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <CheckBarExpandable
      step={{
        id: VerificationPatternTypes.Watchlists,
        checkStatus,
      }}
      isError={checkStatus !== StepStatus.Checking ? !!hasErrors : undefined}
      isNoBadge={!hasErrors}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box>
            <Box mt={0.5} mb={1.5}>
              {hasErrors ? formatMessage(checkStatus === StepStatus.Failure
                ? `SecurityCheckStep.${errorCode}.message` : 'SecurityCheckStep.watchlists.incomplete')
                : formatMessage('BasicWatchlist.verification.noerror.title')}
            </Box>
            {checkStatus !== StepStatus.Incomplete && watchlists?.map((basicWatchlistItem, idx) => (
              <WatchListItem
                item={basicWatchlistItem}
                status={basicWatchlistItem.error ? StepStatus.Failure : checkStatus}
                key={idx}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    </CheckBarExpandable>
  );
};
