import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  window: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    minHeight: 200,
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: 24,
    borderRadius: 6,
    background: 'white',
    boxShadow: '0 2px 4px rgba(52, 73, 94, 0.1)',
  },

  buttonClose: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 0,
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    transition: '.25s all ease-in-out',

    '&:hover, &:focus': {
      filter: 'brightness(0.5)',
      backgroundColor: 'transparent',
    },
  },

  buttonIcon: {
    width: 24,
    height: 24,
  },

  titleWrapper: {
    textAlign: 'left',
  },

  subtitle: {
    maxWidth: 496,
  },
}));
