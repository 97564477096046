export enum SanctionTypes {
    sanction = 'Sanction',
    warning = 'Warning',
    pep = 'PEP',
    'fitness-probity' = 'Fitness / Probity',
    'pep-class-1' = 'PEP Class 1',
    'pep-class-2' = 'PEP Class 2',
    'pep-class-3' = 'PEP Class 3',
    'pep-class-4' = 'PEP Class 4',
    'adverse-media-v2-property' = 'Adverse Media Property',
    'adverse-media-v2-financial-aml-cft' = 'Adverse Media Financial',
    'adverse-media-v2-fraud-linked' = 'Adverse Media Fraud Linked',
    'adverse-media-v2-narcotics-aml-cft' = 'Adverse Media Narcotics',
    'adverse-media-v2-violence-aml-cft' ='Adverse Media Violence AML',
    'adverse-media-v2-terrorism' = 'Adverse Media Terrorism',
    'adverse-media-v2-cybercrime' = 'Adverse Media Cybercrime',
    'adverse-media-v2-general-aml-cft' = 'Adverse Media General AML',
    'adverse-media-v2-regulatory' = 'Adverse Media Regulatory',
    'adverse-media-v2-financial-difficulty' = 'Adverse Media Financial Difficulty',
    'adverse-media-v2-violence-non-aml-cft' = 'Adverse Media Violence Non AML',
    'adverse-media-v2-other-financial' = 'Adverse Media Other Financial',
    'adverse-media-v2-other-serious' = 'Adverse Media Other Serious',
    'adverse-media-v2-other-minor' = 'Adverse Media Other Minor',
}

export interface ITree {
    label: string;
    id: string;
}

export interface ITreeData {
    handleSelect: (id: string, checked: boolean) => void;
    selectedSanctions: string[];
}
