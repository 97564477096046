import { FormatMessage } from 'apps/intl';
import { notification } from 'apps/ui';
import { ITemplate } from '../model/Templates.model';
import { selectTemplatesListModelValues } from './Templates.selectors';
import * as api from '../api/Templates.client';
import { types } from './Templates.store';

export const clearCurrentTemplate = () => ({ type: types.currentTemplate_CLEAR, payload: null });

export const toggleTemplateApplying = (payload) => (dispatch) => {
  dispatch({ type: types.TOGGLE_TEMPLATE_APPLYING, payload });
};

export const getTemplatesList = () => async (dispatch) => {
  dispatch({ type: types.templatesList_UPDATING });

  try {
    const { data } = await api.getTemplatesListRequest();
    dispatch({ type: types.templatesList_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.templatesList_FAILURE, error });
    throw error;
  }
};

export const changeTemplatesOrder = (reorderedTemplates: ITemplate[]) => async (dispatch) => {
  dispatch({ type: types.templatesList_UPDATING });

  try {
    await api.changeTemplatesOrder(reorderedTemplates.map((template) => template._id));
    dispatch({ type: types.templatesList_SUCCESS, payload: { rows: reorderedTemplates }, isReset: false });
  } catch (error) {
    dispatch({ type: types.templatesList_FAILURE, error });
    throw error;
  }
};

export const getMetadata = () => async (dispatch) => {
  dispatch({ type: types.metadataList_UPDATING });

  try {
    const response = await api.getMetadataRequest();
    dispatch({ type: types.metadataList_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.metadataList_FAILURE, error });
    throw error;
  }
};

export const getTemplate = (id: string) => async (dispatch) => {
  dispatch({ type: types.currentTemplate_UPDATING });
  try {
    const { data } = await api.getTemplateRequest(id);
    dispatch({ type: types.currentTemplate_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: types.currentTemplate_FAILURE, error });
    throw error;
  }
};

export const blockTemplate = (id: string) => async (dispatch, getState) => {
  dispatch({ type: types.currentTemplate_UPDATING });

  try {
    const { data: { _id } } = await api.blockTemplateRequest(id);
    const templatesList = selectTemplatesListModelValues(getState());
    const newTemplatesListRows = templatesList.rows.filter((template) => template._id !== _id);
    const newTemplatesList = { ...templatesList, rows: newTemplatesListRows };
    dispatch({ type: types.templatesList_SUCCESS, payload: newTemplatesList });
  } catch (error) {
    dispatch({ type: types.templatesList_FAILURE, payload: error });
  }
};

export const toggleTemplate = (id: string, blocked: boolean, formatMessage: FormatMessage) => async (dispatch, getState) => {
  dispatch({ type: types.currentTemplate_UPDATING });
  const toggle = blocked ? 'unblock' : 'block';

  try {
    const { data } = await api.toggleTemplateRequest(id, toggle);
    const templatesList = selectTemplatesListModelValues(getState());
    const toggleTemplateIndex = templatesList.rows.findIndex((template) => template._id === data._id);
    if (toggleTemplateIndex >= 0) templatesList.rows[toggleTemplateIndex] = data;
    dispatch({ type: types.templatesList_SUCCESS, payload: templatesList });
    notification.info(formatMessage('Template.templateList.changeStatus'));
  } catch (error: any) {
    dispatch({ type: types.currentTemplate_FAILURE, payload: error });
    notification.error(formatMessage(`Settings.teamSettings.submit.${error.response?.data?.name}`, { defaultMessage: formatMessage('Error.common') }));
  }
};
