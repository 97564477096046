import { IFlow } from './Flow.model';
import { ProductTypes } from './Product.model';

export enum FacematchSourceTypes {
  Biometrics = 'biometrics',
  GovermentCheck = 'govermentCheck',
  Document = 'document',
  MerchantDatabase = 'merchantDatabase',
  VideoAgreement = 'videoAgreement',
}

export const sourceMapping = {
  [FacematchSourceTypes.Biometrics]: 'Facematch.addModal.fromBiometricVerification',
  [FacematchSourceTypes.Document]: 'Facematch.addModal.fromUserDocument',
  [FacematchSourceTypes.GovermentCheck]: 'Facematch.addModal.fromGovernmentDatabase',
  [FacematchSourceTypes.MerchantDatabase]: 'Facematch.addModal.fromCustomDatabase',
  [FacematchSourceTypes.VideoAgreement]: 'Facematch.addModal.fromVideoAgreement',
};

export const isHaveFacematchSource = (flow: IFlow, product: ProductTypes): boolean => {
  const { facematchServiceConfig: { sources } } = flow;
  switch (product) {
    case ProductTypes.IdentityValidation:
    case ProductTypes.LegalRecords:
    case ProductTypes.DrivingLicenseValidation:
    case ProductTypes.TaxChecks:
    case ProductTypes.BusinessRecordsChecks:
      return sources.some((source) => source.type === FacematchSourceTypes.GovermentCheck);
    default:
      return true;
  }
};
