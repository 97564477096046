import { createReducer } from 'state/store.utils';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { verificationHistoryCleanFilter } from 'models/History.model';
import { SliceNameTypes, types } from './verificationHistory.store';

const initialState = {
  filter: verificationHistoryCleanFilter,
  [SliceNameTypes.Changes]: LoadableAdapter.createState([]),
  [SliceNameTypes.Count]: 0,
  [SliceNameTypes.Steps]: LoadableAdapter.createState([]),
};

export default createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.Changes),
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.Steps),
  [types.FILTER_UPDATE](state, { payload }) {
    return {
      ...state,
      filter: payload,
    };
  },
  [types.VERIFICATION_HISTORY_COUNT_LOAD](state, { payload }) {
    return {
      ...state,
      [SliceNameTypes.Count]: payload,
    };
  },
});
