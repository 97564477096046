import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    margin: '0px',
    pointerEvents: 'none',
    '& .MuiSwitch-root': {
      pointerEvents: 'auto',
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.common.black90,
      fontFamily: 'Inter, Lato, "Helvetica Neue", "sans-serif"',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px',
    },
    '&.MuiFormControlLabel-labelPlacementStart': {
      justifyContent: 'space-between',
    },
  },
  redirectionOptionsContainer: {
    width: '100%',
    margin: '0px',
  },
  gridItem: {
    padding: '10px 24px 10px 0px !important',
  },
  formGroupRoot: {
    rowGap: '16px',
  },
}));
