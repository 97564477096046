import { selectProductCards, useProductsIssues } from 'apps/Product';
import { notification } from 'apps/ui';
import React, { useEffect, useMemo } from 'react';
import { useFormatMessage } from 'apps/intl';
import { navigation, NavigationEventTypes } from 'apps/layout';
import { Button } from 'lib/UI';
import { useDispatch, useSelector } from 'react-redux';
import { Loadable } from 'models/Loadable.model';
import { IFlow } from 'models/Flow.model';
import { IProductCard, ProductTypes } from 'models/Product.model';
import { ChatWidget, IntercomCustomAttributeTypes, IntercomEventTypes } from 'apps/intercom';
import { TrackTypes, useTrackClickEvent } from 'apps/Tracking';
import { flowBuilderSaveAndPublish } from '../../store/FlowBuilder.action';
import { selectFlowBuilderChangeableFlow, selectFlowBuilderChangeableFlowModel, selectFlowBuilderHaveUnsavedChanges, selectFlowBuilderProductsInGraphModel } from '../../store/FlowBuilder.selectors';

export function Save({ newFlowName }: {
  newFlowName?: string;
}) {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const cards: IProductCard[] = useSelector(selectProductCards);
  const productsInGraphModel = useSelector<any, Loadable<ProductTypes[]>>(selectFlowBuilderProductsInGraphModel);
  const allDisabledCards: IProductCard[] = cards.filter((type: IProductCard) => type.disabled);
  const isCardDisabled: boolean = productsInGraphModel.value.some((type: ProductTypes) => allDisabledCards.find((ele: IProductCard) => ele.id === type)?.disabled === true);
  const haveUnsavedChanges = useSelector<any, boolean>(selectFlowBuilderHaveUnsavedChanges);
  const changeableFlowModel = useSelector<any, Loadable<IFlow>>(selectFlowBuilderChangeableFlowModel);
  const haveIssues = useProductsIssues(productsInGraphModel.value);
  const isDisabled = useMemo(() => (productsInGraphModel?.value?.length || 0) === 0, [productsInGraphModel?.value]);
  const changeableFlow = useSelector(selectFlowBuilderChangeableFlow);
  const producstsInGraph = useSelector(selectFlowBuilderProductsInGraphModel);

  const trackEvent = useTrackClickEvent();
  useEffect(() => {
    function handleBeforeUnload(event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }

    if (haveUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [haveUnsavedChanges]);

  const handleSaveFlow = async (flowName: string) => {
    try {
      await dispatch(flowBuilderSaveAndPublish(flowName));
      notification.info(formatMessage('FlowBuilder.notification.saved'));
      trackEvent(TrackTypes.WorkflowUpdated, {
        workflowId: changeableFlow.id,
        workflowName: flowName || changeableFlow.name,
        workflowType: changeableFlow.integrationType,
        tools: producstsInGraph.value,
      });
      ChatWidget.update({ [IntercomCustomAttributeTypes.HasCreatedFirstMetamap]: true });
      ChatWidget.trackEvent(IntercomEventTypes.CreateMetamap);
    } catch (e) {
      notification.error(formatMessage('Error.common'));
    }
  };

  useEffect(() => {
    const confirm = (resume) => {
      if (haveUnsavedChanges) {
        setTimeout(() => {
          const confirmed = window.confirm(formatMessage('FlowBuilder.confirmUnsavedChanges'));
          if (confirmed) {
            resume();
          }
        });
      }
      return haveUnsavedChanges;
    };

    navigation.on(NavigationEventTypes.BeforeNavigation, confirm);

    return () => {
      navigation.off(NavigationEventTypes.BeforeNavigation, confirm);
    };
  }, [haveUnsavedChanges, handleSaveFlow, newFlowName]);

  if (haveUnsavedChanges && !haveIssues && !isCardDisabled && !changeableFlowModel.isLoading) {
    return (
      <Button
        disabled={isDisabled}
        size="large"
        onClick={() => handleSaveFlow(newFlowName)}
      >
        {formatMessage('FlowBuilder.button.save')}
      </Button>
    );
  }

  if (haveUnsavedChanges && haveIssues && !changeableFlowModel.isLoading) {
    return (
      <Button
        disabled
        size="large"
      >
        {formatMessage('FlowBuilder.button.save')}
      </Button>
    );
  }

  if (!productsInGraphModel.isLoaded || changeableFlowModel.isLoading) {
    return (
      <Button
        disabled={isDisabled}
        size="large"
        onClick={() => handleSaveFlow(newFlowName)}
      >
        {formatMessage('FlowBuilder.button.saving')}
      </Button>
    );
  }

  if (productsInGraphModel.isLoaded && !haveUnsavedChanges) {
    return (
      <Button
        disabled
        size="large"
        onClick={() => handleSaveFlow(newFlowName)}
      >
        {formatMessage('FlowBuilder.button.saved')}
      </Button>
    );
  }

  if (productsInGraphModel.isLoaded && haveUnsavedChanges && isCardDisabled && !haveIssues) {
    return (
      <Button
        disabled
        size="large"
      >
        {formatMessage('FlowBuilder.button.save')}
      </Button>
    );
  }

  return null;
}
