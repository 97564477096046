import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  errorContainer: {
    border: `1px solid ${theme.palette.common.yellow}`,
  },
  image: {
    flexShrink: 0,
    width: 50,
    height: 50,
    fontSize: 20,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  title: {
    fontSize: 18,
    marginBottom: 5,
    color: theme.palette.text.secondary,
  },
  readMoreLink: {
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'relative',
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.gray,
    borderRadius: 10,
    transition: '.25s all ease-in-out',
    '&:hover, &:focus': {
      filter: 'brightness(0.8)',
      backgroundColor: theme.palette.common.gray,
    },
  },
  externalIcon: {
    width: 20,
    height: 20,
  },
  badge: {
    padding: '3px 10px',
    color: theme.palette.common.lightblue,
    backgroundColor: theme.palette.common.whiteblue,
    borderRadius: 30,
    whiteSpace: 'nowrap',
    width: 'fit-content',
    fontWeight: 'bold',
    fontSize: '12px',
  },
}));
