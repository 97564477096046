import React from 'react';
import Box from '@material-ui/core/Box';
import { MexicanBuholegalCheck } from 'models/BackgroundCheck.model';
import { ItemValue } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './RecordFailedContent.styles';
import { RecordAgreements } from './components/RecordAgreements/RecordAgreements';

const fieldsOrder: (keyof Pick<ArrayElement<MexicanBuholegalCheck['records']>,
  'actor' | 'defendant' | 'record' | 'jurisdiction' | 'type' | 'date'>)[] = [
    'actor',
    'defendant',
    'record',
    'type',
    'jurisdiction',
    'date',
  ];

export const RecordFailedContent = ({
  className,
  record,
}: { className: string; record: ArrayElement<MexicanBuholegalCheck['records']> }) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Box className={className}>
      {fieldsOrder.map((fieldKey, idx) => (
        <ItemValue
          key={idx}
          title={formatMessage(`BackgroundCheck.verification.jsonView.${fieldKey}`)}
          value={record[fieldKey]}
          classNames={{ container: classes.item }}
        />
      ))}
      {record.agreements?.length > 0 && (
        <Box mt={2}>
          <RecordAgreements agreements={record.agreements} />
        </Box>
      )}
    </Box>
  );
};
