import { http } from 'lib/client/http';
import { analyticsTokenBodyType } from '../models/Analytics.model';

export function getStatistics(params) {
  return http.get('/api/v1/dashboard/analytics/verification/chart', { params });
}

export function getCountStatistics(params) {
  return http.get('/api/v1/dashboard/analytics/verification/stat', { params });
}

export function getAnalyticsToken(merchantId: string, body: analyticsTokenBodyType) {
  return http.post<{ guestToken: string }>(`/api/v1/dashboard/analytics/preset/token${merchantId ? `?asMerchantId=${merchantId}` : ''}`, body);
}
