import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Switch } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { SDKRedirectionOptionsTypes } from 'models/Flow.model';
import { useStyles } from './UserRedirectionOptions.style';

export const UserRedirectionOptions = ({
  sdkRedirectionOptionsState,
  onSDKRedirectOptionsSwitcher,
}: {
  sdkRedirectionOptionsState: any;
  onSDKRedirectOptionsSwitcher: (option: SDKRedirectionOptionsTypes, value: boolean) => void;
}) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      <Box
        mb={0.5}
        fontWeight="bold"
        color="common.black90"
      >
        {formatMessage('FlowBuilder.settings.title.sdkRedirectionOptions')}
      </Box>
      <Box
        mb={0.5}
        color="common.black75"
      >
        {formatMessage('FlowBuilder.settings.subtitle.sdkRedirectionOptions')}
      </Box>
      <FormGroup className={classes.formGroupRoot}>
        <FormControlLabel
          className={classes.formControlLabel}
          control={(
            <Switch
              checked={sdkRedirectionOptionsState.qr}
              onSwitch={(value) => onSDKRedirectOptionsSwitcher(SDKRedirectionOptionsTypes.QR, value)}
            />
          )}
          label={formatMessage('FlowBuilder.settings.sdkRedirectionOptions.option.QR')}
          labelPlacement="start"
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={(
            <Switch
              checked={sdkRedirectionOptionsState.email}
              onSwitch={(value) => onSDKRedirectOptionsSwitcher(SDKRedirectionOptionsTypes.Email, value)}
            />
          )}
          label={formatMessage('FlowBuilder.settings.sdkRedirectionOptions.option.Email')}
          labelPlacement="start"
        />
      </FormGroup>
      {sdkRedirectionOptionsState?.isError && (
        <Box
          mb={0.5}
          mt={1.5}
          fontWeight="bold"
          color="common.red"
        >
          {formatMessage('FlowBuilder.settings.sdkRedirectionOptions.error.message')}
        </Box>
      )}
    </FormControl>
  );
};
