import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import type { IStep } from 'models/Step.model';
import { BackgroundCheckStatusesTypes, getMexicanBuholegalChecks, IBackgroundCheckStepData, StepExtraMexicanBuholegal, mexicanBuholegalSummaryFieldsOrder, StructureSection } from 'models/BackgroundCheck.model';
import ReactJsonViewer from 'react-json-view';
import { useFormatMessage } from 'apps/intl';
import { BackgroundCheckSummary } from '../BackgroundCheckSummary/BackgroundCheckSummary';
import { BackgroundCheckInProgress } from '../BackgroundCheckInProgress/BackgroundCheckInProgress';
import { BackgroundChecksError } from '../BackgroundChecksError/BackgroundChecksError';
import { BackgroundCheckControls } from '../BackgroundCheckControls/BackgroundCheckControls';
import { BackgroundCheckStructure } from '../BackgroundCheckStructure/BackgroundCheckStructure';
import { MexicanRecordViewPassed } from './components/MexicanRecordViewPassed/MexicanRecordViewPassed';
import { MexicanRecordViewFailed } from './components/MexicanRecordViewFailed/MexicanRecordViewFailed';
import { MexicanRecordViewPartialMatched } from './components/MexicanRecordViewPartialMatched/MexicanRecordViewPartialMatched';
import { useBackgroundCheckParams } from '../../hooks/backgroundCheck.hook';

export function BackgroundChecksMexicanBuholegal({ step }: { step: IStep<IBackgroundCheckStepData<StepExtraMexicanBuholegal>> }) {
  const {
    isJsonVisible,
    setJsonVisible,
    isInProgress,
    couldBeRunManually,
  } = useBackgroundCheckParams(step);

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setJsonVisible(!step.data?.stepExtra?.length);
  }, [step.data?.stepExtra, setJsonVisible]);

  if (couldBeRunManually) {
    return null;
  }

  if (isInProgress) return <BackgroundCheckInProgress />;

  if (step.error && !step.data) return <BackgroundChecksError errorCode={step.error.code} />;

  const { passed, fullyMatched, partialMatched } = getMexicanBuholegalChecks(step.data?.stepExtra);

  const sections: StructureSection[] = [
    {
      titleKey: 'BackgroundCheck.verification.jsonView.failedChecks',
      component: MexicanRecordViewFailed,
      items: fullyMatched,
    },
    {
      titleKey: 'BackgroundCheck.verification.jsonView.partialMatchedChecks',
      component: MexicanRecordViewPartialMatched,
      items: partialMatched,
    },
    {
      titleKey: 'BackgroundCheck.verification.jsonView.passedChecks',
      component: MexicanRecordViewPassed,
      items: passed,
    },
  ];

  return (
    <Grid container spacing={2}>
      <BackgroundCheckSummary
        step={step}
        fieldsOrder={mexicanBuholegalSummaryFieldsOrder}
        scoreLabels={{
          [BackgroundCheckStatusesTypes.LowRisk]: formatMessage('BackgroundCheck.verification.jsonView.partialMatch'),
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BackgroundCheckControls
            step={step}
            isVisible={isJsonVisible}
            onSetVisibility={setJsonVisible}
            isToggleVisible={!step.data?.stepExtra?.length}
          />
          {isJsonVisible && (
            <Box className="fs-exclude">
              <ReactJsonViewer
                src={step}
                collapsed
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
              />
            </Box>
          )}
          {!isJsonVisible && Boolean(step.data?.stepExtra) && (<BackgroundCheckStructure sections={sections} />)}
        </Grid>
      </Grid>
    </Grid>
  );
}
