import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { BackgroundCheckStatusesTypes, IBackgroundCheckStepData } from 'models/BackgroundCheck.model';
import type { IStep } from 'models/Step.model';
import { VerificationStatusIcon } from './components/VerificationStatusIcon/VerificationStatusIcon';
import { useStyles } from './BackgroundCheckSummary.styles';
import { SummaryUserInfo } from './components/SummaryUserInfo/SummaryUserInfo';
import { BackgroundCheckScore } from './components/BackgroundCheckScore/BackgroundCheckScore';
import { getBackgroundCheckScore } from '../../models/BackgroundCheck.model';

export function BackgroundCheckSummary({ step: { id, data }, fieldsOrder, scoreLabels }: {
  step: IStep<IBackgroundCheckStepData>;
  fieldsOrder?: string[];
  scoreLabels?: {
    [BackgroundCheckStatusesTypes.Approved]?: string;
    [BackgroundCheckStatusesTypes.LowRisk]?: string;
    [BackgroundCheckStatusesTypes.HighRisk]?: string;
  };
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const score = getBackgroundCheckScore(id, data);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Typography variant="subtitle2" className={classes.header}>
              {formatMessage('BackgroundCheck.verification.summary')}
            </Typography>
            <Grid container spacing={2} className={classes.summaryContainer}>
              <Grid item md="auto" className={classes.summaryInfoBlock}>
                <Grid item className={classes.statusIcon} xs={12} sm="auto">
                  <VerificationStatusIcon type={data.status} />
                </Grid>
                <Grid item className={classes.summaryInfo} xs="auto" lg="auto">
                  <SummaryUserInfo
                    data={data}
                    stepId={id}
                    className={classes.summaryInfoContent}
                    fieldsOrder={fieldsOrder}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} lg="auto" className={classes.checkScore}>
                <BackgroundCheckScore
                  status={data.status}
                  score={score}
                  className={classes.score}
                  labels={scoreLabels}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
