import { DEFAULT_LOCALE, SupportedLocales } from 'models/Intl.model';
import { useCallback } from 'react';
import { devWarn } from 'lib/console';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'state/merchant/merchant.selectors';
import { IServerSideMessage } from '../models/ServerSideIntl.model';

export function useFormatServerMessage(): (message: IServerSideMessage) => string {
  const currentLang = useSelector<any, SupportedLocales>(selectLanguage);

  return useCallback((message: IServerSideMessage): string => {
    if (currentLang === DEFAULT_LOCALE) {
      return message.name;
    }
    const lang = message.locales.find((serverLocale) => serverLocale.countryCode === currentLang);
    if (!lang?.locale) {
      devWarn(`Missing translation for ${message.name}`);
      return message.name;
    }
    return lang.locale;
  }, [currentLang]);
}
