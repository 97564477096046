import classnames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { AppTheme } from 'apps/theme';
import { InfoTooltip } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { formatCurrency } from 'lib/currency';
import { useStyles } from '../BankAccountDataVerification/BankAccountDataVerification.styles';
import { BankAccountTransactionTypes, IBankAccountDataVerificationTransaction, ICashFlowChartData } from '../../models/BankAccountData.model';

const COLORS = [AppTheme.palette.chartCategory.purple70, AppTheme.palette.chartCategory.cyan50, AppTheme.palette.chartCategory.teal70, AppTheme.palette.chartCategory.magenta70, AppTheme.palette.chartCategory.red50, AppTheme.palette.chartCategory.red90, AppTheme.palette.chartCategory.green60, AppTheme.palette.chartCategory.blue80, AppTheme.palette.chartCategory.magenta50, AppTheme.palette.chartCategory.yellow50, AppTheme.palette.chartCategory.teal50, AppTheme.palette.chartCategory.cyan90, AppTheme.palette.chartCategory.orange70, AppTheme.palette.chartCategory.purple50, AppTheme.palette.common.chartSubtitle];
const LEGEND_VISIBLE_ITEMS = 5;

function CustomTooltip({ active, payload, currency, chartData, noDataMessage }: {
  active?: boolean;
  payload?: any;
  currency: string;
  chartData: ICashFlowChartData[];
  noDataMessage: string;
}) {
  const classes = useStyles();

  if (chartData.every((balancesData) => balancesData.amount === 0)) {
    return (
      <div className={classes.chartTooltip}>
        <div className={classes.noDataAvailable}>{noDataMessage}</div>
      </div>
    );
  }

  if (active && payload?.length > 0) {
    return (
      <div className={classes.chartTooltip}>
        <Grid container alignItems="baseline" justifyContent="space-between">
          <div className={classes.chartLabel}>{payload[0]?.payload?.category}</div>
          <div className={classes.chartValue}>{formatCurrency(payload[0]?.value as string, currency)}</div>
        </Grid>
      </div>
    );
  }

  return null;
}

const CustomLegend = ({ payload, showMoreMessage }: {
  payload?: any;
  showMoreMessage?: string;
}) => (
  <Box display="flex" flexWrap="wrap" justifyContent="center">
    {payload.slice(0, LEGEND_VISIBLE_ITEMS).map((entry, index) => (
      <Box display="flex" key={`item-${index}`} alignItems="center">
        <Box bgcolor={entry.color} width={10} height={10} borderRadius={6} marginRight={1} marginLeft={2} />
        <Box fontSize={12}>{entry.value}</Box>
      </Box>
    ))}
    {payload.length > LEGEND_VISIBLE_ITEMS && (
      <InfoTooltip
        title={(
          <Box>
            {payload
              .slice(LEGEND_VISIBLE_ITEMS)
              .map((entry) => entry.value)
              .join(', ')}
          </Box>
            )}
      >
        <Box marginLeft={1} color={AppTheme.palette.primary.main}>{showMoreMessage}</Box>
      </InfoTooltip>
    )}
  </Box>
);

const ActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }: {
  cx?: number;
  cy?: number;
  innerRadius?: number;
  outerRadius?: number;
  startAngle?: number;
  endAngle?: number;
  fill?: string;
}) => (
  <g>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 5}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  </g>
);

export function BankAccountCashFlowChart({ transactions, currency, activeTab }: {
  transactions: IBankAccountDataVerificationTransaction[];
  currency: string;
  activeTab: BankAccountTransactionTypes;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<Nullable<number>>(null);

  const cashFlowChartData = useMemo<ICashFlowChartData[]>(() => {
    const categoriesMap = transactions.reduce((acc, val) => {
      // eslint-disable-next-line no-param-reassign
      acc[val?.category] = acc[val?.category] || [];
      acc[val?.category].push(val);
      return acc;
    }, {});

    return Object.keys(categoriesMap)
      .map((category: string) => ({
        category,
        amount: categoriesMap[category].reduce((acc, val) => {
          if (val?.type === activeTab) {
            return acc + parseFloat(val?.amount);
          }
          return acc;
        }, 0),
      }))
      .filter((chartData: ICashFlowChartData) => chartData.amount > 0)
      .sort((a: ICashFlowChartData, b: ICashFlowChartData) => b?.amount - a?.amount);
  }, [transactions, activeTab]);

  const cashFlowTotal = cashFlowChartData.reduce((acc: number, value: ICashFlowChartData) => acc + value.amount, 0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieExit = () => {
    setActiveIndex(null);
  };

  const piechartConfig = {
    data: cashFlowChartData,
    dataKey: 'amount',
    nameKey: 'category',
    cx: '50%',
    cy: '50%',
    innerRadius: 78,
    outerRadius: 92,
    fill: '#82ca9d',
    startAngle: 90,
    endAngle: -270,
    isAnimationActive: false,
    labelLine: false,
    activeIndex,
    activeShape: (activeShapeProps) => <ActiveShape {...activeShapeProps} />,
    onMouseEnter: onPieEnter,
    onMouseLeave: onPieExit,
    label: (labelProps) => (
      labelProps.amount >= (cashFlowChartData[2]?.amount || 0) ? (
        <>
          <text
            fill={AppTheme.palette.common.black75}
            x={labelProps.x}
            y={labelProps.y}
            stroke="none"
            alignmentBaseline="middle"
            className="recharts-text recharts-pie-label-text"
            textAnchor="end"
          >
            <tspan x={labelProps.x} textAnchor={labelProps.textAnchor} fontSize={12} dy="0em">{labelProps.category}</tspan>
          </text>
          <text
            fill={AppTheme.palette.common.black90}
            x={labelProps.x}
            y={labelProps.y}
            stroke="none"
            alignmentBaseline="middle"
            className="recharts-text recharts-pie-label-text"
            textAnchor="end"
          >
            <tspan x={labelProps.x} textAnchor={labelProps.textAnchor} fontSize={14} dy="1em">{formatCurrency(labelProps.amount, currency)}</tspan>
          </text>
        </>
      ) : null
    ),
  };

  return (
    <ResponsiveContainer width="100%" height={320} className={classnames(classes.piechartContainer, 'fs-exclude')}>
      <PieChart margin={{ top: 0, right: 20, left: 30 }}>
        <Pie {...piechartConfig}>
          <Label value={formatCurrency(cashFlowTotal, currency, undefined, true)} position="center" fontSize={22} dy={-7} fill={AppTheme.palette.common.black} />
          <Label value={formatMessage(`BankAccountData.chart.${activeTab === BankAccountTransactionTypes.CREDIT ? 'totalDeposit' : 'totalWithdrawal'}`)} position="center" fontSize={16} dy={12} fill={AppTheme.palette.common.chartSubtitle} />
          {cashFlowChartData.map((entry: ICashFlowChartData, index: number) => (
            <Cell key={`cell-${index}`} fill={index >= COLORS.length ? COLORS[COLORS.length - 1] : COLORS[index]} />
          ))}
        </Pie>
        <Tooltip
          isAnimationActive={false}
          content={(tooltipProps) => <CustomTooltip currency={currency} chartData={cashFlowChartData} noDataMessage={formatMessage('BankAccountData.table.noDataAvailable')} {...tooltipProps} />}
        />
        <Legend
          content={(legendProps) => (
            <CustomLegend
              showMoreMessage={formatMessage('BankAccountData.chart.legendShowMore', { messageValues: { number: cashFlowChartData.length - LEGEND_VISIBLE_ITEMS } })}
              {...legendProps}
            />
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
