import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.up('lg')]: {
      width: '90vw',
      height: '92vh',
      maxHeight: '100vh',
      backgroundColor: theme.palette.common.whiteblue,
      marginLeft: '100px',
    },
  },
  container: {
    marginTop: 30,
    justifyContent: 'space-between',
  },
  previewContainer: {
    justifyContent: 'center',
    height: '78vh',
    alignItems: 'center',
    '& iframe': {
      width: 412,
      height: 664,
      borderRadius: 10,
    },
  },
  preview: {
    userSelect: 'none',
  },
  titleWrapper: {
    textAlign: 'left',
    marginLeft: 40,
  },
  subtitle: {
    width: '100%',
    fontSize: 16,
    fontWeight: 400,
  },
  buttonWrapper: {
    flexWrap: 'nowrap',
    marginRight: 40,
    width: 'auto',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderColor: theme.palette.common.whiteblue,
    height: 43,
    minHeight: 43,
    marginLeft: 20,
    fontSize: 14,
    fontWeight: 'bold',
    padding: 24,
    borderRadius: 6,
    '& svg': {
      marginRight: 5,
      fill: theme.palette.common.black,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.common.lightblue,
      },
      color: theme.palette.common.lightblue,
      backgroundColor: theme.palette.common.white,
    },
  },
  active: {
    backgroundColor: theme.palette.common.lightblue,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.whiteblue,
    height: 43,
    minHeight: 43,
    marginLeft: 20,
    fontSize: 14,
    fontWeight: 'bold',
    padding: 24,
    borderRadius: 6,
    '& svg': {
      marginRight: 5,
      fill: theme.palette.common.white,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.common.white,
      },
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
  btnContainer: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.lightblue,
    height: 50,
    minHeight: 48,
    minWidth: 254,
    marginLeft: 10,
    fontSize: 14,
    borderRadius: 6,
    paddingRight: 8,
    position: 'relative',
  },
  link: {
    maxWidth: 150,
    overflow: 'hidden',
    maxHeight: 26,
    padding: '10px 0 4px 10px',
    wordBreak: 'break-all',
  },
  resourceUrl: {
    maxWidth: '100%',
    fontSize: 14,
    color: theme.palette.common.green,
    border: `1px solid ${theme.palette.text.main}`,
    borderRadius: 5,
    '& > div': {
      '& > div': {
        wordBreak: 'break-all',
      },
    },
  },
  disabled: {
    color: theme.palette.common.black75,
  },
  qrCodeContainer: {
    position: 'absolute',
    top: 70,
    right: 0,
    width: 238,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    padding: 20,
    '& canvas': {
      margin: '40px 0 20px 0',
      width: '160px !important',
      height: '160px !important',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '60%',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
    },
  },
  qrCodeButton: {
    position: 'relative',
    minWidth: 200,
  },
  openLinkButton: {
    height: 40,
    width: 40,
    background: theme.palette.common.whiteblue,
    borderRadius: 4,
    minHeight: '30px !important',
    top: 5,
    padding: 0,
    left: 2,
    '& svg': {
      stroke: theme.palette.common.black,
    },
    '&:hover': {
      '& svg': {
        stroke: theme.palette.common.lightblue,
      },
      background: theme.palette.common.whiteblue,
    },
  },
  backButton: {
    position: 'absolute',
    fontWeight: 'bold',
    top: 40,
    left: 22,
    minWidth: 40,
    padding: 0,
    '& svg': {
      marginRight: 5,
      fontSize: 24,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.whiteblue,
    },
  },
}));
