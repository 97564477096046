import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { ExtendedDescription } from 'apps/ui';
import { ProductSettingsProps } from 'models/Product.model';
import { IVerificationPatterns } from 'models/VerificationPatterns.model';
import { cloneDeep } from 'lodash';
import { useFormatMessage } from 'apps/intl';
import { CreditCheckCountriesSettings } from '../CreditCheckCountriesSettings/CreditCheckCountriesSettings';
import { CreditCheckSettingTypes } from '../../models/CreditCheck.model';

export function CreditCheckSettings({ settings, onUpdate }: ProductSettingsProps) {
  const formatMessage = useFormatMessage();
  const handleUpdate = useCallback((settingId: CreditCheckSettingTypes) => (newValue: IVerificationPatterns | string) => {
    const newSettings = cloneDeep(settings);
    if (settingId === CreditCheckSettingTypes.CountriesCreditChecks) {
      newSettings[settingId].value = { ...newSettings[settingId].value, ...newValue as IVerificationPatterns };
    }
    onUpdate(newSettings);
  }, [onUpdate, settings]);

  return (
    <Box>
      <Box mb={1}>
        <ExtendedDescription
          title={formatMessage(`CreditCheck.settings.title.${CreditCheckSettingTypes.CountriesCreditChecks}`)}
        >
          <CreditCheckCountriesSettings
            verificationPattern={settings[CreditCheckSettingTypes.CountriesCreditChecks]?.value}
            onChange={handleUpdate(CreditCheckSettingTypes.CountriesCreditChecks)}
          />
        </ExtendedDescription>
      </Box>
    </Box>
  );
}
