import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import classnames from 'classnames';
import { localeNumber } from 'lib/number';
import { useStyles } from './ChartSpeedometer.styles';
import { labelRanges0To100, colorRangesList0To100 } from './ChartSpeedometer.config';

export function ChartSpeedometer({
  width = 220,
  height = 190,
  data = labelRanges0To100,
  value,
  labelSufix,
  labelPrefix,
  colorRanges = colorRangesList0To100,
  isAllowedAddition = false,
  isMultyColor = false,
  isHigherBetter = true,
  className,
}: {
  width?: number;
  height?: number;
  data?: { value: number }[];
  value: number | 'NA';
  colorRanges?: { value: number }[];
  labelSufix?: string;
  labelPrefix?: string;
  isAllowedAddition?: boolean;
  isMultyColor?: boolean;
  isHigherBetter?: boolean;
  className?: string;
}) {
  const score = Number.isNaN(value) ? 0 : value as number;
  const classes = useStyles();
  const colors = isHigherBetter ? [classes.red, classes.orange, classes.green, classes.brightgray] : [classes.green, classes.orange, classes.red, classes.brightgray];
  const maxScore = data.reduce((accumulator, curValue) => accumulator + curValue.value, 0);

  let colorIndex = 0;
  let currentValue = score;
  const fillColorRanges = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const item of colorRanges) {
    colorIndex += 1;
    if (item.value >= currentValue) {
      fillColorRanges.push({ value: isMultyColor ? currentValue : score });
      break;
    } else {
      if (isMultyColor) {
        fillColorRanges.push(item);
      }
      currentValue -= item.value;
    }
  }
  const fillColors = isMultyColor
    ? colors.slice(0, fillColorRanges.length)
    : [colors[score > maxScore ? colors.length - 2 : colorIndex - 1]];
  fillColors.push(colors[colors.length - 1]);
  fillColorRanges.push({ value: value > maxScore && !isMultyColor ? maxScore : maxScore - score });

  const commenPiechartConfig = {
    startAngle: 180,
    endAngle: 0,
    innerRadius: 70,
    outerRadius: 80,
    dataKey: 'value',
    isAnimationActive: false,
    labelLine: false,
    cx: width / 2,
    cy: height / 2,
  };

  return (
    <PieChart width={width + 30} height={height / 2 + 20} className={className}>
      <Pie
        {...commenPiechartConfig}
        className={classes.fillColor}
        data={data}
        label={({
          cx,
          cy,
          endAngle,
          innerRadius,
          outerRadius,
          value: labelValue,
          index,
        }) => {
          const RADIAN = Math.PI / 180;
          const radius = 10 + innerRadius + (outerRadius - innerRadius);
          const x = cx + radius * Math.cos(-endAngle * RADIAN);
          const y = cy + radius * Math.sin(-endAngle * RADIAN);
          let textAnchor: 'start'|'middle' | 'end';
          if (x === cx) {
            textAnchor = 'middle';
          } else if (x > cx) {
            textAnchor = 'start';
          } else {
            textAnchor = 'end';
          }
          return (
            <>
              <text
                x={x}
                y={y}
                className={classes.coordinateLabel}
                textAnchor={textAnchor}
                dominantBaseline={y === cy ? 'central' : 'end'}
              >
                {localeNumber(data
                  .slice(0, index)
                  .reduce(
                    (accumulator, curValue) => accumulator + curValue.value,
                    labelValue,
                  ))}
                {isAllowedAddition && index === data.length - 1 && '+'}
                {labelSufix}
              </text>
              <text
                x={cx}
                y={cy - 5}
                className={classnames(classes.label)}
                textAnchor="middle"
                dominantBaseline="central"
              >
                {value !== undefined && labelPrefix}
                {value === undefined ? 'N/A' : localeNumber(value)}
                {value !== undefined && labelSufix}
              </text>
            </>
          );
        }}
      />
      <Pie
        {...commenPiechartConfig}
        data={fillColorRanges}
        cornerRadius={isMultyColor ? 0 : 40}
      >
        {fillColors.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            className={fillColors[index % fillColors.length]}
          />
        ))}
      </Pie>
      {value > maxScore && (
        <circle cx={width - 30} cy={height / 2 + 13} r={4} className={colors[colors.length - 2]} />
      )}
    </PieChart>
  );
}
