import { Auth0Provider } from '@auth0/auth0-react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { AppRouter } from 'app.router';
import { AppBootstrap } from 'apps/AppBootstrap';
import { AppIntlProvider } from 'apps/intl';
import { OverlayContainer } from 'apps/overlay';
import { StoreProvider } from 'apps/store';
import { AppTheme } from 'apps/theme/app.theme';
import { NotificationsContainer } from 'apps/ui';
import 'clipboard-polyfill';
import 'core-js';
import 'lib/window';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { history } from 'apps/routing/history/history';
import { Routes } from 'models/Router.model';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
// Next line must stay on the top because of css variables
import 'styles/global.scss';

// set pdf.js worker, needed for react-pdf to work
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// eslint-disable-next-line no-console
console.log('MetaMap version', process.env.REACT_APP_VERSION);

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}${Routes.auth.signIn}`}
    audience={process.env.REACT_APP_API_URL}
    scope="read:current_user"
    useRefreshTokens
  >
    <MuiThemeProvider theme={AppTheme}>
      <StoreProvider>
        <AppIntlProvider>
          <BrowserRouter history={history} getUserConfirmation={() => {}}>
            <AppBootstrap />
            <AppRouter />
            <NotificationsContainer />
            <OverlayContainer />
          </BrowserRouter>
        </AppIntlProvider>
      </StoreProvider>
    </MuiThemeProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);
