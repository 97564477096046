import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useFormatMessage } from 'apps/intl';
import classNames from 'classnames';
import { ISettings } from 'models/Flow.model';
import { IVerificationPatterns } from 'models/VerificationPatterns.model';
import React, { useState } from 'react';
import { isNil } from 'lib/isNil';
import { IFlattenVerificationPatternConfig, isPatternSettingsChanged, toggleSubVerificationPattern, toggleVerificationPattern, VerificationInputTypes } from 'models/VerificationPatternsConfigs.model';
import { ReactComponent as CrossIcon } from 'assets/icon-btn-close.svg';
import { getDefaultSettings, GovernmentCheckSettingTypes, ProductSettingsGovCheck } from '../../models/GovCheckMerit.model';
import { DataSourceSelect } from '../DataSourceSelect/DataSourceSelect';
import { GovCheckAgeCheck } from '../GovCheckAgeCheck/GovCheckAgeCheck';
import { GovCheckExtensions } from '../GovCheckExtensions/GovCheckExtensions';
import { GovCheckFullNameMatch } from '../GovCheckFullNameMatch/GovCheckFullNameMatch';
import { useStyles } from './GovCheckPopper.style';

export function GovCheckPopper({ settings, govCheck, handleUpdate, onClose }: {
  govCheck: IFlattenVerificationPatternConfig;
  handleUpdate: (newData: {settingId: GovernmentCheckSettingTypes; value: unknown}[]) => void;
  settings: ProductSettingsGovCheck;
  onClose: () => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [newPatternSettings, setNewPatternSettings] = useState<ISettings>(govCheck.patternSetting);
  const [newVerificationPatterns, setNewVerificationPatterns] = useState<Partial<IVerificationPatterns>>(settings[GovernmentCheckSettingTypes.VerificationPatterns].value);

  const govCheckOption = govCheck.options?.find((option) => option.isActive);

  const isChanged = govCheckOption || isPatternSettingsChanged(newPatternSettings, getDefaultSettings(govCheck));

  const handleChangeDataSource = (value: VerificationInputTypes) => {
    setNewPatternSettings((prev) => ({
      ...prev,
      dataSource: value === VerificationInputTypes.Default ? null : value,
    }));
  };

  const handleToggleAgeCheck = () => {
    setNewPatternSettings((prev) => ({
      ...prev,
      ageCheckOn: !prev.ageCheckOn,
    }));
  };

  const handleTogglesFullNameMatch = () => {
    setNewPatternSettings((prev) => ({
      ...prev,
      fuzzyNameMatchThreshold: isNil(prev.fuzzyNameMatchThreshold) ? govCheck.defaultFuzzyNameMatchThreshold : null,
    }));
  };

  const handleChangesFullNameMatch = (_, value) => {
    setNewPatternSettings((prev) => ({
      ...prev,
      fuzzyNameMatchThreshold: value,
    }));
  };

  const handleToggleGovCheckOption = (option: IFlattenVerificationPatternConfig) => ({ target: { checked } }) => {
    setNewVerificationPatterns((verificationPatterns) => ({ ...verificationPatterns, ...toggleSubVerificationPattern(checked, govCheck, option) }));
  };

  const handleRemoveGovCheck = () => {
    handleUpdate([
      {
        settingId: GovernmentCheckSettingTypes.VerificationPatterns,
        value: toggleVerificationPattern(false, govCheck),
      },
    ]);
  };

  const handleResetDefault = () => {
    setNewPatternSettings(getDefaultSettings(govCheck));
    setNewVerificationPatterns((verificationPatterns) => ({ ...verificationPatterns, ...toggleVerificationPattern(true, govCheck) }));
  };

  const handleClose = () => {
    handleUpdate([
      {
        settingId: GovernmentCheckSettingTypes.PatternSettings,
        value: {
          [govCheck.patternName]: newPatternSettings,
        },
      },
      {
        settingId: GovernmentCheckSettingTypes.VerificationPatterns,
        value: newVerificationPatterns,
      },
    ]);
    onClose();
  };

  const clickAwayHandler = (event) => {
    // @anatoliy.turkin do not remove https://github.com/mui/material-ui/issues/12034
    if (event.target.localName === 'body') {
      return;
    }
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Paper className={classes.paper}>
        <IconButton className={classes.btnClose} onClick={handleClose}>
          <CrossIcon className={classes.crossIcon} />
        </IconButton>
        <DataSourceSelect
          disabled={govCheck.inputs.length === 1}
          addProductOnDestroy
          allowDefault
          inputs={govCheck.inputs}
          selectedDataSource={govCheck.inputs.length > 1 ? newPatternSettings.dataSource || VerificationInputTypes.Default : govCheck.inputs[0].id}
          handleChangeDataSource={handleChangeDataSource}
        />
        <GovCheckExtensions
          govCheck={govCheck}
          newVerificationPatterns={newVerificationPatterns}
          handleToggleGovCheckOption={handleToggleGovCheckOption}
        />
        {govCheck.isAgeCheckAllowed && (
          <GovCheckAgeCheck
            isAgeCheckEnabled={newPatternSettings.ageCheckOn}
            handleToggleAgeCheck={handleToggleAgeCheck}
          />
        )}
        {govCheck.fuzzyNameMatch && (
          <GovCheckFullNameMatch
            maxFuzzyNameMatch={govCheck.fuzzyNameMatch}
            isFullNameMatchEnabled={!isNil(newPatternSettings.fuzzyNameMatchThreshold)}
            currentFuzzyNameMatch={newPatternSettings.fuzzyNameMatchThreshold}
            handleTogglesFullNameMatch={handleTogglesFullNameMatch}
            handleChangesFullNameMatch={handleChangesFullNameMatch}
          />
        )}
        <Grid container>
          <Grid item xs={8}>
            <Button
              disabled={!isChanged}
              fullWidth
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={handleResetDefault}
            >
              {formatMessage('GovCheckMerit.settings.button.reset')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={handleRemoveGovCheck} fullWidth color="secondary" className={classNames(classes.deleteButton, classes.button)}>
              {formatMessage('GovCheckMerit.settings.button.delete')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </ClickAwayListener>
  );
}
