import { selectLoadableValue, selectModelValue } from 'lib/loadable.selectors';
import { Loadable } from 'models/Loadable.model';
import { ProductTypes } from 'models/Product.model';
import { DocumentTypes } from 'models/Document.model';
import { CountrySpecificCreditChecks, getStepExtra, IStep } from 'models/Step.model';
import { getVerificationExtras, groupVerificationsByFlow, PassedVerificationByFlow, IVerificationListItem, VerificationWithExtras, VerificationResponse } from 'models/VerificationOld.model';
import { createSelector } from 'reselect';
import { selectCountriesList } from 'state/countries/countries.selectors';
import { CreditCheckStep, IDataForCreditCheck } from 'models/CreditCheck.model';
import { isChangeableStatus } from 'models/Status.model';
import { VERIFICATION_STORE_KEY, VerificationSliceTypes, VerificationStore } from './Verification.store';
import { VerificationPatternTypes } from '../../../models/VerificationPatterns.model';

export const selectVerificationStore = (state: {VERIFICATION_STORE_KEY: VerificationStore}): VerificationStore => state[VERIFICATION_STORE_KEY];

export const selectVerificationModel = createSelector<[typeof selectVerificationStore], Loadable<VerificationResponse>>(
  selectVerificationStore,
  (store) => store[VerificationSliceTypes.Verification],
);

export const selectVerificationsCollectionModel = createSelector<[typeof selectVerificationStore], Loadable<IVerificationListItem[]>>(
  selectVerificationStore,
  (store) => store[VerificationSliceTypes.PassedVerifications],
);

export const selectVerificationProductList = createSelector<[typeof selectVerificationStore], ProductTypes[]>(
  selectVerificationStore,
  (store) => store.productList,
);

export const selectNewVerificationModelWithExtras = createSelector<[typeof selectVerificationModel, typeof selectCountriesList], Loadable<VerificationWithExtras>>(
  selectVerificationModel,
  selectCountriesList,
  selectLoadableValue((verification, countries) => getVerificationExtras(verification, countries)),
);

export const selectNewVerificationWithExtras = createSelector<[typeof selectNewVerificationModelWithExtras], VerificationWithExtras>(
  selectNewVerificationModelWithExtras,
  selectModelValue(),
);

export const selectVerification = createSelector<[typeof selectVerificationModel], VerificationResponse | null>(
  selectVerificationModel,
  selectModelValue<VerificationResponse | null>(),
);

export const selectVerificationDocumentsType = createSelector<[typeof selectVerification], DocumentTypes[]>(
  selectVerification,
  (verification) => verification?.documents?.map((document) => document.type),
);

export const selectVerificationsGroupedByFlow = createSelector<[typeof selectVerificationsCollectionModel], PassedVerificationByFlow[]>(
  selectVerificationsCollectionModel,
  selectModelValue((verifications: IVerificationListItem[]) => groupVerificationsByFlow(verifications)),
);

// TODO: @ggrigorev remove deprecated
/**
 * @deprecated
 */
export const selectVerificationStepsExtra = createSelector(
  selectNewVerificationWithExtras,
  (verification): IStep[] => verification.steps.map((step) => getStepExtra(step, verification)),
);

// TODO: @ggrigorev remove deprecated
/**
 * @deprecated
 */
export const selectVerificationSteps = createSelector<[typeof selectNewVerificationWithExtras], IStep[]>(
  selectNewVerificationWithExtras,
  (verification): IStep[] => [...verification?.documents?.reduce((acc, cur) => ([...acc, ...cur.steps]), []), ...verification?.steps],
);

export const selectCreditStep = createSelector<[typeof selectVerificationSteps], IStep<CreditCheckStep> >(
  selectVerificationSteps,
  (verificationSteps: IStep[]): IStep<CreditCheckStep> => (
    verificationSteps.find((step) => CountrySpecificCreditChecks.includes(step.id as VerificationPatternTypes))
  ),
);

export const selectVerificationId = createSelector(
  selectVerification,
  (verification): string => (verification?._id || verification?.id),
);

export const selectVerificationIsEditable = createSelector<[typeof selectVerification], boolean>(
  selectVerification,
  (verification) => isChangeableStatus(verification?.verificationStatus),
);

export const selectDataForCreditCheck = createSelector<[typeof selectCreditStep, typeof selectVerification, typeof selectVerificationId ], IDataForCreditCheck>(
  selectCreditStep,
  selectVerification,
  selectVerificationId,
  (creditStep, verification, id): IDataForCreditCheck => ({
    creditStep,
    verification,
    id,
  }),
);
