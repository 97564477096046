import { IChatWidget } from 'models/ChatWidget.model';

class FreshDeskChat implements IChatWidget {
  open() {
    if (this.isFreshDeskInit()) {
      window.fcWidget.open();
    }
  }

  hide() {
    if (this.isFreshDeskInit()) {
      window.fcWidget.hide();
    }
  }

  createNewMessage(message: string) {
    if (this.isFreshDeskInit()) {
      window.fcWidget.open({ replyText: message });
    }
  }

  isFreshDeskInit() {
    return window.fcWidget;
  }
}

export const FreshChatWidget = new FreshDeskChat();
