import { createSelector } from 'reselect';
import { Loadable } from 'models/Loadable.model';
import { ICardsOptions } from '../model/SolutionCatalog.model';
import { SOLUTION_CATALOG_STORE_KEY, SolutionCatalogStore } from './SolutionCatalog.store';

export const selectSolutionCatalogStore = (state: {SOLUTION_CATALOG_STORE_KEY: SolutionCatalogStore}): SolutionCatalogStore => state[SOLUTION_CATALOG_STORE_KEY];

export const selectAllTemplatesListModel = createSelector<[typeof selectSolutionCatalogStore], Loadable<ICardsOptions[]>>(
  selectSolutionCatalogStore,
  (store) => store.allTemplates,
);
