import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import React, { useMemo } from 'react';
import { IVerificationPatterns } from 'models/VerificationPatterns.model';
import { IFlattenVerificationPatternConfig, isCanUsePattern, isHidePattern, VerificationConfigurationErrorTypes } from 'models/VerificationPatternsConfigs.model';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription, SalesToolTip } from 'apps/ui';
import { useSelector } from 'react-redux';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { ReactComponent as InfoWithTooltipIcon } from '../../assets/InfoWithTooltip.svg';
import { useStyles } from './GovCheckExtensions.style';
import { PatternIssueTooltip } from '../PatternIssueTooltip/PatternIssueTooltip';

export function GovCheckExtensions({ govCheck, newVerificationPatterns, handleToggleGovCheckOption }: {
  govCheck: IFlattenVerificationPatternConfig;
  newVerificationPatterns: Partial<IVerificationPatterns>;
  handleToggleGovCheckOption(option: IFlattenVerificationPatternConfig): ({ target: { checked } }) => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);

  const options = useMemo(() => govCheck.options?.map((option) => {
    if (isHidePattern(option, merchantTags)) {
      return null;
    }
    const isNeedRequest: boolean = !isCanUsePattern(option, merchantTags);

    return (
      <ExtendedDescription
        key={option.patternValue}
        title={(
          <>
            {formatMessage(`GovCheck.check.${govCheck.patternName}.${option.patternValue}`)}
            {!isNeedRequest && newVerificationPatterns[govCheck.patternName] === option.patternValue && option.error.type !== VerificationConfigurationErrorTypes.None && (
            <PatternIssueTooltip
              govCheck={govCheck}
              errorType={option.error}
            >
              <InfoWithTooltipIcon className={classes.icon} />
            </PatternIssueTooltip>
            )}
            {isNeedRequest && (<SalesToolTip />)}
          </>
          )}
        titleColor="common.black75"
        textFontSize={10}
        postfix={(
          <Switch
            disabled={isNeedRequest}
            checked={newVerificationPatterns[govCheck.patternName] === option.patternValue}
            onChange={handleToggleGovCheckOption(option)}
            color="primary"
          />
          )}
      />
    );
  }), [classes.icon, formatMessage, govCheck, handleToggleGovCheckOption, merchantTags, newVerificationPatterns]);

  if (!govCheck.options) {
    return null;
  }

  return (
    <Box>
      <Box mb={0.5} fontWeight="bold" color="common.black90">{formatMessage('GovCheckMerit.settings.extensions')}</Box>
      {options}
    </Box>
  );
}
