import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { CreditCheckConfigurations, creditCheckCountriesOrder, creditCheckParse, verificationPatternsCreditChecksDefault } from 'apps/CreditCheck/models/CreditCheck.model';
import { useFormatMessage } from 'apps/intl';
import { IVerificationPatterns, VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { codedFlagMap } from 'assets/flags';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'apps/overlay';
import { productManagerService } from 'apps/Product';
import { useProductAddingFromSubStep } from 'apps/Product/hooks/ProductAddingFromSubStep';
import { ProductTypes } from 'models/Product.model';
import { CountrySpecificCreditChecks } from 'models/Step.model';
import { CountryCodeTypes } from 'models/Country.model';
import { flowBuilderProductAdd, flowBuilderProductRemove } from 'apps/flowBuilder';
import { useStyles } from './CreditCheckCountriesSettings.styles';
import { selectCanUseColombianCreditCheck } from '../../state/CreditCheck.selectors';

export function CreditCheckCountriesSettings({ verificationPattern, onChange }: {
     verificationPattern: Partial<IVerificationPatterns>;
     onChange: (value: Partial<IVerificationPatterns>) => void;
  }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const addProductFromSubStep = useProductAddingFromSubStep();
  const canUseColombianCreditCheck = useSelector<any, boolean>(selectCanUseColombianCreditCheck);
  const addDisclaimerPopup = useConfirm(formatMessage('CreditCheck.disclaimer.title'), (
    <Box>
      <Box mt={2} textAlign="left">
        {formatMessage('CreditCheck.disclaimer.info1')}
      </Box>
      <Box mt={2} mb={3} textAlign="left">
        {formatMessage('CreditCheck.disclaimer.info2')}
      </Box>
    </Box>
  ), false);

  const countriesInFeatureFlags = { [CountryCodeTypes.CO]: canUseColombianCreditCheck };

  const countriesList = creditCheckCountriesOrder.filter((country) => !Object.prototype.hasOwnProperty.call(countriesInFeatureFlags, country) || countriesInFeatureFlags[country]);

  const currentCheck = useMemo(() => Object.keys(verificationPattern)
    .filter((pattern: string) => CountrySpecificCreditChecks.includes(pattern as VerificationPatternTypes))
    .find((check) => verificationPattern[check] === true), [verificationPattern]);

  const checkListForCountry = (country: string) => {
    const found = CreditCheckConfigurations.find((item) => item.country === country);
    if (!found) {
      return null;
    }

    return creditCheckParse(found.checks, verificationPattern);
  };

  const handleCancle = (subStep: string) => {
    const { requiredProductTypeFromSubStep } = productManagerService.getProduct(ProductTypes.CreditCheck).getCard();
    onChange({ ...verificationPatternsCreditChecksDefault, [currentCheck]: true });
    dispatch(flowBuilderProductRemove(requiredProductTypeFromSubStep[subStep]));
  };

  const handleConfirm = (updatedCreditCheck: string, productToBeAdded?: ProductTypes) => {
    onChange({ ...verificationPatternsCreditChecksDefault, [updatedCreditCheck]: true });
    if (productToBeAdded) {
      dispatch(flowBuilderProductAdd(productToBeAdded));
    }
    if (updatedCreditCheck === VerificationPatternTypes.CreditMexicoCdc) {
      addDisclaimerPopup().catch(() => handleCancle(updatedCreditCheck));
    }
  };

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    addProductFromSubStep(ProductTypes.CreditCheck, value, (productToBeAdded) => handleConfirm(value, productToBeAdded));
  };

  return (
    <FormControl className={classes.control}>
      <RadioGroup
        aria-label="age-check-configuration"
        name="age-check-configuration"
        value={currentCheck}
        onChange={handleChange}
      >
        {countriesList.map((country) => (
          <Box key={country} className={classes.wrapper} p={1} pr={2} mt={1}>
            <Grid container alignItems="center" wrap="nowrap">
              <Box mr={1} className={classes.icon}>{codedFlagMap[country]}</Box>
              <Box color="common.black90" fontWeight="bold">
                {formatMessage(`Countries.${country}`)}
              </Box>
            </Grid>
            {country === CountryCodeTypes.BR && (
              <>
                <Box ml={3} mt={1} color="common.black90" fontWeight="bold">
                  {formatMessage('CreditCheck.check.credit-brazilian-serasa')}
                </Box>
              </>
            )}
            {checkListForCountry(country).map((radio) => (
              <Box key={radio.id} mt={1} className={classes.check}>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={(
                    <Box color="common.black75" fontWeight="bold">
                      {formatMessage(`CreditCheck.check.${radio.id}`)}
                    </Box>
                  )}
                  labelPlacement="start"
                  value={radio.id}
                />
              </Box>
            ))}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
}
