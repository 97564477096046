import { applyMiddleware, createStore } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import { IDENTITIES_STORE_KEY } from 'state/identities/identities.store';
import { rootReducers } from 'state/reducers';
import { sessionExpired } from './middleware/sessionExpired.redux-middleware';

const persistedReducer = persistReducer({
  key: 'mgi-dashboard-4',
  whitelist: [
    IDENTITIES_STORE_KEY,
  ],
  storage,
}, rootReducers);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk, sessionExpired)),
);

export const persistor = persistStore(store, null, () => {
  // invoked after store rehydrate
});
