import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  select: {
    minHeight: 50,
    borderRadius: 4,
    border: `1px solid ${theme.palette.common.darkgrey}`,
    width: '100%',
    padding: '0 22px 0 10px',
    marginTop: 10,
    color: theme.palette.common.black75,
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.common.lightblue,
    },
    input: {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-input': {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.black75,
    },
    '& .MuiSelect-icon': {
      marginTop: 3,
      fontSize: 17,
      right: 12,
      color: theme.palette.common.black75,
    },
  },
  sliderContainer: {
    width: '100%',
  },
  sliderBox: {
    width: '95%',
  },
  slider: {
    marginLeft: '5%',
    marginTop: '10px',
  },
}));
