import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.common.whiteblue}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '4px',
    width: '29px',
    height: '29px',
    background: theme.palette.common.whiteblue,
    border: `1px solid ${theme.palette.common.lightblue}`,
    borderRadius: '50%',
    color: theme.palette.common.lightblue,
  },
  body: {
    color: theme.palette.common.lightblue,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
  },
  title: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
  },
  text: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 400,
    opacity: 0.7,
  },
  close: {
    color: theme.palette.common.lightblue,
  },
}));
