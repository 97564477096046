import { ProductTypes } from 'models/Product.model';
import { createTypesSequences } from 'state/store.utils';
import { SliceNameTypes } from './MeritBlockAdditional.store';

export const types = createTypesSequences(SliceNameTypes);

const importAndGetData = async (moduleNames) => {
  try {
    const importResults = await Promise.all(
      moduleNames.map(async (moduleName) => {
        try {
          const importedModule = await import(`../models/${moduleName}.model.ts`);
          return { [moduleName]: importedModule[moduleName] };
        } catch (error) {
          console.error(`Error importing ${moduleName}.model.ts:`, error);
          return { [moduleName]: null };
        }
      }),
    );
    const filteredResults = importResults.filter((result) => result[Object.keys(result)[0]] !== null);
    return Object.assign({}, ...filteredResults);
  } catch (error) {
    console.error('Error in importAndGetData:', error);
    throw error;
  }
};

export const getMeritBlocksAdditional = (meritBlocks: ProductTypes[]) => async (dispatch) => {
  dispatch({ type: types.meritBlocksAdditional_REQUEST });
  try {
    const response = await importAndGetData(meritBlocks);
    dispatch({ type: types.meritBlocksAdditional_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: types.meritBlocksAdditional_FAILURE });
  }
};
