import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { EditableText } from 'apps/ui';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Modal, useOverlay } from 'apps/overlay';
import { useMerchantPlan } from 'apps/merchant';
import { useFreemium } from 'apps/Freemiun';
import { useFormatMessage } from 'apps/intl';
import { appcues, CustomAppcuesActions } from 'apps/Appcues';
import { navigation, NavigationEventTypes } from 'apps/layout';
import { Button, Icon, Tooltip } from 'lib/UI';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IFlow } from 'models/Flow.model';
import { selectCurrentFlow } from 'state/merchant/merchant.selectors';
import { QATags } from 'models/QA.model';
import { Routes } from 'models/Router.model';
import { Save } from '../Save/Save';
import { Preview } from '../Preview/Preview';
import { selectFlowBuilderChangeableFlow } from '../../store/FlowBuilder.selectors';
import { FlowSettings } from '../FlowSettings/FlowSettings';
import { useStyles } from './FlowHeader.styles';

export function FlowHeader({ canEdit, isEditable, newFlowName, setIsEditable, onSubmit, onCancel, validator, handleIntegrate, handleCustomize }: {
  canEdit?: boolean;
  isEditable?: boolean;
  newFlowName?: string;
  setIsEditable?: Dispatch<SetStateAction<boolean>>;
  onSubmit?: (text: string) => void;
  onCancel?: () => void;
  validator?: (text: string) => void;
  handleIntegrate?: () => void;
  handleCustomize?: () => void;
}) {
  const history = useHistory();
  const formatMessage = useFormatMessage();
  const { id } = useParams();
  const { comparePlans } = useFreemium();
  const currentFlowId = useSelector<any, IFlow>(selectCurrentFlow);
  const flowBuilderChangeableFlow = useSelector<any, IFlow>(selectFlowBuilderChangeableFlow);
  const { name } = (!id || !currentFlowId) ? flowBuilderChangeableFlow : currentFlowId;
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();
  const handleOpenFlowSettings = useCallback(() => {
    createOverlay(<Modal className={classes.modal} onClose={closeOverlay}><FlowSettings onClose={closeOverlay} /></Modal>);
  }, [classes.modal, closeOverlay, createOverlay]);
  const { isFreemium } = useMerchantPlan();
  const handleOpenHistory = () => history.push(Routes.flow.history.replace(':id', id));

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      gridGap="10px"
    >
      {canEdit ? (
        <Grid item container className={classes.editField}>
          <EditableText
            enabled={isEditable}
            setEditable={setIsEditable}
            submitEditable={onSubmit}
            cancelEditable={onCancel}
            validator={validator}
            value={newFlowName || name}
            qaTag={QATags.FlowBuilder.Settings.FlowNameInput}
          />
          {!isEditable && (
          <Button
            size="mini"
            type="secondary"
            data-qa={QATags.FlowBuilder.Settings.EditFlowNameButton}
            onClick={() => {
              setIsEditable((prevState: boolean) => !prevState);
              appcues.action(CustomAppcuesActions.EditedFlowName);
            }}
          >
            <Icon name="edit" />
          </Button>
          )}
        </Grid>
      ) : name}
      <Box flex={1} />
      <Tooltip
        tooltip={formatMessage('FlowBuilder.button.history')}
        bias="bottom"
      >
        <Button
          id="flow-history-button"
          type="icon"
          size="large"
          onClick={() => {
            const interrupted = navigation.trigger(NavigationEventTypes.BeforeNavigation, handleOpenHistory);
            if (!interrupted) {
              handleOpenHistory();
            }
          }}
        >
          <Icon name="history" />
        </Button>
      </Tooltip>
      <Tooltip
        tooltip={formatMessage('FlowBuilder.button.integrate')}
        bias="bottom"
      >
        <Button
          type="icon"
          size="large"
          id="integrate-flow-button"
          onClick={handleIntegrate}
        >
          <Icon name="code" />
        </Button>
      </Tooltip>
      <Tooltip
        tooltip={formatMessage('FlowBuilder.button.settings')}
        bias="bottom"
      >
        <Button
          id="flow-settings-button"
          type="icon"
          size="large"
          onClick={handleOpenFlowSettings}
        >
          <Icon name="settings" />
        </Button>
      </Tooltip>
      <Tooltip
        tooltip={formatMessage('FlowBuilder.button.customize')}
        bias="bottom"
      >
        <Button
          id="flow-settings-button"
          type="icon"
          size="large"
          onClick={handleCustomize}
        >
          <Icon name="customize" />
        </Button>
      </Tooltip>
      <Preview />
      {isFreemium && (
        <Box>
          <Button
            id="upgrade-button"
            size="large"
            data-qa={QATags.Menu.Upgrade}
            onClick={() => {
              appcues.action(CustomAppcuesActions.ClickedUpgradeButton);
              comparePlans();
            }}
          >
            {formatMessage('FlowBuilder.button.upgrade')}
          </Button>
        </Box>
      )}
      <Save newFlowName={newFlowName} />
    </Box>
  );
}
