// TODO: replace with CountryCodeTypes once there is a full list of possible values
export type CountryCode = string; // code: "US"

export enum WorldPartsBackendTypes {
  Africa = 'Africa',
  Asia = 'Asia',
  AsiaAndPacific = 'Asia and Pacific',
  CentralAmericaAndTheCarribean = 'Central America and the Carribean',
  Europe = 'Europe',
  MiddleEast = 'Middle East',
  NorthAmerica = 'North America',
  OceaniaAndPacificAsia = 'Oceania and Pacific Asia',
  SouthAmerica = 'South America',
}

interface ICountryData {
  country: string; // name: "United states"
  worldPart: WorldPartsBackendTypes;
}

export interface ICountryAndWorldPartMetadata {
  _id: string;
  type: 'country';
  name: CountryCode;
  data?: ICountryData;
}
