import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { IoIosArrowBack } from 'react-icons/io';
import { Typography } from '@material-ui/core';
import { useFormatMessage } from 'apps/intl';
import Link from '@material-ui/core/Link';
import { PromoBanner } from 'apps/ui';
import { useStyles } from './NoAccess.styles';

export function NoAccess({ companyName, onGoBack }: {
  companyName: string;
  onGoBack: () => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <PromoBanner
      classNames={{ rightSide: classes.rightSide }}
    >
      <Box className={classes.container}>
        <IconButton
          className={classes.backButton}
          onClick={onGoBack}
          size="small"
        >
          <IoIosArrowBack />
        </IconButton>
        <Typography className={classes.title}>
          {formatMessage('signup.noAccess.joinTitle', {
            messageValues: {
              email: (
                <Link
                  color="primary"
                  href="mailto:support@metamap.com"
                  rel="noopener"
                >
                  support@metamap.com
                </Link>
              ),
            },
          })}
        </Typography>
        <Typography className={classes.subtitle}>
          {companyName?.length > 0 && `${companyName.charAt(0).toUpperCase() + companyName.slice(1) + formatMessage('signup.noAccess.joinSubtitle')}\n`}
          {formatMessage('signup.noAccess.reachAdmin')}
        </Typography>
      </Box>
    </PromoBanner>
  );
}
