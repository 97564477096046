import { ChipText } from 'apps/ui';
import { DateString, getDaysUntilEnd } from 'lib/date';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { QATags } from 'models/QA.model';
import { useStyles } from './DurationFreeTrialChip.styles';

export function DurationFreeTrialChip({ startDate, endDate }: {startDate: DateString; endDate: DateString}) {
  const [currentDay, daysDuration] = getDaysUntilEnd(startDate, endDate);
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <ChipText
      text={formatMessage('SubscriptionStatus.label.daysLeft',
        {
          messageValues:
            {
              startDay:
              currentDay,
              endDay: daysDuration,
            },
        })}
      className={classes.chip}
      qaTag={QATags.SubscriptionStatus.FreeTrial.DurationData}
    />
  );
}
