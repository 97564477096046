import axios, { AxiosError, AxiosResponse } from 'axios';
import { http } from 'lib/client/http';
import { ClientMethodTypes } from 'models/Client.model';

export async function getFileContents(url: string): Promise<AxiosResponse> {
  const headers = await http.getHeaders(ClientMethodTypes.GET);
  return axios.get(url, { headers, withCredentials: true });
}

export function isAxiosError(error: unknown): error is AxiosError {
  return axios.isAxiosError(error);
}
