import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  title: {
    fontSize: '21px',
    marginBottom: '6px',
    fontWeight: 600,
  },
  subTitle: {
    color: 'var(--mgi-theme-palette-darkgrey)',
    fontSize: '16px',
    marginBottom: '21px',
    fontWeight: 600,
  },
  emailTitle: {
    color: 'var(--mgi-theme-palette-darkgrey)',
    fontSize: '16px',
    marginBottom: '6px',
    fontWeight: 500,
  },
  email: {
    fontSize: '21px',
    fontWeight: 700,
  },
  red: {
    color: theme.palette.common.red,
  },
  green: {
    color: theme.palette.common.green,
  },
  yellow: {
    color: theme.palette.common.yellow2,
  },
}));
