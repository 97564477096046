import React, { ReactNode } from 'react';
import { useSlate } from 'slate-react';
import { isMarkActive, MarkFormatTypes, toggleMark } from '../../models/TextEditor.model';
import { Button } from '../Button/Button';

export const MarkButton = ({ format, icon }: {
  format: MarkFormatTypes;
  icon: ReactNode;
}) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};
