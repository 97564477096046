import { makeStyles } from '@material-ui/core/styles';

const CARD_WIDTH = 434;
const CARD_HEIGHT = 280;
const INTERACTIVE_HEIGHT = 139;

export const useStyles = makeStyles((theme) => ({
  templateCard: {
    boxSizing: 'border-box',
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.common.black7}`,
  },
  interactiveContainer: {
    maxWidth: CARD_WIDTH - 2,
    minWidth: CARD_WIDTH - 2,
    maxHeight: INTERACTIVE_HEIGHT,
    minHeight: INTERACTIVE_HEIGHT,
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: 21,
    borderBottom: `1px solid ${theme.palette.common.black50}`,
  },
  hoveredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.black7,
    borderRadius: '12px',
    height: '100%',
    width: '100%',
  },
  infoContainer: {
    maxWidth: CARD_WIDTH,
    minWidth: CARD_WIDTH,
    maxHeight: CARD_HEIGHT - INTERACTIVE_HEIGHT,
    minHeight: CARD_HEIGHT - INTERACTIVE_HEIGHT,
    padding: 12,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexBasis: '100%',
    marginRight: '16px',
    maxWidth: '79px',
    minWidth: '79px',
  },
  iconBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    maxWidth: '66px',
    minWidth: '66px',
    maxHeight: '66px',
    minHeight: '66px',
    marginBottom: 8,
    borderRadius: '50%',
    background: theme.palette.common.whiteblue,
  },
  icon: {
    strokeWidth: 1.5,
    width: 24,
    height: 24,
    color: theme.palette.common.black90,
  },
  label: {
    color: theme.palette.common.black75,
    textAlign: 'center',
    fontSize: 12,
  },
  otherMeritsIcon: {
    border: `1px solid ${theme.palette.common.black90}`,
    color: theme.palette.common.black90,
    background: 'none',
  },
  button: {
    cursor: 'pointer',
    padding: '12px 20px',
    boxSizing: 'border-box',
    borderRadius: '6px',
  },
  buttonFilled: {
    marginRight: 16,
    color: theme.palette.common.white,
    background: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      color: theme.palette.common.white,
      background: theme.palette.common.lightbluehover,
    },
  },
  buttonOutlined: {
    border: `2px solid ${theme.palette.common.lightblue}`,
    color: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      color: theme.palette.common.lightbluehover,
      borderColor: theme.palette.common.lightbluehover,
    },
  },
  textContainer: {
    height: '82px',
    padding: 6,
    marginBottom: 6,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 10,
    lineHeight: '22px',
    color: theme.palette.common.black90,
    wordWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '19px',
    color: theme.palette.common.black90,
    wordWrap: 'break-word',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
  },
}));
