import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    padding: '8px 12px',
    borderRadius: 6,
    minWidth: 'fit-content',
    minHeight: '40px !important',
    fontSize: 14,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
    '&.Mui-disabled': {
      opacity: 0.3,
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
    '& svg': {
      marginRight: 2,
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
