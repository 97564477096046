import { ProductMessageTypes, TIME_TO_INTERACTIVE_TIMEOUT } from 'models/Product.model';

type MessageListener = (data?: any) => void;

let TARGET_ORIGIN = '*';
try {
  TARGET_ORIGIN = (new URL(process.env.REACT_APP_PRODUCT_REGISTRY_URL)).origin;
} catch (error) {
  console.error(error, `Couldn't extract origin from ${process.env.REACT_APP_PRODUCT_REGISTRY_URL}`);
}

export class MessageClient {
  private target: Window | null = null;
  private targetOrigin: string = TARGET_ORIGIN;
  private listeners: Map<string, MessageListener[]> = new Map();
  private timeout: number | undefined = undefined;
  private timer: number | undefined = undefined;

  constructor(frame: HTMLIFrameElement, onTimeout?: () => void, timeout: number = TIME_TO_INTERACTIVE_TIMEOUT) {
    this.timeout = timeout;
    this.close = this.close.bind(this);
    this.send = this.send.bind(this);
    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    this.messageHandler = this.messageHandler.bind(this);

    window.clearTimeout(this.timer);
    if (!frame) {
      return;
    }

    window.addEventListener('message', this.messageHandler);
    this.target = frame.contentWindow;

    if (onTimeout) {
      this.timer = window.setTimeout(() => {
        onTimeout();
      }, this.timeout);
    }
  }

  close() {
    if (!this.target) {
      return;
    }

    this.target = null;
    this.listeners = new Map();
    window.removeEventListener('message', this.messageHandler);
  }

  private messageHandler(payload: MessageEvent) {
    if (TARGET_ORIGIN !== payload.origin) {
      console.error('Product client error: origin is not permitted');
      return;
    }

    console.info('[METAMAP] Platform received event: ', payload);
    if (!payload?.data) {
      console.error('Product client error: invalid message format');
      return;
    }

    const data = payload.data as { type: ProductMessageTypes, payload: any };

    if (this.listeners.has(data.type)) {
      window.clearTimeout(this.timer);
      const listeners = this.listeners.get(data.type);
      listeners.forEach((listener: MessageListener) => listener(data.payload));
    }
  }

  send(type: string, payload?: any) {
    console.info('[METAMAP] Platform send event: ', type, payload);
    this.target.postMessage({ type, payload }, this.targetOrigin);
  }

  on(type: string, callback: MessageListener) {
    if (this.listeners.has(type)) {
      this.listeners.get(type).push(callback);
    } else {
      this.listeners.set(type, [callback]);
    }
  }

  off(type: string, callback: MessageListener) {
    if (!this.listeners.has(type)) {
      return;
    }

    const listeners = this.listeners.get(type);
    if (listeners.length === 1) {
      this.listeners.delete(type);
    } else {
      this.listeners.set(type, listeners.filter((listener) => listener !== callback));
    }
  }
}
