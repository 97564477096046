import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './BannerSubscriptionStatus.styles';

export function BannerSubscriptionStatus({ message, prefixElement, children }: {
  message?: React.ReactNode;
  prefixElement?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.container} elevation={0}>
      <Grid container wrap="nowrap" justifyContent="center" alignItems="center" className={classes.grid}>
        {prefixElement && (
        <Grid item className={classes.item}>
          {prefixElement}
        </Grid>
        )}
        {message && (
        <Grid item className={classes.item}>
          {message}
        </Grid>
        )}
        {children && (
        <Grid item className={classes.item}>
          {children}
        </Grid>
        )}
      </Grid>
    </Paper>
  );
}
