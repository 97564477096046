import React from 'react';
import { EmailValidationStep } from 'models/EmailCheck.model';
import { EmailResult } from '../EmailRisk/components/EmailResult/EmailResult';

export function EmailValidation({ step }: {
  step: EmailValidationStep;
}) {
  if (!step) {
    return null;
  }
  const title = step.error ? `SecurityCheckStep.${step.error?.code}` : 'EmailCheck.result.valid.title';
  return <EmailResult title={title} data={step.data} error={step.error} />;
}
