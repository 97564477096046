import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import { IMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { useFormatMessage, useFormatServerMessage } from 'apps/intl';
import { IProductCard } from 'models/Product.model';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { InfoTooltip } from 'apps/ui';
import { useStyles } from './ProductAdditionalDetails.styles';

const Badge = ({ text, className }: { text: string; className: string}) => (
  <Box className={className}>
    <Box p="6px">
      {text}
    </Box>
  </Box>
);

export function ProductAdditionalDetails({ meritBlocksAdditional, card }: { meritBlocksAdditional: IMeritBlocksAdditional; card: IProductCard }) {
  const classes = useStyles();
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const formatServerMessage = useFormatServerMessage();

  return (
    <Box width="100%" display="flex">
      <Box mt={1.2} ml={0.5}>
        <Box display="flex">
          <Typography id="processes_title" className={classes.processesTitle}>{formatMessage('FlowBuilder.products.processes.title')}</Typography>
          <Box marginLeft="8px" display="inline-block" marginTop="-1px">
            <InfoTooltip
              placement="right"
              title={formatMessage('FlowBuilder.products.processesTooltipText')}
              popperClassname={classes.popperClassName}
              arrowClassname={classes.arrowClassName}
            >
              <AiFillQuestionCircle color={theme.palette.common.darkgrey} size={16} cursor="pointer" />
            </InfoTooltip>
          </Box>
        </Box>
        <Box className={classes.badgesContainer}>
          {meritBlocksAdditional[card.id] && (
            meritBlocksAdditional[card.id].processes?.map((process) => <Badge key={process.name} text={formatServerMessage(process)} className={classes.processBadge} />)
          )}
        </Box>
        <Box id="inputs_title_and_tooltip_container" display="flex" mt="16px">
          <Typography id="inputs_title" className={classes.processesTitle}>{formatMessage('FlowBuilder.products.inputs.title')}</Typography>
          <Box marginLeft="8px" display="inline-block" marginTop="-1px">
            <InfoTooltip
              placement="right"
              title={formatMessage('FlowBuilder.products.inputsTooltipText')}
              popperClassname={classes.popperClassName}
              arrowClassname={classes.arrowClassName}
            >
              <AiFillQuestionCircle color={theme.palette.common.darkgrey} size={16} cursor="pointer" />
            </InfoTooltip>
          </Box>
        </Box>
        <Box id="inputs_badges_container" className={classes.badgesContainer}>
          {meritBlocksAdditional[card.id] && (
            meritBlocksAdditional[card.id].inputTypes?.map((inputType) => <Badge key={inputType.name} text={formatServerMessage(inputType)} className={classes.inputBadge} />)
          )}
        </Box>
        <Box id="merits_title_and_tooltip_container" display="flex" mt="16px">
          <Typography id="inputs_title" className={classes.processesTitle}>{formatMessage('FlowBuilder.products.outputs.title')}</Typography>
          <Box marginLeft="8px" display="inline-block" marginTop="-1px">
            <InfoTooltip
              placement="right"
              title={formatMessage('FlowBuilder.products.outputsTooltipText')}
              popperClassname={classes.popperClassName}
              arrowClassname={classes.arrowClassName}
            >
              <AiFillQuestionCircle color={theme.palette.common.darkgrey} size={16} cursor="pointer" />
            </InfoTooltip>
          </Box>
        </Box>
        <Box id="merits_badges_container" className={classes.badgesContainer}>
          {meritBlocksAdditional[card.id] && (
            meritBlocksAdditional[card.id].meritTypes?.map((meritType) => <Badge key={meritType.name} text={formatServerMessage(meritType)} className={classes.inputBadge} />)
          )}
        </Box>
      </Box>
    </Box>
  );
}
