import { ProductTypes } from 'models/Product.model';
import { productManagerService } from '../services/ProductManager.service';

export function getMatchesAddedProductInGraph(requiredProductId: ProductTypes | ProductTypes[], productsInGraph: ProductTypes[]): Nullable<ProductTypes | ProductTypes[]> {
  if (Array.isArray(requiredProductId)) {
    const requiredProductsInGraph = requiredProductId.filter((productId) => productsInGraph.includes(productId));
    return requiredProductsInGraph.length > 0 ? null : requiredProductId;
  }

  return productsInGraph.includes(requiredProductId) ? null : requiredProductId;
}

export function getMatchesRemovedProductInGraph(productType: ProductTypes, productsInGraph: ProductTypes[]) {
  const { dependentProductTypes } = productManagerService.getProduct(productType).getCard();
  const activeDependentProductTypes = dependentProductTypes.filter((dependentProductType) => productsInGraph.includes(dependentProductType));
  const productsWithSameDependencies = activeDependentProductTypes
    .filter((productId) => [productManagerService.getProduct(productId).getCard().requiredProductType].flat()
      .some((currentProductId) => currentProductId !== productType && productsInGraph.includes(currentProductId)));

  return activeDependentProductTypes.filter((productId) => !productsWithSameDependencies.includes(productId));
}
