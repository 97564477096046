import { getFileContents } from 'lib/client/checks';
import { get } from 'lodash';
import { IdentityStatuses } from 'models/Status.model';
import { FilterRangesByLocal, FilterRangeTypes, initDateFilter } from './Filter.model';
import { ITEMS_PER_PAGE } from './Pagination.model';

export type IdentityId = string; // use only if you are sure that this is identity id, not verification id

const DATE_CREATED_START = 'dateCreated[start]';
const DATE_CREATED_END = 'dateCreated[end]';

export interface IGlobalFilter {
  name?: string;
  documentNumber?: string;
  metadata?: string;
  email?: string;
  verificationId?: string;
  identityId?: string;
}
export interface IIdentityFilter extends IGlobalFilter {
  [DATE_CREATED_START]?: Date;
  [DATE_CREATED_END]?: Date;
  status?: IdentityStatuses[];
  flowIds?: string[];
  offset?: number;
  limit?: number;
  search?: string;
  sortOrder?: OrderDirectionsNumTypes.asc | OrderDirectionsNumTypes.desc;
  sortBy?: any; // is used?
}

export enum VerificationSummaryTitleTypes {
  document = 'document',
  additional = 'additional',
  biometric = 'biometric',
  device = 'device',
}

export enum OrderDirectionsNumTypes {
  asc = '-1',
  desc = '1',
}

export enum OrderKeyTypes {
  sourceStartedAt = 'sourceStartedAt',
  fullName = 'fullName',
  id = 'id',
  verificationFlow = 'verificationFlow',
  status = 'status',
  type = 'type',
  ttv = 'ttv',
}

export interface ITableColumnsData {
  id: OrderKeyTypes;
  beta?: true;
}

export const tableColumnsData: ITableColumnsData[] = [
  {
    id: OrderKeyTypes.id,
  },
  {
    id: OrderKeyTypes.fullName,
  },
  {
    id: OrderKeyTypes.type,
  },
  {
    id: OrderKeyTypes.verificationFlow,
  },
  {
    id: OrderKeyTypes.sourceStartedAt,
  },
  {
    id: OrderKeyTypes.ttv,
  },
  {
    id: OrderKeyTypes.status,
  },
];

export function getGoBackToListLink(location, listRoute: string) {
  return location.state?.from?.startsWith(listRoute)
    ? location.state.from
    : listRoute;
}

export function getDownloadableFileName(verification) {
  if (!verification) {
    return null;
  }
  const flowName = get(verification, 'flow.name', null);
  const { id, fullName: name } = verification;
  return `${flowName?.replaceAll(' ', '_').concat('_')}${name ? name.replaceAll(' ', '_') : id}`;
}

export async function getNom151FileContent(digitalSignatureData = {} as any): Promise<string> {
  let fileContent;
  try {
    const file = await getFileContents(digitalSignatureData.publicUrl);
    fileContent = file?.data || digitalSignatureData.publicUrl;
  } catch {
    fileContent = digitalSignatureData.publicUrl;
  }
  return fileContent;
}

export const verificationsFilterInitialState = {
  ...initDateFilter,
  search: '',
  status: [],
  flowIds: [],
  sortOrder: null,
  sortBy: null,
  offset: 0,
  limit: ITEMS_PER_PAGE,
};

export const verificationsFilterStructure = {
  search: 'search',
  status: 'status',
  flowIds: 'flowIds',
  'dateCreated[start]': 'dateCreated[start]',
  'dateCreated[end]': 'dateCreated[end]',
  offset: 'offset',
  limit: 'limit',
  sortOrder: 'sortOrder',
  sortBy: 'sortBy',
  name: 'name',
  documentNumber: 'documentNumber',
  email: 'email',
  verificationId: 'verificationId',
  identityId: 'identityId',
  metadata: 'metadata',
  // For Customer Support
  asMerchantId: 'asMerchantId',
};

export function getVerificationsFilterInitialState(registrationDate: string, restFilters?: Partial<IIdentityFilter>) {
  const period = FilterRangesByLocal[FilterRangeTypes.All].getDateRange(registrationDate);

  return {
    ...verificationsFilterInitialState,
    'dateCreated[start]': period.start,
    'dateCreated[end]': period.end,
    ...restFilters,
  };
}

export const verificationsCleanFilter = {
  ...initDateFilter,
  status: [],
  flowIds: [],
  sortOrder: null,
  sortBy: null,
};
