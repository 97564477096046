import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    zIndex: 99999,
    right: 0,
    width: 280,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
  input: {
    width: '100%',
  },
}));
