import { useCallback, useEffect } from 'react';
import { trackingManager } from 'apps/Tracking';
import { useInView } from 'react-intersection-observer';

interface IUseTrackViewEventInViewport {
  triggerOnce?: boolean;
  name: string;
  payload?: any;
}

export function useTrackViewEventInViewport({ triggerOnce = true, name, payload }: IUseTrackViewEventInViewport) {
  const { ref: isInViewRef, inView } = useInView({ triggerOnce });

  const trackView = useCallback(() => {
    trackingManager.trackView(name, payload);
  }, [name, payload]);

  useEffect(() => {
    if (inView) {
      trackView();
    }
  }, [inView, trackView]);
  return isInViewRef;
}

export function useTrackViewEvent() {
  const trackClick = useCallback((name, payload?) => {
    trackingManager.trackView(name, payload);
  }, []);

  return trackClick;
}

export function useTrackNavigation(name, payload?) {
  useEffect(() => {
    trackingManager.trackNavigation(name, payload);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
