import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ZoomableImage } from '../ZoomableImage/ZoomableImage';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 5,
    border: `1px solid ${theme.palette.foreground.main}`,
  },
  wrapper: {
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  image: {
    borderRadius: 5,
    overflow: 'hidden',
  },
}));

export const StyledZoomableImage = withStyles(() => ({
  root: {
    height: 140,
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5,
  },
}))(ZoomableImage);
