import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Close from '@material-ui/icons/Close';
import { CircledContent, FlowError } from 'apps/ui';
import { IProductCard } from 'models/Product.model';
import { IMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { useFormatMessage } from 'apps/intl';
import { clearProductSelection } from 'apps/flowBuilder';
import { useStyles } from './ProductTitleWithData.styles';
import { useProductFormatMessage } from '../../hooks/useProductFormatMessage';

export function ProductTitleWithData({ card, error, meritBlocksAdditional = {}, isSdkOnly = false }: {
  card: IProductCard;
  error: Nullable<string>;
  meritBlocksAdditional: IMeritBlocksAdditional;
  isSdkOnly: boolean;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const { icon, iconUrl } = card;
  const productFormatMessage = useProductFormatMessage(card);

  const closePanel = () => dispatch(clearProductSelection());

  return (
    <Box pl="21px" pr="21px" pt="24px" display="flex">
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Box id="main" display="flex">
          <Box className={classes.image}>
            <CircledContent>
              {card.iconUrl && (
                <img
                  alt=""
                  src={card.iconUrl}
                  className={classes.externalIcon}
                  onError={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                />
              )}
              {icon && icon({})}
            </CircledContent>
          </Box>
          <Box ml={1}>
            <Box className={classes.title}>
              {card.isExternal ? productFormatMessage('title') : formatMessage(card.title)}
            </Box>
            <Box color="common.black75">
              {meritBlocksAdditional[card.id]?.shortDescription && (
                formatMessage(`Merit.${card.id}.shortDescription`)
              )}
              {' '}
              {meritBlocksAdditional[card.id]?.readmeLink && (
                <a href={meritBlocksAdditional[card.id]?.readmeLink} className={classes.readMoreLink} target="_blank" rel="noreferrer">{formatMessage('link.read-more')}</a>
              )}
            </Box>
            {isSdkOnly && <Box mt={1.2} className={classes.badge}>{formatMessage('FlowBuilder.integration.sdkOnly')}</Box>}
          </Box>
        </Box>
        <Box height="100%">
          <Box ml={2} onClick={closePanel} className={classes.closeButton}>
            <Close />
          </Box>
        </Box>
      </Grid>
      {error && (
        <Box mb={1} mt={1}>
          <FlowError text={formatMessage(error)} />
        </Box>
      )}
    </Box>
  );
}
