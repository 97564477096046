import React from 'react';
import Box from '@material-ui/core/Box';
import { useStyles } from './Separator.styles';

export function Separator() {
  const classes = useStyles();
  return (
    <Box className={classes.line} />
  );
}
