import { makeStyles, Select, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, {fullLabel: boolean}>((theme) => ({
  container: {
    position: 'relative',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 3,
    color: theme.palette.common.lightblue,
    gap: 8,
    padding: '6px 8px',
    '&:hover': {
      backgroundColor: theme.palette.common.neutralLightN30,
      color: theme.palette.common.black90,
    },
    '&:focus': {
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
  buttonAlt: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.common.lightblue,
    gap: 8,
    padding: '6px 8px',
    '&:hover': {
      color: theme.palette.common.rosado,
    },
    '&:focus': {
      color: theme.palette.common.black3a,
    },
  },
  buttonOpen: {
    backgroundColor: theme.palette.common.lightblue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
  },
  buttonOpenAlt: {
    color: theme.palette.common.black3a,
    '&:hover': {
      color: theme.palette.common.black3a,
    },
  },
  content: {
    boxSizing: 'border-box',
    position: 'absolute',
    zIndex: 250,
    right: 0,
    top: 40,
    padding: '4px 0',
    borderRadius: '3px',
    backgroundColor: theme.palette.common.white,
  },
  item: {
    cursor: 'pointer',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 137,
  },
  itemAlt: {
    ursor: 'pointer',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 137,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.rosado,
    },
  },
  topMenuItem: (props) => ({
    ...(props.fullLabel && {
      width: '100%',
    }),
    minHeight: 32,
    alignItems: 'center',
    lineHeight: 1,
    fontWeight: 'bold',
    transition: 'none',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
    '&:focus': {
      background: 'rgba(255, 255, 255, 0.15)',
    },
  }),
  menuIcon: (props) => ({
    color: theme.palette.common.black7,
    height: 17,
    width: 17,
    overflow: 'inherit',
    ...(props.fullLabel ? {
      position: 'absolute',
      left: 22,
    } : {
      height: 19,
      width: 19,
    }),
  }),
  menuList: {
    minWidth: '150px !important',
    maxWidth: 200,
  },
  select: (props) => ({
    textTransform: 'capitalize',
    width: '100%',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-root': {
      paddingLeft: 5,
    },
    ...(props.fullLabel && {
      '& .MuiSelect-root': {
        paddingLeft: 60,
      },
    }),
  }),
  selectItem: {
    position: 'relative',
    padding: '7px 10px 6px',
    color: theme.palette.common.black75,
    '&:hover': {
      backgroundColor: theme.palette.common.black7,
    },
  },
  radio: {
    '& .MuiToggleButton-root': {
      minWidth: 17,
      height: 10,
      textTransform: 'uppercase',
    },
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export const SelectLight = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    '&:focus': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.black,
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.black,
    },
  },
}))(Select);
