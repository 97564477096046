import { selectProductRegistered } from 'apps/Product';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { Loadable } from 'models/Loadable.model';
import { ProductTypes } from 'models/Product.model';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMeritBlocksAdditional } from '../models/MeritBlockAdditional.model';
import { getMeritBlocksAdditional } from '../store/MeritBlockAdditional.actions';
import { selectMeritBlocksAdditionalModel } from '../store/MeritBlockAdditional.selectors';

export function useLoadMeritBlocksAdditional(): Loadable<IMeritBlocksAdditional> {
  const dispatch = useDispatch();
  const meritBlockAdditionalModel: Loadable<IMeritBlocksAdditional> = useSelector(selectMeritBlocksAdditionalModel);
  const registeredProducts = useSelector<any, ProductTypes[]>(selectProductRegistered);

  useEffect(() => {
    if (registeredProducts?.length > 0 && LoadableAdapter.isPristine(meritBlockAdditionalModel)) {
      try {
        dispatch(getMeritBlocksAdditional(registeredProducts));
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, meritBlockAdditionalModel, registeredProducts]);

  return meritBlockAdditionalModel;
}
