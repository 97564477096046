import React, { useCallback } from 'react';
import { useOverlay } from './Overlay.hook';
import ConfirmLeaveModal from '../components/ConfirmLeaveModal/ConfirmLeaveModal';

export function useConfirmLeave(title?: React.ReactNode, subtitle?: React.ReactNode, onCancelChanges?: () => void, onSaveChanges?: () => void) {
  const [createOverlay, closeOverlay] = useOverlay();

  return useCallback(() => new Promise<void>((resolve, reject) => {
    function onClose() {
      closeOverlay();
      reject();
    }

    function onConfirm() {
      closeOverlay();
      resolve();
    }

    createOverlay(
      <ConfirmLeaveModal
        title={title}
        subtitle={subtitle}
        onCancelChanges={onCancelChanges}
        onSaveChanges={onSaveChanges}
        onClose={onClose}
        onConfirm={onConfirm}
      />, {
        onClose,
      },
    );
  }), [closeOverlay, createOverlay, onCancelChanges, onSaveChanges, title, subtitle]);
}
