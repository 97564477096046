import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    overflow: 'hidden',

    '& > img': {
      userDrag: 'none',
      display: 'block',
      margin: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
}));
