import Grid from '@material-ui/core/Grid';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import Typography from '@material-ui/core/Typography';
import React, { KeyboardEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { ICustomField } from 'models/CustomField.model';
import { selectCustomFieldEditedField } from '../../../../state/CustomField.selectors';
import { TextFieldInput } from '../../CustomFieldModalConfigureAtomic.styles';

export function CustomFieldHintSetting({ handleAtomicFieldParamsChange, handleKeyDown }: {
  handleAtomicFieldParamsChange: StandardInputProps['onChange'];
  handleKeyDown: KeyboardEventHandler;
}) {
  const formatMessage = useFormatMessage();
  const selectedCustomField = useSelector<any, ICustomField>(selectCustomFieldEditedField);

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="subtitle2">
          {formatMessage('CustomField.settings.fieldHint')}
          {!!selectedCustomField.atomicFieldParams.regex && ' *'}
        </Typography>
      </Grid>
      <Grid item>
        <TextFieldInput
          fullWidth
          value={selectedCustomField.atomicFieldParams.placeholder}
          type="text"
          name="placeholder"
          onChange={handleAtomicFieldParamsChange}
          onKeyDown={handleKeyDown}
          placeholder={formatMessage('CustomField.settings.fieldHint.placeholder')}
        />
      </Grid>
    </Grid>
  );
}
