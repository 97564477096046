import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: 16,
  },
  applyBtn: {
    minWidth: 170,
    minHeight: 52,
    fontSize: 16,
    color: theme.palette.common.white,
    borderRadius: 6,
    backgroundColor: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
    '&.Mui-disabled': {
      opacity: 0.3,
      backgroundColor: theme.palette.common.lightblue,
      color: theme.palette.common.white,
    },
    '& svg': {
      marginRight: 2,
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cancelBtn: {
    minWidth: 80,
    minHeight: 52,
    borderRadius: 6,
    fontSize: 16,
  },
}));
