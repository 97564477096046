import { merchantLoad } from 'state/merchant/merchant.actions';
import { selectMerchantModel } from 'state/merchant/merchant.selectors';
import { LoadableAdapter } from 'lib/Loadable.adapter';

export const AuthActionTypes: { CLEAR_APPLICATION_STATE: string } = {
  CLEAR_APPLICATION_STATE: 'CLEAR_APPLICATION_STATE',
};

export const signOut = () => async (dispatch) => {
  window.localStorage.clear();
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
  dispatch({ type: AuthActionTypes.CLEAR_APPLICATION_STATE, payload: null });
};

export const merchantLoadWithCheck = () => async (dispatch, getState) => {
  const merchantModel = selectMerchantModel(getState());
  if (LoadableAdapter.isPristine(merchantModel)) {
    await dispatch(merchantLoad());
  }
};
