import { ITemplateMetadata } from 'apps/Templates';
import React from 'react';
import Box from '@material-ui/core/Box';
import { PageLoader } from 'apps/layout';
import { useFormatMessage } from 'apps/intl';
import { TemplateFilterOption } from '../TemplateFilterOption/TemplateFilterOption';
import { useStyles } from './TemplateFilters.style';

export function TemplateFilters({ title, filterData, currentFilters, onSelectFilter }: {
  title: string;
  filterData: ITemplateMetadata[];
  currentFilters: ITemplateMetadata[];
  onSelectFilter: (filter: ITemplateMetadata) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const getIsFilterSelected = (item: ITemplateMetadata): boolean => currentFilters[0]?.name === item.name;

  return (
    <Box mb="32px" className={classes.filterContainer}>
      <Box className={classes.title}>{formatMessage(`TemplateFilter.label.${title}`)}</Box>
      {filterData?.length > 0 ? filterData.map((item) => (
        <TemplateFilterOption
          key={item._id}
          text={formatMessage(`Templates.category.${item.name}.label`)}
          isChecked={getIsFilterSelected(item)}
          onChange={() => onSelectFilter(item)}
        />
      )) : (
        <PageLoader />
      )}
    </Box>
  );
}
