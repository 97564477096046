import { Route } from 'react-router-dom';
import { Routes } from 'models/Router.model';
import { RedirectWithLocation } from 'apps/routing/index';
import React from 'react';
import { useLoadMerchant } from 'apps/merchant/hooks/loadMerchant.hook';
import { Loader } from 'apps/ui';
import { useRole, PermissionAccessTypes, PermissionsTypes } from 'lib/Auth0';

export function RoleGuardRoute({ path, exact = false, render, permission, access, component, ...props }: {
  path: string;
  exact?: boolean;
  render?: () => React.ReactNode;
  permission: PermissionsTypes;
  access: PermissionAccessTypes;
  component?: React.ReactNode;
}) {
  const { checkPermission } = useRole();
  const merchantModel = useLoadMerchant();

  if (merchantModel.isLoading || !merchantModel.isLoaded) {
    return (<Loader logoWithCompanyName />);
  }

  if (!checkPermission(permission, access)) {
    // TODO: optimize fallback detection
    if (!checkPermission(PermissionsTypes.verifications, PermissionAccessTypes.Read)) {
      return (<RedirectWithLocation pathname={Routes.root} />);
    }

    return (<RedirectWithLocation pathname={Routes.identity.verification.root} />);
  }

  return (
    <Route exact={exact} path={path} render={render} component={component} {...props} />
  );
}
