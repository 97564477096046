import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFormatMessage } from 'apps/intl';
import { CountryCode } from 'apps/countries';
import { Flags } from 'assets/coutryFlags/flags';
import { useStyles } from './DocumentSubTypesCountrySelector.styles';

export function DocumentSubTypesCountrySelector({ country, noOfSelected, children }: {
  country: CountryCode;
  noOfSelected?: number;
  children: ReactNode;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid container item alignItems="center" wrap="nowrap" className={classes.wrapper}>
        <Box>
          <img className={classes.icon} src={Flags[`Flag${country.toUpperCase()}`]} alt={country} />
        </Box>
        <Box ml={1}>
          <Box fontWeight="500" textAlign="left">
            {formatMessage(`Countries.${country}`)}
          </Box>
          <Box fontWeight="200" textAlign="left" color="common.black75">
            {noOfSelected && `${noOfSelected} ${formatMessage('DocumentVerification.settings.description.subTypesSelection.selected')}`}
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}
