import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useFormatMessage } from 'apps/intl';
import { FiActivity, FiDownload, FiExternalLink, FiPauseCircle } from 'react-icons/fi';
import { StepStatus } from 'models/Step.model';
import { CheckStepDetailsEntry } from 'apps/checks';
import { useParams } from 'react-router-dom';
import { http } from 'lib/client/http';
import { openBlob } from 'lib/file';
import { ILastMonitoredTimestamps, MatchTypes } from '../../../../models/Aml.model';
import { LinkButton, useStyles } from './PremiumAmlWatchlistsStepDetails.styles';
import { DateFormatTypes, formatDate } from 'lib/date';

export function PremiumAmlWatchlistsStepDetails({
  searchId,
  status,
  errorCode,
  isMonitored,
  isMonitoringAvailable,
  name,
  countries,
  dateOfBirth,
  birthYear,
  matchType,
  profileUrl,
  lastMonitoredTimestamps,
}: {
  name?: string;
  dateOfBirth?: string;
  birthYear?: number;
  searchId: number;
  status?: StepStatus;
  isMonitored?: boolean;
  isMonitoringAvailable: boolean;
  errorCode?: string;
  countries?: string;
  profileUrl?: string;
  matchType?: typeof MatchTypes;
  lastMonitoredTimestamps?: ILastMonitoredTimestamps[]
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { verificationId } = useParams();
  const isMonitoringVisible = isMonitoringAvailable && typeof isMonitored !== 'undefined';
  const currentLastMonitoredTimestamp = lastMonitoredTimestamps?.find(({ id }) => id === searchId);

  const handleGetPdfCertificate = useCallback(async () => {
    const url = `/api/v1/dashboard/watchlists/premium/pdf-certificate/${verificationId}/${searchId}`;
    const { data } = await http.get(url, { responseType: 'arraybuffer' });
    const blob = new Blob([data], { type: 'application/pdf' });
    openBlob(blob);
  }, [verificationId, searchId]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box>
          {errorCode && (
            <Box mt={0.5} mb={1.5} className={classNames({ [classes.redText]: status === StepStatus.Failure })}>
              {formatMessage(status === StepStatus.Failure ? `SecurityCheckStep.${errorCode}.message` : 'SecurityCheckStep.premiumAmlWatchlists.incomplete')}
            </Box>
          )}

          {status !== StepStatus.Incomplete && (
          <Box>
            {isMonitoringVisible && (
            <Box mt={0.5} mb={1.5} className={classes.monitoringStatus}>
              <Box className={classes.iconContainer}>
                {isMonitored
                  ? (
                    <FiActivity size={18} className={classes.greenText} />
                  ) : (
                    <FiPauseCircle size={18} className={classes.yellowText} />
                  )}
              </Box>
              <Box>
                {formatMessage(`SecurityCheckStep.premiumAmlWatchlists.monitoring.${isMonitored ? 'enabled' : 'disabled'}`, {
                  messageValues: {
                    verified: (
                      <Box component="span" className={classes.greenUnderlinedText}>
                        {formatMessage('statuses.verified')}
                      </Box>
                    ),
                  },
                })}
              </Box>
            </Box>
            )}

            {currentLastMonitoredTimestamp && <CheckStepDetailsEntry label="lastMonitored" value={formatDate(currentLastMonitoredTimestamp.lastMonitored, DateFormatTypes.FullDateWithTimeDashed)} />}
            {name && <CheckStepDetailsEntry label="nameSearched" value={name} />}

            {countries && <CheckStepDetailsEntry label="countriesSearched" value={countries} />}

            <Box className={classes.wrapper}>
              {dateOfBirth && (
              <Box className={classes.inline}>
                <CheckStepDetailsEntry label="dateOfBirth" value={dateOfBirth} />
              </Box>
              )}
              {birthYear && (
              <Box className={classes.inline}>
                <CheckStepDetailsEntry label="birthYear" value={birthYear} />
              </Box>
              )}
              {matchType && (
              <Box className={classes.inline}>
                <CheckStepDetailsEntry
                  label="matchType"
                  value={formatMessage(`SecurityCheckStep.premiumAmlWatchlists.match.${matchType}`)}
                />
              </Box>
              )}
            </Box>
            {profileUrl && (
            <Link href={profileUrl} underline="none">
              <LinkButton fullWidth endIcon={<FiExternalLink />}>
                {formatMessage('SecurityCheckStep.premiumAmlWatchlists.profileLink')}
              </LinkButton>
            </Link>
            )}
            {searchId && (
              <Box mt={0.5}>
                <Link onClick={handleGetPdfCertificate} underline="none">
                  <LinkButton fullWidth endIcon={<FiDownload />}>
                    {formatMessage('SecurityCheckStep.premiumAmlWatchlists.searchCertificate')}
                  </LinkButton>
                </Link>
              </Box>
            )}
          </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
