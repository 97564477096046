import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { BoxBordered, GaugeChart, GaugeChartTypes } from 'apps/ui';
import { useStyles } from './EmailChart.style';
import { Exclamation } from '../../assets';
import { useFormatMessage } from 'apps/intl';
import { DELIVERABILITY_COLORS, DELIVERABILITY_RANGE, RISK_ASSESSMENT_COLORS, RISK_ASSESSMENT_RANGE } from '../../models/EmailRisk.model';

export function EmailChart({ type, value = 0 }: { type: GaugeChartTypes, value: number }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const valueRange = type === GaugeChartTypes.RiskAssessment ? RISK_ASSESSMENT_RANGE : DELIVERABILITY_RANGE;
  const rangeIndex = valueRange.findIndex(([from, to]) => from <= value && value <= to);
  const colors = type === GaugeChartTypes.RiskAssessment ? RISK_ASSESSMENT_COLORS : DELIVERABILITY_COLORS;

  return (
    <Box className={classes.chart}>
      <BoxBordered>
        <Typography className={classes.chartTitle}>{formatMessage(`EmailRisk.${type}.title`)}</Typography>
        <Typography className={classes.chartSubTitle}>{formatMessage(`EmailRisk.${type}.subTitle`)}</Typography>
        <GaugeChart className="fs-exclude" value={value} type={type} progressColor={colors[rangeIndex]} />
        <Typography className={classes.chartName}>{formatMessage(`EmailRisk.${type}.name`)}</Typography>
        <Typography className={classes.charOrder}>{formatMessage(`EmailRisk.${type}.order`)}</Typography>
        <Exclamation className={classes.exclamation} />
      </BoxBordered>
    </Box>
  );
}
