import classNames from 'classnames';
import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './RoundProfilePicture.styles';

export function RoundProfilePicture({ image, className }: {
  image: React.ReactNode;
  className?: string;
}) {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.root, className)}>
      {image}
    </Box>
  );
}
