export type Handler = (resume?: () => void) => boolean;

export class SimpleObservable<T> {
  private handlers: Map<T, Handler[]> = new Map();

  on(event: T, handler: Handler) {
    if (!this.handlers.has(event)) {
      this.handlers.set(event, [handler]);
      return;
    }

    const existingHandlers = this.handlers.get(event);
    if (existingHandlers.includes(handler)) {
      return;
    }

    this.handlers.set(event, [...existingHandlers, handler]);
  }

  off(event: T, handler: Handler) {
    if (!this.handlers.has(event)) {
      return;
    }

    const existingHandlers = this.handlers.get(event);
    if (!existingHandlers.includes(handler)) {
      return;
    }

    this.handlers.set(event, existingHandlers.filter((item) => item !== handler));
  }

  trigger(event: T, resume?: () => void): boolean {
    if (!this.handlers.has(event)) {
      return false;
    }

    const handlers = this.handlers.get(event);
    return handlers.some((handler) => Boolean(handler(resume)));
  }
}
