import { Loadable } from 'models/Loadable.model';
import { createTypesSequence, TypesSequence } from 'state/store.utils';
import { IWatchlist, IWatchlistGroup, IWatchlistContent } from 'models/Watchlist.model';

export const AML_STORE_KEY = 'aml';

export enum BasicWatchlistsActionTypes {
  Watchlists = 'BASIC_WATCHLISTS',
  WatchlistsAdmin = 'BASIC_WATCHLISTS_ADMIN',
  WatchlistsGroups = 'BASIC_WATCHLISTS_GROUPS',
  CurrentWatchlist = 'CURRENT_BASIC_WATCHLIST',
  WatchlistContent = 'BASIC_WATCHLIST_CONTENT',
  WatchlistsDelete = 'BASIC_WATCHLISTS_DELETE',
  CurrentWatchlistHeaders = 'CURRENT_BASIC_WATCHLIST_HEADERS',
  CurrentWatchlistFileFailure = 'CURRENT_BASIC_WATCHLIST_FILE_FAILURE',
}

export enum SliceNamesTypes {
  Watchlists = 'watchlists',
  WatchlistsGroups = 'watchlistsGroups',
  CurrentWatchlist = 'currentWatchlist',
  WatchlistContent = 'watchlistContent',
  CurrentWatchlistHeaders = 'currentWatchlistHeaders',
  watchlistAdmin = 'watchlistAdmin',
}

export const types: TypesSequence = {
  ...createTypesSequence(BasicWatchlistsActionTypes.Watchlists),
  ...createTypesSequence(BasicWatchlistsActionTypes.WatchlistsAdmin),
  ...createTypesSequence(BasicWatchlistsActionTypes.WatchlistsGroups),
  ...createTypesSequence(BasicWatchlistsActionTypes.CurrentWatchlist),
  ...createTypesSequence(BasicWatchlistsActionTypes.WatchlistContent),
  ...createTypesSequence(BasicWatchlistsActionTypes.WatchlistsDelete),
  ...createTypesSequence(BasicWatchlistsActionTypes.CurrentWatchlistHeaders),
  BasicWatchlistsErrorsClear: 'BASIC_WATCHLISTS_ERRORS_CLEAR',
};

export interface IAmlStore {
  [SliceNamesTypes.Watchlists]: Loadable<IWatchlist[]>;
  [SliceNamesTypes.WatchlistsGroups]: Loadable<IWatchlistGroup[]>;
  [SliceNamesTypes.CurrentWatchlist]: Loadable<Nullable<IWatchlist>>;
  [SliceNamesTypes.WatchlistContent]: Loadable<IWatchlistContent>;
  [SliceNamesTypes.CurrentWatchlistHeaders]: Loadable<string[]>;
  fileErrorType: Nullable<string>;
  [SliceNamesTypes.watchlistAdmin]: Loadable<null>;
}
