import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { makeStyles, Tab } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: 'inherit',
  },
  padding: {
    padding: theme.spacing(1),
  },
  gridContainerRoot: {
    width: 'inherit',
    margin: '0',
  },
  meritBlockList: {
    height: '100%',
    overflowY: 'auto',
  },
  tabsContainer: {
    marginBottom: 16,
    padding: '0px 4px',
  },
}));

export const AntTabs = withStyles((theme: Theme) => ({
  root: {
    flexWrap: 'wrap',
    minHeight: 32,
  },
  indicator: {
    backgroundColor: theme.palette.common.lightblue,
    display: 'none',
  },
  flexContainer: {
    flexWrap: 'wrap',
    paddingLeft: 8,
  },
}))(Tabs);

export const AntTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    color: theme.palette.common.black75,
    minWidth: 0,
    padding: '0px 8px',
    fontFamily: [
      'Inter',
      'Lato', 'Helvetica Neue', 'sans-serif',
    ].join(','),
    minHeight: '32px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: theme.palette.common.lightblue,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.common.lightblue,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.common.lightblue,
    },
    '& .Mui-wrapper:hover, &.Mui-selected > span': {
      content: '" "',
      borderBottom: `1px solid ${theme.palette.common.lightblue}`,
      width: '100%',
      paddingBottom: 0,
    },
  },
  selected: {},
}))((props: IStyledTabProps) => <Tab disableRipple {...props} />);

interface IStyledTabProps {
  label: string;
}
