import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { overlayClose } from '../../state/overlay.actions';
import { OverlayI } from '../../models/Overlay.model';
import CSS from './Overlay.module.scss';

export function findRelativeParent(element: HTMLElement): HTMLElement {
  if (!element) {
    return undefined;
  }

  const parent = element.nodeName === 'HTML' ? element : element.parentElement;

  if (!parent) {
    return element;
  }

  return parent.style.position === 'relative' || parent.nodeName === 'HTML'
    ? parent
    : findRelativeParent(parent);
}

export function Overlay({ withBlur = false, inline = false, onClose, options, children }: OverlayI) {
  const overlayRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();

  const [locationState, setLocationState] = useState(location);
  const [isMouseDownOutsideModal, setIsMouseDownOutsideModal] = useState<boolean>(false);

  useEffect(() => {
    if (locationState.key !== location.key) {
      dispatch(overlayClose());
    }
    setLocationState(location);
  }, [location, locationState, dispatch]);

  useEffect(() => {
    const parent: HTMLElement = findRelativeParent(overlayRef.current);
    parent.classList.add(CSS.noScroll);

    return () => {
      parent.classList.remove(CSS.noScroll);
    };
  }, []);

  const handleClose = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget && isMouseDownOutsideModal && !options?.sticky) {
      setIsMouseDownOutsideModal(false);
      if (options?.onClose) {
        options.onClose();
      } else {
        onClose();
      }
    }
  };

  const handleMouseDownOutsideModal = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      setIsMouseDownOutsideModal(true);
    }
  };

  const handleMouseDownInsideModal = () => setIsMouseDownOutsideModal(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        CSS.overlayVisible,
        withBlur ? CSS.blurOverlay : CSS.overlay,
        { [CSS.overlayInline]: inline },
        options?.additionalClasses?.map((item) => CSS[item]),
      )}
      onMouseDown={handleMouseDownOutsideModal}
      onMouseUp={handleClose}
      ref={overlayRef}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span
        onMouseDown={handleMouseDownInsideModal}
        className={classNames(
          withBlur ? CSS.blurOverlayContent : CSS.overlayContent,
          CSS.overlayContentVisible,
        )}
      >
        {children}
      </span>
    </div>
  );
}
