import { useSelector } from 'react-redux';
import { selectMerchantSubscriptionStatusValue } from 'state/merchant';
import { PermissionsSubscriptionStatus, PermissionSubscriptionStatusTypes } from '../models/PermissionsSubscriptionStatus.model';
import { hasPermission } from '../models/Permissions.model';

export function useHasPermissionSubscription(permission: PermissionSubscriptionStatusTypes): boolean {
  const subscriptionStatusValue = useSelector(selectMerchantSubscriptionStatusValue);

  return hasPermission(subscriptionStatusValue, permission, PermissionsSubscriptionStatus);
}
