import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  badge: {
    padding: '3px 10px',
    color: theme.palette.common.lightblue,
    backgroundColor: theme.palette.common.whiteblue,
    borderRadius: 30,
    whiteSpace: 'nowrap',
  },
}));
