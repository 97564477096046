import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '22px',
    padding: '12px 13px',
  },
  filterContainer: {
    width: '100%',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '22px',
  },
  filterOptions: {
    maxHeight: 300,
    maxWidth: '13vw',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    wordBreak: 'break-word',
    boxSizing: 'border-box',
    scrollbarWidth: 'thin',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    padding: '20px 22px 22px 20px',
    marginTop: '3%',
    zIndex: 200,
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.black7,
      borderRadius: 10,
    },
  },
  filterOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '22px',
    cursor: 'pointer',
    border: '1px solid transparent',
    '& svg': {
      display: 'none',
    },
    '&:hover': {
      borderColor: theme.palette.common.black90,
      borderRadius: 6,
      '& svg': {
        display: 'block',
      },
    },
  },
  selected: {
    borderColor: theme.palette.common.black90,
    borderRadius: 6,
    '& svg': {
      display: 'block',
    },
  },
}));
