import Switch from '@material-ui/core/Switch';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription } from 'apps/ui';
import React from 'react';

export function GovCheckAgeCheck({ isAgeCheckEnabled, handleToggleAgeCheck }: {
  isAgeCheckEnabled: boolean;
  handleToggleAgeCheck: () => void;
}) {
  const formatMessage = useFormatMessage();

  return (
    <ExtendedDescription
      title={formatMessage('GovCheckMerit.settings.ageCheck.title')}
      text={formatMessage('GovCheckMerit.settings.ageCheck.description')}
      postfix={(
        <Switch
          checked={isAgeCheckEnabled}
          onChange={handleToggleAgeCheck}
          color="primary"
        />
      )}
    />
  );
}
