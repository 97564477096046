import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BoxBordered, GaugeChartTypes } from 'apps/ui';
import { EmailResult } from '../EmailResult/EmailResult';
import { useStyles } from './EmailRisk.style';
import { EmailChart } from '../EmailChart/EmailChart';
import { EmailVariables } from '../EmailVariables/EmailVariables';
import { EmailRiskValidationStep } from 'models/EmailCheck.model';

export function EmailRisk({ step }: {
  step: EmailRiskValidationStep
}) {
  const classes = useStyles();
  if (!step) {
    return null;
  }
  const hasError = Boolean(step.error);
  const title = hasError ? `SecurityCheckStep.${step.error?.code}` : 'EmailRisk.result.valid.title';
  const subTitle = hasError ? 'EmailRisk.result.inValid.subTitle' : 'EmailRisk.result.valid.subTitle';

  return (
    <BoxBordered border={3}>
      <Grid>
        <EmailResult title={title} subTitle={subTitle} error={step?.error} data={step.data} />
        {step.data && (
          <>
            <Box mt={1} className={classes.charts}>
              <EmailChart value={step.data.riskScore} type={GaugeChartTypes.RiskAssessment} />
              <EmailChart value={step.data.overall_score} type={GaugeChartTypes.Deliverability} />
            </Box>
            <Box mt={1}>
              <EmailVariables data={step.data} />
            </Box>
          </>
        )}
      </Grid>
    </BoxBordered>
  );
}
