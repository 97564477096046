import classNames from 'classnames';
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './SummaryUserInfoItem.styles';

export const SummaryUserInfoItem = ({
  label,
  value,
}: { label: string; value: string }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body1" className={classNames(classes.summaryListItemValue, 'fs-exclude')}>
        {value}
      </Typography>
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
    </Box>
  );
};
