import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from 'react-redux';
import { IFlow } from 'models/Flow.model';
import { groupPatternsConfig, IFlattenVerificationPatternConfig, VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { selectFlowBuilderChangeableFlow } from 'apps/flowBuilder';
import { parseFacematchPatternConfig } from '../../models/Facematch.model';
import { FacematchCountrySettings } from '../FacematchCountrySettings/FacematchCountrySettings';
import { useStyles } from './FacematchGovCheckSettings.styles';

export function FacematchGovCheckSettings({ onChange, patternsConfig }: {
  patternsConfig: VerificationPatternsConfigType;
  onChange: (config: IFlattenVerificationPatternConfig, option: IFlattenVerificationPatternConfig) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) {
  const classes = useStyles();
  const flow = useSelector<any, IFlow>(selectFlowBuilderChangeableFlow);
  const patternsConfigGroupedByCountry = groupPatternsConfig('country', patternsConfig, parseFacematchPatternConfig.bind({}, flow));

  return (
    <FormControl className={classes.control}>
      {Object.entries(patternsConfigGroupedByCountry).map(([country, config]) => <FacematchCountrySettings key={country} onChange={onChange} country={country} patternsConfig={config} />)}
    </FormControl>
  );
}
