import inRange from 'lodash/inRange';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { DEFAULT_LOCALE, SupportedLocales } from 'models/Intl.model';

import utcSupport from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DateLocale } from 'yup/lib/locale';

dayjs.extend(utcSupport);
dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export enum DateFormatTypes {
  YearOnly = 'YYYY',
  MonthYear = 'MMM, YYYY',
  MonthDate = 'MM/DD',
  DateFull = 'MMM D, YYYY',
  DateShort = 'DD/MM/YYYY',
  DateShortDayOneDigit = 'D/MM/YYYY',
  DateShortStroke = 'YYYY-MM-DD',
  HoursFull = 'HH A',
  // TODO = @pabloscdo consult with product about this "no space" format
  MonthShort = 'DD MMM,YYYY',
  DayShortMonthShortWithSpace = 'D MMM, YYYY',
  MonthShortWithSpace = 'DD MMM, YYYY',
  DateTime = 'DD MMM, YYYY HH:mm',
  DayMonthShort = 'DD MMM',
  FullDateWithTime = 'DD/MM/YY-H:mm:ss',
  ShortDayAndLocalizedDateWithShortMonth = 'ddd ll', // Wed, Sep 22, 2021
  FullMonthAndFullYear = 'MMMM YYYY', // September 2021
  LocalizedDayMonthYearSlashes = 'L', // 09/22/2021
  FullMonthDateAndFullYear = 'll', // Mar 11, 1992,
  MonthYearShort = 'MMM YYYY',
  MonthYearVeryShort = 'MMM \'YY',
  FullDateWithTimeDashed = 'DD-MMM-YY H:mm:ss', // 06-May-2022 13:42:35
  FullDateWithTimeAndMillis = 'DD/MM/YY-H:mm:ss.SSS',
}

export const DatePartTypes = {
  Month: 'month',
  Day: 'day',
  Year: 'year',
};

export type DateString = string;

export const AllDateParts = [DatePartTypes.Day, DatePartTypes.Month, DatePartTypes.Year];

export const zeroTime = { hour: 0, minute: 0, second: 0, millisecond: 0 };
export const dayEndTime = { hour: 23, minute: 59, second: 59, millisecond: 999 };

const RE_NON_DIGIT = /\D/g;

export function utcToLocalFormat(value: string | Date, customFormat?: string) {
  const date = dayjs.utc(value);
  if (date.isValid()) {
    return date.local().format(customFormat ?? DateFormatTypes.MonthShort);
  }
  return null;
}

function parseDateStringToDayJS(value: string): Dayjs | null {
  // First try one of the custom formats we support.
  let date = dayjs(value, [DateFormatTypes.DateShortDayOneDigit]);
  if (date.isValid()) {
    return date;
  }

  // Attempt any valid ISO8601 String (ex. "YYYY", "MMM, YYYY", "MMM D, YYYY", etc.)
  date = dayjs.utc(value);
  if (date.isValid()) {
    return date;
  }

  return null;
}

export function formatDate(value: string, customFormat?: string) {
  const dateAsDayJs = parseDateStringToDayJS(value);

  if (!dateAsDayJs) {
    return value;
  }

  if (customFormat) {
    return dateAsDayJs.format(customFormat);
  }

  const { length: dateLength } = value.replace(RE_NON_DIGIT, '');

  if (dateLength > 7) {
    return dateAsDayJs.format(DateFormatTypes.DateFull);
  }
  if (dateLength > 5) {
    return dateAsDayJs.format(DateFormatTypes.MonthYear);
  }
  return dateAsDayJs.format(DateFormatTypes.YearOnly);
}

export function formatRelativeDate(value: string) {
  const dateAsDayJs = parseDateStringToDayJS(value);

  if (!dateAsDayJs) {
    return value;
  }
  return dateAsDayJs.fromNow();
}

export function toIsoPeriod(period: number | string): string {
  return `P${period}D`;
}

export function fromIsoPeriod(period: string): string {
  const match = /^P(\d+)D$/.exec(period);
  if (match) {
    return match[1];
  }
  return '';
}

// TODO: @pabloscdo - type out checkInterval inputs and conform types in GdprPopup
export function checkInterval(value, from, to): boolean {
  return inRange(value, from, to + 1);
}

export function normalizeDate(value): Date {
  const date = dayjs.utc(value, DateFormatTypes.DateFull, true);
  return date.isValid()
    ? date.format(DateFormatTypes.DateShortStroke)
    : value;
}

export function isDateBetween(value, start, end): boolean {
  return dayjs.utc(value).isBetween(dayjs.utc(start), dayjs.utc(end), 'day', '[]');
}

export function getYearsArray(from, to): number[] {
  return Array.from({ length: to - from + 1 }, (_, i) => to - i);
}

export function addMissingZeros(dateString = ' '): string | null {
  if (!dateString) {
    return null;
  }

  const [year, month, day] = dateString.split('-');
  const fixedMonth = (month?.length === 1 ? `0${month}` : month) || '';
  const fixedDay = (day?.length === 1 ? `0${day}` : day) || '';
  if (fixedDay || fixedMonth) {
    return `${year || ''}-${fixedMonth}-${fixedDay}`;
  }
  return null;
}

export function dateSortCompare(a: string | number, b: string | number, isFromOldToNew: boolean = false): number {
  return isFromOldToNew ? dayjs.utc(a).diff(dayjs.utc(b)) : -dayjs.utc(a).diff(dayjs.utc(b));
}

export function getUnixTimestamp(date: Date) {
  return Math.trunc((date.getTime() / 1000));
}

export function dateToFormatString(value: Date | string, locale: string, format: DateFormatTypes = DateFormatTypes.LocalizedDayMonthYearSlashes): string {
  const date = dayjs(value).locale(locale);
  if (!date.isValid()) {
    return value.toString();
  }
  return date.format(format);
}

export function getLocaleFormat(locale: string, format: DateFormatTypes = DateFormatTypes.LocalizedDayMonthYearSlashes): string {
  return dayjs().locale(locale)
    .localeData()
    .longDateFormat(format)
    .toLowerCase();
}

export function changeDateFormat(str: string, format: string = DateFormatTypes.LocalizedDayMonthYearSlashes, locale: SupportedLocales = DEFAULT_LOCALE, newFormat: string = DateFormatTypes.DateShortStroke): string {
  return dayjs(str, format, locale, true).format(newFormat);
}

export function getDaysUntilEnd(startDate: DateString, endDate: DateString): [number, number] {
  const today = dayjs();
  const duration = dayjs(endDate).diff(startDate, 'day');
  // starts from 1
  const daysPassed = dayjs(today).diff(startDate, 'day') + 1;
  const daysFromStartDate = Math.min(daysPassed, duration);
  return [daysFromStartDate, duration];
}

export function isAfter(value: string) {
  return dayjs().isAfter(dayjs(value));
}

export function isBefore(date: string, limitDate: string, comparisionUnit: OpUnitType): boolean {
  return dayjs(date).isBefore(limitDate, comparisionUnit);
}

function mydiff(date1, date2, interval: string) {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const timediff: number = (date2 - date1);
  if (Number.isNaN(timediff)) {
    return Number.NaN;
  }
  switch (interval) {
    case 'years': return date2.getFullYear() - date1.getFullYear();
    case 'months': return ((date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth()));
    case 'weeks': return Math.floor(timediff / week);
    case 'days': return Math.floor(timediff / day);
    case 'hours': return Math.floor(timediff / hour);
    case 'minutes': return Math.floor(timediff / minute);
    case 'seconds': return Math.floor(timediff / second);
    default: return undefined;
  }
}

export function formatDateLastActive(dateString: string) {
  const date1 = new Date(dateString);
  const date2 = new Date();
  const hoursDiff = mydiff(date1, date2, 'hours');
  const daysDiff = mydiff(date1, date2, 'days');
  const weeksDiff = mydiff(date1, date2, 'weeks');
  const monthsDiff = mydiff(date1, date2, 'months');
  const yearsDiff = mydiff(date1, date2, 'years');

  if (hoursDiff < 24) {
    return hoursDiff === 1 ? '1 hour ago' : `${hoursDiff} hours ago`;
  }

  if (hoursDiff >= 24 && daysDiff < 7) {
    return daysDiff === 1 ? '1 day ago' : `${daysDiff} days ago`;
  }

  if (daysDiff > 7 && weeksDiff < 4) {
    return weeksDiff === 1 ? '1 week ago' : `${weeksDiff} weeks ago`;
  }

  if (weeksDiff > 4 && monthsDiff < 12) {
    return monthsDiff === 1 ? '1 month ago' : `${monthsDiff} months ago`;
  }

  if (monthsDiff > 12) {
    return yearsDiff === 1 ? '1 year ago' : `${yearsDiff} years ago`;
  }
  return '-';
}
