import React from 'react';
import { appPalette } from 'apps/theme';
import { useMerchantPlan } from 'apps/merchant';
import { BoxBordered, Warning } from 'apps/ui';
import { MERCHANT_MAIL } from 'models/Merchant.model';
import { useFormatMessage } from 'apps/intl';

export function WarningBadgeAccessAllowed({ meritName }: {
  meritName?: string;
}) {
  const formatMessage = useFormatMessage();
  const { isFreemium } = useMerchantPlan();
  return (
    <BoxBordered borderColor={appPalette.yellow} mb={4} p={1}>
      {isFreemium ? (
        <Warning
          meritName={meritName}
          label={formatMessage('Freemium.productNotAvailable')}
        />
      ) : (
        <Warning
          meritName={meritName}
          label={formatMessage('Product.warnings.usageInfo', {
            messageValues: {
              emailAddress: <a href={`mailto:${MERCHANT_MAIL}`}>{MERCHANT_MAIL}</a>,
            },
          })}
        />
      )}
    </BoxBordered>
  );
}
