import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { selectMerchantModel } from 'state/merchant/merchant.selectors';
import { ErrorStatusTypes } from 'models/Error.model';
import { Routes } from 'models/Router.model';
import { useHistory } from 'react-router-dom';
import { merchantLoadWithCheck } from 'apps/auth/state/auth.actions';
import { getAccessToken, parseError } from 'lib/Auth0';
import { merchantClear } from 'state/merchant/merchant.actions';

export function useLoadMerchant() {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    user,
    ...other
  } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const merchantModel = useSelector(selectMerchantModel);

  useEffect(() => {
    if (!isLoading && isAuthenticated && !user?.email_verified) {
      history.push(Routes.auth.emailVerification);
    } else if (!isLoading && isAuthenticated) {
      getAccessToken(getAccessTokenSilently).finally(async () => {
        try {
          await dispatch(merchantLoadWithCheck());
        } catch (error: any) {
          if (error?.response?.status === 401) {
            history.push(Routes.auth.signIn);
          } else {
            throw error;
          }
        }
      });
    } else if (!isLoading && !isAuthenticated) {
      const exposedError = parseError(other?.error as OAuthError);
      if (exposedError) {
        history.push(`${Routes.auth.signIn}?error=${exposedError}`);
      } else {
        history.push(Routes.auth.signIn);
      }
    }
  }, [isLoading, isAuthenticated, dispatch, history, getAccessTokenSilently, user, other?.error]);

  return merchantModel;
}
