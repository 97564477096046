import { Avatar, Box, Button, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ISignedUrlEntry } from 'models/Watchlist.model';
import axios from 'axios';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './ImageList.styles';

export function ImageList({ file, uploadUrl, onUploadComplete, error }: {
  file: File;
  uploadUrl?: ISignedUrlEntry | null;
  onUploadComplete?: (fileName?: string) => void;
  error?: string;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const uploadFile = useCallback(async () => {
    if (file) {
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      };
      try {
        setUploadError(false);
        await axios.put(uploadUrl?.url, file, config);
        if (onUploadComplete) {
          onUploadComplete(uploadUrl?.fileName);
        }
      } catch {
        setUploadError(true);
        if (onUploadComplete) {
          onUploadComplete();
        }
      }
    }
  }, [file, onUploadComplete, uploadUrl?.fileName, uploadUrl?.url]);

  useEffect(() => {
    if (file && uploadUrl) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ListItem key={file.name} alignItems="flex-start">
        <ListItemAvatar>
          {avatarSrc && <Avatar src={avatarSrc} alt={file.name} />}
        </ListItemAvatar>
        <ListItemText
          primary={(
            <>
              {file.name}
            </>
          )}
          secondary={(
            <>
              {error ? (
                <>
                  <Typography component="span" variant="body2" className={classes.error}>
                    {formatMessage(`CustomWatchlist.settings.facialWatchlists.list.${error}`)}
                  </Typography>
                </>
              ) : (
                <Typography component="span" variant="body2">
                  <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <LinearProgress variant="determinate" value={uploadProgress} />
                    </Box>
                    {uploadError ? (
                      <Box display="flex" alignItems="center">
                        <Button variant="outlined" color="primary" onClick={uploadFile}>
                          {formatMessage('CustomWatchlist.settings.facialWatchlists.modal.retryUpload')}
                        </Button>
                      </Box>
                    ) : (
                      <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">
                          {`${Math.round(
                            uploadProgress,
                          )}%`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Typography>
              )}
            </>
          )}
        />
      </ListItem>
    </>
  );
}
