import { CustomDocumentResponse, CustomDocumentTemplateMatching, CustomDocumentTemplate, ICustomDocumentDocumentReading, ICustomDocumentReadingField } from 'models/CustomDocument.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { createSelector } from 'reselect';
import { CUSTOM_DOCUMENT_STORE_KEY, SliceNameTypes, CustomDocumentStore } from './customDocument.store';
import { CustomDocumentWizardStepTypes } from '../models/CustomDocument.model';

export const selectCustomDocumentStore = (state: { [CUSTOM_DOCUMENT_STORE_KEY]: CustomDocumentStore }): CustomDocumentStore => state[CUSTOM_DOCUMENT_STORE_KEY];

export const selectCustomDocumentModal = createSelector<[typeof selectCustomDocumentStore], Partial<CustomDocumentResponse>>(
  selectCustomDocumentStore,
  (store) => store[SliceNameTypes.CustomDocument],
);

export const selectCustomDocumentWizardStep = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): CustomDocumentWizardStepTypes => store[SliceNameTypes.CustomDocumentWizardStep],
);

export const selectEditedCustomDocument = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): number | null => store[SliceNameTypes.EditedCustomDocument],
);

export const selectCustomDocumentUpload = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore) => store[SliceNameTypes.CustomDocumentUpload],
);

export const selectCustomDocumentSettings = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore) => store[SliceNameTypes.CustomDocumentSettings],
);

export const selectCustomDocumentTemplateMatchingSettings = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): CustomDocumentTemplateMatching => store[SliceNameTypes.CustomDocumentTemplateMatchingSettings],
);

export const selectCustomDocumentTemplateMatchingEditedTemplate = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): number => store[SliceNameTypes.CustomDocumentTemplateEditedTemplate],
);

export const selectCustomDocumentTemplateMatchingTemplateSettings = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): CustomDocumentTemplate => store[SliceNameTypes.CustomDocumentTemplateMatchingTemplateSettings],
);

export const selectCustomDocumentDocumentReadingSettings = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): ICustomDocumentDocumentReading => store[SliceNameTypes.CustomDocumentDocumentReadingSettings],
);

export const selectCustomDocumentDocumentReadingFieldSettings = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): ICustomDocumentReadingField => store[SliceNameTypes.CustomDocumentDocumentReadingFieldSettings],
);

export const selectCustomDocumentEditedField = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): number => store[SliceNameTypes.CustomDocumentEditedField],
);

export const selectCustomDocumentDocumentReadingFieldOption = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): string => store[SliceNameTypes.CustomDocumentDocumentReadingFieldOption],
);

export const selectCustomDocumentDocumentEditedReadingFieldOption = createSelector(
  selectCustomDocumentStore,
  (store: CustomDocumentStore): number | null => store[SliceNameTypes.CustomDocumentDocumentReadingEditedFieldOption],
);

export const selectCustomDocumentReadingFields = createSelector<[typeof selectCustomDocumentModal], ICustomDocumentReadingField[]>(
  selectCustomDocumentModal,
  (customDocumentModal) => customDocumentModal.flow?.verificationPatterns?.[VerificationPatternTypes.DocumentReading]?.fields ?? [],
);
