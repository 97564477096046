import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  editor: {
    border: `1px solid ${theme.palette.common.black50}`,
    '& [data-slate-editor]': {
      padding: '16px',
      minHeight: '300px !important',
      '& > * + *': {
        marginTop: '1em',
      },
      '& ul, ol': {
        listStyleType: 'revert',
        margin: 'revert',
        padding: 'revert',
      },
      '& p': {
        margin: 0,
      },
      '& pre': {
        padding: '10px',
        backgroundColor: theme.palette.common.simplegray,
        whiteSpace: 'pre-wrap',
      },
      '& :not(pre) > code': {
        fontFamily: 'monospace',
        backgroundColor: theme.palette.common.simplegray,
        padding: '3px',
      },
      '& blockquote': {
        borderLeft: `2px solid ${theme.palette.common.alto}`,
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: '10px',
        color: theme.palette.common.silverchalice,
        fontStyle: 'italic',
      },
      '& blockquote[dir="rtl"]': {
        borderLeft: 'none',
        paddingLeft: 0,
        paddingRight: '10px',
        borderRight: `2px solid ${theme.palette.common.alto}`,
      },
    },
  },
}));
