import { makeStyles, Theme } from '@material-ui/core/styles';
import riskChartBackground from 'assets/risk-gauge-chart.svg';
import deliverabilityChartBackground from 'assets/deliverability-gauge-chart.svg';
import { GaugeChartTypes, IGaugeChartParams } from '../../models/GaugeChart.model';

const backgrounds = {
  [GaugeChartTypes.RiskAssessment]: riskChartBackground,
  [GaugeChartTypes.Deliverability]: deliverabilityChartBackground,
};

const progressLimits = {
  [GaugeChartTypes.RiskAssessment]: 100,
  [GaugeChartTypes.Deliverability]: 4,
};

const rotationStartValue = 47;
const rotationEndValue = 223;
const rotationSteps = rotationEndValue - rotationStartValue;

export const useStyles = makeStyles<Theme, IGaugeChartParams>((theme) => ({
  root: {
    width: 215,
    height: 108,
    margin: '16px auto',
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: ({ type }) => `url(${backgrounds[type]})`,
    backgroundRepeat: 'no-repeat',
  },
  gauge: {
    background: 'transparent',
    width: 166,
    height: 166,
    transition: 'transform 600ms ease',
    position: 'relative',
    margin: 'auto',
    marginTop: '9%',
    '&:before, &:after': {
      content: '""',
      width: 7,
      height: 7,
      position: 'absolute',
      top: '51%',
      left: -7,
    },
    '&:after': {
      backgroundColor: ({ progressColor }) => progressColor,
      borderRadius: '50%',
      top: '49%',
      zIndex: 2,
    },
    '&:before': {
      backgroundColor: theme.palette.common.brightgray,
      zIndex: 1,
    },
  },
  percentage: {
    transform: ({ value, type }) => `rotate(${rotationStartValue + (value * ((rotationSteps / progressLimits[type])))}deg)`,
    border: ({ progressColor }) => `7px solid ${progressColor}`,
    borderRadius: '50%',
    borderLeftColor: 'transparent !important',
    borderTopColor: 'transparent !important',
    width: 166,
    height: 166,
    position: 'absolute',
    top: 4,
    left: -7,
    '&:before': {
      content: '""',
      width: 7,
      height: 7,
      backgroundColor: ({ progressColor }) => progressColor,
      borderRadius: '50%',
      position: 'absolute',
      bottom: '10.87%',
      left: '10.5%',
    },
  },
  value: {
    position: 'absolute',
    bottom: 0,
    left: -4,
    width: '100%',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 600,
    color: theme.palette.common.black90,
  },
}));
