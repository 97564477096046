import Box from '@material-ui/core/Box';
import { isEqual } from 'lodash';
import { useCountriesLoad } from 'apps/countries';
import { BusinessRegionsTypes, ICountriesSettings } from 'apps/documentVerification/models/DocumentVerification.model';
import { Modal } from 'apps/overlay';
import { Spinner } from 'apps/ui';
import { useDebounce } from 'lib/debounce.hook';
import { Country, IDocumetSubTypesWithTransalates } from 'models/Country.model';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentSubTypesModalBody } from '../DocumentSubTypesModalBody/DocumentSubTypesModalBody';
import { DocumentSubTypesModalFooter } from '../DocumentSubTypesModalFooter/DocumentSubTypesModalFooter';
import { DocumentSubTypesModalHeader } from '../DocumentSubTypesModalHeader/DocumentSubTypesModalHeader';
import { useStyles } from './DocumentSubTypes.styles';

export function DocumentSubTypes({ onSubmit, onClose, restrictedCountries, countrySettings }:
  { onSubmit: (value: ICountriesSettings[]) => void; onClose: () => void; restrictedCountries: string[]; countrySettings: ICountriesSettings[] }) {
  const [allCountries, setAllCountries] = useState<Country[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [countriesByRegion, setCountriesByRegion] = useState<Record<BusinessRegionsTypes, Country[] | undefined>>();
  const [countryWiseSubTypes, setCountryWiseSubTypes] = useState<{ country: string; name: string; documentSubtypes: IDocumetSubTypesWithTransalates[]}[]>();
  const [selectedCountry, setSelectedCountry] = useState<{ country: string; name: string; documentSubtypes: IDocumetSubTypesWithTransalates[]} | undefined >();
  const [selectedSubTypes, setSelectedSubTypes] = useState<null | ICountriesSettings[]>(null);
  const [initialSelectedSubTypes, setInitialSelectedSubTypes] = useState<Nullable<string[]>>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const classes = useStyles();
  const countriesModel = useCountriesLoad();
  const debounced = useDebounce();

  const getAllSubTypes = (value: ICountriesSettings[]): string[] => value.flatMap((countrySetting: ICountriesSettings): string[] => countrySetting.documentSubtypes.map((documentSubType) => `${countrySetting.country}_${documentSubType}`));

  useEffect(() => {
    if (countriesModel.isLoaded) {
      const countries: Country[] = countriesModel.value.map((country) => ({
        ...country,
      }));
      const countriesWithDocSubTypes: Country[] = countries.filter(
        (country) => country.documentSubtypesWithTranslates?.length > 0,
      );
      const shorListedCountries: Country[] = countriesWithDocSubTypes.filter(
        (country) => (restrictedCountries.length > 0 ? restrictedCountries.includes(country.code) : true),
      );

      setFilteredCountries(shorListedCountries);
      setAllCountries(shorListedCountries);
      if (countrySettings?.length > 0) {
        setSelectedSubTypes(countrySettings);
        setInitialSelectedSubTypes(getAllSubTypes(countrySettings));
      } else {
        const countryWiseSubType = shorListedCountries.map((country) => ({
          country: country.code,
          documentSubtypes: country.documentSubtypesWithTranslates.map((data) => data.id),
        }));
        setSelectedSubTypes(countryWiseSubType);
        setInitialSelectedSubTypes(getAllSubTypes(countryWiseSubType));
      }
    }
  }, [countriesModel, countrySettings, restrictedCountries]);

  const groupBy = (objectArray, property) => objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      // eslint-disable-next-line no-param-reassign
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  useEffect(() => {
    if (filteredCountries.length > 0) {
      const countriesWithDocSubTypes: Country[] = filteredCountries.filter(
        (country) => country.documentSubtypesWithTranslates?.length > 0,
      );
      const shorListedCountries: Country[] = countriesWithDocSubTypes.filter(
        (country) => (restrictedCountries.length > 0 ? restrictedCountries.includes(country.code) : true),
      );
      const countriesByRegions: Record<BusinessRegionsTypes, Country[]> = groupBy(
        shorListedCountries,
        'businessRegion',
      );
      setCountriesByRegion(countriesByRegions);
      const countryWiseSubType = shorListedCountries.map((country) => ({
        country: country.code,
        documentSubtypes: country.documentSubtypesWithTranslates,
        name: country.name,
      }));
      setCountryWiseSubTypes(countryWiseSubType);

      const initalSelectedCountry = countriesByRegions[Object.keys(countriesByRegions)[0]][0].code;
      const selectedCountryDetails = countryWiseSubType.find(
        (data) => data.country === initalSelectedCountry,
      );
      setSelectedCountry(selectedCountryDetails);
    } else {
      setSelectedCountry(undefined);
    }
  }, [filteredCountries, restrictedCountries]);

  const handleSubmit = useCallback((): void => {
    onSubmit(selectedSubTypes);
  }, [onSubmit, selectedSubTypes]);

  const handleClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleSearchChange = useCallback((value: string) => {
    const query = value.toLowerCase();
    if (query === '') {
      setFilteredCountries(allCountries);
    } else {
      debounced(() => {
        const updatedFilteredCountries = allCountries.filter((country) => country.name.toLowerCase().includes(query));
        setFilteredCountries(updatedFilteredCountries.length > 0 ? updatedFilteredCountries : []);
      });
    }
  }, [allCountries, debounced]);

  const updateSelectedSubType = (value: ICountriesSettings[]) => {
    const updatedSelectedSubTypeArray = getAllSubTypes(value);
    setSelectedSubTypes(value);
    const initialSelectedSubTypesCloned = [...initialSelectedSubTypes];
    initialSelectedSubTypesCloned.sort();
    updatedSelectedSubTypeArray.sort();
    setIsSubmitDisabled(isEqual(initialSelectedSubTypesCloned, updatedSelectedSubTypeArray));
  };

  return (
    <Modal className={classes.root}>
      <DocumentSubTypesModalHeader />
      {!countriesModel.isLoading && countriesModel.isLoaded && selectedSubTypes ? (
        <>
          <DocumentSubTypesModalBody
            countries={countriesByRegion}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedSubTypes={selectedSubTypes}
            setSelectedSubTypes={updateSelectedSubType}
            countryWiseSubTypes={countryWiseSubTypes}
            handleSearchChange={handleSearchChange}
          />
          <DocumentSubTypesModalFooter
            handleApply={handleSubmit}
            handleClose={handleClose}
            isApplyDisabled={isSubmitDisabled}
          />
        </>
      ) : (
        <Box
          height={340}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      )}
    </Modal>
  );
}
