import React from 'react';
import { useFormatMessage } from 'apps/intl';
import type { MexicanBuholegalCheck } from 'models/BackgroundCheck.model';
// TODO: @aleksei.rasskazov refactor SpoilerTypes to be able to import it from apps/ui
import { SpoilerTypes } from 'apps/ui/components/Spoiler/SpoilerTypes';
import { RecordView } from 'apps/ui';
import { RecordFailedDetails } from '../RecordFailedDetails/RecordFailedDetails';
import { useStyles } from './MexicanRecordViewFailed.styles';

export const MexicanRecordViewPartialMatched = ({ state, records }:
  {state: string; records?: MexicanBuholegalCheck['records']}) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.state'),
        value: state,
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.partialMatch'),
        valueClass: classes.partialMatchedLabel,
        containerClass: classes.statusCell,
      }}
      details={records.map((record, idx) => <RecordFailedDetails key={idx} record={record} type={SpoilerTypes.Warning} />)}
    />
  );
};
