export enum IntercomChatCommandTypes {
  Show = 'show',
  Hide = 'hide',
  ShowNewMessage = 'showNewMessage',
  TrackEvent = 'trackEvent',
  Update = 'update',
}

export enum IntercomEventTypes {
  CreateMetamap = 'create_metamap',
  TestVerification = 'test_verification',
}

export enum IntercomCustomAttributeTypes {
  HasCreatedFirstMetamap = 'has_created_first_metamap',
  HasTestedFirstVerification = 'has_tested_first_verification',
}
