import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { useFormatMessage } from 'apps/intl';
import { IsShowErrorByKey, isShowTitleInData, IsSuccessByKey, TitleKeyByLabel } from '../apps/checks/models/checks.model';

export const useCheckStepDetailsObject = (label: string, value: Record<string, unknown> | Record<string, unknown>[]) => {
  const formatMessage = useFormatMessage();
  const copyValue = useMemo(() => cloneDeep(value), [value]);
  const titleKey = TitleKeyByLabel[label];
  const title = copyValue[titleKey];

  const isTitleInData = isShowTitleInData[label] ?? true;
  if (!isTitleInData) {
    delete copyValue[titleKey];
  }

  const isSuccessKey = IsSuccessByKey[label];
  const isShowErrorKey = IsShowErrorByKey[label];

  const isSuccess = copyValue[isSuccessKey] ?? true;
  const isShowError = copyValue[isShowErrorKey] ?? false;
  if (isSuccessKey) {
    delete copyValue[isSuccessKey];
  }
  if (isShowErrorKey) {
    delete copyValue[isShowErrorKey];
  }

  return { isShowError, isSuccess, copyValue, title: formatMessage(`SecurityCheckStep.${label}.title`, { defaultMessage: title }) };
};
