import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  meritBlocks: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: theme.palette.common.black7,
    border: `1px solid ${theme.palette.common.black7}`,
    borderRadius: 12,
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',
  },
  preview: {
    marginLeft: 36,
    paddingBottom: 17,
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
  },
  navigationControl: {
    minWidth: '44px',
    maxWidth: '44px',
    minHeight: '44px',
    maxHeight: '44px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.common.black7}`,
    borderRadius: '36px',
    color: theme.palette.common.black90,
  },
  screenshot: {
    border: `1px solid ${theme.palette.common.black50}`,
    borderRadius: 12,
    maxWidth: 500,
    width: 'auto',
    height: 570,
    userSelect: 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
  },
}));
