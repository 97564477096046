import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.common.lightyellow,
  },
  signInContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
  },
  removePadding: {
    padding: '0',
  },
  appBar: {
    position: 'fixed',
    top: 16,
    right: 24,
  },
  redirect: {
    alignSelf: 'start',
    color: theme.palette.common.black90,
    fontWeight: 'normal',
  },
  form: {
    backgroundColor: theme.palette.common.palegray,
    minWidth: 402,
    maxWidth: 402,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 6,
    padding: 48,
    gap: 24,
    boxShadow: '0px 4px 8px 5px rgba(35, 41, 57, 0.04)',
    '& a': {
      color: theme.palette.common.lightblue,
      textDecoration: 'none',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: 'Anybody',
    marginBottom: 16,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '22.4px',
    fontWeight: 400,
    fontFamily: 'Anybody',
  },
  button: {
    width: '100%',
    height: 50,
    padding: '15px 22px',
    fontSize: 14,
    backgroundColor: theme.palette.common.lightblue,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.lightbluehover,
    },
  },
  helperTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 2px 0px 2px',
    fontSize: '12px',
  },
  errorIcon: {
    marginRight: '8px',
    fontSize: '14px',
  },
}));
