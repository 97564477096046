import React from 'react';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import { QATags } from 'models/QA.model';
import { Switch } from 'apps/ui';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { IAppearance } from 'models/Flow.model';
import { selectMerchantTags } from 'state/merchant';
import { LogoUpload } from './LogoUpload';
import { useStyles } from './LogoCustomization.style';
import { ReactComponent as CrownIcon } from '../../assets/icon-crown.svg';
import cloneDeep from 'lodash/cloneDeep';


export function LogoCustomization({ appearance, onChange }: {
  appearance: IAppearance,
  onChange: (appearance: IAppearance | ((current: IAppearance) => IAppearance)) => void;
}) {
  const tags = useSelector(selectMerchantTags);
  const formatMessage = useFormatMessage();
  const styles = useStyles();

  const handleHideMetamapLogoChange = (value) => {
    const updated = cloneDeep(appearance);
    updated.isDisplayedPoweredByMetamap = !value;
    onChange(updated);
  };

  return(
    <Box>
      <LogoUpload />
      {(tags?.includes(MerchantTagsTypes.CanHideMetamapLogo)) && (
        <Box mt={1}>
          <FormControl component="fieldset" fullWidth>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                className={styles.formControl}
                value="start"
                control={(
                  <Switch
                    checked={!appearance.isDisplayedPoweredByMetamap}
                    onSwitch={handleHideMetamapLogoChange}
                    data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.hidePoweredByMetamap}
                  />
                )}
                label={(
                  <>
                    <Box component="span" mr={1}>
                      {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.hidePoweredMetamap.label')}
                    </Box>
                    <Chip
                      icon={<CrownIcon />}
                      label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.hidePoweredMetamap.enterpriseTag.label')}
                      className={styles.enterpriseChipStyle}
                    />
                  </>
                )}
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </Box>
  );
}
