import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.foreground.main}`,
    borderRadius: 5,
  },
  descriptionTitle: {
    fontSize: 18,
    lineHeight: '22px',
    color: theme.palette.common.darkgrey,
  },
  wrapper: {
    minHeight: 200,
    borderRadius: 5,
    backgroundColor: theme.palette.foreground.main,
  },
  title: {
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.common.darkgrey,
  },
  subtitle: {
    marginTop: 3,
    fontSize: 12,
    letterSpacing: '0.2px',
    lineHeight: '15px',
    color: theme.palette.common.black75,
  },
  scoreWrapper: {
    textAlign: 'center',
  },
  heading: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '22px',
    color: theme.palette.common.black75,
  },
  reason: {
    color: theme.palette.common.brinkPink,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
  },
  noRiskColor: {
    color: theme.palette.common.green,
  },
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0,
    },
    '& *': {
      padding: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 30,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  accordionItem: {
    marginTop: theme.spacing(1),
    '& p, & div': {
      fontSize: 11,
      lineHeight: '17px',
      fontWeight: 700,
      color: theme.palette.common.black75,
    },
  },
  noRisk: {
    background: theme.palette.common.greentransparent,
    color: `${theme.palette.common.silverTree} !important`,
  },
  info: {
    fontSize: 16,
    marginLeft: 'auto',
    color: theme.palette.common.black75,
  },
}));
