import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { WebCodeSnippet, DirectLinkCopy } from 'apps/forDevelopers';
import { ButtonLink } from 'apps/ui/components/ButtonLink/ButtonLink';
import { LinkButton, TabID, urls } from 'models/Integration.model';
import { Logo } from 'apps/logo';
import { BoxBordered, Warning, WarningSize, WarningTypes } from 'apps/ui';
import { appPalette } from 'apps/theme/app.palette';
import { useFormatMessage } from 'apps/intl';

export function IntegrationSDK() {
  const formatMessage = useFormatMessage();

  return (
    <Box>
      <Box mb={2.5}>
        <Typography variant="subtitle2" gutterBottom>
          {formatMessage('flow.logoStep.title')}
        </Typography>
        <Logo />
      </Box>
      <BoxBordered borderColor={appPalette.yellow} mt={1} mb={1}>
        <Warning
          type={WarningTypes.Warning}
          size={WarningSize.Large}
          label={formatMessage('ColorPickerWarning')}
          link="https://docs.metamap.com/docs/web"
          linkLabel={formatMessage('ColorPickerWarning.link')}
        />
      </BoxBordered>
      <Box mb={2}>
        <WebCodeSnippet />
      </Box>
      <Box mb={4}>
        <ButtonLink
          url={urls[TabID.Web].documentationURL}
        >
          {formatMessage('forDevs.documentationBanner.button', { messageValues: { platform: 'WebSDK' } })}
        </ButtonLink>
      </Box>
      <Box mb={4}>
        <DirectLinkCopy />
      </Box>
      <Box>
        <Typography variant="subtitle2" gutterBottom>{formatMessage('FlowBuilder.integration.mobileHybridSDKsnippet.title')}</Typography>
        <Box mb={2} color="common.black75">
          {formatMessage('FlowBuilder.integration.mobileHybridSDKsnippet.subtitle')}
        </Box>
        <Box mb={1}>
          <ButtonLink url={LinkButton.documentationURL}>
            {formatMessage('forDevs.documentationBanner.button', { messageValues: { platform: LinkButton.name } })}
          </ButtonLink>
        </Box>
      </Box>
    </Box>
  );
}
