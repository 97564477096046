import { isAfter } from '../lib/date';

export enum CovidToleranceCountryTypes {
  Chile = 'CL',
}

export function isCovidTolerance(date: string, country: string) {
  if (!country || !date) {
    return false;
  }

  return isAfter(date) && country === CovidToleranceCountryTypes.Chile;
}
