import React from 'react';
import { IFlow } from 'models/Flow.model';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { VerificationStepTypes } from 'models/Step.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { DataSourceValueTypes } from 'models/Watchlist.model';
import { BiometricVerificationNistInputTypes } from 'apps/biometricVerification';
import { BiometricTypes } from 'models/Biometric.model';
import { CustomWatchlistCheckTypes, CustomWatchlistSettingsTypes, CustomWatchlistStepType, FacialWatchlistDataSourceTypes } from '../models/CustomWatchlist.model';
import { ReactComponent as FilesWithEye } from '../assets/files-with-eye.svg';
import { CustomWatchlistVerification, CustomWatchlistSettings } from '../components';

type ProductSettingsCustomWatchlist = ProductSettings<CustomWatchlistSettingsTypes>;

export class CustomWatchlist extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.CustomWatchlist;
  order = 1200;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  inputs = [ProductInputTypes.CustomWatchlist];
  requiredProductType = [ProductTypes.DocumentVerification, ProductTypes.CustomField];
  checks = [{
    id: CustomWatchlistCheckTypes.CustomDatabases,
    isActive: true,
  }];
  icon = () => <FilesWithEye />;
  component = CustomWatchlistSettings;
  componentVerification = CustomWatchlistVerification;

  getBiometricType(flow: IFlow) {
    if (flow?.biometrics?.input === BiometricVerificationNistInputTypes.LivenessNist) {
      return FacialWatchlistDataSourceTypes.LIVENESS_NIST;
    } if (flow?.verificationPatterns?.[VerificationPatternTypes.Biometrics] === BiometricTypes.selfie) {
      return FacialWatchlistDataSourceTypes.SELFIE_PHOTO;
    }
    return FacialWatchlistDataSourceTypes.SELFIE_VIDEO;
  }

  parser(flow: IFlow, productsInGraph?: ProductTypes[]): ProductSettingsCustomWatchlist {
    const isBiometricStepsActive = productsInGraph.includes(ProductTypes.BiometricVerification);
    return {
      [CustomWatchlistSettingsTypes.CustomWatchlists]: {
        value: flow?.watchlists?.[CustomWatchlistSettingsTypes.CustomWatchlists]?.list ?? [],
      },
      [CustomWatchlistSettingsTypes.DataSource]: {
        value: flow?.watchlists?.[CustomWatchlistSettingsTypes.CustomWatchlists]?.dataSource ?? DataSourceValueTypes.DocumentVerification,
      },
      [CustomWatchlistSettingsTypes.facialWatchList]: {
        value: flow?.watchlists?.[CustomWatchlistSettingsTypes.facialWatchList]?.list ?? [],
        isRequireOtherProduct: !isBiometricStepsActive,
      },
      [CustomWatchlistSettingsTypes.faceMatchThreshold]: {
        value: flow?.watchlists?.[CustomWatchlistSettingsTypes.facialWatchList]?.facematchThreshold,
      },
      [CustomWatchlistSettingsTypes.facialWatchListSource]: {
        value: this.getBiometricType(flow),
      },
    };
  }

  serialize(settings: ProductSettingsCustomWatchlist, flow: IFlow): Partial<IFlow> {
    return {
      watchlists: {
        [CustomWatchlistSettingsTypes.CustomWatchlists]: {
          list: settings[CustomWatchlistSettingsTypes.CustomWatchlists].value,
          dataSource: settings[CustomWatchlistSettingsTypes.DataSource].value,
        },
        [CustomWatchlistSettingsTypes.facialWatchList]: {
          list: settings[CustomWatchlistSettingsTypes.facialWatchList].value,
          dataSource: this.getBiometricType(flow),
          facematchThreshold: settings[CustomWatchlistSettingsTypes.faceMatchThreshold].value,
        },
      },
    };
  }

  onAdd(): Partial<IFlow> {
    return {
      verificationPatterns: {
        [VerificationPatternTypes.CustomWatchlistsValidation]: true,
      },
    };
  }

  onRemove(): Partial<IFlow> {
    return {
      watchlists: {
        [CustomWatchlistSettingsTypes.CustomWatchlists]: {
          list: [],
          dataSource: undefined,
        },
        [CustomWatchlistSettingsTypes.facialWatchList]: {
          list: [],
          dataSource: undefined,
          facematchThreshold: 0,
        },
      },
      verificationPatterns: {
        [VerificationPatternTypes.CustomWatchlistsValidation]: false,
      },
    };
  }

  isInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.CustomWatchlistsValidation];
  }

  getVerification(verification: VerificationResponse): VerificationResponse {
    return verification;
  }

  hasFailedCheck(verifcation: VerificationResponse): boolean {
    const step: CustomWatchlistStepType = verifcation.steps.find((dataStep) => dataStep.id === VerificationStepTypes.CustomWatchlistsValidation);
    return !!step?.error;
  }

  isInVerification(verification: VerificationResponse): boolean {
    return !!verification.steps.find((dataStep) => dataStep.id === VerificationStepTypes.CustomWatchlistsValidation);
  }
}
