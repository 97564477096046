import { selectFlowBuilderProductsInGraph } from 'apps/flowBuilder';
import { useOverlay } from 'apps/overlay';
import { ProductTypes } from 'models/Product.model';
import React, { useCallback } from 'react';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { productManagerService } from '../services/ProductManager.service';
import { ProductDependencyModal } from '../components/ProductDependencyModal/ProductDependencyModal';
import { ProductCard } from '../components/ProductCard/ProductCard';

export function useProductAddingFromSubStep() {
  const [createOverlay, closeOverlay] = useOverlay();
  const formatMessage = useFormatMessage();
  const productsInGraph = useSelector<any, ProductTypes[]>(selectFlowBuilderProductsInGraph);

  return useCallback((productType: ProductTypes, subStep: string, onAdd: (productType?: ProductTypes) => void) => {
    const { requiredProductTypeFromSubStep } = productManagerService.getProduct(productType).getCard();
    const handleConfirm = () => {
      closeOverlay();
      onAdd(requiredProductTypeFromSubStep[subStep]);
    };

    // if the graph does not contain the merit required by the substep, open a confirmation modal to add that merit
    if (requiredProductTypeFromSubStep[subStep] && productsInGraph.every((type) => type !== requiredProductTypeFromSubStep[subStep])) {
      createOverlay(
        <ProductDependencyModal
          title={formatMessage('FlowBuilder.addingProductModal.title')}
          leftComponent={<ProductCard id={productType} isExpandable={false} defaultExpanded />}
          rightComponent={<ProductCard id={requiredProductTypeFromSubStep[subStep]} isExpandable={false} defaultExpanded />}
          leftSubtitle={formatMessage('FlowBuilder.addingProductModal.dependentProductTitle')}
          rightSubtitle={formatMessage('FlowBuilder.addingProductModal.requiredProductTitle')}
          confirmText={formatMessage('FlowBuilder.addingProductModal.confirmBoth')}
          onClose={closeOverlay}
          onConfirm={handleConfirm}
        />,
      );
      // trigger onAdd without needing to add merit, since its already added
    } else {
      onAdd();
    }
  }, [createOverlay, closeOverlay, formatMessage, productsInGraph]);
}
