const SIGNUP_TOKEN = 'RAW6M43YJVAT6P8';

export const SIGN_UP_FREE_VERIFICATIONS_COUNT = 300;
export const SIGN_UP_FREE_ACCESS_MONTHS_COUNT = 3;

export function isValidCheckSum(token) {
  return token === SIGNUP_TOKEN;
}

export enum AuthInputTypes {
  Password = 'password',
  OldPassword = 'oldPassword',
  RepeatPassword = 'repeatPassword',
  Email = 'email',
  CaptchaToken = 'captchaToken',
  FreemiumPageVersion = 'freemiumPageVersion',
}

export interface IIdentifierFirstCredentials {
  captchaToken: string;
  email: string;
}

export interface IIdentifierFirstResponseData {
  organization: string;
  invitation?: string;
}
