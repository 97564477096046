import { useContactSales } from 'apps/ContactSales';
import { useHistory } from 'react-router-dom';
import { Routes } from 'models/Router.model';

export function useFreemium() {
  const contactSales = useContactSales();
  const history = useHistory();

  return {
    upgrade: () => contactSales(),
    comparePlans: () => history.push(Routes.freemiumVsPreemium.root),
  };
}

