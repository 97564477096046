import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { WarningBadge } from 'apps/ui';
import { useStyles } from './LatamRecordFailedDetails.styles';

export const LatamRecordFailedDetails = ({ result }: { result: string }) => {
  const classes = useStyles();

  return (
    <Box pb="14px" px="12px">
      <Paper>
        <Box p="12px">
          <Box className={classes.alert}>
            <div>{result}</div>
            <div>
              <WarningBadge isDefaultPosition={false} />
            </div>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
