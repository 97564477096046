import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notification } from 'apps/ui';
import { IntlButton, useFormatMessage } from 'apps/intl';
import { ReactComponent as MatiLogo } from 'assets/metamap-logo.svg';
import { ReactComponent as LoginPromo } from 'assets/login-promo.svg';
import { ErrorStatusTypes } from 'models/Error.model';
import { Routes } from 'models/Router.model';
import { QATags } from 'models/QA.model';
import { getAccessToken, emailVerification } from 'lib/Auth0';
import { IoIosArrowBack } from 'react-icons/io';
import { AuthInputTypes, SIGN_UP_FREE_ACCESS_MONTHS_COUNT, SIGN_UP_FREE_VERIFICATIONS_COUNT } from 'apps/auth';
import { useStyles } from './UserEmailVerification.styles';

interface IPasswordRecoveryInputs {
  [AuthInputTypes.Email]: string;
}

export function UserEmailVerification() {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    user,
  } = useAuth0();

  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      history.push(Routes.auth.signIn);
    } else if (!isLoading && isAuthenticated && user.email_verified) {
      history.push(Routes.root);
    } else {
      setEmail(user?.email);
    }
  }, [user, isLoading, isAuthenticated, history]);

  const handleGoToSignIn = () => {
    logout({ returnTo: `${window.location.origin}${Routes.auth.signIn}` });
  };
  const { handleSubmit } = useForm<IPasswordRecoveryInputs>();

  const handleFormSubmit: SubmitHandler<IPasswordRecoveryInputs> = useCallback(async () => {
    setIsSubmitting(true);
    getAccessToken(getAccessTokenSilently).finally(async () => {
      try {
        await dispatch(emailVerification());
        setIsSubmitting(false);
        notification.success(formatMessage('UserEmailValidation.notification.emailSent'));
        history.push(Routes.auth.signIn);
      } catch (error: any) {
        const status = error?.response?.status;
        setIsSubmitting(false);
        if (status === ErrorStatusTypes.WrongCredentials) {
          history.push(Routes.auth.signIn);
        } else if (status === ErrorStatusTypes.TooManyRequests) {
          notification.error(formatMessage('SignIn.form.error.tooManyRequest'));
        } else {
          notification.error(formatMessage('Error.common'));
        }
      }
    });
  }, [dispatch, formatMessage, getAccessTokenSilently, history]);

  return (
    <Grid container className={classes.container}>
      <Box className={classes.promoWrapper}>
        <div className={classes.promoContainer}>
          <Box className={classes.logo}>
            <MatiLogo width={160} height={40} />
          </Box>
          <Box className={classes.promoImage}>
            <LoginPromo />
          </Box>
          <div className={classes.banner}>
            <span className={classes.greenDot} />
            {formatMessage('SignUp.promo.start')}
          </div>
          <Box className={classes.promoTitle}>
            <span>{formatMessage('SignUp.promo.welcome')}</span>
            &nbsp;
            <b>
              {formatMessage('SignUp.promo.text', {
                messageValues: {
                  verificationsCnt: SIGN_UP_FREE_VERIFICATIONS_COUNT,
                  monthsCnt: SIGN_UP_FREE_ACCESS_MONTHS_COUNT,
                },
              })}
            </b>
          </Box>
        </div>
      </Box>
      <Box className={classes.signInContainer}>
        <Box mb={3} className={classes.appBar}>
          <IntlButton isSync={false} />
        </Box>
        <Box className={classes.form}>
          <Box className={classes.backButton} onClick={handleGoToSignIn}>
            <IoIosArrowBack />
          </Box>
          <Box className={classes.title}>
            {formatMessage('UserEmailValidation.title')}
          </Box>
          <Box className={classes.subtitle}>
            {formatMessage('UserEmailValidation.subtitle', {
              messageValues: {
                email: (
                  <span className={classes.email}>{email}</span>
                ),
              },
            })}
          </Box>
          <Button
            data-qa={QATags.Auth.EmailVerification.Resend}
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {formatMessage('UserEmailValidation.sendVerification')}
          </Button>
          <span className={classes.footer}>
            {formatMessage('UserEmailValidation.signin')}
            &nbsp;
            <span role="button" className={classes.link} onClick={handleGoToSignIn} onKeyDown={handleGoToSignIn} tabIndex={0}>
              {formatMessage('UserEmailValidation.signinButton')}
            </span>
          </span>
        </Box>
      </Box>
    </Grid>
  );
}
