import { makeStyles } from '@material-ui/core';
import BackgroundFigure from './assets/freemium-promo-background-figure.svg';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
  },
  leftSide: {
    display: 'flex',
    width: '40%',
    backgroundColor: '#FCF0F5',
    background: `url(${BackgroundFigure}) no-repeat no-repeat right top 247px`,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '43px 47px 40px 50px',
  },
  rightSide: {
    width: '60%',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: 24,
    padding: 16,
    fontSize: 16,
    lineHeight: '10px',
    color: theme.palette.common.black,
  },
  overlayContainer: {
    padding: 16,
    fontSize: 32,
    lineHeight: '32px',
    color: theme.palette.common.black,
  },
}));
