import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import classNames from 'classnames';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useFormatMessage } from 'apps/intl';
import { PermissionSubscriptionStatusTypes, LimiterSubscriptionStatus } from 'apps/Permissions';
import { ProductIntegrationTypes } from 'models/Product.model';
import { useStyles } from './Tabs.styles';

export function Tabs({ tabs, activeTab, onChange }: {
  tabs: {
    id: ProductIntegrationTypes;
    label: string;
    qa: string;
    body: React.ReactNode;
  }[];
  activeTab?: ProductIntegrationTypes;
  onChange?: (tab: string) => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [selectedTabId, setSelectedTabId] = useState<ProductIntegrationTypes>(activeTab || tabs[0]?.id);

  useEffect(() => {
    setSelectedTabId((prev) => activeTab || prev);
  }, [activeTab]);

  const handleSelect = useCallback((id) => () => {
    setSelectedTabId(id);

    if (onChange) {
      onChange(id);
    }
  }, [onChange]);

  const selectedTab = useMemo(() => tabs.find((tab) => tab.id === selectedTabId).body, [tabs, selectedTabId]);

  return (
    <Box>
      {/* tabs */}
      <Box mb={2}>
        <Grid container wrap="nowrap">
          <LimiterSubscriptionStatus
            permission={PermissionSubscriptionStatusTypes.canSwitchIntegrationType}
            classNameRoot={classes.tabsRoot}
          >
            <ButtonGroup fullWidth>
              {tabs.map((tab) => [
                <Button
                  key={tab.label}
                  data-qa={tab.qa}
                  className={classNames(classes.tab, {
                    [classes.active]: selectedTabId === tab.id,
                  })}
                  onClick={handleSelect(tab.id)}
                >
                  {formatMessage(tab.label)}
                </Button>,
              ])}
            </ButtonGroup>
          </LimiterSubscriptionStatus>
        </Grid>
      </Box>

      {/* content */}
      <Box>
        {selectedTab}
      </Box>
    </Box>
  );
}
