import { httpNew } from 'lib/client/http';
import { AuthInputTypes, IIdentifierFirstResponseData } from 'apps/auth';
import { IIdentifierFirstInputs } from '../models/Auth0.model';

export function identifierFirst(credentials: IIdentifierFirstInputs) {
  const { [AuthInputTypes.CaptchaToken]: captchaToken, freemiumPageVersion, ...payload } = credentials;
  return httpNew.post<IIdentifierFirstResponseData>(
    '/auth0/identifier-first',
    payload,
    {
      headers: {
        'captcha-token': captchaToken,
        'freemium-page-version': freemiumPageVersion,
      },
    },
  );
}

export function emailVerification() {
  return httpNew.post('/auth0/email-verification', null);
}

export function passwordReset() {
  return httpNew.post('/auth0/password-change', null);
}

export function getFreemiumPage() {
  return httpNew.get('/auth0/freemium-page', null);
}
