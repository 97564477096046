import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { useStyles } from './Badge.styles';

interface IBadge extends BoxProps {
  text: string;
  className?: string;
}

export const Badge = ({ text, className, ...otherProps }: IBadge) => {
  const classes = useStyles();
  return (
    <Box mr={1} fontSize={12} fontWeight="bold" className={`${classes.badge} ${className}`} {...otherProps}>{text}</Box>
  );
};
