import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from 'apps/overlay';
import classNames from 'classnames';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './ProductDependencyModal.styles';

interface IProductDependencyModalProps {
  onClose: () => void;
  onConfirm: () => void;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  leftSubtitle?: React.ReactNode;
  rightSubtitle?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  confirmText?: React.ReactNode;
  variant?: 'Red';
}

export function ProductDependencyModal({ leftComponent, rightComponent, leftSubtitle, rightSubtitle, title, subTitle, confirmText, variant, onConfirm, onClose }: IProductDependencyModalProps) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Modal className={classes.modal} onClose={onClose}>
      <>
        <Box mb={subTitle ? 0.8 : 3} color="common.black90" fontWeight="bold" fontSize="21px">
          {title}
        </Box>
        {subTitle && (
          <Box mb={3} color="common.black75" fontSize="16px">
            {subTitle}
          </Box>
        )}
        <Grid container spacing={4}>
          <Grid container direction="column" wrap="nowrap" item xs={12} lg={6}>
            <Box color="common.black75" mb={1} textAlign="center">
              {leftSubtitle}
            </Box>
            <Grid container direction="column" wrap="nowrap" className={classes.wrapper}>
              {leftComponent}
            </Grid>
          </Grid>
          <Grid container direction="column" wrap="nowrap" item xs={12} lg={6}>
            <Box color="common.black75" mb={1} textAlign="center">
              {rightSubtitle}
            </Box>
            <Grid container direction="column" wrap="nowrap" className={classes.wrapper}>
              {rightComponent}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.buttonsWrapper}>
            <Button onClick={onClose} variant="text" className={classNames(classes.button, classes.buttonCancel, classes[`buttonCancel${variant}`])}>
              <Box>
                {formatMessage('cancel')}
              </Box>
            </Button>
            <Button onClick={onConfirm} className={classNames(classes.button, classes.buttonSave, classes[`buttonSave${variant}`])}>
              {confirmText}
            </Button>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}
