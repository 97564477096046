import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 170,
    height: 50,
    fontSize: 14,
    padding: '6px 10px',
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    color: theme.palette.text.main,

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      borderBottom: `2px solid ${theme.palette.background.default}`,
      boxShadow: 'none',
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selected: {
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,

    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },
  },
}));
