import React, { PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import ChevronIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './Spoiler.styles';
import { SpoilerTypes } from './SpoilerTypes';

export const Spoiler = ({
  type = SpoilerTypes.Default,
  title,
  children,
  unmountOnExit,
  headerIcon,
  extraIcon,
  classNames,
}: PropsWithChildren<{
  type?: SpoilerTypes;
  title: string;
  classNames?: {
    container?: string;
    spoiler?: string;
  };
  unmountOnExit?: boolean;
  headerIcon?: React.ReactNode;
  extraIcon?: React.ReactNode;
}>) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const classes = useStyles();

  return (
    <div className={classNames?.container}>
      <Box
        className={classnames(classes.spoiler, classNames?.spoiler, {
          [classes.default]: type === SpoilerTypes.Default,
          [classes.warning]: type === SpoilerTypes.Warning,
          [classes.danger]: type === SpoilerTypes.Danger,
        })}
        onClick={() => setCollapsed(!isCollapsed)}
      >
        <Grid container className={classes.headerWrapper}>
          <Grid item className={classes.headerContainer}>
            {headerIcon && <span className={classes.headerIcon}>{headerIcon}</span>}
            <span>{title}</span>
          </Grid>
          <Grid item className={classes.statusIcons}>
            {extraIcon}
            <ChevronIcon className={classnames(classes.chevron, { [classes.chevronCollapsedIn]: isCollapsed })} />
          </Grid>
        </Grid>
      </Box>
      <Collapse in={isCollapsed} unmountOnExit={unmountOnExit}>{children}</Collapse>
    </div>
  );
};
