import axios from 'axios';
import { http } from 'lib/client/http';
import { IRegistryProduct, IProductTranslations } from 'models/Product.model';

export function getExternalProducts() {
  return http.get<IRegistryProduct[]>('/api/v1/dashboard/products');
}

export function getProductTranslations(url: string) {
  return axios.get<IProductTranslations>(url);
}

export function getProductPDFTemplate(url: string) {
  return axios.get<string>(url);
}
