import { http } from 'lib/client/http';
import { ApiResponse } from 'models/Client.model';
import { IFlow } from 'models/Flow.model';
import { IPreviewFlow } from '../models/FlowBuilder.model';
import { IFlowAuditEvent } from '../models/FlowAudit.model';

export function changeableFlowPost(changeableFlow: IFlow): Promise<ApiResponse<{ _id: string }>> {
  return http.post<{ _id: string }>('/api/v1/dashboard/flow', changeableFlow);
}

export function flowUpdate(merchantId: string, flowId: string, flow: IFlow): Promise<ApiResponse<IFlow>> {
  return http.put<IFlow>(`/api/v1/merchants/${merchantId}/flows/${flowId}`, flow);
}

export function previewFlow(merits: Array<string>): Promise<ApiResponse<IPreviewFlow>> {
  return http.get<IPreviewFlow>('/api/v1/dashboard/flow/preview/screenshots', { params: { meritBlocks: merits } });
}

export function getFlowHistory(flowId: string, params: Record<string, unknown>): Promise<ApiResponse<IFlowAuditEvent[]>> {
  return http.get(`/api/v1/dashboard/audit/flows/${flowId}`, { params });
}

export function getFlowHistoryEventCount(flowId: string): Promise<ApiResponse<{ count: number; }>> {
  return http.get(`/api/v1/dashboard/audit/counts/flows/${flowId}`);
}


