import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { IPreviewFlow } from 'apps/flowBuilder';
import { useFormatMessage } from 'apps/intl';
import { IMeritBlockContentAndType, IMeritBlocksAdditional } from 'apps/MeritBlockAdditional';
import { ITemplateMetadata, MetadataTypes } from 'apps/Templates';
import { QATags } from 'models/QA.model';
import React, { useMemo } from 'react';
import { FiChevronLeft, FiChevronRight, FiPlus } from 'react-icons/fi';
import { TemplateFlowContent } from '../TemplateFlowContent/TemplateFlowContent';
import { TemplateCountryTags } from '../TemplateCountryTags/TemplateCountryTags';
import { getSelectedFilterName, ICardsOptions, IFilterOptions } from '../../model/SolutionCatalog.model';
import { useStyles } from './TemplateDetails.styles';

const MAX_NUMBER_OF_COUNTRY_TAGS = 3;

export function TemplateDetails({ template, templatePreview, currentFilters, meritBlocksAdditional, onReturn, onSelect }: {
  template: ICardsOptions;
  templatePreview: IPreviewFlow;
  currentFilters: IFilterOptions;
  meritBlocksAdditional: IMeritBlocksAdditional;
  onReturn: () => void;
  onSelect: () => void;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const selectedFilterName: Nullable<string> = getSelectedFilterName(currentFilters);
  const localizeKey: string = template?.flow?.name?.replace(/\s/g, '');
  const countryWithWorldPartMetadatas = useMemo<ITemplateMetadata[]>(() => template.metadata?.filter((templateMetadata) => templateMetadata.type === MetadataTypes.Country && templateMetadata?.data?.worldPart), [template]);
  const meritBlockContentAndTypes = useMemo<IMeritBlockContentAndType[]>(() => template?.meritsList?.filter((type) => meritBlocksAdditional[type])
    .map((type) => ({ type, content: meritBlocksAdditional[type] })), [template?.meritsList, meritBlocksAdditional]);

  return (
    <Box className={classes.container}>
      <Box className={classes.navigationBar}>
        <IconButton className={classes.returnIcon} onClick={onReturn}>
          <FiChevronLeft />
        </IconButton>
        <Box>{formatMessage('TemplatesModal.title')}</Box>
        <FiChevronRight />
        <Box>{formatMessage(`Templates.category.${selectedFilterName || 'allTemplates'}.label`)}</Box>
        <FiChevronRight />
        <Box>{formatMessage(`Template.${localizeKey}.name`)}</Box>
      </Box>
      <Box className={classes.header}>
        <Box>
          <Box className={classes.title}>{formatMessage(`Template.${localizeKey}.name`)}</Box>
          <Box className={classes.description}>{formatMessage(`Template.${localizeKey}.description`)}</Box>
        </Box>
        <Box className={classes.headerRight}>
          <Button
            className={classes.buttonSelect}
            data-qa={QATags.Templates.SelectButton}
            onClick={onSelect}
          >
            <FiPlus />
            {formatMessage('TemplateCard.button.select')}
          </Button>
          <TemplateCountryTags
            maxNumTags={MAX_NUMBER_OF_COUNTRY_TAGS}
            countryMetadatas={countryWithWorldPartMetadatas}
            countryFilters={currentFilters.country}
            labelHasEnabled={formatMessage('TemplateCard.label.countriesAvailable')}
          />
        </Box>
      </Box>
      <TemplateFlowContent
        screenshots={templatePreview.screenshots}
        meritBlockContentAndTypes={meritBlockContentAndTypes}
        meritBlockToScreenshot={templatePreview.meritsIndex}
      />
    </Box>
  );
}
