import React from 'react';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Close from '@material-ui/icons/Close';
import { IWatchlist } from 'models/Watchlist.model';
import { selectCanManageBasicWatchlists, selectCurrentBasicWatchlist, selectCurrentBasicWatchlistId, selectWatchlistsModel } from '../../state/Aml.selectors';
import { IBasicWatchlistModalValidationInputs } from '../../models/Aml.model';
import { useStyles } from './BasicWatchlistModalValidation.styles';
import { BasicWatchlistModalValidationForm } from '../BasicWatchlistModalValidationForm/BasicWatchlistModalValidationForm';

export function BasicWatchlistModalValidation({ watchlist, onClose, onChangeVisibility, onSubmit }: {
  onClose: () => void;
  onChangeVisibility: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onSubmit: (values: IBasicWatchlistModalValidationInputs, watchlist?: Partial<IWatchlist>) => void;
  watchlist?: IWatchlist;
}) {
  const formatMessage = useFormatMessage();
  const { isLoading: isWatchlistsLoading } = useSelector(selectWatchlistsModel);
  const currentWatchlist = useSelector(selectCurrentBasicWatchlist);
  const currentWatchlistId = useSelector<any, number>(selectCurrentBasicWatchlistId);
  const canManageBasicWatchlists = useSelector<any, boolean>(selectCanManageBasicWatchlists);

  const classes = useStyles();
  const isEdit = Boolean(currentWatchlistId);

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid container>
          <Grid item xs={6}>
            <Box mb={4}>
              <Typography variant="h3" className={classes.modalTitle}>
                {formatMessage('Watchlist.settings.modal.title')}
              </Typography>
              {Boolean(currentWatchlistId) && (
                <Typography variant="subtitle2" className={classes.modalSubTitle}>
                  {formatMessage('BasicWatchlist.settings.modal.subtitle')}
                  &#58;
                  {' '}
                  {currentWatchlistId}
                </Typography>
              )}
            </Box>
          </Grid>
          {isEdit && canManageBasicWatchlists && (
            <Grid item>
              <FormControlLabel
                control={(
                  <Switch
                    checked={currentWatchlist.isVisible}
                    onChange={onChangeVisibility}
                    color="primary"
                    disabled={isWatchlistsLoading}
                  />
                )}
                label={formatMessage('Watchlist.settings.modal.validation.visibility')}
              />
            </Grid>
          )}
        </Grid>
        <div onClick={onClose} onKeyPress={onClose} role="button" tabIndex={0} className={classes.closeButton}>
          <Close />
        </div>
      </Grid>
      <BasicWatchlistModalValidationForm watchlist={watchlist} onClose={onClose} onSubmit={onSubmit} />
    </Box>
  );
}
