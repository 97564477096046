import { createStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  container: {
    flexGrow: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    minHeight: '90vh',
    overflowY: 'hidden',
  },
  titleIcon: {
    flexShrink: 0,
    fontSize: 15,
    strokeWidth: 1.5,
  },
  newList: {
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: theme.palette.common.lightblue,
    cursor: 'pointer',
  },
  inactive: {
    visibility: 'hidden',
  },
  notification: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.white,
    borderRadius: 10,
    width: 20,
    height: 20,
    display: 'inline-flex',
    marginLeft: 6,
    fontSize: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'relative',
    top: 0,
    right: 12,
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.gray,
    borderRadius: 10,
    transition: '.25s all ease-in-out',
    '&:hover, &:focus': {
      filter: 'brightness(0.8)',
      backgroundColor: theme.palette.common.gray,
    },
  },
  meritCloseBtn: {
    cursor: 'pointer',
    width: 50,
    '&:hover, &:focus': {
      filter: 'brightness(0.8)',
    },
  },
  meritBlockHeaderText: {
    fontSize: 16,
    color: theme.palette.common.black90,
  },
}));
