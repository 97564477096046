import Box from '@material-ui/core/Box';
import { useFormattedValue } from 'lib/formatValue.hook';
import React from 'react';
import classnames from 'classnames';
import { includesPattern } from 'lib/string';
import { useFormatMessage } from 'apps/intl';
import { ImageContainer, PrivateImage } from 'apps/media';
import { CheckStepDetailsImage } from '../CheckStepDetailsImage/CheckStepDetailsImage';
import { useStyles } from './CheckStepDetails.styles';
import { ChecksWithImage, ChecksWithObject } from '../../models/checks.model';
import { CheckStepDetailsObject } from '../CheckStepDetailsObject/CheckStepDetailsObject';

export function CheckStepDetailsEntry({ label, value, isMarkedAsFailed = false, isCentered = false, isMediaUrl = false }: {
  label: string;
  value: any;
  isMarkedAsFailed?: boolean;
  isCentered?: boolean;
  isMediaUrl?: boolean;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const formatted = useFormattedValue(label, value, [
    { pattern: ChecksWithImage, formatter: CheckStepDetailsImage },
    { pattern: ChecksWithObject, formatter: CheckStepDetailsObject },
  ]);

  return (
    <Box key={label} className={classnames(classes.item, { [classes.centeredItem]: isCentered })}>
      <Box mb={0.4} className={classnames(classes.value, { [classes.failed]: isMarkedAsFailed }, 'fs-exclude')}>{isMediaUrl ? <ImageContainer src={value} alt="" className={classes.image} /> : formatted}</Box>
      {!includesPattern(label, ChecksWithObject) && (
        <Box className={classes.label}>
          {formatMessage(
            `identity.field.${label}`, { defaultMessage: label },
          )}
        </Box>
      )}
    </Box>
  );
}
