export const ERROR_COMMON_MESSAGE = 'Something went wrong. Please retry';

export enum ErrorMessagesTokenTypes {
  ERROR_COMMON = 'Error.common',
  IN_REVIEW_MODE_ERROR = 'Error.inReviewMode'
}

export enum ErrorTypes {
  SystemError = 'SystemError',
  PasswordExpired = 'Password is expired',
  PasswordInvalid = 'Unauthorized',
}

export enum ErrorStatusTypes {
  ValidationError = 400,
  WrongCredentials = 401,
  BlockedByMerchant = 403,
  UnprocessableEntity = 422,
  error404 = 404,
  PasswordInvalid= 422,
  TooManyRequests = 429,
  Locked = 423,
  error500 = 500,
  PayloadTooLarge = 413,
}

export enum PasswordInvalidErrorCodeTypes {
  PasswordUsedBefore = 'password.usedBefore',
  PasswordWeak = 'password.weak',
}

export interface IErrorTypeData {
  code: string;
  type: ErrorTypes;
  message: ErrorMessagesFromBackendTypes;
}

export interface IErrorType<T = IErrorTypeData> {
  response?: {
    status: ErrorStatusTypes;
    data: T;
  };
}

export interface IBaseError<T = Record<string, any>> {
  name: string;
  status: number;
  message: string;
  details?: T;
}

enum ErrorMessagesFromBackendTypes {
  IN_REVIEW_MODE_ERROR = 'Cannot update verification running in review mode',
}

export function isNotFound(error: IErrorType) {
  return error && error.response && error.response.status >= ErrorStatusTypes.ValidationError && error.response.status < 499;
}

export function isInReviewModeError(error: IErrorType) {
  return error?.response?.data?.message === ErrorMessagesFromBackendTypes.IN_REVIEW_MODE_ERROR;
}
