import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { useCallback } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'apps/intl';
import { ICustomField, IMapping } from 'models/CustomField.model';
import { InfoTooltip } from 'apps/ui';
import { CustomFieldModalTypes, isValidFieldSystemName } from '../../../../models/CustomField.model';
import { updateCustomFieldEditedField, updateCustomFieldModalStep } from '../../../../state/CustomField.actions';
import { selectCustomFieldEditedField, selectCustomFieldEditedMapping } from '../../../../state/CustomField.selectors';
import { InfoIcon, TextFieldInput, useStyles } from '../../CustomFieldModalConfigureAtomic.styles';

export function CustomFieldSystemNameSetting() {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const classes = useStyles();

  const selectedFieldMapping = useSelector<any, IMapping>(selectCustomFieldEditedMapping);
  const selectedCustomField = useSelector<any, ICustomField>(selectCustomFieldEditedField);

  const goToDocumentMapping = useCallback(() => dispatch(updateCustomFieldModalStep(CustomFieldModalTypes.MappingFieldToDocument)), [dispatch]);

  const handleUpdateSystemName = ({ target: { value, name } }) => {
    if (!isValidFieldSystemName(value)) {
      return;
    }
    dispatch(updateCustomFieldEditedField({ ...selectedCustomField, [name]: value }));
  };

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="subtitle2">
          {formatMessage('CustomField.settings.fieldSystemName')}
          <InfoTooltip
            placement="right"
            title={formatMessage('CustomField.settings.systemName.tooltip')}
          >
            <InfoIcon />
          </InfoTooltip>
        </Typography>
      </Grid>
      <Grid item>
        <TextFieldInput
          fullWidth
          value={selectedCustomField.name}
          type="text"
          name="name"
          onChange={handleUpdateSystemName}
          placeholder={formatMessage('CustomField.settings.fieldSystemName.placeholder')}
        />
        <Link
          component="button"
          variant="body1"
          underline="always"
          onClick={goToDocumentMapping}
        >
          {selectedFieldMapping?.country && selectedFieldMapping?.key && (<FiCheckCircle className={classes.checkIcon} />)}
          {formatMessage('CustomField.settings.goToDocumentMapping')}
          <InfoTooltip
            placement="right"
            title={formatMessage('CustomField.settings.modal.mappingFieldToDocument.tooltip')}
          >
            <InfoIcon />
          </InfoTooltip>
        </Link>
      </Grid>
    </Grid>
  );
}
