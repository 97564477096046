import { downloadBlob } from 'lib/file';
import { ProductTypes } from 'models/Product.model';
import { productManagerService } from 'apps/Product';
import { createTypesSequences } from 'state/store.utils';
import { BankAccountData } from '../services/BankAccountData.service';
import * as api from '../client/BankAccountData.client';
import { SliceNamesTypes } from './BankAccountData.store';

export const types = createTypesSequences(SliceNamesTypes);

export const bankAccountDataInit = (registered: ProductTypes[] = []) => (): Nullable<ProductTypes> => {
  // don't register old version if PRS version is availible
  if (registered.includes(ProductTypes.BankAccountData)) {
    return null;
  }

  const bankAccountData = new BankAccountData();
  productManagerService.register(bankAccountData);
  return bankAccountData.id;
};

export const bankAccountGenerateExcelReport = (verificationId: string, asMerchantId: string, filename: string) => async (dispatch) => {
  dispatch({ type: types.excelFile_REQUEST });
  try {
    const { data } = await api.bankAccountGenerateExcelReport(verificationId, asMerchantId);

    const blob = new Blob([data]);
    await downloadBlob(blob, `${filename}.xlsx`);
    dispatch({ type: types.excelFile_SUCCESS });
  } catch (error) {
    console.error(error);
    dispatch({ type: types.excelFile_FAILURE });
  }
};
