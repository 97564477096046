import { parseDate } from 'apps/ui/models/ReactDayPicker.model';
import { changeDateFormat, DateFormatTypes } from 'lib/date';
import { isNil } from 'lib/isNil';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { CountryCodeTypes } from 'models/Country.model';
import { AtomicCustomFieldTypes, ICustomField, IMapping, ISelectOptions, IStaticTextParams, MAIN_DROPPABLE_ID, MainCustomFieldTypes, MappingValueKeyTypes } from 'models/CustomField.model';
import { SupportedLocales, SupportedLocalesToLocaleAsPopup } from 'models/Intl.model';
import { ProductSettings, ProductSettingsProps } from 'models/Product.model';

export const FIELD_SYSTEM_NAME_PATTERN = '^([a-zA-Z-_0-9]+)?$';

export const REGEXP_BANNED_SYMBOLS_IN_FIELD_SYSTEM_NAME = /[^a-zA-Z-_0-9]/g;

export const REPLACEMENT_SYMBOL = '_';

export const MAX_THUMBNAIL_SIZE = 64;

export const MAIN_DROPPABLE_TYPE = MAIN_DROPPABLE_ID;

export const MIN_SELECT_OPTIONS = 2;

export const MAX_TEXT_INPUT_LENGTH = 100;

const TYPES_WITH_CHILDREN = [MainCustomFieldTypes.Group, MainCustomFieldTypes.Select];

export const CUSTOM_FIELD_SELECTION_OPTION_NAME = 'Option 1';
export const CUSTOM_FIELD_SELECTION_SECOND_OPTION_NAME = 'Option 2';
export const CUSTOM_FIELD_SELECTION_OPTION_PREFIX = '-option1';
export const CUSTOM_FIELD_SELECTION_SECOND_OPTION_PREFIX = '-option2';

export const FIELD_WITH_SELECTION_OPTION = [AtomicCustomFieldTypes.Select];
export const FIELD_WITH_HINT = [AtomicCustomFieldTypes.Text, AtomicCustomFieldTypes.Date];
export const FIELD_CAN_BE_MANDATORY = [AtomicCustomFieldTypes.Checkbox, AtomicCustomFieldTypes.Select, AtomicCustomFieldTypes.Text, AtomicCustomFieldTypes.Date];

export enum CustomFieldSettingTypes {
  Fields = 'fields',
  FlattenedFields = 'flattenedFields',
  CountryRestriction = 'countryRestriction',
}

export interface IDefaultValues {
  index: Nullable<number>;
  checked: boolean;
  text: string;
}

export type CustomFieldProductSettings = ProductSettings<CustomFieldSettingTypes>;

export type CustomFieldProductSettingsProps = ProductSettingsProps<CustomFieldSettingTypes>;

export enum CustomFieldTypes {
  InputTypes = 'InputTypes',
  FormatValidation = 'FormatValidation',
  MappingDocuments = 'MappingDocuments'
}

export enum CustomFieldModalTypes {
  ConfigureField = 'configureField',
  MappingFieldToDocument = 'mappingFieldToDocument',
  ConfigureGroup = 'Group',
  ConfigureSelection = 'Selection',
  ConfigureOption = 'Option',
  PreviewCustomField = 'previewCustomField',
}

export const MODAL_BY_FIELD_TYPE = {
  [MainCustomFieldTypes.Select]: CustomFieldModalTypes.ConfigureSelection,
  [MainCustomFieldTypes.Group]: CustomFieldModalTypes.ConfigureGroup,
  [MainCustomFieldTypes.Atomic]: CustomFieldModalTypes.ConfigureField,
};

export interface ICustomFieldConfig {
  regex: string;
  type: AtomicCustomFieldTypes;
}

export const CONFIG_BY_KEY: PartialRecord<MappingValueKeyTypes, ICustomFieldConfig> = {
  [MappingValueKeyTypes.BusinessRegistrationSystemCompanyRegistrationNumber]: {
    // The regex actually combines 3 regexes:
    // - ^((PVT|CPR)\\/\\d{4}\\/\\d{1,7})$
    //   numbers like CPR/2013/119491
    // - ^(C(.|\\s)?/?(\\d{1,7}|\\d{1,2}\\/\\d{1,6}|\\d{4}/\\d{4}))$
    //   numbers like C.127237, C 44008, C.3/75, C93384, CF/2009/2499
    // - ^([A-Z]{3}[-\\s][A-Z0-9]{1,7})$
    //   numbers like CPV-V7UYLY2, PVT-PJUVZMK
    // Check docs for details https://www.notion.so/metamap/Kenya-BRS-feature-specification-ad11c0f82db5470a989766c2aba81749#66eb0b9772dd4e9591068a2351ca5c8f
    regex: '^((PVT|CPR)\\/\\d{4}\\/\\d{1,7}|C(.|\\s)?/?(\\d{1,7}|\\d{1,2}\\/\\d{1,6}|\\d{4}/\\d{4})|[A-Z]{3}[-\\s][A-Z0-9]{1,7})$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdFirstName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdLastName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdDateOfBirth]: {
    regex: '',
    type: AtomicCustomFieldTypes.Date,
  },
  [MappingValueKeyTypes.UnifiedMultiPurposeIdUMIDSSNcrn]: {
    regex: '^[0-9]{4}-?[0-9]{7}-?[0-9]$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.SocialSecuritySystemCardUMIDSSNssn]: {
    regex: '^[0-9]{2}-?[0-9]{7}-?[0-9]$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdNINDocumentNumber]: {
    regex: '^[0-9]{11}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdBVNDocumentNumber]: {
    regex: '^[0-9]{11}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdVINDocumentNumber]: {
    regex: '^[a-zA-Z0-9]{19}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.CertificateOfTaxTINTin]: {
    regex: '^[0-9]{10}$|^[0-9]{8}-?[0-9]{4}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.CertificateOfIncorporationCACRcBnNumber]: {
    regex: '^([a-zA-Z]{2})?\\s?[0-9]{4,7}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdKTPNik]: {
    regex: '^[0-9]{16}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdKTPName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.FirstName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.LastName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.MiddleName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.DateOfBirth]: {
    regex: '',
    type: AtomicCustomFieldTypes.Date,
  },
  [MappingValueKeyTypes.FullName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.DrivingLicenseDLNo]: {
    regex: '^[a-zA-Z][0-9]{2}-?[0-9]{2}-?[0-9]{6}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.DrivingLicenseDLSerialNumber]: {
    regex: '^[0-9]{9}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.DrivingLicenseDLExpirationDate]: {
    regex: '',
    type: AtomicCustomFieldTypes.Date,
  },
  [MappingValueKeyTypes.NationalIdIprsIdNumber]: {
    regex: '^[0-9]{8}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.AlienCardIprsIdNumber]: {
    regex: '^[0-9]{6,10}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.PassportIprsIdNumber]: {
    regex: '^[a-zA-Z]{1}[0-9]{6,8}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.PoliceClearanceCertificateClearanceNumber]: {
    regex: '^[A-Z0-9]+-[A-Z0-9]+$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.PoliceClearanceCertificatePnpClearanceNumber]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.PrcProfessionalLicensePRCLicenseNumber]: {
    regex: '^[0-9]{7}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.Profession]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdVirtualNINDocumentNumber]: {
    regex: '^[0-9a-zA-Z]{16}$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdDniDocumentNumber]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdDniProcedureNumber]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdDniDateOfIssue]: {
    regex: '',
    type: AtomicCustomFieldTypes.Date,
  },
  [MappingValueKeyTypes.Gender]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.RegistroCivilDocumentNumber]: {
    regex: '^[0-9]+$',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.BrazilianCNPJ]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.Rfc]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.NationalIdCICDocNumber]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.Ocr]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.Ne]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.Cde]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.Curp]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.CompanyName]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
  [MappingValueKeyTypes.CPF]: {
    regex: '',
    type: AtomicCustomFieldTypes.Text,
  },
};

export const Global = 'AA';

export const MAPPING_ALLOWED_COUNTRIES: CountryCodeTypes[] = [
  CountryCodeTypes.ID,
  CountryCodeTypes.KE,
  CountryCodeTypes.PH,
  CountryCodeTypes.NG,
  CountryCodeTypes.AR,
  CountryCodeTypes.UY,
  CountryCodeTypes.MX,
  CountryCodeTypes.BR,
];

export const MAPPING_OPTIONS: PartialRecord<typeof Global | CountryCodeTypes, MappingValueKeyTypes[]> = {
  [Global]: [
    MappingValueKeyTypes.FirstName,
    MappingValueKeyTypes.MiddleName,
    MappingValueKeyTypes.LastName,
    MappingValueKeyTypes.FullName,
    MappingValueKeyTypes.DateOfBirth,
    MappingValueKeyTypes.Profession,
    MappingValueKeyTypes.Gender,
    MappingValueKeyTypes.CompanyName,
  ],
  [CountryCodeTypes.ID]: [
    MappingValueKeyTypes.NationalIdDateOfBirth,
    MappingValueKeyTypes.NationalIdKTPName,
    MappingValueKeyTypes.NationalIdKTPNik,
  ],
  [CountryCodeTypes.KE]: [
    MappingValueKeyTypes.NationalIdIprsIdNumber,
    MappingValueKeyTypes.AlienCardIprsIdNumber,
    MappingValueKeyTypes.PassportIprsIdNumber,
    MappingValueKeyTypes.BusinessRegistrationSystemCompanyRegistrationNumber,
  ],
  [CountryCodeTypes.PH]: [
    MappingValueKeyTypes.UnifiedMultiPurposeIdUMIDSSNcrn,
    MappingValueKeyTypes.SocialSecuritySystemCardUMIDSSNssn,
    MappingValueKeyTypes.DrivingLicenseDLNo,
    MappingValueKeyTypes.DrivingLicenseDLSerialNumber,
    MappingValueKeyTypes.DrivingLicenseDLExpirationDate,
    MappingValueKeyTypes.PoliceClearanceCertificateClearanceNumber,
    MappingValueKeyTypes.PoliceClearanceCertificatePnpClearanceNumber,
    MappingValueKeyTypes.PrcProfessionalLicensePRCLicenseNumber,
  ],
  [CountryCodeTypes.NG]: [
    MappingValueKeyTypes.NationalIdFirstName,
    MappingValueKeyTypes.NationalIdLastName,
    MappingValueKeyTypes.NationalIdDateOfBirth,
    MappingValueKeyTypes.NationalIdNINDocumentNumber,
    MappingValueKeyTypes.NationalIdBVNDocumentNumber,
    MappingValueKeyTypes.NationalIdVINDocumentNumber,
    MappingValueKeyTypes.CertificateOfTaxTINTin,
    MappingValueKeyTypes.CertificateOfIncorporationCACRcBnNumber,
    MappingValueKeyTypes.NationalIdVirtualNINDocumentNumber,
  ],
  [CountryCodeTypes.AR]: [
    MappingValueKeyTypes.NationalIdDniDocumentNumber,
    MappingValueKeyTypes.NationalIdDniDateOfIssue,
    MappingValueKeyTypes.NationalIdDniProcedureNumber,
  ],
  [CountryCodeTypes.UY]: [
    MappingValueKeyTypes.RegistroCivilDocumentNumber,
  ],
  [CountryCodeTypes.MX]: [
    // RFC fields
    MappingValueKeyTypes.Rfc,
    // INE fields
    MappingValueKeyTypes.NationalIdCICDocNumber,
    MappingValueKeyTypes.Ocr,
    MappingValueKeyTypes.Ne,
    MappingValueKeyTypes.Cde,
    // RFC fields
    MappingValueKeyTypes.Curp,
  ],
  [CountryCodeTypes.BR]: [
    MappingValueKeyTypes.BrazilianCNPJ,
    MappingValueKeyTypes.CPF,
  ],
};

export const INPUT_TYPE_WITH_DROPPABLE = [MainCustomFieldTypes.Group, MainCustomFieldTypes.Select];

export const AllowedGroupDrop = [MAIN_DROPPABLE_ID, MainCustomFieldTypes.Select];
export const AllowedFieldDrop = [MAIN_DROPPABLE_ID, MainCustomFieldTypes.Group];
export const AllowedSectionDrop = [MAIN_DROPPABLE_ID];
export const AllowedSeparatorDrop = [MAIN_DROPPABLE_ID];

export const EMPTY_OPTION: ISelectOptions = {
  label: '',
  value: '',
};

export const EMPTY_SELECT_OPTIONS: ISelectOptions[] = [
  { ...EMPTY_OPTION },
  { ...EMPTY_OPTION },
];

export const EMPTY_MAPPING: IMapping = {
  country: '',
  key: '',
  shouldCheckFormat: false,
  shouldFilter: false,
};

export const EMPTY_STATIC_TEXT_PARAMS: IStaticTextParams = {
  header: '',
  text: '',
  isCollapsed: false,
};

export const EMPTY_CUSTOM_ATOMIC_FIELD: ICustomField = {
  name: '',
  label: '',
  type: MainCustomFieldTypes.Atomic,
  isMandatory: false,
  atomicFieldParams: {
    placeholder: '',
    defaultValue: '',
    regex: '',
    mapping: null,
    selectOptions: [...EMPTY_SELECT_OPTIONS],
    type: null,
    staticTextParams: { ...EMPTY_STATIC_TEXT_PARAMS },
  },
};

export const EMPTY_CUSTOM_GROUP_FIELD: ICustomField = {
  name: '',
  label: '',
  type: MainCustomFieldTypes.Group,
  children: [],
  isMandatory: false,
};

export const EMPTY_PAGE_SEPARATOR: ICustomField = {
  name: '',
  label: '',
  type: MainCustomFieldTypes.Separator,
  isMandatory: false,
};

export const EMPTY_CUSTOM_SELECT_FIELD: ICustomField = {
  name: '',
  label: '',
  type: MainCustomFieldTypes.Select,
  children: [],
  isMandatory: false,
  selectedGroup: null,
};

export type HandleUpdateFields = (fields: ICustomField[]) => void;

export type HandleOpenModal = (modalType: CustomFieldModalTypes, editedField?: ICustomField, index?: number, parent?: string) => () => void;

export const checkIsDropDisabled = (draggableId: string, droppableId: string, fields: ICustomField[]): boolean => {
  if (!draggableId) {
    return false;
  }
  const typeDroppable = fields.find(({ name }) => name === droppableId)?.type || MAIN_DROPPABLE_TYPE;
  const typeDraggable = fields.find(({ name }) => name === draggableId)?.type;
  switch (typeDraggable) {
    case MainCustomFieldTypes.Select:
      return !AllowedSectionDrop.includes(typeDroppable);
    case MainCustomFieldTypes.Group:
      return !AllowedGroupDrop.includes(typeDroppable);
    case MainCustomFieldTypes.Separator:
      return !AllowedSeparatorDrop.includes(typeDroppable);
    default:
      return !AllowedFieldDrop.includes(typeDroppable);
  }
};

function removeEmpty<T>(obj: T): T {
  return <T>Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => value !== null && value !== '')
      .map(([key, value]) => [key, (value instanceof Object && !(value instanceof Array)) ? removeEmpty(value) : value]),
  );
}

export function mutableFindChildren(fields: ICustomField[], parentName: string): ICustomField[] {
  if (parentName === MAIN_DROPPABLE_ID) {
    return fields;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const field of fields) {
    if (field.name === parentName) {
      return field.children;
    }
    if (TYPES_WITH_CHILDREN.includes(field.type)) {
      const child = mutableFindChildren(field.children, parentName);
      if (child !== undefined) {
        return child;
      }
    }
  }
  return undefined;
}

export function findAndDelete(fields: ICustomField[], name: string): ICustomField[] {
  const result = fields;
  for (let i = 0; result.length > i; i += 1) {
    const field = result[i];
    if (field.name === name) {
      result.splice(i, 1);
      break;
    }
    if (TYPES_WITH_CHILDREN.includes(field.type)) {
      findAndDelete(field.children, name);
    }
  }
  return result;
}

export function findAndSetValues(fields: ICustomField[], names: string[], values: (string | boolean | Date)[], locale: SupportedLocales): ICustomField[] {
  for (let fieldIdx = 0; fields.length > fieldIdx; fieldIdx += 1) {
    if (!names.length) {
      break;
    }
    const field = fields[fieldIdx];
    const index = names.indexOf(field.name);
    if (index !== -1) {
      names.splice(index, 1);
      const value = values.splice(index, 1)[0];
      if (value) {
        if (field.type === MainCustomFieldTypes.Select) {
          field.selectedGroup = value as string;
        } else if (field.atomicFieldParams.type === AtomicCustomFieldTypes.Date) {
          field.atomicFieldParams.value = changeDateFormat(value as string, DateFormatTypes.LocalizedDayMonthYearSlashes, locale);
        } else {
          field.atomicFieldParams.value = value as string;
        }
      }
      if (!value) {
        if (field.type === MainCustomFieldTypes.Select) {
          delete field.selectedGroup;
        } else if (field.atomicFieldParams.type === AtomicCustomFieldTypes.Checkbox) {
          field.atomicFieldParams.value = value as string;
        } else {
          delete field.atomicFieldParams.value;
        }
      }
    }
    if (TYPES_WITH_CHILDREN.includes(field.type)) {
      findAndSetValues(field.children, names, values, locale);
    }
  }
  return fields;
}

export const fillFieldsWithData = (data: unknown, fields: ICustomField[], locale: SupportedLocales): ICustomField[] => {
  const names: string[] = Object.keys(data);
  const values: (string | boolean | Date)[] = Object.values(data);
  return findAndSetValues(cloneDeep(fields), names, values, locale);
};

export const prepareCustomField = (customField: ICustomField, defaults: Nullable<IDefaultValues> = null): ICustomField => {
  const result = cloneDeep(customField);
  if (result?.atomicFieldParams?.type !== AtomicCustomFieldTypes.Select) {
    delete result?.atomicFieldParams?.selectOptions;
  }
  if (result?.atomicFieldParams?.type !== AtomicCustomFieldTypes.StaticText) {
    delete result?.atomicFieldParams?.staticTextParams;
  }
  if (!result?.atomicFieldParams?.mapping?.country || !result?.atomicFieldParams?.mapping?.key) {
    delete result?.atomicFieldParams?.mapping;
  }
  if (result?.atomicFieldParams?.type === AtomicCustomFieldTypes.Select) {
    delete result.atomicFieldParams.defaultValue;
    if (!isNil(defaults.index) && defaults.index !== -1) {
      result.atomicFieldParams.defaultValue = result?.atomicFieldParams?.selectOptions[defaults.index].value;
    }
  }
  if (result?.atomicFieldParams?.type === AtomicCustomFieldTypes.Text) {
    delete result.atomicFieldParams.defaultValue;
    if (defaults.text) {
      result.atomicFieldParams.defaultValue = defaults.text;
    }
  }
  if (result?.atomicFieldParams?.type === AtomicCustomFieldTypes.Checkbox) {
    result.atomicFieldParams.defaultValue = defaults.checked;
  }
  return removeEmpty(result);
};

export const isSetMainData = (name: string, label: string): boolean => !!(name && label);

export const isNameUniq = (listFlattenFields: ICustomField[], oldName: string, newName: string): boolean => {
  const duplicateNameFields = listFlattenFields.filter((field) => field.name === newName);
  if (duplicateNameFields.length === 1 && oldName === newName) {
    return true;
  }
  return duplicateNameFields.length === 0;
};

export const isAllOptionsUniq = (selectOptions: ISelectOptions[]): boolean => uniqBy(selectOptions, 'value').length === selectOptions.length;

export const isAllOptionsFielded = (selectOptions: ISelectOptions[]): boolean => selectOptions.every((option) => option.value && option.label);

export const validateDateInput = (str: string, field: ICustomField, locale: string): boolean => {
  if (!str && !field?.isMandatory) {
    return true;
  }
  return parseDate(str, DateFormatTypes.LocalizedDayMonthYearSlashes, locale) instanceof Date;
};

export const prepareEmptyGroupToSection = (parentName: string, prefix: string = CUSTOM_FIELD_SELECTION_OPTION_PREFIX, label: string = CUSTOM_FIELD_SELECTION_OPTION_NAME) => ({
  ...cloneDeep(EMPTY_CUSTOM_GROUP_FIELD),
  name: `${parentName}${prefix}`,
  label,
});

export const fieldIsValid = (isUploadingThumbnail: boolean, selectedCustomField: ICustomField, listFlattenFields: ICustomField[], oldName: string): boolean => {
  if (isUploadingThumbnail) {
    return false;
  }
  if (!isSetMainData(selectedCustomField.name, selectedCustomField.label)) {
    return false;
  }
  return isNameUniq(listFlattenFields, oldName, selectedCustomField.name);
};

export const atomicFieldIsValid = (selectedCustomField: ICustomField, listFlattenFields: ICustomField[], oldName: string): boolean => {
  if (!selectedCustomField.atomicFieldParams.type) {
    return false;
  }
  if (!isSetMainData(selectedCustomField.name, selectedCustomField.label)) {
    return false;
  }
  if (selectedCustomField.atomicFieldParams.regex && !selectedCustomField.atomicFieldParams.placeholder) {
    return false;
  }
  if (selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.Select) {
    return isNameUniq(listFlattenFields, oldName, selectedCustomField.name) && isAllOptionsUniq(selectedCustomField.atomicFieldParams.selectOptions) && isAllOptionsFielded(selectedCustomField.atomicFieldParams.selectOptions);
  }
  if (selectedCustomField.atomicFieldParams.type === AtomicCustomFieldTypes.StaticText) {
    if (!selectedCustomField.atomicFieldParams?.staticTextParams?.text) {
      return false;
    }
    if (!selectedCustomField.atomicFieldParams?.staticTextParams?.header && selectedCustomField.atomicFieldParams?.staticTextParams?.isCollapsed) {
      return false;
    }
  }
  return isNameUniq(listFlattenFields, oldName, selectedCustomField.name);
};

export const getNotSelectedMapping = (listFlattenFields: ICustomField[], newMapping: IMapping, mapping: IMapping): MappingValueKeyTypes[] => {
  if (!newMapping?.country) {
    return [];
  }
  const notSelected = MAPPING_OPTIONS[newMapping?.country]?.filter((option) => !listFlattenFields.find((field) => field?.atomicFieldParams?.mapping?.key === option)) || [];
  if (mapping.country === newMapping.country && !notSelected.includes(mapping.key)) {
    notSelected.push(mapping.key);
  }
  return notSelected;
};

export const isValidFieldSystemName = (value: string): boolean => new RegExp(FIELD_SYSTEM_NAME_PATTERN).test(value);

export const isTypeFromConfig = (selectedFieldMapping: IMapping): boolean => !!CONFIG_BY_KEY[selectedFieldMapping?.key]?.type;

export const generatePreviewModeURL = (listFields: ICustomField[], currentLocale: SupportedLocales): string => {
  const previewUrl = new URL(`${process.env.REACT_APP_PRODUCT_REGISTRY_URL_LEGACY}/custom-input-product/`);

  previewUrl.searchParams.append('isPreviewMode', 'true');
  previewUrl.searchParams.append('fields', JSON.stringify(listFields));
  previewUrl.searchParams.append('locale', SupportedLocalesToLocaleAsPopup[currentLocale]);

  return previewUrl.href;
};
