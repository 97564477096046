import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useFormatMessage } from 'apps/intl';
import React from 'react';
import { IFlattenVerificationPatternConfig } from 'models/VerificationPatternsConfigs.model';
import { useStyles } from './GovCheckModalBanner.styles';

export function GovCheckModalBanner({ onClose, govCheck }: {
  onClose: () => void;
  govCheck: IFlattenVerificationPatternConfig;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.icon}>
          <CheckIcon />
        </Box>
        <Box className={classes.body}>
          <Box className={classes.title}>
            {formatMessage('GovCheckMerit.settings.banner.title', {
              messageValues: {
                govCheck: formatMessage(`GovCheck.check.${govCheck.patternName}.${govCheck.patternValue}`, {
                  defaultMessage: formatMessage(`GovCheck.check.${govCheck.patternName}`),
                }),
              },
            })}
          </Box>
          <Box className={classes.text}>
            {formatMessage('GovCheckMerit.settings.banner.text')}
          </Box>
        </Box>
      </Box>
      <IconButton
        onClick={onClose}
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
