import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  coordinateLabel: {
    fontSize: 12,
    fontFamily: 'Inter',
    fill: theme.palette.text.main,
  },
  brightgray: {
    fill: theme.palette.common.brightgray,
  },
  green: {
    fill: theme.palette.common.green,
  },
  red: {
    fill: theme.palette.common.red,
  },
  orange: {
    fill: theme.palette.common.orange,
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 22,
    fill: theme.palette.common.black90,
  },
  fillColor: {
    fill: theme.palette.common.brightgray,
  },
}));

export const useLagendStyles = makeStyles((theme) => ({
  roundIcon: {
    display: 'flex',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItem: 'center',
    marginRight: 8,
    strokeWidth: 2,
    '& svg': {
      fill: 'none',
    },
    '&.error': {
      background: theme.palette.common.lightRed,
      '& svg': {
        stroke: theme.palette.common.red,
        fill: 'none',
      },
    },
    '&.worning': {
      background: theme.palette.common.lightyellow,
      '& svg': {
        stroke: theme.palette.common.orange,
        width: 19,
      },
    },
    '&.success': {
      background: theme.palette.common.greentransparent,
      '& svg': {
        stroke: theme.palette.common.green,
      },
    },
  },
}));
