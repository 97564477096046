import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.common.black50}`,
    padding: '4px',
    gap: '4px',
  },
}));
