import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { RecordView } from 'apps/ui';

export type LatamRecordViewPassedItem = {
  title: string;
};

export const LatamRecordViewPassed = ({
  title,
}: LatamRecordViewPassedItem) => {
  const formatMessage = useFormatMessage();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.institution'),
        value: formatMessage(`BackgroundCheck.verification.jsonView.${title}`),
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.noRecordsFound'),
      }}
    />
  );
};
