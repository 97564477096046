import React, { useCallback, useEffect, useRef, useState, ReactNode } from 'react';
import classNames from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import { IList } from '../../models/CustomSelect.model';
import { useStyles, StyledButtonBase } from './CustomSelect.styles';
import { SearchFilter } from '../SearchFilter/SearchFilter';

export function CustomSelect({ list, isMultiple = true, placeholder, onApply, defaultValue, children, isAllSelectable = false, disabled }:
  {
    children?: ReactNode;
    placeholder: string;
    isMultiple?: boolean;
    isAllSelectable?: boolean;
    list: IList[];
    defaultValue: (string | number)[];
    onApply: (values: any) => void;
    disabled?: boolean;
  }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [filteredList, setFilteredList] = useState<IList[]>(list);
  const [names, setNames] = useState<(string | number)[]>(defaultValue);
  const [open, setOpen] = useState<boolean>(false);
  const customSelectRef = useRef(null);

  const toggleOpen = () => {
    if (!disabled) {
      setOpen((prev) => !prev);
    }
  };

  const onClose = useCallback(() => {
    setOpen(false);
    setNames(defaultValue);
    setFilteredList(list);
  }, [defaultValue, list]);

  useEffect(() => {
    setNames(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const handleOuterClick = (event: Event) => {
      const path = event.composedPath();
      if (!path?.includes(customSelectRef.current)) {
        onClose();
      }
    };
    if (open) {
      document.addEventListener('click', handleOuterClick);
    } else {
      document.removeEventListener('click', handleOuterClick);
    }
    return () => document.removeEventListener('click', handleOuterClick);
  }, [open, onClose]);

  const handleSelect = useCallback((value: string | number) => async () => {
    const ind = names.indexOf(value);
    const newNames = [...names];
    if (ind === -1) {
      newNames.push(value);
    } else {
      newNames.splice(ind, 1);
    }
    setNames(isMultiple ? newNames : [value]);
  }, [isMultiple, names]);

  const handleApply = useCallback(() => {
    setOpen(false);
    onApply(names);
  }, [names, onApply]);

  const handleSelectAll = useCallback(() => {
    const newNames = [...names];
    filteredList.forEach(({ value }) => {
      if (newNames.indexOf(value) === -1) {
        newNames.push(value);
      }
    });
    setNames(newNames);
  }, [filteredList, names]);

  const handleDeselectAll = useCallback(() => {
    const newNames = [...names];
    filteredList.forEach(({ value }) => {
      const ind = newNames.indexOf(value);
      if (ind !== -1) {
        newNames.splice(ind, 1);
      }
    });
    setNames(newNames);
  }, [filteredList, names]);

  const handleFilterList = useCallback((filter) => {
    let newFilteredList = [...list];
    if (filter) {
      newFilteredList = filteredList.filter((item) => item.label.indexOf(filter) !== -1);
    }
    setFilteredList(newFilteredList);
  }, [filteredList, list]);

  return (
    <Card ref={customSelectRef} className={classNames(classes.wrapper, { isOpen: open, isDisabled: disabled })}>
      <Box px={1.6} py={1.5} onClick={toggleOpen}>
        <Typography variant="body1" className={classes.placeholder}>
          {(names.length > 0 && !open)
            ? names.map((value) => list.find((item) => item.value === value)?.label).join(', ')
            : placeholder}
        </Typography>
        <FiChevronDown className={`${classes.icon} ${open ? classes.iconOpen : ''}`} />
      </Box>
      {open && (
        <Card className={classes.openItems}>
          <Box px={1.5}>
            {children}
            {isAllSelectable && (
            <Grid container justify="flex-end">
              <Box mr={1} my={1.2}>
                <StyledButtonBase onClick={handleSelectAll}>
                  {formatMessage('Button.selectAll')}
                </StyledButtonBase>
              </Box>
              <Box my={1.2}>
                <StyledButtonBase onClick={handleDeselectAll}>
                  {formatMessage('Button.deselectAll')}
                </StyledButtonBase>
              </Box>
            </Grid>
            )}
            <Grid container direction="column">
              {isAllSelectable && <SearchFilter onSetFilter={handleFilterList} />}
              <Box className={classNames(classes.content, { boxBordered: isAllSelectable })}>
                {filteredList.map((item) => (
                  <Grid
                    container
                    item
                    key={item.value}
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    onClick={handleSelect(item.value)}
                  >
                    <Typography className={classes.text}>
                      {item.label}
                    </Typography>
                    {isMultiple ? (
                      <Checkbox
                        className={classes.itemIcons}
                        checked={names.indexOf(item.value) > -1}
                        checkedIcon={<CheckboxOn />}
                        icon={<CheckboxOff />}
                        color="primary"
                        disabled={disabled}
                      />
                    ) : (
                      <Radio
                        checked={names.indexOf(item.value) > -1}
                        color="primary"
                        className={classes.itemIcons}
                        disabled={disabled}
                      />
                    ) }
                  </Grid>
                ))}
              </Box>
              <Box py={1.5} className={classes.btnGroup}>
                <Button color="primary" onClick={onClose}>
                  {formatMessage('Button.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                  disabled={disabled}
                >
                  {formatMessage('Button.apply')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Card>
      )}
    </Card>
  );
}
