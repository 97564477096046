import Box from '@material-ui/core/Box';
import React, { ReactNode } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import { useStyles } from './FlowError.styles';

export function FlowError({ text }: {
  text: ReactNode;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.error}>
      <Box className={classes.errorIcon}>
        <RiAlertFill size={19} />
      </Box>
      <Box className={classes.errorContent}>
        {text}
      </Box>
    </Box>
  );
}
