import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  chip: {
    border: `1px solid ${theme.palette.common.black75}`,
    marginLeft: 4,
    '& .MuiChip-label': {
      color: theme.palette.common.black75,
    },
  },
}));
