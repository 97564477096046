import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormatMessage } from 'apps/intl';
import { TextEditor } from 'apps/ui';
import { useDispatch, useSelector } from 'react-redux';
import { IStaticTextParams } from 'models/CustomField.model';
import { updateCustomFieldEditedStaticTextParams } from '../../../../state/CustomField.actions';
import { selectCustomFieldEditedStaticTextParams } from '../../../../state/CustomField.selectors';
import { TextFieldInput } from '../../CustomFieldModalConfigureAtomic.styles';

export const CustomFieldStaticTextSettings = ({ isAddHeading, setIsAddHeading }: {
  isAddHeading: boolean;
  setIsAddHeading: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const staticTextParams = useSelector<any, IStaticTextParams>(selectCustomFieldEditedStaticTextParams);

  const handleChangeIsAddHeading = () => {
    setIsAddHeading((prev) => !prev);
    dispatch(updateCustomFieldEditedStaticTextParams({ ...staticTextParams, header: '', isCollapsed: false }));
  };

  const handleUpdateStaticTextParams = ({ target: { value, name } }) => {
    dispatch(updateCustomFieldEditedStaticTextParams({ ...staticTextParams, [name]: value }));
  };

  const handleChangeIsCollapsed = () => {
    dispatch(updateCustomFieldEditedStaticTextParams({ ...staticTextParams, isCollapsed: !staticTextParams?.isCollapsed }));
  };

  const onHTMLChange = (text: string) => {
    dispatch(updateCustomFieldEditedStaticTextParams({ ...staticTextParams, text }));
  };

  return (
    <>
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="body1">
            {formatMessage('CustomField.settings.addHeading')}
          </Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={isAddHeading}
            color="primary"
            size="small"
            onChange={handleChangeIsAddHeading}
          />
        </Grid>
        {isAddHeading && (
          <>
            <Grid item>
              <Typography variant="body1">
                {formatMessage('CustomField.settings.collapsed')}
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={staticTextParams?.isCollapsed}
                color="primary"
                size="small"
                name="isCollapsed"
                onChange={handleChangeIsCollapsed}
              />
            </Grid>
          </>
        )}
      </Grid>
      {isAddHeading && (
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="subtitle2">
              {formatMessage('CustomField.settings.heading')}
              {staticTextParams?.isCollapsed && '*'}
            </Typography>
          </Grid>
          <Grid item>
            <TextFieldInput
              fullWidth
              value={staticTextParams?.header}
              type="text"
              name="header"
              onChange={handleUpdateStaticTextParams}
              placeholder={formatMessage('CustomField.settings.heading.placeholder')}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <TextEditor onHTMLChange={onHTMLChange} html={staticTextParams?.text} />
        </Grid>
      </Grid>
    </>
  );
};
