import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filterOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: 6,
    '& svg': {
      display: 'none',
    },
    '&:hover': {
      '& svg': {
        display: 'block',
      },
    },
  },
  selected: {
    borderColor: theme.palette.common.black90,
    '& svg': {
      display: 'block',
    },
  },
}));
