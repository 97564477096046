import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { INotificationsStore, SliceNameTypes } from './Notifications.store';

const initialState: INotificationsStore = {
  [SliceNameTypes.NotificationsSummary]: LoadableAdapter.createState({
    beamerCount: 0,
    statusPageCount: 0,
    forceBanner: [],
  }),
  [SliceNameTypes.Notifications]: LoadableAdapter.createState({
    beamer: [],
    statusPage: [],
  }),
};

export const notificationsReducer = createReducer(initialState, {
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.NotificationsSummary),
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.Notifications),
});
