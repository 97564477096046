import { Accordion, AccordionDetails, AccordionSummary, makeStyles, withStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  labelSuccessIcon: {
    flexShrink: 0,
  },
  labelErrorIcon: {
    flexShrink: 0,
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    fontSize: 14,
    color: theme.palette.text.main,
    fontWeight: 'bold',
  },
}));

export const ExpansionPanel = withStyles({
  root: {
    width: '100%',
    marginBottom: 10,
    border: 'none',
    borderRadius: 5,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0 0 10px',
    },
    '& svg': {
      fontSize: 17,
    },
  },
  rounded: {
    '&:first-child': {
      borderRadius: 5,
    },
    '&:last-child': {
      borderRadius: 5,
    },
  },
  expanded: {},
  disabled: {},
})(Accordion);

export const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: '4px 10px',
    borderRadius: 5,
    minHeight: 30,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.foreground.main,
    },
    '&$expanded': {
      minHeight: 30,
      margin: 0,
      backgroundColor: theme.palette.foreground.main,
    },
    '&$focused': {
      backgroundColor: '#FFF',
    },
    '&.error': {
      '&:hover, &:focus': {
        backgroundColor: theme.palette.common.redopacity,
        ...(theme.isDarkMode && ({
          backgroundColor: 'rgba(255, 231, 232, .15)',
        })),
      },
      '& .MuiBox-root, & svg': {
        color: theme.palette.common.red,
      },
      '&$expanded': {
        backgroundColor: theme.palette.common.redopacity,
        ...(theme.isDarkMode && ({
          backgroundColor: 'rgba(255, 231, 232, .15)',
        })),
      },
    },
  },
  content: {
    margin: 0,
    borderRadius: 5,
    '&$expanded': {
      margin: 0,
    },
  },
  focused: {},
  disabled: {
    opacity: '1 !important',
  },
  expanded: {
    margin: 0,
  },
  expandIcon: {
    marginRight: -4,
    padding: 0,
    color: theme.palette.text.main,
  },
}))(AccordionSummary);

export const ExpansionPanelDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: 0,
  },
}))(AccordionDetails);
