import React from 'react';
import { ProductByVerificationPattern, VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { FormatMessage, useFormatMessage } from 'apps/intl';
import { PatternErrorType } from 'models/VerificationPatternsConfigs.model';
import { GovernmentChecksPatterns } from '../../../../models/GovCheckMerit.model';

function requiredPatternsErrorMap({ patternName, patternValues, formatMessage }: {
  patternName: string;
  patternValues: [];
  formatMessage: FormatMessage;
}): string {
  const meritForEnable = formatMessage(`${ProductByVerificationPattern[patternName]}.card.title`);

  if (patternName === VerificationPatternTypes.Facematch) {
    return formatMessage(`${meritForEnable} ${formatMessage('tooltip.issue.product.option', {
      messageValues: { messageContinued: formatMessage('GovernmentCheck.card.title') },
    })}`);
  }

  if (GovernmentChecksPatterns.includes(patternName as VerificationPatternTypes)) {
    return formatMessage(`GovCheck.check.${patternName}`);
  }

  return formatMessage(`${meritForEnable} ${formatMessage('tooltip.issue.product.option', {
    messageValues: { messageContinued: patternValues.map((value) => formatMessage(`Product.userInput.${value}`)).join(` ${formatMessage('or')} `) },
  })}`);
}

// TODO: @anatoliy.turkin - very hard code, but it covers all the right cases. fix me later
export function RequiredPatternsError({ errorType }: {
  errorType: PatternErrorType;
}) {
  const formatMessage = useFormatMessage();

  const patternsValues = errorType.requiredPatternsValues.flat()
    .reduce((groups, pattern) => {
      const [patternName, patternValue] = Object.entries(pattern)[0];
      return { ...groups, [patternName]: [...(groups[patternName] || []), patternValue] };
    }, {});

  const messageContinued = Object.entries(patternsValues)
    .map(([patternName, patternValues]) => requiredPatternsErrorMap({ patternName, patternValues: (patternValues as []), formatMessage }))
    .join(` ${formatMessage('or')} `);

  return (
    <>
      {formatMessage('tooltip.issue.product', {
        messageValues: {
          messageContinued,
        },
      })}
    </>
  );
}
