import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormatMessage } from 'apps/intl';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { InfoTooltip } from 'apps/ui';
import { WarningBadge } from 'apps/ui/components/WarningBadge/WarningBadge';
import { CheckBarIcon } from 'apps/ui/components/CheckBarIcon/CheckBarIcon';
import { ReactComponent as IconData } from 'assets/icon-identity-data.svg';
import { ReactComponent as IconDone } from 'assets/icon-identity-done.svg';
import { ReactComponent as IconError } from 'assets/icon-identity-error.svg';
import { ReactComponent as IconLoad } from 'assets/icon-load.svg';
import { StepIds, StepStatus } from 'models/Step.model';
import React, { useCallback, useState } from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, useStyles } from './CheckBarExpandable.styles';

const IconStatuses = {
  [StepStatus.Success]: <CheckBarIcon key="check-bar-icon" icon={<IconDone />} />,
  [StepStatus.Failure]: <CheckBarIcon key="check-bar-icon" icon={<IconError />} />,
  [StepStatus.Incomplete]: <CheckBarIcon key="check-bar-icon" icon={<IconData />} />,
  [StepStatus.Checking]: <CheckBarIcon key="check-bar-icon" icon={<IconLoad />} />,
};

type Step = {
  id?: string | StepIds;
}

export function CheckBarExpandable({
  step = {},
  children,
  title,
  name,
  isError,
  isOpenByDefault = false,
  isNoBadge = false,
  tooltipMessage,
  isWarning = false,
}: {
  step?: {
    checkStatus?: StepStatus;
  };
  isError?: boolean;
  children?: React.ReactElement;
  title?: string;
  name?: string;
  isOpenByDefault?: boolean;
  isNoBadge?: boolean;
  tooltipMessage?: string;
  isWarning?: boolean;
} & ({
  step: Required<Step>;
  title?: string;
} | {
  step?: Step;
  title: string;
} | {
  step?: Step;
  name: string;
})) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const {
    id,
    checkStatus,
  } = step;
  const [expanded, setExpanded] = useState(isOpenByDefault);
  const isChecking = checkStatus === StepStatus.Checking;
  const isManualError = typeof isError === 'boolean';
  const handleChange = useCallback((_, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);
  const header = name
    || (title && formatMessage(title))
    || formatMessage(`SecurityCheckStep.${id}.title`, { defaultMessage: formatMessage(`SecurityCheckStep.${checkStatus}`) });

  if (isChecking) {
    return (
      <Box display="flex" alignItems="center" width="100%" mb={1} py={0.4} px={1}>
        <Box width={17}>
          <CheckBarIcon icon={<IconLoad />} />
        </Box>
        <Box className={classes.labelContainer}>
          <Box className={classes.label}>
            <Box fontWeight={600}>
              {header}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange}
    >
      <ExpansionPanelSummary
        className={classNames({
          error: isManualError ? isError : (step.checkStatus === StepStatus.Failure && !isWarning),
        })}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        {!isManualError && isWarning && IconStatuses[StepStatus.Success]}
        {!isManualError && !isWarning && IconStatuses[step.checkStatus]}
        {isManualError && IconStatuses[isError ? StepStatus.Failure : StepStatus.Success]}
        <Box className={classes.labelContainer}>
          <Box className={classes.label}>
            <Box fontWeight={600}>
              {header}
            </Box>
            {tooltipMessage && (
              <InfoTooltip title={tooltipMessage} popperClassname={classes.tooltip}>
                <AiOutlineInfoCircle className={classes.infoIcon} size="1.2em" />
              </InfoTooltip>
            )}
          </Box>
        </Box>
        {!isNoBadge && ((isManualError ? isError : step.checkStatus === StepStatus.Failure) || isWarning) && <Box className={classes.warning}><WarningBadge /></Box> && <Box className={classes.warning}><WarningBadge /></Box>}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
