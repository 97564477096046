import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { FlattenVerificationPatternConfigType, IFlattenVerificationPatternConfig, isHidePattern } from 'models/VerificationPatternsConfigs.model';
import { selectMerchantTags } from 'state/merchant';
import { GovCheckModalBanner } from '../GovCheckModalBanner/GovCheckModalBanner';
import { GovCheckModalDataBaseInfo } from '../GovCheckModalDataBaseInfo/GovCheckModalDataBaseInfo';
import { GovCheckModalDataBaseOption } from '../GovCheckModalDataBaseOption/GovCheckModalDataBaseOption';

export function GovCheckModalDataBase({ patternConfig, handleToggleGovCheck, handleToggleOption }: {
  patternConfig: FlattenVerificationPatternConfigType;
  handleToggleGovCheck: (checked: boolean, govCheck: IFlattenVerificationPatternConfig) => void;
  handleToggleOption: (govCheck: IFlattenVerificationPatternConfig, checked: boolean, option: IFlattenVerificationPatternConfig) => void;
}) {
  const govCheck = Object.values(patternConfig)[0];

  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);

  const isHide: boolean = isHidePattern(govCheck, merchantTags);

  const [isShowBanner, setIsShowBanner] = useState<boolean>(true);

  const onClose = () => {
    setIsShowBanner(false);
  };

  if (isHide) return null;

  return (
    <>
      <GovCheckModalDataBaseInfo handleToggle={handleToggleGovCheck} govCheck={govCheck} />
      {isShowBanner && govCheck.isActive && govCheck?.options?.length && <GovCheckModalBanner govCheck={govCheck} onClose={onClose} />}
      {govCheck.isActive && govCheck?.options?.map((option) => <GovCheckModalDataBaseOption key={option.patternValue} govCheck={govCheck} handleToggleOption={handleToggleOption} option={option} />)}
    </>
  );
}
