import React from 'react';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import { LinkButton, RecordView } from 'apps/ui';
import { TrialRecord } from 'models/BackgroundCheck.model';
import { normalizeLink } from 'lib/url';
import { BrazilTrialRecord } from './components/BrazilTrialRecord/BrazilTrialRecord';
import { useStyles } from './BrazilRecordViewFailed.styles';

export type BrazilRecordViewFailedItem = {
  title: string;
  link: string;
  trials?: TrialRecord[];
}

export const BrazilRecordViewFailed = ({
  title,
  link,
  trials,
}: BrazilRecordViewFailedItem) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <RecordView
      itemLeft={{
        title: formatMessage('BackgroundCheck.verification.jsonView.institution'),
        value: title,
        gridClass: classes.gridCell,
      }}
      itemRight={{
        title: formatMessage('BackgroundCheck.verification.jsonView.status'),
        value: formatMessage('BackgroundCheck.verification.jsonView.failed'),
        valueClass: classes.failedLabel,
        gridClass: classnames(classes.gridCell, classes.statusCell),
        extraContent: link && <Box px="24px" py="14px"><LinkButton link={normalizeLink(link)} /></Box>,
      }}
      details={(
        trials.map((trialItem, idx) => (
          <BrazilTrialRecord
            key={idx}
            record={trialItem}
          />
        ))
      )}
    />
  );
};
