import React, { useCallback, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as IconDownload } from 'assets/icon-download.svg';
import { ReactComponent as Spinner } from 'assets/icon-load.svg';
import { ReactComponent as Placeholder } from 'assets/placeholder.svg';
import { downloadBlob } from 'lib/file';
import { getMedia, ImageContainer, MediaStatusTypes, useLoadPrivateMedia } from 'apps/media';
import { useStyles } from './MediaSelfie.styles';

export function MediaSelfie({ title, subtitle, image, downloadableFileName = 'selfie', isNotLoaded = true }: {
  title: string;
  subtitle?: string;
  image?: string;
  downloadableFileName?: string;
  isNotLoaded?: boolean;
}) {
  const classes = useStyles();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [objectUrl, isLoading] = useLoadPrivateMedia(isNotLoaded ? image : null);

  const mediaSrc = useMemo(() => (isNotLoaded ? objectUrl : image), [isNotLoaded, objectUrl, image]);
  const resultMediaSrc = useMemo<string | MediaStatusTypes>(() => (isLoading ? MediaStatusTypes.MediaIsLoading : mediaSrc), [isLoading, mediaSrc]);
  const handleDownload = useCallback(async () => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    try {
      const response = await getMedia(image);
      downloadBlob(response.data, `${downloadableFileName}.jpeg`);
      setIsDownloading(false);
    } catch (error) {
      console.error(error);
      setIsDownloading(false);
    }
  }, [image, downloadableFileName, isDownloading]);

  if (!image) {
    return <Placeholder />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.mediaBox}>
        <ImageContainer className={classes.media} src={resultMediaSrc} alt={title} />
        {isDownloading ? (
          <Spinner onClick={handleDownload} className={classes.load} />
        ) : (
          <IconDownload onClick={handleDownload} className={classes.load} />
        )}
      </Box>
      {(title || subtitle) && (
        <Box className={classes.titleBox}>
          <Typography variant="body1" className={classes.title}>{title}</Typography>
          <Typography variant="body2" className={classes.subtitle}>{subtitle}</Typography>
        </Box>
      )}
    </Box>
  );
}
