import { titleize } from 'inflection';
import { formatDate } from 'lib/date';
import { startCase } from 'lodash';
import { isNil } from './isNil';

export const FieldTitlizedPatterns = [
  'name',
  'address',
  'gender',
  'nationality',
  'taxstatus',
  'verified',
  'registrationstatus',
  'idtype',
  'civilstatus',
  'city',
  'position',
  'status',
];

export const FieldTitlizedDashedPatterns = [
  'documenttype',
];

export const FieldDatePatterns = [
  'date',
  'emissiondate',
  'readat',
  'signedat',
  'registrationdate',
  'expirationdate',
  'dateofbirth',
  'expirydate',
  'issueddate',
  'surveydate',
  'lastupdate',
  'bday',
  'dateofregistration',
  'blockeddate',
];

export const FieldBooleanPatterns = [
  'deceased',
  'fiscalstatusvalid',
  'hasdebts',
  'ispep',
  'isphoneverified',
  'issourceverified',
  'registeredtaxpayer',
  'dobmatch',
  'fullnamematch',
  'facematch',
  'dateofbirthmatch',
  'namematch',
  'valid',
  'ispersondeceased',
  'hasfines',
  'activedriver',
  'activelicense',
  'active',
  'affectsvat',
  'blocked',
];

export const FieldMatchObjectPatterns = [
  'governmentfacematchscorepercentage',
  'score',
];

export function titleCase(string = '') {
  return typeof string === 'string' ? startCase(string.toLowerCase()) : string;
}

export function includesPattern(name = '', keys) {
  return keys.some((key) => name.toLowerCase() === key);
}

export function formatValue(label, value) {
  if (includesPattern(label, FieldTitlizedPatterns)) {
    return titleize(value || '');
  }

  if (includesPattern(label, FieldTitlizedDashedPatterns)) {
    return titleize((value || '').replace('-', ' '));
  }

  if (includesPattern(label, FieldDatePatterns)) {
    return formatDate(value);
  }

  return !isNil(value) ? `${value}` : '';
}

export function trimMiddle(string = '', begin = 30, end = 5, delimiter = '…') {
  if (string.length < begin + end + delimiter.length) return string;
  const start = string.substring(0, begin);
  const finish = string.substring(string.length - end);
  return `${start}${delimiter}${finish}`;
}

export function stringHash(s) {
  return s.split('').reduce((prev, subStr) => {
    // eslint-disable-next-line no-bitwise
    const hash = ((prev << 5) - prev) + subStr.charCodeAt(0);
    // eslint-disable-next-line no-bitwise
    return hash & hash;
  }, 0);
}

export function camelCase(str) {
  return str.replace(/^\w|[A-Z]|\b\w/g, (ltr, idx) => (idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()))
    .replace(/\s+/g, '');
}

export function PascalCase(str) {
  return str.replace(/_+/g, ' ').split(' ')
    .map((item) => item.replace(/^\w|[A-Z]|\b\w/g, (ltr, idx) => (idx === 0 ? ltr.toUpperCase() : ltr.toLowerCase())))
    .join('');
}
