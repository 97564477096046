import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Modal } from 'apps/overlay';
import { useFormatMessage } from 'apps/intl';
import { ProductCard } from 'apps/Product/components/ProductCard/ProductCard';
import { ProductTypes } from 'models/Product.model';
import { useStyles } from './ProductDependencyMultipleModal.styles';

interface IProductDependencyMultipleModalProps {
  onClose: () => void;
  onConfirm: () => void;
  requiredProductTypes: ProductTypes | Array<ProductTypes>;
}

export function ProductDependencyMultipleModal({ requiredProductTypes, onConfirm, onClose }: IProductDependencyMultipleModalProps) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Modal onClose={onClose} className={classes.modal}>
      <Box p={2} mb={3} color="common.black90" fontWeight="bold">
        <Box mb={2} color="common.black90" fontWeight="bold">
          <Typography className={classes.title}>{formatMessage('Facematch.addModal.title')}</Typography>
        </Box>
        <Box mb={1}>
          <Typography className={classes.subtitle}>{formatMessage('Facematch.addModal.subtitle')}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={3}>
          <Box width="48%">
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Typography className={classes.columnTitle}>{formatMessage('Facematch.addModal.addColumnTitle')}</Typography>
              <Box mt={1} width="100%">
                <ProductCard id={ProductTypes.Facematch} isExpandable={false} defaultExpanded />
              </Box>
            </Box>
          </Box>
          <Box width="48%">
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Typography className={classes.columnTitle}>{formatMessage('Facematch.addModal.requiredColumnTitle')}</Typography>
              <Box mt={1} width="100%">
                {Array.isArray(requiredProductTypes) && requiredProductTypes.map((requiredProduct) => <ProductCard id={requiredProduct} isExpandable={false} defaultExpanded />)}
                {!Array.isArray(requiredProductTypes) && <ProductCard id={requiredProductTypes} isExpandable={false} defaultExpanded />}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Box width="50%" display="flex" justifyContent="space-between">
            <Button onClick={onClose} variant="text" className={classes.cancelButton}>
              <Box ml={1}>
                {formatMessage('cancel')}
              </Box>
            </Button>
            <Button onClick={onConfirm} className={classes.confirmButton}>
              {formatMessage('Facematch.addModal.confirm')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
