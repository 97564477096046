import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { PatternErrorType } from 'models/VerificationPatternsConfigs.model';

export function ExclusivePatternsError({ errorType }: {
  errorType: PatternErrorType;
}) {
  const formatMessage = useFormatMessage();

  return (
    <>
      {formatMessage('tooltip.issue.conflict', {
        messageValues: {
          patterns: errorType.exclusivePatternsValues
            .map((patterns) => patterns.map((pattern) => {
              const [patternName, patternValue] = Object.entries(pattern)[0];
              return formatMessage(`Pattern.${patternName}.${patternValue}`, {
                defaultMessage: formatMessage(`Pattern.${patternName}`),
              });
            }).join(` ${formatMessage('And')} `)).join(` ${formatMessage('or')} `),
        },
      })}
    </>
  );
}
