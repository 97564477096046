import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription, TimeoutSettings } from 'apps/ui';
import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch, TextField } from '@material-ui/core';
import { IRetryBlockerConfig, VerificationRetryBlockerModeTypes } from 'apps/flowBuilder';
import { useStyles } from './VerificationRetryBlockOptions.styles';
import { defaultCustomBlockTime, isValidPrimaryKeyForBlockRetries } from '../../models/FlowBuilder.model';

const VerificationRetryBlockOptions = ({ retryVerificationBlockerConfig, onBlockVerificationRetryChange, updateBlockRetriesPrimaryKey }: {
  retryVerificationBlockerConfig?: IRetryBlockerConfig;
  onBlockVerificationRetryChange?: (mode: VerificationRetryBlockerModeTypes, timeOut?: string) => void;
  updateBlockRetriesPrimaryKey?: (value: string | undefined) => void;
}) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [isCustomPrimaryKey, setIsCustomPrimaryKey] = useState<boolean>(true);
  useEffect(() => {
    if (retryVerificationBlockerConfig?.primaryKey === undefined) {
      setIsCustomPrimaryKey(false);
    }
  }, [retryVerificationBlockerConfig?.primaryKey, setIsCustomPrimaryKey]);

  const handleToggleOfBlockVerificationRetries = () => {
    if (retryVerificationBlockerConfig && retryVerificationBlockerConfig?.mode !== VerificationRetryBlockerModeTypes.Off) {
      onBlockVerificationRetryChange(VerificationRetryBlockerModeTypes.Off);
    } else {
      onBlockVerificationRetryChange(VerificationRetryBlockerModeTypes.Block);
    }
  };
  const handleBlocklRetriesPrimaryKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.value;
    if (isValidPrimaryKeyForBlockRetries(updatedValue)) {
      updateBlockRetriesPrimaryKey(updatedValue);
    }
  };
  const handleToggleOfBlockVerificationOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedMode = (event.target as HTMLInputElement).value as VerificationRetryBlockerModeTypes;
    onBlockVerificationRetryChange(updatedMode, updatedMode === VerificationRetryBlockerModeTypes.BlockCustom ? defaultCustomBlockTime : undefined);
  };
  const onBlockTimeOutUpdate = (timeout: string) => {
    onBlockVerificationRetryChange(VerificationRetryBlockerModeTypes.BlockCustom, timeout);
  };
  const handlePrimaryKeyToogle = () => {
    const updatedIsCustomPrimaryKey = !isCustomPrimaryKey;
    setIsCustomPrimaryKey(updatedIsCustomPrimaryKey);
    if (!updatedIsCustomPrimaryKey) {
      updateBlockRetriesPrimaryKey(undefined);
    }
  };
  return (
    <>
      <Box mt={2}>
        <ExtendedDescription
          title={formatMessage('FlowBuilder.settings.blockVerificationRetries.title')}
          text={formatMessage('FlowBuilder.settings.blockVerificationRetries.description')}
          className={classes.extendedDescription}
          postfix={<Switch color="primary" checked={retryVerificationBlockerConfig ? retryVerificationBlockerConfig.mode !== VerificationRetryBlockerModeTypes.Off : false} onChange={handleToggleOfBlockVerificationRetries} />}
        />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          {retryVerificationBlockerConfig && retryVerificationBlockerConfig?.mode !== VerificationRetryBlockerModeTypes.Off && (
            <>
              <Box mt={1} pl={2}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="block-retries"
                    name="block-retries"
                    value={retryVerificationBlockerConfig.mode}
                    onChange={handleToggleOfBlockVerificationOptions}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          className={classes.radioAlign}
                          label={(
                            <Box pl={1}>
                              <Box color="common.black90" fontWeight="bold">
                                Default
                              </Box>
                              <Box color="common.black75" mt={0.5}>
                                {formatMessage('FlowBuilder.settings.blockVerificationRetries.blockCompletely.description')}
                              </Box>

                            </Box>
                          )}
                          value={VerificationRetryBlockerModeTypes.Block}
                        />

                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          className={classes.radioAlign}
                          label={(
                            <Box pl={1}>
                              <Box color="common.black90" fontWeight="bold">
                                {formatMessage('FlowBuilder.settings.blockVerificationRetries.blockPartially.title')}
                              </Box>
                              <Box color="common.black75" mt={1}>
                                {formatMessage('FlowBuilder.settings.blockVerificationRetries.blockPartially.description')}
                              </Box>
                            </Box>
                          )}
                          value={VerificationRetryBlockerModeTypes.BlockCustom}
                        />

                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Box>

            </>
          )}
          {retryVerificationBlockerConfig?.mode === VerificationRetryBlockerModeTypes.BlockCustom && (
            <Box mt={1} pl={6}>
              <Box color="common.black75" mt={0.5} mb={0.5}>
                {formatMessage('FlowBuilder.settings.blockVerificationRetries.blockPartially.timeDuration')}
              </Box>
              <Grid item xs={6} container>
                <TimeoutSettings
                  onChange={onBlockTimeOutUpdate}
                  postponedTimeout={retryVerificationBlockerConfig.timeout}
                  hideMinutes
                />
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          {retryVerificationBlockerConfig && retryVerificationBlockerConfig?.mode !== VerificationRetryBlockerModeTypes.Off && (
            <Box mt={2} pl={1}>
              <ExtendedDescription
                title={formatMessage('FlowBuilder.settings.blockVerificationRetriesCustomPrimaryKey.title')}
                text={formatMessage('FlowBuilder.settings.blockVerificationRetriesCustomPrimaryKey.description')}
                className={classes.extendedDescription}
                postfix={<Switch color="primary" checked={isCustomPrimaryKey} onChange={handlePrimaryKeyToogle} />}
              />
              {isCustomPrimaryKey && (
                <Box mt={1} pl={2}>
                  <TextField
                    variant="outlined"
                    value={retryVerificationBlockerConfig.primaryKey || ''}
                    onChange={handleBlocklRetriesPrimaryKeyChange}
                    type="string"
                    placeholder="Custom Primary Key"
                  />
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default VerificationRetryBlockOptions;
