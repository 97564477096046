/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import React, { useState, ImgHTMLAttributes } from 'react';
import { ImageSkeleton } from 'apps/ui';
import { useStyles } from './ImageContainer.styles';
import { MediaStatusTypes } from '../../models/Media.model';
import { useWaitMediaDownloading } from '../../hooks/waitMediaDownloading.hook';

/* NOTE: modifies argument inplace */
function normalizeOffset(offset: [number, number]): [number, number] {
  /* eslint-disable no-param-reassign */
  if (offset[0] > 25) {
    offset[0] = 25;
  }

  if (offset[0] < -25) {
    offset[0] = -25;
  }

  if (offset[1] > 25) {
    offset[1] = 25;
  }

  if (offset[1] < -25) {
    offset[1] = -25;
  }
  /* eslint-enable no-param-reassign */

  return offset;
}

export function ImageContainer({ src, alt, rotation, isZoomable, className, style, ...props }: {
  src: MediaStatusTypes | string | Promise<string>;
  rotation?: number;
  alt: any;
  className?: any;
  style?: any;
  isZoomable?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>) {
  const styles = useStyles();
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState<[number, number]>([0, 0]);
  const [imageUrl, isMediaLoaded] = useWaitMediaDownloading(src !== MediaStatusTypes.MediaIsLoading ? src : null);

  if (src === MediaStatusTypes.MediaIsLoading || !isMediaLoaded) {
    return (<ImageSkeleton className={className} />);
  }

  if (isZoomable) {
    return (
      <div
        {...props}
        className={classNames(styles.container, className)}
        style={style}
      >
        <img
          src={imageUrl}
          alt={alt}
          style={{
            transform: `scale(${isZoomed ? 2 : 1}) translate(${zoomPosition[0]}%, ${zoomPosition[1]}%) rotate(${rotation ?? 0}deg)`,
            cursor: isZoomed ? 'move' : 'zoom-in',
          }}
          onMouseDown={({ target, clientX, clientY }) => {
            if (!isZoomed) {
              const rect = (target as HTMLImageElement).getBoundingClientRect();
              const clickPosition = [clientX - rect.left, clientY - rect.top];
              const offset = normalizeOffset([
                ((rect.width / 2 - clickPosition[0]) / rect.height) * 100,
                ((rect.height / 2 - clickPosition[1]) / rect.height) * 100,
              ]);
              setZoomPosition(offset);
              setIsZoomed(true);
            }
          }}
          onMouseUp={() => {
            setZoomPosition([0, 0]);
            setIsZoomed(false);
          }}
          onMouseMove={({ movementX, movementY }) => {
            if (isZoomed) {
              setZoomPosition(normalizeOffset([
                zoomPosition[0] - movementX / 2,
                zoomPosition[1] - movementY / 2,
              ]));
            }
          }}
        />
      </div>
    );
  }

  return (<img src={imageUrl} alt={alt} className={className} style={style} {...props} />);
}
