import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import { MerchantTagsTypes } from 'models/Merchant.model';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantTags } from 'state/merchant';
import { SalesToolTip } from 'apps/ui';
import { IFlattenVerificationPatternConfig, isCanUsePattern, VerificationConfigurationErrorTypes } from 'models/VerificationPatternsConfigs.model';
import { ReactComponent as CrownIcon } from '../../assets/CrownSimple.svg';
import { ReactComponent as InfoWithTooltipIcon } from '../../assets/InfoWithTooltip.svg';
import { PatternIssueTooltip } from '../PatternIssueTooltip/PatternIssueTooltip';
import { useStyles } from './GovCheckModalMainInfo.styles';

export function GovCheckModalMainInfo({ govCheck, onClick }: {
  govCheck: IFlattenVerificationPatternConfig;
  onClick: () => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);

  const isNeedRequest: boolean = !isCanUsePattern(govCheck, merchantTags);

  const isHaveError = govCheck.isActive && govCheck.error.type !== VerificationConfigurationErrorTypes.None;

  return (
    <Box className={classes.root}>
      <Box className={classes.info}>
        <Box className={classes.dataBaseName}>
          {isHaveError && (
            <PatternIssueTooltip
              govCheck={govCheck}
              errorType={govCheck.error}
            >
              <InfoWithTooltipIcon />
            </PatternIssueTooltip>
          )}
          <span>
            {formatMessage(`GovCheck.check.${govCheck.patternName}.${govCheck.patternValue}`, {
              defaultMessage: formatMessage(`GovCheck.check.${govCheck.patternName}`),
            })}
          </span>
          {govCheck.institution.map((institution) => <Box key={institution} className={classes.tag}>{formatMessage(`GovCheckMerit.settings.institution.${institution}`)}</Box>)}
        </Box>
        <Box>
          <span>
            {formatMessage(`GovCheckMerit.check.${govCheck.patternName}.${govCheck.patternValue}.description`, {
              defaultMessage: formatMessage(`GovCheckMerit.check.${govCheck.patternName}.description`),
            })}
          </span>
        </Box>
      </Box>
      {isNeedRequest && (
        <SalesToolTip>
          <Button
            startIcon={<CrownIcon />}
            className={classnames(classes.btn, classes.requestBtn)}
          >
            {formatMessage('GovCheckMerit.settings.button.request')}
          </Button>
        </SalesToolTip>
      )}
      {!isNeedRequest && (
        <Button
          startIcon={govCheck.isActive ? <DeleteIcon /> : <AddIcon />}
          className={classnames(classes.btn, { [classes.deleteBtn]: govCheck.isActive, [classes.addBtn]: !govCheck.isActive })}
          onClick={onClick}
        >
          {govCheck.isActive ? formatMessage('GovCheckMerit.settings.button.delete') : formatMessage('GovCheckMerit.settings.button.add')}
        </Button>
      )}
    </Box>
  );
}
