import { IProductCard, ProductTypes } from 'models/Product.model';
import { createSelector } from 'reselect';
import { selectFlowBuilderChangeableFlow } from 'apps/flowBuilder/store/FlowBuilder.selectors';
import { IFlow } from 'models/Flow.model';
import { selectMerchantFlowList } from 'state/merchant/merchant.selectors';
import { productManagerService } from '../services/ProductManager.service';
import { PRODUCT_STORE_KEY, ProductStore } from './Product.store';

export const selectProductStore = (state) => state[PRODUCT_STORE_KEY];

export const selectProductIsInited = createSelector(
  selectProductStore,
  (store: ProductStore): boolean => store.isInited,
);

export const selectProductRegistered = createSelector(
  selectProductStore,
  (store: ProductStore): ProductTypes[] => store.registered || [],
);

export const selectProductCards = createSelector(
  selectFlowBuilderChangeableFlow,
  selectProductRegistered,
  (): IProductCard[] => productManagerService.getProductsConfigurable().map((item) => item.getCard()),
);

export const selectNeverUsedMerits = createSelector<[typeof selectMerchantFlowList, typeof selectProductRegistered], ProductTypes[]>(
  selectMerchantFlowList,
  selectProductRegistered,
  (flows: IFlow[], registeredMerits: ProductTypes[]) => registeredMerits.filter((merit: ProductTypes) => flows.every((flow: IFlow) => !flow.isFlowAlreadyUsed || !productManagerService.getProduct(merit).isInFlow(flow))),
);
