import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { IFlattenVerificationPatternConfig } from 'models/VerificationPatternsConfigs.model';
import { ReactComponent as ArrowSquareOutIcon } from '../../assets/ArrowSquareOut.svg';
import { GovCheckModalMainInfo } from '../GovCheckModalMainInfo/GovCheckModalMainInfo';
import { useStyles } from './GovCheckModalDataBaseInfo.styles';

export function GovCheckModalDataBaseInfo({ govCheck, handleToggle }: {
  govCheck: IFlattenVerificationPatternConfig;
  handleToggle: (checked: boolean, govCheck?: IFlattenVerificationPatternConfig) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const onClick = () => {
    handleToggle(!govCheck.isActive, govCheck);
  };

  return (
    <Box className={classes.root}>
      <GovCheckModalMainInfo onClick={onClick} govCheck={govCheck} />
      <Box className={classes.body}>
        <Grid container direction="row" className={classes.description}>
          <Grid spacing={1} item container direction="column" xs={2}>
            <Grid item>{formatMessage('GovCheckMerit.settings.modal.input')}</Grid>
            <Grid item>{formatMessage('GovCheckMerit.settings.modal.outputs')}</Grid>
          </Grid>
          <Grid spacing={1} item container direction="column" xs={10}>
            <Grid item>{govCheck.inputFields}</Grid>
            <Grid item>{govCheck.outputFields}</Grid>
          </Grid>
        </Grid>
        {(govCheck.documentationUrlStep || govCheck.documentationUrlApi) && (
          <Link className={classes.link} href={govCheck.documentationUrlStep || govCheck.documentationUrlApi}>
            <ArrowSquareOutIcon />
          </Link>
        )}
      </Box>
    </Box>
  );
}
