import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { FiLoader } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import { notification, Switch } from 'apps/ui';
import { flowBuilderChangeableFlowUpdate, selectFlowBuilderChangeableFlowModel, selectFlowBuilderChangeableLogoUrl } from 'apps/flowBuilder';
import { LimiterSubscriptionStatus, PermissionSubscriptionStatusTypes, SubscriptionStatusGate, useHasPermissionSubscription } from 'apps/Permissions';
import { flowBuilderSDKMediaUpdate } from 'apps/logo/state/Logo.actions';
import compressImage from 'lib/compressImage';
import { QATags } from 'models/QA.model';
import { useStyles } from './LogoUpload.styles';
import { Button } from 'lib/UI';

export function LogoUpload() {
  const styles = useStyles();
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const logoModel = useSelector(selectFlowBuilderChangeableLogoUrl);
  const flowModel = useSelector(selectFlowBuilderChangeableFlowModel);
  const hasUploadPermission = useHasPermissionSubscription(PermissionSubscriptionStatusTypes.canUploadLogo);
  const [isCustomLogo, setIsCustomLogo] = useState(Boolean(logoModel?.publicUrl));

  const showError = () => notification.error(formatMessage('flow.logoStep.button.error'));

  const onDropAccepted = async (files) => {
    try {
      const file = files[0];
      const form = new FormData();
      const compressedFile = await compressImage(file, {
        type: file.type,
      });
      form.append('media', compressedFile);
      dispatch(flowBuilderSDKMediaUpdate(form));
    } catch (error) {
      showError();
    }
  };
  const handleRemove = () => dispatch(flowBuilderChangeableFlowUpdate({ logo: { url: null, publicUrl: null } }));

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected: showError,
    multiple: false,
    accept: 'image/*',
    disabled: !hasUploadPermission,
  });

  const handleSwitchCustomLogo = (value: boolean) => {
    if (!value) {
      dispatch(flowBuilderChangeableFlowUpdate({ logo: { url: null, publicUrl: null } }));
    }

    setIsCustomLogo(value);
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className={styles.formControl}
          value="start"
          control={(
            <Switch
              checked={isCustomLogo}
              onSwitch={handleSwitchCustomLogo}
              data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.customLogo}
            />
          )}
          label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.customLogo.label')}
          labelPlacement="start"
        />
      </FormGroup>
      {isCustomLogo && (
        <Box mt={1}>
          <FormGroup aria-label="position" row>
            <LimiterSubscriptionStatus permission={PermissionSubscriptionStatusTypes.canUploadLogo}>
              <Box maxWidth={387} maxHeight={271}>
                <Typography className={styles.logoPreviewSectionHeading}>
                  {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.logoUploadSection.heading')}
                </Typography>
                <Box className={styles.logoPreviewSection}>
                  <div {...((!logoModel?.publicUrl) && getRootProps())} className={styles.addLogo}>
                    <input
                      {...getInputProps()}
                      data-qa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.customLogoUploadInput}
                    />
                    {flowModel.isLoading && !logoModel
                      ? <FiLoader size={20} color="gray" />
                      : logoModel?.publicUrl
                        ? <img src={logoModel?.publicUrl} alt="logo-preview" className={styles.logoPreview} />
                        : (
                          <Box className={styles.defaultLogoPreviewSection}>
                            <Typography className={styles.logoPreviewSectionText}>
                              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.logoUploadSection.placeHolderOne')}
                            </Typography>
                            <Typography className={styles.logoPreviewSectionText}>
                              {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.logoUploadSection.placeHolderTwo')}
                            </Typography>
                          </Box>
                        )}
                  </div>
                </Box>
                <div {...((!logoModel?.publicUrl) && getRootProps())}>
                  <SubscriptionStatusGate permission={PermissionSubscriptionStatusTypes.canUploadLogo}>
                    <Button
                      type="secondary"
                      destructive
                      data-qa={
                        logoModel?.publicUrl ? QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.customLogoRemoveButton
                          : QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.customLogoUploadButton
                      }
                      onClick={logoModel?.publicUrl ? handleRemove : null}
                    >
                      {flowModel.isLoading
                        ? formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.logoUploadSection.uploadBtn.loadingState')
                        : (logoModel?.publicUrl
                          ? formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.logoUploadSection.removeBtn')
                          : formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Logo.logoUploadSection.uploadBtn')
                        )}
                    </Button>
                  </SubscriptionStatusGate>
                </div>
              </Box>
            </LimiterSubscriptionStatus>
          </FormGroup>
        </Box>
      )}
    </FormControl>
  );
}
