import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription } from 'apps/ui';
import { CountriesByRegions, CountryCodeTypes } from 'models/Country.model';
import React from 'react';
import { getPatternNameFromConfig, GroupedPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { GovernmentChecksRegionsOrder } from '../../models/GovCheckMerit.model';
import { GovCheckModalRegion } from '../GovCheckModalRegion/GovCheckModalRegion';
import { useStyles } from './GovCheckModalSidebar.styles';

export function GovCheckModalSidebar({ selectedCountry, setSelectedCountry, isShowSelected, setIsShowSelected, patternsConfigGroupedByCountry }: {
  isShowSelected: boolean;
  setIsShowSelected: (value: boolean) => void;
  selectedCountry?: CountryCodeTypes;
  setSelectedCountry: (value: CountryCodeTypes) => void;
  patternsConfigGroupedByCountry: GroupedPatternsConfigType;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const handleChange = () => setIsShowSelected(!isShowSelected);

  return (
    <>
      <Box width="100%" className={classes.root}>
        {GovernmentChecksRegionsOrder.map((region) => {
          const countries = CountriesByRegions[region].filter((country) => {
            const patternsConfig = patternsConfigGroupedByCountry[country];
            if (!isShowSelected) {
              return patternsConfig;
            }
            return patternsConfig?.filter((config) => config[getPatternNameFromConfig(config)].isActive).length;
          });
          if (!countries.length) {
            return null;
          }
          return <GovCheckModalRegion key={region} countries={countries} setSelectedCountry={setSelectedCountry} selectedCountry={selectedCountry} region={region} />;
        })}
      </Box>
      <Box width="100%" className={classes.showSelectedBlock}>
        <ExtendedDescription
          titleColor="common.black75"
          textFontSize={14}
          title={formatMessage('GovCheckMerit.settings.showSelected.title')}
          postfix={(
            <Switch
              onChange={handleChange}
              checked={isShowSelected}
              color="primary"
            />
          )}
        />
      </Box>
    </>
  );
}
