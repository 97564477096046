import { capitalize } from 'lodash';
import { ValidationError } from 'yup';
import * as yup from 'yup';
import restrictedDomains from './emailDomains.json';

export const ONLY_NUMBERS_REG_EXP = /[^0-9]/;
export const ONLY_POSITIVE_NUMBERS_REG_EXP = /^[1-9]\d*$/i;
export const BUSINESS_NAME_REG_EXP = /^(?=.*[a-zA-Z0-9])/;
export const FULL_NAME_REG_EXP = /^(?=.*[a-zA-Z])/;
export const EMAIL_REG_EXP = /^[A-Z0-9'._%+-]+@([A-Z0-9.-]+\.[A-Z]{2,63})$/i;
export const CUSTOMER_SUPPORT_EMAIL_REG_EXP = /\+[A-Z0-9.-]+@/i;
export const CLEAN_TEXT_REG_EXP = /^[^`~!@#$%^&*()+=[{\]}|\\'<,.>?";:]+$/;
export const SPECIAL_CHARACTERS_REG_EXP = /[ !"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~]/;
export const PASSWORD_REG_EXP = /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~]).*$/;
export const WEBHOOK_SECRET_REG_EXP = /^.*(?=.{16,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/;
export const HTTPS_REG_EXP = /^((https):\/\/)/;

export enum ValidationTypes {
  TOOLONG = 'validations.tooLong',
  EMPTY = 'validations.empty',
  TOOSHORT = 'validations.tooShort',
}

export function isBusinessEmail(value) {
  const match = EMAIL_REG_EXP.exec(value.toLowerCase());
  return !(match && restrictedDomains.includes(match[1]));
}

export function isMetamapEmail(value: string): boolean {
  const match = EMAIL_REG_EXP.exec(value?.toLowerCase());
  return match && ['mati.io', 'metamap.com', 'getmati.com'].includes(match[1]);
}

// name.surname+businessname@metamap.com
export function isCustomerSupportEmail(value: string): boolean {
  if (!value) {
    return false;
  }
  return isMetamapEmail(value) && CUSTOMER_SUPPORT_EMAIL_REG_EXP.test(value);
}

export function password(value, message, field) {
  const PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/;

  if (!PASSWORD.test(value[field])) {
    return message;
  }
  return null;
}

export function validationHandler(error, intl, setError) {
  if (error instanceof ValidationError) {
    const message = capitalize(
      intl.formatMessage({ id: error.message }, error.params),
    );
    setError(message);
  } else {
    console.error(error);
    setError(capitalize(intl.formatMessage({ id: 'unknownError' })));
  }
}

export function validateMaxLength(name: string = '', limit: number): Nullable<ValidationTypes> {
  if (name.length > limit) {
    return ValidationTypes.TOOLONG;
  }
  return null;
}

export function validateEmpty(text: string): Nullable<ValidationTypes> {
  if (text?.length === 0) {
    return ValidationTypes.EMPTY;
  }
  return null;
}

export function validateMinLength(name = '', limit): Nullable<ValidationTypes> {
  if (name.length < limit) {
    return ValidationTypes.TOOSHORT;
  }
  return null;
}

export function validateRangeLength(text: string, min: number, max: number): Nullable<ValidationTypes> {
  return validateMinLength(text, min) || validateMaxLength(text, max) || null;
}

export function generateWebhookSecret(): string {
  const baseString = window.crypto.getRandomValues(new BigUint64Array(1))[0].toString(36)
    + window.crypto.getRandomValues(new BigUint64Array(1))[0].toString(36).toUpperCase();
  return baseString.split('')
    .sort(() => Math.random() - 0.5)
    .join('');
}

const nameSchema = yup.object({
  hasDuplicate: yup.boolean().oneOf([false], 'validators.nameOccupied'),
  name: yup
    .string()
    .trim()
    .min(1, 'validators.StringMin')
    .max(30, 'validators.StringMax'),
});

export async function flowNameValidator(data) {
  return nameSchema.validate(data);
}
