import { DEFAULT_LOCALE, SupportedLocales } from 'models/Intl.model';
import baseLocale from '../locale/en.json';
import baseRegions from '../locale/en_regions.json';
import { getTranslations } from '../client/translations';

export async function preloadTranslations(locale: SupportedLocales): Promise<Record<string, unknown>> {
  let messages: Record<string, unknown> = {};
  if (locale !== DEFAULT_LOCALE) {
    messages = await getTranslations(locale);
  }
  return {
    ...baseLocale,
    ...messages,
    ...baseRegions,
  };
}
