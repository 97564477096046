import { useSegment, useSegmentInit } from '../hooks/segment.hook';
import { useSentry } from '../hooks/sentry.hook';

export function AppBootstrap() {
  useSentry();
  useSegmentInit();
  useSegment();

  return null;
}
