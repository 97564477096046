import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  editField: {
    cursor: 'pointer',
    alignItems: 'center',
    width: 'initial',
    gap: '10px',
    '& button': {
      display: 'none',
    },
    '&:hover': {
      '& button': {
        display: 'block',
      },
    },
  },
  modal: {
    [theme.breakpoints.up('lg')]: {
      width: 725,
    },
  },
}));
