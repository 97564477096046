import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    width: 'calc(100vw - 160px)',
    marginLeft: 100,
    padding: 0,
    height: 'calc(100vh - 40px)',
    maxHeight: '100vh',
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    [theme.breakpoints.down(1080)]: {
      width: '85vw',
    },
  },
  btnClose: {
    position: 'absolute',
    zIndex: 100,
    right: 10,
    top: 10,
    maxWidth: 32,
    minWidth: 32,
    maxHeight: 32,
    minHeight: '32px !important',
    border: 'none',
    background: theme.palette.common.black7,
    outline: 'none',
    cursor: 'pointer',
    transition: '.25s all ease-in',

    '& .MuiIconButton-label': {
      width: 'auto',
      height: 'auto',
    },

    '&:hover, &:focus': {
      filter: 'brightness(0.5)',
    },
  },
}));
