import * as api from '../api/auth0.client';
import { IIdentifierFirstInputs, IIdentifierFirstResponseData } from '../models/Auth0.model';

export const identifierFirst = async (credentials: IIdentifierFirstInputs): Promise<IIdentifierFirstResponseData> => {
  const { data } = await api.identifierFirst(credentials);
  return data;
};

export const emailVerification = () => async () => {
  await api.emailVerification();
};

export const passwordReset = () => async () => {
  await api.passwordReset();
};

export const getFreemiumPage = () => async () => {
  const { data } = await api.getFreemiumPage();
  return data;
};
