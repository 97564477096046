import Box from '@material-ui/core/Box';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import Grid from '@material-ui/core/Grid';
import { BsInfo } from 'react-icons/bs';
import { useStyles } from './RiskScoreInstructions.styles';

const RiskScoreInstructions = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  return (
    <Box>
      <Typography variant="body2" className={classes.info}>
        {formatMessage('riskScoreInstructions.description.title')}
      </Typography>
      <Box mt={2}>
        <Typography variant="body2" className={classes.listItem}>
          {formatMessage('riskScoreInstructions.description.lowScore.title')}
        </Typography>
        <Typography variant="body2" className={classes.info}>
          {formatMessage('riskScoreInstructions.description.lowScore.description')}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.listItem}>
          {formatMessage('riskScoreInstructions.description.mediumScore.title')}
        </Typography>
        <Typography variant="body2" className={classes.info}>
          {formatMessage('riskScoreInstructions.description.mediumScore.description')}
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Typography variant="body2" className={classes.listItem}>
          {formatMessage('riskScoreInstructions.description.hightScore.title')}
        </Typography>
        <Typography variant="body2" className={classes.info}>
          {formatMessage('riskScoreInstructions.description.hightScore.description')}
        </Typography>
      </Box>
      <Box m={1}>
        <Grid container className={classes.footer} spacing={2} alignItems="flex-start">
          <Grid item xs="auto">
            <BsInfo size={20} className={classes.footerIcon} />
          </Grid>
          <Grid item xs={10} className={classes.footerData}>
            <Typography variant="body2">
              {formatMessage('riskScoreInstructions.description.footer.details')}
            </Typography>
            <Typography variant="body2" className={classes.recommendation}>
              {formatMessage('riskScoreInstructions.description.footer.recommendation')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RiskScoreInstructions;
