import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderWidth: 1,
    borderColor: theme.palette.common.black7,
    borderRadius: 4,
    borderStyle: 'solid',
    height: '100%',
    padding: 5,
  },
  marginRight: {
    marginRight: 10,
  },
  marginBottom: {
    marginBottom: 10,
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
  },
  responsiveContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  piechartContainer: {
    marginBottom: 20,
  },
  boxTitle: {
    color: theme.palette.common.black75,
    marginTop: 15,
    marginLeft: 20,
    marginBottom: 14,
  },
  tooltipIcon: {
    verticalAlign: 'sub',
  },
  staticDataItem: {
    marginTop: 12,
    marginLeft: 18,
    marginRight: 18,
    '&:last-child': {
      marginBottom: 20,
    },
  },
  detailLabel: {
    color: theme.palette.common.black75,
    fontWeight: 400,
  },
  detailValue: {
    color: theme.palette.common.black90,
    fontSize: 16,
    overflowWrap: 'break-word',
  },
  chartTooltip: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 1,
    borderColor: theme.palette.common.black7,
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    filter: 'drop-shadow(0px 0.5px 1.75px rgba(0, 0, 0, 0.039)) drop-shadow(0px 1.85px 6.25px rgba(0, 0, 0, 0.19))',
    display: 'flex',
    flexDirection: 'column',
  },
  chartLabel: {
    color: theme.palette.common.black60,
    fontSize: 14,
    marginRight: 8,
  },
  chartValue: {
    color: theme.palette.common.black,
    fontSize: 16,
    marginBottom: 8,
  },
  redChartValue: {
    color: theme.palette.common.red,
  },
  greenChartValue: {
    color: theme.palette.common.green,
  },
  noDataAvailable: {
    color: theme.palette.common.black60,
    fontSize: 18,
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 4,
  },
  radio: {
    width: 'calc(100% - 28px)',
    marginLeft: 14,
    marginRight: 14,
    marginBottom: 14,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
    '& .MuiToggleButton-root': {
      width: '100%',
      border: 0,
      margin: 3,
    },

    '& .MuiToggleButton-root:hover': {
      borderRadius: 6,
    },

    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.common.whiteblue,
      borderRadius: 6,
    },

    '& .MuiToggleButton-label': {
      fontSize: 16,
      color: theme.palette.primary.main,
    },
  },
  errorTitle: {
    color: theme.palette.common.yellow,
  },
  errorSubtitle: {
    opacity: 0.65,
    color: theme.palette.common.black90,
    fontWeight: 500,
  },
  excelButton: {
    border: '1px solid',
    boxShadow: 'none',
  },
  excelErrorDialog: {
    backgroundColor: theme.palette.common.white,
    padding: 24,
    width: 500,
  },
  excelErrorTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24px',
    marginBottom: 16,
  },
  excelErrorContent: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 24,
  },
  excelErrorOkay: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px !important',
    lineHeight: '18px',
  },
}));
