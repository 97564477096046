import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { overlayClose } from 'apps/overlay/state/overlay.actions';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { QATags } from 'models/QA.model';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as CloseIcon } from 'assets/close-button.svg';
import { useStyles } from './ModalMetamap.styles';

export function ModalMetamap({ onClose, children, className, title, subtitle, description }: {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const closeModal = useCallback(() => {
    if (onClose) {
      onClose(); // this onClose will be called when you click on the closeButton (x)
    } else {
      dispatch(overlayClose());
    }
  }, [dispatch, onClose]);

  return (
    <div
      className={classNames(classes.window, className)}
      data-role="modal"
    >
      <IconButton data-qa={QATags.Modal.CloseButton} className={classes.buttonClose} onClick={closeModal}>
        <CloseIcon className={classes.buttonIcon} />
      </IconButton>
      <Box mb={2}>
        <Box className={classes.titleWrapper}>
          {title && (
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Box mt={2} className={classes.subtitle}>
              {subtitle}
            </Box>
          )}
          {description}
        </Box>
      </Box>
      {children}
    </div>
  );
}
