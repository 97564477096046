import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { formatCurrency } from 'lib/currency';
import { ReactComponent as NoDataTableIcon } from 'assets/no-data-table.svg';
import { QATags } from 'models/QA.model';
import { Pagination } from '@material-ui/lab';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import { dateSortCompare } from 'lib/date';
import { BankAccountTransactionTypes, IBankAccountDataVerificationTransaction, transactionTableColumnsData, TransactionTableKeyTypes, TransactionTableSortByMap, TransactionTableSortDirection } from '../../models/BankAccountData.model';
import { useStyles } from './BankAccountTransactionTable.styles';

const DEFAULT_PAGE_SIZE = 10;

export function BankAccountTransactionTable({ data, currency }: {
  data: IBankAccountDataVerificationTransaction[];
  currency: string;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState(data);
  const [pageData, setPageData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handlePagination = useCallback((pageNumber) => {
    setPage(pageNumber);
    setPageData(allData?.slice((pageNumber - 1) * DEFAULT_PAGE_SIZE, pageNumber * DEFAULT_PAGE_SIZE));
  }, []);

  const handleSort = useCallback((id: TransactionTableKeyTypes) => () => {
    const isAsc = sortBy === id && sortOrder === TransactionTableSortDirection.asc;

    setSortBy(id);
    setSortOrder(isAsc ? TransactionTableSortDirection.desc : TransactionTableSortDirection.asc);

    const updatedData = allData.sort((a, b) => {
      if (id === TransactionTableKeyTypes.date) {
        if (isAsc) {
          return dateSortCompare(a?.date, b?.date);
        }
        return dateSortCompare(a?.date, b?.date, true);
      }
      if (isAsc) {
        return parseFloat(b[id]) - parseFloat(a[id]);
      }
      return parseFloat(a[id]) - parseFloat(b[id]);
    });
    setAllData(updatedData);

    handlePagination(1);
  }, [sortBy, sortOrder]);

  // initialize
  useEffect(() => {
    handlePagination(1);
  }, [handlePagination]);

  return (
    <Grid container className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography variant="subtitle2" className={classes.tableTitle}>
          {formatMessage('BankAccountData.table.title')}
        </Typography>
        <Box className={classes.paginator}>
          <Pagination
            count={Math.ceil(data?.length / DEFAULT_PAGE_SIZE)}
            onChange={(_, pageNumber) => handlePagination(pageNumber)}
            page={page}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Box>
      <Grid xs={12}>
        <Divider light className={classes.divider} />
      </Grid>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} data-qa={QATags.BankAccountVerification.Table}>
          <TableHead>
            <TableRow>
              {/* Header cells */}
              {transactionTableColumnsData.map(({ id, isSortable }) => (
                <React.Fragment key={id}>
                  {id && isSortable ? (
                    <TableCell className={classes.tableHeadCell} onClick={handleSort(id)} sortDirection={sortBy === TransactionTableSortByMap[id] ? sortOrder : false}>
                      <Typography variant="subtitle2" className={classes.tableHeadTitle}>
                        <TableSortLabel
                          IconComponent={KeyboardArrowDown}
                          active={sortBy === TransactionTableSortByMap[id]}
                          direction={sortBy === TransactionTableSortByMap[id] ? sortOrder : TransactionTableSortDirection.asc}
                        >
                          {formatMessage(`BankAccountData.table.field.${id}`, { messageValues: { currency } })}
                        </TableSortLabel>
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableHeadCell}>
                      <Typography variant="subtitle2" className={classes.tableHeadTitle}>
                        {formatMessage(`BankAccountData.table.field.${id}`)}
                      </Typography>
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>

          {(!data || data.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Box mb="10vh">
                    <Box py={2.5}><NoDataTableIcon /></Box>
                    <Typography variant="h4">{formatMessage('BankAccountData.table.noDataAvailable')}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {data?.length > 0 && (
            <TableBody>
              {pageData.map(({ date, description, category, amount, type }) => (
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>
                    <Typography variant="subtitle2" className={classnames(classes.tableRowDataText, 'fs-exclude')}>
                      {date}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle2" className={classnames(classes.descriptionText, 'fs-exclude')}>
                        {description}
                      </Typography>
                      <Box className={classes.tag}>
                        <Typography variant="subtitle2" className={classnames(classes.tagText, 'fs-exclude')}>
                          {category}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} align="right">
                    <Typography
                      variant="subtitle2"
                      className={classnames(classes.tableRowDataText, {
                        [classes.debits]: type === BankAccountTransactionTypes.DEBIT,
                        [classes.credits]: type === BankAccountTransactionTypes.CREDIT,
                      }, 'fs-exclude')}
                    >
                      {formatCurrency(amount)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );
}
