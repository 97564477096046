import React from 'react';
import { ItemValue, Spoiler, WarningBadge } from 'apps/ui';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { SpoilerTypes } from 'apps/ui/components/Spoiler/SpoilerTypes';
import { ReactComponent as CrossIcon } from 'assets/icon-btn-close.svg';
import { useFormatMessage } from 'apps/intl';
import { LegalSearchRecord } from '../../../../models/GovCheckMerit.model';
import { useStyles } from './LegalSearchRecord.styles';

export const LegalSearchProcessDetails = ({
  record: {
    region,
    processId,
    processNumber,
    processClass,
    processType,
    updatedAt,
    openedAt,
    involvedParties,
  },
}: {
  record: LegalSearchRecord;
}) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  return (
    <Box px="12px">
      <Box p="12px">
        <Spoiler
          type={SpoilerTypes.Danger}
          title={region}
          extraIcon={<WarningBadge className={classes.warningIcon} isDefaultPosition={false} />}
          headerIcon={(
            <CrossIcon className={classes.crossIcon} />
          )}
        >
          <Box p="12px">
            <Grid container>
              <Grid item xs={12}>
                <ItemValue
                  value={involvedParties}
                  title={formatMessage('GovCheck.check.unifiedLegalSearch.involvedParties')}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item container className={classes.row} xs={6}>
                <Grid item xs={4}>
                  <ItemValue value={processId} title={formatMessage('GovCheck.check.unifiedLegalSearch.processId')} />
                </Grid>
                <Grid item xs={8}>
                  <ItemValue
                    value={processNumber}
                    title={formatMessage('GovCheck.check.unifiedLegalSearch.processNumber')}
                  />
                </Grid>
              </Grid>
              <Grid item container className={classes.row} xs={6}>
                <Grid item xs={4}>
                  <ItemValue value={processType} title={formatMessage('GovCheck.check.unifiedLegalSearch.processType')} />
                </Grid>
                <Grid item xs={8}>
                  <ItemValue
                    value={processClass}
                    title={formatMessage('GovCheck.check.unifiedLegalSearch.processClass')}
                  />
                </Grid>
              </Grid>
              <Grid item container className={classes.row} xs={6}>
                <Grid item xs={6}>
                  <ItemValue value={updatedAt} title={formatMessage('GovCheck.check.unifiedLegalSearch.updatedAt')} />
                </Grid>
                <Grid item xs={6}>
                  <ItemValue value={openedAt} title={formatMessage('GovCheck.check.unifiedLegalSearch.updatedAt')} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Spoiler>
      </Box>
    </Box>
  );
};
