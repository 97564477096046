import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ItemValue } from 'apps/ui';
import { useStyles } from './RecordView.styles';

type RecordItem = {
  title: React.ReactNode;
  value: React.ReactNode;
  titleClass?: string;
  valueClass?: string;
  containerClass?: string;
  gridClass?: string;
  extraContent?: React.ReactNode;
}

export const RecordView = ({
  itemLeft,
  itemRight,
  details,
}: {
  itemLeft: RecordItem;
  itemRight: RecordItem;
  details?: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper}>
      <Grid container direction="column">
        <Grid item container xs={12} alignItems="center">
          <Grid item xs className={itemLeft?.gridClass}>
            <Box className={classes.header} px="24px" py="14px">
              <ItemValue
                title={itemLeft.title}
                value={itemLeft.value}
                classNames={{
                  title: itemLeft.titleClass,
                  value: itemLeft.valueClass,
                  container: itemLeft.containerClass,
                }}
              />
            </Box>
            {itemLeft.extraContent}
          </Grid>
          <Grid item className={itemRight?.gridClass}>
            <Box className={classes.header} px="24px" py="14px">
              <ItemValue
                title={itemRight.title}
                value={itemRight.value}
                classNames={{
                  title: itemRight.titleClass,
                  value: itemRight.valueClass,
                  container: itemRight.containerClass,
                }}
              />
            </Box>
            {itemRight.extraContent}
          </Grid>
        </Grid>
        {details && (
          <Grid item>
            {details}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
