import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import cloneDeep from 'lodash/cloneDeep';
import React, { useMemo } from 'react';
import { IAppearance, CustomizablePropertiesTypes } from 'models/Flow.model';
import { useStyles } from './TypographyCustomization.style';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FontSelect } from './FontSelect';

export function TypographyCustomization({ appearance, onChange }: {
  appearance: IAppearance;
  onChange: (appearance: IAppearance) => void;
}) {
  const formatMessage = useFormatMessage();
  const styles = useStyles();

  const fontFamily = useMemo(() => {
    const fontProperty = appearance.properties.find((property) => property.name === CustomizablePropertiesTypes.FontFamily);
    if (!fontProperty) {
      return null;
    }

    return fontProperty.value;
  }, [appearance]);

  const handleFontChange = (value) => {
    const updated = cloneDeep(appearance);
    const property = updated.properties.find((item) => item.name === CustomizablePropertiesTypes.FontFamily);
    if (!property) {
      updated.properties.push({
        name: CustomizablePropertiesTypes.FontFamily,
        value,
      });
    } else {
      property.value = value;
    }
    onChange(updated);
  };

  return (
    <Box mt={1}>
      <Box mb={1} className={styles.label}>
        {formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.tabs.Typography.FontSelectLabel')}
      </Box>
      <FontSelect
        value={fontFamily}
        defaultValue="Open Sans"
        onChange={handleFontChange}
      />
    </Box>
  );
}
