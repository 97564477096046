import { ITracker } from '../model/Tracker.model';

export class TrackingManagerService {
  private trackers: ITracker[] = [];

  register(tracker: ITracker) {
    this.trackers.push(tracker);
  }

  trackNavigation(eventName, payload) {
    this.trackers.forEach((tracker) => tracker.trackNavigation(eventName, payload));
  }

  trackView(eventName, payload) {
    this.trackers.forEach((tracker) => tracker.trackView(eventName, payload));
  }

  trackClick(eventName, payload) {
    this.trackers.forEach((tracker) => tracker.trackClick(eventName, payload));
  }
}

export const trackingManager = new TrackingManagerService();
