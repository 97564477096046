import Box from '@material-ui/core/Box';
import React from 'react';
import { IFlattenVerificationPatternConfig, isHidePattern } from 'models/VerificationPatternsConfigs.model';
import { useSelector } from 'react-redux';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { GovCheckModalDataBaseInfo } from '../GovCheckModalDataBaseInfo/GovCheckModalDataBaseInfo';
import { useStyles } from './GovCheckModalDataBaseOption.styles';

export function GovCheckModalDataBaseOption({ option, handleToggleOption, govCheck }: {
  option: IFlattenVerificationPatternConfig;
  govCheck: IFlattenVerificationPatternConfig;
  handleToggleOption: (govCheck: IFlattenVerificationPatternConfig, checked: boolean, option: IFlattenVerificationPatternConfig) => void;
}) {
  const classes = useStyles();

  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);

  const isHide: boolean = isHidePattern(option, merchantTags);

  const handleToggle = (checked: boolean) => {
    handleToggleOption(govCheck, checked, option);
  };

  if (isHide) return null;

  return (
    <Box className={classes.root}>
      <GovCheckModalDataBaseInfo handleToggle={handleToggle} govCheck={option} />
    </Box>
  );
}
