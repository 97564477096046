import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export enum GDPRRangeTypes {
  From = 1,
  To = 1096,
  Exact = 30,
}

export const GDPRRanges = {
  [VerificationPatternTypes.CreditMexicoCdc]: {
    from: 30,
    to: 1096,
  },
  default: {
    from: 1,
    to: 1096,
  },
};

export enum GDPRUnitTypes{
  Day = 'DAY',
  MONTH = 'MONTH'
}

export interface GDPRSettings{
  interval: string;
  unit: GDPRUnitTypes;
}
