import React from 'react';
import { Modal, useOverlay } from 'apps/overlay';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './SanctionListModalContainer.style';
import { SanctionModalSelect } from '../SanctionModalSelect/SanctionModalSelect';

export function SanctionListModalContainer({ title, description, selectedValues, onSubmit }: { title: string; description: string; selectedValues: string[]; onSubmit: (data: string[]) => void }) {
  const [, closeOverlay] = useOverlay();
  const classes = useStyles();
  return (
    <Modal
      onClose={closeOverlay}
      description={(
        <>
          <Typography variant="h4" gutterBottom className={classes.modalTitle}>{title}</Typography>
          <Box className={classes.modalSubTitle}>{description}</Box>
        </>
    )}
      className={classes.modal}
    >
      <SanctionModalSelect onSubmit={onSubmit} selectedValues={selectedValues} />
    </Modal>
  );
}
