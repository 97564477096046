import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useFormatMessage } from 'apps/intl';
import { ReactComponent as GreyShield } from 'assets/icon-shield-grey.svg';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './BackgroundCheckInProgress.styles';

export function BackgroundCheckInProgress() {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Typography className={classes.header}>
              {formatMessage('BackgroundCheck.verification.summary')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Box px={5} py={3} className={classes.iconBackground}>
                  <GreyShield />
                </Box>
              </Grid>
              <Grid item xs>
                <Box className={classes.descriptionCell} alignItems="center">
                  <Typography>
                    {formatMessage('BackgroundCheck.verification.process', { messageValues: { break: <br /> } })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
