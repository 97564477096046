import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, useStyles } from './ProductSettingsDetails.styles';

export function ProductSettingsDetails(
  { summary = null, details = null, defaultExpanded = false, className = null }:
  { defaultExpanded?: boolean; summary: Nullable<string>; details?: any; className?: Nullable<string> },
) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  const handleChange = useCallback(() => setIsExpanded((prev) => !prev), []);

  return (
    <Box className={classnames(classes.root, className)}>
      <ExpansionPanel expanded={isExpanded} onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center" wrap="nowrap">
            <Box ml={0.5} mr={2} fontSize="16px" fontWeight="600">
              {summary}
            </Box>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {details}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
}
