import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useFormatMessage } from 'apps/intl';
import { TabIDTypes, InformationImageTypes } from '../../models/ForDevelopers.model';
import { InformationImage } from '../InformationImage/InformationImage';
import { VideoExplainer } from '../VideoExplainer/VideoExplainer';
import { DirectLinkCopy } from '../DirectLinkCopy/DirectLinkCopy';

export function DirectLinkIntegrationInformation() {
  const formatMessage = useFormatMessage();

  return (
    <Box>
      <Typography
        variant="subtitle2"
        gutterBottom
      >
        {formatMessage(`forDevs.informationPage.${TabIDTypes.DirectLink}.header`)}
      </Typography>
      <Box
        mb={2}
        color="common.black75"
      >
        {formatMessage(`forDevs.informationPage.${TabIDTypes.DirectLink}.subheader`)}
      </Box>
      <Box mb={4}>
        <InformationImage type={InformationImageTypes.DirectLink} />
      </Box>
      <Box
        pt={4}
        mb={4}
        borderTop={1}
        borderColor="common.black7"
      >
        <DirectLinkCopy />
      </Box>
      <Box
        pt={4}
        borderTop={1}
        borderColor="common.black7"
      >
        <VideoExplainer
          videoCover="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati%2BDirect%2BLink.png"
          videoURL="https://s3.eu-central-1.amazonaws.com/io.mati.sharedfiles/Mati+Direct+Link.mp4"
          tabId={TabIDTypes.DirectLink}
        />
      </Box>
    </Box>
  );
}
