import React, { useCallback } from 'react';
import { useOverlay } from 'apps/overlay/hooks/Overlay.hook';
import { ConfirmModalMetamap } from '../components/ConfirmModalMetamap/ConfirmModalMetamap';

export function useConfirmOneAction(title?: React.ReactNode, subtitle?: React.ReactNode, buttonMessage?: string) {
  const [createOverlay, closeOverlay] = useOverlay();

  return useCallback((replacementSubtitle?: React.ReactNode) => new Promise<void>((resolve, reject) => {
    const onClose = () => {
      closeOverlay();
      reject();
    };

    const onConfirm = () => {
      closeOverlay();
      resolve();
    };

    createOverlay(
      <ConfirmModalMetamap
        title={title}
        subtitle={replacementSubtitle || subtitle}
        onClose={onClose}
        onConfirm={onConfirm}
        withCancelButton={false}
        confirmButtonMessage={buttonMessage}
      />, {
        onClose,
      },
    );
  }), [buttonMessage, closeOverlay, createOverlay, subtitle, title]);
}
