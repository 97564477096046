import * as api from '../api/CountryAndWorldPart.client';
import { types } from './CountryAndWorldPart.store';

export const getCountryAndWorldParts = () => async (dispatch) => {
  dispatch({ type: types.countryAndWorldParts_REQUEST });

  try {
    const { data } = await api.getCountryAndWorldParts();
    dispatch({ type: types.countryAndWorldParts_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: types.countryAndWorldParts_FAILURE, error });
    throw error;
  }
};
