import cloneDeep from 'lodash/cloneDeep';
import { isHaveFacematchSource } from 'models/Facematch.model';
import { IVerificationPatterns, VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { CountryCodeTypes } from './Country.model';
import { flattenTree, MappingValueKeyTypes } from './CustomField.model';
import { DocumentTypes } from './Document.model';
import { IFlow, ISettings, PatternSettingsType, VerificationStepsType } from './Flow.model';
import { InstitutionsTypes } from './Institutions.model';
import { MerchantTagModeTypes, MerchantTagsTypes } from './Merchant.model';
import { GOVERNMENT_CHECKS_PRODUCT_TYPES_TAGS_MAP, ProductTypes } from './Product.model';
import { GovCheckStepTypes } from './Step.model';

export const DisabledStringVerificationPattern = 'none';

export enum VerificationConfigurationErrorTypes {
  None = 'none',
  DataSourceNotInFlow = 'dataSourceNotInFlow',
  DataSourceBadConfig = 'dataSourceBadConfig',
  DataSourceSubTypeBadConfig = 'dataSourceSubTypeBadConfig',
  RequiredPatternsNotInFlow = 'requiredPatternsNotInFlow',
  ExclusivePatternsError = 'exclusivePatternsError',
  AgeCheckError = 'ageCheckError',
}

export enum VerificationInputTypes {
  Default = 'default',
  CustomField = 'custom-fields',
  DocumentVerification = 'document-data',
  PhoneCheck = 'PhoneCheck'
}

export const ProductByVerificationInput: { [T in VerificationInputTypes]?: ProductTypes } = {
  [VerificationInputTypes.CustomField]: ProductTypes.CustomField,
  [VerificationInputTypes.DocumentVerification]: ProductTypes.DocumentVerification,
  [VerificationInputTypes.PhoneCheck]: ProductTypes.PhoneCheck,
};

export interface IVerificationPatternInputConfig {
  id: VerificationInputTypes;
  documents?: { type: DocumentTypes; subTypes: string[] }[];
  requiredMappingOptionGroups?: { country: CountryCodeTypes; key: MappingValueKeyTypes }[][];
}

export interface IVerificationPatternConfig {
  inputs: IVerificationPatternInputConfig[];
  country: CountryCodeTypes;
  product: ProductTypes;
  institution: InstitutionsTypes[];
  patternValue?: GovCheckStepTypes;
  description: string;
  slaMetrics: string;
  inputFields: string;
  outputFields: string;
  documentationUrlStep: string;
  documentationUrlApi: string;
  merchantTags?: { [T in MerchantTagsTypes]?: MerchantTagModeTypes }[];
  exclusivePatternsValues?: IVerificationPatterns[][];
  requiredPatternsValues?: IVerificationPatterns[][];
  fuzzyNameMatch?: number;
  defaultFuzzyNameMatchThreshold?: number;
  isAgeCheckAllowed: boolean;
  isFacematchSource?: boolean;
  govCheckName?: string;
  patternName?: string;
}

export type PatternErrorType = {
  type: VerificationConfigurationErrorTypes;
  requiredPatternsValues?: IVerificationPatterns[][];
  exclusivePatternsValues?: IVerificationPatterns[][];
  input?: IVerificationPatternInputConfig;
};

export interface IFlattenVerificationPatternConfig extends IVerificationPatternConfig {
  options?: IFlattenVerificationPatternConfig[];
  isActive?: boolean;
  patternName: string;
  patternSetting?: ISettings;
  error?: PatternErrorType;
}

export type VerificationPatternsConfigType<T = IVerificationPatternConfig[]> = {
  [P in VerificationPatternTypes]?: T;
};

export type FlattenVerificationPatternConfigType = VerificationPatternsConfigType<IFlattenVerificationPatternConfig>;

export type GroupedPatternsConfigType = Record<string, FlattenVerificationPatternConfigType[]>;

export const EmptyError: PatternErrorType = { type: VerificationConfigurationErrorTypes.None };

export const isCanUsePattern = (verificationPatternsConfig: IVerificationPatternConfig, merchantTags: MerchantTagsTypes[]): boolean => {
  if (!verificationPatternsConfig?.merchantTags) {
    const product = verificationPatternsConfig?.product;
    return !merchantTags.includes(GOVERNMENT_CHECKS_PRODUCT_TYPES_TAGS_MAP[product]);
  }

  return verificationPatternsConfig.merchantTags?.every((tag) => merchantTags.includes((Object.keys(tag)[0] as MerchantTagsTypes)));
};

export const isHidePattern = (verificationPatternsConfig: IVerificationPatternConfig, merchantTags: MerchantTagsTypes[]): boolean => {
  if (!verificationPatternsConfig?.merchantTags) {
    return false;
  }

  return verificationPatternsConfig.merchantTags?.some((merchantTag) => {
    const [tag, mode] = Object.entries(merchantTag)[0];
    if (!merchantTags.includes(tag as MerchantTagsTypes)) {
      return mode === MerchantTagModeTypes.Hide;
    }
    return false;
  });
};

export const getExclusivePatternsError = (patternConfig: IVerificationPatternConfig, verificationPatterns: Partial<IVerificationPatterns>): PatternErrorType => {
  if (!patternConfig.exclusivePatternsValues) {
    return EmptyError;
  }
  const isHaveError = patternConfig.exclusivePatternsValues?.some((exclusivePatternsValue) => exclusivePatternsValue.every((value) => {
    const [patternName, patternValue] = Object.entries(value)[0];
    return verificationPatterns[patternName] === patternValue;
  }));
  return isHaveError ? { type: VerificationConfigurationErrorTypes.ExclusivePatternsError, exclusivePatternsValues: patternConfig.exclusivePatternsValues } : EmptyError;
};

export const getRequiredPatternsError = (patternConfig: IVerificationPatternConfig, verificationPatterns: Partial<IVerificationPatterns>, flow: IFlow): PatternErrorType => {
  if (!patternConfig.requiredPatternsValues) {
    return EmptyError;
  }

  const isHaveError = !patternConfig.requiredPatternsValues?.some((requiredPatternsValues) => requiredPatternsValues.every((value) => {
    const [patternName, patternValue] = Object.entries(value)[0];
    const isPatternHasRightValue = verificationPatterns[patternName] === patternValue;
    if (isPatternHasRightValue && patternName === VerificationPatternTypes.Facematch) {
      return isHaveFacematchSource(flow, patternConfig.product);
    }
    return isPatternHasRightValue;
  }));

  if (!isHaveError) {
    return EmptyError;
  }

  return {
    type: VerificationConfigurationErrorTypes.RequiredPatternsNotInFlow,
    requiredPatternsValues: patternConfig.requiredPatternsValues,
  };
};

function hasRequiredDocumentType(inputConfig: IVerificationPatternInputConfig, verificationSteps: VerificationStepsType) {
  return inputConfig.documents.some(({ type }) => {
    const index = verificationSteps.findIndex((step) => step.includes(type));
    if (index === -1) {
      return false;
    }
    verificationSteps.splice(index, 1);
    return true;
  });
}

const getInputConfigError = (inputConfig: IVerificationPatternInputConfig, flow: IFlow, patternConfig: IVerificationPatternConfig): Nullable<{type: VerificationConfigurationErrorTypes; input: IVerificationPatternInputConfig}> => {
  const inputSourceError = {
    type: VerificationConfigurationErrorTypes.DataSourceBadConfig,
    input: inputConfig,
  };

  const inputSubtypeError = {
    type: VerificationConfigurationErrorTypes.DataSourceSubTypeBadConfig,
    input: inputConfig,
  };

  if (inputConfig.id === VerificationInputTypes.CustomField) {
    const flattenCustomFields = flattenTree(flow.customFieldsConfig?.fields);
    const hasError = !inputConfig.requiredMappingOptionGroups.some((keys) => keys.every(({ key }) => flattenCustomFields.find((field) => field.atomicFieldParams?.mapping?.key === key)));
    return hasError ? inputSourceError : null;
  }

  if (inputConfig.id === VerificationInputTypes.DocumentVerification) {
    const verificationSteps = cloneDeep(flow.verificationSteps);
    const hasRequiredDocuments = hasRequiredDocumentType(inputConfig, verificationSteps);

    if (!hasRequiredDocuments) {
      return inputSourceError;
    }

    if (flow.countriesSettings) {
      const hasRequiredSubTypes = inputConfig?.documents?.every(({ subTypes }) => {
        if (!subTypes) {
          return true;
        }

        const countrySetting = flow.countriesSettings.find(({ country }) => country === patternConfig.country);

        if (!countrySetting) {
          return true;
        }

        return subTypes.some((subType) => countrySetting.documentSubtypes.includes(subType));
      });

      return !hasRequiredSubTypes ? inputSubtypeError : null;
    }
  }

  return null;
};

export const getInputError = (patternConfig: IVerificationPatternConfig, productsInGraph: ProductTypes[], selectedInput: VerificationInputTypes, flow: IFlow): PatternErrorType => {
  const inputConfig = patternConfig.inputs.find((input) => input.id === selectedInput);
  if (!productsInGraph.includes(ProductByVerificationInput[inputConfig.id])) {
    return {
      type: VerificationConfigurationErrorTypes.DataSourceNotInFlow,
      input: inputConfig,
    };
  }

  return getInputConfigError(inputConfig, flow, patternConfig) || EmptyError;
};

const validateVirtualNIN = (patternConfig: IVerificationPatternConfig, productsInGraph: ProductTypes[], flow: IFlow, selectedDataSource: VerificationInputTypes): PatternErrorType => {
  // ATLDC-1111: Verification requires for CustomInput Merit if DocumentVerification is selected
  if (selectedDataSource === VerificationInputTypes.DocumentVerification) {
    const customInput = patternConfig.inputs.find(({ id }) => id === VerificationInputTypes.CustomField);

    if (!productsInGraph.includes(ProductTypes.CustomField)) {
      return {
        type: VerificationConfigurationErrorTypes.DataSourceNotInFlow,
        input: customInput,
      };
    }

    return getInputError(patternConfig, productsInGraph, customInput.id, flow);
  }

  return EmptyError;
};

const validateNigerianAv = (patternConfig: IVerificationPatternConfig, productsInGraph: ProductTypes[], flow: IFlow, selectedDataSource: VerificationInputTypes): PatternErrorType => {
  if (!productsInGraph.includes(ProductTypes.PhoneCheck)) {
    return {
      type: VerificationConfigurationErrorTypes.DataSourceNotInFlow,
      input: {
        id: VerificationInputTypes.PhoneCheck,
      },
    };
  }

  return EmptyError;
};

// TODO: @aleksei.rasskazov - GovCheck configuration model should be extended on BE, remove when ready
// Additional FE validations
const getAdditionalError = (patternName: string, patternConfig: IVerificationPatternConfig, productsInGraph: ProductTypes[], flow: IFlow, selectedDataSource: VerificationInputTypes): PatternErrorType => {
  const additionalPatternValidations: Record<string, (patternConfig: IVerificationPatternConfig, productsInGraph: ProductTypes[], flow: IFlow, selectedDataSource: VerificationInputTypes) => PatternErrorType> = {
    [VerificationPatternTypes.NigerianVirtualNin]: validateVirtualNIN,
    [VerificationPatternTypes.NigerianAv]: validateNigerianAv,
  };

  if (additionalPatternValidations[patternName]) {
    return additionalPatternValidations[patternName](patternConfig, productsInGraph, flow, selectedDataSource);
  }

  return EmptyError;
};

export function getPatternsConfigByProducts(products: ProductTypes[], patternsConfig: VerificationPatternsConfigType): VerificationPatternsConfigType {
  const result = {};
  if (patternsConfig) {
    Object.entries(patternsConfig).forEach(([patternName, patternConfigs]) => {
      if (products.includes(patternConfigs[0].product)) {
        result[patternName] = patternConfigs;
      }
    });
  }
  return result;
}

export function removeVerificationPatternsByConfig(patternsConfig: VerificationPatternsConfigType): Partial<IVerificationPatterns> {
  const verificationPatterns = {};
  Object.entries(patternsConfig || {}).forEach(([patternName, patternConfig]) => {
    verificationPatterns[patternName] = patternConfig[0].patternValue ? DisabledStringVerificationPattern : false;
  });

  return verificationPatterns;
}

export const isPatternSettingsChanged = (patternSetting: ISettings, defaultSettings: ISettings) => {
  if (!patternSetting) return false;
  return Object.entries(patternSetting).some(([setting, value]) => value !== defaultSettings[setting]);
};

export const getPatternNameFromConfig = (verificationPatternsConfig: VerificationPatternsConfigType<{}>): string => Object.keys(verificationPatternsConfig)[0];

export const isPatternActive = (value: unknown): boolean => value && value !== DisabledStringVerificationPattern;

export const isSubPatternActive = (value: unknown, patternValue: string): boolean => isPatternActive(value) && value === patternValue;

export const productIsInFlow = (flow: IFlow, patternsConfig: VerificationPatternsConfigType): boolean => Object.entries(flow?.verificationPatterns).some(
  ([key, value]) => Object.prototype.hasOwnProperty.call(patternsConfig, key) && isPatternActive(value),
);

export function toggleVerificationPattern(checked: boolean, pattern: IFlattenVerificationPatternConfig): Partial<IVerificationPatterns> {
  if (!pattern.patternValue) {
    return { [pattern.patternName]: checked };
  }
  return { [pattern.patternName]: checked ? pattern.patternValue : DisabledStringVerificationPattern };
}

export function toggleSubVerificationPattern(checked: boolean, pattern: IFlattenVerificationPatternConfig, option: IFlattenVerificationPatternConfig): Partial<IVerificationPatterns> {
  return { [pattern.patternName]: !checked ? DisabledStringVerificationPattern : option.patternValue };
}

export function setPatternSettings(patternSettings: PatternSettingsType, verificationPatterns: Partial<IVerificationPatterns>, verificationPatternsConfig: VerificationPatternsConfigType, getDefaultSettings: (verificationPatternConfigs: IVerificationPatternConfig) => ISettings): PatternSettingsType {
  const newPatternSettings = cloneDeep(patternSettings || {});
  Object.keys(verificationPatternsConfig).forEach((patternName) => {
    if ((!isPatternActive(verificationPatterns[patternName]) && newPatternSettings?.[patternName]) || (isPatternActive(verificationPatterns[patternName]) && !newPatternSettings?.[patternName])) {
      const settings = getDefaultSettings(verificationPatternsConfig[patternName][0]);
      if (Object.keys(settings).length !== 0) {
        newPatternSettings[patternName] = settings;
      }
    }
  });
  return newPatternSettings;
}

export const getErrorProperties = (patternName: string, config: IVerificationPatternConfig, patternSetting: ISettings, productSettings: ISettings, productsInGraph: ProductTypes[], flow: IFlow, verificationPatterns: Partial<IVerificationPatterns>, isGetInputError: boolean = false): Partial<IFlattenVerificationPatternConfig> => {
  let error = { type: VerificationConfigurationErrorTypes.None };
  let inputError = EmptyError;
  const selectedDataSource = config?.inputs.length === 1 ? config.inputs[0].id : patternSetting?.dataSource || productSettings?.dataSource;

  if (!isGetInputError) {
    inputError = getInputError(config, productsInGraph, selectedDataSource, flow);
  }

  const exclusivePatternsError = getExclusivePatternsError(config, verificationPatterns);
  const requiredPatternsError = getRequiredPatternsError(config, verificationPatterns, flow);
  const additionalError = getAdditionalError(patternName, config, productsInGraph, flow, selectedDataSource);

  if (exclusivePatternsError.type !== VerificationConfigurationErrorTypes.None) error = exclusivePatternsError;
  else if (inputError.type !== VerificationConfigurationErrorTypes.None) error = inputError;
  else if (requiredPatternsError.type !== VerificationConfigurationErrorTypes.None) error = requiredPatternsError;
  else if (patternSetting?.ageCheckOn && !flow?.ageThreshold) error = { type: VerificationConfigurationErrorTypes.AgeCheckError };
  else if (additionalError.type !== VerificationConfigurationErrorTypes.None) error = additionalError;

  return { error };
};

export function groupPatternsConfig(groupByProperty: string, patternsConfig: VerificationPatternsConfigType, parser: (patternName: string, patternConfigs: IVerificationPatternConfig[]) => IFlattenVerificationPatternConfig): GroupedPatternsConfigType {
  const unordered = Object
    .entries(cloneDeep(patternsConfig))
    .reduce((groups, [patternName, patternConfigs]) => {
      const propertyValue = patternConfigs[0][groupByProperty];
      const group = (groups[propertyValue] || []);
      group.push({ [patternName]: parser(patternName, patternConfigs) });
      return { ...groups, [propertyValue]: group };
    }, {});
  return Object
    .keys(unordered)
    .sort()
    .reduce((obj, key) => ({ ...obj, [key]: unordered[key] }), {});
}
