import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '400px',
    border: `1px solid ${theme.palette.common.black7}`,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px',
        borderColor: `${theme.palette.common.black7}`,
      },
      '&:hover fieldset': {
        border: '1px',
        borderColor: `${theme.palette.common.black7}`,
      },
      '&.Mui-focused fieldset': {
        border: '1px',
        borderColor: `${theme.palette.common.black7}`,
      },
    },
  },
  sidebar: {
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0px',
    borderRight: `1px solid ${theme.palette.common.black7}`,
  },
  sidebarContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    isolation: 'isolate',
    overflow: 'auto',
  },
  content: {
    overflow: 'auto',
    height: '400px',
  },
  wrapper: {
    height: '52px',
    borderBottom: `1px solid ${theme.palette.common.black7}`,
    alignItems: 'center',
  },
  outlinedInput: {
    border: `1px solid ${theme.palette.common.black7}`,
  },
  selectionButton: {
    minWidth: 80,
    minHeight: 52,
    borderRadius: 6,
    fontSize: 16,
    textDecoration: 'underline',
  },
  selectedCountry: {
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
    color: `${theme.palette.common.black75}`,
    textAlign: 'left',
    paddingLeft: '12px',
  },
  deselectionButton: {
    minWidth: 80,
    minHeight: 52,
    borderRadius: 6,
    fontSize: 16,
    textDecoration: 'underline',
  },
  alertWrapper: {
    backgroundColor: `${theme.palette.common.lightyellow}`,
  },
  alertIcon: {
    color: `${theme.palette.common.yellow}`,
  },
  alertText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: `${theme.palette.common.black75}`,
  },
  searchNotFoundText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '130%',
    color: `${theme.palette.common.black75}`,
  },

}));
