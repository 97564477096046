import { LoadableAdapter } from 'lib/Loadable.adapter';
import { DEFAULT_LOCALE } from 'models/Intl.model';
import { createReducer } from 'state/store.utils';
import { IMerchant } from 'models/Merchant.model';
import { types } from './merchant.actions';
import { MerchantActionGroups, SliceNameTypes, IMerchantStore } from './merchant.store';

const initialState: IMerchantStore = {
  [SliceNameTypes.Merchant]: LoadableAdapter.createState({
    // logoUrl: string;
    // id: string;
    // blockedAt: Date;
    // collaborators: any[];
    // createdAt: Date;
    // displayName: string;
    // businessName: string;
    // owner: string;
    // updatedAt: Date;
  } as IMerchant),
  [SliceNameTypes.Configuration]: LoadableAdapter.createState({
    dashboard: {
      language: DEFAULT_LOCALE,
    },
    patternsConfig: null,
  }),
  [SliceNameTypes.CustomDocuments]: LoadableAdapter.createState([]),
  [SliceNameTypes.Flows]: LoadableAdapter.createState([]),
  [SliceNameTypes.App]: LoadableAdapter.createState([]),
  [SliceNameTypes.HasVerifications]: LoadableAdapter.createState({ hasVerifications: false }),
  [SliceNameTypes.ManageRoles]: LoadableAdapter.createState(null),
  currentFlow: null,
  pristineFlows: [],
};

export default createReducer(initialState, {
  ...LoadableAdapter.createHandlers(MerchantActionGroups.Merchant, SliceNameTypes.Merchant),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.Configuration, SliceNameTypes.Configuration),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.App, SliceNameTypes.App),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.CustomDocuments, SliceNameTypes.CustomDocuments),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.Flows, SliceNameTypes.Flows),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.HasVerifications, SliceNameTypes.HasVerifications),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.GetManageRoles, SliceNameTypes.ManageRoles),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.UpdateManageRoles, SliceNameTypes.ManageRoles),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.CreateManageRoles, SliceNameTypes.ManageRoles),
  ...LoadableAdapter.createHandlers(MerchantActionGroups.DeleteManageRoles, SliceNameTypes.ManageRoles),

  [types.CURRENT_FLOW_UPDATE](state, { payload }) {
    return {
      ...state,
      currentFlow: payload,
    };
  },
  [types.PRISTINE_FLOWS_UPDATE](state, { payload }) {
    return {
      ...state,
      pristineFlows: payload,
    };
  },
  [types.BUSINESS_NAME_UPDATE](state, { payload }) {
    return {
      ...state,
      merchant: {
        ...state.merchant,
        value: {
          ...state.merchant.value,
          ...payload,
        },
      },
    };
  },
  [types.SETTINGS_UPDATE](state, { payload }) {
    return {
      ...state,
      merchant: {
        ...state.merchant,
        value: {
          ...state.merchant.value,
          settings: {
            ...state.merchant.value.settings,
            ...payload,
          },
        },
      },
    };
  },

  [types.CUSTOM_DOCUMENTS_CLEAR_STATS](state) {
    return {
      ...state,
      [SliceNameTypes.CustomDocuments]: {
        ...state[SliceNameTypes.CustomDocuments],
        isLoaded: true,
        isLoading: false,
        isFailed: false,
        error: null,
      },
    };
  },
  [types.MERCHANT_SUCCESS](state, { payload }) {
    return {
      ...state,
      merchant: {
        ...state.merchant,
        isLoaded: true,
        isLoading: false,
        value: {
          ...state.merchant.value,
          ...payload,
        },
      },
    };
  },
  [types.TRANSFER_OWNERSHIP_SUCCESS](state, { payload }) {
    return {
      ...state,
      merchant: {
        ...state.merchant,
        value: {
          ...state.merchant.value,
          collaborators: payload.collaborators,
          indexFields: payload.indexFields,
          owner: payload.owner,
        },
      },
    };
  },
  [types.CREATE_MANAGE_ROLES_SUCCESS](state, { payload }) {
    return {
      ...state,
      [SliceNameTypes.ManageRoles]: {
        ...state[SliceNameTypes.ManageRoles],
        isLoaded: true,
        isLoading: false,
        value: {
          ...state[SliceNameTypes.ManageRoles].value,
          roles: [
            ...state[SliceNameTypes.ManageRoles].value.roles,
            payload.newRole,
          ],
        },
      },
    };
  },
  [types.UPDATE_MANAGE_ROLES_SUCCESS](state, { payload }) {
    return {
      ...state,
      [SliceNameTypes.ManageRoles]: {
        ...state[SliceNameTypes.ManageRoles],
        isLoaded: true,
        isLoading: false,
        value: {
          ...state[SliceNameTypes.ManageRoles].value,
          roles: state[SliceNameTypes.ManageRoles].value?.roles?.map((o) => (o._id === payload.updatedRole._id ? { ...o, ...payload.updatedRole } : o)),
        },
      },
    };
  },
  [types.DELETE_MANAGE_ROLES_SUCCESS](state, { payload }) {
    return {
      ...state,
      [SliceNameTypes.ManageRoles]: {
        ...state[SliceNameTypes.ManageRoles],
        isLoaded: true,
        isLoading: false,
        value: {
          ...state[SliceNameTypes.ManageRoles].value,
          roles: state[SliceNameTypes.ManageRoles].value?.roles?.filter((o) => o._id !== payload.deletedRole._id),
        },
      },
    };
  },
});
