import React, { useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { BoxBordered } from 'apps/ui';
import { useFormatMessage } from 'apps/intl';
import { FixedSizeTree, treeWalker } from 'lib/FixedSizeTree';
import { useOverlay } from 'apps/overlay';
import { useStyles, StyledButtonBase } from './SanctionModalSelect.style';
import { SanctionListItem } from '../SanctionListItem/SanctionListItem';
import { SanctionTypes } from '../../models/SanctionListModal.model';

export function SanctionModalSelect({ onSubmit, selectedValues }: {
    onSubmit: (data: string[]) => void;
    selectedValues: string[];
}) {
  const classes = useStyles();
  const [, closeOverlay] = useOverlay();
  const formatMessage = useFormatMessage();
  const [selectedSanctions, setSelectedSanctions] = useState<string[]>(selectedValues);
  const tree: { id: string; label: SanctionTypes }[] = useMemo(() => Object.entries(SanctionTypes).map(([id, label]) => ({ id, label })), []);
  const handleTreeWalker = useCallback((refresh: boolean) => treeWalker(refresh, tree, () => false), [tree]);
  const handleSelect = useCallback((id: string, checked: boolean) => {
    if (checked) {
      setSelectedSanctions([...selectedSanctions, id]);
      return;
    }
    setSelectedSanctions(selectedSanctions.filter((sanction) => sanction !== id));
  }, [selectedSanctions]);
  const handleSelectAll = useCallback(() => setSelectedSanctions(Object.keys(SanctionTypes)), []);
  const handleUnselectAll = useCallback(() => setSelectedSanctions([]), []);
  const handleSubmit = useCallback(() => {
    closeOverlay();
    onSubmit(selectedSanctions);
  }, [closeOverlay, onSubmit, selectedSanctions]);
  const listItemData = useMemo(() => ({
    handleSelect,
    selectedSanctions,
  }), [handleSelect, selectedSanctions]);

  return (
    <>
      <Box className={classes.headerControls}>
        <Box mr={0.4}>
          <StyledButtonBase disableRipple onClick={handleSelectAll}>{formatMessage('SanctionModalSelect.selectAll')}</StyledButtonBase>
        </Box>
        <Box>
          <StyledButtonBase disableRipple onClick={handleUnselectAll}>{formatMessage('SanctionModalSelect.deselectAll')}</StyledButtonBase>
        </Box>
      </Box>
      <BoxBordered mb={2} className={classes.formBox}>
        <List className={classes.tree}>
          <FixedSizeTree
            treeWalker={handleTreeWalker}
            itemSize={30}
            height={340}
            width="100%"
            itemData={listItemData}
          >
            {SanctionListItem}
          </FixedSizeTree>
        </List>
      </BoxBordered>
      <Box className={classes.footer}>
        <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
          {formatMessage('SanctionModalSelect.submit')}
        </Button>
      </Box>
    </>
  );
}
