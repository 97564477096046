import { useCallback } from 'react';
import { trackingManager } from 'apps/Tracking';

export function useTrackClickEvent() {
  const trackClick = useCallback((name, payload?) => {
    trackingManager.trackClick(name, payload);
  }, []);

  return trackClick;
}
