import { EmailCheckSettingTypes } from 'apps/EmailCheck/models/EmailCheck.model';
import { EmailCheck } from 'apps/EmailCheck/services/EmailCheck.service';
import { BankAccountData } from 'apps/BankAccountData/services/BankAccountData.service';
import { CustomFieldService } from 'apps/CustomField/services/CustomField.service';
import { IFlow } from 'models/Flow.model';
import { ProductTypes } from 'models/Product.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { ProductManagerService } from '../services/ProductManager.service';

const adatptedProductServiceManager = new ProductManagerService();
adatptedProductServiceManager.register(new EmailCheck({ disabled: false }));
adatptedProductServiceManager.register(new BankAccountData());
adatptedProductServiceManager.register(new CustomFieldService());

export const isCompatibilityMode = (productId: string): boolean => Boolean(adatptedProductServiceManager.getProduct(productId as ProductTypes));

export const onAdd = (productId: string, flow: IFlow): Partial<IFlow> => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return null;
  }

  return product.onAdd();
};

export const onRemove = (productId: string, flow: IFlow): Partial<IFlow> => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return null;
  }

  return product.onRemove(flow);
};

export const parse = (productId: string, flow: IFlow): any => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return null;
  }

  if (productId === ProductTypes.EmailCheck) {
    return {
      [EmailCheckSettingTypes.CompanyName]: flow?.emailOwnership?.companyName,
      [EmailCheckSettingTypes.EmailFrom]: flow?.emailOwnership?.emailFrom ?? '',
      [EmailCheckSettingTypes.EmailRiskThreshold]: flow?.emailRiskThreshold,
      [EmailCheckSettingTypes.EmailOwnershipValidation]: flow?.verificationPatterns?.[VerificationPatternTypes.EmailOwnershipValidation],
      [EmailCheckSettingTypes.EmailRiskValidation]: flow?.verificationPatterns?.[VerificationPatternTypes.EmailRiskValidation],
    };
  }

  return product.parser(flow);
};

export const serialize = (productId: string, settings: any): Partial<IFlow> => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return null;
  }

  if (productId === ProductTypes.EmailCheck) {
    return {
      emailOwnership: {
        [EmailCheckSettingTypes.CompanyName]: settings[EmailCheckSettingTypes.CompanyName],
        [EmailCheckSettingTypes.EmailFrom]: settings[EmailCheckSettingTypes.EmailFrom],
      },
      emailRiskThreshold: settings[EmailCheckSettingTypes.EmailRiskThreshold],
      verificationPatterns: {
        [VerificationPatternTypes.EmailOwnershipValidation]: settings[EmailCheckSettingTypes.EmailOwnershipValidation],
        [VerificationPatternTypes.EmailRiskValidation]: settings[EmailCheckSettingTypes.EmailRiskValidation],
      },
    };
  }

  return product.serialize(settings);
};

export const isInFlow = (productId: string, flow: IFlow): boolean => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return false;
  }

  return product.isInFlow(flow);
};

export const getVerification = (productId: string, verification: VerificationResponse): any => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return null;
  }

  return product.getVerification(verification);
};

export const hasFailedCheck = (productId: string, verification: VerificationResponse): boolean => {
  const product = adatptedProductServiceManager.getProduct(productId as ProductTypes);
  if (!product) {
    return null;
  }

  return product.hasFailedCheck(verification);
};
