import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  crossIcon: {
    flexShrink: 0,
    marginRight: '10px',
  },
  crossIconDanger: {
    '& > path': {
      stroke: theme.palette.common.red,
    },
  },
  crossIconWarning: {
    '& > path': {
      stroke: theme.palette.common.yellow,
    },
  },
  detailsContent: {
    padding: '0 15px 0 38px',
    marginTop: '14px',
  },
  warningIcon: {
    flexShrink: 0,
    width: '17px',
    height: '17px',
  },
}));
