import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription } from 'apps/ui';
import { CountryCodeTypes } from 'models/Country.model';
import React, { useMemo } from 'react';
import { getPatternNameFromConfig, GroupedPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { GovernmentCheckSettingTypes, ProductSettingsGovCheck } from '../../models/GovCheckMerit.model';
import { GovCheckMeritPanel } from '../GovCheckMeritPanel/GovCheckMeritPanel';
import { useStyles } from './GovCheckExternalDB.styles';

export function GovCheckExternalDB({ settings, patternsConfigGroupedByCountry, handleOpenModal, handleUpdate }: {
  handleUpdate: (newData: {settingId: GovernmentCheckSettingTypes; value: unknown}[]) => void;
  patternsConfigGroupedByCountry: GroupedPatternsConfigType;
  handleOpenModal: (country?: CountryCodeTypes) => void;
  settings: ProductSettingsGovCheck;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const content = useMemo(() => Object.entries(patternsConfigGroupedByCountry).map(([country, verificationPatternsConfig]) => {
    const selectedVerificationPatternsConfig = verificationPatternsConfig
      .filter((patternConfig) => patternConfig[getPatternNameFromConfig(patternConfig)].isActive);
    if (!selectedVerificationPatternsConfig.length) {
      return null;
    }
    return <GovCheckMeritPanel settings={settings} handleUpdate={handleUpdate} handleOpenModal={handleOpenModal} key={country} country={country as CountryCodeTypes} selectedVerificationPatternsConfig={selectedVerificationPatternsConfig} />;
  }), [handleOpenModal, handleUpdate, patternsConfigGroupedByCountry, settings]);
  return (
    <Box mb={2}>
      <ExtendedDescription
        title={formatMessage('GovCheckMerit.settings.externalDB.title')}
        text={formatMessage('GovCheckMerit.settings.externalDB.description')}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={classes.list}
        >
          {content}
        </Box>
      </ExtendedDescription>
    </Box>
  );
}
