import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.common.darkgrey,
    backgroundColor: theme.palette.common.whiteblue,
    borderRadius: '8px',
  },
  text: {
    ...theme.typography.body1,
    color: theme.palette.common.darkgrey,
  },
  paddingLeft: {
    paddingLeft: '16px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    padding: '0 16px',
    width: '100%',
  },
  sms: {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    paddingLeft: '20px',
    height: '50px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    top: '-5px',
  },
  smsBefore: {
    content: '',
    position: 'absolute',
    top: '50%', /* Position the triangle vertically centered */
    left: '-20px', /* Adjust the distance of the triangle from the left side */
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderRight: '10px solid #fff',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid transparent',
    transform: 'translateY(-50%)', /* Center the triangle vertically */
  },
  padding: {
    padding: '8px',
    paddingLeft: '0',
  },
}));
