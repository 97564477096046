import { createTypesSequences } from 'state/store.utils';
import { IFilter } from 'models/Filter.model';
import { Loadable } from 'models/Loadable.model';
import { VerificationHistoryEventTypes } from 'models/History.model';
import { IUser } from 'models/Collaborator.model';
import { IdentityId } from 'models/Identity.model';
import { VerificationId } from 'models/VerificationOld.model';
import { IdentityStatuses } from 'models/Status.model';
import { DocumentTypes } from 'models/Document.model';
import { IVerificationStep } from '../model/verificationHistory.model';

export const VERIFICATION_HISTORY_STORE_KEY = 'verificationHistory';

export enum SliceNameTypes {
  Filter = 'filter',
  Changes = 'changes',
  Count = 'count',
  Steps = 'steps',
}

export interface IStatusUpdatedEventBody {
  verificationStatus: {
    previousValue: IdentityStatuses;
    value: IdentityStatuses;
  };
}

export type IDocumentFields = Record<string, {previousValue: string; value: string}>

export interface IDocumentFieldsUpdatesEventBody {
  documentIndex: number;
  documentType: DocumentTypes;
  documentFields: IDocumentFields;
}

export interface IHistoryVerificationChange {
  verificationId: VerificationId;
  identityId: IdentityId;
  agentNote: string | null;
  eventType: VerificationHistoryEventTypes;
  updatedAt: string;
  updatedBy: IUser;
  eventBody?: Nullable<IStatusUpdatedEventBody | IDocumentFieldsUpdatesEventBody>;
  __v: number;
  _id: string;
}
export interface IVerificationHistoryStore {
  [SliceNameTypes.Filter]: Nullable<IFilter>;
  [SliceNameTypes.Changes]: Loadable<IHistoryVerificationChange[]>;
  [SliceNameTypes.Count]: number;
  [SliceNameTypes.Steps]: Loadable<IVerificationStep[]>;
}

export const types = {
  ...createTypesSequences(SliceNameTypes),
  VERIFICATION_CHANGE_AGENT_NOTE_REQUEST: 'VERIFICATION_CHANGE_AGENT_NOTE_REQUEST',
  VERIFICATION_CHANGE_AGENT_NOTE_SUCCESS: 'VERIFICATION_CHANGE_AGENT_NOTE_SUCESS',
  VERIFICATION_CHANGE_AGENT_NOTE_FAILURE: 'VERIFICATION_CHANGE_AGENT_NOTE_FAILURE',
  FILTER_UPDATE: 'verificationHistory/FILTER_UPDATE',
  VERIFICATION_HISTORY_COUNT_LOAD: 'VERIFICATION_HISTORY_COUNT_LOAD',
};
