import { FormatMessage } from 'apps/intl';
import { camelCase } from 'lib/string';
import { WorldPartsBackendTypes, CountryCode } from './CountryAndWorldPart.model';

export interface ICountryAndWorldPart {
  worldPart: WorldPartsBackendTypes;
  countryCode: CountryCode;
}
export interface ICountryPart {
  id: CountryCode;
  label: string;
}

export interface ICountryAndWorldPartState {
  countryAndWorldPart: ICountryAndWorldPart;
  checked: boolean;
}

export interface ITree{
  id: WorldPartsBackendTypes | CountryCode;
  label: string;
  children?: ITree[];
}

export type SelectItemHandler = {
  (checked: boolean, id: string): void;
}

export type IsItemCheckedGetter = {
  (id: string): boolean;
}

export interface IListItemData {
  handleSelect: SelectItemHandler;
  getIsChecked: IsItemCheckedGetter;
}

export function getInitialCountryAndWorldPartState(possibleValues: ICountryAndWorldPart[], initialValues: ICountryAndWorldPart[] = []): ICountryAndWorldPartState[] {
  return possibleValues.map<ICountryAndWorldPartState>((countryAndWorldPart) => ({
    countryAndWorldPart,
    checked: initialValues.some((initialValue) => initialValue.countryCode === countryAndWorldPart.countryCode && initialValue.countryCode === countryAndWorldPart.countryCode),
  }));
}

export function setCountryOrWorldPartChecked(countryAndWorldPartStates: ICountryAndWorldPartState[], id: WorldPartsBackendTypes | CountryCode, checked: boolean): ICountryAndWorldPartState[] {
  // checking world part
  if (Object.values(WorldPartsBackendTypes).includes(id as WorldPartsBackendTypes)) {
    return countryAndWorldPartStates.map<ICountryAndWorldPartState>((countryAndWorldPartState) => (countryAndWorldPartState.countryAndWorldPart.worldPart === id ? {
      ...countryAndWorldPartState,
      checked,
    } : countryAndWorldPartState));
  }
  // checking country
  const result: ICountryAndWorldPartState[] = [...countryAndWorldPartStates];
  const index: number = result.findIndex((countryAndWorldPartState) => countryAndWorldPartState.countryAndWorldPart.countryCode === id);
  if (index === -1) {
    return countryAndWorldPartStates;
  }
  result[index].checked = checked;
  return result;
}

export function getIsCountryOrWorldPartChecked(countryAndWorldPartStates: ICountryAndWorldPartState[], id: WorldPartsBackendTypes | CountryCode): boolean {
  // is world part checked
  if (Object.values(WorldPartsBackendTypes).includes(id as WorldPartsBackendTypes)) {
    return countryAndWorldPartStates.every((state) => state.countryAndWorldPart.worldPart !== id || state.checked);
  }
  // is country checked
  return countryAndWorldPartStates.some((state) => state.countryAndWorldPart.countryCode === id && state.checked);
}

export function createTrees(countryAndWorldParts: ICountryAndWorldPart[], formatMessage: FormatMessage): ITree[] {
  const availableWorldParts: WorldPartsBackendTypes[] = Array.from(new Set(countryAndWorldParts.map<WorldPartsBackendTypes>((countryAndWorldPart) => countryAndWorldPart.worldPart)));

  return availableWorldParts.map<ITree>((worldPart) => ({
    id: worldPart,
    label: formatMessage(`WorldPart.${camelCase(worldPart)}`).toUpperCase(),
    children: countryAndWorldParts.filter((countryAndWorldPart) => countryAndWorldPart.worldPart === worldPart)
      .map((countryAndWorldPart) => ({
        id: countryAndWorldPart.countryCode,
        label: formatMessage(`Countries.${countryAndWorldPart.countryCode}`),
      }))
      .sort((a, b) => (a.label.localeCompare(b.label))),
  }))
    .sort((a, b) => (a.label.localeCompare(b.label)));
}
