import Box from '@material-ui/core/Box';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { copyToClipboard } from 'lib/copyToClipboard';
import { notification } from '../Notification/Notification';
import { useStyles } from './CopyToClipboard.styles';
import { Button, Icon } from 'lib/UI';

export function CopyToClipboard({ children, text, isOverlay = false, withCopyText = false, withCopy = true, qa }: {
  children: React.ReactNode;
  text: string;
  isOverlay?: boolean;
  withCopy?: boolean;
  withCopyText?: boolean;
  qa?: string;
}) {
  const classes = useStyles();
  const intl = useIntl();
  const handleClick = useCallback(() => {
    copyToClipboard(text);
    notification.info(intl.formatMessage({ id: 'copied' }));
  }, [text, intl]);

  return (
    <Box className={classes.root}>
      <Box flexGrow="0" className={isOverlay ? classes.overlayedText : classes.text}>
        {children}
      </Box>
      {text && (
        <Box ml="0.5rem" flexGrow="0" className={isOverlay ? classes.overlayed : null}>
          {withCopy && (
          <Button
            size="large"
            onClick={handleClick}
            data-qa={qa}
            type="secondary"
          >
            <Icon name="copy" />
            {withCopyText && intl.formatMessage({ id: 'copy' })}
          </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
