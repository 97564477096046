import React from 'react';
import { CheckBarExpandable } from 'apps/checks';
import { StepStatus } from 'models/Step.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useFormatMessage } from 'apps/intl';
import { PremiumAmlWatchlistsStepDetails } from '../PremiumAmlWatchlistsStepDetails/PremiumAmlWatchlistsStepDetails';
import { getPremiumAmlMatchType, ILastMonitoredTimestamps, IPremiumWatchlistData } from '../../../../models/Aml.model';
import { useStyles } from './PremiumWatchlists.styles';

export const PremiumWatchlists = ({
  watchlists,
  errorCode,
  status,
  isAmlMonitoringAvailable,
  lastMonitoredTimestamps,
}:
  { watchlists: IPremiumWatchlistData[]; errorCode?: string; status: StepStatus; isAmlMonitoringAvailable: boolean; lastMonitoredTimestamps: ILastMonitoredTimestamps[] }) => {
  const hasErrors = !watchlists || watchlists?.some((stepWatchlist) => !!stepWatchlist.error || stepWatchlist.matchFound);
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <CheckBarExpandable
      step={{
        id: VerificationPatternTypes.PremiumAmlWatchListsValidation,
        checkStatus: status,
      }}
      isError={hasErrors}
    >
      <>
        {!watchlists?.length && hasErrors && (
          <Card className={classes.card}>
            <CardContent>
              <Box mt={0.5} mb={1.5}>
                {formatMessage(status === StepStatus.Failure
                  ? `SecurityCheckStep.${errorCode}.message`
                  : 'SecurityCheckStep.premiumAmlWatchlists.incomplete')}
              </Box>
            </CardContent>
          </Card>
        )}
        {watchlists?.map(({
          birthYear,
          countriesSearched,
          error,
          monitored,
          nameSearched,
          originalMatchStatus,
          profileUrl,
          searchId,
        }, idx) => (
          <PremiumAmlWatchlistsStepDetails
            key={idx}
            name={nameSearched}
            birthYear={birthYear}
            countries={countriesSearched?.join(', ')}
            profileUrl={profileUrl}
            searchId={searchId}
            status={status}
            isMonitored={monitored}
            isMonitoringAvailable={isAmlMonitoringAvailable}
            matchType={getPremiumAmlMatchType(originalMatchStatus)}
            errorCode={error?.code}
            lastMonitoredTimestamps={lastMonitoredTimestamps}
          />
        ))}
      </>
    </CheckBarExpandable>
  );
};
