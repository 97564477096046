import React from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { FixedSizeNodeComponentProps, ITreeWalkerNodeData } from 'lib/FixedSizeTree';
import { useStyles, StyledCheckbox } from './SanctionListItem.style';
import { ITree, ITreeData } from '../../models/SanctionListModal.model';

interface ISanctionModalSelectItemProps extends FixedSizeNodeComponentProps<ITreeWalkerNodeData<ITree>> {
  treeData?: ITreeData;
}

export const SanctionListItem = ({
  style,
  data,
  treeData,
}: ISanctionModalSelectItemProps) => {
  const { handleSelect } = treeData;
  const { id, label } = data.item;
  const classes = useStyles();

  return (
    <ListItem style={style} className={classNames(classes.listItem)}>
      <FormLabel
        className={classNames(classes.listName)}
        htmlFor="name"
      >
        {label}
      </FormLabel>
      <Box ml={0.5}>
        <FormControl>
          <StyledCheckbox
            disableRipple
            onChange={(_, checked) => { handleSelect(id, checked); }}
            checked={treeData.selectedSanctions.includes(id)}
            color="primary"
            checkedIcon={<CheckboxOn />}
            icon={<CheckboxOff />}
          />
        </FormControl>
      </Box>
    </ListItem>
  );
};
