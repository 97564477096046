import { useScrollMeIntoView } from 'lib/ScrollIntoView';
import React, { useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { ExtendedDescription, TimeoutSettings } from 'apps/ui';
import { ProductSettingsProps } from 'models/Product.model';
import cloneDeep from 'lodash/cloneDeep';
import { BackgroundCheckSettingTypes } from 'models/BackgroundCheck.model';
import { useFormatMessage } from 'apps/intl';
import { useSelector } from 'react-redux';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant';
import { postPhaseTimeoutConfig } from '../../models/BackgroundCheck.model';
import { BackgroundCheckCountriesSettings } from '../BackgroundCheckCountriesSettings/BackgroundCheckCountriesSettings';

export function BackgroundCheckSettings({
  settings,
  onUpdate,
}: ProductSettingsProps) {
  const formatMessage = useFormatMessage();
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const canUseLegacyBackgroundChecks = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseLegacyBackgroundChecks)), [merchantTags]);
  const ScrollMeIntoView = useScrollMeIntoView();

  const handleUpdate = useCallback((settingId: BackgroundCheckSettingTypes) => (newValue: Record<string, unknown> | string) => {
    const newSettings = cloneDeep(settings);
    if (settingId === BackgroundCheckSettingTypes.BackgroundChecksSetting) {
      newSettings[settingId].value = { ...newSettings[settingId].value, ...(newValue as Record<string, unknown>) };
    }
    if (settingId === BackgroundCheckSettingTypes.PostResultPhaseTimeout) {
      newSettings[settingId].value = newValue;
    }

    onUpdate(newSettings);
  }, [onUpdate, settings]);

  return (
    <Box>
      {!canUseLegacyBackgroundChecks && (
        <>
          <Box mb={4}>
            <ExtendedDescription
              title={formatMessage('BackgroundCheck.card.timeout.title')}
              text={formatMessage('BackgroundCheck.card.timeout.description')}
            >
              <TimeoutSettings
                onChange={handleUpdate(BackgroundCheckSettingTypes.PostResultPhaseTimeout)}
                postponedTimeout={settings[BackgroundCheckSettingTypes.PostResultPhaseTimeout]?.value}
                defaultTimeout={postPhaseTimeoutConfig}
              />
            </ExtendedDescription>
          </Box>
          <Box mb={1}>
            <ExtendedDescription
              title={formatMessage('BackgroundCheck.card.check.condition.title')}
              text={formatMessage('BackgroundCheck.card.check.condition.subTitle')}
            />
          </Box>
        </>
      )}

      {settings[BackgroundCheckSettingTypes.BackgroundChecksSetting]?.error && (
        <ScrollMeIntoView />
      )}
      <ExtendedDescription
        title={formatMessage('BackgroundCheck.card.check.availableCountries.title')}
        text={formatMessage('BackgroundCheck.card.check.availableCountries.subTitle')}
        error={settings[BackgroundCheckSettingTypes.BackgroundChecksSetting]?.error && formatMessage(settings[BackgroundCheckSettingTypes.BackgroundChecksSetting].error)}
      >
        <BackgroundCheckCountriesSettings
          verificationPattern={settings[BackgroundCheckSettingTypes.BackgroundChecksSetting]?.value}
          onChange={handleUpdate(BackgroundCheckSettingTypes.BackgroundChecksSetting)}
        />
      </ExtendedDescription>
    </Box>
  );
}
