import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 400,
    overflow: 'auto',
    padding: '16px',
    display: 'flex',
    flexFlow: 'column',
    gap: '19px 0',
  },
  button: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  deleteButton: {
    color: theme.palette.common.red,
    textDecorationLine: 'underline',
  },
  crossIcon: {
    flexShrink: 0,
    '& > path': {
      stroke: theme.palette.common.black75,
    },
  },
  btnClose: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 0,
  },
}));
