import { Loadable } from 'models/Loadable.model';
import { createTypesSequences, TypesSequences } from 'state/store.utils';
import { ICountryAndWorldPartMetadata } from '../models/CountryAndWorldPart.model';

export const COUNTRY_AND_WORLD_PART_STORE_KEY = 'countriesAndWorldPart';

export enum SliceNameTypes {
  CountryAndWorldParts = 'countryAndWorldParts',
}

export interface ICountryAndWorldPartStore {
  [SliceNameTypes.CountryAndWorldParts]: Loadable<ICountryAndWorldPartMetadata[]>;
}

export const types: TypesSequences<typeof SliceNameTypes> = {
  ...createTypesSequences(SliceNameTypes),
};
