import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import cloneDeep from 'lodash/cloneDeep';
import { QATags } from 'models/QA.model';
import React, { useMemo } from 'react';
import { useFormatMessage } from 'apps/intl';
import CustomColorPicker from '../CustomColorPicker/CustomColorPicker';
import { IAppearance, CustomizablePropertiesTypes, DefaultCustomizationColors } from 'models/Flow.model';

function SDKColorItem({ dataQa, label, value, defaultValue, handleChange, isBottomElement = false }: {
  dataQa: string;
  label: string;
  value: string;
  defaultValue: string;
  handleChange: (param: string) => void;
  isBottomElement?: boolean;
}) {
  return (
    <>
      <InputLabel
        id="label"
      >
        {label}
      </InputLabel>
      <CustomColorPicker
        isBottomElement={isBottomElement}
        dataQa={dataQa}
        colorHexCode={value ?? defaultValue}
        handleChangeColor={handleChange}
      />
    </>
  );
}

export function ColorCustomization({ appearance, onChange }: {
  appearance: IAppearance,
  onChange: (appearance: IAppearance) => void;
}) {
  const formatMessage = useFormatMessage();
  const values = useMemo(() => appearance?.properties?.reduce((result, property) => ({
    ...result,
    [property.name]: property.value,
  }), {}) || {}, [appearance]);

  const handleOnChange = (name: string) => (value: string) => {
    const updated = cloneDeep(appearance);
    if (!updated.properties) {
      updated.properties = [];
    }

    const property = updated.properties.find((item) => item.name === name);
    if (!property) {
      updated.properties.push({
        name,
        value,
      });
    } else {
      property.value = value;
    }

    onChange(updated);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="8px"
      mt={1}
    >
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.accentColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.AccentColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.AccentColor')}
        value={values[CustomizablePropertiesTypes.AccentColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.AccentColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.errorColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.ErrorColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.ErrorColor')}
        value={values[CustomizablePropertiesTypes.ErrorColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.ErrorColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.warningColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.WarningColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.WarningColor')}
        value={values[CustomizablePropertiesTypes.WarningColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.WarningColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.successColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.SuccessColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.SuccessColor')}
        value={values[CustomizablePropertiesTypes.SuccessColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.SuccessColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.backgroundAltColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.BackgroundAltColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.BackgroundAltColor')}
        value={values[CustomizablePropertiesTypes.BackgroundAltColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.BackgroundAltColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.bodyTextColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.BodyTextColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.BodyTextColor')}
        value={values[CustomizablePropertiesTypes.BodyTextColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.BodyTextColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.titleTextColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.TitleTextColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.TitleTextColor')}
        value={values[CustomizablePropertiesTypes.TitleTextColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.TitleTextColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.subtitleTextColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.SubtitleTextColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.SubtitleTextColor')}
        value={values[CustomizablePropertiesTypes.SubtitleTextColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.SubtitleTextColor]}
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.labelTextColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.LabelTextColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.LabelTextColor')}
        value={values[CustomizablePropertiesTypes.LabelTextColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.LabelTextColor]}
        isBottomElement
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.lineColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.LineColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.LineColor')}
        value={values[CustomizablePropertiesTypes.LineColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.LineColor]}
        isBottomElement
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.borderColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.BorderColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.BorderColor')}
        value={values[CustomizablePropertiesTypes.BorderColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.BorderColor]}
        isBottomElement
      />
      <SDKColorItem
        dataQa={QATags.FlowBuilder.PreviewModal.SDKCustomisationForm.label.backgroundColor}
        handleChange={handleOnChange(CustomizablePropertiesTypes.BackgroundColor)}
        label={formatMessage('FlowBuilder.previewModal.SDKCustomisationForm.label.BackgroundColor')}
        value={values[CustomizablePropertiesTypes.BackgroundColor]}
        defaultValue={DefaultCustomizationColors[CustomizablePropertiesTypes.BackgroundColor]}
        isBottomElement
      />
    </Box>
  );
}
