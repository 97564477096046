import Switch from '@material-ui/core/Switch';
import { useFormatMessage } from 'apps/intl';
import { ExtendedDescription, RangeSlider } from 'apps/ui';
import React from 'react';

export function GovCheckFullNameMatch({ maxFuzzyNameMatch, isFullNameMatchEnabled, currentFuzzyNameMatch, handleTogglesFullNameMatch, handleChangesFullNameMatch }: {
  maxFuzzyNameMatch: number;
  isFullNameMatchEnabled: boolean;
  currentFuzzyNameMatch: number;
  handleTogglesFullNameMatch: () => void;
  handleChangesFullNameMatch: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
}) {
  const formatMessage = useFormatMessage();

  return (
    <ExtendedDescription
      title={formatMessage('GovCheckMerit.settings.fullName.title')}
      text={formatMessage('GovCheckMerit.settings.fullName.description')}
      postfix={(
        <Switch
          checked={isFullNameMatchEnabled}
          onChange={handleTogglesFullNameMatch}
          color="primary"
        />
      )}
    >
      {isFullNameMatchEnabled && (
        <RangeSlider
          step={1}
          isShowOptimal={false}
          value={currentFuzzyNameMatch}
          max={maxFuzzyNameMatch}
          onChange={handleChangesFullNameMatch}
        />
      )}
    </ExtendedDescription>
  );
}
