import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { FiTrash2 } from 'react-icons/fi';
import { useFormatMessage } from 'apps/intl';
import { ISelectOptions } from 'models/CustomField.model';
import { TextFieldInput } from '../../CustomFieldModalConfigureAtomic.styles';
import { updateCustomFieldEditedSelectOptions } from '../../../../state/CustomField.actions';
import { EMPTY_OPTION, IDefaultValues, isValidFieldSystemName, MIN_SELECT_OPTIONS } from '../../../../models/CustomField.model';
import { selectCustomFieldEditedSelectOptions } from '../../../../state/CustomField.selectors';
import { useStyles } from './CustomFieldSelectionOptions.style';

export function CustomFieldSelectionOptions({ indexOfDefault, setDefaults }: {
  indexOfDefault: number;
  setDefaults: Dispatch<SetStateAction<IDefaultValues>>;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectOptions = useSelector<any, ISelectOptions[]>(selectCustomFieldEditedSelectOptions);

  const handleCaptionUpdate = (index: number) => ({ target: { value, name } }) => {
    const clone = cloneDeep(selectOptions);
    clone[index][name] = value;
    dispatch(updateCustomFieldEditedSelectOptions(clone));
  };

  const handleSelectOptionAdd = () => {
    dispatch(updateCustomFieldEditedSelectOptions([...selectOptions, { ...EMPTY_OPTION }]));
  };

  const setIndexOfDefaultOption = useCallback((value: Nullable<number>) => setDefaults((prev) => ({ ...prev, index: value })), [setDefaults]);

  const handleSelectOptionRemove = (index: number) => () => {
    const clone = cloneDeep(selectOptions);
    clone.splice(index, 1);
    dispatch(updateCustomFieldEditedSelectOptions(clone));
    if (index === indexOfDefault) {
      setIndexOfDefaultOption(null);
    }
  };

  const handleChangeDefault = ({ target: { value: index } }) => {
    setIndexOfDefaultOption(Number(index));
  };

  const handleClickOption = ({ target }) => {
    if (Number(target.value) === indexOfDefault) {
      setIndexOfDefaultOption(null);
    }
  };

  const handleUpdateSystemName = (index: number) => ({ target: { value, name } }) => {
    if (!isValidFieldSystemName(value)) {
      return;
    }
    const clone = cloneDeep(selectOptions);
    clone[index][name] = value;
    dispatch(updateCustomFieldEditedSelectOptions(clone));
  };

  return (
    <Grid item container spacing={1} direction="column">
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle2">
            {formatMessage('CustomField.settings.selectionOptions')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} justifyContent="flex-end">
        <Grid item xs={6}>
          <Typography variant="body1" color="textSecondary">
            {formatMessage('CustomField.settings.selectionOptions.displayName')}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1" color="textSecondary">
            {formatMessage('CustomField.settings.selectionOptions.systemName')}
          </Typography>
        </Grid>
      </Grid>
      {
        selectOptions.map((option, index) => (
          <React.Fragment key={index}>
            <Grid item container spacing={1} justifyContent="space-between">
              <Grid item xs={1}>
                <Radio
                  onClick={handleClickOption}
                  onChange={handleChangeDefault}
                  name="default_value"
                  value={index}
                  checked={index === indexOfDefault}
                  color="primary"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldInput
                  fullWidth
                  value={option.label}
                  type="text"
                  name="label"
                  onChange={handleCaptionUpdate(index)}
                  placeholder={formatMessage('CustomField.settings.selectionOptions.placeholder')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldInput
                  fullWidth
                  value={option.value}
                  type="text"
                  name="value"
                  onChange={handleUpdateSystemName(index)}
                  placeholder={formatMessage('CustomField.settings.selectionOptions.value.placeholder')}
                />
              </Grid>
              <Grid item xs={1} container justifyContent="center" alignItems="center">
                {selectOptions.length > MIN_SELECT_OPTIONS && (
                  <FiTrash2 onClick={handleSelectOptionRemove(index)} className={classes.remove} />)}
                {selectOptions.length === MIN_SELECT_OPTIONS && (<FiTrash2 className={classes.disabledRemove} />)}
              </Grid>
            </Grid>
            {index === indexOfDefault && (
              <Grid item container justifyContent="flex-end">
                <Grid item xs={11}>
                  <Typography variant="body1" color="textSecondary">
                    {formatMessage('CustomField.settings.selectionOptions.defaultSelection')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        ))
      }
      <Grid item>
        <Button
          variant="text"
          color="primary"
          size="large"
          onClick={handleSelectOptionAdd}
        >
          {formatMessage('CustomField.settings.selectionOptions.addBtn')}
        </Button>
      </Grid>
    </Grid>
  );
}
