import Box from '@material-ui/core/Box';
import { flowBuilderProductAdd } from 'apps/flowBuilder';
import { Modal, useOverlay } from 'apps/overlay';
import { useFreemium } from 'apps/Freemiun';
import { SubscriptionStatusTypes } from 'apps/SubscriptionStatus';
import { sanitize } from 'dompurify';
import { ProductMessageTypes, ProductTypes } from 'models/Product.model';
import { SupportedLocales, SupportedLocalesToLocaleAsPopup } from 'models/Intl.model';
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectMerchantSubscriptionStatusValue, selectLanguage, selectMerchantSettings } from 'state/merchant';
import { FreshChatWidget } from 'apps/freshdesk';
import { useStyles } from './ProductExternalSettingsContainer.styles';
import { MessageClient } from '../../services/MessageClient';
import { useOtherProductAdding } from '../../hooks/OtherProductAdding.hook';

const MIN_HEIGHT = 600;

export function ProductExternalSettingsContainer({ settings, onUpdate, disabled }) {
  const dispatch = useDispatch();
  const currentLang = useSelector<any, SupportedLocales>(selectLanguage);
  const settingsMessageClient = useRef<Nullable<MessageClient>>(null);
  const overlayMessageClient = useRef<Nullable<MessageClient>>(null);
  const frame = useRef(null);
  const styles = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();
  const [settingsHeight, setSettingsHeight] = useState(MIN_HEIGHT);
  const [overlayHeight, setOverlayHeight] = useState(MIN_HEIGHT);
  const merchantSettings = useSelector(selectMerchantSettings);
  const createAddOtherProductModalOverlay = useOtherProductAdding();
  const subscriptionStatusValue = useSelector(selectMerchantSubscriptionStatusValue);
  const { comparePlans } = useFreemium();

  const handleAddProduct = useCallback(({ productId, message }: { productId: ProductTypes; message: string }) => {
    createAddOtherProductModalOverlay(
      productId,
      <Box component="span">{sanitize(message)}</Box>,
      (productType) => dispatch(flowBuilderProductAdd(productType)),
    );
  }, [createAddOtherProductModalOverlay, dispatch]);

  const onSupportMessage = useCallback((message: string) => {
    if (subscriptionStatusValue === SubscriptionStatusTypes.Freemium) {
      comparePlans();
    } else {
      FreshChatWidget.createNewMessage(sanitize(message));
    }
  }, [comparePlans, subscriptionStatusValue]);

  const meritSettings = useMemo(() => ({
    ...(settings?.self.value ?? {}),
    externalSettings: settings?.external.value,
    merchantSettings,
  }), [settings?.url?.value, merchantSettings]);

  const fullUrl = useMemo<Nullable<string>>(() => {
    if (!settings?.url?.value) {
      return null;
    }

    const parameters = new URLSearchParams();
    parameters.set('disabled', disabled);
    parameters.set('locale', SupportedLocalesToLocaleAsPopup[currentLang]);

    return `${settings?.url?.value}index.html?${parameters.toString()}`;
  }, [settings?.url?.value, disabled]);

  useEffect(() => {
    if (frame.current) {
      settingsMessageClient.current = new MessageClient(frame.current);
      settingsMessageClient.current.on(ProductMessageTypes.Handshake, () => settingsMessageClient.current.send(ProductMessageTypes.Handshake, meritSettings));
      settingsMessageClient.current.on(ProductMessageTypes.PullSettings, () => settingsMessageClient.current.send(ProductMessageTypes.PullSettings, meritSettings));
      settingsMessageClient.current.on(ProductMessageTypes.PushSettings, (data) => onUpdate({ data }));
      settingsMessageClient.current.on(ProductMessageTypes.PushSettingsTo, onUpdate);
      settingsMessageClient.current.on(ProductMessageTypes.Overlay, (target: string) => {
        const url = new URL(`${settings?.url?.value}${target}`);
        const parameters = new URLSearchParams(url.search);
        parameters.set('disabled', disabled);
        parameters.set('locale', SupportedLocalesToLocaleAsPopup[currentLang]);
        url.search = parameters.toString();

        createOverlay(
          <Modal className={styles.overlay}>
            <iframe
              className={styles.productFrame}
              title="product"
              sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
              height={overlayHeight}
              src={url.toString()}
              ref={(element) => {
                overlayMessageClient.current = new MessageClient(element);
                overlayMessageClient.current.on(ProductMessageTypes.Handshake, () => overlayMessageClient.current.send(ProductMessageTypes.Handshake, meritSettings));
                overlayMessageClient.current.on(ProductMessageTypes.PullSettings, () => overlayMessageClient.current.send(ProductMessageTypes.PullSettings, meritSettings));
                overlayMessageClient.current.on(ProductMessageTypes.PushSettings, (data) => onUpdate({ data }));
                overlayMessageClient.current.on(ProductMessageTypes.PushSettingsTo, onUpdate);
                overlayMessageClient.current.on(ProductMessageTypes.Resize, (payload) => {
                  if (payload?.height) {
                    setOverlayHeight((payload.height > MIN_HEIGHT) ? (payload.height + 100) : MIN_HEIGHT);
                  } else {
                    setOverlayHeight(MIN_HEIGHT);
                  }
                });
                overlayMessageClient.current.on(ProductMessageTypes.AddProduct, handleAddProduct);
                overlayMessageClient.current.on(ProductMessageTypes.ContactSupport, onSupportMessage);
                overlayMessageClient.current.on(ProductMessageTypes.Close, () => {
                  closeOverlay();
                  overlayMessageClient.current.close();
                });
              }}
            />
          </Modal>,
          {
            onClose: () => {
              overlayMessageClient.current.close();
              closeOverlay();
            },
          },
        );
      });
      settingsMessageClient.current.on(ProductMessageTypes.Resize, (payload) => {
        if (payload?.height) {
          setSettingsHeight((payload.height > MIN_HEIGHT) ? (payload.height + 100) : MIN_HEIGHT);
        } else {
          setSettingsHeight(MIN_HEIGHT);
        }
      });
      settingsMessageClient.current.on(ProductMessageTypes.AddProduct, handleAddProduct);
      settingsMessageClient.current.on(ProductMessageTypes.ContactSupport, onSupportMessage);
    }

    return () => {
      if (settingsMessageClient.current) {
        settingsMessageClient.current.close();
      }
    };
  }, [meritSettings, frame, onSupportMessage, handleAddProduct]);

  useEffect(() => {
    if (frame.current) {
      if (settingsMessageClient.current) {
        settingsMessageClient.current.send(ProductMessageTypes.PullSettings, meritSettings);
      }
      if (overlayMessageClient.current) {
        settingsMessageClient.current.send(ProductMessageTypes.PullSettings, meritSettings);
      }
    }
  }, [meritSettings, frame, settingsMessageClient]);

  return (
    <iframe
      className={styles.productFrame}
      title="product"
      height={settingsHeight}
      sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      src={fullUrl}
      ref={frame}
    />
  );
}
