import { getAlterationReason } from 'apps/alterationDetection/models/alterationDetection.model';
import { getFacematchStepExtra } from 'apps/facematch/models/facematch.model';
import { getTemplateMatchingStepExtraData } from 'apps/templateMatching/models/templateMatching.model';
import { isNil } from 'lib/isNil';
import get from 'lodash/get';
import { isCovidTolerance } from 'models/Covid.model';
import { getFieldsExtra } from 'models/Field.model';
import { AmlDocumentStepTypes, getPremiumAmlWatchlistsCheckExtraData, MatchTypes } from 'apps/Aml/models/Aml.model';
import { DocumentTypes } from './Document.model';
import { VerificationPatternTypes } from './VerificationPatterns.model';

export enum StepTypes {
  ProofOfOwnership = 'proof-of-ownership',
  LivenessMovement = 'liveness',
  Selfie = 'selfie',
  // TODO @dkchv: wtf?
  Voice = 'voice',
  LivenessVoice = 'voice+liveness',
  LivenessNist = 'liveness-nist',
  PhoneOwnership = 'phone-ownership-validation',
  PhoneRiskValidation = 'phone-risk-analysis-validation',
  EmailOwnership = 'email-ownership-validation',
  EmailRisk = 'email-risk-validation',
  VideoAgreement = 'video-agreement',
}

// TODO: @anatoliy.turkin make as DocumentStepTypes(?)
export enum VerificationStepTypes {
  EmailOwnershipValidation = 'email-ownership-validation',
  EmailRiskValidation = 'email-risk-validation',
  Liveness = 'liveness',
  Voice = 'voice',
  Selfie = 'selfie',
  LocationIntelligence = 'geolocation',
  PhoneOwnershipValidation = 'phone-ownership-validation',
  PhoneRiskAnalysisValidation = 'phone-risk-analysis-validation',
  PhoneRiskValidation = 'phone-risk-analysis-validation',
  ReFacematch = 're-facematch',
  DuplicateUserDetection = 'duplicate-user-detection',
  BackgroundMexicanBuholegal = 'background-mexican-buholegal-validation',
  BackgroundBrazilianChecks = 'brazilian-background-checks',
  BackgroundBrazilianChecksLight = 'brazilian-background-checks-light',
  BackgroundBrazilianChecksFull = 'brazilian-background-checks-full',
  BackgroundLatinAmericaChecks = 'background-drucker-validation',
  CustomWatchlistsValidation = 'custom-watchlists-validation',
  NigerianCacValidation = 'nigerian-cac-validation',
  NigerianAffiliatesCac = 'nigerian-cac-affiliates-validation',
  NigerianShareholdersCac = 'nigerian-cac-shareholders-validation',
  NigerianLegalValidation = 'nigerian-legal-validation',
  Watchlists = 'watchlists',
  PhilippinianDlValidation = 'philippinian-dl-validation',
  PhilippineDlValidation = 'philippine-dl-validation',
  PhilippineNbiValidation = 'philippine-nbi-validation',
  PhilippinePrcValidation = 'philippine-prc-validation',
  PhilippineUMIDSSNValidation = 'philippine-umid-ssn-validation',
  PhilippinePNPValidation = 'philippine-pnp-validation',
  BasicWatchlistsValidation = 'basic-watchlists-validation',
  NigerianTinValidation = 'nigerian-tin-validation',
  IndonesianKTPValidation = 'indonesian-ktp-validation',
  KenyanBRC = 'kenyan-brs-validation',
  KenyanIprsValidation = 'kenyan-iprs-validation',
  NigerianNin = 'nigerian-nin-validation',
  NigerianVin = 'nigerian-vin-validation',
  NigerianBvn = 'nigerian-bvn-validation',
  NigerianVirtualNin = 'nigerian-vnin-validation',
  MexicanImss = 'mexican-imss-validation',
  NigerianAv = 'nigerian-av-validation',
}

export enum StepStatus {
  Success = 'success',
  Failure = 'failure',
  Incomplete = 'incomplete',
  Checking = 'checking',
  Skipped = 'skipped',
  Default = 'default',
}

export enum StepCodeStatus {
  Pending = 0,
  Running = 100,
  Complete = 200,
}

export const LEGACY_ERROR = 'LegacyError';
export const FRONTEND_ERROR = 'FrontendError';
export const SYSTEM_ERROR = 'SystemError';
export const STEP_ERROR = 'StepError';
export const AD_FRAUD_ATTEMPT = 'alterationDetection.fraudAttempt';
export const AD_NEGLIGENCE = 'alterationDetection.negligence';
export const AD_SUSPICIOUS_PROFILE = 'alterationDetection.suspiciousProfile';
export const DUPLICATE_SELFIE_QUALITY_LIVENESS = 'duplicateSelfieStep.qualityLiveness';
export interface IStepErrorReasons {
  category: string;
  code: string;
  rejectionType: string;
  title: string;
}
export interface IStepErrorDetails {
  reason?: IStepErrorReasons;
}
export interface StepError {
  code: string;
  message?: typeof AD_FRAUD_ATTEMPT | typeof AD_NEGLIGENCE | typeof AD_SUSPICIOUS_PROFILE | string;
  type?: typeof LEGACY_ERROR | typeof FRONTEND_ERROR | typeof SYSTEM_ERROR | typeof STEP_ERROR;
  details?: IStepErrorDetails;
  reasonCode?: string;
}

// TODO: @richvoronov refactor this, figure out with types of steps for documents
export enum VerificationDocStepTypes {
  AgeValidation = 'age-check',
  AgeConsistencyCheck = 'age-consistency-check',
  IpValidation = 'ip-validation',
  DuplicateUserValidation = 'duplicate-user-detection',
}

export enum GovCheckStepTypes {
  None = 'none',
  Cpf = 'cpf',
  CpfFacematch = 'cpf+facematch',
  Renaper = 'renaper',
  RenaperAfip = 'renaper+afip',
  Ktp = 'ktp',
  KtpFacematch = 'ktp+facematch',
}

export enum PremiumLivenessStepTypes {
  AgeVerification = 'ageVerification',
  DigitalSpoof = 'digitalSpoof',
  FaceEvasion = 'faceEvasion',
}

export const DocumentStepTypes = {
  AlternationDetection: 'alteration-detection',
  FaceMatch: 'facematch',
  FruadAssessment: 'fraud-assessment',
  AgeValidation: VerificationPatternTypes.AgeValidation,
  AgeConsistencyCheck: VerificationDocStepTypes.AgeConsistencyCheck,
  BolivianOep: VerificationPatternTypes.BolivianOep,
  DocumentReading: VerificationPatternTypes.DocumentReading,
  TemplateMatching: VerificationPatternTypes.TemplateMatching,
  MexicanCurp: VerificationPatternTypes.MexicanCurp,
  MexicanIne: VerificationPatternTypes.MexicanIne,
  MexicanRfc: VerificationPatternTypes.MexicanRfc,
  BrazilianCpf: VerificationPatternTypes.BrazilianCpf,
  BrazilianCpfLight: VerificationPatternTypes.BrazilianCpfLight,
  BrazilianNoCriminalRecordsValidation: VerificationPatternTypes.BrazilianNoCriminalRecordsValidation,
  CreditArgentinianFidelitas: VerificationPatternTypes.CreditArgentinianFidelitas,
  CreditBrazilianSerasa: VerificationPatternTypes.CreditBrazilianSerasa,
  CreditBrazilianSerasaChecks: VerificationPatternTypes.CreditBrazilianSerasaChecks,
  CreditMexicoCdc: VerificationPatternTypes.CreditMexicoCdc,
  CreditColombiaTransunion: VerificationPatternTypes.CreditColombiaTransunion,
  ChileanRut: VerificationPatternTypes.ChileanRut,
  ChileanDriverLicense: VerificationPatternTypes.ChileanDriverLicense,
  ChileanRegistroCivil: VerificationPatternTypes.ChileanRegistroCivil,
  ColombianBdua: VerificationPatternTypes.ColombianBdua,
  ColombianContraloria: VerificationPatternTypes.ColombianContraloria,
  ColombianMigraciones: VerificationPatternTypes.ColombianMigraciones,
  ColombianNationalPolice: VerificationPatternTypes.ColombianNationalPolice,
  ColombianProcuraduria: VerificationPatternTypes.ColombianProcuraduria,
  ColombianNit: VerificationPatternTypes.ColombianNit,
  ColombianPpt: VerificationPatternTypes.ColombianPpt,
  ColombianRunt: VerificationPatternTypes.ColombianRunt,
  ColombianSisben: VerificationPatternTypes.ColombianSisben,
  ColombianRegistraduriaLight: VerificationPatternTypes.ColombianRegistraduriaLight,
  MexicanPep: VerificationPatternTypes.MexicanPep,
  ColombianRegistraduria: VerificationPatternTypes.ColombianRegistraduria,
  ColombianUnifiedLegalSearch: VerificationPatternTypes.ColombianUnifiedLegalSearch,
  ArgentinianRenaper: VerificationPatternTypes.ArgentinianRenaper,
  ArgentinianRenaperExtended: VerificationPatternTypes.ArgentinianRenaperExtended,
  ArgentinianRenaperFacematch: VerificationPatternTypes.ArgentinianRenaperFacematch,
  EcuadorianSri: VerificationPatternTypes.EcuadorianSri,
  EcuadorianRegistroCivil: VerificationPatternTypes.EcuadorianRegistroCivil,
  EcuadorianSocialSecurity: VerificationPatternTypes.EcuadorianSocialSecurity,
  GhanaianGra: VerificationPatternTypes.GhanaianGra,
  GuatemalanTse: VerificationPatternTypes.GuatemalanTse,
  ParaguayanRcp: VerificationPatternTypes.ParaguayanRcp,
  PeruvianReniec: VerificationPatternTypes.PeruvianReniec,
  PeruvianSunat: VerificationPatternTypes.PeruvianSunat,
  PeruvianMigrationInstitute: VerificationPatternTypes.PeruvianMigrationInstitute,
  PeruvianHealthSocialSecurity: VerificationPatternTypes.PeruvianHealthSocialSecurity,
  CostaRicanAtv: VerificationPatternTypes.CostaRicanAtv,
  CostaRicanTse: VerificationPatternTypes.CostaRicanTse,
  CostaRicanSocialSecurity: VerificationPatternTypes.CostaRicanSocialSecurity,
  ArgentinianDni: VerificationPatternTypes.ArgentinianDni,
  SalvadorianTse: VerificationPatternTypes.SalvadorianTse,
  DominicanJce: VerificationPatternTypes.DominicanJce,
  DominicanRnc: VerificationPatternTypes.DominicanRnc,
  DuplicateUserDetectionCheck: VerificationPatternTypes.DuplicateUserDetection,
  HonduranRnp: VerificationPatternTypes.HonduranRnp,
  PremiumAmlWatchlistsCheck: VerificationPatternTypes.PremiumAmlWatchListsSearchValidation,
  PanamenianTribunalElectoral: VerificationPatternTypes.PanamenianTribunalElectoral,
  VenezuelanCne: VerificationPatternTypes.VenezuelanCne,
  VenezuelanSeniat: VerificationPatternTypes.VenezuelanSeniat,
  ReFacematch: VerificationPatternTypes.ReFacematch,
  KenyanEcitizen: VerificationPatternTypes.KenyanEcitizen,
  ArgentinianAnses: VerificationPatternTypes.ArgentinianAnses,
  UgandanElectoralCommission: VerificationPatternTypes.UgandanElectoralCommission,
  NigerianDl: VerificationPatternTypes.NigerianDl,
  ...AmlDocumentStepTypes,
};

export type StepIds = VerificationPatternTypes | StepTypes | VerificationDocStepTypes | VerificationStepTypes | DocumentStepFrontendChecksTypes | PremiumLivenessStepTypes;

export interface IStep<DataType = any> {
  status: StepCodeStatus;
  id: StepIds;
  error: StepError | null;
  phase?: string;
  checkStatus?: StepStatus;
  completedAt?: string;
  isTip?: boolean;
  startedAt?: string;
  startCount?: number;
  data?: DataType;
  inner?: DataType;
  connectedDocumentType: DocumentTypes;
  startedManuallyAt?: string;
}

export interface IStepExtra<T = any> extends IStep<T> {
  checkStatus?: StepStatus;
  isTip?: boolean;
}

export enum BiometricStepTypes {
  Liveness = 'liveness',
  Voice = 'voice',
  Selfie = 'selfie',
}

// used as 'id' of failed steps in check summary
export enum DocumentStepFrontendChecksTypes {
  ExpiredDate = 'expired-date',
  EmptyFields = 'empty-fields',
  EmptyRequiredFields = 'empty-required-fields',
}

export const DocumentFrontendSteps = [
  DocumentStepFrontendChecksTypes.ExpiredDate,
  DocumentStepFrontendChecksTypes.EmptyFields,
];

export const DocumentSecuritySteps = [
  DocumentStepTypes.TemplateMatching,
  DocumentStepTypes.AlternationDetection,
  DocumentStepTypes.Watchlists,
  DocumentStepTypes.FaceMatch,
];

export const CountrySpecificCreditChecks = [
  DocumentStepTypes.CreditArgentinianFidelitas,
  DocumentStepTypes.CreditBrazilianSerasa,
  DocumentStepTypes.CreditBrazilianSerasaChecks,
  DocumentStepTypes.CreditMexicoCdc,
  DocumentStepTypes.CreditColombiaTransunion,
];

export const AgeConsistencyCheckErrors = ['consistency.mismatch', 'consistency.noDOB', 'consistency.noEstimatedAge', 'node.failed'];

export function hasFailureStep(steps: IStep[]): boolean {
  if (!steps || !Array.isArray(steps)) {
    return false;
  }
  return steps.some((step) => step?.error);
}

// this is a priority step statuses for document from highest to lowest
const StepStatusesWeights = {
  [StepStatus.Failure]: 0,
  [StepStatus.Incomplete]: 1,
  [StepStatus.Checking]: 2,
  [StepStatus.Success]: 3,
};

export const StepStatusesWeightsOrder = [
  StepStatus.Failure,
  StepStatus.Incomplete,
  StepStatus.Checking,
  StepStatus.Success,
];

// TODO: refactor this to make it simpler
export function getDocumentStatus(steps) {
  const isAlterationDetectionFailed = steps.find((step) => step.id === DocumentStepTypes.AlternationDetection && step.checkStatus === StepStatus.Failure);
  if (isAlterationDetectionFailed) {
    return StepStatus.Failure;
  }

  const weight = steps.reduce((memo, { checkStatus }) => (memo > StepStatusesWeights[checkStatus] ? StepStatusesWeights[checkStatus] : memo), StepStatusesWeightsOrder.length - 1);
  return StepStatusesWeightsOrder[Math.max(weight, StepStatusesWeights[StepStatus.Incomplete])];
}

const StepIncompletionErrors = {
  [DocumentStepTypes.AgeValidation]: ['underage.noDOB'],
  [DocumentStepTypes.AgeConsistencyCheck]: ['consistency.mismatch', 'consistency.noDOB', 'consistency.noEstimatedAge'],
  [DocumentStepTypes.BrazilianCpfLight]: ['brazilianCpfLight.notEnoughParams'],
  [DocumentStepTypes.DuplicateUserDetectionCheck]: ['duplacateIdentityDetection.notValidParams'],
  [DocumentStepTypes.HonduranRnp]: ['honduranRnp.notEnoughParams'],
  [DocumentStepTypes.PremiumAmlWatchlistsCheck]: ['premiumAmlWatchlists.notValidParams'],
  [DocumentStepTypes.ParaguayanRcp]: ['paraguayanRcp.notEnoughParams'],
  [DocumentStepTypes.Watchlists]: ['watchlists.notEnoughParams'],
  [DocumentStepTypes.ArgentinianAnses]: ['argentinianAnses.notEnoughParams'],
  [DocumentStepTypes.ArgentinianDni]: ['argentinianDni.notEnoughParams'],
  [DocumentStepTypes.CostaRicanAtv]: ['costaRicanAtv.notEnoughParams'],
  [DocumentStepTypes.ArgentinianRenaperExtended]: ['argentinianRenaperExtended.notEnoughParams'],
  [DocumentStepTypes.ArgentinianRenaperFacematch]: ['argentinianRenaperFacematch.notEnoughParams'],
  [DocumentStepTypes.BolivianOep]: ['bolivianOep.notEnoughParams'],
  [DocumentStepTypes.ChileanRegistroCivil]: ['chileanRegistroCivil.notEnoughParams'],
  [DocumentStepTypes.ChileanDriverLicense]: ['chileanDriverLicense.notEnoughParams'],
  [DocumentStepTypes.ColombianContraloria]: ['colombianContraloria.notEnoughParams'],
  [DocumentStepTypes.ColombianProcuraduria]: ['colombianProcuraduria.notEnoughParams'],
  [DocumentStepTypes.ColombianRunt]: ['colombianRunt.notEnoughParams', 'colombianRunt.notValidParams'],
  [DocumentStepTypes.ColombianSisben]: ['colombianSisben.notEnoughParams', 'colombianSisben.notValidParams'],
  [DocumentStepTypes.CostaRicanTse]: ['costaRicanTse.notEnoughParams'],
  [DocumentStepTypes.EcuadorianSri]: ['ecuadorianSri.notEnoughParams'],
  [DocumentStepTypes.EcuadorianRegistroCivil]: ['ecuadorianRegistroCivil.notEnoughParams'],
  [DocumentStepTypes.GhanaianGra]: ['ghanaianGra.notEnoughParams'],
  [DocumentStepTypes.GuatemalanTse]: ['guatemalanTse.notEnoughParams'],
  [DocumentStepTypes.MexicanPep]: ['mexicanPep.notEnoughParams'],
  [DocumentStepTypes.NigerianDl]: ['nigerianDl.notEnoughParams'],
  [DocumentStepTypes.SalvadorianTse]: ['salvadorianTse.notEnoughParams'],
  [DocumentStepTypes.ColombianBdua]: ['colombianBdua.notEnoughParams'],
  [DocumentStepTypes.ColombianNationalPolice]: ['colombianNationPolice.notEnoughParams'],
  [DocumentStepTypes.ColombianMigraciones]: ['colombianMigrationInstitute.notEnoughParams', 'colombianMigrationInstitute.colombianNationality'],
  [DocumentStepTypes.ColombianNit]: ['colombianNit.notEnoughParams'],
  [DocumentStepTypes.ColombianUnifiedLegalSearch]: ['colombianUnifiedLegalSearch.notEnoughParams', 'colombianUnifiedLegalSearch.notValidParams'],
  [DocumentStepTypes.CostaRicanSocialSecurity]: ['costaRicanSocialSecurity.notEnoughParams'],
  [DocumentStepTypes.PanamenianTribunalElectoral]: ['panamenianTribunalElectoral.notEnoughParams'],
  [DocumentStepTypes.DominicanJce]: ['dominicanJce.notEnoughParams'],
  [DocumentStepTypes.DominicanRnc]: ['dominicanRnc.notEnoughParams'],
  [DocumentStepTypes.VenezuelanCne]: ['venezuelanCne.notEnoughParams'],
  [StepTypes.PhoneOwnership]: ['phoneOwnership.notEnoughParams', 'phoneOwnership.skipped'],
  [StepTypes.PhoneRiskValidation]: ['phoneRisk.skipped'],
  [StepTypes.EmailOwnership]: ['emailOwnership.notEnoughParams', 'emailOwnership.skipped'],
  [StepTypes.EmailRisk]: ['emailRisk.notEnoughParams', 'emailRisk.skipped'],
  [DocumentStepTypes.VenezuelanSeniat]: ['venezuelanSeniat.notEnoughParams'],
  [DocumentStepTypes.KenyanEcitizen]: ['kenyanEcitizen.notEnoughParams'],
  [DocumentStepTypes.PeruvianSunat]: ['peruvianSunat.notEnoughParams'],
  [DocumentStepTypes.UgandanElectoralCommission]: ['ugandanElectoralCommission.notEnoughParams'],
  [DocumentStepTypes.BrazilianNoCriminalRecordsValidation]: ['brazilianNoCriminalRecordsValidation.notEnoughParams'],
  [VerificationDocStepTypes.DuplicateUserValidation]: ['duplicateUserDetection.notValidParams'],
  [VerificationStepTypes.BackgroundBrazilianChecks]: ['brazilianBackgroundChecks.documentNotFound'],
  [VerificationPatternTypes.PremiumAmlWatchListsValidation]: ['premiumAmlWatchlists.notValidParams'],
};

export const OptionalErrorCodes = {
  [DocumentStepTypes.ArgentinianRenaperExtended]: ['argentinianRenaperExtended.deceasedPerson', 'argentinianRenaperExtended.dniMismatch', 'argentinianRenaperExtended.hasNoCuit'],
  [DocumentStepTypes.BrazilianCpf]: ['brazilianCpf.faceBiometricsMismatch'],
  [DocumentStepTypes.PeruvianReniec]: ['peruvianReniec.fullNameMismatch'],
  [DocumentStepTypes.MexicanPep]: ['mexicanPep.matchFound'],
  [DocumentStepTypes.ChileanRut]: ['chileanRut.fullNameMismatch'],
  [DocumentStepTypes.ChileanDriverLicense]: ['chileanDriverLicense.fullNameMismatch', 'chileanDriverLicense.blocked'],
  [DocumentStepTypes.UgandanElectoralCommission]: ['ugandanElectoral.fullNameMismatch'],
  [DocumentStepTypes.NigerianDl]: ['nigerianDl.fullNameMismatch'],
  [DocumentStepTypes.ColombianPpt]: ['colombianPPT.notEnoughParamsNameMatch', 'colombianPPT.fullNameMismatch'],
  [DocumentStepTypes.ColombianRunt]: ['colombianRunt.fullNameMismatch', 'colombianRunt.hasFines'],
  [DocumentStepTypes.ArgentinianRenaper]: ['argentinianRenaper.deceasedPerson', 'argentinianRenaper.fullNameMismatch'],
  [VerificationStepTypes.NigerianCacValidation]: ['nigerianCac.invalidStatus'],
  [VerificationStepTypes.NigerianLegalValidation]: ['nigerianLegal.fullNameMismatch', 'nigerianLegal.faceMismatch'],
  [VerificationStepTypes.NigerianNin]: ['nigerianNin.fullNameMismatch', 'nigerianNin.faceMismatch'],
  [VerificationStepTypes.NigerianVirtualNin]: ['nigerianVirtualNIN.fullNameMismatch', 'nigerianVirtualNIN.faceMismatch'],
  [VerificationStepTypes.NigerianVin]: ['nigerianVin.fullNameMismatch', 'nigerianVin.faceMismatch'],
  [VerificationStepTypes.NigerianBvn]: ['nigerianBvn.fullNameMismatch', 'nigerianBvn.faceMismatch'],
  [VerificationStepTypes.PhilippineDlValidation]: ['philippineDL.fullNameMismatch', 'philippineDL.dateOfBirthMismatch'],
  [VerificationStepTypes.PhilippineNbiValidation]: ['philippineNib.fullNameMismatch'],
  [VerificationStepTypes.PhilippinePNPValidation]: ['philippinePnp.fullNameMismatch'],
  [VerificationStepTypes.PhilippinePrcValidation]: ['philippinePRC.fullNameMismatch'],
  [VerificationStepTypes.IndonesianKTPValidation]: ['indonesianKTP.faceBiometricsMismatch', 'indonesianKTP.dobMismatch', 'indonesianKTP.fullNameMismatch'],
  [VerificationStepTypes.KenyanBRC]: ['kenyanBrs.fullNameMismatch'],
  [VerificationStepTypes.KenyanIprsValidation]: ['kenyanIprs.fullNameMismatch', 'kenyanIprs.dobMismatch'],
  [DocumentStepTypes.ColombianSisben]: ['colombianSisben.fullNameMismatch'],
  [DocumentStepTypes.DominicanRnc]: ['dominicanRnc.nameMismatch'],
  [DocumentStepTypes.EcuadorianSocialSecurity]: ['ecuadorianSocialSecurity.fullNameMismatch'],
  [DocumentStepTypes.MexicanCurp]: ['curp.curpInactive'],
  [DocumentStepTypes.ColombianUnifiedLegalSearch]: ['colombian-unified-legal-search-validation.activeProcesses'],
  [VerificationPatternTypes.ArgentinianRenaperPremium]: ['argentinianRenaperPremium.fullNameMismatch', 'argentinianRenaperPremium.dobMismatch', 'argentinianRenaperPremium.dateOfIssueMismatch', 'argentinianRenaperPremium.genderMismatch', 'argentinianRenaperPremium.deceasedPerson'],
};

export const StepErrorsToHide = {
  'nigerianLegal.documentNotFound': true,
  'brazilianBackgroundChecks.documentNotFound': true,
  'druckerLatamBackgroundChecks.documentNotFound': true,
  'backgroundMexicanBuholegal.documentNotFound': true,
};

export const StepSkippedCodes = [
  'customDocument.skipped',
  'customDocument.notProvided',
];

export const MinorErrorCodes = [
  'philippineDL.incompleteDataReceived',
  'mexicanRfcStatus.notEnoughParamsNameMatch',
  'brazilianCpfLight.notEnoughParamsNameMatch',
  'curp.notEnoughParamsNameMatch',
];

function getAltered(step, verification, countries, document) {
  switch (step.id) {
    case DocumentStepTypes.AlternationDetection:
      return getAlterationReason(step);
    case DocumentStepTypes.FaceMatch: {
      const steps = get(verification, '_embedded.verification.steps') || [];
      const pooStep = steps.find((item) => item.id === StepTypes.ProofOfOwnership);
      return getFacematchStepExtra(step, pooStep, verification, document);
    }
    case DocumentStepTypes.TemplateMatching:
      return getTemplateMatchingStepExtraData(step, verification, countries, document);
    case DocumentStepTypes.PremiumAmlWatchlistsCheck:
      return getPremiumAmlWatchlistsCheckExtraData(step, document);
    default:
      return step;
  }
}

export interface IPremiumAmlWatchlistStepData {
  nameSearched: string;
  profileUrl?: string;
  searchId: number;
  searchedOn: string;
  updatedOn?: string;
  searchedCountries?: string[];
  countriesSearched?: string[];
  dateOfBirth?: string;
  isMonitored?: boolean;
  matchType: typeof MatchTypes;
  originalMatchStatus?: string;
}

export function getDocumentStep(id, steps = []) {
  return steps.find((step) => step.id === id) || {};
}

// TODO function gets step as a param but we cant use IStep interface for now
// because not all step typings are inherited from IStep
export function getStepStatus(step): StepStatus {
  const { id, status, data = {}, error } = step || {};

  if (error?.type === FRONTEND_ERROR && error.code === DocumentStepFrontendChecksTypes.EmptyFields) {
    return StepStatus.Success;
  }

  if (status !== 200) {
    return StepStatus.Checking;
  }

  if (id === DocumentStepTypes.PremiumAmlWatchlistsCheck && (data as IPremiumAmlWatchlistStepData)?.updatedOn) {
    return StepStatus.Failure;
  }

  if (!error) {
    return StepStatus.Success;
  }

  const code = get(error, 'code');

  if (StepSkippedCodes.includes(code)) {
    return StepStatus.Skipped;
  }

  if (MinorErrorCodes.includes(code)) {
    return StepStatus.Success;
  }

  return code && StepIncompletionErrors[id] && StepIncompletionErrors[id].includes(code)
    ? StepStatus.Incomplete
    : StepStatus.Failure;
}

export function getStepExtra<T = any>(step: IStep<T>, verification?: any, countries?: any, document?: any) {
  if (!step) {
    return step;
  }

  const altered = getAltered(step, verification, countries, document);

  return {
    ...altered,
    checkStatus: getStepStatus(step),
    // extras (DocumentStepFrontendChecksTypes) and Gov-checks has no tip
    isTip: Object.values(DocumentStepTypes).includes(step.id),
  };
}

function getEmptyFieldError(stepData: IStep): Nullable<StepError> {
  const fields = getFieldsExtra(stepData.data);
  const emptyFields = fields.filter((item) => !item.value);
  const isEmptyRequiredField = emptyFields.some((item) => item.required);

  if (isEmptyRequiredField) {
    return {
      type: FRONTEND_ERROR,
      code: DocumentStepFrontendChecksTypes.EmptyRequiredFields,
    };
  }

  if (fields.length === 0 || emptyFields.length > 0) {
    return {
      type: FRONTEND_ERROR,
      code: DocumentStepFrontendChecksTypes.EmptyFields,
    };
  }

  return null;
}

export function getReaderFrontendSteps(readerStep?) {
  const steps = [];

  if (!readerStep) {
    return steps;
  }

  steps.push({
    ...readerStep,
    id: DocumentStepFrontendChecksTypes.EmptyFields,
    error: getEmptyFieldError(readerStep),
  });

  return steps;
}

export function getComputedSteps(readerStep, verification, document) {
  const steps = [];
  const isDocumentExpired = verification?.computed?.isDocumentExpired?.data?.[document?.type];
  const isUndetermined = isNil(isDocumentExpired);
  const isCovid = !isDocumentExpired && isCovidTolerance(document.fields?.expirationDate?.value, document?.country);
  if (isUndetermined) {
    return steps;
  }

  steps.push({
    ...readerStep,
    id: DocumentStepFrontendChecksTypes.ExpiredDate,
    error: isDocumentExpired && !isCovid ? {
      code: DocumentStepFrontendChecksTypes.ExpiredDate,
    } : null,
    labelExtra: isCovid ? 'SecurityCheckStep.expired-date.success-covid' : null,
  });

  return steps;
}

export function getStepsExtra(steps = [], verification, countries, document) {
  const readerStep = getDocumentStep(DocumentStepTypes.DocumentReading, steps);

  return [
    ...getReaderFrontendSteps(readerStep),
    ...getComputedSteps(readerStep, verification, document),
    ...steps,
  ].map((item) => getStepExtra(item, verification, countries, document));
}

const MinorErrorNameSpaces = [
  'notEnoughParamsNameMatch',
  'incompleteDataReceived',
];

const CustomMinorErrors: PartialRecord<VerificationPatternTypes, string[]> = {
  [VerificationPatternTypes.PhilippineDl]: ['philippineDL.incompleteDataReceived'],
  [VerificationPatternTypes.ArgentinianDni]: ['argentinianDni.oldDocument'],
  [VerificationPatternTypes.ColombianRegistraduria]: [
    'columbianRegistraduria.fullNameMismatch',
    'colombianRegistraduria.suspendedDocument',
    'colombianRegistraduria.judicialBanForDementia',
    'colombianRegistraduria.deadOwner',
    'colombianRegistraduria.duplicatedDocument',
    'colombianRegistraduria.falseDocument',
    'colombianRegistraduria.underageOwner',
    'colombianRegistraduria.foreignOwner',
    'colombianRegistraduria.lowQualityDocument',
    'colombianRegistraduria.sexChangeOwner',
    'colombianRegistraduria.neverIssuedDocument',
    'colombianRegistraduria.canceledDueDeath',
    'colombianRegistraduria.canceledDueDuplicateRequest',
    'colombianRegistraduria.canceledDueTheftAttempt',
    'colombianRegistraduria.canceledDueUnderage',
    'colombianRegistraduria.canceledByForeignMinistry',
    'colombianRegistraduria.canceledDueLowQuality',
    'colombianRegistraduria.notApplicable',
    'colombianRegistraduria.notIssuedYet',
  ],
};

// Arkadiy: columbianRegistraduria.fullNameMismatch, not colOmbianRegistraduria in that case. Done by purpose.

const StepsErrorsWithDataView: PartialRecord<string, boolean> = {
  'argentinianDni.oldDocument': true,
  'columbianRegistraduria.fullNameMismatch': true,
  'colombianRegistraduria.suspendedDocument': true,
  'colombianRegistraduria.judicialBanForDementia': true,
  'colombianRegistraduria.deadOwner': true,
  'colombianRegistraduria.duplicatedDocument': true,
  'colombianRegistraduria.falseDocument': true,
  'colombianRegistraduria.underageOwner': true,
  'colombianRegistraduria.foreignOwner': true,
  'colombianRegistraduria.lowQualityDocument': true,
  'colombianRegistraduria.sexChangeOwner': true,
  'colombianRegistraduria.neverIssuedDocument': true,
  'colombianRegistraduria.canceledDueDeath': true,
  'colombianRegistraduria.canceledDueDuplicateRequest': true,
  'colombianRegistraduria.canceledDueTheftAttempt': true,
  'colombianRegistraduria.canceledDueUnderage': true,
  'colombianRegistraduria.canceledByForeignMinistry': true,
  'colombianRegistraduria.canceledDueLowQuality': true,
  'colombianRegistraduria.notApplicable': true,
  'colombianRegistraduria.notIssuedYet': true,
};

export const hasMinorErrorNamespace = (errorCode: string): boolean => MinorErrorNameSpaces.some((namespace) => new RegExp(`.${namespace}$`, 'g').test(errorCode));

export const hasMinorError = (id: string, errorCode: string): boolean => hasMinorErrorNamespace(errorCode) || CustomMinorErrors[id]?.includes(errorCode);
export const showMinorErrorAsDefaultError = (code: string): boolean => !!StepsErrorsWithDataView[code];

export function isSecondaryError(id: string, errorCode: string): boolean {
  return OptionalErrorCodes[id]?.includes(errorCode);
}

export function filterSteps<T>(steps: IStep<T>[], listStep: string[]): IStep<T>[] {
  return steps
    .filter((step) => listStep.includes(step.id) && !(step?.error?.code && StepErrorsToHide[step?.error?.code]))
    .map((step) => getStepExtra(step)) || [];
}
