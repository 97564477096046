import React from 'react';
import { RecordView } from 'apps/ui';
// TODO: @aleksei.rasskazov update import from apps/ui when circle deps problem be solved
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { LegalSearchRecord } from '../../models/GovCheckMerit.model';
import { useStyles } from './GovcheckLegalSearchProcess.styles';
import { LegalSearchProcessDetails } from './components/LegalSearchProcessDetails/LegalSearchProcessDetails';

export const GovcheckLegalSearchProcess = ({ data }: {
  data: {
    department: string;
    records: LegalSearchRecord[];
  }[];
}) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Grid container spacing={2}>
      {data.map(({
        department,
        records,
      }) => (
        <Grid item key={department} xs={12}>
          <Box mb={1}>
            <RecordView
              itemLeft={{
                title: formatMessage('GovCheck.check.unifiedLegalSearch.department'),
                value: department,
              }}
              itemRight={{
                title: formatMessage('GovCheck.check.unifiedLegalSearch.status'),
                value: formatMessage('GovCheck.check.unifiedLegalSearch.failed'),
                valueClass: classes.recordStatus,
              }}
              details={records.map((record) => <LegalSearchProcessDetails record={record} key={record.processId} />)}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
