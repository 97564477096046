import { ProductTypes } from 'models/Product.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { productManagerService } from 'apps/Product';
import { selectMerchantTags } from 'state/merchant';
import { selectCanUseReverificationFlow } from './ReVerification.selectors';
import { ReVerification } from '../services/ReVerification.service';

export const reVerificationInit = () => (dispatch, getState): ProductTypes | null => {
  const merchantTags = selectMerchantTags(getState());
  const isCanUseReverificationFlow = selectCanUseReverificationFlow(getState());
  const reVerification = new ReVerification({
    disabled: !isCanUseReverificationFlow,
    isVideoDisabled: merchantTags.includes(MerchantTagsTypes.CanNotUseSelfieVideo),
  });
  productManagerService.register(reVerification);
  return reVerification.id;
};
