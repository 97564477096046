import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { ITemplateMetadata } from 'apps/Templates';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { ICountryTag } from '../../model/SolutionCatalog.model';
import { useStyles } from './TemplateCountryTags.styles';

export function TemplateCountryTags({ countryMetadatas, countryFilters, maxNumTags, labelHasEnabled }: {
  maxNumTags: number;
  countryMetadatas: ITemplateMetadata[];
  countryFilters: ITemplateMetadata[];
  labelHasEnabled?: string;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const countryTags = useMemo<ICountryTag[]>(() => {
    if (!countryFilters.length) {
      return countryMetadatas.map((countryMetadata) => ({
        name: countryMetadata.name,
        disabled: false,
      }));
    }
    return countryFilters.map((countryFilter) => ({
      name: countryFilter.name,
      disabled: countryMetadatas.every((countryMetadata) => countryMetadata.name !== countryFilter.name),
    }));
  }, [countryFilters, countryMetadatas]);

  const displayedCountryTags = useMemo<ICountryTag[]>(() => countryTags.sort((countryTag) => (countryTag.disabled ? 1 : -1))
    .slice(0, maxNumTags), [countryTags, maxNumTags]);

  const enabledTagsCount = useMemo<number>(() => countryTags.reduce<number>((sum, tag) => sum + (tag.disabled ? 0 : 1), 0), [countryTags]);

  if (enabledTagsCount === 0) {
    return (
      <Box className={classes.label}>
        {formatMessage('TemplateCard.label.allCountriesDisabled')}
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {labelHasEnabled && (
        <Box className={classes.label} mr="6px">
          {labelHasEnabled}
        </Box>
      )}
      {displayedCountryTags.map((countryTag, index) => {
        if (index < maxNumTags - 1 || enabledTagsCount <= maxNumTags) {
          return (
            <Box className={classNames(classes.country, { [classes.countyDisabled]: countryTag.disabled })} key={countryTag.name}>
              {formatMessage(`Countries.${countryTag.name}`)}
            </Box>
          );
        }
        return (
          <Box key="otherCountries" className={classes.label}>
            {formatMessage('TemplateCard.label.otherCountries', {
              messageValues: {
                number: (enabledTagsCount - maxNumTags) + 1,
              },
            })}
          </Box>
        );
      })}
    </Box>
  );
}
