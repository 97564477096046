import { DocumentVerificationProduct } from 'apps/documents';
import { FACEMATCH_DEFAULT_THRESHOLD } from 'apps/facematch/models/facematch.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { InputValidationType } from 'models/ImageValidation.model';
import { DocumentSideTypes, DocumentTypes, DocumentInputTypes, getDocumentCURLExampleFiles, DocumentErrorCodeTypes } from 'models/Document.model';
import { IFlow } from 'models/Flow.model';
import { IProductCURLExampleParams, Product, ProductInputTypes, ProductIntegrationTypes, ProductTypes } from 'models/Product.model';
import { DocumentFrontendSteps, DocumentSecuritySteps, DocumentStepTypes, getComputedSteps, getDocumentStep, getReaderFrontendSteps, getStepStatus, StepStatus, VerificationDocStepTypes } from 'models/Step.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { FiFileText } from 'react-icons/fi';
import { BiometricVerificationCheckTypes } from 'apps/biometricVerification/models/BiometricVerification.model';
import { IESignatureFlow } from 'models/ESignature.model';
import { DocumentVerificationSettings } from 'apps/documentVerification/components/DocumentVerificationSettings/DocumentVerificationSettings';
import { CountryCodeTypes } from 'models/Country.model';
import { FacematchSourceTypes } from 'models/Facematch.model';
import { DataSourceValueTypes } from '../../../models/Watchlist.model';
import { DocumentVerificationCheckTypes, DocumentVerificationSettingTypes, getDocumentStepsSettingError, ProductSettingsDocumentVerification } from '../models/DocumentVerification.model';

export class DocumentVerification extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.DocumentVerification;
  order = 100;
  integrationTypes = [
    ProductIntegrationTypes.Sdk,
    ProductIntegrationTypes.Api,
  ];
  icon = FiFileText;
  inputs = [
    ProductInputTypes.Documents,
  ];
  dependentProductTypes = [
    ProductTypes.AmlCheck,
    ProductTypes.CustomWatchlist,
  ];
  checks = [
    {
      id: DocumentVerificationCheckTypes.DocumentReading,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.ExpirationDetection,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.TemplateMatching,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.AgeThreshold,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.DuplicateUserDetection,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.AlterationDetection,
      isActive: true,
    },
    {
      id: DocumentVerificationCheckTypes.Facematch,
      isActive: true,
    }];

  component = DocumentVerificationSettings;
  componentVerification = DocumentVerificationProduct;
  isRestrictedToFreemiumCountries = false;

  constructor({ isOnlyFreemiumCountries }: { isOnlyFreemiumCountries: boolean }) {
    super();
    if (isOnlyFreemiumCountries) {
      this.isRestrictedToFreemiumCountries = true;
    }
  }

  onAdd(flow: IFlow): Partial<IFlow> {
    // TODO: remove when POA is deprecated
    const isPOA = flow?.verificationSteps.some((group) => group.some((step) => step === DocumentTypes.ProofOfResidency));
    const verificationSteps = flow?.verificationSteps
      .map((group) => group.filter((step) => ![DocumentTypes.Passport, DocumentTypes.NationalId, DocumentTypes.DrivingLicense, DocumentTypes.ProofOfResidency].includes(step as DocumentTypes)))
      .filter((group) => group.length > 0);

    verificationSteps.push([DocumentTypes.NationalId]);

    if (isPOA) {
      verificationSteps.push([DocumentTypes.ProofOfResidency]);
    }

    return {
      isDuplicateUserRejection: true,
      verificationSteps,
    };
  }

  parser(flow: IFlow, productsInGraph?: ProductTypes[]): ProductSettingsDocumentVerification {
    const isDocumentStepsActive = flow?.verificationSteps?.length > 0;
    const isBiometricStepsActive = productsInGraph.includes(ProductTypes.BiometricVerification);
    const isDuplicateUserDetectionActive = !!flow?.verificationPatterns?.[VerificationPatternTypes.DuplicateUserDetection];

    const neededSteps = flow?.verificationSteps
      .map((group) => group.filter((step) => [DocumentTypes.Passport, DocumentTypes.NationalId, DocumentTypes.DrivingLicense].includes(step as DocumentTypes)))
      .filter((group) => group.length > 0);

    const otherSteps = flow?.verificationSteps
      .map((group) => group.filter((step) => ![DocumentTypes.Passport, DocumentTypes.NationalId, DocumentTypes.DrivingLicense].includes(step as DocumentTypes)))
      .filter((group) => group.length > 0);

    return {
      [DocumentVerificationSettingTypes.DocumentSteps]: {
        value: neededSteps,
        error: getDocumentStepsSettingError(flow, productsInGraph),
      },
      [DocumentVerificationSettingTypes.OtherSteps]: {
        value: otherSteps,
      },
      [DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera]: {
        value: !!flow?.allowDocumentsViaWebCamera,
      },
      [DocumentVerificationSettingTypes.DenyUploadRequirement]: {
        value: !!flow?.denyUploadsFromMobileGallery,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera]: {
        value: !!flow?.allowDocumentsViaNativeCamera,
      },
      [DocumentVerificationSettingTypes.DocumentCroppedCheck]: {
        value: !flow?.disableCroppedDocumentDetection,
      },
      [DocumentVerificationSettingTypes.AgeThreshold]: {
        value: flow?.ageThreshold,
      },
      [DocumentVerificationSettingTypes.GrayscaleImage]: {
        value: flow?.inputValidationChecks?.some((check) => check.id === InputValidationType.GrayscaleImage && !check.isDisabled) || false,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.ScreenPhotoValidation]: {
        value: flow?.inputValidationChecks?.some((check) => check.id === InputValidationType.ScreenPhoto && check.isDisabled) || false,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.DocumentPhotoFaceCheck]: {
        value: !flow?.inputValidationChecks?.some((check) => [InputValidationType.HasPhoto, InputValidationType.FaceDetected].includes(check.id) && check.isDisabled),
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck]: {
        value: !flow?.inputValidationChecks?.some((check) => check.id === InputValidationType.NoDocumentFound && check.isDisabled),
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.BlurryImageCheck]: {
        value: !flow?.inputValidationChecks?.some((check) => check.id === InputValidationType.BlurryImage && check.isDisabled),
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.SideMismatch]: {
        value: !flow?.inputValidationChecks?.some((check) => check.id === InputValidationType.SideMismatch && check.isDisabled),
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.DuplicateUserDetection]: {
        value: isDuplicateUserDetectionActive,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.IsDuplicateUserRejection]: {
        value: isDuplicateUserDetectionActive ? !!flow?.isDuplicateUserRejection : true,
      },
      [DocumentVerificationSettingTypes.IsRestrictedToFreemiumCountries]: {
        value: this.isRestrictedToFreemiumCountries,
      },
      [DocumentVerificationSettingTypes.CountryRestriction]: {
        value: this.isRestrictedToFreemiumCountries ? [CountryCodeTypes.MX, CountryCodeTypes.CO] : (flow?.supportedCountries || []),
        error: flow?.integrationType === ProductIntegrationTypes.Api && flow?.supportedCountries?.length > 0 ? 'DocumentVerification.issues.description' : null,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.FacematchThreshold]: {
        value: flow?.facematchThreshold || FACEMATCH_DEFAULT_THRESHOLD,
        isDisabled: !isDocumentStepsActive,
        isRequireOtherProduct: !isBiometricStepsActive,
      },
      [DocumentVerificationSettingTypes.ProofOfOwnership]: {
        value: !!flow?.verificationPatterns?.[VerificationPatternTypes.ProofOfOwnership],
        isDisabled: !isDocumentStepsActive,
        isRequireOtherProduct: !isBiometricStepsActive,
        isCantBeUsedWithOtherSetting: flow?.verificationPatterns?.biometrics === BiometricVerificationCheckTypes.VoiceLiveness || flow?.verificationPatterns?.[VerificationPatternTypes.BiometricsVerification],
      },
      [DocumentVerificationSettingTypes.HumanDocumentReadingService]: {
        value: flow.disableDocumentReading,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.HumanFraudDetectionService]: {
        value: flow.disableAlterationDetection,
        isDisabled: !isDocumentStepsActive,
      },
      [DocumentVerificationSettingTypes.CountriesSettings]: {
        value: flow.countriesSettings,
      },
      [DocumentVerificationSettingTypes.PooNistWarning]: {
        value: flow?.biometrics?.challenge || false,
      },
    };
  }

  serialize(settings: ProductSettingsDocumentVerification): Partial<IFlow> {
    return {
      allowDocumentsViaWebCamera: settings[DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera].value,
      allowDocumentsViaNativeCamera: settings[DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera].value,
      verificationSteps: [...settings.documentSteps.value, ...settings.otherSteps.value],
      denyUploadsFromMobileGallery: settings.denyUploadRequirement.value,
      ageThreshold: settings.ageThreshold.value,
      inputValidationChecks: [
        {
          id: InputValidationType.GrayscaleImage,
          isDisabled: !settings.grayscaleImage.value,
        }, {
          id: InputValidationType.DocumentDetected,
          isDisabled: false,
        },
        {
          id: InputValidationType.ScreenPhoto,
          isDisabled: settings.screenPhotoValidation.value,
        },
        {
          id: InputValidationType.HasPhoto,
          isDisabled: !settings.documentPhotoFaceCheck.value,
        },
        {
          id: InputValidationType.FaceDetected,
          isDisabled: !settings.documentPhotoFaceCheck.value,
        },
        {
          id: InputValidationType.NoDocumentFound,
          isDisabled: !settings.documentPhotoPresenceCheck.value,
        },
        {
          id: InputValidationType.BlurryImage,
          isDisabled: !settings.blurryImageCheck.value,
        },
        {
          id: InputValidationType.SideMismatch,
          isDisabled: !settings.sideMismatch.value,
        },
      ],
      isDuplicateUserRejection: settings.isDuplicateUserRejection.value,
      supportedCountries: settings.countryRestriction.value,
      facematchThreshold: settings.facematchThreshold.value,
      disableDocumentReading: settings.humanDocumentReadingService?.value,
      disableAlterationDetection: settings.humanFraudDetectionService?.value,
      disableCroppedDocumentDetection: !settings.documentCroppedCheck?.value,
      countriesSettings: settings.countriesSettings?.value,
      verificationPatterns: {
        [VerificationPatternTypes.DuplicateUserDetection]: settings.duplicateUserDetection.value,
        [VerificationPatternTypes.ProofOfOwnership]: settings.proofOfOwnership.value,
      },
    };
  }

  hasCustomFieldInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.CustomFieldsValidation] && !!flow?.customFieldsConfig?.fields?.length;
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    const otherSteps = flow?.verificationSteps
      .map((group) => group.filter((step) => ![DocumentTypes.Passport, DocumentTypes.NationalId, DocumentTypes.DrivingLicense].includes(step as DocumentTypes)))
      .filter((group) => group.length > 0);
    let electronicSignature: IESignatureFlow = flow?.electronicSignature;
    if (flow?.electronicSignature?.acceptanceCriteria.isDocumentsRequired) {
      electronicSignature = {
        ...flow.electronicSignature,
        acceptanceCriteria: {
          ...flow.electronicSignature.acceptanceCriteria,
          isDocumentsRequired: false,
          isFaceMatchRequired: false,
        },
      };
    }

    const facematchSources = flow?.facematchServiceConfig?.sources.filter((source) => source.type !== FacematchSourceTypes.Document);
    const watchListsConfig = { ...flow.watchlists };
    const dataSource = this.hasCustomFieldInFlow(flow) ? DataSourceValueTypes.CustomField : undefined;

    return {
      verificationSteps: [...otherSteps],
      denyUploadsFromMobileGallery: false,
      ageThreshold: undefined,
      allowDocumentsViaWebCamera: false,
      allowDocumentsViaNativeCamera: false,
      inputValidationChecks: [
        {
          id: InputValidationType.GrayscaleImage,
          isDisabled: true,
        },
        {
          id: InputValidationType.SimilarImages,
          isDisabled: true,
        },
        {
          id: InputValidationType.IdenticalImages,
          isDisabled: true,
        },
        {
          id: InputValidationType.DocumentDetected,
          isDisabled: false,
        },
        {
          id: InputValidationType.ScreenPhoto,
          isDisabled: false,
        },
        {
          id: InputValidationType.HasPhoto,
          isDisabled: false,
        },
        {
          id: InputValidationType.FaceDetected,
          isDisabled: false,
        },
        {
          id: InputValidationType.NoDocumentFound,
          isDisabled: false,
        },
        {
          id: InputValidationType.BlurryImage,
          isDisabled: false,
        },
        {
          id: InputValidationType.SideMismatch,
          isDisabled: false,
        },
      ],
      isDuplicateUserRejection: false,
      supportedCountries: [],
      facematchThreshold: undefined,
      disableAlterationDetection: undefined,
      disableDocumentReading: undefined,
      disableCroppedDocumentDetection: undefined,
      countriesSettings: undefined,
      verificationPatterns: {
        [VerificationPatternTypes.AgeConsistencyCheckEnabled]: false,
        [VerificationPatternTypes.DuplicateUserDetection]: false,
        [VerificationPatternTypes.ProofOfOwnership]: false,
      },
      electronicSignature,
      facematchServiceConfig: {
        ...flow?.facematchServiceConfig,
        sources: facematchSources,
      },
      watchlists: {
        ...watchListsConfig,
        basic: {
          ...watchListsConfig.basic,
          dataSource,
        },
        premiumAml: {
          ...watchListsConfig.premiumAml,
          dataSource,
        },
        custom: {
          ...watchListsConfig.custom,
          dataSource,
        },
      },
    };
  }

  isInFlow(flow: IFlow): boolean {
    const allSteps = flow?.verificationSteps?.flatMap((step) => step) || [];
    return allSteps.some((step) => [DocumentTypes.DrivingLicense, DocumentTypes.NationalId, DocumentTypes.Passport].includes(step as DocumentTypes));
  }

  getVerification(verification: VerificationResponse): VerificationResponse {
    const documentTypes: string[] = Object.values(DocumentTypes).filter((item) => item !== DocumentTypes.ProofOfResidency).map((item: DocumentTypes) => item as string);
    const documents = verification?.documents?.filter((el) => documentTypes.includes(el.type) || el?.documentReadingStep?.error?.code === DocumentErrorCodeTypes.DocumentSkipped).map((doc) => {
      const duplicateUserDetectionStep = doc?.steps?.find((item) => item?.id === VerificationDocStepTypes.DuplicateUserValidation);
      const ageCheck = doc?.steps?.find((item) => item?.id === VerificationDocStepTypes.AgeValidation);
      const ageConsistencyCheck = doc?.steps?.find((step) => step?.id === VerificationDocStepTypes.AgeConsistencyCheck);
      return { ...doc, duplicateUserDetectionStep, ageCheck, ageConsistencyCheck };
    });

    return { ...verification, documents };
  }

  getCURLExampleParams(flow: IFlow): Nullable<IProductCURLExampleParams> {
    const verificationSteps = flow?.verificationSteps || [];
    const documentTypes: string[] = Object.values(DocumentTypes);
    const documentInputTypes = verificationSteps.filter((step) => documentTypes.includes(step[0])).map((step) => step[0]);

    if (!documentInputTypes.length) {
      return null;
    }

    return documentInputTypes.reduce((acc, type, idx) => {
      const inputId = type.toUpperCase();
      const inputTemplate = {
        inputType: DocumentInputTypes.DocumentPhoto,
        group: idx,
        data: {
          type,
          country: CountryCodeTypes.MX,
          page: DocumentSideTypes.Front,
          filename: `MX_${inputId}_FRONT.jpg`,
        },
      };

      if (type === DocumentTypes.NationalId) {
        return {
          inputs: [
            ...acc.inputs,
            inputTemplate,
            {
              ...inputTemplate,
              data: {
                ...inputTemplate.data,
                page: DocumentSideTypes.Back,
                filename: `MX_${inputId}_BACK.jpg`,
              },
            },
          ],
          files: [...acc.files, getDocumentCURLExampleFiles(type)],
        };
      }

      return {
        inputs: [...acc.inputs, inputTemplate],
        files: [...acc.files, getDocumentCURLExampleFiles(type)],
      };
    }, {
      inputs: [],
      files: [],
    });
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    return verification?.documents?.some((document) => {
      const steps = document?.steps || [];
      const documentStep = getDocumentStep(DocumentStepTypes.DocumentReading, steps);
      const readerStep = getReaderFrontendSteps(documentStep);
      const computedStep = getComputedSteps(documentStep, verification, document);
      const filteredSteps = steps.filter((step) => [
        ...DocumentSecuritySteps,
        ...DocumentFrontendSteps,
        VerificationDocStepTypes.DuplicateUserValidation].includes(step.id));
      const allSteps = [
        ...filteredSteps,
        ...readerStep,
        ...computedStep,
      ];

      if (allSteps.length === 0) {
        return false;
      }

      return allSteps.some((step) => getStepStatus(step) === StepStatus.Failure);
    });
  }
}
