import classNames from 'classnames';
import React from 'react';
import Box from '@material-ui/core/Box';
import { FiArrowRight } from 'react-icons/fi';
import { useStyles } from './TemplateFilterOption.style';

export function TemplateFilterOption({ text, isChecked, onChange }: {
  text: string;
  isChecked: boolean;
  onChange: () => void;
}) {
  const classes = useStyles();
  const handleClick = () => {
    if (isChecked) {
      return;
    }
    onChange();
  };

  return (
    <Box
      className={classNames(classes.filterOption, { [classes.selected]: isChecked })}
      onClick={handleClick}
    >
      {text}
      <FiArrowRight size={16} />
    </Box>
  );
}
