import { Editor, Transforms, Element as SlateElement, Text, Descendant, BaseEditor } from 'slate';
import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';
import { ReactEditor } from 'slate-react';

export enum TextAlignTypes {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}

export const TEXT_ALIGN = [TextAlignTypes.Center, TextAlignTypes.Left, TextAlignTypes.Right, TextAlignTypes.Justify];

export enum ElementTypes {
  Paragraph = 'paragraph',
  BlockQuote = 'block-quote',
  BulletedList = 'bulleted-list',
  HeadingOne = 'heading-one',
  HeadingTwo = 'heading-two',
  ListItem = 'list-item',
  NumberedList = 'numbered-list',
}

export const LIST_ELEMENTS = [ElementTypes.NumberedList, ElementTypes.BulletedList];

export enum MarkFormatTypes {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Code = 'code',
  Link = 'link',
}

export type BlockFormatTypes = ElementTypes | TextAlignTypes;

export enum BlockTypes {
  Align = 'align',
  Type = 'type',
}

export type NodeAttributes = {
  italic?: boolean;
  code?: boolean;
  underline?: boolean;
  bold?: boolean;
  link?: string;
}
type CustomText = {
  text: string;
} & NodeAttributes;

type CustomElement = {
  type: ElementTypes;
  children: CustomText[];
  align?: TextAlignTypes;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export const EMPTY_TEXT_EDITOR: Descendant[] = [
  {
    type: ElementTypes.Paragraph,
    children: [{ text: '' }],
  },
];

export const isBlockActive = (editor, format: BlockFormatTypes, blockType: BlockTypes = BlockTypes.Type) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n)
        && SlateElement.isElement(n)
        && n[blockType] === format,
    }),
  );

  return !!match;
};

export const getMarkValue = (editor: Editor, mark: MarkFormatTypes) => {
  const marks = Editor.marks(editor);
  return marks?.[mark];
};

export const isMarkActive = (editor: Editor, format: MarkFormatTypes) => {
  const mark = getMarkValue(editor, format);
  return Boolean(mark);
};

export const toggleBlock = (editor: Editor, format: BlockFormatTypes) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN.includes(format as TextAlignTypes) ? BlockTypes.Align : BlockTypes.Type,
  );
  const isList = LIST_ELEMENTS.includes(format as ElementTypes);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n)
      && SlateElement.isElement(n)
      && LIST_ELEMENTS.includes(n.type)
      && !TEXT_ALIGN.includes(format as TextAlignTypes),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN.includes(format as TextAlignTypes)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? ElementTypes.Paragraph : isList ? ElementTypes.ListItem : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format as ElementTypes, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: Editor, format: MarkFormatTypes, value: any = true) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value);
  }
};

export const serialize = (node: Descendant) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.code) {
      string = `<code>${string}</code>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    // @ts-ignore
    if (node.link) {
      // @ts-ignore
      string = `<a href="${node.link}" target="_blank" rel="noreferrer noopener">${string}</a>`;
    }

    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');
  const style = `style="text-align:${node.align}"`;
  switch (node.type) {
    case ElementTypes.BlockQuote:
      return `<blockquote ${style}><p>${children}</p></blockquote>`;
    case ElementTypes.BulletedList:
      return `<ul ${style}>${children}</ul>`;
    case ElementTypes.HeadingOne:
      return `<h1 ${style}>${children}</h1>`;
    case ElementTypes.HeadingTwo:
      return `<h2 ${style}>${children}</h2>`;
    case ElementTypes.ListItem:
      return `<li ${style}>${children}</li>`;
    case ElementTypes.NumberedList:
      return `<ol ${style}>${children}</ol>`;
    case ElementTypes.Paragraph:
      return `<p ${style}>${children}</p>`;
    default:
      return children;
  }
};

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export const deserialize = (element: HTMLElement | ChildNode, markAttributes: NodeAttributes = {}): Descendant | Descendant[] => {
  if (element.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, element.textContent);
  } if (element.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };
  if (element.nodeName === 'STRONG') {
    nodeAttributes.bold = true;
  }
  if (element.nodeName === 'CODE') {
    nodeAttributes.code = true;
  }
  if (element.nodeName === 'EM') {
    nodeAttributes.italic = true;
  }
  if (element.nodeName === 'U') {
    nodeAttributes.underline = true;
  }
  if (element.nodeName === 'A') {
    // @ts-ignore
    nodeAttributes.link = (element as HTMLElement).getAttribute('href');
  }

  const children = Array.from(element.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  const align = (element as HTMLElement).style.textAlign;

  switch (element.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BLOCKQUOTE':
      return jsx(
        'element',
        { type: ElementTypes.BlockQuote, align },
        children,
      );
    case 'UL':
      return jsx(
        'element',
        { type: ElementTypes.BulletedList, align },
        children,
      );
    case 'H1':
      return jsx(
        'element',
        { type: ElementTypes.HeadingOne, align },
        children,
      );
    case 'H2':
      return jsx(
        'element',
        { type: ElementTypes.HeadingTwo, align },
        children,
      );
    case 'LI':
      return jsx(
        'element',
        { type: ElementTypes.ListItem, align },
        children,
      );
    case 'OL':
      return jsx(
        'element',
        { type: ElementTypes.NumberedList, align },
        children,
      );
    case 'P':
      return jsx(
        'element',
        { type: ElementTypes.Paragraph, align },
        children,
      );
    default:
      return children;
  }
};
