import { productManagerService } from 'apps/Product';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { ProductTypes } from 'models/Product.model';
import { selectMerchantTags } from 'state/merchant';
import { ESignatureService } from '../services/ESignature.service';

export const eSignatureInit = () => (_, getState): ProductTypes => {
  const eSignature = new ESignatureService({
    disabled: !selectMerchantTags(getState()).includes(MerchantTagsTypes.CanUseESignature),
  });
  productManagerService.register(eSignature);
  return eSignature.id;
};
