import { FacialWatchlistDataSourceTypes } from 'apps/CustomWatchlist/models/CustomWatchlist.model';
import { DataSourceValueTypes } from './Watchlist.model';

export enum CustomWatchlistSeverityOnMatchTypes {
  NoAction = 'no-action',
  Low = 'low',
  Medium = 'medium',
  Critical = 'critical',
}

export interface IFlowWatchlistList {
  id: number;
  severityOnMatch: CustomWatchlistSeverityOnMatchTypes;
}

export interface IFlowWatchlist {
  list: IFlowWatchlistList[];
  dataSource: DataSourceValueTypes | FacialWatchlistDataSourceTypes ;
}
export interface ICustomWatchList extends IFlowWatchlist {
  facematchThreshold: number;
}
