import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ProductIntegrationTypes } from 'models/Product.model';
import React, { useState } from 'react';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './IntegrationTypeSelectingForm.styles';

export function IntegrationTypeSelectingForm({ onSubmit, onCancel, value }: {
  onSubmit: (value: Nullable<ProductIntegrationTypes>) => void;
  onCancel: () => void;
  value: Nullable<ProductIntegrationTypes>;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [localValue, setLocalValue] = useState<Nullable<ProductIntegrationTypes>>(value);

  const handleSubmit = () => onSubmit(localValue);
  const handleClick = (event: any) => {
    if (!event.target.value) { // the first event (on label) should be skipped
      return;
    }
    setLocalValue((prevValue) => (event.target.value === prevValue ? null : event.target.value) as ProductIntegrationTypes);
  };

  return (
    <Box>
      <Box className={classes.title}>
        {formatMessage('IntegrationForm.title')}
      </Box>
      <RadioGroup value={localValue}>
        <FormControlLabel
          control={<Radio color="primary" className={classes.radio} />}
          value={ProductIntegrationTypes.Api}
          onClick={handleClick as any}
          label={formatMessage('FlowBuilder.integration.api')}
        />
        <FormControlLabel
          control={<Radio color="primary" className={classes.radio} />}
          value={ProductIntegrationTypes.Sdk}
          onClick={handleClick as any}
          label={formatMessage('FlowBuilder.integration.sdk')}
        />
      </RadioGroup>
      <Box className={classes.footer}>
        <Button variant="text" color="primary" onClick={onCancel}>
          {formatMessage('CountryModalSelect.cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
          {formatMessage('CountryModalSelect.submit')}
        </Button>
      </Box>
    </Box>
  );
}
