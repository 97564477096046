import React from 'react';
import { RenderLeafProps } from 'slate-react/dist/components/editable';

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  let result = children;
  if (leaf.bold) {
    result = <strong>{result}</strong>;
  }

  if (leaf.code) {
    result = <code>{result}</code>;
  }

  if (leaf.italic) {
    result = <em>{result}</em>;
  }

  if (leaf.underline) {
    result = <u>{result}</u>;
  }

  // @ts-ignore
  if (leaf.link) {
    // @ts-ignore
    result = <a>{result}</a>;
  }

  return <span {...attributes}>{result}</span>;
};
