import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { BackgroundCheckStatusesTypes, BackgroundScoreTypes, checkStatusesMap } from 'models/BackgroundCheck.model';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import { useStyles } from './BackgroundCheckScore.styles';
import { BackgroundCheckScoreItem } from './components/BackgroundCheckScoreItem/BackgroundCheckScoreItem';

export const BackgroundCheckScore = ({
  score,
  labels = {},
  status,
  className,
}: {
  status: BackgroundCheckStatusesTypes;
  score: {
    [BackgroundCheckStatusesTypes.Approved]?: number;
    [BackgroundCheckStatusesTypes.LowRisk]?: number;
    [BackgroundCheckStatusesTypes.HighRisk]?: number;
  };
  labels?: {
    [BackgroundCheckStatusesTypes.Approved]?: string;
    [BackgroundCheckStatusesTypes.LowRisk]?: string;
    [BackgroundCheckStatusesTypes.HighRisk]?: string;
  };
  className?: string;
}) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Paper className={classnames(classes.wrapper, className)}>
      <Box p={3} className={classes.box}>
        <Grid container direction="column" justifyContent="center" className={classes.score}>
          <Grid container item direction="row" wrap="nowrap" xs={12} md="auto">
            {score && Object.entries(checkStatusesMap)
              .map(([scoreKey, {
                title,
              }]) => (
                <BackgroundCheckScoreItem
                  key={scoreKey}
                  title={labels[scoreKey] || formatMessage(title)}
                  status={scoreKey as BackgroundScoreTypes}
                  value={score[scoreKey]}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={classnames(classes.statusValue, {
                [classes.highRisk]: status === BackgroundCheckStatusesTypes.HighRisk,
                [classes.lowRisk]: status === BackgroundCheckStatusesTypes.LowRisk
                || status === BackgroundCheckStatusesTypes.Rejected,
              })}
            >
              {formatMessage(`BackgroundCheck.verification.summary.status.${status}`)}
            </Typography>
            <Typography variant="body1" className={classes.label}>
              {formatMessage('BackgroundCheck.verification.summary.status')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
