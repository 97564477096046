import classnames from 'classnames';
import React from 'react';
import { Line, LineChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Grid from '@material-ui/core/Grid';
import { AppTheme } from 'apps/theme';
import { useFormatMessage } from 'apps/intl';
import { formatCurrency } from 'lib/currency';
import { useStyles } from '../BankAccountDataVerification/BankAccountDataVerification.styles';
import { IBalancesChartData } from '../../models/BankAccountData.model';
import { LineChartDot } from './LineChartDot';

function CustomTooltip({ active, payload, currency, chartData, noDataMessage }: {
  active?: boolean;
  payload?: any;
  currency: string;
  chartData: IBalancesChartData[];
  noDataMessage: string;
}) {
  const classes = useStyles();

  if (chartData.every((balancesData) => balancesData.balance === 0)) {
    return (
      <div className={classes.chartTooltip}>
        <div className={classes.noDataAvailable}>{noDataMessage}</div>
      </div>
    );
  }

  if (active && payload?.length > 0) {
    return (
      <div className={classes.chartTooltip}>
        <Grid container alignItems="baseline" justifyContent="space-between">
          <div className={classes.chartLabel}>{payload[0]?.payload?.timePeriod}</div>
          <div className={classes.chartValue}>{formatCurrency(payload[0]?.value as string, currency)}</div>
        </Grid>
        <Grid container alignItems="baseline" justifyContent="space-between">
          <div className={classes.chartLabel}>{payload[1]?.name}</div>
          <div className={classes.chartValue}>{formatCurrency(payload[1]?.value as string, currency)}</div>
        </Grid>
      </div>
    );
  }

  return null;
}

export function BankAccountBalancesChart({ balancesChartData, currency }: {
  balancesChartData: IBalancesChartData[];
  currency: string;
}) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <ResponsiveContainer width="100%" height={350} className={classnames(classes.responsiveContainer, 'fs-exclude')}>
      <LineChart data={balancesChartData} margin={{ top: 20, right: 20, left: 30, bottom: 5 }}>
        <CartesianGrid vertical={false} stroke={AppTheme.palette.common.black50} />
        <XAxis
          dataKey="timePeriod"
          tickLine={false}
          tickMargin={5}
          tick={{ fill: AppTheme.palette.common.black60, fontSize: 12 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={5}
          orientation="right"
          tick={{ fill: AppTheme.palette.common.black60, fontSize: 12 }}
        />
        <Line
          stroke={AppTheme.palette.common.red}
          strokeWidth={2}
          connectNulls
          type="monotone"
          dataKey="balance"
          dot={false}
          activeDot={(chartDotProps) => <LineChartDot color={AppTheme.palette.common.red} {...chartDotProps} />}
          name={formatMessage('BankAccountData.chart.balance', { messageValues: { currency } })}
        />
        <Line
          stroke={AppTheme.palette.common.black75}
          strokeWidth={2}
          strokeDasharray="3 3"
          type="monotone"
          dataKey="averageBalance"
          dot={false}
          activeDot={false}
          legendType="plainline"
          name={formatMessage('BankAccountData.chart.averageBalance', { messageValues: { currency } })}
        />
        {/* @ts-ignore */}
        <Tooltip isAnimationActive={false} content={<CustomTooltip currency={currency} chartData={balancesChartData} noDataMessage={formatMessage('BankAccountData.table.noDataAvailable')} />} />
        <Legend formatter={(value) => <span style={{ color: AppTheme.palette.common.black90 }}>{value}</span>} />
      </LineChart>
    </ResponsiveContainer>
  );
}
