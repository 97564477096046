import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import { IMeritBlockContentAndType } from 'apps/MeritBlockAdditional';
import { isNil } from 'lib/isNil';
import { ProductTypes } from 'models/Product.model';
import { QATags } from 'models/QA.model';
import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { TemplateMeritBlockCard } from '../TemplateMeritBlockCard/TemplateMeritBlockCard';
import { useStyles } from './TemplateFlowContent.styles';

type ScreenshotIndex = number;

export function TemplateFlowContent({ screenshots = [], meritBlockToScreenshot = {}, meritBlockContentAndTypes = [] }: {
  screenshots: string[];
  meritBlockToScreenshot: Partial<Record<ProductTypes, ScreenshotIndex>>;
  meritBlockContentAndTypes: IMeritBlockContentAndType[];
}) {
  const classes = useStyles();
  const [openedMerit, setOpenedMerit] = useState<Nullable<ProductTypes>>(meritBlockContentAndTypes[0]?.type || null);
  const [openedScreenshot, setOpenedScreenshot] = useState<ScreenshotIndex>(0);

  const handleClickOnMerit = (meritType: ProductTypes) => () => {
    setOpenedMerit(meritType);
    if (!isNil(meritBlockToScreenshot[meritType])) {
      setOpenedScreenshot(meritBlockToScreenshot[meritType]);
    }
  };

  const handleNextScreenshot = () => setOpenedScreenshot(((prev) => (prev < screenshots.length - 1 ? prev + 1 : prev)));
  const handlePrevScreenshot = () => setOpenedScreenshot(((prev) => (prev > 0 ? prev - 1 : prev)));

  return (
    <Box className={classes.container}>
      {meritBlockContentAndTypes.length > 0 && (
        <Box className={classes.meritBlocks}>
          {meritBlockContentAndTypes.map((contentAndType) => (
            <Box mb="22px" key={contentAndType.type}>
              <TemplateMeritBlockCard
                type={contentAndType.type}
                meritBlockContent={contentAndType.content}
                isOpen={openedMerit === contentAndType.type}
                onClick={handleClickOnMerit(contentAndType.type)}
              />
            </Box>
          ))}
        </Box>
      )}
      {screenshots.length > 0 && (
        <Box className={classes.preview}>
          <Box className={classes.navigationControl} onClick={handlePrevScreenshot} mr="10px" data-qa={QATags.Templates.Details.PreviousScreenshotButton}>
            <FiChevronLeft size={24} />
          </Box>
          <CardMedia
            alt="Metamap preview"
            className={classes.screenshot}
            component="img"
            image={screenshots[openedScreenshot]}
            data-qa={QATags.Templates.Details.ScreenshotImage}
          />
          <Box className={classes.navigationControl} onClick={handleNextScreenshot} ml="10px" data-qa={QATags.Templates.Details.NextScreenshotButton}>
            <FiChevronRight size={24} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
