import React, { ErrorInfo } from 'react';

/* eslint-disable react/destructuring-assignment */

interface IErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  fallbackComponent?: React.ReactNode;
}

interface IErrorCatchState {
  hasError: boolean;
}

export class ErrorCatch extends React.Component<IErrorBoundaryProps, IErrorCatchState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    const { children, fallbackComponent } = this.props;
    const { hasError } = this.state;

    if (hasError && fallbackComponent !== undefined) {
      return fallbackComponent;
    }
    return children;
  }
}

/* eslint-enable react/destructuring-assignment */
