import { IFlow } from 'models/Flow.model';
import { StepIds } from 'models/Step.model';
import { BackgroundCheckStatusesTypes, BackgroundCheckTypes, getBackgroundCheckScoreByCrawlerStatus, getBackgroundCheckScoreByExtraRecords, getBackgroundCheckScoreByLength, IBackgroundCheckStepData, StepExtraMexicanBuholegal } from 'models/BackgroundCheck.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

const scoreGettersMap = {
  [VerificationPatternTypes.BackgroundBrazilianChecks]: ({ results }: IBackgroundCheckStepData) => getBackgroundCheckScoreByCrawlerStatus(results),
  [VerificationPatternTypes.BackgroundLatinAmericaChecks]: ({ stepExtra }: IBackgroundCheckStepData) => getBackgroundCheckScoreByExtraRecords(stepExtra),
  [VerificationPatternTypes.BackgroundMexicanBuholegal]: ({ stepExtra }: IBackgroundCheckStepData<StepExtraMexicanBuholegal>) => getBackgroundCheckScoreByLength(stepExtra),
};

export const getBackgroundCheckScore = (id: StepIds, data?: IBackgroundCheckStepData):
  {
    [BackgroundCheckStatusesTypes.Approved]: number;
    [BackgroundCheckStatusesTypes.LowRisk]: number;
    [BackgroundCheckStatusesTypes.HighRisk]: number;
  } => (data && scoreGettersMap[id]?.(data)) || {
  [BackgroundCheckStatusesTypes.Approved]: 0,
  [BackgroundCheckStatusesTypes.LowRisk]: 0,
  [BackgroundCheckStatusesTypes.HighRisk]: 0,
};

export function getBackgroundCheckSettingError(flow: IFlow): Nullable<string> {
  if (!flow.verificationPatterns[VerificationPatternTypes.BackgroundMexicanBuholegal]
    && (flow.verificationPatterns[VerificationPatternTypes.BackgroundBrazilianChecks] === BackgroundCheckTypes.None)
    && (flow.verificationPatterns[VerificationPatternTypes.BackgroundLatinAmericaChecks] === BackgroundCheckTypes.None)) {
    return 'FlowBuilder.issue.countriesNotSpecified';
  }
  return null;
}
export const postPhaseTimeoutConfig = {
  hh: 6,
  mm: 0,
} as const;
