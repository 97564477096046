import React from 'react';
import { MexicanBuholegalCheck } from 'models/BackgroundCheck.model';
import { ItemValue, Spoiler } from 'apps/ui';
import Box from '@material-ui/core/Box';
import { useFormatMessage } from 'apps/intl';
import { useStyles } from './RecordAgreements.styles';

export const RecordAgreements = ({ agreements }: { agreements: ArrayElement<MexicanBuholegalCheck['records']>['agreements'] }) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  return (
    <Spoiler
      title={formatMessage('BackgroundCheck.verification.jsonView.agreements')}
      classNames={{ spoiler: classes.spoiler }}
    >
      <Box className={classes.container}>
        {agreements.map(({
          agreement,
          date,
        }, idx) => (
          <Box key={idx} className={classes.item}>
            <Box pb={1}>
              <ItemValue title={formatMessage('BackgroundCheck.verification.jsonView.agreement')} value={agreement} />
            </Box>
            <Box>
              <ItemValue title={formatMessage('BackgroundCheck.verification.jsonView.agreementDate')} value={date} />
            </Box>
          </Box>
        ))}
      </Box>
    </Spoiler>
  );
};
