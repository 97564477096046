import { FixedSizeNodeData } from '../FixedSizeTree';

export interface ITreeWalkerNodeData<T> extends FixedSizeNodeData {
  nestingLevel: number;
  item: T;
}

interface IItemAndNestingLevel<T> {
  nestingLevel: number;
  item: T;
}

export function* treeWalker<T extends { id: string; children?: T[] }>(refresh: boolean, tree: T[], getIsOpenByDefault: () => boolean): Generator<ITreeWalkerNodeData<T> | string, void, boolean> {
  // render each item in the tree
  for (let k = 0; k < tree.length; k += 1) {
    const treeItem: T = tree[k];
    const stack: IItemAndNestingLevel<T>[] = [];

    stack.push({
      nestingLevel: 0,
      item: treeItem,
    });

    while (stack.length !== 0) {
      const {
        item,
        nestingLevel,
      } = stack.pop();
      const { children = [], id } = item;

      const isOpened = yield refresh
        ? {
          isOpenByDefault: getIsOpenByDefault(),
          nestingLevel,
          id,
          item,
        }
        : id;

      if (children.length !== 0 && isOpened) {
        for (let i = children.length - 1; i >= 0; i -= 1) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            item: children[i],
          });
        }
      }
    }
  }
}
