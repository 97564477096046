import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  error: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'left',
    verticalAlign: 'center',
    lineHeight: 1.25,
    border: `1px solid ${theme.palette.warning.main}`,
    padding: '8px 16px',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.lightyellow,
  },
  errorContent: {
    flexGrow: 1,
    color: theme.palette.common.black90,
  },
  errorIcon: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: 16,
    color: theme.palette.warning.main,
  },
}));
