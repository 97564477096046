import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import { AgeThresholdSettings } from 'apps/AgeCheck/components/AgeThresholdSettings/AgeThresholdSettings';
import { CountryRestrictionSettings } from 'apps/countries';
import { DocumentStepSettings } from 'apps/documents/components/DocumentStepSettings/DocumentStepSettings';
import { FaceMatchingThreshold } from 'apps/facematch';
import { flowBuilderProductAdd } from 'apps/flowBuilder/store/FlowBuilder.action';
import { useFormatMessage } from 'apps/intl';
import { useOtherProductAdding } from 'apps/Product';
import { useMerchantPlan } from 'apps/merchant';
import { useSettingsBuffer } from 'apps/flowBuilder/hooks/settingsBuffer.hook';
import { appPalette } from 'apps/theme';
import { BoxBordered, RadioButton, Warning } from 'apps/ui';
import { ExtendedDescription } from 'apps/ui/components/ExtendedDescription/ExtendedDescription';
import classNames from 'classnames';
import { useScrollMeIntoView } from 'lib/ScrollIntoView';
import { cloneDeep } from 'lodash';
import { ProductSettingsProps, ProductTypes } from 'models/Product.model';
import { selectCanUseProofOfOwnership } from 'apps/ProofOfOwnership';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AGE_CHECK_DEFAULT_THRESHOLD } from 'apps/AgeCheck/models/AgeCheck.model';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';
import { BiometricVerificationChallengeTypes } from 'apps/biometricVerification';
import { DocumentDuplicateUserRejectionFlowTypes, DocumentVerificationSettingTypes } from '../../models/DocumentVerification.model';
import { useStyles } from './DocumentVerificationSettings.styles';

export function DocumentVerificationSettings({ settings, onUpdate }: ProductSettingsProps<DocumentVerificationSettingTypes>) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { isFreemium } = useMerchantPlan();
  const [isCountryRestrictionEnabled, setIsCountryRestrictionEnabled] = useState(false);
  const checkCountryRestriction = useCallback(() => setIsCountryRestrictionEnabled(settings[DocumentVerificationSettingTypes.CountryRestriction]?.value?.length > 0), [settings]);
  const [bufferedSettings, updateBufferedSettings] = useSettingsBuffer<DocumentVerificationSettingTypes>(settings, checkCountryRestriction);
  const createAddOtherProductModalOverlay = useOtherProductAdding();
  const dispatch = useDispatch();

  const canUseProofOfOwnership = useSelector(selectCanUseProofOfOwnership);
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const isAlterationDetectionDisabled = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanNotUseHumanAlterationDetection)), [merchantTags]);
  const isAutoDocumentReading = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanNotUseHumanDocumentReading)), [merchantTags]);
  const manualDocumentReading = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseHumanDocumentReading)), [merchantTags]);
  const canNotUseDocumentReading = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanNotUseDocumentReading)), [merchantTags]);
  const canUseSideSideMismatch = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseSideMismatch)), [merchantTags]);
  const isDocumentReadingDisabled = settings[DocumentVerificationSettingTypes.HumanDocumentReadingService]?.isDisabled || isAutoDocumentReading;
  const isHumanDocumentReadingServiceEnabled = isAutoDocumentReading || manualDocumentReading;
  const isDuplicateUserRejection = useMemo<DocumentDuplicateUserRejectionFlowTypes>(() => (settings.isDuplicateUserRejection.value ? DocumentDuplicateUserRejectionFlowTypes.Rejected : DocumentDuplicateUserRejectionFlowTypes.ManualReview), [settings.isDuplicateUserRejection.value]);
  const ScrollMeIntoView = useScrollMeIntoView();

  useEffect(() => {
    // TODO: @ggrigorev rework this logic in useSettingsBuffer and checkCountryRestriction
    if (settings[DocumentVerificationSettingTypes.CountryRestriction]?.value?.length > 0) {
      setIsCountryRestrictionEnabled(true);
    }
  }, [settings]);

  useEffect(() => {
    if (settings[DocumentVerificationSettingTypes.HumanFraudDetectionService]?.value === undefined) {
      const newSettings = cloneDeep(settings);
      newSettings[DocumentVerificationSettingTypes.HumanFraudDetectionService].value = !!isAlterationDetectionDisabled;
      onUpdate(newSettings);
    }
    if (isHumanDocumentReadingServiceEnabled && settings[DocumentVerificationSettingTypes.HumanDocumentReadingService]?.value === undefined) {
      const newSettings = cloneDeep(settings);
      newSettings[DocumentVerificationSettingTypes.HumanDocumentReadingService].value = !!isDocumentReadingDisabled;
      onUpdate(newSettings);
    }
  }, [isAlterationDetectionDisabled, isDocumentReadingDisabled, isHumanDocumentReadingServiceEnabled, onUpdate, settings]);

  const handleClick = useCallback((settingId: DocumentVerificationSettingTypes) => () => {
    const newSettings = cloneDeep(settings);
    if (settingId === DocumentVerificationSettingTypes.AgeThreshold) {
      newSettings[settingId].value = settings[settingId].value ? undefined : (bufferedSettings[settingId] ?? AGE_CHECK_DEFAULT_THRESHOLD);
    }
    if (settingId === DocumentVerificationSettingTypes.CountryRestriction) {
      setIsCountryRestrictionEnabled((prevState) => !prevState);
      newSettings[settingId].value = settings[settingId].value?.length > 0 ? [] : bufferedSettings[settingId];
    }
    if ((settingId === DocumentVerificationSettingTypes.DenyUploadRequirement) && newSettings[DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera]) {
      newSettings[DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera].value = false;
    }
    if ([
      DocumentVerificationSettingTypes.GrayscaleImage,
      DocumentVerificationSettingTypes.DocumentPhotoFaceCheck,
      DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck,
      DocumentVerificationSettingTypes.DenyUploadRequirement,
      DocumentVerificationSettingTypes.ProofOfOwnership,
      DocumentVerificationSettingTypes.DuplicateUserDetection,
      DocumentVerificationSettingTypes.HumanDocumentReadingService,
      DocumentVerificationSettingTypes.HumanFraudDetectionService,
      DocumentVerificationSettingTypes.ScreenPhotoValidation,
      DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera,
      DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera,
      DocumentVerificationSettingTypes.DocumentCroppedCheck,
      DocumentVerificationSettingTypes.BlurryImageCheck,
      DocumentVerificationSettingTypes.SideMismatch,
    ].includes(settingId)) {
      newSettings[settingId].value = !newSettings[settingId].value;
    }
    onUpdate(newSettings);
  }, [onUpdate, settings, bufferedSettings]);

  const handleUpdate = useCallback((settingId: DocumentVerificationSettingTypes) => (newValue: any) => {
    const newSettings = cloneDeep(settings);
    newSettings[settingId].value = newValue;
    updateBufferedSettings(settingId, newValue);

    onUpdate(newSettings);
  }, [onUpdate, settings, updateBufferedSettings]);

  const handleAdd = useCallback((productType) => {
    dispatch(flowBuilderProductAdd(productType));
  }, [dispatch]);

  const handleDuplicateUserRejectionFlowStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDuplicateUserDetectionFlowStatus = event.target.value as DocumentDuplicateUserRejectionFlowTypes;
    const newSettings = cloneDeep(settings);
    newSettings[DocumentVerificationSettingTypes.IsDuplicateUserRejection].value = updatedDuplicateUserDetectionFlowStatus === DocumentDuplicateUserRejectionFlowTypes.Rejected;
    onUpdate(newSettings);
  };

  const handleAddBiometricVerification = useCallback(() => {
    createAddOtherProductModalOverlay(
      ProductTypes.BiometricVerification,
      <Box component="span">
        {formatMessage('DocumentVerification.settings.description.addBiometricProduct', {
          messageValues: {
            settings: (
              <Box component="span" color="common.green">
                {formatMessage('DocumentVerification.settings.description.facematchAndPoo')}
              </Box>
            ),
          },
        })}
      </Box>,
      handleAdd,
    );
  }, [createAddOtherProductModalOverlay, formatMessage, handleAdd]);

  const handleDocumentStepSettingsUpdate = useCallback((newStepSettingValue: any, newCountrySettingValue?: any) => {
    const newSettings = cloneDeep(settings);
    newSettings[DocumentVerificationSettingTypes.DocumentSteps].value = newStepSettingValue;
    if (newCountrySettingValue) {
      newSettings[DocumentVerificationSettingTypes.CountriesSettings].value = newCountrySettingValue;
      updateBufferedSettings(DocumentVerificationSettingTypes.CountriesSettings, newCountrySettingValue);
    }
    updateBufferedSettings(DocumentVerificationSettingTypes.DocumentSteps, newStepSettingValue);
    onUpdate(newSettings);
  }, [onUpdate, settings, updateBufferedSettings]);

  return (
    <Box>
      { /* Document Steps */ }
      {settings[DocumentVerificationSettingTypes.DocumentSteps].error && (
        <ScrollMeIntoView />
      )}
      <Box mb={4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.DocumentSteps}`)}
          text={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.DocumentSteps}`)}
          error={settings[DocumentVerificationSettingTypes.DocumentSteps].error && formatMessage(settings[DocumentVerificationSettingTypes.DocumentSteps].error)}
        >
          <DocumentStepSettings steps={settings[DocumentVerificationSettingTypes.DocumentSteps]?.value} onUpdate={handleDocumentStepSettingsUpdate} restrictedCountries={settings[DocumentVerificationSettingTypes.CountryRestriction]?.value} countrySettings={settings[DocumentVerificationSettingTypes.CountriesSettings]?.value} />
        </ExtendedDescription>
      </Box>

      { /* Country Restriction */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.CountryRestriction}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.CountryRestriction}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.CountryRestriction]?.isDisabled}
          error={settings[DocumentVerificationSettingTypes.CountryRestriction].error && formatMessage(settings[DocumentVerificationSettingTypes.CountryRestriction].error)}
          prefix={!settings[DocumentVerificationSettingTypes.IsRestrictedToFreemiumCountries].value && (
          <Switch
            checked={isCountryRestrictionEnabled}
            disabled={settings[DocumentVerificationSettingTypes.CountryRestriction]?.isDisabled}
            onClick={handleClick(DocumentVerificationSettingTypes.CountryRestriction)}
            color="primary"
          />
          )}
          badge={<Box>{formatMessage('FlowBuilder.integration.sdkOnly')}</Box>}
        >
          {isCountryRestrictionEnabled && !settings[DocumentVerificationSettingTypes.CountryRestriction]?.isDisabled && (
          <CountryRestrictionSettings
            countries={settings[DocumentVerificationSettingTypes.CountryRestriction]?.value}
            onUpdate={handleUpdate(DocumentVerificationSettingTypes.CountryRestriction)}
            isDisabled={settings[DocumentVerificationSettingTypes.IsRestrictedToFreemiumCountries].value}
          />
          )}
        </ExtendedDescription>
      </Box>
      { /* Document Reading Setting */ }
      {isHumanDocumentReadingServiceEnabled && (
      <>
        <Box mb={2.4}>
          <ExtendedDescription
            title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.HumanDocumentReadingService}`)}
            info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.HumanDocumentReadingService}`)}
            isDisabled={isDocumentReadingDisabled}
            prefix={settings[DocumentVerificationSettingTypes.HumanDocumentReadingService].value !== undefined
              && (
              <Switch
                checked={!settings[DocumentVerificationSettingTypes.HumanDocumentReadingService].value}
                disabled={isDocumentReadingDisabled}
                onClick={handleClick(DocumentVerificationSettingTypes.HumanDocumentReadingService)}
                color="primary"
              />
              )}
          />
        </Box>
        <Box mb={2.4}>
          {isDocumentReadingDisabled && (
            isFreemium ? (
              <Warning
                label={formatMessage('Freemium.productNotAvailable')}
                isLabelColored={false}
                meritName={formatMessage('CustomDocument')}
                bordered
              />
            ) : (
              <Warning
                label={formatMessage('DocumentVerification.settings.disabled')}
                linkLabel={formatMessage('DocumentVerification.settings.helpEmail')}
                isLabelColored={false}
                bordered
              />
            )
          )}
        </Box>
      </>
      )}
      { /* Alteration Detection Setting */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.HumanFraudDetectionService}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.HumanFraudDetectionService}`)}
          isDisabled={isAlterationDetectionDisabled}
          prefix={settings[DocumentVerificationSettingTypes.HumanFraudDetectionService]?.value !== undefined && (
          <Switch
            checked={!settings[DocumentVerificationSettingTypes.HumanFraudDetectionService].value}
            disabled={isAlterationDetectionDisabled}
            onClick={handleClick(DocumentVerificationSettingTypes.HumanFraudDetectionService)}
            color="primary"
          />
          )}
        />
      </Box>
      { isAlterationDetectionDisabled
      && (
      <Box mb={2.4}>
        {(
          isFreemium ? (
            <Warning
              label={formatMessage('Freemium.productNotAvailable')}
              isLabelColored={false}
              bordered
            />
          ) : (
            <Warning
              label={formatMessage('DocumentVerification.settings.disabled')}
              linkLabel={formatMessage('DocumentVerification.settings.helpEmail')}
              isLabelColored={false}
              bordered
            />
          )
        )}
      </Box>
      )}
      { /* Deny Upload */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.DenyUploadRequirement}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.DenyUploadRequirement}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.DenyUploadRequirement]?.isDisabled}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.DenyUploadRequirement]?.value}
              disabled={settings[DocumentVerificationSettingTypes.DenyUploadRequirement]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.DenyUploadRequirement)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Document upload via native camera */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera}`)}
          isDisabled={!settings[DocumentVerificationSettingTypes.DenyUploadRequirement]?.value}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera]?.value}
              onClick={handleClick(DocumentVerificationSettingTypes.AllowDocumentsViaNativeCamera)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Screen Photo Validation */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.ScreenPhotoValidation}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.ScreenPhotoValidation}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.ScreenPhotoValidation]?.isDisabled}
          prefix={(
            <Switch
              checked={!settings[DocumentVerificationSettingTypes.ScreenPhotoValidation]?.value}
              disabled={settings[DocumentVerificationSettingTypes.ScreenPhotoValidation]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.ScreenPhotoValidation)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Age threshold */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.AgeThreshold}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.AgeThreshold}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.AgeThreshold]?.isDisabled || canNotUseDocumentReading}
          prefix={(
            <Switch
              checked={!!settings[DocumentVerificationSettingTypes.AgeThreshold]?.value}
              disabled={settings[DocumentVerificationSettingTypes.AgeThreshold]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.AgeThreshold)}
              color="primary"
            />
          )}
        >
          {settings[DocumentVerificationSettingTypes.AgeThreshold]?.value && !settings[DocumentVerificationSettingTypes.AgeThreshold]?.isDisabled && !canNotUseDocumentReading && (
          <AgeThresholdSettings
            ageThreshold={settings[DocumentVerificationSettingTypes.AgeThreshold]?.value}
            onUpdate={handleUpdate(DocumentVerificationSettingTypes.AgeThreshold)}
          />
          )}
        </ExtendedDescription>
      </Box>

      { /* Grayscale image */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.GrayscaleImage}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.GrayscaleImage}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.GrayscaleImage]?.isDisabled}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.GrayscaleImage]?.value}
              disabled={settings[DocumentVerificationSettingTypes.GrayscaleImage]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.GrayscaleImage)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Document upload via WebCamera */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera}`)}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera]?.value}
              onClick={handleClick(DocumentVerificationSettingTypes.AllowDocumentsViaWebCamera)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Duplicate user detection */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.DuplicateUserDetection}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.DuplicateUserDetection}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.DuplicateUserDetection]?.isDisabled || canNotUseDocumentReading}
          prefix={(
            <Switch
              checked={!!settings[DocumentVerificationSettingTypes.DuplicateUserDetection]?.value}
              disabled={settings[DocumentVerificationSettingTypes.DuplicateUserDetection]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.DuplicateUserDetection)}
              color="primary"
            />
          )}
        />
        {!!settings[DocumentVerificationSettingTypes.DuplicateUserDetection]?.value && (
        <Box mt={2.4}>
          <RadioGroup
            aria-label="duplicate-user-detection-rejection-type"
            name="duplicate-user-detection-rejection-type"
            value={isDuplicateUserRejection}
            onChange={handleDuplicateUserRejectionFlowStatusChange}
          >
            <BoxBordered mt={1}>
              <Box mb={2} color="common.black75">
                {formatMessage('DocumentVerification.settings.duplicateUserDetection.flowStatus.title')}
              </Box>
              <Box m={1}>
                <FormControlLabel
                  value={DocumentDuplicateUserRejectionFlowTypes.ManualReview}
                  control={<RadioButton color="primary" />}
                  label={(
                    <Box mb={0.5} color="common.black90" fontWeight="bold">
                      {formatMessage('DocumentVerification.settings.duplicateUserDetection.flowStatus.ManualReview')}
                    </Box>
                    )}
                />
              </Box>

              <Box m={1}>
                <FormControlLabel
                  value={DocumentDuplicateUserRejectionFlowTypes.Rejected}
                  control={<RadioButton color="primary" />}
                  label={(
                    <Box mb={0.5} color="common.black90" fontWeight="bold">
                      {formatMessage('DocumentVerification.settings.duplicateUserDetection.flowStatus.Rejected')}
                    </Box>
                  )}
                />
              </Box>
            </BoxBordered>
          </RadioGroup>
        </Box>
        )}
      </Box>

      { /* Document Cropped Detection */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.DocumentCroppedCheck}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.DocumentCroppedCheck}`)}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.DocumentCroppedCheck]?.value}
              onClick={handleClick(DocumentVerificationSettingTypes.DocumentCroppedCheck)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Document photo face check */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.DocumentPhotoFaceCheck}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.DocumentPhotoFaceCheck}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.DocumentPhotoFaceCheck]?.isDisabled}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.DocumentPhotoFaceCheck]?.value}
              disabled={settings[DocumentVerificationSettingTypes.DocumentPhotoFaceCheck]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.DocumentPhotoFaceCheck)}
              color="primary"
            />
          )}
        />
      </Box>

      { /* Disable document photo presence check */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck]?.isDisabled}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck]?.value}
              disabled={settings[DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.DocumentPhotoPresenceCheck)}
              color="primary"
            />
          )}
        />
      </Box>
      { /* Disable blurry image check */ }
      <Box mb={2.4}>
        <ExtendedDescription
          title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.BlurryImageCheck}`)}
          info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.BlurryImageCheck}`)}
          isDisabled={settings[DocumentVerificationSettingTypes.BlurryImageCheck]?.isDisabled}
          prefix={(
            <Switch
              checked={settings[DocumentVerificationSettingTypes.BlurryImageCheck]?.value}
              disabled={settings[DocumentVerificationSettingTypes.BlurryImageCheck]?.isDisabled}
              onClick={handleClick(DocumentVerificationSettingTypes.BlurryImageCheck)}
              color="primary"
            />
          )}
        />
      </Box>

      {settings[DocumentVerificationSettingTypes.CountryRestriction].error && !settings[DocumentVerificationSettingTypes.CountryRestriction]?.isDisabled && (
        <ScrollMeIntoView priority={1} />
      )}

      {settings[DocumentVerificationSettingTypes.IsRestrictedToFreemiumCountries].value && (
        <Box mb={4}>
          {isAlterationDetectionDisabled && (
            isFreemium ? (
              <Warning
                label={formatMessage('Freemium.productNotAvailable')}
                isLabelColored={false}
                meritName={formatMessage('CustomDocument')}
                bordered
              />
            ) : (
              <Warning
                label={formatMessage('DocumentVerification.settings.disabled')}
                linkLabel={formatMessage('DocumentVerification.settings.helpEmail')}
                isLabelColored={false}
                bordered
              />
            )
          ) }
        </Box>
      )}

      { /* Disable Document Side check */ }
      {canUseSideSideMismatch && (
        <Box mb={2.4}>
          <ExtendedDescription
            title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.SideMismatch}`)}
            info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.SideMismatch}`)}
            isDisabled={settings[DocumentVerificationSettingTypes.SideMismatch]?.isDisabled}
            prefix={(
              <Switch
                checked={settings[DocumentVerificationSettingTypes.SideMismatch]?.value}
                disabled={settings[DocumentVerificationSettingTypes.SideMismatch]?.isDisabled}
                onClick={handleClick(DocumentVerificationSettingTypes.SideMismatch)}
                color="primary"
              />
            )}
          />
        </Box>
      )}

      {!settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isRequireOtherProduct && !settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isRequireOtherProduct ? (
        <>
          { /* Face match */ }
          {!settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isRequireOtherProduct && (
            <>
              <Box mb={4}>
                <ExtendedDescription
                  title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.FacematchThreshold}`)}
                  text={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.FacematchThreshold}`)}
                  isDisabled={isFreemium || settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isDisabled}
                >
                  <FaceMatchingThreshold
                    disabled={settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isDisabled}
                    facematchThreshold={settings[DocumentVerificationSettingTypes.FacematchThreshold]?.value}
                    onUpdate={handleUpdate(DocumentVerificationSettingTypes.FacematchThreshold)}
                  />
                </ExtendedDescription>
              </Box>
              {
                isFreemium && (
                  <Box mb={4}>
                    <Warning
                      label={formatMessage('Freemium.productNotAvailable')}
                      isLabelColored={false}
                      bordered
                    />
                  </Box>
                )
              }
            </>
          )}

          { /* Proof of Ownership */ }
          {canUseProofOfOwnership && !settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isRequireOtherProduct && (
            <ExtendedDescription
              title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.ProofOfOwnership}`)}
              info={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.ProofOfOwnership}`)}
              isDisabled={settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isDisabled || settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isCantBeUsedWithOtherSetting}
              prefix={(
                <Switch
                  checked={settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.value}
                  disabled={settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isDisabled || settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isCantBeUsedWithOtherSetting}
                  onClick={handleClick(DocumentVerificationSettingTypes.ProofOfOwnership)}
                  color="primary"
                />
              )}
            >
              {settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isCantBeUsedWithOtherSetting
              && !settings[DocumentVerificationSettingTypes.PooNistWarning].value && (
                <BoxBordered borderColor={appPalette.yellow} mt={2}>
                  <Warning label={formatMessage('DocumentVerification.settings.warning.voiceAndPoo')} />
                </BoxBordered>
              )}
              {settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isCantBeUsedWithOtherSetting
              && settings[DocumentVerificationSettingTypes.PooNistWarning].value === BiometricVerificationChallengeTypes.ACTIVE && (
                <BoxBordered borderColor={appPalette.yellow} mt={2}>
                  <Warning label={formatMessage('DocumentVerification.settings.warning.nistActiveAndPoo')} />
                </BoxBordered>
              )}
              {settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isCantBeUsedWithOtherSetting
              && settings[DocumentVerificationSettingTypes.PooNistWarning].value === BiometricVerificationChallengeTypes.PASSIVE && (
                <BoxBordered borderColor={appPalette.yellow} mt={2}>
                  <Warning label={formatMessage('DocumentVerification.settings.warning.nistPassiveAndPoo')} />
                </BoxBordered>
              )}
            </ExtendedDescription>
          )}
        </>
      )
        : (
          <BoxBordered className={classNames(classes.biometric, { [classes.disabled]: settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isDisabled })} borderColor={appPalette.lightblue}>
            <Box mb={2}>
              <ExtendedDescription
                title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.FacematchThreshold}.instruction`)}
                text={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.FacematchThreshold}.instruction`)}
                isDisabled={settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isDisabled}
              />
            </Box>
            <Box mb={2}>
              <ExtendedDescription
                title={formatMessage(`DocumentVerification.settings.title.${DocumentVerificationSettingTypes.ProofOfOwnership}`)}
                text={formatMessage(`DocumentVerification.settings.description.${DocumentVerificationSettingTypes.ProofOfOwnership}`)}
                isDisabled={settings[DocumentVerificationSettingTypes.ProofOfOwnership]?.isDisabled}
              />
            </Box>
            <Button onClick={handleAddBiometricVerification} disabled={settings[DocumentVerificationSettingTypes.FacematchThreshold]?.isDisabled} className={classes.button} fullWidth variant="contained" color="primary">
              {formatMessage('DocumentVerification.settings.button.addBiometricProduct')}
            </Button>
          </BoxBordered>
        )}
    </Box>
  );
}
