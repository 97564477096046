import { useEffect, useState, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { IProductCard } from 'models/Product.model';

export function useProductFormatMessage(card: IProductCard) {
  const intl = useIntl();
  const [translations, setTranslations] = useState<Nullable<Record<string, any>>>(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (card.isExternal) {
      card.getTranslations((data) => {
        if (isMounted.current) {
          setTranslations(data);
        }
      });
    }
  }, [card, isMounted]);

  const formatMessage = useCallback((id: string) => {
    if (!translations) {
      return '';
    }

    const locale = translations[intl.locale] ?? translations[intl.defaultLocale];
    if (!locale) {
      return '';
    }

    return locale[id] ?? '';
  }, [translations, intl.locale]);

  return formatMessage;
}
