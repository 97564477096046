import { getESignatureExample } from 'apps/ESignature/models/ESignature.model';
import { ProductBaseFlowBuilder } from 'apps/flowBuilder/services/ProductBaseFlowBuilder.service';
import { ESignatureCheckTypes, ESignatureCheckSettingsTypes, getAcceptanceCriteria, getSigMethod } from 'models/ESignature.model';
import { IFlow } from 'models/Flow.model';
import { Product, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes, IProductCURLExampleParams } from 'models/Product.model';
import { getStepStatus, StepStatus } from 'models/Step.model';
import { VerificationResponse } from 'models/VerificationOld.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { FiPenTool } from 'react-icons/fi';
import { ESignatureSettings } from '../components/ESignatureSettings/ESignatureSettings';
import { ESignatureVerification } from '../components/ESignatureVerification/ESignatureVerification';

export class ESignatureService extends ProductBaseFlowBuilder implements Product {
  id = ProductTypes.ESignatureCheck;
  order = 1000;
  integrationTypes = [
    ProductIntegrationTypes.Api,
    ProductIntegrationTypes.Sdk,
  ];
  icon = FiPenTool;
  checks = [{
    id: ESignatureCheckTypes.SignatureCheck,
    isActive: true,
  }, {
    id: ESignatureCheckTypes.GeoRestrictionCheck,
    isActive: true,
  }, {
    id: ESignatureCheckTypes.RiskyIpCheck,
    isActive: true,
  }];

  inputs = [
    ProductInputTypes.Sign,
  ];

  component = ESignatureSettings;
  componentVerification = ESignatureVerification;

  parser(flow: IFlow, productsInGraph?: ProductTypes[]): ProductSettings<ESignatureCheckSettingsTypes> {
    const { electronicSignature } = flow;
    const acceptanceCriteria = electronicSignature?.acceptanceCriteria;
    const neededProductsInFlow = productsInGraph?.includes(ProductTypes.BiometricVerification) && productsInGraph?.includes(ProductTypes.DocumentVerification);

    return {
      [ESignatureCheckSettingsTypes.SignatureMethod]: {
        value: getSigMethod(acceptanceCriteria, neededProductsInFlow),
      },
      [ESignatureCheckSettingsTypes.SignatureTouch]: {
        value: acceptanceCriteria?.isTouchSignRequired,
      },
      [ESignatureCheckSettingsTypes.Terms]: {
        value: electronicSignature?.templates?.list || [],
        error: !flow.electronicSignature?.templates?.list?.length && 'ESignature.issues.noDocumentUploaded',
      },
      [ESignatureCheckSettingsTypes.TermsOrder]: {
        value: electronicSignature?.templates?.order || [],
      },
    };
  }

  serialize(settings: ProductSettings<ESignatureCheckSettingsTypes>): Partial<IFlow> {
    const {
      termsOrder,
      terms,
      signatureMethod,
    } = settings;
    return {
      electronicSignature: {
        templates: {
          order: termsOrder.value,
          list: terms.value,
        },
        acceptanceCriteria: getAcceptanceCriteria(signatureMethod.value, settings),
      },
    };
  }

  onAdd(): Partial<IFlow> {
    return {
      electronicSignature: getESignatureExample(),
      verificationPatterns: {
        [VerificationPatternTypes.ESignatureDocuments]: true,
      },
    };
  }

  onRemove(flow: IFlow): Partial<IFlow> {
    return {
      electronicSignature: {
        templates: flow.electronicSignature?.templates,
        acceptanceCriteria: {
          isDocumentsRequired: false,
          isFaceMatchRequired: false,
          isFullNameRequired: false,
          isTouchSignRequired: false,
        },
      },
      verificationPatterns: {
        [VerificationPatternTypes.ESignatureDocuments]: false,
      },
    };
  }

  isInFlow(flow: IFlow): boolean {
    return flow?.verificationPatterns?.[VerificationPatternTypes.ESignatureDocuments];
  }

  getCURLExampleParams(flow: IFlow): Nullable<IProductCURLExampleParams> {
    return {
      inputs: [],
      files: [],
      data: {
        fullName: 'Some Name',
        readDetails: flow.electronicSignature.templates.list.map((document) => ({
          documentId: document.id,
          readAt: new Date().toISOString(),
        })),
        signedAt: new Date().toISOString(),
      },
    };
  }

  getVerification(verification: VerificationResponse): any {
    return verification.steps.find((step) => step.id === VerificationPatternTypes.ESignatureDocuments);
  }

  hasFailedCheck(verification: VerificationResponse): boolean {
    const eSigStep = verification?.steps?.find((step) => step?.id === VerificationPatternTypes.ESignatureDocuments);
    const eSigStatus = getStepStatus(eSigStep);
    return [StepStatus.Incomplete, StepStatus.Failure].includes(eSigStatus);
  }
}
