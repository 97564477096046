import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormatMessage } from 'apps/intl';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { StepIds } from 'models/Step.model';
import { BackgroundCheckCountryTypes } from 'models/BackgroundCheck.model';
import { useStyles, StyledButton } from './BackgroundCheckShowManualButton.styles';
import { backgroundCheckManualRun } from '../../state/BackgroundCheck.actions';

export function BackgroundCheckShowManualButton({
  stepIds,
  verificationId,
  documentsCountries,
}: { stepIds: StepIds[]; verificationId: string; documentsCountries: string[] }) {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleBackgroundCheckManualRun = (stepId: StepIds) => {
    dispatch(backgroundCheckManualRun(verificationId, VerificationPatternTypes.BackgroundMexicanBuholegal, stepId));
  };

  const getCountryCodeFromDocuments = (stepId: StepIds) => {
    const countriesByStep = {
      [VerificationPatternTypes.BackgroundMexicanBuholegal]: [BackgroundCheckCountryTypes.Mexico],
      [VerificationPatternTypes.BackgroundBrazilianChecks]: [BackgroundCheckCountryTypes.Brazil],
      [VerificationPatternTypes.BackgroundLatinAmericaChecks]: [
        BackgroundCheckCountryTypes.Argentina,
        BackgroundCheckCountryTypes.Chile,
        BackgroundCheckCountryTypes.Colombia,
        BackgroundCheckCountryTypes.Ecuador,
        BackgroundCheckCountryTypes.Paraguay,
        BackgroundCheckCountryTypes.Peru,
        BackgroundCheckCountryTypes.Venezuella,
      ],
    };
    const stepCountries = countriesByStep[stepId];

    return stepCountries.find((stepCountry) => documentsCountries.includes(stepCountry));
  };

  return (
    <Box>
      <Typography variant="h3" gutterBottom className={classnames(classes.reportTitle, classes.colorGrey)}>
        {formatMessage('BackgroundCheck.report.title')}
      </Typography>
      <Box mt={2.5} mb={2.3} className={classnames(classes.reportSubTitle, classes.colorGrey)}>
        {formatMessage('BackgroundCheck.report.subTitle')}
      </Box>
      <Grid container>
        {stepIds.map((stepId) => {
          const countryCode = getCountryCodeFromDocuments(stepId);
          // Do not show manual request button if there is no document with correct country code for the current stepId
          if (!countryCode) {
            return null;
          }

          return (
            <Grid item key={stepId}>
              <Box>
                <StyledButton
                  color="primary"
                  variant="contained"
                  onClick={() => handleBackgroundCheckManualRun(stepId)}
                  size="large"
                  className={classes.ultraLargeButton}
                >
                  {formatMessage('BackgroundCheck.requestBackgroundCheckReport.country', { defaultMessage: formatMessage('BackgroundCheck.requestBackgroundCheckReport'), messageValues: { country: formatMessage(`Countries.${countryCode}`) } })}
                </StyledButton>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
