import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useStyles } from './NavTab.styles';

export function NavTab({ children, selected = false, className, disabled = false, onClick, icon, qa }: {
  children?: React.ReactNode;
  selected?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  qa?: string;
}) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      startIcon={icon}
      disabled={disabled}
      className={classNames(classes.container, { [classes.selected]: selected }, className)}
      data-qa={qa}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
