import React from 'react';
import { IProductCard, Product, ProductCheck, ProductInputTypes, ProductIntegrationTypes, ProductSettings, ProductTypes, IProductCURLExampleParams, isSdkOnly } from 'models/Product.model';
import { IconType } from 'react-icons';
import { VerificationResponse } from 'models/VerificationOld.model';
import { IFlow } from 'models/Flow.model';
import { VerificationPatternsConfigType } from 'models/VerificationPatternsConfigs.model';
import { VerificationPatternTypes } from 'models/VerificationPatterns.model';

export abstract class ProductBaseFlowBuilder implements Partial<Product> {
  constructor(args?: { disabled?: boolean }) {
    this.disabled = args?.disabled ?? false;
  }
  abstract id: ProductTypes;
  abstract order: number;
  abstract icon: IconType | (() => React.ReactNode);
  disabled: boolean;

  verificationPatternsConfig: VerificationPatternsConfigType = null;
  isConfigurable = true;
  isShowDescription = false;
  isExternal = false;
  integrationTypes: ProductIntegrationTypes[] = [];
  inputs: ProductInputTypes[] = [];
  checks: ProductCheck[] = [];
  requiredProductType: Nullable<ProductTypes | ProductTypes[]> = null;
  dependentProductTypes: ProductTypes[] = [];
  requiredProductTypeFromSubStep: Partial<Record<VerificationPatternTypes, ProductTypes>> = null;

  getIssuesComponent(flow: IFlow, productsInGraph?: ProductTypes[]): Nullable<string> {
    const errorMessages: string[] = [];
    Object.values(this.parser(flow, productsInGraph)).forEach((setting) => setting.error && errorMessages.push(setting.error));
    if (flow.integrationType === ProductIntegrationTypes.Api && isSdkOnly(this.integrationTypes)) {
      return 'FlowBuilder.issue.sdkOnly';
    }
    if (errorMessages.length > 1) {
      return 'FlowBuilder.issue.moreThanOne';
    }
    if (errorMessages.length === 1) {
      return errorMessages[0];
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRemovingAlertComponent(flow: IFlow, productsInGraph?: ProductTypes[]): any {
    return null;
  }

  getTitle(): string {
    return `${this.id}.card.title`;
  }

  getDescription(): string {
    return `${this.id}.card.description`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parser(flow?: IFlow, productsInGraph?: ProductTypes[]): ProductSettings {
    return null;
  }

  getCard(): IProductCard {
    return {
      id: this.id,
      disabled: this.disabled,
      icon: this.icon,
      order: this.order,
      title: this.getTitle(),
      description: this.getDescription(),
      isShowDescription: this.isShowDescription,
      inputs: this.inputs,
      checks: this.checks,
      integrationTypes: this.integrationTypes,
      requiredProductType: this.requiredProductType,
      dependentProductTypes: this.dependentProductTypes,
      requiredProductTypeFromSubStep: this.requiredProductTypeFromSubStep,
      isExternal: false,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCURLExampleParams(flow: IFlow): Nullable<IProductCURLExampleParams> {
    return null;
  }

  onAdd(flow: IFlow): Partial<IFlow> {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRemove(flow: IFlow): Partial<IFlow> {
    return null;
  }

  abstract isInFlow(flow: IFlow): boolean;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInVerification(verification: VerificationResponse): boolean {
    return this.isInFlow(verification?.flow);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasFailedCheck(verification: VerificationResponse): boolean {
    return false;
  }

  getPdfTemplate() {
    return null;
  }
}
